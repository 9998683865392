import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData, urlsData } from "data";
import { editUserMutation } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useEditUser = (id) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [editUserAction, { loading }] = useMutation(editUserMutation);

    const editUser = async ({ role }) => {
        try {
            await editUserAction({
                onCompleted: () => {
                    navigate(urlsData.routes.users.url);

                    dispatch(openAlert(
                        `${t(
                            "messages.editMsgs.success",
                            { entity: t("users.entity") },
                        )}, ${t("messages.operationDelay")}`,
                        statusTypesData.success,
                    ));
                },
                variables: {
                    data: { role_id: role }, //eslint-disable-line
                    id,
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        editUser,
        loading,
    };
};

export default useEditUser;
