import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    TableRow,
} from "@mui/material";
import { Modal, TableList, TableMenu } from "atoms";
import { PageHead } from "components";
import {
    actionsData,
    identifiersData,
    namesData,
    tableHeadCellsData,
    urlsData,
} from "data";
import { useDeleteRelease, useReleasesList } from "hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { StyledLink, StyledTableCell } from "styles";
import { convertDateToLocaleStringFormatHandler, convertHourToDurationFormatHandler } from "utils";

const ArtistDetailsReleases = () => {
    const [isCompleted, setIsCompleted] = useState(true);

    const { t } = useTranslation();

    const { id: artistId } = useParams();

    const location = useLocation();

    const {
        count,
        data: releases,
        limit,
        loading: releasesLoading,
        page,
        setLimit,
        setPage,
    } = useReleasesList(
        true,
        false,
        isCompleted,
        {},
        null,
        parseInt(artistId),
    );

    const { deleteRelease } = useDeleteRelease(
        isCompleted,
        page,
        limit,
        parseInt(artistId),
    );

    const [modalData, setModalData] = useState({
        action: deleteRelease,
        actionType: actionsData["delete"],
        body: t(
            "messages.deleteMsgs.alert",
            { entity: t("releases.entity") },
        ),
        id: null,
        open: false,
        title: t("releases.deleteRelease"),
    });

    const {
        edit: editReleaseRouteUrl,
        url: releasesRouteUrl,
    } = urlsData.routes.releases;

    const {
        link: linkId,
        table: tableId,
    } = identifiersData.ids;

    const {
        allDrafts: allDraftsButtonName,
        completed: completedButtonName,
    } = namesData.buttons;

    if (releasesLoading) {
        return (
            <Box
                alignItems="center"
                display="flex"
                justifyContent="center"
                mt={5}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box
            alignItems="end"
            display="flex"
            flexDirection="column"
            mt={5}
        >
            <PageHead
                title={t("releases.name")}
                titleTag="h2"
            />
            <Button
                id={`${isCompleted ? allDraftsButtonName : completedButtonName}_${identifiersData.ids.button}`}
                sx={{ paddingTop: 0 }}
                onClick={() => {
                    setPage(1);

                    setLimit(25);

                    setIsCompleted((prev) => !prev);
                }}
            >
                {isCompleted ? t("labels.allDrafts") : t("labels.completed")}
                {" "}
                <ArrowForwardIosIcon fontSize="10px" />
            </Button>
            <Modal
                modalData={modalData}
                setModalData={setModalData}
            />
            <TableList
                count={count}
                headCells={tableHeadCellsData.releases.list}
                limit={limit}
                page={page}
                setLimit={setLimit}
                setPage={setPage}
            >
                {releases?.map(({
                    album_name: name,
                    artist,
                    duration,
                    id,
                    image,
                    release_date: releaseDate,
                    releaseTracks,
                    releaseType,
                    upc,
                }, index) => (
                    <TableRow key={name}>
                        <StyledTableCell>
                            <Avatar
                                alt={t("imageAlternatives.release")}
                                imgProps={{ loading: "lazy" }}
                                src={`${image?.downloadThumbnail ? window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_DOMAIN + image?.downloadThumbnail : ""}`} // eslint-disable-line
                            >
                                <LibraryMusicIcon />
                            </Avatar>
                        </StyledTableCell>
                        <StyledTableCell boldCell={+true}>
                            <StyledLink
                                id={`${tableId}_${linkId}_${index + 1}`}
                                state={{ from: location.pathname + location.search }}
                                to={`${releasesRouteUrl}/${id}`}
                            >
                                {name}
                            </StyledLink>
                        </StyledTableCell>
                        <StyledTableCell>{artist?.name}</StyledTableCell>
                        <StyledTableCell>{releaseType.name}</StyledTableCell>
                        <StyledTableCell>
                            <time>{convertDateToLocaleStringFormatHandler(releaseDate)}</time>
                        </StyledTableCell>
                        <StyledTableCell>{upc}</StyledTableCell>
                        <StyledTableCell>{releaseTracks?.length}</StyledTableCell>
                        <StyledTableCell>
                            <time>{convertHourToDurationFormatHandler(duration)}</time>
                        </StyledTableCell>
                        <StyledTableCell onClick={(e) => e.stopPropagation()}>
                            <TableMenu
                                editRouteUrl={`${editReleaseRouteUrl}/${id}`}
                                id={id}
                                rowId={index + 1}
                                setOpenModal={() => {
                                    setModalData({
                                        ...modalData,
                                        id,
                                        open: true,
                                    });
                                }}
                                hasDelete
                                hasEdit
                                isReleasesTable
                            />
                        </StyledTableCell>
                    </TableRow>
                ))}
            </TableList>
        </Box>
    );
};

export default ArtistDetailsReleases;
