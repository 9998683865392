import { Box } from "@mui/material";
import { FiltersForm } from "components";
import { PropTypes } from "prop-types";

const Filters = ({
    asyncMenus,
    data,
    loadings,
    module,
}) => (
    <Box>
        <FiltersForm
            asyncMenus={asyncMenus}
            data={data}
            loadings={loadings}
            module={module}
        />
    </Box>

);

export default Filters;

Filters.propTypes = {
    asyncMenus: PropTypes.object,
    data: PropTypes.object,
    loadings: PropTypes.object,
    module: PropTypes.string,
};
