import {
    SET_DRIVE_MANAGEMENT_DRIVES_LIST,
    SET_DRIVE_MANAGEMENT_LABELS_LIST,
    SET_REFETCH_DRIVE_MANAGEMENT_LIST,
    TOGGLE_DRIVE_MANAGEMENT_LABEL_DRAWER,
} from "redux/types";

export const setRefetchDriveManagementList = (refetch) => ({
    payload: { refetch },
    type: SET_REFETCH_DRIVE_MANAGEMENT_LIST,
});

export const setDriveManagementDrivesList = (drives) => ({
    payload: { drives },
    type: SET_DRIVE_MANAGEMENT_DRIVES_LIST,
});

export const toggleDriveManagementLabelDrawer = (open) => ({
    payload: { open },
    type: TOGGLE_DRIVE_MANAGEMENT_LABEL_DRAWER,
});

export const setDriveManagementLabelsList = (labels) => ({
    payload: { labels },
    type: SET_DRIVE_MANAGEMENT_LABELS_LIST,
});
