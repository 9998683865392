import { useAuth0 } from "@auth0/auth0-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { AutoCompleteMenu } from "atoms";
import { FormContainer } from "components";
import {
    identifiersData,
    inputData,
    namesData,
    permissionsData,
    releaseOwnershipFormData,
} from "data";
import { useDeleteReleaseOwnership } from "hooks";
import { PropTypes } from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useReleaseOwnershipSchema } from "schemas";
import { StyledTextField } from "styles";
import { canDo, clearFormStateAfterSubmissionHandler, renderFormControllerHandler } from "utils";

const ReleaseOwnershipForm = ({
    action,
    asyncMenus,
    loading,
    onSubmitForm,
    values,
}) => {
    const selections = useSelector((state) => state.selectionsReducer);

    const { t } = useTranslation();

    const { id } = useParams();

    const { user } = useAuth0();

    const schema = useReleaseOwnershipSchema();

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const {
        deleteReleaseOwnership,
        loading: deleteReleaseOwnershipLoading,
    } = useDeleteReleaseOwnership(
        parseInt(id),
        () => {},
        true,
    );

    const {
        clearErrors,
        control,
        formState: { errors },
        getValues,
        setValue,
    } = formMethods;

    const {
        mediaCreate: mediaCreatePermission,
        mediaEdit: mediaEditPermission,
        mediaRead: mediaReadPermission,
    } = permissionsData.permissions.keys;

    const allowedPermissions = values ? [mediaEditPermission, mediaReadPermission] : [mediaCreatePermission, mediaReadPermission];

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        type,
        isOptional,
        selectionKey,
        isAsyncMenu,
    ) => {
        if (type === inputData.types.text) {
            return (
                <StyledTextField
                    {...field}
                    error={errors[name]}
                    helperText={errors[name] && errors[name]?.message}
                    id={`${name}_${identifiersData.ids.input}`}
                    label={isOptional ? `${t(`labels.${label}`)} (${t("labels.optional")})` : t(`labels.${label}`)}
                    variant="outlined"
                    fullWidth
                />
            );
        }

        return (
            <AutoCompleteMenu
                asyncMenuData={asyncMenus?.[selectionKey]}
                defaultValue={selections?.[selectionKey]?.find((option) => option.value === values?.[name])}
                errors={errors}
                getRenderedOption={(option) => option.label}
                isAsyncMenu={isAsyncMenu}
                label={label}
                name={name}
                optionLabel={(option) => option.label}
                options={selections?.[selectionKey]}
                onChange={(_, option) => {
                    if (option) field.onChange(option.value);
                    else field.onChange("");
                }}
            />

        );
    };

    return (
        <FormContainer
            action={action}
            formMethods={formMethods}
            hasSecondaryActionButton={!!values?.id}
            loading={loading}
            data={renderFormControllerHandler(
                control,
                releaseOwnershipFormData,
                renderFormControllerChildrenHandler,
            )}
            hasSubmitButton={canDo(
                user,
                allowedPermissions,
            )}
            secondaryActionButtonData={{
                action: () => {
                    deleteReleaseOwnership(values.id).then((response) => {
                        clearFormStateAfterSubmissionHandler(
                            response,
                            null,
                            null,
                            null,
                            getValues,
                            setValue,
                            clearErrors,
                        );
                    });
                },
                color: "error",
                loading: deleteReleaseOwnershipLoading,
                name: namesData.buttons["delete"],
                text: t("actions.delete"),
                variant: "contained",
            }}
            onSubmitForm={onSubmitForm}
        />
    );
};

export default ReleaseOwnershipForm;

ReleaseOwnershipForm.propTypes = {
    action: PropTypes.string,
    asyncMenus: PropTypes.object,
    loading: PropTypes.bool,
    onSubmitForm: PropTypes.func,
    values: PropTypes.object,
};
