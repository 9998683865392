import { useMutation } from "@apollo/client";
import {
    constantsData,
    identifiersData,
    namesData,
    statusTypesData,
} from "data";
import { deleteMemberMutation, getGroupMembersListQuery, getMembersListQuery } from "graph";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useDeleteMember = (
    page,
    limit,
    groupId,
    searchData,
) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [deleteMemberAction, { loading }] = useMutation(
        deleteMemberMutation,
        {
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: groupId ? getGroupMembersListQuery : getMembersListQuery,
                    variables: {
                        limit,
                        page,
                        where: {
                            ...groupId && { driveGroupId: groupId },
                            ...searchData?.[0] && {
                                email: { contains: searchData?.[0] },
                            },
                        },
                    },
                },
            ],
        },
    );

    const { deleteMemberPending: deleteMemberPendingToastName } = namesData.toasts;

    const { toast: toastId } = identifiersData.ids;

    const deleteMember = async (id) => {
        try {
            await deleteMemberAction({
                onCompleted: () => {
                    dispatch(openAlert(
                        t(
                            "messages.deleteMsgs.success",
                            { entity: t("groups.members.entity") },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: { id },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        } finally {
            toast.dismiss(`${deleteMemberPendingToastName}_${toastId}`);
        }
    };

    useEffect(
        () => {
            if (loading) {
                toast.info(
                    () => t(
                        "messages.deleteMsgs.pending",
                        { entity: t("groups.members.entity") },
                    ),
                    { toastId: `${deleteMemberPendingToastName}_${toastId}` },
                );
            }
        },
        [loading], //eslint-disable-line
    );

    return { deleteMember };
};

export default useDeleteMember;
