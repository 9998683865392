import inputData from "data/input";

const { text: textInputType } = inputData.types;

const {
    driveLink: driveLinkInputName,
    resourceName: resourceNameInputName,
} = inputData.names;

const {
    driveLink: driveLinkInputLabel,
    resourceName: resourceNameInputLabel,
} = inputData.labels;

const driveLinkFormData = [
    {
        col: { xs: 12 },
        label: resourceNameInputLabel,
        name: resourceNameInputName,
        type: textInputType,
    },
    {
        col: { xs: 12 },
        label: driveLinkInputLabel,
        name: driveLinkInputName,
        type: textInputType,
    },

];

export default driveLinkFormData;
