import { useAuth0 } from "@auth0/auth0-react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import {
    Box,
    Button,
    Typography,
} from "@mui/material";
import { Menu } from "atoms";
import { DistributionDrawer } from "components";
import {
    identifiersData,
    namesData,
    permissionsData,
    urlsData,
} from "data";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { toggleDistributionDrawer } from "redux/actions";
import { StyledToolbar } from "styles";
import { canDo } from "utils";

const Toolbar = ({
    downloadContentAction,
    exportAction,
    hasDistribute,
    hasDownloadContent,
    hasExport,
    limit,
    page,
    selected,
}) => {
    const dispatch = useDispatch();

    const location = useLocation();

    const { t } = useTranslation();

    const { user } = useAuth0();

    const {
        releases: { url: releasesRouteUrl },
        search: searchRouteUrl,
    } = urlsData.routes;

    const {
        button: buttonId,
        toolbar: toolbarId,
    } = identifiersData.ids;

    const {
        buttons: {
            distribute: distributeButtonName,
            download: downloadButtonName,
        },
        menus: { exportData: exportDataMenuName },
    } = namesData;

    const { mediaCreate: mediaCreatePermission } = permissionsData.permissions.keys;

    return (
        <Box>
            {(location?.pathname === releasesRouteUrl || location?.pathname === searchRouteUrl) && (
                <DistributionDrawer
                    ids={selected}
                    limit={limit}
                    page={page}
                    hasBulkDistributions
                />
            )}
            {selected?.length > 0 && (
                <StyledToolbar selected={selected}>
                    <Typography
                        component="span"
                        flex="1 1 100%"
                        variant="subtitle1"
                    >
                        {selected?.length}
                        {" "}
                        {t("labels.selected")}
                    </Typography>
                    <Box
                        alignItems="center"
                        display="flex"
                        gap={3}
                        justifyContent="flex-end"
                        minWidth={500}
                    >
                        {hasDownloadContent && (
                            <Button
                                id={`${toolbarId}_${downloadButtonName}_${buttonId}`}
                                size="small"
                                variant="outlined"
                                onClick={() => downloadContentAction(selected)}
                            >
                                <Box
                                    display="flex"
                                    gap={1}
                                >
                                    <FileDownloadIcon sx={{ fontSize: 20 }} />
                                    <Typography
                                        fontWeight="bold"
                                        variant="caption"
                                    >
                                        {t("actions.download")}
                                    </Typography>
                                </Box>
                            </Button>
                        )}
                        {hasDistribute && canDo(
                            user,
                            [mediaCreatePermission],
                        ) && (
                            <Button
                                id={`${toolbarId}_${distributeButtonName}_${buttonId}`}
                                size="small"
                                variant="outlined"
                                onClick={() => dispatch(toggleDistributionDrawer(true))}
                            >
                                <Box
                                    display="flex"
                                    gap={1}
                                >
                                    <PodcastsIcon sx={{ fontSize: 20 }} />
                                    <Typography
                                        fontWeight="bold"
                                        variant="caption"
                                    >
                                        {t("actions.distribute")}
                                    </Typography>
                                </Box>
                            </Button>
                        )}
                        {hasExport && (
                            <Menu
                                label={t("actions.exportData")}
                                name={exportDataMenuName}
                                options={[
                                    {
                                        action: () => exportAction(selected),
                                        label: t("labels.csv"),
                                    },
                                ]}
                                withTransparentButton
                            />
                        )}
                    </Box>
                </StyledToolbar>
            )}
        </Box>
    );
};
export default Toolbar;

Toolbar.propTypes = {
    downloadContentAction: PropTypes.func,
    exportAction: PropTypes.func,
    hasDistribute: PropTypes.bool,
    hasDownloadContent: PropTypes.bool,
    hasExport: PropTypes.bool,
    limit: PropTypes.number,
    page: PropTypes.number,
    selected: PropTypes.array,
};
