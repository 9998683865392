import inputData from "data/input";
import selectionsData from "data/selections";

const {
    clients: clientsSelectionKey,
    tracks: tracksSelectionKey,
} = selectionsData.keys;

const {
    autoComplete: autoCompleteInputType,
    text: textInputType,
} = inputData.types;

const {
    client: clientInputName,
    link: linkInputName,
    track: trackInputName,
} = inputData.names;

const {
    client: clientInputLabel,
    link: linkInputLabel,
    track: trackInputLabel,
} = inputData.labels;

const releaseClientLinkFormData = [
    {
        col: {
            md: 4,
            xs: 12,
        },
        drawerCol: { xs: 12 },
        label: linkInputLabel,
        name: linkInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        drawerCol: { xs: 12 },
        hasOffset: true,
        label: trackInputLabel,
        name: trackInputName,
        selectionKey: tracksSelectionKey,
        type: autoCompleteInputType,

    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        drawerCol: { xs: 12 },
        isAsyncMenu: true,
        label: clientInputLabel,
        name: clientInputName,
        selectionKey: clientsSelectionKey,
        type: autoCompleteInputType,
    },

];

export default releaseClientLinkFormData;
