import { useMutation } from "@apollo/client";
import {
    constantsData,
    identifiersData,
    namesData,
    statusTypesData,
} from "data";
import { deleteGroupMutation, getGroupsListQuery } from "graph";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useDeleteGroup = (
    page,
    limit,
    searchData,
) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [deleteGroupAction, { loading }] = useMutation(
        deleteGroupMutation,
        {
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: getGroupsListQuery,
                    variables: {
                        limit,
                        page,
                        where: {
                            ...searchData?.[0] && {
                                OR: [
                                    {
                                    group_name: { contains: searchData?.[0] }, // eslint-disable-line
                                    },
                                    {
                                    group_email: { contains: searchData?.[0] }, // eslint-disable-line
                                    },
                                ],
                            },
                        },
                    },
                },
            ],
        },
    );

    const { deleteGroupPending: deleteGroupPendingToastName } = namesData.toasts;

    const { toast: toastId } = identifiersData.ids;

    const deleteGroup = async (id) => {
        try {
            await deleteGroupAction({
                onCompleted: () => {
                    dispatch(openAlert(
                        t(
                            "messages.deleteMsgs.success",
                            { entity: t("groups.entity") },
                        ),
                        statusTypesData.success,
                    ));
                },

                variables: { id },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        } finally {
            toast.dismiss(`${deleteGroupPendingToastName}_${toastId}`);
        }
    };

    useEffect(
        () => {
            if (loading) {
                toast.info(
                    () => t(
                        "messages.deleteMsgs.pending",
                        { entity: t("groups.entity") },
                    ),
                    { toastId: `${deleteGroupPendingToastName}_${toastId}` },
                );
            }
        },
        [loading], //eslint-disable-line
    );

    return { deleteGroup };
};

export default useDeleteGroup;
