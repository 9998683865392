import { PageHead, ReleaseClientLinksList } from "components";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";

const ReleaseDetailsClientLinks = ({ data }) => {
    const { t } = useTranslation();

    return (
        <>
            <PageHead
                title={t("labels.clientLinks")}
                titleTag="h2"
            />
            <ReleaseClientLinksList data={data} />
        </>
    );
};

export default ReleaseDetailsClientLinks;

ReleaseDetailsClientLinks.propTypes = { data: PropTypes.object };
