import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

i18n.
    use(Backend).
    use(initReactI18next).
    init({
        backend: { loadPath: "/locales/{{lng}}/{{ns}}.json" },
        fallbackLng: "en",
        interpolation: { escapeValue: false },
        lng: "en",
        supportedLngs: ["en"],
        whitelist: ["en"],
    });

export default i18n;
