import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useVideoCutSchema = () => {
    const { t } = useTranslation();

    return yup.object({
        from: yup.string().required(t("messages.inputMsgs.required")),
        to: yup.string().required(t("messages.inputMsgs.required")),
        video: yup.string().required(t("messages.inputMsgs.required")),
    });
};

export default useVideoCutSchema;
