import {
    CLEAR_SEARCH_VALUES,
    DELETE_SEARCH_VALUE,
    SET_SEARCH_CATEGORY,
    SET_SEARCH_DATA,
    SET_SEARCH_VALUE,
    SET_SEARCH_VALUES,
} from "redux/types";
import { updateStateHandler } from "utils";

const initialState = {
    searchCategory: "",
    searchData: {},
    searchValues: [],
};

const setSearchValue = (
    state,
    value,
) => {
    const searchValuesList = [...state.searchValues];

    searchValuesList.push(value);

    return updateStateHandler(
        state,
        { searchValues: searchValuesList },
    );
};

const setSearchValues = (
    state,
    values,
) => updateStateHandler(
    state,
    { searchValues: values },
);

const deleteSearchValue = (
    state,
    index,
) => {
    const searchValuesList = [...state.searchValues];

    const filteredSearchValuesList = searchValuesList.filter((_, i) => i !== index);

    return updateStateHandler(
        state,
        { searchValues: filteredSearchValuesList },
    );
};

const clearSearchValues = (state) => updateStateHandler(
    state,
    { searchValues: [] },
);

const setSearchCategory = (state, category) => updateStateHandler(
    state,
    { searchCategory: category },
);

const setSearchData = (state, data) => updateStateHandler(
    state,
    { searchData: data },
);

const searchReducer = (state = initialState, action) => {
    const {
        payload,
        type,
    } = action;

    const {
        category,
        data,
        index,
        value,
        values,
    } = payload || {};

    switch (type) {
    case SET_SEARCH_VALUE: return setSearchValue(
        state,
        value,
    );
    case SET_SEARCH_VALUES: return setSearchValues(
        state,
        values,
    );
    case DELETE_SEARCH_VALUE: return deleteSearchValue(
        state,
        index,
    );
    case CLEAR_SEARCH_VALUES: return clearSearchValues(state);
    case SET_SEARCH_CATEGORY: return setSearchCategory(
        state,
        category,
    );
    case SET_SEARCH_DATA: return setSearchData(
        state,
        data,
    );
    default: return state;
    }
};

export default searchReducer;
