import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData } from "data";
import { createBulkDistributionsMutation } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert, toggleDistributionDrawer } from "redux/actions";
import { displayErrorHandler } from "utils";

const useCreateBulkDistributions = (ids) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [createBulkDistributionsAction, { loading }] = useMutation(createBulkDistributionsMutation);

    const createBulkDistributions = async ({
        client,
        fileType,
        releaseDate,
        territory,
    }) => {
        try {
            await createBulkDistributionsAction({
                onCompleted: () => {
                    dispatch(openAlert(
                        t("messages.distributionMsgs.bulk.success"),
                        statusTypesData.success,
                    ));

                    dispatch(toggleDistributionDrawer(false));
                },
                variables: {
                    clientId: client,
                    distributionFileTypeId: fileType,
                    releaseDate,
                    releasesIds: ids,
                    territoryId: territory,
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        createBulkDistributions,
        loading,
    };
};

export default useCreateBulkDistributions;
