import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useRevenueReportSchema = () => {
    const { t } = useTranslation();

    return yup.object({
        artist: yup.mixed(),
        label: yup.string().nullable(),
        release: yup.mixed(),
        revenue: yup.number().required(t("messages.inputMsgs.required")),
    });
};

export default useRevenueReportSchema;
