import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData } from "data";
import { cutVideoMutation, getVideoCutsListQuery } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useCutVideo = (videoId) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [cutVideoAction, { loading }] = useMutation(
        cutVideoMutation,
        {
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: getVideoCutsListQuery,
                    variables: { id: videoId },
                },
            ],
        },
    );

    const cutVideo = async ({
        from,
        to,
        video,
    }) => {
        try {
            await cutVideoAction({
                onCompleted: () => {
                    dispatch(openAlert(
                        t(
                            "messages.cutMsgs.success",
                            { entity: t("labels.video") },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: {
                    endTime: to,
                    id: video,
                    startTime: from,
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        cutVideo,
        loading,
    };
};

export default useCutVideo;
