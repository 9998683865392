import { useMutation } from "@apollo/client";
import {
    constantsData,
    identifiersData,
    namesData,
    statusTypesData,
    urlsData,
} from "data";
import { deleteArtistMutation, getArtistsListQuery, getSearchListQuery } from "graph";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useDeleteArtist = (
    page,
    limit,
    hasSearchMode,
    searchData,
    redirect,
) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { deleteArtistPending: deleteArtistPendingToastName } = namesData.toasts;

    const { toast: toastId } = identifiersData.ids;

    const [deleteArtistAction, { loading }] = useMutation(
        deleteArtistMutation,
        {
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: hasSearchMode ? getSearchListQuery : getArtistsListQuery,
                    variables: hasSearchMode ? {
                        entityId: parseInt(searchData?.category),
                        limit,
                        page,
                        searchValues: searchData?.values,
                    } : {
                        limit,
                    orderBy: { created_at: "desc" }, // eslint-disable-line
                        page,
                        where: {},
                    },
                },
            ],
        },
    );

    const deleteArtist = async (id) => {
        try {
            await deleteArtistAction({
                onCompleted: () => {
                    if (redirect) navigate(urlsData.routes.artists.url);

                    dispatch(openAlert(
                        t(
                            "messages.deleteMsgs.success",
                            { entity: t("artists.entity") },
                        ),
                        statusTypesData.success,
                    ));
                },

                variables: {
                    where: { id },
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        } finally {
            toast.dismiss(`${deleteArtistPendingToastName}_${toastId}`);
        }
    };

    useEffect(
        () => {
            if (loading) {
                toast.info(
                    () => t(
                        "messages.deleteMsgs.pending",
                        { entity: t("artists.entity") },
                    ),
                    { toastId: `${deleteArtistPendingToastName}_${toastId}` },
                );
            }
        },
        [loading], //eslint-disable-line
    );

    return { deleteArtist };
};

export default useDeleteArtist;
