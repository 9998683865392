import styled from "@emotion/styled";
import { Box } from "@mui/material";

const StyledModal = styled(Box)(({ theme }) => ({
    "@media(max-width: 700px) ": {
        left: "none",
        minWidth: 300,
        top: "50%",
    },
    background: theme.palette.grey[100],
    borderRadius: 5,
    borderTop: `5px solid ${theme.palette?.primary?.main}`,
    boxShadow: 24,
    left: "50%",
    marginInline: 2,
    minWidth: 400,
    p: 4,
    padding: 20,
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
}));

export default StyledModal;
