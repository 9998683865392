import { Box, Button, Typography } from "@mui/material";
import { identifiersData, namesData } from "data";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { theme } from "theme";

const DownloadBox = ({
    id,
    link,
    message,
}) => {
    const { t } = useTranslation();

    const { button: buttonId } = identifiersData.ids;

    const { download: downloadButtonName } = namesData.buttons;

    return (
        <Box>
            <Typography
                fontSize={14}
                variant="body1"
            >
                {message}
            </Typography>
            <Box
                display="flex"
                justifyContent="flex-end"
            >
                <a
                    href={`${window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_DOMAIN}${link}`} // eslint-disable-line
                    style={{
                        color: theme.palette.primary.main,
                        textDecoration: "none",
                    }}
                >
                    <Button
                        id={id ? `${id}_${downloadButtonName}_${buttonId}` : `${namesData.buttons.download}_${buttonId}`}
                        size="small"
                        variant="outlined"
                    >
                        {t("actions.download")}
                    </Button>
                </a>
            </Box>
        </Box>
    );
};

export default DownloadBox;

DownloadBox.propTypes = {
    id: PropTypes.string,
    link: PropTypes.string,
    message: PropTypes.string,
};
