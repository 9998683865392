import { Box, Slider } from "@mui/material";
import { identifiersData, namesData } from "data";
import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";
import { convertHourToDurationFormatHandler } from "utils";

const minDistance = 5;

const FilterRangeInput = ({
    defaultValue,
    fieldName,
    formMethods,
}) => {
    const currentValue = formMethods?.watch(fieldName);

    const [rangeInputValue, setRangeInputValue] = useState(currentValue ? [currentValue?.gte, currentValue?.lte] : defaultValue);

    const {
        classNames: { filterRangeInput: filterRangeInputClassName },
        ids: {
            slider: sliderId,
            thumb: thumbId,
            track: trackId,
        },
    } = identifiersData;

    const { filterRange: filterRangeSliderName } = namesData.sliders;

    const changeRangeInputHandler = (_, value, activeThumb) => {
        if (!Array.isArray(value)) return;

        if (activeThumb === 0) {
            setRangeInputValue(
                [
                    Math.min(
                        value[0],
                        rangeInputValue[1] - minDistance,
                    ),
                    rangeInputValue[1],
                ],
            );
        } else {
            setRangeInputValue(
                [
                    rangeInputValue[0],
                    Math.max(
                        value[1],
                        rangeInputValue[0] + minDistance,
                    ),
                ],
            );
        }
    };

    useEffect(
        () => {
            formMethods.setValue(
                fieldName,
                {
                    gte: rangeInputValue[0],
                    lte: rangeInputValue[1],
                },
                { shouldDirty: rangeInputValue[0] > defaultValue[0] || rangeInputValue[1] < defaultValue[1] },
            );
        },
        [rangeInputValue], //eslint-disable-line
    );

    useEffect(
        () => {
            if (currentValue?.gte === undefined && currentValue?.lte === undefined) setRangeInputValue(defaultValue);
        },
        [currentValue?.gte, currentValue?.lte], //eslint-disable-line
    );

    return (
        <Box
            alignItems="start"
            display="flex"
            gap={3}
            width={{
                md: 400,
                xs: 200,
            }}
        >
            <time>{convertHourToDurationFormatHandler(rangeInputValue[0])}</time>
            <Slider
                className={filterRangeInputClassName}
                max={defaultValue[1]}
                name={fieldName}
                value={rangeInputValue}
                slotProps={{
                    thumb: { id: `${filterRangeSliderName}_${sliderId}_${thumbId}` },
                    track: { id: `${filterRangeSliderName}_${sliderId}_${trackId}` },
                }}
                disableSwap
                onChange={changeRangeInputHandler}
            />
            <time>{convertHourToDurationFormatHandler(rangeInputValue[1])}</time>
        </Box>
    );
};

export default FilterRangeInput;

FilterRangeInput.propTypes = {
    defaultValue: PropTypes.array,
    fieldName: PropTypes.string,
    formMethods: PropTypes.object,
};
