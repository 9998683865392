import {
    Avatar,
    Box,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import PlayIcon from "assets/images/play-icon.jpg";
import { identifiersData, namesData, urlsData } from "data";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { StyledLink, StyledThumbnailVideoPlayer } from "styles";
import StyledChip from "styles/chip";

const ThumbnailVideoPlayer = ({
    artist,
    hasSelectMode,
    id,
    imgSrc,
    isTrackPage,
    rowId,
    selectVideoAction,
    selectedVideosIds,
    setCurrentVideo,
    track,
    type,
    url,
    videoCut,
    videoName,
}) => {
    const { t } = useTranslation();

    const location = useLocation();

    const {
        artists: { url: artistsRouteUrl },
        tracks: { url: tracksRouteUrl },
    } = urlsData.routes;

    const {
        classNames: { thumbnail: thumbnailClassName },
        ids: {
            button: buttonId,
            link: linkId,
            thumbnail: thumbnailId,
        },
    } = identifiersData;

    const {
        buttons: { playVideoPlayer: playVideoPlayerButtonName },
        links: {
            artist: artistLink,
            track: trackLink,
        },
    } = namesData;

    return (
        <StyledThumbnailVideoPlayer
            backgroundImg={imgSrc}
            hasSelectMode={+!!hasSelectMode}
            id={id}
            videos={selectedVideosIds}
            onClick={() => hasSelectMode && selectVideoAction(id)}
        >
            <Box
                className={thumbnailClassName}
                onClick={() => !hasSelectMode && setCurrentVideo({
                    artistName: artist.name,
                    trackName: isTrackPage ? videoName || videoCut?.name : track?.name,
                    url,
                })}
            >
                <Box>
                    <Tooltip title={`${isTrackPage ? videoName || videoCut?.name : track?.name} | ${artist?.name}`}>
                        <IconButton id={`${playVideoPlayerButtonName}_${buttonId}_${rowId}`}>
                            <Avatar
                                alt={t("imageAlternatives.playIcon")}
                                imgProps={{ loading: "lazy" }}
                                src={PlayIcon}
                            />
                        </IconButton>
                    </Tooltip>
                </Box>
                {type && (
                    <StyledChip
                        label={type}
                        status="completed"
                    />
                )}
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                gap={1}
                mt={1}
            >
                {isTrackPage ? (
                    <Typography
                        color="primary"
                        component="h6"
                        fontWeight="bold"
                        variant="body1"
                    >
                        {videoName || videoCut?.name}

                    </Typography>
                ) : (
                    <StyledLink
                        bold={+true}
                        id={`${thumbnailId}_${trackLink}_${linkId}`}
                        state={{ from: location.pathname + location.search }}
                        to={`${tracksRouteUrl}/${track?.id}`}
                    >
                        {track?.name}
                    </StyledLink>
                )}
            </Box>
            <StyledLink
                gray={+true}
                id={`${thumbnailId}_${artistLink}_${linkId}`}
                state={{ from: location.pathname + location.search }}
                to={`${artistsRouteUrl}/${artist?.id}`}
            >
                {artist?.name}
            </StyledLink>
        </StyledThumbnailVideoPlayer>
    );
};

export default ThumbnailVideoPlayer;

ThumbnailVideoPlayer.propTypes = {
    artist: PropTypes.object,
    hasSelectMode: PropTypes.bool,
    id: PropTypes.number,
    imgSrc: PropTypes.string,
    isTrackPage: PropTypes.bool,
    rowId: PropTypes.number,
    selectVideoAction: PropTypes.func,
    selectedVideosIds: PropTypes.array,
    setCurrentVideo: PropTypes.func,
    track: PropTypes.object,
    type: PropTypes.string,
    url: PropTypes.string,
    videoCut: PropTypes.object,
    videoName: PropTypes.string,
};
