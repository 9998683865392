import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { constantsData } from "data";
import { getGroupsListQuery } from "graph";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useGroupsList = () => {
    const [params] = useSearchParams();

    const pageParam = parseInt(params.get("page")) || 1;

    const limitParam = parseInt(params.get("limit")) || 25;

    const [page, setPage] = useState(pageParam);

    const [limit, setLimit] = useState(limitParam);

    const searchData = useSelector((state) => state.searchReducer.searchData);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const { isAuthenticated } = useAuth0();

    const {
        data,
        error,
        loading,
    } = useQuery(
        getGroupsListQuery,
        {
            skip: !isAuthenticated,
            variables: {
                limit,
                page: page - 1,
                where: {
                    ...searchData?.values?.[0] && {
                        OR: [
                            {
                                group_name: { contains: searchData?.values[0] }, // eslint-disable-line
                            },
                            {
                                group_email: { contains: searchData?.values[0] }, // eslint-disable-line
                            },
                        ],
                    },
                },
            },
        },
    );

    useEffect(
        () => {
            if (error?.message === constantsData.failedToFetch && !loading) {
                displayErrorHandler(
                    t("messages.networkFailure"),
                    dispatch,
                    openAlert,
                );
            }
        },
        [error, data], // eslint-disable-line
    );

    return {
        count: data?.listDriveGroups?.meta?.nodeCount,
        data: data?.listDriveGroups?.posts,
        error,
        limit,
        loading,
        page: data?.listDriveGroups?.meta?.currentPage,
        setLimit,
        setPage,
    };
};

export default useGroupsList;
