import {
    CREATE_TRACK,
    DELETE_TRACK,
    SET_CURRENT_PLAYED_TRACK,
    TOGGLE_TRACK_DRAWER,
} from "redux/types";
import { updateStateHandler } from "utils";

const initialState = {
    openDrawer: false,
    playedTrack: {},
    tracks: [],
};

const createTrack = (state, track) => updateStateHandler(
    state,
    { tracks: [...state.tracks, track] },
);

const deleteTracks = (state) => updateStateHandler(
    state,
    { tracks: [] },
);

const toggleTrackDrawer = (state, open) => updateStateHandler(
    state,
    { openDrawer: open },
);

const setCurrentPlayedTrack = (state, track) => updateStateHandler(
    state,
    { playedTrack: track },
);

const tracksReducer = (state = initialState, action) => {
    const {
        payload,
        type,
    } = action;

    const {
        open,
        track,
    } = payload || {};

    switch (type) {
    case TOGGLE_TRACK_DRAWER: return toggleTrackDrawer(
        state,
        open,
    );
    case CREATE_TRACK: return createTrack(
        state,
        track,
    );
    case DELETE_TRACK: return deleteTracks(state);
    case SET_CURRENT_PLAYED_TRACK: return setCurrentPlayedTrack(
        state,
        track,
    );
    default:
        return state;
    }
};

export default tracksReducer;
