import {
    Card as MuiCard,
    CardContent,
    CardMedia,
    Typography,
} from "@mui/material";
import { identifiersData } from "data";
import { PropTypes } from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { theme } from "theme";

const Card = ({
    description,
    imageData,
    link,
    rowId,
    title,
}) => {
    const location = useLocation();

    const {
        card: cardId,
        link: linkId,
    } = identifiersData.ids;

    return (
        <Link
            id={`${cardId}_${linkId}_${rowId}`}
            state={{ from: location.pathname + location.search }}
            style={{ textDecoration: "none" }}
            to={link}
        >
            <MuiCard
                sx={{
                    "&:hover": { boxShadow: `${theme.palette.grey[901]} 0px 5px 15px` },
                    margin: "auto",
                    maxWidth: 240,
                    textAlign: "center",
                    transition: ".5s all",
                }}
            >
                <CardMedia
                    alt={imageData.alt}
                    component="img"
                    height="240"
                    src={`${imageData.src}`}
                />
                <CardContent>
                    <Typography
                        variant="h6"
                        gutterBottom
                    >
                        {title}
                    </Typography>
                    {description}
                </CardContent>
            </MuiCard>
        </Link>
    );
};

export default Card;

Card.propTypes = {
    description: PropTypes.node,
    imageData: PropTypes.object,
    link: PropTypes.string,
    rowId: PropTypes.number,
    title: PropTypes.string,
};
