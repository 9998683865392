import { Grid } from "@mui/material";
import { DialogVideoPlayer, ThumbnailVideoPlayer } from "atoms";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

const VideosList = ({
    data,
    hasSelectMode,
    page,
    selectedVideosIds,
    setSelectedVideosIds,
}) => {
    const [currentVideo, setCurrentVideo] = useState({
        artistName: "",
        trackName: "",
        url: null,
    });

    const navigate = useNavigate();

    const location = useLocation();

    const options = {
        pathname: location.pathname,
        search: createSearchParams({ page: page + 1 }).toString(),
    };

    const selectVideoHandler = (id) => {
        let clonedSelectedVideosIds = [...selectedVideosIds];

        if (clonedSelectedVideosIds.find((videoId) => videoId === id)) {
            const filteredSelectedIds = clonedSelectedVideosIds.filter((videoId) => videoId !== id);

            clonedSelectedVideosIds = filteredSelectedIds;
        } else clonedSelectedVideosIds.push(id);

        setSelectedVideosIds(clonedSelectedVideosIds);
    };

    useEffect(
        () => {
            if (page + 1) {
                navigate(
                    options,
                    { replace: true },
                );
            }
        },
        [page], // eslint-disable-line
    );

    return (
        <>
            <DialogVideoPlayer
                currentVideo={currentVideo}
                setCurrentVideo={setCurrentVideo}
            />
            <Grid container>
                {data?.map(({
                    downloadUri,
                    id,
                    image,
                    release,
                    track,
                    type,
                }, index) => (
                    <Grid
                        key={id}
                        lg={3}
                        mb={5}
                        md={4}
                        sm={6}
                        xs={12}
                        item
                    >
                        <ThumbnailVideoPlayer
                            hasSelectMode={hasSelectMode}
                            id={id}
                            imgSrc={image?.downloadThumbnail || track?.image?.downloadThumbnail || release?.image?.downloadThumbnail}
                            rowId={index + 1}
                            selectVideoAction={selectVideoHandler}
                            selectedVideosIds={selectedVideosIds}
                            setCurrentVideo={setCurrentVideo}
                            type={type.name}
                            url={downloadUri}
                            artist={{
                                id: track?.artist?.id,
                                name: track?.artist?.name,
                            }}
                            track={{
                                id: track?.id,
                                name: track?.title,
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default VideosList;

VideosList.propTypes = {
    data: PropTypes.array,
    hasSelectMode: PropTypes.bool,
    page: PropTypes.number,
    selectedVideosIds: PropTypes.array,
    setSelectedVideosIds: PropTypes.func,
};
