import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { constantsData, importerConfigData, selectionsData } from "data";
import { getArtistsListQuery } from "graph";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { openAlert, setSelection } from "redux/actions";
import { displayErrorHandler } from "utils";

const useArtistsList = (
    isPaginated = true,
    hasAutoCompleteMenu = false,
    filters = {},
    importerData,
    searchValue,
) => {
    const [cancelRequest, setCancelRequest] = useState(false);

    const [fetched, setFetched] = useState(false);

    const [params] = useSearchParams();

    const pageParam = parseInt(params.get("page")) || 1;

    const limitParam = parseInt(params.get("limit")) || 25;

    const [page, setPage] = useState(pageParam);

    const [limit, setLimit] = useState(limitParam);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const selections = useSelector((state) => state.selectionsReducer);

    const { isAuthenticated } = useAuth0();

    const { artists: artistsKey } = selectionsData.keys;

    const {
        data,
        error,
        loading,
        refetch,
    } = useQuery(
        getArtistsListQuery,
        {
            onCompleted: ({ listArtists }) => {
                if (!isPaginated || hasAutoCompleteMenu) {
                    const fetchedList = listArtists?.posts?.map(({
                        code,
                        id,
                        name,
                        name_ar,
                    }) => ({
                        code,
                        label: name,
                        nameAr: name_ar, //eslint-disable-line
                        value: id,
                    }));

                    dispatch(setSelection({
                        [artistsKey]: selections?.[artistsKey]?.length > 0 && hasAutoCompleteMenu ? selections?.[artistsKey].concat(fetchedList) : fetchedList,
                    }));
                }

                setFetched(true);

                if (importerData) importerData?.setCurrentStep(null);
            },
            ...hasAutoCompleteMenu && { notifyOnNetworkStatusChange: true },
            skip: !isAuthenticated || (importerData && importerData.codesList?.length === 0 && importerData.namesList?.length === 0) || cancelRequest,
            variables: {
                ...isPaginated && {
                    limit,
                    page: page - 1,
                },
                orderBy: { created_at: "desc" }, // eslint-disable-line
                where: {
                    ...!importerData && Object.keys(filters).length > 0 && { ...filters },
                    ...importerData && {
                        OR: [
                            {
                                ...importerData && {
                                    code: { in: importerData.codesList },
                                },
                            },
                            {
                                ...importerData && {
                                    name: { in: importerData.namesList },
                                },
                            },
                        ],
                    },
                    ...searchValue && {
                        name: { contains: searchValue },
                    },
                },
            },
        },
    );

    useEffect(
        () => {
            if (error?.message === constantsData.failedToFetch && !loading) {
                if (importerData) {
                    dispatch(importerData?.setInvalidImporterRelease(
                        {
                            isNetworkFailed: true,
                            originalData: importerData?.originalData,
                            release: {
                                data: importerData?.release,
                                message: t("messages.networkFailure"),
                            },
                        },
                    ));
                } else {
                    displayErrorHandler(
                        t("messages.networkFailure"),
                        dispatch,
                        openAlert,
                    );
                }
            }
        },
        [error, data], // eslint-disable-line
    );

    useEffect(
        () => {
            if (importerData) if (importerData.codesList?.length > 0 && importerData.namesList?.length > 0) importerData?.setCurrentStep(importerConfigData.steps.searchingArtists);
        },
        [importerData?.codesList, importerData?.namesList], // eslint-disable-line
    );

    useEffect(
        () => {
            if (importerData?.stopping) setCancelRequest(false);
        },
        [importerData?.stopping],
    );

    useEffect(
        () => {
            dispatch(setSelection({ [artistsKey]: [] }));
        },
        [], // eslint-disable-line
    );

    return {
        cancelRequest: () => setCancelRequest(true),
        count: data?.listArtists?.meta?.nodeCount,
        data: data?.listArtists.posts,
        error,
        fetched,
        limit,
        loading,
        page: data?.listArtists?.meta?.currentPage,
        refetch,
        searchKey: "name",
        setFetched,
        setLimit,
        setPage,
    };
};

export default useArtistsList;
