import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { identifiersData } from "data";
import { PropTypes } from "prop-types";
import { StyledTextField } from "styles";

const TimeInput = ({
    errors,
    field,
    label,
    name,
    onChange,
    value,
}) => (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
            inputFormat="hh:mm:ss"
            label={label}
            views={["hours", "minutes", "seconds"]}
            renderInput={(params) => (
                <StyledTextField
                    {...params}
                    error={!!errors?.[name]}
                    helperText={errors?.[name] && errors?.[name]?.message}
                    id={`${name}_${identifiersData.ids.input}`}
                    sx={{
                        input: { opacity: !value && 0.3 },
                    }}
                    fullWidth
                />
            )}
            disableOpenPicker
            {...field}
            {...onChange && { onChange }}
            value={value || new Date(new Date().setHours(
                13,
                0,
                0,
                0,
            ))}
        />
    </LocalizationProvider>
);
export default TimeInput;

TimeInput.propTypes = {
    errors: PropTypes.object,
    field: PropTypes.object,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
};
