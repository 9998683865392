import { useAuth0 } from "@auth0/auth0-react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LogoutIcon from "@mui/icons-material/Logout";
import {
    Avatar,
    Box,
    Button,
    Menu,
    Typography,
    Zoom,
} from "@mui/material";
import { MenuItem } from "atoms";
import { identifiersData, namesData } from "data";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { theme } from "theme";
import { logoutHandler } from "utils";

const UserMenu = ({ withoutUsername }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const { t } = useTranslation();

    const {
        logout,
        user,
    } = useAuth0();

    const open = Boolean(anchorEl);

    const {
        button: buttonId,
        menu: menuId,
        option: optionId,
    } = identifiersData.ids;

    const { user: userMenuName } = namesData.menus;

    const openMenuHandler = (event) => setAnchorEl(event.currentTarget);

    const closeMenuHandler = () => setAnchorEl(null);

    return (
        <Box>
            <Button
                id={`${userMenuName}_${menuId}_${buttonId}`}
                size="medium"
                variant="outlined"
                endIcon={(
                    <KeyboardArrowDownIcon
                        sx={{
                            transform: open && "rotate(180deg)",
                            transition: "ease-in-out .2s",
                        }}
                    />
                )}
                sx={{
                    "&:hover": { border: "none" },
                    border: "none",
                }}
                onClick={openMenuHandler}
            >
                <Box
                    alignItems="center"
                    display="flex"
                    gap={1}
                >
                    <Avatar
                        alt={t("imageAlternatives.user")}
                        imgProps={{ loading: "lazy" }}
                        src={user?.picture}
                        sx={{
                            height: 30,
                            width: 30,
                        }}
                    />
                    <Box
                        alignItems="center"
                        display="flex"
                    >
                        <Typography
                            color={theme.palette.primary.main}
                            fontWeight="bold"
                            variant="caption"
                            sx={{
                                display: {
                                    md: "initial",
                                    xs: withoutUsername && "none",
                                },
                            }}
                        >
                            {user?.name}
                        </Typography>
                    </Box>
                </Box>
            </Button>
            <Menu
                TransitionComponent={Zoom}
                anchorEl={anchorEl}
                open={open}
                sx={{
                    marginTop: 1,
                    width: "100%",
                }}
                onClose={closeMenuHandler}
            >
                <Box
                    id={`${userMenuName}_${menuId}`}
                    onClick={closeMenuHandler}
                />
                <MenuItem
                    icon={<LogoutIcon />}
                    id={`${userMenuName}_${menuId}_${optionId}`}
                    label={t("logout.name")}
                    onClick={() => {
                        closeMenuHandler();

                        logoutHandler(logout);
                    }}
                />
            </Menu>
        </Box>
    );
};

export default UserMenu;

UserMenu.propTypes = { withoutUsername: PropTypes.bool };
