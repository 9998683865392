import { useMutation } from "@apollo/client";
import {
    constantsData,
    importerConfigData,
    statusTypesData,
    urlsData,
} from "data";
import { createReleaseMutation } from "graph";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useCreateRelease = (withoutRedirection, withoutAlert) => {
    const [controller, setController] = useState(null);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [createReleaseAction, {
        data,
        loading,
    }] = useMutation(createReleaseMutation);

    const createRelease = async ({
        albumCode,
        discNumber,
        endDate,
        genre,
        imageFile,
        isCompleted,
        nameAr,
        nameEn,
        primaryArtist,
        releaseDate,
        releaseType,
        rightType,
        upc,
    }, importerData) => {
        try {
            const newController = new AbortController();

            setController(newController);

            importerData?.setCurrentStep(importerConfigData.steps.creatingRelease);

            await createReleaseAction({
                context: {
                    fetchOptions: { signal: newController.signal },
                },
                onCompleted: () => {
                    if (!withoutRedirection) navigate(urlsData.routes.releases.url);

                    if (!withoutAlert) {
                        dispatch(openAlert(
                            t(
                                "messages.createMsgs.success",
                                { entity: t("releases.entity") },
                            ),
                            statusTypesData.success,
                        ));
                    }

                    if (importerData) {
                        if (!importerData?.release?.ownership && !importerData?.release?.note) {
                            importerData.setStatus((prev) => ({
                                ...prev,
                                release: {
                                    ...prev.release,
                                    createReleaseSuccess: true,
                                    loading: false,
                                    success: true,
                                },
                            }));
                        } else {
                            importerData.setStatus((prev) => ({
                                ...prev,
                                release: {
                                    ...prev.release,
                                    createReleaseSuccess: true,
                                },
                            }));
                        }
                    }
                },
                variables: {
                    data: {
                        album_code: parseInt(albumCode), //eslint-disable-line
                        album_name: nameEn, //eslint-disable-line
                        album_name_ar: nameAr, //eslint-disable-line
                        artist: {
                            connect: { id: primaryArtist },
                        },
                        ...endDate && { end_date: endDate }, //eslint-disable-line
                        ...genre && {
                            genre: {
                                connect: { id: genre },
                            },
                        },
                        ...upc && { upc },
                        ...imageFile && {
                            image: {
                                connect: { id: imageFile },
                            },
                        },
                        releaseType: {
                            connect: { id: releaseType },
                        },
                        release_date: releaseDate, //eslint-disable-line
                        rightType: {
                            connect: { id: rightType },
                        },
                        ...discNumber && { disc_number: parseInt(discNumber) }, //eslint-disable-line
                        isCompleted: isCompleted || true,
                    },
                },
            });
        } catch (err) {
            if (!withoutAlert) {
                displayErrorHandler(
                    err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                    dispatch,
                    openAlert,
                );
            }

            if (importerData) {
                dispatch(importerData?.setInvalidImporterRelease(
                    {
                        originalData: importerData?.originalData,
                        release: {
                            data: importerData?.release,
                            message: err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                        },
                        ...err.message === constantsData.failedToFetch && { isNetworkFailed: true },
                    },
                ));

                importerData?.setStatus((prev) => ({
                    ...prev,
                    release: {
                        ...prev.release,
                        loading: false,
                        success: false,
                    },
                }));
            }
        } finally {
            setController(null);

            if (importerData) importerData?.setCurrentStep(null);
        }
    };

    return {
        cancelRequest: () => controller && controller.abort(),
        createRelease,
        data,
        loading,
    };
};

export default useCreateRelease;
