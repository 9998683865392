import {
    Avatar, Box, Button, TableRow,
} from "@mui/material";
import { Loader, Modal, TableList } from "atoms";
import { PageHead } from "components";
import {
    actionsData, identifiersData, tableHeadCellsData, urlsData,
} from "data";
import { useDriveManagementDrivesList } from "hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StyledTableCell } from "styles";
import { convertDateToLocaleStringFormatHandler } from "utils";

const DriveManagementDrivesList = () => {
    const { t } = useTranslation();

    const { loading } = useDriveManagementDrivesList();

    const navigate = useNavigate();

    const data = useSelector((state) => state.driveManagementReducer).drivesList;

    const [modalData, setModalData] = useState({
        actionType: actionsData["delete"],
        body: t(
            "messages.deleteMsgs.alert",
            { entity: t("labels.drive") },
        ),
        id: null,
        open: false,
        title: `${t("actions.delete")} ${t("labels.drive")}`,
    });

    const {
        drives: driveManagementDrivesRouteUrl,
        url: driveManagementRouteUrl,
    } = urlsData.routes.driveManagement;

    const {
        link: linkId,
        table: tableId,
    } = identifiersData.ids;

    if (loading || !data) return <Loader />;

    return (
        <>
            <Modal
                modalData={modalData}
                setModalData={setModalData}
            />
            <PageHead title={t("driveManagement.sharedDrives")} />
            <TableList
                headCells={tableHeadCellsData.driveManagement.drives}
                isPaginated={false}
                type="files"
            >
                {data?.map(({
                    backgroundImageLink,
                    createdTime,
                    id,
                    name,
                }, index) => (
                    <TableRow
                        key={id}
                        sx={{ height: 50 }}
                    >
                        <StyledTableCell boldCell={+true}>
                            <Button
                                id={`${tableId}_${linkId}_${index + 1}`}
                                onClick={() => navigate(`${driveManagementRouteUrl}${driveManagementDrivesRouteUrl}/${id}`)}
                            >
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    gap={2}
                                >
                                    <Avatar
                                        alt={t("imageAlternatives.drive")}
                                        imgProps={{ loading: "lazy" }}
                                        src={backgroundImageLink}
                                        sx={{
                                            borderRadius: 1,
                                            height: 25,
                                            width: 25,
                                        }}
                                    />
                                    {name}
                                </Box>
                            </Button>
                        </StyledTableCell>
                        <StyledTableCell>
                            <time>{convertDateToLocaleStringFormatHandler(createdTime)}</time>
                        </StyledTableCell>
                    </TableRow>

                ))}
            </TableList>
        </>
    );
};

export default DriveManagementDrivesList;
