import { useAuth0 } from "@auth0/auth0-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import {
    AudioRangeInput,
    AutoCompleteMenu,
    FileLink,
    SkeletonLoader,
} from "atoms";
import { FormContainer } from "components";
import { inputData, permissionsData, releaseSoundRecordingFormData } from "data";
import { useDeleteReleaseSoundRecording } from "hooks";
import { PropTypes } from "prop-types";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useReleaseSoundRecordingSchema } from "schemas";
import { canDo, renderFormControllerHandler } from "utils";

const ReleaseSoundRecordingForm = ({
    action,
    loading,
    onSubmitForm,
    setTrackId,
    trackId,
    trackLoading,
    values,
}) => {
    const tracksList = useSelector((state) => state?.releasesReducer)?.releaseTracks;

    const { user } = useAuth0();

    const schema = useReleaseSoundRecordingSchema();

    const { deleteReleaseSoundRecording } = useDeleteReleaseSoundRecording(trackId);

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const {
        control,
        formState: { errors },
        watch,
    } = formMethods;

    const {
        mediaCreate: mediaCreatePermission,
        mediaDelete: mediaDeletePermission,
        mediaRead: mediaReadPermission,
    } = permissionsData.permissions.keys;

    const allowedPermissions = [mediaCreatePermission, mediaReadPermission];

    const { track: trackInputName } = inputData.names;

    const tracksOptions = tracksList?.map(({
        downloadUri,
        id,
        recordings,
        title,
    }) => ({
        label: title,
        recordings,
        trackFile: downloadUri,
        value: id,
    }));

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        type,
        isOptional,
    ) => {
        if (type === inputData.types.range) {
            return (
                <AudioRangeInput
                    name={name}
                    setValue={formMethods.setValue}
                    url={values?.downloadUrl}
                />
            );
        }

        return (
            <AutoCompleteMenu
                errors={errors}
                getRenderedOption={(option) => option?.label}
                isOptional={isOptional}
                label={label}
                name={name}
                optionLabel={(option) => option?.label || ""}
                options={tracksOptions}
                onChange={(_, option) => {
                    if (option) field.onChange(option.value);
                    else field.onChange("");
                }}
            />
        );
    };

    useEffect(
        () => {
            setTrackId(watch(trackInputName));
        },
        [watch(trackInputName)], // eslint-disable-line
    );

    return (
        <FormContainer
            action={action}
            formMethods={formMethods}
            loading={loading}
            data={renderFormControllerHandler(
                control,
                releaseSoundRecordingFormData,
                renderFormControllerChildrenHandler,
            )}
            hasSubmitButton={canDo(
                user,
                allowedPermissions,
            )}
            otherData={(
                <Grid
                    spacing={2}
                    container
                >
                    <Grid
                        md={4}
                        xs={12}
                        item
                    >
                        {trackLoading ? <SkeletonLoader /> : values?.soundRecordings?.length > 0 && values?.soundRecordings?.map(({
                            downloadUri,
                            id,
                            name: fileName,
                        }, index) => (
                            <FileLink
                                deleteAction={() => deleteReleaseSoundRecording(id)}
                                key={id}
                                link={downloadUri}
                                name={fileName}
                                rowId={index + 1}
                                hasDelete={canDo(
                                    user,
                                    [mediaDeletePermission],
                                )}
                                hasDownload
                            />
                        ))}
                    </Grid>
                </Grid>
            )}
            onSubmitForm={onSubmitForm}
        />
    );
};

export default ReleaseSoundRecordingForm;

ReleaseSoundRecordingForm.propTypes = {
    action: PropTypes.string,
    loading: PropTypes.bool,
    onSubmitForm: PropTypes.func,
    setTrackId: PropTypes.func,
    trackId: PropTypes.number,
    trackLoading: PropTypes.bool,
    values: PropTypes.object,
};
