import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useDynamicXMLSchema = () => {
    const { t } = useTranslation();

    return yup.object({
        client: yup.string().required(t("messages.inputMsgs.required")),
        release: yup.string().required(t("messages.inputMsgs.required")),
        releaseDate: yup.string().required(t("messages.inputMsgs.required")),
        territory: yup.string().required(t("messages.inputMsgs.required")),
        xmlFields: yup.object(),
        xmlFile: yup.mixed().required(t("messages.inputMsgs.required")),
    });
};

export default useDynamicXMLSchema;
