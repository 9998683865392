import styled from "@emotion/styled";
import { Card } from "@mui/material";
import { identifiersData } from "data";

const StyledFormContainer = styled(
    Card,
    { shouldForwardProp: (prop) => prop !== identifiersData.props.withoutShadow },
)(({
    theme,
    withoutShadow,
}) => ({
    "& .MuiCardContent-root": { padding: 0 },
    borderRadius: "16px",
    boxShadow: withoutShadow ? "none" : theme?.shape?.boxShadow,
    marginBlock: "16px",
    paddingBlock: "20px",
    paddingInline: "16px",
    position: "relative",
}));

export default StyledFormContainer;
