import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { identifiersData } from "data";
import { PropTypes } from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/actions";
import { StyledTextField } from "styles";
import { displayErrorHandler } from "utils";

const Calendar = ({
    errors,
    field,
    hasSmallField,
    hasTime,
    isFilterInput,
    isOptional,
    label,
    name,
    onChange,
    value,
    withoutLabelTranslation,
}) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    let inputLabel = "";

    const {
        classNames: {
            smallField: smallFieldClassName,
            withoutPosition: withoutPositionClassName,
        },
        ids: { input: inputId },
    } = identifiersData;

    useEffect(
        () => {
            if (isFilterInput && !!errors?.[name]) {
                displayErrorHandler(
                    errors?.[name]?.message,
                    dispatch,
                    openAlert,
                );
            }
        },
        [errors], //eslint-disable-line
    );

    if (withoutLabelTranslation) inputLabel = label;
    else inputLabel = t(`labels.${label}`);

    if (hasTime) {
        return (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                    inputFormat="dd/MM/yyyy hh:mm  a"
                    label={isOptional ? `${inputLabel} (${t("labels.optional")})` : inputLabel}
                    renderInput={(params) => (
                        <StyledTextField
                            {...params}
                            className={hasSmallField && `${smallFieldClassName} ${withoutPositionClassName}`}
                            error={!!errors?.[name]}
                            helperText={(errors?.[name] && !isFilterInput) && errors?.[name]?.message}
                            id={`${name}_${inputId}`}
                            isCalendar={+true}
                            name={name}
                            fullWidth
                        />
                    )}
                    {...field}
                    {...onChange && { onChange }}
                    value={value}
                />
            </LocalizationProvider>
        );
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                PopperProps={{ placement: "auto" }}
                inputFormat="dd/MM/yyyy"
                label={isOptional ? `${inputLabel} (${t("labels.optional")})` : inputLabel}
                renderInput={(params) => (
                    <StyledTextField
                        {...params}
                        className={hasSmallField && `${smallFieldClassName} ${withoutPositionClassName}`}
                        error={!!errors?.[name]}
                        helperText={(errors?.[name] && !isFilterInput) && errors?.[name]?.message}
                        id={`${name}_${inputId}`}
                        isCalendar={+true}
                        name={name}
                        fullWidth
                    />
                )}
                {...field}
                {...onChange && { onChange }}
                value={value}
            />
        </LocalizationProvider>
    );
};

export default Calendar;

Calendar.propTypes = {
    errors: PropTypes.object,
    field: PropTypes.object,
    hasSmallField: PropTypes.bool,
    hasTime: PropTypes.bool,
    isFilterInput: PropTypes.bool,
    isOptional: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
    ]),
    withoutLabelTranslation: PropTypes.bool,
};
