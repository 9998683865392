import { useAuth0 } from "@auth0/auth0-react";
import { constantsData, urlsData } from "data";
import { useApisClient } from "hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useGetDriveManagementFilesSessionsUrls = () => {
    const [sessionsUrls, setSessionsUrls] = useState([]);

    const [loading, setLoading] = useState(false);

    const [finishRequest, setFinishRequest] = useState(false);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { logout } = useAuth0();

    const { post } = useApisClient();

    const filesRequests = [];

    const getDriveManagementFilesSessionsUrls = async (files, parentId) => {
        setSessionsUrls([]);

        setFinishRequest(false);

        setLoading(true);

        files.forEach(({
            name,
            type,
        }) => {
            filesRequests.push(
                post(
                    urlsData.apis.driveManagement.files.session,
                    null,
                    {
                        mimeType: type,
                        name,
                        parents: [parentId],
                    },
                ),
            );

            Promise.all(filesRequests).
                then((data) => setSessionsUrls(data))["catch"]((err) => {
                    displayErrorHandler(
                        err.response.data.message === constantsData.networkError ? t("messages.networkFailure") : err.response.data.message,
                        dispatch,
                        openAlert,
                        err.response.status,
                        logout,
                    );
                })["finally"](() => {
                    setLoading(false);

                    setFinishRequest(true);
                });
        });
    };

    return {
        finishRequest,
        getDriveManagementFilesSessionsUrls,
        loading,
        sessionsUrls,
    };
};

export default useGetDriveManagementFilesSessionsUrls;
