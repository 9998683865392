import { yupResolver } from "@hookform/resolvers/yup";
import { AutoCompleteMenu, Loader } from "atoms";
import { FormContainer } from "components";
import { actionsData, downloadReleaseClientXmlFileFormData, selectionsData } from "data";
import {
    useClientsList,
    useDistributionFileTypesSelectionList,
    useDownloadReleaseClientXmlFile,
    useTerritoriesList,
    useXmlFileTypesSelectionList,
} from "hooks";
import { PropTypes } from "prop-types";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useDownloadReleaseClientXmlFileSchema } from "schemas";
import { renderFormControllerHandler } from "utils";

const DownloadReleaseClientXmlFileForm = ({ setModalData }) => {
    const selections = useSelector((state) => state.selectionsReducer);

    const schema = useDownloadReleaseClientXmlFileSchema();

    const { id } = useParams();

    const formMethods = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const { loading: distributionFileTypesSelectionListLoading } = useDistributionFileTypesSelectionList();

    const { loading: xmlFileTypesSelectionListLoading } = useXmlFileTypesSelectionList();

    const {
        downloadReleaseClientXmlFile,
        loading,
    } = useDownloadReleaseClientXmlFile(parseInt(id));

    const {
        count: clientsCount,
        fetched: clientsFetched,
        loading: clientsLoading,
        page: clientsPage,
        refetch: clientsRefetch,
        searchKey: clientsSearchKey,
    } = useClientsList(
        true,
        true,
    );

    const {
        count: territoriesCount,
        fetched: territoriesFetched,
        loading: territoriesLoading,
        page: territoriesPage,
        refetch: territoriesRefetch,
        searchKey: territoriesSearchKey,
    } = useTerritoriesList(
        true,
        true,
    );

    const {
        control,
        formState: { errors },
    } = formMethods;

    const asyncMenus = {
        clients: {
            count: clientsCount,
            loading: clientsLoading,
            page: clientsPage,
            refetch: clientsRefetch,
            searchKey: clientsSearchKey,
            selectionKey: selectionsData.keys.clients,
        },
        territories: {
            count: territoriesCount,
            loading: territoriesLoading,
            page: territoriesPage,
            refetch: territoriesRefetch,
            searchKey: territoriesSearchKey,
            selectionKey: selectionsData.keys.territories,
        },
    };

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        __,
        ___,
        selectionKey,
        isAsyncMenu,
    ) => (
        <AutoCompleteMenu
            asyncMenuData={asyncMenus?.[selectionKey]}
            errors={errors}
            getRenderedOption={(option) => option.label}
            isAsyncMenu={isAsyncMenu}
            label={label}
            name={name}
            optionLabel={(option) => option.label}
            options={selections?.[selectionKey]}
            onChange={(_, option) => {
                if (option)field.onChange(option.value);
                else field.onChange("");
            }}
        />
    );

    if ((clientsLoading && !clientsFetched) || (territoriesLoading && !territoriesFetched) || distributionFileTypesSelectionListLoading || xmlFileTypesSelectionListLoading) return <Loader withoutFullHeight />;

    return (
        <FormContainer
            action={actionsData.download}
            formMethods={formMethods}
            loading={loading}
            setModalData={setModalData}
            data={renderFormControllerHandler(
                control,
                downloadReleaseClientXmlFileFormData,
                renderFormControllerChildrenHandler,
            )}
            hasCancelButton
            withoutShadow
            onSubmitForm={downloadReleaseClientXmlFile}
        />
    );
};

export default DownloadReleaseClientXmlFileForm;

DownloadReleaseClientXmlFileForm.propTypes = { setModalData: PropTypes.string };
