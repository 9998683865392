const tableHeadCellsData = {
    artists: [
        {
            id: 0,
            key: "select",
        },
        {
            id: 1,
            key: "image",
        },
        {
            id: 2,
            key: "name",
        },
        {
            id: 3,
            key: "nameAr",
        },
        {
            id: 4,
            key: "code",
        },
        {
            id: 5,
            key: "nationality",
        },
        {
            id: 6,
            key: "numOfReleases",
        },
        {
            id: 7,
            key: "numOfTracks",
        },
        {
            id: 8,
            key: "actions",
        },
    ],
    clients: {
        list: [
            {
                id: 1,
                key: "name",
            },
            {
                id: 2,
                key: "ftpUsername",
            },
            {
                id: 3,
                key: "ftpHost",
            },
            {
                id: 4,
                key: "ftpPort",
            },
            {
                id: 5,
                key: "actions",
            },
        ],
        revenueReportsTemplates: [
            {
                id: 1,
                key: "templateName",
            },

            {
                alignment: "right",
                id: 2,
                key: "actions",
            },
        ],
    },
    driveLinks: [
        {
            id: 1,
            key: "resourceName",
        },
        {
            id: 2,
            key: "driveLink",
        },
        {
            id: 3,
            key: "actions",
        },
    ],
    driveManagement: {
        drives: [
            {
                id: 1,
                key: "name",
            },
            {
                id: 2,
                key: "createdAt",
            },
        ],
        files: [
            {
                id: 1,
                key: "name",
            },
            {
                alignment: "center",
                id: 2,
                key: "fileSize",
            },
            {
                id: 3,
                key: "lastModified",
            },
            {
                id: 4,
                key: "actions",
            },
        ],
    },
    groups: {
        list: [
            {
                id: 1,
                key: "name",
            },
            {
                alignment: "center",
                id: 2,
                key: "email",
            },
            {
                id: 3,
                key: "members",
            },
            {
                id: 4,
                key: "actions",
            },
        ],
        members: [
            {
                id: 1,
                key: "email",
            },
            {
                alignment: "right",
                id: 3,
                key: "actions",
            },
        ],
    },
    releases: {
        clientLinks: {
            list: [
                {
                    id: 1,
                    key: "link",
                },
                {
                    id: 2,
                    key: "client",
                },
                {
                    id: 3,
                    key: "track",
                },
                {
                    id: 4,
                    key: "actions",
                },
            ],
            results: [
                {
                    id: 1,
                    key: "clientName",
                },
                {
                    id: 2,
                    key: "link",
                },
                {
                    id: 3,
                    key: "upc",
                },
                {
                    id: 4,
                    key: "message",
                },
            ],
        },
        distributions: [
            {
                id: 1,
                key: "release",
            },
            {
                id: 2,
                key: "client",
            },
            {
                id: 3,
                key: "processedOn",
            },
            {
                id: 4,
                key: "duration",
            },
            {
                id: 5,
                key: "status",
            },
            {
                id: 6,
                key: "progress",
            },
            {
                id: 7,
                key: "actions",
            },

        ],
        importer: {
            completedReleasesTab: [
                {
                    id: 1,
                    key: "albumName",
                },
                {
                    id: 2,
                    key: "primaryArtist",
                },
                {
                    id: 3,
                    key: "releaseType",
                },
                {
                    id: 4,
                    key: "releaseDate",
                },
                {
                    id: 5,
                    key: "upc",
                },
                {
                    id: 6,
                    key: "genre",
                },

            ],
            invalidReleasesTab: [
                {
                    id: 1,
                    key: "open",
                },
                {
                    id: 2,
                    key: "albumName",
                },
                {
                    id: 3,
                    key: "primaryArtist",
                },
                {
                    id: 4,
                    key: "releaseType",
                },
                {
                    id: 5,
                    key: "releaseDate",
                },
                {
                    id: 6,
                    key: "upc",
                },
                {
                    id: 7,
                    key: "genre",
                },
                {
                    id: 8,
                    key: "actions",
                },

            ],
            invalidReleasesTabModules: {
                artists: [
                    {
                        id: 1,
                        key: "name",
                    },
                    {
                        id: 2,
                        key: "code",
                    },
                    {
                        id: 3,
                        key: "errorMessage",
                    },
                ],
                documents: [
                    {
                        id: 1,
                        key: "isrc/upc",
                    },
                    {
                        id: 2,
                        key: "documentType",
                    },
                    {
                        id: 3,
                        key: "errorMessage",
                    },
                ],
                tracks: [
                    {
                        id: 1,
                        key: "name",
                    },
                    {
                        id: 2,
                        key: "isrc",
                    },
                    {
                        id: 3,
                        key: "errorMessage",
                    },
                ],
                videos: [
                    {
                        id: 1,
                        key: "name",
                    },
                    {
                        id: 2,
                        key: "errorMessage",
                    },
                ],
            },
            releasesTab: [
                {
                    id: 1,
                    key: "nameEn",
                },
                {
                    id: 2,
                    key: "nameAr",
                },
                {
                    id: 3,
                    key: "releaseType",
                },
                {
                    id: 4,
                    key: "upc",
                },
                {
                    id: 5,
                    key: "artist",
                },
                {
                    id: 6,
                    key: "releaseDate",
                },
                {
                    id: 7,
                    key: "status",
                },
            ],
        },
        list: [
            {
                id: 0,
                key: "select",
            },
            {
                id: 1,
                key: "image",
            },
            {
                id: 2,
                key: "albumName",
            },
            {
                id: 3,
                key: "primaryArtist",
            },
            {
                id: 4,
                key: "releaseType",
            },
            {
                id: 5,
                key: "releaseDate",
            },
            {
                id: 6,
                key: "upc",
            },
            {
                id: 7,
                key: "numOfTracks",
            },
            {
                id: 8,
                key: "totalDuration",
            },

            {
                id: 9,
                key: "actions",
            },
        ],
    },
    revenueReports: [
        {
            id: 0,
            key: "select",
        },
        {
            id: 1,
            key: "artistNameEn",
        },
        {
            id: 2,
            key: "artistNameAr",
        },
        {
            id: 3,
            key: "albumNameEn",
        },
        {
            id: 4,
            key: "albumNameAr",
        },
        {
            id: 5,
            key: "platformId",
        },
        {
            id: 6,
            key: "platformName",
        },
        {
            id: 7,
            key: "upc",
        },
        {
            id: 8,
            key: "anotherUpc",
        },
        {
            id: 9,
            key: "isrc",
        },
        {
            id: 10,
            key: "anotherIsrc",
        },
        {
            id: 11,
            key: "songId",
        },
        {
            id: 12,
            key: "songNameEn",
        },
        {
            id: 13,
            key: "songNameAr",
        },
        {
            id: 14,
            key: "country",
        },
        {
            id: 15,
            key: "label",
        },
        {
            id: 16,
            key: "contentType",
        },
        {
            id: 17,
            key: "timesPlayed",
        },
        {
            id: 18,
            key: "date",
        },
        {
            id: 19,
            key: "revenue",
        },
        {
            id: 20,
            key: "actions",
        },
    ],
    territories: [
        {
            id: 1,
            key: "name",
        },
        {
            id: 2,
            key: "numOfCountries",
        },
        {
            id: 3,
            key: "numOfOwnerships",
        },
        {
            alignment: "right",
            id: 4,
            key: "actions",
        },
    ],
    tracks: {
        list: [
            {
                id: 0,
                key: "select",
            },
            {
                id: 1,
                key: "image",
            },
            {
                id: 2,
                key: "name",
            },
            {
                id: 3,
                key: "nameAr",
            },
            {
                id: 4,
                key: "number",
            },
            {
                id: 5,
                key: "duration",
            },
            {
                id: 6,
                key: "primaryArtist",
            },
            {
                id: 7,
                key: "secondaryArtists",
            },
            {
                id: 8,
                key: "isrc",
            },
            {
                id: 9,
                key: "actions",
            },
        ],
        soundRecordings: [
            {
                id: 1,
                key: "name",
            },
            {
                id: 2,
                key: "duration",
            },
            {
                id: 3,
                key: "actions",
            },
        ],
    },
    users: [
        {
            id: 1,
            key: "firstName",
        },
        {
            id: 2,
            key: "lastName",
        },
        {
            id: 3,
            key: "email",
        },
        {
            id: 4,
            key: "role",
        },
        {
            id: 5,
            key: "actions",
        },
    ],
};

export default tableHeadCellsData;
