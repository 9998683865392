import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData } from "data";
import { editReleaseRoyaltyMutation, getTrackQuery } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useEditReleaseRoyalty = (id, trackId) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [editReleaseRoyaltyAction, { loading }] = useMutation(
        editReleaseRoyaltyMutation,
        {
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: getTrackQuery,
                    variables: {
                    orderBy: { track_num: "asc" }, //eslint-disable-line
                        where: { id: trackId },
                    },
                },
            ],
        },
    );

    const editReleaseRoyalty = async ({
        master,
        mechanicalPerformance,
        neighboring,
        synchronization,
    }) => {
        try {
            await editReleaseRoyaltyAction({
                onCompleted: () => {
                    dispatch(openAlert(
                        t(
                            "messages.editMsgs.success",
                            { entity: `${t("releases.entity")} ${t("labels.royalty")}` },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: {
                    data: {
                        master: { set: !!master },
                        mechanical_performance: { set: !!mechanicalPerformance }, // eslint-disable-line
                        neighboring: { set: !!neighboring },
                        synchronization: { set: !!synchronization },
                    },
                    where: { id },
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        editReleaseRoyalty,
        loading,
    };
};

export default useEditReleaseRoyalty;
