import { useAuth0 } from "@auth0/auth0-react";
import {
    constantsData, identifiersData, namesData, urlsData,
} from "data";
import { useApisClient } from "hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useDownloadDriveManagementFile = () => {
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { logout } = useAuth0();

    const { get } = useApisClient();

    const { downloadDriveManagementFilePending: downloadDriveManagementFilePendingToastName } = namesData.toasts;

    const { toast: toastId } = identifiersData.ids;

    const downloadDriveManagementFile = (fileId, fileName) => {
        setLoading(true);

        get(
            `${urlsData.apis.driveManagement.files.url}/download/${fileId}/${fileName}`,
            null,
            null,
            { responseType: "arraybuffer" },
        ).
            then(({ data }) => {
                const uint8Array = new Uint8Array(data);

                const blob = new Blob(
                    [uint8Array],
                    { type: "application/octet-stream" },
                );

                const url = URL.createObjectURL(blob);

                const anchorElement = document.createElement("a"); //eslint-disable-line

                anchorElement.href = url;

                anchorElement.download = fileName;

                anchorElement.click();
            })["catch"]((err) => {
                displayErrorHandler(
                    err.response.data.message === constantsData.networkError ? t("messages.networkFailure") : err.response.data.message,
                    dispatch,
                    openAlert,
                    err.response.status,
                    logout,
                );
            })["finally"](() => {
                toast.dismiss(`${downloadDriveManagementFilePendingToastName}_${toastId}`);

                setLoading(false);
            });
    };

    useEffect(
        () => {
            if (loading) {
                toast.info(
                    () => t(
                        "messages.downloadMsgs.pending",
                        { entity: t("labels.file") },
                    ),
                    { toastId: `${downloadDriveManagementFilePendingToastName}_${toastId}` },
                );
            }
        },
        [loading], //eslint-disable-line
    );
    return {
        downloadDriveManagementFile,
        loading,
    };
};

export default useDownloadDriveManagementFile;
