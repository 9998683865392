import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import { Loader } from "atoms";
import { identifiersData } from "data";
import { PropTypes } from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledDebouncedSearchInput } from "styles";
import { debounce } from "utils";

const DebouncedSearchInput = ({
    label,
    name,
    setSearchValue,
}) => {
    const [focused, setFocused] = useState(false);

    const [loading, setLoading] = useState(false);

    const [searchInputValue, setSearchInputValue] = useState("");

    const { t } = useTranslation();

    const fetchSearchDataHandler = useCallback(
        debounce(
            (value) => {
                setLoading(false);

                if (value.length > 0) setSearchValue(value);
            },
            1200,
        ),
        [],
    ); /* eslint react-hooks/exhaustive-deps: "off" */

    const changeSearchInputValueHandler = (e) => {
        const { value } = e.target;

        fetchSearchDataHandler(value);

        setSearchInputValue(value);

        setLoading(true);

        if (value === "") setSearchValue("");
    };

    return (
        <StyledDebouncedSearchInput
            focused={focused}
            id={`${name}_${identifiersData.ids.input}`}
            label={label || t("labels.search")}
            value={searchInputValue}
            variant="outlined"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        {loading ? <Loader size={20} /> : <SearchIcon />}
                    </InputAdornment>
                ),
            }}
            onBlur={() => setFocused(false)}
            onChange={changeSearchInputValueHandler}
            onFocus={() => setFocused(true)}
        />
    );
};

export default DebouncedSearchInput;

DebouncedSearchInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    setSearchValue: PropTypes.func,
};
