import { useAuth0 } from "@auth0/auth0-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { AutoCompleteMenu, Loader } from "atoms";
import { FormContainer } from "components";
import {
    actionsData,
    identifiersData,
    inputData,
    permissionsData,
    releaseClientLinkFormData,
    selectionsData,
} from "data";
import { useClientsList } from "hooks";
import { PropTypes } from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useReleaseClientLinkSchema } from "schemas";
import { StyledTextField } from "styles";
import { canDo, renderFormControllerHandler } from "utils";

const ReleaseClientLinkForm = ({
    action,
    hasDrawer,
    loading,
    onSubmitForm,
    values,
}) => {
    const { t } = useTranslation();

    const { user } = useAuth0();

    const selections = useSelector((state) => state.selectionsReducer);

    const tracksList = useSelector((state) => state.releasesReducer)?.releaseTracks;

    const schema = useReleaseClientLinkSchema();

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const {
        count: clientsCount,
        fetched: clientsFetched,
        loading: clientsLoading,
        page: clientsPage,
        refetch: clientsRefetch,
        searchKey: clientsSearchKey,
    } = useClientsList(
        true,
        true,
    );

    const {
        control,
        formState: { errors },
    } = formMethods;

    const {
        mediaCreate: mediaCreatePermission,
        mediaRead: mediaReadPermission,
    } = permissionsData.permissions.keys;

    const allowedPermissions = [mediaCreatePermission, mediaReadPermission];

    const { track: trackInputName } = inputData.names;

    const tracksOptions = tracksList?.map(({
        releaseTrackId,
        title,
    }) => ({
        label: title,
        value: releaseTrackId,
    }));

    const asyncMenus = {
        clients: {
            count: clientsCount,
            loading: clientsLoading,
            page: clientsPage,
            refetch: clientsRefetch,
            searchKey: clientsSearchKey,
            selectionKey: selectionsData.keys.clients,
        },
    };

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        type,
        _,
        selectionKey,
        isAsyncMenu,
    ) => {
        if (type === inputData.types.text) {
            return (
                <StyledTextField
                    {...field}
                    error={errors[name]}
                    helperText={errors?.[name]?.message}
                    id={`${name}_${identifiersData.ids.input}`}
                    label={t(`labels.${label}`)}
                    variant="outlined"
                    fullWidth
                />
            );
        }

        return (
            <AutoCompleteMenu
                asyncMenuData={asyncMenus?.[selectionKey]}
                defaultValue={name === trackInputName ? tracksOptions?.find(({ value }) => value === values?.track) : selections?.[selectionKey]?.find((option) => option?.value === values?.[name])}
                errors={errors}
                getRenderedOption={(option) => option.label}
                isAsyncMenu={isAsyncMenu}
                label={label}
                name={name}
                optionLabel={(option) => option.label}
                options={name === trackInputName ? tracksOptions : selections?.[selectionKey]}
                onChange={(__, option) => {
                    if (option) field.onChange(option.value);

                    else field.onChange("");
                }}
            />

        );
    };

    if (clientsLoading && !clientsFetched) return <Loader />;

    return (
        <FormContainer
            action={action}
            formMethods={formMethods}
            hasClearAfterSubmission={action === actionsData.create}
            loading={loading}
            data={renderFormControllerHandler(
                control,
                releaseClientLinkFormData,
                renderFormControllerChildrenHandler,
                hasDrawer,
            )}
            hasSubmitButton={canDo(
                user,
                allowedPermissions,
            )}
            onSubmitForm={onSubmitForm}
        />
    );
};

export default ReleaseClientLinkForm;

ReleaseClientLinkForm.propTypes = {
    action: PropTypes.string,
    hasDrawer: PropTypes.bool,
    loading: PropTypes.bool,
    onSubmitForm: PropTypes.func,
    values: PropTypes.object,
};
