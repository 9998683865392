import { constantsData, statusTypesData } from "data";
import { axios, updateUploadFileToastHandler } from "utils";

export const getData = async (apiUrl, id, params, config) => {
    const fullApiUrl = id ? `${apiUrl}${"/"}${id}` : apiUrl;

    return axios(
        false,
        params,
    ).get(
        fullApiUrl,
        {
            headers: {
                Authorization: `Bearer ${config?.token}`,
                "Content-Type": "application/json",
            },
            ...config?.responseType && { responseType: config.responseType },

        },
    );
};

export const postData = async (apiUrl, id, data, config) => {
    const fullApiUrl = id ? `${apiUrl}/${id}` : apiUrl;

    return axios(
        false,
        null,
        config?.isFormData,
    ).post(
        fullApiUrl,
        data,
        {
            headers: { Authorization: `Bearer ${config?.token}` },
        },
    );
};

export const patchData = async (
    apiUrl,
    id,
    data,
    params,
    config,
) => {
    const fullApiUrl = id ? `${apiUrl}/${id}` : apiUrl;

    return axios(
        false,
        params,
    ).patch(
        fullApiUrl,
        data,
        {
            headers: { Authorization: `Bearer ${config?.token}` },
        },
    );
};

export const updateData = async (
    apiUrl,
    id,
    data,
    params,
    config,
) => {
    const fullApiUrl = id ? `${apiUrl}/${id}` : apiUrl;

    let currentProgress = 0;

    const { closeToastButtonClicked: closeToastButtonClickedConstant } = constantsData;

    const {
        file,
        folderId,
        isFormData,
        isGoogleFilesUpload,
        setFailedUploadedFiles,
        setSuccessUploadedFiles,
        toastIdsRef,
        token,
    } = config;

    const controller = new AbortController();

    return axios(
        false,
        params,
        isFormData,
    ).put(
        fullApiUrl,
        data,
        {
            ...isGoogleFilesUpload && { signal: controller.signal },
            headers: {
                ...!isGoogleFilesUpload && { Authorization: `Bearer ${token}` },
            },
            onUploadProgress: (progressEvent) => {
                const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);

                currentProgress = progress;

                if (isGoogleFilesUpload && !localStorage.getItem(closeToastButtonClickedConstant)) { // eslint-disable-line
                    updateUploadFileToastHandler(
                        file,
                        parseInt(currentProgress),
                        toastIdsRef,
                        statusTypesData.inProgress,
                        () => controller.abort(),
                    );
                }
            },
            ...isGoogleFilesUpload && { baseURL: apiUrl },
        },
    ).then((response) => {
        if (isGoogleFilesUpload) {
            setSuccessUploadedFiles((prev) => [
                ...prev,
                {
                    driveId: response?.data?.driveId,
                    fileId: response?.data?.id,
                    folderId,
                    name: file.name,
                    size: file.size,
                    type: file.type,
                },
            ]);

            if (!localStorage.getItem(closeToastButtonClickedConstant)) { // eslint-disable-line
                updateUploadFileToastHandler(
                    file,
                    parseInt(currentProgress),
                    toastIdsRef,
                    statusTypesData.success,
                    () => controller.abort(),
                );
            }
        }
    })["catch"](() => {
        if (isGoogleFilesUpload) {
            setFailedUploadedFiles((prev) => [
                ...prev,
                file,
            ]);

            if (!localStorage.getItem(closeToastButtonClickedConstant)) { // eslint-disable-line
                updateUploadFileToastHandler(
                    file,
                    parseInt(currentProgress),
                    toastIdsRef,
                    statusTypesData.error,
                    () => controller.abort(),
                );
            }
        }
    });
};

export const deleteData = async (apiUrl, id, config) => axios(
    false,
    null,
    false,
)["delete"](
    `${apiUrl}${id ? `/${id}` : ""}`,
    {
        headers: { Authorization: `Bearer ${config?.token}` },
    },
)["catch"]((err) => {
    if (err?.response?.status === 401 && config?.logoutAction) config?.logoutAction();
});
