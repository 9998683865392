import { PropTypes } from "prop-types";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const Meta = ({ title }) => {
    const { t } = useTranslation();

    const location = useLocation();

    return (
        <Helmet>
            <title>
                {title}
                {" "}
                |
                {" "}
                {t("labels.cmsTitle")}
            </title>
            <meta
                content={t("labels.metaDescription")}
                name="description"
            />
            <meta
                content={t("labels.metaKeywords")}
                name="keywords"
            />
            <meta
                content={`${title} | ${t("labels.cmsTitle")}`}
                property="og:title"
            />
            <meta
                content={t("labels.metaDescription")}
                property="og:description"
            />
            <meta
                content={`${window?.__RUNTIME_CONFIG__?.REACT_APP_HOST}/logo.png`} // eslint-disable-line
                property="og:image"
            />
            <meta
                content={window?.__RUNTIME_CONFIG__?.REACT_APP_HOST + location.pathname + location.search} // eslint-disable-line
                property="og:url"
            />
        </Helmet>
    );
};

export default Meta;

Meta.propTypes = { title: PropTypes.string };
