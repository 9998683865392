import { DetailsText } from "atoms";
import { DetailsHeader } from "components";
import { actionsData, urlsData } from "data";
import { useDeleteArtist } from "hooks";
import { PropTypes } from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ArtistDetailsHeader = ({ data }) => {
    const { t } = useTranslation();

    const { deleteArtist } = useDeleteArtist(
        0,
        25,
        false,
        false,
        true,
    );

    const [modalData, setModalData] = useState({
        action: deleteArtist,
        actionType: actionsData["delete"],
        body: t(
            "messages.deleteMsgs.alert",
            { entity: t("artists.entity") },
        ),
        id: null,
        open: false,
        title: t("artists.deleteArtist"),
    });

    const {
        code,
        country,
        id,
        image,
        isni,
        name,
        name_ar: nameAr,
        releases,
        tracks,
    } = data || {};

    return (
        <DetailsHeader
            editRouteUrl={`${urlsData.routes.artists.edit}/${id}`}
            entity={t("artists.name").toLowerCase()}
            id={id}
            image={image}
            modalData={modalData}
            setModalData={setModalData}
            title={`${name} | ${nameAr}`}
            columnOneData={(
                <>
                    {isni && (
                        <DetailsText
                            info={isni}
                            tag={t("labels.isni")}
                        />
                    )}
                    <DetailsText
                        info={code}
                        tag={t("labels.code")}
                    />
                    <DetailsText
                        info={releases?.length}
                        tag={t("releases.name")}
                    />
                </>
            )}
            columnTwoData={(
                <>
                    <DetailsText
                        info={country?.en_name}
                        tag={t("labels.nationality")}
                    />
                    <DetailsText
                        info={tracks?.length}
                        tag={t("tracks.name")}
                    />
                </>
            )}
        />
    );
};

export default ArtistDetailsHeader;

ArtistDetailsHeader.propTypes = { data: PropTypes.object };
