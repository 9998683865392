import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import {
    Avatar,
    Box,
    Checkbox,
    TableRow,
    Typography,
} from "@mui/material";
import {
    Loader,
    Menu,
    MenuItem,
    Modal,
    TableList,
    TableMenu,
} from "atoms";
import { Filters, PageHead, TrackDrawer } from "components";
import {
    actionsData,
    filtersModulesData,
    identifiersData,
    inputData,
    namesData,
    selectionsData,
    tableHeadCellsData,
    urlsData,
} from "data";
import {
    useArtistsList,
    useDeleteTrack,
    useDownloadTracksContent,
    useExportAllTracks,
    useExportTracks,
    useGenresSelectionList,
    useReleaseTypesSelectionList,
    useRemoveReleaseTrack,
    useRightTypesSelectionList,
    useSelectTableRows,
} from "hooks";
import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
    clearFilters,
    closeFilters,
    setCurrentPlayedTrack,
    setModulesFilters,
    toggleTrackDrawer,
} from "redux/actions";
import { StyledLink, StyledTableCell } from "styles";
import { convertHourToDurationFormatHandler } from "utils";

const TracksList = ({
    data,
    hasTab,
    releaseId,
    tabValue,
}) => {
    const [trackId, setTrackId] = useState(null);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const filtersModules = useSelector((state) => state.filtersReducer?.filtersModules);

    const filtersOpened = useSelector((state) => state.filtersReducer.open);

    const location = useLocation();

    const { removeReleaseTrack } = useRemoveReleaseTrack(parseInt(releaseId));

    const {
        isSelected,
        selectAllHandler,
        selectRowHandler,
        selected,
        setSelected,
    } = useSelectTableRows();

    const { exportAllTracks } = useExportAllTracks();

    const {
        count,
        data: tracksData,
        limit,
        loading,
        page,
        setLimit,
        setPage,
    } = data || {};

    const { deleteTrack } = useDeleteTrack(
        parseInt(releaseId),
        limit,
        page,
    );

    const { exportTracks } = useExportTracks();

    const { downloadTracksContent } = useDownloadTracksContent();

    const { loading: genresSelectionListLoading } = useGenresSelectionList();

    const { loading: releaseTypesSelectionListLoading } = useReleaseTypesSelectionList();

    const { loading: rightTypesSelectionListLoading } = useRightTypesSelectionList();

    const {
        count: artistsCount,
        fetched: artistsFetched,
        loading: artistsLoading,
        page: artistsPage,
        refetch: artistsRefetch,
        searchKey: artistsSearchKey,
    } = useArtistsList(
        true,
        true,
        {},
        null,
    );

    const [modalData, setModalData] = useState({
        action: deleteTrack,
        actionType: actionsData["delete"],
        body: t(
            "messages.deleteMsgs.alert",
            { entity: t("tracks.entity") },
        ),
        id: null,
        open: false,
        title: t("tracks.deleteTrack"),
    });

    const {
        artists: { url: artistsRouteUrl },
        tracks: { url: tracksRouteUrl },
    } = urlsData.routes;

    const asyncMenus = {
        artists: {
            count: artistsCount,
            loading: artistsLoading,
            page: artistsPage,
            refetch: artistsRefetch,
            searchKey: artistsSearchKey,
            selectionKey: selectionsData.keys.artists,
        },
    };

    useEffect(
        () => {
            dispatch(setModulesFilters({}));

            dispatch(clearFilters());

            dispatch(closeFilters());
        },
        [], //eslint-disable-line
    );

    const filtersLength = Object.keys(filtersModules?.isrc || {}).length + Object.keys(filtersModules?.tracks || {}).length + Object.keys(filtersModules?.releases || {}).length + Object.keys(filtersModules?.videos || {}).length;

    const {
        input: inputId,
        link: linkId,
        menu: menuId,
        option: optionId,
        table: tableId,
    } = identifiersData.ids;

    const {
        links: {
            primaryArtist: primaryArtistLinkName,
            secondaryArtist: secondaryArtistLinkName,
        },
        menus: { table: tableMenuName },
        options: {
            downloadTrack: downloadTrackOptionName,
            playTrack: playTrackOptionName,
            removeFromRelease: removeFromReleaseOptionName,
        },
    } = namesData;

    if (loading) return <Loader />;

    return (
        <>
            <PageHead
                filtersCount={filtersLength}
                filtersModule={filtersModulesData.tracks}
                title={t("tracks.name")}
                hasFilters
            />
            <TrackDrawer
                id={trackId}
                setId={setTrackId}
            />
            <Modal
                modalData={modalData}
                setModalData={setModalData}
            />
            {filtersOpened && (
                <Filters
                    asyncMenus={asyncMenus}
                    loadings={{
                        artists: artistsLoading && !artistsFetched,
                        genres: genresSelectionListLoading,
                        releaseTypes: releaseTypesSelectionListLoading,
                        rightTypes: rightTypesSelectionListLoading,
                    }}
                />
            )}
            {!releaseId && (
                <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="end"
                    marginBlock={2}
                    width="100%"
                >
                    <Menu
                        label={t("actions.exportAllData")}
                        name={namesData.menus.exportAllData}
                        options={[
                            {
                                action: () => exportAllTracks(),
                                label: t("labels.csv"),
                            },
                        ]}
                        withTransparentButton
                    />
                </Box>
            )}
            <TableList
                count={count}
                downloadContentAction={downloadTracksContent}
                exportAction={exportTracks}
                hasTab={hasTab}
                headCells={tableHeadCellsData.tracks.list}
                limit={limit}
                page={page}
                selectAllAction={selectAllHandler}
                selected={selected}
                setLimit={setLimit}
                setPage={setPage}
                setSelected={setSelected}
                tabValue={tabValue}
                tableData={tracksData}
                hasDownloadContent
                hasExport
                isSelectable
                paginated
            >
                {tracksData?.map(({
                    artist,
                    downloadUri,
                    id,
                    image,
                    isrc,
                    releaseTracks,
                    secondary_artists: secondaryArtists,
                    title,
                    title_ar: titleAr,
                    track_num: trackNumber,
                    trackFile,
                }, index) => (
                    <TableRow
                        key={id}
                        role="checkbox"
                        selected={isSelected(id)}
                        tabIndex={-1}
                        aria-checked
                        hover
                        onClick={() => selectRowHandler(id)}
                    >
                        <StyledTableCell padding="checkbox">
                            <Checkbox
                                checked={isSelected(id)}
                                color="primary"
                                id={`${tableId}_${inputData.names.select}_${inputId}_${index + 1}`}
                            />
                        </StyledTableCell>
                        <StyledTableCell>
                            <Avatar
                                alt={t("imageAlternatives.track")}
                                imgProps={{ loading: "lazy" }}
                                src={`${image?.downloadThumbnail ? window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_DOMAIN + image?.downloadThumbnail : ""}`} // eslint-disable-line
                            >
                                <AudiotrackIcon />
                            </Avatar>
                        </StyledTableCell>
                        <StyledTableCell boldCell={+true}>
                            <StyledLink
                                id={`${tableId}_${linkId}_${index + 1}`}
                                state={{ from: location.pathname + location.search }}
                                to={`${tracksRouteUrl}/${id}`}
                            >
                                {title}
                            </StyledLink>
                        </StyledTableCell>
                        <StyledTableCell>{titleAr}</StyledTableCell>
                        <StyledTableCell>{trackNumber}</StyledTableCell>
                        <StyledTableCell>
                            <time>{convertHourToDurationFormatHandler(trackFile?.duration)}</time>
                        </StyledTableCell>
                        <StyledTableCell>
                            <StyledLink
                                id={`${tableId}_${primaryArtistLinkName}_${linkId}_${index + 1}`}
                                state={{ from: location.pathname + location.search }}
                                to={`${artistsRouteUrl}/${artist?.id}`}
                            >
                                {artist?.name}
                            </StyledLink>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Box
                                alignItems="start"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                            >
                                {secondaryArtists?.length === 0 ? <Typography variant="body1">{t("labels.N/A")}</Typography> : secondaryArtists?.map(({
                                    id: secondaryArtistId,
                                    name,
                                }, secondaryArtistIndex) => (
                                    <StyledLink
                                        id={`${tableId}_${secondaryArtistLinkName}_${linkId}_${index + 1}_${secondaryArtistIndex + 1}`}
                                        key={secondaryArtistId}
                                        state={{ from: location.pathname + location.search }}
                                        to={`${artistsRouteUrl}/${secondaryArtistId}`}
                                    >
                                        {name}
                                    </StyledLink>
                                ))}
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell>{isrc}</StyledTableCell>
                        <StyledTableCell onClick={(e) => e.stopPropagation()}>
                            <TableMenu
                                rowId={index + 1}
                                editAction={() => {
                                    setTrackId(id);

                                    dispatch(toggleTrackDrawer(true));
                                }}
                                setOpenModal={() => {
                                    setModalData({
                                        ...modalData,
                                        id,
                                        open: true,
                                    });
                                }}
                                hasDelete
                                hasEdit
                            >
                                <MenuItem
                                    icon={<PlayCircleFilledWhiteOutlinedIcon />}
                                    id={`${tableMenuName}_${menuId}_${index + 1}_${playTrackOptionName}_${optionId}`}
                                    label={t("tracks.playTrack")}
                                    onClick={() => dispatch(setCurrentPlayedTrack({
                                        artist,
                                        downloadUri,
                                        image,
                                        title,
                                    }))}
                                />
                                <a
                                    href={`${window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_DOMAIN}${downloadUri}`} // eslint-disable-line
                                    style={{
                                        display: "flex",
                                        gap: "8px",
                                        textDecoration: "none",
                                    }}
                                >
                                    <MenuItem
                                        icon={<DownloadOutlinedIcon />}
                                        id={`${tableMenuName}_${menuId}_${index + 1}_${downloadTrackOptionName}_${optionId}`}
                                        label={t("tracks.downloadTrack")}
                                    />
                                </a>
                                {releaseId && (
                                    <MenuItem
                                        icon={<PlaylistRemoveIcon />}
                                        id={`${tableMenuName}_${menuId}_${index + 1}_${removeFromReleaseOptionName}_${optionId}`}
                                        label={t("releases.removeFromRelease")}
                                        onClick={() => {
                                            const releaseTracksId = releaseTracks.find((release) => release.releaseId === parseInt(releaseId))?.id;

                                            removeReleaseTrack(releaseTracksId);
                                        }}
                                    />
                                )}
                            </TableMenu>
                        </StyledTableCell>
                    </TableRow>
                ))}
            </TableList>
        </>
    );
};

export default TracksList;

TracksList.propTypes = {
    data: PropTypes.object,
    hasTab: PropTypes.bool,
    releaseId: PropTypes.string,
    tabValue: PropTypes.string,
};
