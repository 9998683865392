import {
    ApolloClient,
    ApolloProvider,
    createHttpLink,
    InMemoryCache,
} from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import { authHeaders } from "utils";

const GraphQLProvider = ({ children }) => {
    const graphQLLink = createHttpLink({ uri: window?.__RUNTIME_CONFIG__?.REACT_APP_GRAPHQL_SERVER }); // eslint-disable-line

    const { getAccessTokenSilently } = useAuth0();

    const apolloClient = new ApolloClient({
        cache: new InMemoryCache(),
        connectToDevTools: true,
        defaultOptions: {
            watchQuery: { fetchPolicy: "cache-and-network" },
        },
        link: authHeaders(getAccessTokenSilently).concat(graphQLLink),
    });

    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default GraphQLProvider;

GraphQLProvider.propTypes = { children: PropTypes.any };
