import styled from "@emotion/styled";
import { Alert } from "@mui/material";
import { constantsData, identifiersData } from "data";

const StyledNetworkAlert = styled(
    Alert,
    { shouldForwardProp: (prop) => prop !== identifiersData.props.networkMode },
)(({
    networkMode,
    theme,
}) => ({
    "& svg": { color: theme.palette.grey[100] },
    background: networkMode === constantsData.offline ? theme.palette.grey[905] : theme.palette.primary.main,
    bottom: networkMode ? 0 : -100,
    color: theme.palette.grey[100],
    height: 50,
    position: "fixed",
    right: 0,
    transition: "all 2s",
    width: "100%",
    zIndex: 9999,
}));

export default StyledNetworkAlert;
