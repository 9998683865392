import inputData from "data/input";
import selectionsData from "data/selections";

const {
    artists: artistsSelectionKey,
    releases: releasesSelectionKey,
} = selectionsData.keys;

const {
    autoComplete: autoCompleteInputType,
    text: textInputType,
} = inputData.types;

const {
    artist: artistInputName,
    label: labelInputName,
    release: releaseInputName,
    revenue: revenueInputName,
} = inputData.names;

const {
    artist: artistInputLabel,
    label: labelInputLabel,
    release: releaseInputLabel,
    revenue: revenueInputLabel,
} = inputData.labels;

const revenueReportFormData = [
    {
        col: { xs: 12 },
        isAsyncMenu: true,
        label: artistInputLabel,
        name: artistInputName,
        selectionKey: artistsSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: { xs: 12 },
        isAsyncMenu: true,
        label: releaseInputLabel,
        name: releaseInputName,
        selectionKey: releasesSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: { xs: 12 },
        label: labelInputLabel,
        name: labelInputName,
        type: textInputType,
    },
    {
        col: { xs: 12 },
        label: revenueInputLabel,
        name: revenueInputName,
        type: textInputType,
    },

];

export default revenueReportFormData;
