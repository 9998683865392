import { useAuth0 } from "@auth0/auth0-react";
import { constantsData, urlsData } from "data";
import { useApisClient } from "hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useGetDriveManagementFileLabels = (id) => {
    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(true);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const open = useSelector((state) => state.driveManagementReducer?.openDrawer);

    const { logout } = useAuth0();

    const { get } = useApisClient();

    const {
        files: { url: driveManagementFilesApiUrl },
        labels: driveManagementLabelsApiUrl,
    } = urlsData.apis.driveManagement;

    useEffect(() => {
        if (id && open) {
            setLoading(true);

            get(
                `${driveManagementFilesApiUrl}/${driveManagementLabelsApiUrl}`,
                id,
                {
                    pageSize: 200,
                    publishedOnly: true,
                },
            ).then(({ data: responseData }) => setData(responseData.labels))["catch"]((err) => {
                displayErrorHandler(
                    err.response.data.message === constantsData.networkError ? t("messages.networkFailure") : err.response.data.message,
                    dispatch,
                    openAlert,
                    err.response.status,
                    logout,
                );
            })["finally"](() => {
                setLoading(false);
            });
        }
    }, [id, open]); //eslint-disable-line

    return {
        data,
        loading,
    };
};

export default useGetDriveManagementFileLabels;
