import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useReleaseDocumentSchema = () => {
    const { t } = useTranslation();

    return yup.object({
        documentFile: yup.string().required(t("messages.inputMsgs.required")),
        tracks: yup.array().required(t("messages.inputMsgs.required")),
        type: yup.string().required(t("messages.inputMsgs.required")),
    });
};

export default useReleaseDocumentSchema;
