import { useAuth0 } from "@auth0/auth0-react";
import DashboardCustomizeRoundedIcon from "@mui/icons-material/DashboardCustomizeRounded";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import FolderSharedOutlinedIcon from "@mui/icons-material/FolderSharedOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import {
    Box,
    IconButton,
    Menu,
    Tooltip,
    Zoom,
} from "@mui/material";
import { MenuItem } from "atoms";
import {
    identifiersData,
    namesData,
    permissionsData,
    urlsData,
} from "data";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { theme } from "theme";

const DashboardMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const { t } = useTranslation();

    const navigate = useNavigate();

    const location = useLocation();

    const { user } = useAuth0();

    const open = Boolean(anchorEl);

    const {
        driveManagement: { url: driveManagementRouteUrl },
        groups: { url: groupsRouteUrl },
        home: homeRouteUrl,
    } = urlsData.routes;

    const {
        button: buttonId,
        menu: menuId,
        option: optionId,
    } = identifiersData.ids;

    const {
        buttons: { dashboard: dashboardButtonName },
        menus: { dashboard: dashboardMenuName },
        options: {
            driveManagement: driveManagementOptionName,
            groups: groupsOptionName,
            releases: releasesOptionName,
            revenueReports: revenueReportsOptionName,
        },
    } = namesData;

    const mediaReadPermission = user?.[permissionsData.key]?.find((permission) => permission === permissionsData.permissions.keys.mediaRead);

    const driveReadPermission = user?.[permissionsData.key]?.find((permission) => permission === permissionsData.permissions.keys.driveRead);

    const reportReadPermission = user?.[permissionsData.key]?.find((permission) => permission === permissionsData.permissions.keys.reportRead);

    const openMenuHandler = (event) => setAnchorEl(event.currentTarget);

    const closeMenuHandler = () => setAnchorEl(null);

    return (
        <Box>
            <Tooltip title={t("labels.dashboard")}>
                <IconButton
                    id={`${dashboardMenuName}_${menuId}_${dashboardButtonName}_${buttonId}`}
                    variant="outlined"
                    onClick={openMenuHandler}
                >
                    <DashboardCustomizeRoundedIcon />
                </IconButton>
            </Tooltip>
            <Menu
                TransitionComponent={Zoom}
                anchorEl={anchorEl}
                open={open}
                sx={{ ".MuiList-root": { padding: 0 } }}
                onClose={closeMenuHandler}
            >
                <Box
                    id={`${dashboardMenuName}_${menuId}`}
                    sx={{ margin: "14px" }}
                    onClick={closeMenuHandler}
                >
                    {(mediaReadPermission || reportReadPermission) && (
                        <MenuItem
                            icon={mediaReadPermission ? <DashboardOutlinedIcon /> : <RequestQuoteOutlinedIcon />}
                            id={`${dashboardMenuName}_${menuId}_${mediaReadPermission ? releasesOptionName : revenueReportsOptionName}_${optionId}`}
                            label={mediaReadPermission ? t("releases.name") : t("revenueReports.name")}
                            style={{
                                backgroundColor: (!location.pathname.includes(driveManagementRouteUrl) && !location.pathname.includes(groupsRouteUrl)) && theme.palette.grey[904],
                                borderRadius: "8px",
                                gap: 1,
                                marginBottom: "14px",
                                padding: "12px",
                            }}
                            onClick={() => navigate(homeRouteUrl)}
                        />
                    )}
                    {driveReadPermission && (
                        <>
                            <MenuItem
                                icon={<FolderSharedOutlinedIcon />}
                                id={`${dashboardMenuName}_${menuId}_${driveManagementOptionName}_${optionId}`}
                                label={t("driveManagement.name")}
                                style={{
                                    backgroundColor: location.pathname.includes(driveManagementRouteUrl) && theme.palette.grey[904],
                                    borderRadius: "8px",
                                    gap: 1,
                                    marginBottom: "14px",
                                    padding: "12px",
                                }}
                                onClick={() => navigate(driveManagementRouteUrl)}
                            />
                            <MenuItem
                                icon={<GroupsOutlinedIcon />}
                                id={`${dashboardMenuName}_${menuId}_${groupsOptionName}_${optionId}`}
                                label={t("groups.name")}
                                style={{
                                    backgroundColor: location.pathname.includes(groupsRouteUrl) && theme.palette.grey[904],
                                    borderRadius: "8px",
                                    gap: 1,
                                    padding: "12px",
                                }}
                                onClick={() => navigate(groupsRouteUrl)}
                            />
                        </>
                    )}
                </Box>
            </Menu>
        </Box>
    );
};

export default DashboardMenu;
