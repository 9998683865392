import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData } from "data";
import { editRevenueReportMutation } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert, setRefetchRevenueReportsList, toggleRevenueReportDrawer } from "redux/actions";
import { displayErrorHandler } from "utils";

const useEditRevenueReport = (id, setId) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [editRevenueReportAction, { loading }] = useMutation(editRevenueReportMutation);

    const editRevenueReport = async ({
        artist,
        label,
        release,
        revenue,
    }) => {
        try {
            await editRevenueReportAction({
                onCompleted: () => {
                    dispatch(setRefetchRevenueReportsList(true));

                    dispatch(toggleRevenueReportDrawer(false));

                    setId(null);

                    dispatch(openAlert(
                        t(
                            "messages.editMsgs.success",
                            { entity: t("revenueReports.entity") },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: {
                    data: {
                        ...release?.albumNameAr && {
                            album_name_ar: { set: release?.albumNameAr }, //eslint-disable-line
                        },
                        ...release?.upc && {
                            upc: { set: release?.upc }, //eslint-disable-line
                        },
                        ...release?.label && {
                            album_name_en: { set: release?.label }, //eslint-disable-line
                        },
                        ...artist?.nameAr && {
                            artist_name_ar: { set: artist?.nameAr }, //eslint-disable-line
                        },
                        ...artist?.label && {
                            artist_name_en: { set: artist?.label }, //eslint-disable-line
                        },
                        ...label && {
                            labelName: { set: label },
                        },
                        ...revenue && {
                            revenue: { set: parseFloat(revenue) },
                        },
                    },
                    where: { id },
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        editRevenueReport,
        loading,
    };
};

export default useEditRevenueReport;
