import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { constantsData, identifiersData, namesData } from "data";
import { getReleaseShareLinkQuery } from "graph";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { openAlert } from "redux/actions";
import { copyTextHandler, displayErrorHandler } from "utils";

const useGetReleaseShareLink = (releaseId, skip) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { isAuthenticated } = useAuth0();

    const { getReleaseShareLinkPending: getReleaseShareLinkPendingToastName } = namesData.toasts;

    const { toast: toastId } = identifiersData.ids;

    const {
        data,
        error,
        loading,
    } = useQuery(
        getReleaseShareLinkQuery,
        {
            onCompleted: ({ shareLinkRelease }) => {
                toast.dismiss(`${getReleaseShareLinkPendingToastName}_${toastId}`);

                copyTextHandler(
                    window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_DOMAIN + shareLinkRelease, //eslint-disable-line
                    dispatch,
                    t(
                        "messages.shareLinkMsgs.success",
                        { entity: `${t("releases.entity")} ${t("labels.shareLink")}` },
                    ),
                );
            },
            onError: () => toast.dismiss(`${getReleaseShareLinkPendingToastName}_${toastId}`),
            skip: !isAuthenticated || !skip,
            variables: { id: releaseId },
        },
    );

    const copyReleaseShareLink = () => {
        copyTextHandler(
            window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_DOMAIN + data?.shareLinkRelease, //eslint-disable-line
            dispatch,
            t(
                "messages.copied",
                { entity: `${t("releases.entity")} ${t("labels.shareLink")}` },
            ),
        );
    };

    useEffect(
        () => {
            if (loading) {
                toast.info(
                    () => t(
                        "messages.shareLinkMsgs.pending",
                        { entity: t("releases.entity") },
                    ),
                    { toastId: `${getReleaseShareLinkPendingToastName}_${toastId}` },
                );
            }
        },
        [loading], //eslint-disable-line
    );

    useEffect(
        () => {
            if (error?.message && !loading) {
                displayErrorHandler(
                    error?.message === constantsData.failedToFetch ? t("messages.networkFailure") : error.message,
                    dispatch,
                    openAlert,
                );
            }
        },
        [error, data], // eslint-disable-line
    );

    return {
        copyReleaseShareLink,
        data: data?.shareLinkRelease,
    };
};

export default useGetReleaseShareLink;
