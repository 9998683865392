import "react-toastify/dist/ReactToastify.css";
import "./i18n";

import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Snackbar } from "atoms";
import { constantsData, sidebarData } from "data";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { setNetworkMode, setSidebarActiveTab } from "redux/actions";
import { Routes } from "routes";
import { prefixer } from "stylis";
import { theme } from "theme";
import { NetworkErrorBoundary } from "utils";

const cacheLtR = createCache({
    key: "rotana-cms",
    stylisPlugins: [prefixer],
});

const App = () => {
    const dispatch = useDispatch();

    const networkMode = useSelector((state) => state.networkReducer.mode);

    const routes = useRoutes(Routes);

    const location = useLocation();

    useEffect(
        () => {
            const activeTab = sidebarData.routes[location.pathname];

            dispatch(setSidebarActiveTab(activeTab));
        },
        [dispatch, location],
    );

    useEffect(
        () => {
            window.addEventListener( // eslint-disable-line
                "offline",
                () => dispatch(setNetworkMode(constantsData.offline)),
            );
            window.addEventListener( // eslint-disable-line
                "online",
                () => dispatch(setNetworkMode(constantsData.online)),
            );

            return () => {
                window.removeEventListener( // eslint-disable-line
                    "offline",
                    () => dispatch(setNetworkMode(constantsData.offline)),
                );

                window.removeEventListener( // eslint-disable-line
                    "online",
                    () => dispatch(setNetworkMode(constantsData.online)),
                );
            };
        },
        [], // eslint-disable-line
    );

    useEffect(
        () => {
            if (networkMode === constantsData.online) {
                setTimeout(
                    () => { dispatch(setNetworkMode(null)); },
                    3000,
                );
            }
        },
        [networkMode], // eslint-disable-line
    );

    return (
        <CacheProvider value={cacheLtR}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Snackbar />
                <NetworkErrorBoundary>{routes}</NetworkErrorBoundary>
                <ToastContainer
                    autoClose={false}
                    closeButton={false}
                    closeOnClick={false}
                    position="bottom-right"
                    hideProgressBar
                />
            </ThemeProvider>
        </CacheProvider>
    );
};

export default App;
