import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
    Backdrop,
    Box,
    Fade,
    Grid,
    IconButton,
    Typography,
} from "@mui/material";
import { DetailsMenu, Modal } from "atoms";
import { PageHead } from "components";
import { urlsData } from "data";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
    StyledHeader,
    StyledImage,
    StyledImageBackdrop,
    StyledImageShadow,
} from "styles";
import { theme } from "theme";

const DetailsHeader = ({
    columnOneData,
    columnTwoData,
    copyLinkAction,
    editAction,
    editRouteUrl,
    entity,
    hasDownloadXmlFile,
    hasShareLinkAction,
    id,
    image,
    isReleaseHeader,
    modalData,
    setModalData,
    title,
    withoutStyle,
}) => {
    const [openImageBackdrop, setOpenImageBackdrop] = useState(false);

    const [showImagePreview, setShowImagePreview] = useState();

    const { t } = useTranslation();

    const location = useLocation();

    const fallbackImages = {
        artists: <PersonIcon sx={{ fontSize: 150 }} />,
        releases: <LibraryMusicIcon sx={{ fontSize: 150 }} />,
        tracks: <AudiotrackIcon sx={{ fontSize: 150 }} />,
    };

    return (
        <StyledHeader withoutStyle={+!!withoutStyle}>
            <Modal
                modalData={modalData}
                setModalData={setModalData}
            />
            <Grid
                columnSpacing={4}
                rowSpacing={1}
                container
            >
                <Grid
                    md={4}
                    xs={11}
                    item
                >
                    <Box
                        position="relative"
                        onMouseEnter={() => setShowImagePreview(true)}
                        onMouseLeave={() => setShowImagePreview(false)}
                    >
                        <Backdrop
                            open={openImageBackdrop}
                            sx={{ zIndex: theme.zIndex.drawer + 1 }}
                            onClick={() => setOpenImageBackdrop(false)}
                        >
                            <StyledImageBackdrop src={`${image?.downloadUri ? window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_DOMAIN + image?.downloadUri : ""}`}> {/* eslint-disable-line */}
                                {fallbackImages[entity]}
                            </StyledImageBackdrop>
                        </Backdrop>
                        <StyledImage src={`${image?.downloadThumbnail ? window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_DOMAIN + image?.downloadThumbnail : ""}`}> {/* eslint-disable-line */}
                            {fallbackImages[entity]}
                        </StyledImage>
                        <Fade in={showImagePreview}>
                            <StyledImageShadow onClick={() => setOpenImageBackdrop(true)}>
                                <Box>
                                    {image?.downloadUri && (
                                        <a
                                            href={`${image?.downloadUri ? window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_DOMAIN + image?.downloadUri : ""}`}  /* eslint-disable-line */
                                            style={{ textDecoration: "none" }}
                                            target="_self"
                                            download
                                        >
                                            <IconButton
                                                sx={{ color: theme.palette.grey[100] }}
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                <CloudDownloadIcon
                                                    sx={{
                                                        position: "relative",
                                                        right: 2,
                                                    }}
                                                />
                                                <Typography variant="caption">{t("actions.download")}</Typography>
                                            </IconButton>
                                        </a>
                                    )}
                                    <IconButton sx={{ color: theme.palette.grey[100] }}>
                                        <VisibilityIcon />
                                        <Typography variant="caption">{t("actions.preview")}</Typography>
                                    </IconButton>
                                </Box>
                            </StyledImageShadow>
                        </Fade>
                    </Box>
                </Grid>
                <Grid
                    md={8}
                    xs={12}
                    item
                >
                    <Grid
                        flexWrap="wrap"
                        justifyContent="space-between"
                        spacing={2}
                        container
                    >
                        <Grid
                            paddingLeft="0px!important"
                            sm={6}
                            xs={12}
                            item
                        >
                            <PageHead
                                backRouteUrl={location.state?.from || urlsData.routes?.[entity]?.url}
                                title={title}
                                hasBack
                            />
                        </Grid>
                        <Grid
                            sm={3}
                            xs={12}
                            item
                        >
                            <DetailsMenu
                                copyLinkAction={copyLinkAction}
                                editAction={editAction}
                                editRouteUrl={editRouteUrl}
                                hasDownloadXmlFile={hasDownloadXmlFile}
                                hasShareLink={hasShareLinkAction}
                                isReleaseHeader={isReleaseHeader}
                                setOpenModal={() => {
                                    setModalData({
                                        ...modalData,
                                        id,
                                        open: true,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid
                            md={6}
                            xs={12}
                            item
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                gap={2}
                                mt={{
                                    md: 5,
                                    xs: 3,

                                }}
                            >
                                {columnOneData}
                            </Box>
                        </Grid>
                        <Grid
                            md={6}
                            xs={12}
                            item
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                gap={2}
                                mt={{
                                    md: 5,
                                    xs: 0,

                                }}
                            >
                                {columnTwoData}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </StyledHeader>
    );
};

export default DetailsHeader;

DetailsHeader.propTypes = {
    columnOneData: PropTypes.node,
    columnTwoData: PropTypes.node,
    copyLinkAction: PropTypes.func,
    editAction: PropTypes.func,
    editRouteUrl: PropTypes.string,
    entity: PropTypes.string,
    hasDownloadXmlFile: PropTypes.bool,
    hasShareLinkAction: PropTypes.bool,
    id: PropTypes.number,
    image: PropTypes.object,
    isReleaseHeader: PropTypes.bool,
    modalData: PropTypes.object,
    setModalData: PropTypes.func,
    title: PropTypes.string,
    withoutStyle: PropTypes.bool,
};
