import inputData from "data/input";
import selectionsData from "data/selections";

const { territories: territoriesSelectionKey } = selectionsData.keys;

const {
    autoComplete: autoCompleteInputType,
    text: textInputType,
} = inputData.types;

const {
    cLine: cLineInputName,
    label: labelInputName,
    pLine: pLineInputName,
    territory: territoryInputName,
} = inputData.names;

const {
    cLine: cLineInputLabel,
    label: labelInputLabel,
    pLine: pLineInputLabel,
    territory: territoryInputLabel,
} = inputData.labels;

const releaseOwnershipFormData = [
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: pLineInputLabel,
        name: pLineInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        hasOffset: true,
        label: cLineInputLabel,
        name: cLineInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: labelInputLabel,
        name: labelInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        isAsyncMenu: true,
        label: territoryInputLabel,
        name: territoryInputName,
        selectionKey: territoriesSelectionKey,
        type: autoCompleteInputType,
    },
];

export default releaseOwnershipFormData;
