import ClearIcon from "@mui/icons-material/Clear";
import { Box, Chip, FormGroup } from "@mui/material";
import { SelectMenu } from "atoms";
import { identifiersData, inputData, namesData } from "data";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setCategoryFilterData } from "redux/actions";
import { StyledCategoryFilterInput, StyledTextField } from "styles";

const CategoryFilterInput = ({
    categoriesData,
    label: inputLabel,
    name,
    setValue,
}) => {
    const dispatch = useDispatch();

    const {
        inputValue,
        selectedCategory,
        tagsArray,
    } = useSelector((state) => state.filtersReducer.categoryFilter[name]) || {};

    const { t } = useTranslation();

    const {
        classNames: {
            categoryFilterBox: categoryFilterBoxClassName,
            categoryFilterSelectMenu: categoryFilterSelectMenuClassName,
            categoryFilterSelectMenuFormControl: categoryFilterSelectMenuFormControlClassName,
            categoryFilterTagsBox: categoryFilterTagsBoxClassName,
            categoryFilterTextField: categoryFilterTextFieldClassName,
        },
        ids: {
            input: inputId,
            label: labelId,
            selectMenu: selectMenuId,
        },
    } = identifiersData;

    const { categoryFilter: categoryFilterInputName } = inputData.names;

    const keyDownSearchInputHandler = (event) => {
        const value = inputValue;

        switch (event.key) {
        case ",":
        case "Tab": {
            event.preventDefault();

            event.stopPropagation();

            if (value.length > 0) {
                dispatch(setCategoryFilterData({
                    inputValue: "",
                    name,
                }));

                if (value.includes(",")) {
                    dispatch(setCategoryFilterData({
                        name,
                        tagsArray: value.split(",").map((tag) => tag.trim()),
                    }));

                    setValue(
                        categoryFilterInputName,
                        {
                            [name]: {
                                category: selectedCategory,
                                tagsArray: value.split(",").map((tag) => tag.trim()),
                            },
                        },
                        { shouldDirty: true },
                    );
                } else {
                    dispatch(setCategoryFilterData({
                        name,
                        tagsArray: tagsArray.concat([value.trim()]),
                    }));

                    setValue(
                        categoryFilterInputName,
                        {
                            [name]: {
                                category: selectedCategory,
                                tagsArray: tagsArray.concat([value.trim()]),
                            },
                        },
                        { shouldDirty: true },
                    );
                }

                    const tagsBox = document.querySelector(`.${identifiersData.classNames.categoryFilterTagsBox}`); // eslint-disable-line

                if (tagsBox) tagsBox.scrollLeft = tagsBox.scrollWidth;
            }
            break;
        }
        default:
        }
    };

    const deleteCategoryFilterTagHandler = (index) => {
        const filteredTagsNewArray = tagsArray.filter((_, i) => i !== index);

        dispatch(setCategoryFilterData({
            name,
            tagsArray: filteredTagsNewArray,
        }));

        setValue(
            categoryFilterInputName,
            {
                [name]: {
                    category: selectedCategory,
                    tagsArray: filteredTagsNewArray,
                },
            },
            { shouldDirty: true },
        );
    };

    const selectCategoryHandler = (e) => {
        dispatch(setCategoryFilterData({
            category: e.target.value,
            name,
        }));

        if (tagsArray.length > 0) {
            setValue(
                categoryFilterInputName,
                {
                    [name]: {
                        category: e.target.value,
                        tagsArray,
                    },
                },
                { shouldDirty: true },
            );
        }
    };

    return (
        <StyledCategoryFilterInput
            className={categoryFilterBoxClassName}
            id={`${name}_${inputId}`}
            inputValue={inputValue}
            options={[]}
            value={tagsArray}
            clearIcon={(
                <Box>
                    <Box ml={1}>
                        <ClearIcon fontSize="small" />
                    </Box>
                </Box>
            )}
            renderInput={(inputParams) => {
                inputParams.InputProps.onKeyDown = keyDownSearchInputHandler; // eslint-disable-line

                return (
                    <FormGroup>
                        <StyledTextField
                            {...inputParams}
                            className={categoryFilterTextFieldClassName}
                            placeholder={t(`labels.${inputLabel}`)}
                            variant="outlined"
                            InputProps={{
                                ...inputParams.InputProps,
                                startAdornment: (
                                    <>
                                        <SelectMenu
                                            className={categoryFilterSelectMenuClassName}
                                            formControlClassName={categoryFilterSelectMenuFormControlClassName}
                                            label={t("labels.category")}
                                            labelId={`${categoryFilterInputName}_${selectMenuId}_${labelId}`}
                                            name={namesData.menus.categoryFilter}
                                            value={selectedCategory}
                                            options={categoriesData.map(({
                                                label,
                                                value,
                                            }) => ({
                                                label: t(`labels.${label}`),
                                                value,
                                            }))}
                                            onChange={(e) => selectCategoryHandler(e)}
                                        />
                                        {inputParams.InputProps.startAdornment}
                                    </>
                                ),
                            }}
                        />
                    </FormGroup>
                );
            }}
            renderTags={(_, getTagProps) => (
                <Box
                    className={categoryFilterTagsBoxClassName}
                    display="flex"
                    flexWrap="nowrap"
                    maxHeight={40}
                    overflow="auto"
                >
                    {tagsArray.filter((tag) => tag !== undefined).filter((tag) => tag !== "").map((tag, index) => (
                        <Chip
                            key={tag}
                            label={tag}
                            variant="outlined"
                            {...getTagProps({ index })}
                            onDelete={() => deleteCategoryFilterTagHandler(index)}
                        />
                    ))}
                </Box>
            )}
            freeSolo
            multiple
            onChange={(_, value) => {
                dispatch(setCategoryFilterData({
                    name,
                    tagsArray: value,
                }));

                setValue(
                    categoryFilterInputName,
                    {
                        [name]: {
                            category: selectedCategory,
                            tagsArray: value,
                        },
                    },
                    { shouldDirty: true },
                );
            }}
            onInputChange={(_, value) => {
                dispatch(setCategoryFilterData({
                    inputValue: value,
                    name,
                }));
            }}
        />
    );
};

export default CategoryFilterInput;

CategoryFilterInput.propTypes = {
    categoriesData: PropTypes.array,
    label: PropTypes.string,
    name: PropTypes.string,
    setValue: PropTypes.func,
};
