import { SET_REFETCH_REVENUE_REPORTS_LIST, TOGGLE_REVENUE_REPORT_DRAWER } from "redux/types";
import { updateStateHandler } from "utils";

const initialState = {
    openDrawer: false,
    refetch: false,
};

const setRefetchRevenueReportsList = (state, refetch) => updateStateHandler(
    state,
    { refetch },
);

const toggleRevenueReportDrawer = (state, open) => updateStateHandler(
    state,
    { openDrawer: open },
);

const revenueReportsReducer = (state = initialState, action) => {
    const {
        payload,
        type,
    } = action;

    const {
        open,
        refetch,
    } = payload || {};

    switch (type) {
    case TOGGLE_REVENUE_REPORT_DRAWER: return toggleRevenueReportDrawer(
        state,
        open,
    );
    case SET_REFETCH_REVENUE_REPORTS_LIST: return setRefetchRevenueReportsList(
        state,
        refetch,
    );
    default:
        return state;
    }
};

export default revenueReportsReducer;
