import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import {
    AutoCompleteMenu,
    Calendar,
    FileLink,
    UploadInput,
} from "atoms";
import { DynamicXmlFields, FormContainer } from "components";
import { dynamicXmlFormData, fileData, inputData } from "data";
import { useUploadFile } from "hooks";
import { PropTypes } from "prop-types";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDynamicXMLSchema } from "schemas";
import { deleteFileHandler, renderFormControllerHandler } from "utils";

const DynamicXmlForm = ({
    action,
    asyncMenus,
}) => {
    const [fields, setFields] = useState([{
        key: "",
        value: "",
    }]);

    const { t } = useTranslation();

    const selections = useSelector((state) => state.selectionsReducer);

    const schema = useDynamicXMLSchema();

    const formMethods = useForm({
        defaultValues: {},
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const {
        control,
        formState: { errors },
        setValue,
    } = formMethods;

    const {
        types: {
            autoComplete: autoCompleteInputType,
            date: dateInputType,
            file: fileInputType,
            title: titleInputType,
            titleWithTopLine: titleWithTopLineInputType,
        },
    } = inputData;

    const {
        filesData,
        filesLoading,
        setFilesData,
        uploadFile,
    } = useUploadFile();

    const onSubmitForm = async ({
        client,
        release,
        releaseDate,
        territory,
        xmlFields,
    }) => {
        await uploadFile(
            filesData.xmlFile,
            formMethods,
            fileData.names.xml,
            null,
            {
                clientId: client,
                releaseDate,
                releaseId: release,
                territoryId: territory,
                xmlForm: xmlFields,
            },
        );
    };

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        type,
        isOptional,
        selectionKey,
        isAsyncMenu,
    ) => {
        switch (type) {
        case fileInputType:
            return (
                <Box mb={1}>
                    <UploadInput
                        accept={fileData.acceptedExtensions.xml}
                        color={errors[name] ? "error" : "primary"}
                        error={errors[name]?.message}
                        field={field}
                        hasError={!!errors[name]}
                        label={t(`labels.${label}`)}
                        onChange={(e) => {
                            setFilesData((prev) => ({
                                ...prev,
                                [name]: e.target.files[0],
                            }));
                        }}

                    />
                    {filesData?.[name] && (
                        <FileLink
                            name={filesData?.[name]?.name}
                            deleteAction={() => deleteFileHandler(
                                setFilesData,
                                formMethods,
                                name,
                            )}
                            hasDelete
                        />
                    )}

                </Box>
            );
        case titleInputType:
        case titleWithTopLineInputType:
            return (
                <Box>
                    {type === titleWithTopLineInputType && <hr style={{ marginBlock: 30 }} />}
                    <Typography
                        color="primary"
                        component="h4"
                        variant="h6"
                    >
                        {t(`labels.${label}`)}
                    </Typography>
                </Box>
            );
        case autoCompleteInputType:
            return (
                <AutoCompleteMenu
                    asyncMenuData={asyncMenus?.[selectionKey]}
                    errors={errors}
                    getRenderedOption={(option) => option?.label}
                    isAsyncMenu={isAsyncMenu}
                    label={label}
                    name={name}
                    optionLabel={(option) => option?.label}
                    options={selections?.[selectionKey]}
                    onChange={(_, option) => field.onChange(option?.value)}
                />
            );
        case dateInputType:
            return (
                <Calendar
                    errors={errors}
                    field={field}
                    isOptional={isOptional}
                    label={label}
                    name={name}
                    value={field.value || null}
                />
            );
        }
    };

    return (
        <FormContainer
            action={action}
            formMethods={formMethods}
            loading={filesLoading?.xmlFile}
            clearAction={() => setFields([{
                key: "",
                value: "",
            }])}
            data={renderFormControllerHandler(
                control,
                dynamicXmlFormData,
                renderFormControllerChildrenHandler,
            )}
            otherData={(
                <DynamicXmlFields
                    fields={fields}
                    setFields={setFields}
                    setValue={setValue}
                />
            )}
            hasClearAfterSubmission
            onSubmitForm={onSubmitForm}
        />
    );
};

export default DynamicXmlForm;

DynamicXmlForm.propTypes = {
    action: PropTypes.string,
    asyncMenus: PropTypes.object,
};
