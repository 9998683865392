import { gql } from "@apollo/client";

const getPermissionsListQuery = gql`
    query getPermissionsList($roleId: RoleInput!) {
        listPermissionsByRole(RoleInput: $roleId) {
            data
        }
    }
`;

export default getPermissionsListQuery;
