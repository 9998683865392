import { Box, TableRow } from "@mui/material";
import {
    Loader,
    Modal,
    TableList,
    TableMenu,
} from "atoms";
import { actionsData, tableHeadCellsData } from "data";
import { useDeleteClientRevenueReportTemplate } from "hooks";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setRevenueReportTemplateData } from "redux/actions";
import { StyledTableCell } from "styles";

const RevenueReportsTemplatesList = ({
    data,
    loading,
}) => {
    const { t } = useTranslation();

    const { id } = useParams();

    const dispatch = useDispatch();

    const { deleteClientRevenueReportTemplate } = useDeleteClientRevenueReportTemplate(parseInt(id));

    const [modalData, setModalData] = useState({
        action: deleteClientRevenueReportTemplate,
        actionType: actionsData["delete"],
        body: t(
            "messages.deleteMsgs.alert",
            { entity: t("labels.template") },
        ),
        id: null,
        open: false,
        title: t("clients.deleteTemplate"),
    });

    if (loading) return <Loader />;

    return (
        <Box>
            <Modal
                modalData={modalData}
                setModalData={setModalData}
            />
            <TableList
                headCells={tableHeadCellsData.clients.revenueReportsTemplates}
                isPaginated={false}
            >
                {data?.map(({
                    id: templateId,
                    templateName,
                    templateObj,
                }, index) => (
                    <TableRow key={templateId}>
                        <StyledTableCell boldCell={+true}>{templateName}</StyledTableCell>
                        <StyledTableCell
                            align="right"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <TableMenu
                                id={id}
                                rowId={index + 1}
                                editAction={() => {
                                    dispatch(setRevenueReportTemplateData({
                                        data: templateObj,
                                        id: templateId,
                                        name: templateName,
                                    }));
                                }}
                                setOpenModal={() => {
                                    setModalData({
                                        ...modalData,
                                        id: parseInt(templateId),
                                        open: true,
                                    });
                                }}
                                hasDelete
                                hasEdit
                                isRevenueReportsTemplatesMenu
                            />
                        </StyledTableCell>
                    </TableRow>
                ))}
            </TableList>
        </Box>
    );
};

export default RevenueReportsTemplatesList;

RevenueReportsTemplatesList.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool,
};
