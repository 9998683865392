import {
    Avatar,
    Box,
    Button,
    Typography,
} from "@mui/material";
import MinimizedLogo from "assets/images/logo-mini.png";
import { identifiersData } from "data";
import { PropTypes } from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Blank = ({
    buttonName,
    buttonText,
    description,
    onClickButton,
    title,
    withoutButton,
}) => {
    const [showLogo, setShowLogo] = useState(true);

    const { t } = useTranslation();

    return (
        <Box>
            {showLogo && (
                <Avatar
                    alt={t("imageAlternatives.logo")}
                    src={MinimizedLogo}
                    imgProps={{
                        loading: "lazy",
                        onError: () => setShowLogo(false),
                    }}
                />
            )}
            <Typography
                component="h1"
                mb={2}
                mt={2}
                textAlign="center"
                variant="h4"
            >
                {title}
            </Typography>
            {description && (
                <Typography
                    mb={2}
                    textAlign="center"
                    variant="body1"
                >
                    {description}
                </Typography>
            )}
            {!withoutButton && (
                <Button
                    id={`${buttonName}_${identifiersData.ids.button}`}
                    variant="contained"
                    onClick={onClickButton}
                >
                    {buttonText}
                </Button>
            )}
        </Box>
    );
};

export default Blank;

Blank.propTypes = {
    buttonName: PropTypes.string,
    buttonText: PropTypes.string,
    description: PropTypes.string,
    onClickButton: PropTypes.func,
    title: PropTypes.string,
    withoutButton: PropTypes.bool,
};
