import { TableRow } from "@mui/material";
import {
    Loader,
    Modal,
    TableList,
    TableMenu,
} from "atoms";
import { ReleaseClientLinkDrawer } from "components";
import {
    actionsData,
    identifiersData,
    tableHeadCellsData,
    urlsData,
} from "data";
import { useDeleteReleaseClientLink } from "hooks";
import { PropTypes } from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { toggleReleaseClientLinkDrawer } from "redux/actions";
import { StyledLink, StyledTableCell } from "styles";

const ReleaseClientLinksList = ({
    data,
    hasTab,
    tabValue,
}) => {
    const [releaseClientLinkId, setReleaseClientLinkId] = useState(null);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const location = useLocation();

    const { id: releaseId } = useParams();

    const {
        count,
        data: releaseClientLinksData,
        limit,
        loading,
        page,
        setLimit,
        setPage,
    } = data || {};

    const { deleteReleaseClientLink } = useDeleteReleaseClientLink(
        limit,
        page,
        parseInt(releaseId),
    );

    const [modalData, setModalData] = useState({
        action: deleteReleaseClientLink,
        actionType: actionsData["delete"],
        body: t(
            "messages.deleteMsgs.alert",
            { entity: `${t("releases.entity")} ${t("labels.clientLink")}` },
        ),
        id: null,
        open: false,
        title: `${t("actions.delete")} ${t("releases.entity")} ${t("labels.link")}`,
    });

    const {
        link: linkId,
        table: tableId,
    } = identifiersData.ids;

    if (loading) return <Loader />;

    return (
        <>
            <Modal
                modalData={modalData}
                setModalData={setModalData}
            />
            <ReleaseClientLinkDrawer
                id={releaseClientLinkId}
                setId={setReleaseClientLinkId}
            />
            <TableList
                count={count}
                hasTab={hasTab}
                headCells={tableHeadCellsData.releases.clientLinks.list}
                limit={limit}
                page={page}
                setLimit={setLimit}
                setPage={setPage}
                tabValue={tabValue}
            >
                {releaseClientLinksData?.map(({
                    client,
                    id,
                    releaseLink,
                    releaseTrack,
                }, index) => (
                    <TableRow key={id}>
                        <StyledTableCell boldCell>
                            {releaseLink}
                        </StyledTableCell>
                        <StyledTableCell>{client?.name}</StyledTableCell>
                        <StyledTableCell boldCell>
                            <StyledLink
                                id={`${tableId}_${linkId}_${index + 1}`}
                                state={{ from: location.pathname + location.search }}
                                to={`${urlsData.routes.tracks.url}/${releaseTrack?.track?.id}`}
                            >
                                {releaseTrack?.track?.title}
                            </StyledLink>
                        </StyledTableCell>
                        <StyledTableCell onClick={(e) => e.stopPropagation()}>
                            <TableMenu
                                rowId={index + 1}
                                editAction={() => {
                                    setReleaseClientLinkId(id);

                                    dispatch(toggleReleaseClientLinkDrawer(true));
                                }}
                                setOpenModal={() => {
                                    setModalData({
                                        ...modalData,
                                        id,
                                        open: true,
                                    });
                                }}
                                hasDelete
                                hasEdit
                            />
                        </StyledTableCell>
                    </TableRow>
                ))}
            </TableList>
        </>
    );
};

export default ReleaseClientLinksList;

ReleaseClientLinksList.propTypes = {
    data: PropTypes.object,
    hasTab: PropTypes.bool,
    tabValue: PropTypes.string,
};
