import { Box, CssBaseline } from "@mui/material";
import { Content, Footer, Navbar } from "components";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { setSearchCategory, setSearchData, setSearchValues } from "redux/actions";

const GroupsLayout = () => {
    const [open, setOpen] = useState(false);

    const dispatch = useDispatch();

    useEffect(
        () => {
            dispatch(setSearchValues([]));

            dispatch(setSearchCategory(""));

            dispatch(
                setSearchData({
                    category: "",
                    values: [],
                }),
            );
        },
        [], // eslint-disable-line
    );

    return (
        <>
            <Box display="flex">
                <CssBaseline />
                <Navbar
                    open={open}
                    isGroupsNavbar
                    onOpenSidebar={() => setOpen(true)}
                />
                <Content open={open}>
                    <Outlet />
                </Content>
            </Box>
            <Footer />
        </>
    );
};

export default GroupsLayout;
