import { gql } from "@apollo/client";

export const getArtistsListQuery = gql`
    query getArtistsList(
        $page: Int = 0,
        $limit: Int = 100000,
        $orderBy: ArtistOrderByInput!,
        $where: ArtistWhereInput!
    ) {
        listArtists(
            page: $page,
            elementsPerPage: $limit,
            orderBy: $orderBy,
            where: $where
        ) {
            posts {
                id
                name
                name_ar
                code
                country {
                    en_name
                }
                releases {
                    id
                }
                tracks {
                    id
                }
                secondary_tracks {
                    id
                }
                image {
                    downloadThumbnail
                }
            }
            meta {
                resultCount
                nodeCount
                currentPage
                totalPages
            }
        }
    }
`;

export const getArtistQuery = gql`
    query getArtist($where: ArtistWhereUniqueInput!) {
        artist(where: $where) {
            code
            id
            isni
            name
            name_ar
            country {
                id
                en_name
            }
            image {
                downloadUri
                downloadThumbnail
            }
            secondary_tracks {
                id
            }
            releases {
                id
            }
            tracks {
                id
            }
        }
    }
`;
