import inputData from "data/input";
import selectionsData from "data/selections";

const {
    clients: clientsSelectionKey,
    distributionFileTypes: distributionFileTypesSelectionKey,
    territories: territoriesSelectionKey,
} = selectionsData.keys;

const {
    autoComplete: autoCompleteInputType,
    date: dateInputType,
} = inputData.types;

const {
    client: clientInputName,
    fileType: fileTypeInputName,
    releaseDate: releaseDateInputName,
    territory: territoryInputName,
} = inputData.names;

const {
    client: clientInputLabel,
    fileType: fileTypeInputLabel,
    releaseDate: releaseDateInputLabel,
    territory: territoryInputLabel,
} = inputData.labels;

const releaseDistributionFormData = [
    {
        col: { xs: 12 },
        isAsyncMenu: true,
        label: clientInputLabel,
        name: clientInputName,
        selectionKey: clientsSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: { xs: 12 },
        isAsyncMenu: true,
        label: territoryInputLabel,
        name: territoryInputName,
        selectionKey: territoriesSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: { xs: 12 },
        label: fileTypeInputLabel,
        name: fileTypeInputName,
        selectionKey: distributionFileTypesSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: { xs: 12 },
        label: releaseDateInputLabel,
        name: releaseDateInputName,
        type: dateInputType,
    },

];

export default releaseDistributionFormData;
