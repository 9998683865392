import inputData from "data/input";
import selectionsData from "data/selections";

const { countries: countriesSelectionKey } = selectionsData.keys;

const {
    multiAutoComplete: multiAutoCompleteInputType,
    text: textInputType,
} = inputData.types;

const {
    countries: countriesInputName,
    name: nameInputName,
} = inputData.names;

const {
    countries: countriesInputLabel,
    name: nameInputLabel,
} = inputData.labels;

const territoryFormData = [
    {
        col: {
            md: 4,
            xs: 12,
        },
        hasOffset: true,
        label: nameInputLabel,
        name: nameInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: countriesInputLabel,
        name: countriesInputName,
        selectionKey: countriesSelectionKey,
        type: multiAutoCompleteInputType,
    },
];

export default territoryFormData;
