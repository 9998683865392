const filtersModulesData = {
    artists: "artists",
    driveManagement: "driveManagement",
    releases: "releases",
    revenueReports: "revenueReports",
    tracks: "tracks",
    videos: "videos",
};

export default filtersModulesData;
