import { Box, Grid } from "@mui/material";
import { VideoPlayer } from "atoms";
import { PageHead, ReleaseVideoCutForm } from "components";
import { actionsData } from "data";
import { useCutVideo, useVideoCutsList } from "hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ReleaseVideoCutsTab = () => {
    const [selectedVideo, setSelectedVideo] = useState(undefined);

    const [markerValues, setMarkerValues] = useState({
        from: null,
        to: null,
    });

    const { t } = useTranslation();

    const {
        cutVideo,
        loading,
    } = useCutVideo(selectedVideo?.value);

    const {
        data,
        loading: videoCutsLoading,
    } = useVideoCutsList(selectedVideo?.value);

    const fromToHandler = (from, to) => parseInt(from) < parseInt(to);

    const formValues = { videoCuts: data };

    return (
        <Box>
            <PageHead title={t("tabs.videoCuts")} />
            <Grid
                spacing={7}
                container
            >
                <Grid
                    md={7}
                    xs={12}
                    item
                >
                    <Box>
                        <ReleaseVideoCutForm
                            action={actionsData.create}
                            loading={loading}
                            selectedVideo={selectedVideo}
                            setMarkerValues={setMarkerValues}
                            setSelectedVideo={setSelectedVideo}
                            values={formValues}
                            videoCutsLoading={videoCutsLoading}
                            onSubmitForm={cutVideo}
                        />
                    </Box>
                </Grid>
                {selectedVideo && (
                    <Grid
                        alignItems="start"
                        display="flex"
                        flexDirection="column"
                        justifyContent="start"
                        md={5}
                        xs={12}
                        item
                    >
                        <PageHead
                            title={t("labels.originalVideo")}
                            titleTag="h2"
                        />
                        <Box
                            display="flex"
                            width="100%"
                        >
                            <VideoPlayer
                                url={`${window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_DOMAIN}${selectedVideo.src}`} //eslint-disable-line
                                markers={!!markerValues.from && !!markerValues.to && fromToHandler(
                                    markerValues.from,
                                    markerValues.to,
                                ) && [
                                    {
                                        id: 1,
                                        time: markerValues.from,
                                        title: t("labels.from"),
                                    },
                                    {
                                        id: 2,
                                        time: markerValues.to,
                                        title: t("labels.to"),
                                    },
                                ]}
                            />
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Box>

    );
};

export default ReleaseVideoCutsTab;
