import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData } from "data";
import { editTrackMutation, getTrackQuery } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert, toggleTrackDrawer } from "redux/actions";
import { displayErrorHandler } from "utils";

const useEditTrack = (trackFileId = null, id, setId) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [editTrackAction, { loading }] = useMutation(
        editTrackMutation,
        {
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: getTrackQuery,
                    variables: {
                        where: { id },
                    },
                },
            ],
        },
    );

    const editTrack = async ({
        composers,
        customId,
        imageFile,
        isrc,
        iswc,
        nameAr,
        nameEn,
        number,
        otherIsrc,
        primaryArtist,
        secondaryArtists,
        trackFile,
        writers,
    }) => {
        try {
            await editTrackAction({
                onCompleted: () => {
                    dispatch(toggleTrackDrawer(false));

                    setId(null);

                    dispatch(openAlert(
                        t(
                            "messages.editMsgs.success",
                            { entity: t("tracks.entity") },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: {
                    data: {
                        artist: primaryArtist && {
                            connect: { id: primaryArtist },
                        },
                        composers: composers ? { set: composers } : null,
                        ...imageFile && {
                            image: {
                                connect: { id: imageFile },
                            },
                        },
                        isrc: { set: isrc },
                        custom_id: { set: customId }, //eslint-disable-line
                        isrc_other: otherIsrc ? { set: otherIsrc }: null, //eslint-disable-line
                        iswc: iswc ? { set: iswc } : null,
                        secondary_artists: { //eslint-disable-line
                            set: secondaryArtists?.map(({
                                id: secondaryArtistId,
                                value,
                            }) => ({ id: secondaryArtistId || value })),
                        },
                        title: nameEn && { set: nameEn },
                        title_ar: nameAr && { set: nameAr }, //eslint-disable-line
                        ...trackFileId !== trackFile && {
                            trackFile: trackFile && {
                                connect: { id: trackFile },
                            },
                        },
                        track_num: number && {  set: number && parseInt(number) }, //eslint-disable-line
                        writers: writers ? { set: writers } : null,
                    },
                    where: { id },
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        editTrack,
        loading,
    };
};

export default useEditTrack;
