import { useMutation } from "@apollo/client";
import {
    constantsData,
    identifiersData,
    namesData,
    statusTypesData,
    urlsData,
} from "data";
import { deleteTrackMutation, getSearchListQuery, getTracksListQuery } from "graph";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useDeleteTrack = (
    releaseId,
    limit,
    page,
    hasSearchMode,
    searchData,
    redirect,
) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [deleteTrackAction, { loading }] = useMutation(
        deleteTrackMutation,
        {
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: hasSearchMode ? getSearchListQuery : getTracksListQuery,
                    variables: hasSearchMode ? {
                        entityId: parseInt(searchData?.category),
                        limit,
                        page,
                        searchValues: searchData?.values,
                    } : {
                        limit,
                orderBy: { track_num: "asc" }, //eslint-disable-line
                        page,
                        where: {
                            AND: {},
                            ...releaseId && {
                                releaseTracks: {
                                    some: {
                                        releaseId: { equals: releaseId },
                                    },
                                },
                            },
                        },
                    },
                },
            ],
        },
    );

    const { deleteTrackPending: deleteTrackPendingToastName } = namesData.toasts;

    const { toast: toastId } = identifiersData.ids;

    const deleteTrack = async (id) => {
        try {
            await deleteTrackAction({
                onCompleted: () => {
                    dispatch(openAlert(
                        t(
                            "messages.deleteMsgs.success",
                            { entity: t("tracks.entity") },
                        ),
                        statusTypesData.success,
                    ));

                    if (redirect) navigate(urlsData.routes.tracks.url);
                },
                variables: {
                    where: { id },
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        } finally {
            toast.dismiss(`${deleteTrackPendingToastName}_${toastId}`);
        }
    };

    useEffect(
        () => {
            if (loading) {
                toast.info(
                    () => t(
                        "messages.deleteMsgs.pending",
                        { entity: t("tracks.entity") },
                    ),
                    { toastId: `${deleteTrackPendingToastName}_${toastId}` },
                );
            }
        },
        [loading], //eslint-disable-line
    );

    return { deleteTrack };
};

export default useDeleteTrack;
