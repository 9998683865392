import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useTerritorySchema = () => {
    const { t } = useTranslation();

    return yup.object({
        countries: yup.array().min(1).required(t("messages.inputMsgs.required")),
        name: yup.string().required(t("messages.inputMsgs.required")),
    });
};

export default useTerritorySchema;
