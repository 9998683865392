import { Box, Grid } from "@mui/material";
import { DetailsText } from "atoms";
import { PageHead } from "components";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";

const ReleaseDetailsOwnership = ({ data }) => {
    const { t } = useTranslation();

    const {
        c_line: cLine,
        label,
        p_line: pLine,
        territory,
    } = data || {};

    return (
        <Box>
            <PageHead
                title={t("labels.ownership")}
                titleTag="h2"
            />
            <Grid
                spacing={2}
                container
            >
                <Grid
                    md={6}
                    xs={12}
                    item
                >
                    {pLine && (
                        <DetailsText
                            info={pLine}
                            tag={t("labels.pLine")}
                        />

                    )}
                </Grid>
                <Grid
                    md={6}
                    xs={12}
                    item
                >
                    {cLine && (
                        <DetailsText
                            info={cLine}
                            tag={t("labels.cLine")}
                        />
                    )}
                </Grid>
                <Grid
                    md={6}
                    xs={12}
                    item
                >
                    {label && (
                        <DetailsText
                            info={label}
                            tag={t("labels.label")}
                        />
                    )}
                </Grid>
                <Grid
                    md={6}
                    xs={12}
                    item
                >
                    {Object?.keys(territory)?.length > 0 && (
                        <DetailsText
                            info={territory?.name}
                            tag={t("labels.territories")}
                        />
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default ReleaseDetailsOwnership;

ReleaseDetailsOwnership.propTypes = { data: PropTypes.object };
