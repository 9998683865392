export const OPEN_FILTERS = "OPEN_FILTERS";

export const CLOSE_FILTERS = "CLOSE_FILTERS";

export const SET_FILTERS = "SET_FILTERS";

export const SET_MODULES_FILTERS = "SET_MODULES_FILTERS";

export const SET_CATEGORY_FILTER_DATA = "SET_CATEGORY_FILTER_DATA";

export const RESET_CATEGORY_FILTER_DATA = "RESET_CATEGORY_FILTER_DATA";

export const CLEAR_FILTERS = "CLEAR_FILTERS";
