import { Box, CssBaseline } from "@mui/material";
import { AudioPlayer } from "atoms";
import {
    Content,
    Footer,
    Navbar,
    Sidebar,
} from "components";
import { useRolesSelectionList } from "hooks";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { setSearchCategory, setSearchData, setSearchValues } from "redux/actions";

const MainLayout = () => {
    const [open, setOpen] = useState(false);

    const dispatch = useDispatch();

    useRolesSelectionList();

    useEffect(
        () => {
            dispatch(setSearchValues([]));

            dispatch(setSearchCategory(""));

            dispatch(
                setSearchData({
                    category: "",
                    values: [],
                }),
            );
        },
        [], // eslint-disable-line
    );

    return (
        <>
            <Box display="flex">
                <CssBaseline />
                <Navbar
                    open={open}
                    onOpenSidebar={() => setOpen(true)}
                />
                <Sidebar
                    open={open}
                    setOpen={setOpen}
                />
                <Content open={open}>
                    <Outlet />
                </Content>
            </Box>
            <Footer />
            <AudioPlayer open={open} />
        </>
    );
};

export default MainLayout;
