import {
    FormControl,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import { identifiersData } from "data";
import { PropTypes } from "prop-types";

const SelectMenu = ({
    className,
    disabled,
    formControlClassName,
    formControlStyle,
    hasFullWidth,
    hasInputLabel,
    inputLabel,
    label,
    labelId,
    name,
    onChange,
    options,
    selected,
    style,
    value,
}) => {
    const {
        option: optionId,
        selectMenu: selectMenuId,
    } = identifiersData.ids;

    return (
        <FormControl
            className={formControlClassName}
            fullWidth={!!hasFullWidth}
            sx={formControlStyle}
        >
            {hasInputLabel && inputLabel}
            <Select
                className={className}
                disabled={disabled}
                id={`${name}_${selectMenuId}`}
                label={label}
                labelId={labelId}
                sx={style}
                value={value}
                onChange={onChange}
            >
                {options?.map((option, index) => (
                /*
                    We still using MUI Menu Item here due to the restriction from the MUI Select component as the Menu Item should be the direct descendant of it.
                    Docs Link:  https://mui.com/material-ui/api/select/
                    Stack Overflow Issue: https://stackoverflow.com/questions/66943324/mui-select-component-with-custom-children-item
                */
                    <MenuItem
                        id={`${name}_${selectMenuId}_${optionId}_${index + 1}`}
                        key={option?.value}
                        selected={index === 0 && selected}
                        value={option?.value}
                        onClick={option?.onClick}
                    >
                        <Typography
                            component="span"
                            mx={1}
                            variant="subtitle1"
                        >
                            {option?.label}
                        </Typography>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectMenu;

SelectMenu.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    formControlClassName: PropTypes.string,
    formControlStyle: PropTypes.object,
    hasFullWidth: PropTypes.bool,
    hasInputLabel: PropTypes.bool,
    inputLabel: PropTypes.node,
    label: PropTypes.string,
    labelId: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    selected: PropTypes.bool,
    style: PropTypes.object,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};
