import { DetailsText } from "atoms";
import { DetailsHeader, TrackDrawer } from "components";
import {
    actionsData,
    identifiersData,
    namesData,
    urlsData,
} from "data";
import { useDeleteTrack, useGetTrackShareLink } from "hooks";
import { PropTypes } from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { toggleTrackDrawer } from "redux/actions";
import { StyledLink } from "styles";
import { convertHourToDurationFormatHandler } from "utils";

const TrackDetailsHeader = ({ data }) => {
    const [trackId, setTrackId] = useState(null);

    const [shareLinkButtonClicked, setShareLinkButtonClicked] = useState(false);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const location = useLocation();

    const { deleteTrack } = useDeleteTrack(
        null,
        25,
        0,
        false,
        false,
        true,
    );

    const [modalData, setModalData] = useState({
        action: deleteTrack,
        actionType: actionsData["delete"],
        body: t(
            "messages.deleteMsgs.alert",
            { entity: t("tracks.entity") },
        ),
        id: null,
        open: false,
        title: t("tracks.deleteTrack"),
    });

    const {
        copyTrackShareLink,
        data: shareLinkData,
    } = useGetTrackShareLink(
        data?.id,
        shareLinkButtonClicked,
    );

    const {
        artist,
        composers,
        custom_id: customId,
        isrc,
        isrc_other: otherIsrc,
        iswc,
        track_num: trackNum,
        trackFile,
        writers,
    } = data || {};

    return (
        <>
            <TrackDrawer
                id={trackId}
                setId={setTrackId}
            />
            <DetailsHeader
                copyLinkAction={shareLinkData ? copyTrackShareLink : () => setShareLinkButtonClicked(true)}
                entity={t("tracks.name").toLowerCase()}
                id={data?.id}
                image={data?.image}
                modalData={modalData}
                setModalData={setModalData}
                title={`${data?.title} | ${data?.title_ar}`}
                columnOneData={(
                    <>
                        <DetailsText
                            info={isrc}
                            tag={t("labels.isrc")}
                        />
                        <DetailsText
                            info={customId}
                            tag={t("labels.customId")}
                        />
                        <DetailsText
                            info={trackNum}
                            tag={t("labels.number")}
                        />
                        {composers && (
                            <DetailsText
                                info={composers}
                                tag={t("labels.composers")}
                            />
                        )}
                        <DetailsText
                            tag={t("labels.primaryArtist")}
                            info={(
                                <StyledLink
                                    id={`${namesData.links.primaryArtist}_${identifiersData.ids.link}`}
                                    state={{ from: location.pathname + location.search }}
                                    to={`${urlsData.routes.artists.url}/${artist?.id}`}
                                >
                                    {artist?.name}
                                </StyledLink>
                            )}
                        />
                    </>
                )}
                columnTwoData={(
                    <>
                        {otherIsrc && (
                            <DetailsText
                                info={otherIsrc}
                                tag={t("labels.otherIsrc")}
                            />
                        )}
                        {iswc && (
                            <DetailsText
                                info={iswc}
                                tag={t("labels.iswc")}
                            />
                        )}
                        <DetailsText
                            info={<time>{convertHourToDurationFormatHandler(trackFile?.duration)}</time>}
                            tag={t("labels.duration")}
                        />
                        {writers && (
                            <DetailsText
                                info={writers}
                                tag={t("labels.writers")}
                            />
                        )}
                    </>
                )}
                editAction={() => {
                    setTrackId(data?.id);

                    dispatch(toggleTrackDrawer(true));
                }}
                hasShareLinkAction
                withoutStyle
            />
        </>
    );
};

export default TrackDetailsHeader;

TrackDetailsHeader.propTypes = { data: PropTypes.object };
