import { SET_SIDEBAR_ACTIVE_TAB } from "redux/types";
import { updateStateHandler } from "utils";

const initialState = {
    activeTab: {
        id: null,
        parentId: null,
    },
};

const setSidebarActiveTab = (state, activeTab) => updateStateHandler(
    state,
    {
        activeTab: { id: activeTab?.id },
    },
);

const sidebarActiveTabReducer = (state = initialState, action) => {
    const {
        payload,
        type,
    } = action;

    const { activeTab } = payload || {};

    switch (type) {
    case SET_SIDEBAR_ACTIVE_TAB: return setSidebarActiveTab(
        state,
        activeTab,
    );
    default: return state;
    }
};

export default sidebarActiveTabReducer;
