import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { selectionsData } from "data";
import { getRevenueReportsLabelsListQuery } from "graph";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelection } from "redux/actions";

const useRevenueReportsLabelsList = (
    paginated = true,
    hasAutoCompleteMenu = false,
    searchValue,
) => {
    const [fetched, setFetched] = useState(false);

    const [page, setPage] = useState(1);

    const [limit, setLimit] = useState(25);

    const dispatch = useDispatch();

    const selections = useSelector((state) => state.selectionsReducer);

    const { isAuthenticated } = useAuth0();

    const { revenueReportsLabels: revenueReportsLabelsKey } = selectionsData.keys;

    const {
        data,
        error,
        loading,
        refetch,
    } = useQuery(
        getRevenueReportsLabelsListQuery,
        {
            onCompleted: ({ listRevenueReport }) => {
                if (!paginated || hasAutoCompleteMenu) {
                    const fetchedList = listRevenueReport?.posts?.map(({
                        id,
                        labelName,
                    }) => ({
                        label: labelName,
                        value: id,
                    }));

                    const labelsSet = new Set();

                    const filteredList = [];

                    fetchedList.forEach((report) => {
                        if (report.label !== null && report.label !== "" && !labelsSet.has(report.label)) {
                            filteredList.push(report);

                            labelsSet.add(report.label);
                        }
                    });

                    dispatch(setSelection({
                        [revenueReportsLabelsKey]: selections?.[revenueReportsLabelsKey]?.length > 0 && hasAutoCompleteMenu ? selections?.[revenueReportsLabelsKey].concat(filteredList) : filteredList,
                    }));

                    setFetched(true);
                }
            },
            ...hasAutoCompleteMenu && { notifyOnNetworkStatusChange: true },
            skip: !isAuthenticated,
            variables: {
                ...paginated && {
                    limit,
                    page: page - 1,
                },
                ...searchValue && {
                    where: {
                        labelName: { contains: searchValue },
                    },
                },
            },
        },
    );

    useEffect(
        () => {
            dispatch(setSelection({ [revenueReportsLabelsKey]: [] }));
        },
        [], // eslint-disable-line
    );

    return {
        count: data?.autoCompleteSearch?.meta?.nodeCount,
        data: data?.autoCompleteSearch?.posts,
        error,
        fetched,
        limit,
        loading,
        page: data?.autoCompleteSearch?.meta?.currentPage,
        refetch,
        searchKey: "labelName",
        setFetched,
        setLimit,
        setPage,
    };
};

export default useRevenueReportsLabelsList;
