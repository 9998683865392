import { useMutation } from "@apollo/client";
import { constantsData, importerConfigData, statusTypesData } from "data";
import { createReleaseNoteMutation, getReleaseQuery } from "graph";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useCreateReleaseNote = (withoutRefetch, withoutAlert) => {
    const [controller, setController] = useState(null);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const release = useSelector((state) => state.releasesReducer).currentRelease;

    const [createReleaseNoteAction, { loading }] = useMutation(
        createReleaseNoteMutation,
        {
            ...!withoutRefetch && {
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: getReleaseQuery,
                        variables: {
                            where: { id: release?.id },
                        },

                    },
                ],
            },
        },
    );

    const createReleaseNote = async (
        { note },
        id,
        importerData,
    ) => {
        if (window?.__RUNTIME_CONFIG__?.ENABLE_DEBUG_LOGS === "true" && importerData) { // eslint-disable-line
            console.log(
                "releaseIdFromCreateReleaseNoteHook",
                id,
            );
        }

        try {
            const newController = new AbortController();

            setController(newController);

            importerData?.setCurrentStep(importerConfigData.steps.creatingNote);

            await createReleaseNoteAction({
                context: {
                    fetchOptions: { signal: newController.signal },
                },
                onCompleted: () => {
                    if (!withoutAlert) {
                        dispatch(openAlert(
                            t(
                                "messages.createMsgs.success",
                                { entity: `${t("releases.entity")} ${t("labels.note")}` },
                            ),
                            statusTypesData.success,
                        ));
                    }

                    if (importerData) {
                        importerData?.setStatus((prev) => ({
                            ...prev,
                            release: {
                                ...prev.release,
                                noteCreated: true,
                            },
                        }));
                    }
                },
                variables: {
                    data: {
                        content: note,
                        release: {
                            connect: {
                                id: release?.id || id,
                            },
                        },
                    },
                },

            });
        } catch (err) {
            if (!withoutAlert) {
                displayErrorHandler(
                    err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                    dispatch,
                    openAlert,
                );
            }

            if (importerData) {
                dispatch(importerData?.setInvalidImporterRelease(
                    {
                        originalData: importerData?.originalData,
                        release: {
                            data: importerData?.release,
                            message: err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                        },
                        ...err.message === constantsData.failedToFetch && { isNetworkFailed: true },
                    },
                ));

                importerData?.setStatus((prev) => ({
                    ...prev,
                    release: {
                        ...prev.release,
                        loading: false,
                        noteCreated: false,
                        success: false,
                    },
                }));
            }
        } finally {
            setController(null);

            if (importerData) importerData?.setCurrentStep(null);
        }
    };

    return {
        cancelRequest: () => controller && controller.abort(),
        createReleaseNote,
        loading,
    };
};

export default useCreateReleaseNote;
