import { useAuth0 } from "@auth0/auth0-react";
import {
    constantsData, identifiersData,
    namesData,
    statusTypesData, urlsData,
} from "data";
import { useApisClient } from "hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useEditDriveManagementUserPermission = (setCurrentPermissions) => {
    const [loading, setLoading] = useState(false);

    const {
        files: { url: driveManagementFilesApiUrl },
        users: { permissions: driveManagementUsersPermissionsApiUrl },
    } = urlsData.apis.driveManagement;

    const { t } = useTranslation();

    const { logout } = useAuth0();

    const { patch } = useApisClient();

    const dispatch = useDispatch();

    const { editDriveManagementUserPermissionPending: editDriveManagementUserPermissionPendingToastName } = namesData.toasts;

    const { toast: toastId } = identifiersData.ids;

    const editDriveManagementUserPermission = (fileId, permissionId, permission) => {
        setLoading(true);

        patch(
            `${driveManagementFilesApiUrl}/${fileId}/${driveManagementUsersPermissionsApiUrl}`,
            permissionId,
            { role: permission },
        ).then(() => {
            setCurrentPermissions((prev) => ({
                ...prev,
                [permissionId]: permission,
            }));

            dispatch(openAlert(
                t(
                    "messages.editMsgs.success",
                    { entity: t("labels.permission") },
                ),
                statusTypesData.success,
            ));
        })["catch"]((err) => {
            displayErrorHandler(
                err.response.data.message === constantsData.networkError ? t("messages.networkFailure") : err.response.data.message,
                dispatch,
                openAlert,
                err.response.status,
                logout,
            );
        })["finally"](() => {
            toast.dismiss(`${editDriveManagementUserPermissionPendingToastName}_${toastId}`);

            setLoading(false);
        });
    };

    useEffect(
        () => {
            if (loading) {
                toast.info(
                    () => t(
                        "messages.editMsgs.pending",
                        { entity: t("labels.permission") },
                    ),
                    { toastId: `${editDriveManagementUserPermissionPendingToastName}_${toastId}` },
                );
            }
        },
        [loading], //eslint-disable-line
    );
    return {
        editDriveManagementUserPermission,
        loading,
    };
};

export default useEditDriveManagementUserPermission;
