import {
    CLEAR_IMPORTER_RELEASES,
    REMOVE_CURRENT_RELEASE,
    REMOVE_INVALID_IMPORTER_RELEASE,
    SET_COMPLETED_IMPORTER_RELEASE,
    SET_CURRENT_RELEASE,
    SET_INVALID_IMPORTER_RELEASE,
    SET_RELEASE_TRACKS,
    STOP_RELEASE_IMPORTING,
    TOGGLE_RELEASE_CLIENT_LINK_DRAWER,
} from "redux/types";

export const setCurrentRelease = (release) => ({
    payload: { release },
    type: SET_CURRENT_RELEASE,
});

export const removeCurrentRelease = () => ({ type: REMOVE_CURRENT_RELEASE });

export const toggleReleaseClientLinkDrawer = (open) => ({
    payload: { open },
    type: TOGGLE_RELEASE_CLIENT_LINK_DRAWER,
});

export const setCompletedImporterRelease = (release) => ({
    payload: { release },
    type: SET_COMPLETED_IMPORTER_RELEASE,
});

export const setReleaseTracks = (tracks) => ({
    payload: { tracks },
    type: SET_RELEASE_TRACKS,
});

export const setInvalidImporterRelease = (data) => ({
    payload: { data },
    type: SET_INVALID_IMPORTER_RELEASE,
});

export const removeInvalidImporterRelease = (upc) => ({
    payload: { upc },
    type: REMOVE_INVALID_IMPORTER_RELEASE,
});

export const stopReleaseImporting = (data) => ({
    payload: { data },
    type: STOP_RELEASE_IMPORTING,
});

export const clearImporterReleases = () => ({ type: CLEAR_IMPORTER_RELEASES });
