import { TOGGLE_DRIVE_LINK_DRAWER } from "redux/types";
import { updateStateHandler } from "utils";

const initialState = { openDrawer: false };

const toggleDriveLinkDrawer = (state, open) => updateStateHandler(
    state,
    { openDrawer: open },
);

const driveLinksReducer = (state = initialState, action) => {
    const {
        payload,
        type,
    } = action;

    const { open } = payload || {};

    switch (type) {
    case TOGGLE_DRIVE_LINK_DRAWER: return toggleDriveLinkDrawer(
        state,
        open,
    );
    default:
        return state;
    }
};

export default driveLinksReducer;
