import inputData from "data/input";
import selectionsData from "data/selections";

const {
    fileTypes: fileTypesSelectionKey,
    trackFileNames: trackFileNamesSelectionKey,
    trackTypes: trackTypesSelectionKey,
} = selectionsData.keys;

const { autoComplete: autoCompleteInputType } = inputData.types;

const {
    fileExtension: fileExtensionInputName,
    fileName: fileNameInputName,
    fileType: fileTypeInputName,
} = inputData.names;

const {
    fileExtension: fileExtensionInputLabel,
    fileName: fileNameInputLabel,
    fileType: fileTypeInputLabel,
} = inputData.labels;

const foldersStructureTrackFileFormData = [
    {
        col: { xs: 12 },
        label: fileTypeInputLabel,
        name: fileTypeInputName,
        selectionKey: fileTypesSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: { xs: 12 },
        label: fileExtensionInputLabel,
        name: fileExtensionInputName,
        selectionKey: trackTypesSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: { xs: 12 },
        label: fileNameInputLabel,
        name: fileNameInputName,
        selectionKey: trackFileNamesSelectionKey,
        type: autoCompleteInputType,
    },
];

export default foldersStructureTrackFileFormData;
