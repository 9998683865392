import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
    Box,
    Button,
    Menu as MuiMenu,
    Zoom,
} from "@mui/material";
import { MenuItem } from "atoms";
import { identifiersData } from "data";
import PropTypes from "prop-types";
import { useState } from "react";

const Menu = ({
    label,
    name,
    options,
    style,
    withTransparentButton,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const {
        button: buttonId,
        menu: menuId,
        option: optionId,
    } = identifiersData.ids;

    const openMenuHandler = (event) => setAnchorEl(event.currentTarget);

    const closeMenuHandler = () => setAnchorEl(null);

    return (
        <Box>
            <Button
                color={withTransparentButton ? "inherit" : "primary"}
                id={`${name}_${menuId}_${buttonId}`}
                size="medium"
                variant={withTransparentButton ? "outlined" : "contained"}
                endIcon={(
                    <KeyboardArrowDownIcon
                        sx={{
                            transform: open && "rotate(180deg)",
                            transition: "ease-in-out .2s",
                        }}
                    />
                )}
                sx={{
                    "&:hover": { border: "none" },
                    border: "none",
                    ...style,
                }}
                onClick={openMenuHandler}
            >
                {label}
            </Button>
            <MuiMenu
                TransitionComponent={Zoom}
                anchorEl={anchorEl}
                open={open}
                onClose={closeMenuHandler}
            >
                <Box
                    id={`${name}_${menuId}`}
                    onClick={closeMenuHandler}
                >
                    {options?.map(({
                        action,
                        disabled,
                        hidden,
                        icon,
                        isDelete,
                        label: optionLabel,
                    }, index) => !hidden && (
                        <MenuItem
                            disabled={disabled}
                            icon={icon}
                            id={`${name}_${menuId}_${optionId}_${index + 1}`}
                            isDelete={isDelete}
                            key={optionLabel}
                            label={optionLabel}
                            onClick={action}
                        />
                    ))}
                </Box>
            </MuiMenu>
        </Box>
    );
};

export default Menu;

Menu.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.array,
    style: PropTypes.object,
    withTransparentButton: PropTypes.bool,
};
