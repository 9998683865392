import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import {
    Avatar,
    Box,
    Checkbox,
    TableRow,
} from "@mui/material";
import { Modal, TableList, TableMenu } from "atoms";
import {
    actionsData,
    identifiersData,
    inputData,
    namesData,
    tableHeadCellsData,
    urlsData,
} from "data";
import {
    useDeleteRelease,
    useDownloadReleasesContent,
    useExportReleases,
    useSelectTableRows,
} from "hooks";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { StyledLink, StyledTableCell } from "styles";
import { convertDateToLocaleStringFormatHandler, convertHourToDurationFormatHandler } from "utils";

const ReleasesSearchList = ({
    count,
    data,
    limit,
    page,
    setLimit,
    setPage,
}) => {
    const { t } = useTranslation();

    const searchValues = useSelector((state) => state.searchReducer.searchValues);

    const { downloadReleasesContent } = useDownloadReleasesContent();

    const location = useLocation();

    const searchCategory = useSelector((state) => state.searchReducer.searchCategory);

    const {
        isSelected,
        selectAllHandler,
        selectRowHandler,
        selected,
        setSelected,
    } = useSelectTableRows();

    const { deleteRelease } = useDeleteRelease(
        false,
        page,
        limit,
        null,
        true,
        {
            category: searchCategory || 1,
            values: searchValues,
        },
    );

    const { exportReleases } = useExportReleases();

    const [modalData, setModalData] = useState({
        action: deleteRelease,
        actionType: actionsData["delete"],
        body: t(
            "messages.deleteMsgs.alert",
            { entity: t("releases.entity") },
        ),
        id: null,
        open: false,
        title: t("releases.deleteRelease"),
    });

    const {
        artists: { url: artistsRouteUrl },
        releases: {
            edit: editReleaseRouteUrl,
            url: releasesRouteUrl,
        },
    } = urlsData.routes;

    const {
        input: inputId,
        link: linkId,
        table: tableId,
    } = identifiersData.ids;

    return (
        <Box
            alignItems="end"
            display="flex"
            flexDirection="column"
            mt={5}
        >
            <Modal
                modalData={modalData}
                setModalData={setModalData}
            />

            <TableList
                count={count}
                downloadContentAction={downloadReleasesContent}
                exportAction={exportReleases}
                headCells={tableHeadCellsData.releases.list}
                limit={limit}
                page={page}
                selectAllAction={selectAllHandler}
                selected={selected}
                setLimit={setLimit}
                setPage={setPage}
                setSelected={setSelected}
                tableData={data}
                hasDistribute
                hasDownloadContent
                hasExport
                hasSearchMode
                isSelectable
            >
                {data?.map(({
                    album_name: name,
                    artist,
                    duration,
                    id,
                    release_date: releaseDate,
                    releaseImage: image,
                    releaseTracks,
                    releaseType,
                    upc,
                }, index) => (
                    <TableRow
                        key={id}
                        role="checkbox"
                        selected={isSelected(id)}
                        tabIndex={-1}
                        aria-checked
                        hover
                        onClick={() => selectRowHandler(id)}
                    >
                        <StyledTableCell padding="checkbox">
                            <Checkbox
                                checked={isSelected(id)}
                                color="primary"
                                id={`${tableId}_${inputData.names.select}_${inputId}_${index + 1}`}
                            />
                        </StyledTableCell>
                        <StyledTableCell>
                            <Avatar
                                alt={t("imageAlternatives.release")}
                                imgProps={{ loading: "lazy" }}
                                src={`${image?.downloadThumbnail ? window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_DOMAIN + image?.downloadThumbnail : ""}`} // eslint-disable-line
                            >
                                <LibraryMusicIcon />
                            </Avatar>
                        </StyledTableCell>
                        <StyledTableCell boldCell={+true}>
                            <StyledLink
                                id={`${tableId}_${linkId}_${index + 1}`}
                                state={{ from: location.pathname + location.search }}
                                to={{ pathname: `${releasesRouteUrl}/${id}` }}
                            >
                                {name}
                            </StyledLink>
                        </StyledTableCell>
                        <StyledTableCell>
                            <StyledLink
                                id={`${tableId}_${namesData.links.primaryArtist}_${linkId}_${index + 1}`}
                                state={{ from: location.pathname + location.search }}
                                to={`${artistsRouteUrl}/${artist?.id}`}
                            >
                                {artist?.name}
                            </StyledLink>
                        </StyledTableCell>
                        <StyledTableCell>{releaseType?.name}</StyledTableCell>
                        <StyledTableCell>
                            <time>{convertDateToLocaleStringFormatHandler(releaseDate)}</time>
                        </StyledTableCell>
                        <StyledTableCell>{upc}</StyledTableCell>
                        <StyledTableCell>{releaseTracks?.length}</StyledTableCell>
                        <StyledTableCell>
                            <time>{convertHourToDurationFormatHandler(duration)}</time>
                        </StyledTableCell>
                        <StyledTableCell onClick={(e) => e.stopPropagation()}>
                            <TableMenu
                                editRouteUrl={`${editReleaseRouteUrl}/${id}`}
                                rowId={index + 1}
                                setOpenModal={() => {
                                    setModalData({
                                        ...modalData,
                                        id,
                                        open: true,
                                    });
                                }}
                                hasDelete
                                hasEdit
                                isReleasesTable
                            />
                        </StyledTableCell>
                    </TableRow>
                ))}
            </TableList>
        </Box>
    );
};

export default ReleasesSearchList;

ReleasesSearchList.propTypes = {
    count: PropTypes.number,
    data: PropTypes.array,
    limit: PropTypes.number,
    page: PropTypes.number,
    setLimit: PropTypes.func,
    setPage: PropTypes.func,
};
