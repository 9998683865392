import { useMutation } from "@apollo/client";
import {
    constantsData,
    importerConfigData,
    statusTypesData,
    urlsData,
} from "data";
import { createArtistMutation } from "graph";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useCreateArtist = (withoutRedirection, withoutAlert) => {
    const [controller, setController] = useState(null);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [createArtistAction, { loading }] = useMutation(createArtistMutation);

    const createArtist = async ({
        code,
        imageFile,
        isni,
        nameAr,
        nameEn,
        nationality,
    }, importerData) => {
        try {
            const newController = new AbortController();

            setController(newController);

            if (importerData?.creatingArtist) importerData?.setCurrentStep(importerConfigData.steps.creatingArtist);

            if (importerData?.creatingSecondaryArtist) importerData?.setCurrentStep(importerConfigData.steps.creatingSecondaryArtist);

            await createArtistAction({
                context: {
                    fetchOptions: { signal: newController.signal },
                },
                onCompleted: ({ createOneArtist }) => {
                    if (importerData) {
                        if (importerData?.creatingArtist) {
                            importerData?.setArtistId(createOneArtist?.id);

                            importerData?.setStatus((prev) => ({
                                ...prev,
                                artist: {
                                    ...prev.artist,
                                    loading: false,
                                    searching: false,
                                    success: true,
                                },
                                releaseImage: {
                                    ...prev.releaseImage,
                                    loading: true,
                                },
                            }));

                            importerData?.uploadReleaseImageHandler(importerData);
                        }

                        if (importerData?.creatingSecondaryArtist) {
                            importerData?.setNonExistingSecondaryArtists((prev) => [
                                ...prev,
                                { ...createOneArtist },
                            ]);
                        }
                    }

                    if (!withoutRedirection) navigate(urlsData.routes.artists.url);

                    if (!withoutAlert) {
                        dispatch(openAlert(
                            t(
                                "messages.createMsgs.success",
                                { entity: t("artists.entity") },
                            ),
                            statusTypesData.success,
                        ));
                    }
                },
                variables: {
                    data: {
                        code: parseInt(code),
                        country: {
                            connect: { id: nationality },
                        },
                        ...imageFile && {
                            image: {
                                connect: { id: imageFile },
                            },
                        },
                        isni,
                        name: nameEn,
                        name_ar: nameAr, //eslint-disable-line
                    },
                },
            });
        } catch (err) {
            if (!withoutAlert) {
                displayErrorHandler(
                    err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                    dispatch,
                    openAlert,
                );
            }

            if (importerData) {
                dispatch(importerData?.setInvalidImporterRelease(
                    {
                        artist: {
                            data: importerData?.artist,
                            message: err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                        },
                        originalData: importerData?.originalData,
                        release: { data: importerData?.release },
                        ...err.message === constantsData.failedToFetch && { isNetworkFailed: true },
                    },
                ));

                if (importerData?.creatingArtist) {
                    importerData?.setStatus((prev) => ({
                        ...prev,
                        artist: {
                            ...prev.artist,
                            loading: false,
                            searching: false,
                            success: false,
                        },
                    }));
                }

                if (importerData?.creatingSecondaryArtist) {
                    importerData?.setStatus((prev) => ({
                        ...prev,
                        secondaryArtists: {
                            ...prev.secondaryArtists,
                            loading: false,
                            searching: false,
                            success: false,
                        },
                    }));
                }
            }
        } finally {
            setController(null);

            if (importerData) importerData?.setCurrentStep(null);
        }
    };

    return {
        cancelRequest: () => controller && controller.abort(),
        createArtist,
        loading,
    };
};

export default useCreateArtist;
