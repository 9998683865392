const selectionsData = {
    data: {
        artworkFileNames: [
            {
                label: "UPC",
                value: "UPC",
            },
            {
                label: "Default",
                value: "Default",
            },
        ],
        fileTypes: [
            {
                label: "ArtWork",
                value: "ArtWork",
            },
            {
                label: "Track",
                value: "Track",
            },
            {
                label: "XML",
                value: "xml",
            },
        ],
        folderNames: [
            {
                label: "Time Stamp",
                value: "TimeStamp",
            },
            {
                label: "UPC",
                value: "UPC",
            },
            {
                label: "Release Name",
                value: "ReleaseName",
            },
        ],
        mimeTypes: [
            {
                label: "Audio",
                types: ["audio/3gp", "application/vnd.google-gsuite.encrypted; content=\"audio/3gp\"", "audio/midi", "application/vnd.google-gsuite.encrypted; content=\"audio/midi\"", "audio/mp4a-latm", "application/vnd.google-gsuite.encrypted; content=\"audio/mp4a-latm\"", "audio/mpeg", "application/vnd.google-gsuite.encrypted; content=\"audio/mpeg\"", "audio/mpeg3", "application/vnd.google-gsuite.encrypted; content=\"audio/mpeg3\"", "audio/ogg", "application/vnd.google-gsuite.encrypted; content=\"audio/ogg\"", "audio/x-ms-wma", "application/vnd.google-gsuite.encrypted; content=\"audio/x-ms-wma\"", "audio/x-wav", "application/vnd.google-gsuite.encrypted; content=\"audio/x-wav\"", "audio/wav", "application/vnd.google-gsuite.encrypted; content=\"audio/wav\""],
                value: 1,
            },
            {
                label: "Documents",
                types: ["application/msword", "application/vnd.google-gsuite.encrypted; content=\"application/msword\"", "application/vnd.ms-word", "application/vnd.google-gsuite.encrypted; content=\"application/vnd.ms-word\"", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.google-gsuite.encrypted; content=\"application/vnd.openxmlformats-officedocument.wordprocessingml.document\"", "application/vnd.google-apps.document", "application/vnd.google-gsuite.encrypted; content=\"application/vnd.google-apps.document\"", "application/vnd.google-apps.kix", "application/vnd.google-gsuite.document-blob", "application/vnd.google-gsuite.encrypted; content=\"application/vnd.google-gsuite.document-blob\"", "text/plain", "application/vnd.google-gsuite.encrypted; content=\"text/plain\"", "application/vnd.oasis.opendocument.text", "application/vnd.google-gsuite.encrypted; content=\"application/vnd.oasis.opendocument.text\""],
                value: 2,
            },
            {
                label: "PDF",
                types: ["application/pdf", "application/vnd.google-gsuite.encrypted; content=\"application/pdf\""],
                value: 3,
            },
            {
                label: "Archives (zip)",
                types: ["application/bzip2", "application/gzip", "application/gzip-compressed", "application/gzipped", "application/rar", "application/tar", "application/x-bzip", "application/x-bzip-compressed-tar", "application/x-bzip2", "application/x-gtar", "application/x-gtar-compressed", "application/x-gunzip", "application/x-gzip", "application/x-gzip-compressed", "application/x-rar", "application/x-tar", "application/x-tgz", "application/x-zip", "application/x-zip-compressed", "application/zip", "gzip/document", "multipart/x-gzip", "multipart/x-rar", "multipart/x-tar", "multipart/x-zip", "multipart/zip"],
                value: 4,
            },
            {
                label: "Folders",
                types: ["application/vnd.google-apps.folder"],
                value: 5,
            },
            {
                label: "Forms",
                types: ["application/vnd.google-apps.form", "application/vnd.google-gsuite.encrypted; content=\"application/vnd.google-apps.form\"", "application/vnd.google-apps.freebird", "application/vnd.google-gsuite.encrypted; content=\"application/vnd.google-apps.freebird\""],
                value: 6,
            },
            {
                label: "Photos & Images",
                types: ["image/bmp", "application/vnd.google-gsuite.encrypted; content=\"image/bmp\"", "image/jpeg", "application/vnd.google-gsuite.encrypted; content=\"image/jpeg\"", "image/png", "application/vnd.google-gsuite.encrypted; content=\"image/png\"", "image/gif", "application/vnd.google-gsuite.encrypted; content=\"image/gif\"", "image/tiff", "application/vnd.google-gsuite.encrypted; content=\"image/tiff\"", "image/x-ms-bmp", "application/vnd.google-gsuite.encrypted; content=\"image/x-ms-bmp\"", "image/svg+xml", "application/vnd.google-gsuite.encrypted; content=\"image/svg+xml\"", "image/vnd.microsoft.icon", "application/vnd.google-gsuite.encrypted; content=\"image/vnd.microsoft.icon\"", "image/heif", "application/vnd.google-gsuite.encrypted; content=\"image/heif\"", "image/heic", "application/vnd.google-gsuite.encrypted; content=\"image/heic\"", "image/webp", "application/vnd.google-gsuite.encrypted; content=\"image/webp\""],
                value: 7,
            },
            {
                label: "Presentations",
                types: ["application/mspowerpoint", "application/vnd.google-gsuite.encrypted; content=\"application/mspowerpoint\"", "application/vnd.ms-powerpoint", "application/vnd.google-gsuite.encrypted; content=\"application/vnd.ms-powerpoint\"", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "application/vnd.google-gsuite.encrypted; content=\"application/vnd.openxmlformats-officedocument.presentationml.presentation\"", "application/vnd.google-apps.presentation", "application/vnd.google-gsuite.encrypted; content=\"application/vnd.google-apps.presentation\"", "application/vnd.google-apps.punch", "application/vnd.google-gsuite.presentation-blob", "application/vnd.google-gsuite.encrypted; content=\"application/vnd.google-gsuite.presentation-blob\"", "application/vnd.oasis.opendocument.presentation", "application/vnd.google-gsuite.encrypted; content=\"application/vnd.oasis.opendocument.presentation\""],
                value: 8,
            },
            {
                label: "Spreadsheets",
                types: ["application/msexcel", "application/vnd.google-gsuite.encrypted; content=\"application/msexcel\"", "application/vnd.ms-excel", "application/vnd.google-gsuite.encrypted; content=\"application/vnd.ms-excel\"", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.google-gsuite.encrypted; content=\"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet\"", "application/vnd.google-apps.spreadsheet", "application/vnd.google-gsuite.encrypted; content=\"application/vnd.google-apps.spreadsheet\"", "application/vnd.google-apps.ritz", "application/vnd.google-gsuite.spreadsheet-blob", "application/vnd.google-gsuite.encrypted; content=\"application/vnd.google-gsuite.spreadsheet-blob\"", "application/vnd.oasis.opendocument.spreadsheet", "application/vnd.google-gsuite.encrypted; content=\"application/vnd.oasis.opendocument.spreadsheet\"", "text/csv", "application/vnd.google-gsuite.encrypted; content=\"text/csv\""],
                value: 9,
            },
            {
                label: "Videos",
                types: ["application/x-shockwave-flash", "application/vnd.google-gsuite.encrypted; content=\"application/x-shockwave-flash\"", "video/3gpp", "application/vnd.google-gsuite.encrypted; content=\"video/3gpp\"", "video/avi", "application/vnd.google-gsuite.encrypted; content=\"video/avi\"", "video/dv", "application/vnd.google-gsuite.encrypted; content=\"video/dv\"", "video/flv", "application/vnd.google-gsuite.encrypted; content=\"video/flv\"", "video/mkv", "application/vnd.google-gsuite.encrypted; content=\"video/mkv\"", "video/mp2p", "application/vnd.google-gsuite.encrypted; content=\"video/mp2p\"", "video/mp2t", "application/vnd.google-gsuite.encrypted; content=\"video/mp2t\"", "video/mp4", "application/vnd.google-gsuite.encrypted; content=\"video/mp4\"", "video/mpeg", "application/vnd.google-gsuite.encrypted; content=\"video/mpeg\"", "video/mpv", "application/vnd.google-gsuite.encrypted; content=\"video/mpv\"", "video/ogg", "application/vnd.google-gsuite.encrypted; content=\"video/ogg\"", "video/quicktime", "application/vnd.google-gsuite.encrypted; content=\"video/quicktime\"", "video/x-flv", "application/vnd.google-gsuite.encrypted; content=\"video/x-flv\"", "video/x-msvideo", "application/vnd.google-gsuite.encrypted; content=\"video/x-msvideo\"", "video/x-ms-wmv", "application/vnd.google-gsuite.encrypted; content=\"video/x-ms-wmv\"", "video/x-m4v", "application/vnd.google-gsuite.encrypted; content=\"video/x-m4v\"", "video/x-ms-asf", "application/vnd.google-gsuite.encrypted; content=\"video/x-ms-asf\"", "video/x-matroska", "application/vnd.google-gsuite.encrypted; content=\"video/x-matroska\"", "video/x-dv", "application/vnd.google-gsuite.encrypted; content=\"video/x-dv\"", "video/webm", "application/vnd.google-gsuite.encrypted; content=\"video/webm\""],
                value: 10,
            },
        ],
        trackFileNames: [
            {
                label: "ISRC",
                value: "ISRC",
            },
            {
                label: "Track Name",
                value: "TrackName",
            },
        ],
        xmlFileNames: [
            {
                label: "UPC",
                value: "UPC",
            },
            {
                label: "Time Stamp",
                value: "TimeStamp",
            },
        ],
    },
    keys: {
        artists: "artists",
        artworkFileNames: "artworkFileNames",
        batchNames: "batchNames",
        clients: "clients",
        countries: "countries",
        distributionFileTypes: "distributionFileTypes",
        documentTypes: "documentTypes",
        fileTypes: "fileTypes",
        folderNames: "folderNames",
        genres: "genres",
        imageTypes: "imageTypes",
        mimeTypes: "mimeTypes",
        releaseTypes: "releaseTypes",
        releases: "releases",
        revenueReportsLabels: "revenueReportsLabels",
        revenueReportsSystemFields: "revenueReportsSystemFields",
        rightTypes: "rightTypes",
        roles: "roles",
        territories: "territories",
        trackFileNames: "trackFileNames",
        trackTypes: "trackTypes",
        tracks: "tracks",
        videoTypes: "videoTypes",
        videos: "videos",
        xmlFileNames: "xmlFileNames",
        xmlFileTree: "xmlFileTree",
        xmlFileTypes: "xmlFileTypes",
    },
};

export default selectionsData;
