import inputData from "data/input";
import selectionsData from "data/selections";

const { folderNames: folderNamesSelectionKey } = selectionsData.keys;

const { autoComplete: autoCompleteInputType } = inputData.types;

const { folderName: folderNameInputName } = inputData.names;

const { folderName: textFolderNameInputLabel } = inputData.labels;

const foldersStructureRootFolderFormData = [
    {
        col: { xs: 12 },
        label: textFolderNameInputLabel,
        name: folderNameInputName,
        selectionKey: folderNamesSelectionKey,
        type: autoCompleteInputType,
    },
];

export default foldersStructureRootFolderFormData;
