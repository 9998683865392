import AddCircleIcon from "@mui/icons-material/AddCircle";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import CheckIcon from "@mui/icons-material/Check";
import { Box } from "@mui/material";
import { DebouncedSearchInput, List } from "atoms";
import { inputData, namesData, urlsData } from "data";
import { useAddReleaseTrack, useTracksList } from "hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { theme } from "theme";

const AddExistingTrackTab = () => {
    const [searchValue, setSearchValue] = useState(null);

    const { id: releaseId } = useParams();

    const { t } = useTranslation();

    const releaseTracks = useSelector((state) => state.releasesReducer)?.releaseTracks;

    const { addReleaseTrack } = useAddReleaseTrack(parseInt(releaseId));

    const {
        data,
        loading,
    } = useTracksList(
        true,
        false,
        {},
        null,
        null,
        searchValue,
    );

    return (
        <Box
            bgcolor={theme.palette.grey[100]}
            borderRadius={3}
            p={2}
        >
            <DebouncedSearchInput
                name={inputData.names.tracksSearch}
                setSearchValue={setSearchValue}
            />
            <Box
                sx={{
                    "@media(min-width: 700px) ": { width: " 50%" },
                }}
            >
                <List
                    comparingData={releaseTracks}
                    loading={loading}
                    routeUrl={urlsData.routes.tracks.url}
                    data={data?.map((track) => ({
                        ...track,
                        fallbackImage: <AudiotrackIcon />,
                        name: track?.title,
                    }))}
                    icons={[
                        {
                            Icon: <CheckIcon sx={{ marginRight: 1 }} />,
                            tooltip: t("labels.alreadyExists"),
                        },
                        {
                            Icon: <AddCircleIcon />,
                            action: (id) => {
                                addReleaseTrack(
                                    id,
                                    releaseId,
                                );
                            },
                            name: namesData.buttons.addToRelease,
                            tooltip: t("actions.addToRelease"),
                        },
                    ]}
                />
            </Box>
        </Box>

    );
};

export default AddExistingTrackTab;
