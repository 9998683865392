import { Box, Button, Grid } from "@mui/material";
import { identifiersData, inputData, namesData } from "data";
import { PropTypes } from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StyledTextField } from "styles";

const DynamicXmlFields = ({
    fields,
    setFields,
    setValue,
}) => {
    const { t } = useTranslation();

    const {
        labels: {
            key: keyInputLabel,
            value: valueInputLabel,
        },
        names: {
            key: keyInputName,
            value: valueInputName,
            xmlFields: xmlFieldsInputName,
        },
        types: { text: textInputType },
    } = inputData;

    const {
        addField: addFieldButtonName,
        remove: removeButtonName,
    } = namesData.buttons;

    const { button: buttonId } = identifiersData.ids;

    const addFieldHandler = () => {
        setFields([...fields, {
            key: "",
            value: "",
        }]);
    };

    const removeFieldHandler = (index) => {
        const clonedFields = [...fields];

        clonedFields.splice(
            index,
            1,
        );

        setFields(clonedFields);
    };

    const changeFieldInputValueHandler = (index, name, value) => {
        const clonedFields = [...fields];

        clonedFields[index][name] = value;

        setFields(clonedFields);
    };

    useEffect(
        () => {
            setValue(
                xmlFieldsInputName,
                fields.reduce(
                    (acc, curr) => {
                        if (curr.key.trim() !== "" && curr.value.trim() !== "") acc[curr.key] = curr.value;

                        return acc;
                    },
                    {},
                ),
            );
        },
        [fields, xmlFieldsInputName, setValue],
    );

    return (
        <>
            <Grid
                mt={2}
                overflow="auto"
                spacing={2}
                container
            >
                {fields.map(({
                    key,
                    value,
                }, index) => (
                    <Grid
                        key={index} //eslint-disable-line
                        spacing={2}
                        container
                        item
                    >
                        <Grid
                            xs={5}
                            item
                        >
                            <StyledTextField
                                name={keyInputName}
                                placeholder={t(`labels.${keyInputLabel}`)}
                                type={textInputType}
                                value={key}
                                fullWidth
                                onChange={(e) => changeFieldInputValueHandler(
                                    index,
                                    keyInputName,
                                    e.target.value,
                                )}
                            />
                        </Grid>
                        <Grid
                            xs={5}
                            item
                        >
                            <StyledTextField
                                name={valueInputName}
                                placeholder={t(`labels.${valueInputLabel}`)}
                                type={textInputType}
                                value={value}
                                fullWidth
                                onChange={(e) => changeFieldInputValueHandler(
                                    index,
                                    valueInputName,
                                    e.target.value,
                                )}
                            />
                        </Grid>
                        <Grid
                            xs={2}
                            item
                        >
                            <Button
                                color="error"
                                id={`${removeButtonName}_${buttonId}`}
                                size="large"
                                variant="outlined"
                                onClick={() => removeFieldHandler(index)}
                            >
                                {t("actions.remove")}
                            </Button>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            <Box mt={2}>
                <Button
                    color="success"
                    id={`${addFieldButtonName}_${buttonId}`}
                    size="large"
                    variant="outlined"
                    onClick={addFieldHandler}
                >
                    {t("actions.addField")}
                </Button>
            </Box>
        </>
    );
};

export default DynamicXmlFields;

DynamicXmlFields.propTypes = {
    fields: PropTypes.array,
    setFields: PropTypes.func,
    setValue: PropTypes.func,
};
