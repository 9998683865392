import { TrackForm } from "components";
import { actionsData } from "data";
import { useCreateTrack } from "hooks";

const AddNewTrackTab = () => {
    const {
        createTrack,
        loading,
    } = useCreateTrack();

    return (
        <TrackForm
            action={actionsData.create}
            loading={loading}
            onSubmitForm={createTrack}
        />
    );
};

export default AddNewTrackTab;
