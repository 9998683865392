import { Tooltip } from "@mui/material";
import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";
import { StyledMarker } from "styles";

const Marker = ({
    duration,
    marker,
    onClick,
}) => {
    const [position, setPosition] = useState("-9999px");

    const {
        time,
        title,
    } = marker || {};

    useEffect(
        () => {
            if (duration) {
                const percentage = time <= duration ? time / duration : 1;

                setPosition(`calc(${percentage * 100}% - 5px)`);
            } else setPosition("-9999px");
        },
        [time, duration],
    );

    return (
        <Tooltip title={title}>
            <StyledMarker
                position={position}
                onClick={onClick}
            />
        </Tooltip>
    );
};

export default Marker;

Marker.propTypes = {
    duration: PropTypes.number,
    marker: PropTypes.object,
    onClick: PropTypes.func,
};
