import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData } from "data";
import { createReleaseSoundRecordingMutation, getTracksListQuery } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useCreateReleaseSoundRecording = (id) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [createSoundRecordingAction, { loading }] = useMutation(
        createReleaseSoundRecordingMutation,
        {
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: getTracksListQuery,
                    variables: {
                    orderBy: { track_num: "asc" }, //eslint-disable-line
                        page: 0,
                        where: {
                            ...id && {
                                OR: [
                                    {
                                        releaseId: { equals: id },
                                    },
                                ],
                            },
                        },
                    },
                },
            ],
        },
    );

    const createReleaseSoundRecording = async ({
        duration,
        track,
    }) => {
        try {
            await createSoundRecordingAction({
                onCompleted: () => {
                    dispatch(openAlert(
                        t(
                            "messages.createMsgs.success",
                            { entity: `${t("releases.entity")} ${t("labels.soundRecording")}` },
                        ),
                        statusTypesData.success,
                    ));
                },

                variables: {
                    duration: parseInt(duration[1] - duration[0]),
                    start: parseInt(duration[0]),
                    trackId: track,  //eslint-disable-line
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        createReleaseSoundRecording,
        loading,
    };
};

export default useCreateReleaseSoundRecording;
