import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useImportRevenueReportTemplateSchema = () => {
    const { t } = useTranslation();

    return yup.object({
        revenueReportTemplateFile: yup.string().required(t("messages.inputMsgs.required")),
        templateName: yup.string().required(t("messages.inputMsgs.required")),
    });
};

export default useImportRevenueReportTemplateSchema;
