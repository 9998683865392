import { useAuth0 } from "@auth0/auth0-react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
    Box,
    Button,
    Grid,
    Typography,
} from "@mui/material";
import { Modal, TableMenu } from "atoms";
import {
    FoldersStructureArtworkFileForm,
    FoldersStructureChildFolderForm,
    FoldersStructureRootFolderForm,
    FoldersStructureTrackFileForm,
    FoldersStructureXmlFileForm,
    NoData,
} from "components";
import {
    actionsData,
    constantsData,
    fileData,
    identifiersData,
    namesData,
    permissionsData,
    selectionsData,
    statusTypesData,
} from "data";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { openAlert } from "redux/actions";
import { theme } from "theme";
import { canDo, getUniqueRandomId } from "utils";

const FoldersStructureTabFilesView = ({
    actions,
    selectedTreeItemData,
    setSelectedTreeItemData,
}) => {
    const [filesData, setFilesData] = useState([]);

    const [modalData, setModalData] = useState({
        body: null,
        hasComponent: true,
        id: null,
        open: false,
        title: "",
    });

    const { t } = useTranslation();

    const selections = useSelector((state) => state.selectionsReducer);

    const dispatch = useDispatch();

    const { user } = useAuth0();

    const {
        mediaEdit: mediaEditPermission,
        mediaRead: mediaReadPermission,
    } = permissionsData.permissions.keys;

    const { file: fileConstant } = constantsData;

    const { button: buttonId } = identifiersData.ids;

    const {
        addNewFile: addNewFileButtonName,
        save: saveButtonName,
    } = namesData.buttons;

    const clickDeleteFolderButtonHandler = () => {
        setModalData({
            action: () => actions.deleteFolder({
                name: selectedTreeItemData?.name,
                parentPath: selectedTreeItemData?.parentPath,
            }),
            actionType: actionsData["delete"],
            body: t(
                "messages.deleteMsgs.alert",
                { entity: t("labels.folder") },
            ),
            open: true,
            title: t("labels.deleteFolder"),
        });
    };

    const editFolderHandler = ({
        folderName,
        id,
        selectableFolderName,
        textFolderName,
    }) => {
        if ((!selectableFolderName && !textFolderName) && !folderName) {
            dispatch(openAlert(
                t("messages.requiredNewFolderName"),
                statusTypesData.error,
            ));
        } else {
            actions.editFolder({
                id,
                isSelection: !!(folderName || selectableFolderName),
                name: folderName || (selectableFolderName || textFolderName),
                parentPath: selectedTreeItemData?.parentPath,
            });

            setModalData({
                ...modalData,
                open: false,
            });

            setSelectedTreeItemData({
                ...selectedTreeItemData,
                name: folderName,
            });
        }
    };

    const clickEditFolderButtonHandler = () => {
        const isRootFolder = selectedTreeItemData?.parentPath === "";

        setModalData({
            body: isRootFolder ? (
                <FoldersStructureRootFolderForm
                    action={actionsData.edit}
                    setModalData={setModalData}
                    values={{
                        folderName: selectedTreeItemData?.name,
                        id: selectedTreeItemData?.id,
                    }}
                    onSubmitForm={editFolderHandler}
                />
            ) : (
                <FoldersStructureChildFolderForm
                    action={actionsData.edit}
                    setModalData={setModalData}
                    values={{
                        id: selectedTreeItemData?.id,
                        selectableFolderName: selectedTreeItemData?.name && selectedTreeItemData?.isSelection ? selectedTreeItemData?.name : "",
                        textFolderName: selectedTreeItemData?.name && !selectedTreeItemData?.isSelection ? selectedTreeItemData?.name : "",
                    }}
                    onSubmitForm={editFolderHandler}
                />
            ),
            hasComponent: true,
            open: true,
            title: t("labels.editFolder"),
        });
    };

    const clickDeleteFileButtonHandler = (id) => {
        const clonedFilesData = [...filesData];

        const filteredFilesData = clonedFilesData.filter((file) => file.id !== id);

        setFilesData(filteredFilesData);
    };

    const editFileHandler = ({
        fileExtension,
        fileName,
        fileType,
        height,
        id,
        selectableFileName,
        textFileName,
        width,
    }) => {
        if ((!selectableFileName && !textFileName) && !fileName) {
            dispatch(openAlert(
                t("messages.requiredNewFileName"),
                statusTypesData.error,
            ));
        } else {
            const clonedFilesData = [...filesData];

            const filteredFileIndex = clonedFilesData.findIndex((file) => file.id === id);

            clonedFilesData[filteredFileIndex] = {
                id,
                isSelection: !!(fileName || selectableFileName),
                name: fileName || (selectableFileName || textFileName),
                properties: {
                    extension: fileExtension,
                    height: parseInt(height),
                    type: fileType,
                    width: parseInt(width),
                },
                type: fileConstant,
            };

            setFilesData(clonedFilesData);

            setModalData({
                ...modalData,
                open: false,
            });
        }
    };

    const clickEditFileButtonHandler = ({
        id,
        isSelection,
        name,
        properties,
    }) => {
        const renderFileFormHandler = () => {
            if (properties.type === fileData.types.artwork) {
                return (
                    <FoldersStructureArtworkFileForm
                        action={actionsData.save}
                        modalData={modalData}
                        setModalData={setModalData}
                        values={{
                            fileExtension: properties?.extension,
                            fileName: name,
                            fileType: properties?.type,
                            height: properties?.height,
                            id,
                            width: properties?.width,
                        }}
                        onSubmitForm={editFileHandler}
                    />
                );
            }

            if (properties.type === fileData.types.track) {
                return (
                    <FoldersStructureTrackFileForm
                        action={actionsData.save}
                        modalData={modalData}
                        setModalData={setModalData}
                        values={{
                            fileExtension: properties?.extension,
                            fileName: name,
                            fileType: properties?.type,
                            id,
                        }}
                        onSubmitForm={editFileHandler}
                    />
                );
            }

            return (
                <FoldersStructureXmlFileForm
                    action={actionsData.save}
                    modalData={modalData}
                    setModalData={setModalData}
                    values={{
                        fileType: properties?.type,
                        id,
                        selectableFileName: isSelection ? name : "",
                        textFileName: !isSelection ? name : "",
                    }}
                    onSubmitForm={editFileHandler}
                />
            );
        };

        setModalData({
            body: renderFileFormHandler(),
            hasComponent: true,
            id,
            open: true,
            title: t("labels.editFile"),
        });
    };

    const addFileHandler = ({
        fileExtension,
        fileName,
        fileType,
        height,
        selectableFileName,
        textFileName,
        width,
    }) => {
        if ((!selectableFileName && !textFileName) && !fileName) {
            dispatch(openAlert(
                t("messages.requiredNewFileName"),
                statusTypesData.error,
            ));
        } else {
            const clonedFilesData = [...filesData];

            clonedFilesData.push({
                id: getUniqueRandomId(),
                isSelection: !!(fileName || selectableFileName),
                name: fileName || (selectableFileName || textFileName),
                properties: {
                    ...fileExtension && { extension: fileExtension },
                    ...height && { height: parseInt(height) },
                    type: fileType,
                    ...width && { width: parseInt(width) },
                },
                type: fileConstant,
            });

            setFilesData(clonedFilesData);

            setModalData({
                ...modalData,
                open: false,
            });
        }
    };

    const clickAddNewFileButtonHandler = () => {
        setModalData({
            body: (
                <FoldersStructureTrackFileForm
                    action={actionsData.add}
                    modalData={modalData}
                    setModalData={setModalData}
                    values={{}}
                    onSubmitForm={addFileHandler}
                />
            ),
            hasComponent: true,
            open: true,
            title: t("actions.addNewFile"),
        });
    };

    const saveFilesHandler = () => {
        actions.createFiles({
            files: filesData.map(({
                isSelection,
                name,
                properties,
            }) => ({
                name,
                ...properties,
                isSelection,
                type: properties.type.toUpperCase() === fileData.types.xml ? properties.type.toLowerCase() : properties.type,
            })),
            parentPath: selectedTreeItemData?.parentPath === "" ? selectedTreeItemData?.name : `${selectedTreeItemData?.parentPath}/${selectedTreeItemData?.name}`,
        });

        const selectedTreeItemFoldersData = selectedTreeItemData?.children?.filter((child) => child.type !== fileConstant);

        setSelectedTreeItemData({
            ...selectedTreeItemData,
            children: [
                ...selectedTreeItemFoldersData,
                ...filesData,
            ],
        });
    };

    useEffect(
        () => {
            setFilesData(selectedTreeItemData?.children?.filter((child) => child.type === fileConstant));
        },
        [selectedTreeItemData], // eslint-disable-line
    );

    return filesData.length > 0 ? (
        <Box>
            <Modal
                modalData={modalData}
                setModalData={setModalData}
            />
            <Box
                display="flex"
                justifyContent="space-between"
                mb={3}
            >
                <Box>
                    <Button
                        color="primary"
                        id={`${addNewFileButtonName}_${buttonId}`}
                        variant="text"
                        disabled={
                            !canDo(
                                user,
                                [mediaEditPermission, mediaReadPermission],
                            )
                        }
                        onClick={clickAddNewFileButtonHandler}
                    >
                        <AddOutlinedIcon />
                        {t("actions.addNewFile")}
                    </Button>
                    <Typography
                        caption="body1"
                        color={theme.palette.grey[600]}
                        ml={2}
                    >
                        {t("labels.foldersStructureFileTitle")}
                    </Typography>
                </Box>
                <Box>
                    <TableMenu
                        deleteAction={clickDeleteFolderButtonHandler}
                        deleteText={t("actions.deleteFolder")}
                        editAction={clickEditFolderButtonHandler}
                        editText={t("actions.editFolder")}
                        rowId={0}
                        hasDelete
                        hasEdit
                    />
                </Box>
            </Box>
            <Box minHeight={400}>
                {filesData.map(({
                    id,
                    isSelection,
                    name,
                    properties,
                }, index) => (
                    <Grid
                        alignItems="center"
                        flexWrap="nowrap"
                        key={id}
                        mt={3}
                        pl={2}
                        py={1}
                        sx={{ backgroundColor: index % 2 === 0 ? theme.palette.grey[100] : theme.palette.grey[200] }}
                        container
                    >
                        <Grid
                            display="flex"
                            flexWrap="nowrap"
                            gap={1}
                            overflow="auto"
                            sx={{ "&::-webkit-scrollbar": { display: "none" } }}
                            xs={11}
                            item
                        >
                            <Grid
                                minWidth={100}
                                xs={2}
                                item
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    gap={1}
                                >
                                    <Typography
                                        color={theme.palette.grey[600]}
                                        fontSize={10}
                                        variant="caption"
                                    >
                                        {t("labels.fileType")}
                                    </Typography>
                                    <Typography
                                        fontSize={14}
                                        variant="caption"
                                    >
                                        {properties.type.toUpperCase() === fileData.types.xml ? properties.type.toUpperCase() : properties.type}
                                    </Typography>
                                </Box>
                            </Grid>
                            {properties.type.toUpperCase() !== fileData.types.xml && (
                                <Grid
                                    minWidth={100}
                                    xs={2}
                                    item
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        gap={1}
                                    >
                                        <Typography
                                            color={theme.palette.grey[600]}
                                            fontSize={10}
                                            variant="h5"
                                        >
                                            {t("labels.fileExtension")}
                                        </Typography>
                                        <Typography
                                            fontSize={14}
                                            variant="h6"
                                        >
                                            {properties.type === fileData.types.artwork ? selections?.[selectionsData.keys.imageTypes].find((image) => image?.value === properties.extension)?.label : selections?.[selectionsData.keys.trackTypes].find((track) => track?.value === properties.extension)?.label}
                                        </Typography>
                                    </Box>
                                </Grid>
                            )}
                            <Grid
                                minWidth={100}
                                xs={2}
                                item
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    gap={1}
                                >
                                    <Typography
                                        color={theme.palette.grey[600]}
                                        fontSize={10}
                                        variant="h5"
                                    >
                                        {t("labels.fileName")}
                                    </Typography>
                                    <Typography
                                        fontSize={14}
                                        variant="h6"
                                    >
                                        {name}
                                    </Typography>
                                </Box>
                            </Grid>
                            {properties.type === fileData.types.artwork && (
                                <>
                                    <Grid
                                        minWidth={80}
                                        xs={1}
                                        item
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            gap={1}
                                        >
                                            <Typography
                                                color={theme.palette.grey[600]}
                                                fontSize={10}
                                                variant="h5"
                                            >
                                                {t("labels.width")}
                                            </Typography>
                                            <Typography
                                                fontSize={14}
                                                variant="h6"
                                            >
                                                {properties.width}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid
                                        minWidth={80}
                                        xs={1}
                                        item
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            gap={1}
                                        >
                                            <Typography
                                                color={theme.palette.grey[600]}
                                                fontSize={10}
                                                variant="h5"
                                            >
                                                {t("labels.height")}
                                            </Typography>
                                            <Typography
                                                fontSize={14}
                                                variant="h6"
                                            >
                                                {properties.height}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        <Grid
                            display="flex"
                            justifyContent="flex-end"
                            xs={1}
                            item
                        >
                            <TableMenu
                                deleteAction={() => clickDeleteFileButtonHandler(id)}
                                rowId={index + 1}
                                editAction={() => clickEditFileButtonHandler({
                                    id,
                                    isSelection,
                                    name,
                                    properties,
                                })}
                                hasDelete
                                hasEdit
                            />
                        </Grid>
                    </Grid>
                ))}
            </Box>
            {canDo(
                user,
                [mediaReadPermission, mediaEditPermission],
            ) && (
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    mt={3}
                >
                    <Button
                        color="primary"
                        id={`${saveButtonName}_${buttonId}`}
                        variant="contained"
                        onClick={saveFilesHandler}
                    >
                        {t("actions.save")}
                    </Button>
                </Box>
            )}
        </Box>
    ) : (
        <Box>
            <Modal
                modalData={modalData}
                setModalData={setModalData}
            />
            {selectedTreeItemData?.name && (
                <Box
                    display="flex"
                    justifyContent="space-between"
                    mb={3}
                >
                    <Box>
                        <Button
                            color="primary"
                            id={`${addNewFileButtonName}_${buttonId}`}
                            variant="text"
                            disabled={
                                !canDo(
                                    user,
                                    [mediaEditPermission, mediaReadPermission],
                                )
                            }
                            onClick={clickAddNewFileButtonHandler}
                        >
                            <AddOutlinedIcon />
                            {t("actions.addNewFile")}
                        </Button>
                        <Typography
                            caption="body1"
                            color={theme.palette.grey[600]}
                            ml={2}
                        >
                            {t("labels.foldersStructureFileTitle")}
                        </Typography>
                    </Box>
                    <Box>
                        <TableMenu
                            deleteAction={clickDeleteFolderButtonHandler}
                            deleteText={t("actions.deleteFolder")}
                            editAction={clickEditFolderButtonHandler}
                            editText={t("actions.editFolder")}
                            rowId={0}
                            hasDelete
                            hasEdit
                        />
                    </Box>
                </Box>
            )}
            <Box
                alignItems="center"
                display="flex"
                height="100%"
                justifyContent="center"
            >
                <NoData />
            </Box>
            {(selectedTreeItemData?.children?.filter(({ type: itemType }) => itemType === fileConstant)?.length > 0 && canDo(
                user,
                [mediaReadPermission, mediaEditPermission],
            )) && (
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    mt={3}
                >
                    <Button
                        color="primary"
                        id={`${saveButtonName}_${buttonId}`}
                        variant="contained"
                        onClick={saveFilesHandler}
                    >
                        {t("actions.save")}
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default FoldersStructureTabFilesView;

FoldersStructureTabFilesView.propTypes = {
    actions: PropTypes.object,
    selectedTreeItemData: PropTypes.object,
    setSelectedTreeItemData: PropTypes.func,
};
