import { useAuth0 } from "@auth0/auth0-react";
import { constantsData, urlsData } from "data";
import { useApisClient } from "hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { openAlert, setRefetchDriveManagementList } from "redux/actions";
import { convertDateToIsoFormatHandler, displayErrorHandler, getNextDayDateHandler } from "utils";

const useDriveManagementFilesList = (
    driveId,
    folderId,
    searchValueParam,
    driveParam,
    filters = {},
) => {
    const [data, setData] = useState([]);

    const [nextPageToken, setNextPageToken] = useState(null);

    const [hasNext, setHasNext] = useState(true);

    const [loading, setLoading] = useState(true);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { refetch } = useSelector((state) => state.driveManagementReducer);

    const { logout } = useAuth0();

    const { get } = useApisClient();

    useEffect(
        () => {
            if (
                hasNext
            || refetch
            || (searchValueParam && driveParam && refetch && hasNext)
            ) {
                setLoading(true);

                if (refetch) setData([]);

                const labelsQueries = {};

                filters?.filteredLabels?.forEach((label) => labelsQueries[`${label?.type?.type.toLowerCase()}Options`] = label.query);

                const queryParam = Object.keys(filters)?.map((key) => {
                    if (key !== "filteredLabels") {
                        if (key === "mimeType") return `(${filters[key]?.map((type) => `(${key} in ${type})`).join(" or ")})`;
                        return `(${key} >= '${convertDateToIsoFormatHandler(filters[key])}') and (${key} < '${convertDateToIsoFormatHandler(getNextDayDateHandler(filters[key]))}')`;
                    }
                }).join(" and ");

                const slicedQueryParam = queryParam.slice(
                    0,
                    -5,
                );

                get(
                    urlsData.apis.driveManagement.files.url,
                    null,
                    {
                        pageSize: 25,
                        ...hasNext && { pageToken: nextPageToken },
                        ...(driveId && !folderId) && { parentFolder: `"${driveId}"` },
                        ...folderId && { parentFolder: `"${folderId}"` },
                        ...driveParam && {
                            corpora: "drive",
                            driveId: driveParam,
                        },
                        ...Object.keys(filters)?.length > 0 && (filters?.createdTime || filters?.modifiedTime || filters?.mimeType) ? {
                            q: filters?.filteredLabels?.length > 0 ? `${slicedQueryParam} and trashed=false` : `${queryParam} and trashed=false`,
                        } : { q: "trashed=false" },
                        ...labelsQueries,
                        ...searchValueParam && { search: `'${searchValueParam}'` },
                    },
                ).
                    then(({ data: responseData }) => {
                        if (refetch) setData(responseData.files);

                    else  setData((prev) => [...prev, ...responseData?.files]); //eslint-disable-line

                        setHasNext(false);

                        if (responseData?.nextPageToken) setNextPageToken(responseData?.nextPageToken);
                        else setNextPageToken(null);
                    })["catch"]((err) => {
                        displayErrorHandler(
                            err.response.data.message === constantsData.networkError ? t("messages.networkFailure") : err.response.data.message,
                            dispatch,
                            openAlert,
                            err.response.status,
                            logout,
                        );
                    })["finally"](() => {
                        setLoading(false);

                        dispatch(setRefetchDriveManagementList(false));
                    });
            }
        },
        [hasNext, refetch, driveParam, searchValueParam], //eslint-disable-line
    );

    useEffect(
        () => {
            if (driveId) {
                setData([]);

                setHasNext(true);

                setNextPageToken(null);
            }
        },
        [driveId, folderId], //eslint-disable-line
    );

    useEffect(
        () => {
            if (searchValueParam) {
                setData([]);

                setNextPageToken(null);

                setHasNext(true);
            }
        },
        [searchValueParam, driveParam], //eslint-disable-line
    );

    return {
        data,
        loading,
        nextPageToken,
        setHasNext,
    };
};

export default useDriveManagementFilesList;
