import inputData from "data/input";
import selectionsData from "data/selections";

const {
    artists: artistsSelectionKey,
    clients: clientsSelectionKey,
    countries: countriesSelectionKey,
    genres: genresSelectionKey,
    mimeTypes: mimeTypesSelectionKey,
    releaseTypes: releaseTypesSelectionKey,
    releases: releasesSelectionKey,
    revenueReportsLabels: revenueReportsLabelsSelectionKey,
    rightTypes: rightTypesSelectionKey,
    videoTypes: videoTypesSelectionKey,
} = selectionsData.keys;

const {
    autoComplete: autoCompleteInputType,
    category: categoryInputType,
    date: dateInputType,
    range: rangeInputType,
    switch: switchInputType,
    text: textInputType,
} = inputData.types;

const {
    artistName: artistNameInputName,
    createdTime: createdTimeInputName,
    dateRangeFrom: dateRangeFromInputName,
    dateRangeTo: dateRangeToInputName,
    duration: durationInputName,
    endDate: endDateInputName,
    genre: genreInputName,
    hasVideos: hasVideosInputName,
    isrc: isrcInputName,
    label: labelInputName,
    mimeType: mimeTypeInputName,
    modifiedTime: modifiedTimeInputName,
    nationality: nationalityInputName,
    platformName: platformNameInputName,
    releaseName: releaseNameInputName,
    releaseType: releaseTypeInputName,
    rightType: rightTypeInputName,
    startDate: startDateInputName,
    upc: upcInputName,
    videoType: videoTypeInputName,
} = inputData.names;

const {
    artistName: artistNameInputLabel,
    createdTime: createdTimeInputLabel,
    dateRangeFrom: dateRangeFromInputLabel,
    dateRangeTo: dateRangeToInputLabel,
    duration: durationInputLabel,
    endDate: endDateInputLabel,
    genre: genreInputLabel,
    hasVideos: hasVideosInputLabel,
    isrc: isrcInputLabel,
    label: labelInputLabel,
    mimeType: mimeTypeInputLabel,
    modifiedTime: modifiedTimeInputLabel,
    nationality: nationalityInputLabel,
    platformName: platformNameInputLabel,
    releaseName: releaseNameInputLabel,
    releaseType: releaseTypeInputLabel,
    rightType: rightTypeInputLabel,
    startDate: startDateInputLabel,
    upc: upcInputLabel,
    videoType: videoTypeInputLabel,
} = inputData.labels;

const filtersFormData = {
    artists: [
        {
            label: nationalityInputLabel,
            name: nationalityInputName,
            selectionKey: countriesSelectionKey,
            type: autoCompleteInputType,
        },
    ],
    driveManagement: [
        {
            label: createdTimeInputLabel,
            name: createdTimeInputName,
            type: dateInputType,
        },
        {
            label: modifiedTimeInputLabel,
            name: modifiedTimeInputName,
            type: dateInputType,
        },
        {
            label: mimeTypeInputLabel,
            name: mimeTypeInputName,
            selectionKey: mimeTypesSelectionKey,
            type: autoCompleteInputType,
        },
    ],
    releases: [
        {
            label: startDateInputLabel,
            name: startDateInputName,
            type: dateInputType,
        },
        {
            label: endDateInputLabel,
            name: endDateInputName,
            type: dateInputType,
        },
        {
            label: upcInputLabel,
            name: upcInputName,
            type: textInputType,
        },
        {
            label: releaseTypeInputLabel,
            name: releaseTypeInputName,
            selectionKey: releaseTypesSelectionKey,
            type: autoCompleteInputType,
        },
        {
            label: genreInputLabel,
            name: genreInputName,
            selectionKey: genresSelectionKey,
            type: autoCompleteInputType,
        },
        {
            label: rightTypeInputLabel,
            name: rightTypeInputName,
            selectionKey: rightTypesSelectionKey,
            type: autoCompleteInputType,
        },
        {
            isAsyncMenu: true,
            label: artistNameInputLabel,
            name: artistNameInputName,
            selectionKey: artistsSelectionKey,
            type: autoCompleteInputType,
        },
    ],
    revenueReports: [
        {
            label: dateRangeFromInputLabel,
            name: dateRangeFromInputName,
            type: dateInputType,
        },
        {
            label: dateRangeToInputLabel,
            name: dateRangeToInputName,
            type: dateInputType,
        },
        {
            categoriesData: [
                {
                    label: upcInputLabel,
                    value: upcInputName,
                },
                {
                    label: isrcInputLabel,
                    value: isrcInputName,
                },
            ],
            label: inputData.labels["upc/isrc"],
            name: inputData.names["upc/isrc"],
            type: categoryInputType,
        },
        {
            isAsyncMenu: true,
            label: artistNameInputLabel,
            name: artistNameInputName,
            selectionKey: artistsSelectionKey,
            type: autoCompleteInputType,

        },
        {
            isAsyncMenu: true,
            label: releaseNameInputLabel,
            name: releaseNameInputName,
            selectionKey: releasesSelectionKey,
            type: autoCompleteInputType,
        },
        {
            isAsyncMenu: true,
            label: platformNameInputLabel,
            name: platformNameInputName,
            selectionKey: clientsSelectionKey,
            type: autoCompleteInputType,
        },
        {
            isAsyncMenu: true,
            label: labelInputLabel,
            name: labelInputName,
            selectionKey: revenueReportsLabelsSelectionKey,
            type: autoCompleteInputType,
        },
        {
            label: nationalityInputLabel,
            name: nationalityInputName,
            selectionKey: countriesSelectionKey,
            type: autoCompleteInputType,
        },
    ],
    tracks: [
        {
            label: startDateInputLabel,
            name: startDateInputName,
            type: dateInputType,
        },
        {
            label: endDateInputLabel,
            name: endDateInputName,
            type: dateInputType,
        },
        {
            label: isrcInputLabel,
            name: isrcInputName,
            type: textInputType,
        },
        {
            label: releaseTypeInputLabel,
            name: releaseTypeInputName,
            selectionKey: releaseTypesSelectionKey,
            type: autoCompleteInputType,
        },
        {
            label: genreInputLabel,
            name: genreInputName,
            selectionKey: genresSelectionKey,
            type: autoCompleteInputType,
        },
        {
            label: rightTypeInputLabel,
            name: rightTypeInputName,
            selectionKey: rightTypesSelectionKey,
            type: autoCompleteInputType,
        },
        {
            isAsyncMenu: true,
            label: artistNameInputLabel,
            name: artistNameInputName,
            selectionKey: artistsSelectionKey,
            type: autoCompleteInputType,
        },
        {
            label: hasVideosInputLabel,
            name: hasVideosInputName,
            type: switchInputType,
        },
        {
            label: durationInputLabel,
            name: durationInputName,
            type: rangeInputType,
        },
    ],
    videos: [
        {
            label: startDateInputLabel,
            name: startDateInputName,
            type: dateInputType,
        },
        {
            label: endDateInputLabel,
            name: endDateInputName,
            type: dateInputType,
        },
        {
            label: releaseTypeInputLabel,
            name: releaseTypeInputName,
            selectionKey: releaseTypesSelectionKey,
            type: autoCompleteInputType,
        },
        {
            label: genreInputLabel,
            name: genreInputName,
            selectionKey: genresSelectionKey,
            type: autoCompleteInputType,
        },
        {
            label: rightTypeInputLabel,
            name: rightTypeInputName,
            selectionKey: rightTypesSelectionKey,
            type: autoCompleteInputType,
        },
        {
            label: videoTypeInputLabel,
            name: videoTypeInputName,
            selectionKey: videoTypesSelectionKey,
            type: autoCompleteInputType,
        },
        {
            isAsyncMenu: true,
            label: artistNameInputLabel,
            name: artistNameInputName,
            selectionKey: artistsSelectionKey,
            type: autoCompleteInputType,
        },
        {
            isAsyncMenu: true,
            label: releaseNameInputLabel,
            name: releaseNameInputName,
            selectionKey: releasesSelectionKey,
            type: autoCompleteInputType,
        },
    ],
};

export default filtersFormData;
