import inputData from "data/input";

const { text: textInputType } = inputData.types;

const { email: emailInputName } = inputData.names;

const { email: emailInputLabel } = inputData.labels;

const memberFormData = [
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: emailInputLabel,
        name: emailInputName,
        type: textInputType,
    },
];

export default memberFormData;
