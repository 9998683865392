import { gql } from "@apollo/client";

export const getRevenueReportsListQuery = gql`
    query getRevenueReportsList(
        $page: Int,
        $limit: Int,
        $where: RevenueReportWhereInput
    ) {
        listRevenueReport(
            page: $page,
            elementsPerPage: $limit,
            where: $where
        ) {
            posts {
                id
                artist_name_en
                artist_name_ar
                album_name_en
                album_name_ar
                track {
                    title
                }
                client {
                    name
                }
                upc
                anotherUPC
                isrc
                anotherISRC
                songId
                song_name_en
                song_name_ar
                platformId
                platformName
                countryName
                labelName
                contentType
                timesPlayed
                date
                revenue
            }
            meta {
                totalPages
                resultCount
                nodeCount
                currentPage
                totalReveunue
            }
        }
    }
`;

export const getRevenueReportsLabelsListQuery = gql`
    query getRevenueReportsLabelsList(
        $page: Int,
        $limit: Int,
        $where: RevenueReportWhereInput
    ) {
        listRevenueReport(
            page: $page,
            elementsPerPage: $limit,
            where: $where
        ) {
            posts {
                id
                labelName
            }
            meta {
                totalPages
                resultCount
                nodeCount
                currentPage
                totalReveunue
            }
        }
    }
`;
