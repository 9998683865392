import { gql } from "@apollo/client";

export const getTracksListQuery = gql`
    query getTracksList(
        $page: Int = 0,
        $limit: Int = 100000,
        $orderBy: TrackOrderByInput!,
        $where: TrackWhereInput!
    ) {
        listTracks(
            page: $page,
            elementsPerPage: $limit,
            orderBy: $orderBy,
            where: $where
        ) {
            posts {
                id
                title
                title_ar
                track_num
                downloadUri
                isrc
                trackFile {
                    duration
                }
                secondary_artists {
                    id
                    name
                }
                releaseTracks {
                    id
                    releaseId
                }
                artist {
                    id
                    name
                }
                image {
                    downloadThumbnail
                }
            }
            meta {
                resultCount
                nodeCount
                currentPage
                totalPages
            }
        }
    }
`;

export const getTrackQuery = gql`
    query getTrack($where: TrackWhereUniqueInput!) {
        track(where: $where) {
            composers
            custom_id
            id
            isrc_other
            isrc
            iswc
            title
            title_ar
            track_num
            writers
            downloadUri
            royalty {
                id
                master
                mechanical_performance
                synchronization
                neighboring
            }
            image {
                id
                downloadUri
                downloadThumbnail
            }
            videoClips {
                video {
                    name
                    track {
                        artist {
                            id
                            name
                        }
                        image {
                            downloadThumbnail
                        }
                    }
                    release {
                        id
                        image {
                            downloadThumbnail
                        }
                    }
                    image {
                        downloadThumbnail
                    }
                }
                downloadUri
            }
            videos {
                id
                name
                downloadUri
                type {
                    name
                }
                track {
                    artist {
                        id
                        name
                    }
                    image {
                        downloadThumbnail
                    }
                }
                release {
                    id
                    image {
                        downloadThumbnail
                    }
                }
                image {
                    downloadThumbnail
                }
            }
            secondary_artists {
                id
                name
            }
            artist {
                id
                name
            }
            trackFile {
                id
                duration
            }
            recordings {
                id
                name
                downloadUri
                trackFile {
                    duration
                }
            }
            documents {
                id
                name
                downloadUri
            }
            lyric_files {
                id
                downloadUri
                name
            }
        }
    }
`;

export const getTrackShareLinkQuery = gql`
    query getTrackShareLink($id: Int!) {
        shareLinkTrack(track_id: $id)
    }
`;
