import { useState } from "react";

const useSelectTableRows = () => {
    const [selected, setSelected] = useState([]);

    const selectRowHandler = (id) => {
        const selectedIndex = selected.indexOf(id);

        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(
                selected,
                id,
            );
        } else if (selectedIndex === 0) newSelected = newSelected.concat(selected.slice(1));
        else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(
                selected.slice(
                    0,
                    -1,
                ),
            );
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(
                    0,
                    selectedIndex,
                ),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const selectAllHandler = (event, data) => {
        if (event.target.checked) {
            const newSelected = data.map(({ id }) => id);

            setSelected(newSelected);
        } else setSelected([]);
    };

    return {
        isSelected,
        selectAllHandler,
        selectRowHandler,
        selected,
        setSelected,
    };
};

export default useSelectTableRows;
