import inputData from "data/input";
import selectionsData from "data/selections";

const {
    clients: clientsSelectionKey,
    distributionFileTypes: distributionFileTypesSelectionKey,
    territories: territoriesSelectionKey,
    xmlFileTypes: xmlFileTypesSelectionKey,
} = selectionsData.keys;

const { autoComplete: autoCompleteInputType } = inputData.types;

const {
    client: clientInputName,
    fileType: fileTypeInputName,
    territory: territoryInputName,
    xmlType: xmlTypeInputName,
} = inputData.names;

const {
    client: clientInputLabel,
    fileType: fileTypeInputLabel,
    territory: territoryInputLabel,
    xmlType: xmlTypeInputLabel,
} = inputData.labels;

const downloadReleaseClientXmlFileFormData = [
    {
        col: { xs: 12 },
        isAsyncMenu: true,
        label: clientInputLabel,
        name: clientInputName,
        selectionKey: clientsSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: { xs: 12 },
        label: fileTypeInputLabel,
        name: fileTypeInputName,
        selectionKey: distributionFileTypesSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: { xs: 12 },
        isOptional: true,
        label: xmlTypeInputLabel,
        name: xmlTypeInputName,
        selectionKey: xmlFileTypesSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: { xs: 12 },
        isAsyncMenu: true,
        label: territoryInputLabel,
        name: territoryInputName,
        selectionKey: territoriesSelectionKey,
        type: autoCompleteInputType,
    },
];

export default downloadReleaseClientXmlFileFormData;
