import inputData from "data/input";
import selectionsData from "data/selections";

const { folderNames: folderNamesSelectionKey } = selectionsData.keys;

const {
    autoComplete: autoCompleteInputType,
    text: textInputType,
} = inputData.types;

const {
    selectableFolderName: selectableFolderNameInputName,
    textFolderName: textFolderNameInputName,
} = inputData.names;

const {
    selectableFolderName: selectableFolderNameInputLabel,
    textFolderName: textFolderNameInputLabel,
} = inputData.labels;

const foldersStructureChildFolderFormData = [
    {
        col: { xs: 12 },
        label: textFolderNameInputLabel,
        name: textFolderNameInputName,
        type: textInputType,
    },
    {
        col: { xs: 12 },
        label: selectableFolderNameInputLabel,
        name: selectableFolderNameInputName,
        selectionKey: folderNamesSelectionKey,
        type: autoCompleteInputType,
    },
];

export default foldersStructureChildFolderFormData;
