import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import { Box, Slider } from "@mui/material";
import { SkeletonLoader } from "atoms";
import { identifiersData, namesData } from "data";
import { PropTypes } from "prop-types";
import { useEffect, useRef, useState } from "react";
import { theme } from "theme";
import { convertHourToDurationFormatHandler } from "utils";

const minDistance = 5;

const AudioRangeInput = ({
    name,
    setValue,
    url,
}) => {
    const [loading, setLoading] = useState(false);

    const [rangeInputValue, setRangeInputValue] = useState([]);

    const [isPlaying, setIsPlaying] = useState(false);

    const audioFile = useRef();

    const {
        ids: {
            slider: sliderId,
            thumb: thumbId,
            track: trackId,
        },
    } = identifiersData;

    const { audioRange: audioRangeSliderName } = namesData.sliders;

    const togglePlayHandler = () => {
        if (audioFile?.current?.paused) {
            audioFile.current.currentTime = rangeInputValue[0]; //eslint-disable-line

            audioFile.current.play();

            setIsPlaying(true);
        } else {
            audioFile.current.pause();

            setIsPlaying(false);
        }
    };

    const changeRangeInputHandler = (_, value, activeThumb) => {
        if (!Array.isArray(value)) return;

        audioFile.current.pause();

        setIsPlaying(false);

        if (activeThumb === 0) {
            setRangeInputValue(
                [
                    Math.min(
                        value[0],
                        rangeInputValue[1] - minDistance,
                    ),
                    rangeInputValue[1],
                ],
            );
        } else {
            setRangeInputValue([rangeInputValue[0], Math.max(
                value[1],
                rangeInputValue[0] + minDistance,
            )]);
        }

        setValue(
            name,
            rangeInputValue,
            { shouldDirty: true },
        );
    };

    const isEndedHandler = () => {
        if (Math.round(audioFile?.current?.currentTime) === rangeInputValue[1]) {
            audioFile.current.pause();

            setIsPlaying(false);
        }
    };

    useEffect(
        () => {
            setRangeInputValue([]);
            if (url) setLoading(true);
        },
        [url],
    );

    return (
        <Box sx={{ width: "100%" }}>
            <audio
                crossOrigin="anonymous"
                ref={audioFile}
                src={`${window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_DOMAIN}${url}`} /*eslint-disable-line */
                onEnded={togglePlayHandler}
                onTimeUpdate={isEndedHandler}
                onCanPlay={() => {
                    if (rangeInputValue?.length === 0) {
                        setValue(
                            name,
                            [0, audioFile.current.duration],
                        );
                        setRangeInputValue([0, audioFile.current.duration]);
                    }
                    setLoading(false);
                }}
            />
            {(rangeInputValue.length > 0 || loading) && (
                <Box>
                    {loading ? <SkeletonLoader count={1} /> : (
                        <Box
                            display="flex"
                            gap={3}
                        >
                            <time>{convertHourToDurationFormatHandler(rangeInputValue?.[0])}</time>
                            <Slider
                                max={audioFile?.current?.duration}
                                name={name}
                                value={rangeInputValue}
                                slotProps={{
                                    thumb: { id: `${audioRangeSliderName}_${sliderId}_${thumbId}` },
                                    track: { id: `${audioRangeSliderName}_${sliderId}_${trackId}` },
                                }}
                                disableSwap
                                onChange={changeRangeInputHandler}
                            />
                            <time>{convertHourToDurationFormatHandler(rangeInputValue?.[1])}</time>
                            {isPlaying ? (
                                <PauseCircleIcon
                                    sx={{
                                        color: theme.palette.primary.main,
                                        cursor: "pointer",
                                    }}
                                    onClick={togglePlayHandler}
                                />
                            ) : (
                                <PlayCircleFilledIcon
                                    sx={{
                                        color: theme.palette.primary.main,
                                        cursor: "pointer",
                                    }}
                                    onClick={togglePlayHandler}
                                />
                            )}
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default AudioRangeInput;

AudioRangeInput.propTypes = {
    name: PropTypes.string,
    setValue: PropTypes.func,
    url: PropTypes.string,
};
