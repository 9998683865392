import { inputData } from "data";
import {
    CLEAR_FILTERS,
    CLOSE_FILTERS,
    OPEN_FILTERS,
    RESET_CATEGORY_FILTER_DATA,
    SET_CATEGORY_FILTER_DATA,
    SET_FILTERS,
    SET_MODULES_FILTERS,
} from "redux/types";
import { updateStateHandler } from "utils";

const categoryFilterInitialState = {
    [inputData.names["upc/isrc"]]: {
        inputValue: "",
        selectedCategory: inputData.names.upc,
        tagsArray: [],
    },
};

const initialState = {
    categoryFilter: categoryFilterInitialState,
    filters: {},
    filtersModule: null,
    filtersModules: {},
    open: false,
};

const openFilters = (state, filtersModule) => updateStateHandler(
    state,
    {
        filtersModule,
        open: true,
    },
);

const closeFilters = (state) => updateStateHandler(
    state,
    {
        filtersModule: null,
        open: false,
    },
);

const setFilters = (state, filters) => updateStateHandler(
    state,
    { filters },
);

const setModulesFilters = (state, filters) => updateStateHandler(
    state,
    { filtersModules: filters },
);

const setCategoryFilterData = (
    state,
    {
        category,
        inputValue,
        name,
        tagsArray,
    },
) => updateStateHandler(
    state,
    {
        categoryFilter: {
            ...state.categoryFilter,
            [name]: {
                ...state.categoryFilter[name],
                ...(inputValue || inputValue === "") && { inputValue },
                ...category && { selectedCategory: category },
                ...tagsArray && { tagsArray },
            },
        },
    },
);

const resetCategoryFilterData = (state) => updateStateHandler(
    state,
    { categoryFilter: categoryFilterInitialState },
);

const clearFilters = (state) => updateStateHandler(
    state,
    { filters: {} },
);

const filtersReducer = (state = initialState, action) => {
    const {
        payload,
        type,
    } = action;

    const {
        categoryFilterData,
        filters,
        filtersModule,
    } = payload || {};

    switch (type) {
    case OPEN_FILTERS: return openFilters(
        state,
        filtersModule,
    );
    case CLOSE_FILTERS: return closeFilters(state);
    case SET_FILTERS: return setFilters(
        state,
        filters,
    );
    case SET_MODULES_FILTERS: return setModulesFilters(
        state,
        filters,
    );
    case SET_CATEGORY_FILTER_DATA: return setCategoryFilterData(
        state,
        categoryFilterData,
    );
    case RESET_CATEGORY_FILTER_DATA: return resetCategoryFilterData(state);
    case CLEAR_FILTERS: return clearFilters(state);
    default: return state;
    }
};

export default filtersReducer;
