import { gql } from "@apollo/client";

export const getUsersListQuery = gql`
    query getUsersList(
        $page: Int!,
        $limit: Int
    ) {
        listUsers(
            page: $page,
            per_page: $limit
        ) {
            posts {
                email
                given_name
                family_name
                user_id
                app_metadata {
                    roles
                }
            }
            meta {
                currentPage
                nodeCount
                resultCount
                totalPages
            }
        }
    }
`;

export const getUserQuery = gql`
    query getUser($id: String!) {
        user(id: $id) {
            email
            family_name
            given_name
            user_id
            app_metadata {
                roles
            }
        }
    }
`;
