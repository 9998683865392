import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Typography } from "@mui/material";
import {
    AutoCompleteMenu,
    Drawer,
    LabelCard,
    Loader,
    Modal,
} from "atoms";
import { DriveManagementLabelForm, NoData } from "components";
import {
    actionsData,
    identifiersData,
    inputData,
    namesData,
    statusTypesData,
} from "data";
import { useDeleteDriveManagementLabel, useGetDriveManagementFileLabels } from "hooks";
import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { openAlert, toggleDriveManagementLabelDrawer } from "redux/actions";

const DriveManagementLabelsDrawer = ({ id }) => {
    const [showLabelsMenu, setShowLabelsMenu] = useState(false);

    const [fileLabels, setFileLabels] = useState([]);

    const dispatch = useDispatch();

    const open = useSelector((state) => state.driveManagementReducer?.openDrawer);

    const labels = useSelector((state) => state.driveManagementReducer?.labels);

    const { t } = useTranslation();

    const {
        data,
        loading,
    } = useGetDriveManagementFileLabels(id);

    const { deleteDriveManagementLabel } = useDeleteDriveManagementLabel();

    const [modalData, setModalData] = useState({
        action: deleteDriveManagementLabel,
        actionType: actionsData["delete"],
        body: t(
            "messages.deleteMsgs.alert",
            { entity: t("labels.label") },
        ),
        id: null,
        open: false,
        title: t("labels.deleteLabel"),
    });

    const labelsOptions = labels ? labels.map((label) => ({
        details: label,
        fields: label.fields,
        label: label.properties?.title,
        value: label.id,
    })) : [];

    const {
        labels: { searchLabel: searchLabelInputLabel },
        names: { searchLabel: searchLabelInputName },
        types: {
            dateString: dateStringInputType,
            integer: integerInputType,
            selection: selectionInputType,
            text: textInputType,
        },
    } = inputData;

    const selectLabelHandler = (selectedLabel) => {
        if (fileLabels.find((label) => label.id === selectedLabel.value)) {
            dispatch(openAlert(
                t("messages.labelAlreadyAdded"),
                statusTypesData.error,
            ));
        } else {
            setFileLabels((prev) => [
                ...prev,
                {
                    ...selectedLabel.details,
                    selectedValues: {},
                },
            ]);

            setShowLabelsMenu(false);
        }
    };

    const closeLabelsDrawerHandler = () => {
        setFileLabels([]);

        setShowLabelsMenu(false);

        dispatch(toggleDriveManagementLabelDrawer(false));
    };

    useEffect(
        () => {
            if (data?.length > 0) {
                data.forEach(({
                    fields,
                    id: labelId,
                }) => {
                    const labelDetails = labels.find((label) => label.id === labelId);

                    const labelFields = fields && Object?.values(fields)?.map((key) => ({ ...key }));

                    const dateField = labelFields?.find((field) => field.valueType === dateStringInputType);

                    const selectionField = labelFields?.find((field) => field.valueType === selectionInputType);

                    const textField = labelFields?.find((field) => field.valueType === textInputType);

                    const integerField = labelFields?.find((field) => field.valueType === integerInputType);

                    setFileLabels((prev) => [
                        ...prev,
                        {
                            ...labelDetails,
                            selectedValues: {
                                ...selectionField && { Selection: selectionField?.selection[0] },
                                ...dateField && { Date: dateField?.dateString[0] },
                                ...textField && { Text: textField?.text[0] },
                                ...integerField && { Integer: integerField?.integer[0] },
                            },
                        },
                    ]);
                });
            }
        },
        [data], // eslint-disable-line
    );

    return (
        <Drawer
            closeAction={closeLabelsDrawerHandler}
            open={!!(open && id)}
            title={t("labels.addLabel")}
            isMini
        >
            <Modal
                modalData={modalData}
                setModalData={setModalData}
            />
            <Box padding={2}>
                <Box mb={3}>
                    {showLabelsMenu ? (
                        <AutoCompleteMenu
                            getRenderedOption={(option) => option?.label}
                            label={searchLabelInputLabel}
                            name={searchLabelInputName}
                            optionLabel={(option) => option?.label}
                            options={labelsOptions}
                            onChange={(_, option) => selectLabelHandler(option)}
                        />
                    ) : (
                        <Button
                            id={`${namesData.buttons.addLabel}_${identifiersData.ids.button}`}
                            variant="outlined"
                            fullWidth
                            onClick={() => setShowLabelsMenu(true)}
                        >
                            <Box
                                alignItems="center"
                                display="flex"
                                gap={1}
                            >
                                <AddIcon />
                                <Typography
                                    fontWeight="bold"
                                    variant="caption"
                                >
                                    {t("actions.addLabel")}
                                </Typography>
                            </Box>
                        </Button>
                    )}
                </Box>
                {loading ? <Loader /> : (
                    <Box>
                        {fileLabels.length > 0 ? fileLabels?.map?.(({
                            fields,
                            id: labelId,
                            properties,
                            selectedValues,
                        }) => (
                            <LabelCard
                                key={labelId}
                                title={properties?.title}
                                deleteAction={() => {
                                    setModalData({
                                        ...modalData,
                                        id: {
                                            fileLabels,
                                            id,
                                            labelId,
                                            setFileLabels,
                                        },
                                        open: true,
                                    });
                                }}
                            >
                                <DriveManagementLabelForm
                                    data={fields}
                                    id={id}
                                    labelId={labelId}
                                    selectedValues={selectedValues}
                                    title={properties.title}
                                />
                            </LabelCard>
                        )) : (
                            <NoData
                                body={t("labels.noLabelsDescription")}
                                title={t("labels.noLabels")}
                                type="labels"
                            />
                        )}
                    </Box>
                )}
            </Box>
        </Drawer>
    );
};

export default DriveManagementLabelsDrawer;

DriveManagementLabelsDrawer.propTypes = { id: PropTypes.string };
