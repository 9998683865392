import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData } from "data";
import { editReleaseNoteMutation } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useEditReleaseNote = (id) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [editReleaseNoteAction, { loading }] = useMutation(editReleaseNoteMutation);

    const editReleaseNote = async ({ note }) => {
        try {
            await editReleaseNoteAction({
                onCompleted: () => {
                    dispatch(openAlert(
                        t(
                            "messages.editMsgs.success",
                            { entity: `${t("releases.entity")} ${t("labels.note")}` },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: {
                    data: {
                        content: { set: note },
                    },
                    where: { id },
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        editReleaseNote,
        loading,
    };
};

export default useEditReleaseNote;
