import styled from "@emotion/styled";
import { Box } from "@mui/material";

const StyledNoData = styled(Box)(() => ({
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    marginBlock: 50,
    opacity: 0.6,
    width: " 100%",
}));

export default StyledNoData;
