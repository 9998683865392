import { useAuth0 } from "@auth0/auth0-react";
import { constantsData, urlsData } from "data";
import { useApisClient } from "hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useGetDriveManagementDrive = (id) => {
    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(true);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { logout } = useAuth0();

    const { get } = useApisClient();

    useEffect(() => {
        if (id) {
            setLoading(true);

            get(
                `${urlsData.apis.driveManagement.drives}/${id}`,
                null,
                null,
            ).then(({ data: responseData }) => setData(responseData))["catch"]((err) => {
                displayErrorHandler(
                    err.response.data.message === constantsData.networkError ? t("messages.networkFailure") : err.response.data.message,
                    dispatch,
                    openAlert,
                    err.response.status,
                    logout,
                );
            })["finally"](() => {
                setLoading(false);
            });
        }
    }, [id]); //eslint-disable-line

    return {
        data,
        loading,

    };
};

export default useGetDriveManagementDrive;
