import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StyledFooter } from "styles";

import version from "../../../../package.json";

const Footer = () => {
    const { t } = useTranslation();

    return (
        <StyledFooter>
            <Typography
                color="primary"
                textAlign="center"
                variant="h6"
            >
                {t("labels.copyrights")}
                {" "}
                &copy;
                {" "}
                {new Date().getFullYear()}
            </Typography>
            <Typography
                display="block"
                mt={2}
                textAlign="center"
                variant="caption"
            >
                {`${t("labels.version")} ${version?.version}`}
            </Typography>
        </StyledFooter>
    );
};

export default Footer;
