import { yupResolver } from "@hookform/resolvers/yup";
import { AutoCompleteMenu, Loader } from "atoms";
import { FormContainer } from "components";
import {
    actionsData,
    identifiersData,
    inputData,
    userFormData,
} from "data";
import { useRolesSelectionList } from "hooks";
import { PropTypes } from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useUserSchema } from "schemas";
import { StyledTextField } from "styles";
import { renderFormControllerHandler } from "utils";

const UserForm = ({
    action,
    loading,
    onSubmitForm,
    values,
}) => {
    const { t } = useTranslation();

    const selections = useSelector((state) => state.selectionsReducer);

    const schema = useUserSchema();

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const { loading: rolesSelectionListLoading } = useRolesSelectionList();

    const {
        control,
        formState: { errors },
    } = formMethods;

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        type,
        __,
        selectionKey,
    ) => {
        if (type === inputData.types.text) {
            return (
                <StyledTextField
                    {...field}
                    disabled={action === actionsData.edit}
                    error={errors[name]}
                    helperText={errors[name] && errors[name]?.message}
                    id={`${name}_${identifiersData.ids.input}`}
                    label={t(`labels.${label}`)}
                    variant="outlined"
                    fullWidth
                />
            );
        }

        return (
            <AutoCompleteMenu
                defaultValue={selections?.[selectionKey]?.list?.find((option) => option?.value === values?.[name])}
                errors={errors}
                getRenderedOption={(option) => option?.label}
                label={label}
                name={name}
                optionLabel={(option) => option?.label}
                options={selections?.[selectionKey]?.list}
                isRolesMenu
                onChange={(_, option) => field.onChange(option?.value)}
            />
        );
    };

    if (rolesSelectionListLoading) return <Loader />;

    return (
        <FormContainer
            action={action}
            formMethods={formMethods}
            loading={loading}
            data={renderFormControllerHandler(
                control,
                userFormData,
                renderFormControllerChildrenHandler,
            )}
            onSubmitForm={onSubmitForm}
        />
    );
};

export default UserForm;

UserForm.propTypes = {
    action: PropTypes.string,
    loading: PropTypes.bool,
    onSubmitForm: PropTypes.func,
    values: PropTypes.object,

};
