import { HIDE_LOADING_LOADER, SHOW_LOADING_LOADER } from "redux/types";
import { updateStateHandler } from "utils";

const initialState = { show: true };

const showLoading = (state) => updateStateHandler(
    state,
    { show: true },
);

const hideLoading = (state) => updateStateHandler(
    state,
    { show: false },
);

const loadingReducer = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
    case SHOW_LOADING_LOADER: return showLoading(state);
    case HIDE_LOADING_LOADER: return hideLoading(state);
    default: return state;
    }
};

export default loadingReducer;
