import { Drawer, Loader } from "atoms";
import { ReleaseClientLinkForm } from "components";
import { actionsData } from "data";
import { useEditReleaseClientLink, useGetReleaseClientLink } from "hooks";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toggleReleaseClientLinkDrawer } from "redux/actions";

const ReleaseClientLinkDrawer = ({
    id,
    setId,
}) => {
    const { t } = useTranslation();

    const open = useSelector((state) => state.releasesReducer?.openDrawer);

    const dispatch = useDispatch();

    const {
        data,
        loading,
    } = useGetReleaseClientLink(id);

    const {
        editReleaseClientLink,
        loading: editReleaseClientLinkLoading,
    } = useEditReleaseClientLink(
        id,
        setId,
    );

    const {
        client,
        releaseLink,
        releaseTrack,
    } = data || {};

    const formValues = {
        client: client?.id,
        link: releaseLink,
        track: releaseTrack?.id,
    };

    return (
        <Drawer
            closeAction={() => dispatch(toggleReleaseClientLinkDrawer(false))}
            open={!!(open && id)}
            title={`${t("actions.edit")} ${t("releases.entity")} ${t("labels.clientLink")}`}
        >
            {loading ? <Loader /> : (
                <ReleaseClientLinkForm
                    action={actionsData.edit}
                    loading={editReleaseClientLinkLoading}
                    values={formValues}
                    hasDrawer
                    onSubmitForm={editReleaseClientLink}
                />
            )}
        </Drawer>
    );
};

export default ReleaseClientLinkDrawer;

ReleaseClientLinkDrawer.propTypes = {
    id: PropTypes.number,
    setId: PropTypes.func,
};
