import { MenuItem as MuiMenuItem, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { theme } from "theme";

const MenuItem = ({
    disabled,
    icon,
    id,
    isDelete,
    label,
    onClick,
    style,
}) => (
    <MuiMenuItem
        disabled={disabled}
        id={id}
        sx={{
            color: isDelete && theme.palette?.chip?.color.failed,
            width: "100%",
            ...style,
        }}
        onClick={onClick}
    >
        {icon}
        <Typography
            component="span"
            mx={1}
            variant="subtitle1"
        >
            {label}
        </Typography>
    </MuiMenuItem>
);

export default MenuItem;

MenuItem.propTypes = {
    disabled: PropTypes.bool,
    icon: PropTypes.node,
    id: PropTypes.string,
    isDelete: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
};
