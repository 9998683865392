import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
    Box,
    IconButton,
    TableRow,
    Tooltip,
} from "@mui/material";
import { Modal, TableList, TableMenu } from "atoms";
import { DriveLinkDrawer, PageHead } from "components";
import {
    actionsData,
    identifiersData,
    namesData,
    tableHeadCellsData,
} from "data";
import { useDeleteDriveLink } from "hooks";
import { PropTypes } from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toggleDriveLinkDrawer } from "redux/actions";
import { StyledLink, StyledTableCell } from "styles";
import { theme } from "theme";
import { copyTextHandler, getSubstringHandler, openLinkInNewTabHandler } from "utils";

const ReleaseDetailsDriveLinks = ({
    data,
    releaseId,
}) => {
    const [driveLink, setDriveLink] = useState(null);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const { deleteDriveLink } = useDeleteDriveLink(
        parseInt(releaseId),
        t("releases.entity"),
    );

    const [modalData, setModalData] = useState({
        action: deleteDriveLink,
        actionType: actionsData["delete"],
        body: t(
            "messages.deleteMsgs.alert",
            { entity: `${t("releases.entity")} ${t("labels.driveLink")}` },
        ),
        id: null,
        open: false,
        title: `${t("actions.delete")} ${t("releases.entity")} ${t("labels.driveLink")}`,
    });

    const {
        button: buttonId,
        link: linkId,
        table: tableId,
    } = identifiersData.ids;

    return (
        <Box>
            <Modal
                modalData={modalData}
                setModalData={setModalData}
            />
            <DriveLinkDrawer
                driveLink={driveLink}
                entityId={releaseId}
                entityType={t("releases.entity")}
            />
            <PageHead
                title={t("labels.driveLinks")}
                titleTag="h2"
                addAction={() => {
                    setDriveLink(null);

                    dispatch(toggleDriveLinkDrawer(true));
                }}
                hasAdd
            />
            <TableList
                headCells={tableHeadCellsData.driveLinks}
                isPaginated={false}
            >
                {data?.map(({
                    id,
                    link_name: linkName,
                    url,
                }, index) => (
                    <TableRow key={id}>
                        <StyledTableCell boldCell={+true}>{linkName}</StyledTableCell>
                        <StyledTableCell
                            boldCell={+true}
                        >
                            <Box
                                alignItems="center"
                                display="flex"
                                gap={1}
                            >
                                <Tooltip title={t("actions.copy")}>
                                    <IconButton
                                        id={`${namesData.buttons.copy}_${buttonId}_${index + 1}`}
                                        onClick={() => {
                                            copyTextHandler(
                                                url,
                                                dispatch,
                                                t(
                                                    "messages.copied",
                                                    { entity: `${t("releases.entity")} ${t("labels.driveLink")}` },
                                                ),
                                            );
                                        }}
                                    >
                                        <ContentCopyIcon
                                            sx={{
                                                color: theme.palette.primary.main,
                                                fontSize: 20,
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                                <StyledLink
                                    id={`${tableId}_${linkId}_${index + 1}`}
                                    to="#"
                                    onClick={() => openLinkInNewTabHandler(url)}
                                >
                                    {getSubstringHandler(
                                        url,
                                        40,
                                    )}
                                </StyledLink>
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                            <TableMenu
                                rowId={index + 1}
                                editAction={() => {
                                    setDriveLink({
                                        id,
                                        name: linkName,
                                        url,
                                    });

                                    dispatch(toggleDriveLinkDrawer(true));
                                }}
                                setOpenModal={() => {
                                    setModalData({
                                        ...modalData,
                                        id,
                                        open: true,
                                    });
                                }}
                                hasDelete
                                hasEdit
                            />
                        </StyledTableCell>
                    </TableRow>
                ))}
            </TableList>
        </Box>
    );
};

export default ReleaseDetailsDriveLinks;

ReleaseDetailsDriveLinks.propTypes = {
    data: PropTypes.array,
    releaseId: PropTypes.number,
};
