import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { constantsData } from "data";
import { getVideosListQuery } from "graph";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useVideosList = (filters = {}) => {
    const [params] = useSearchParams();

    const pageParam = parseInt(params.get("page")) || 1;

    const [page, setPage] = useState(pageParam);

    const { isAuthenticated } = useAuth0();

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const {
        data,
        error,
        loading,
    } = useQuery(
        getVideosListQuery,
        {
            skip: !isAuthenticated,
            variables: {
                limit: 25,
                page: page - 1,
                where: filters?.videos || filters?.releases ? {
                    ...filters?.videos,
                    ...filters?.releases && {
                        release: { ...filters?.releases },
                    },
                } : {},
            },
        },
    );

    useEffect(
        () => {
            if (error?.message === constantsData.failedToFetch && !loading) {
                displayErrorHandler(
                    t("messages.networkFailure"),
                    dispatch,
                    openAlert,
                );
            }
        },
        [error, data], // eslint-disable-line
    );

    return {
        count: data?.listVideos?.meta?.nodeCount,
        data: data?.listVideos?.posts,
        error,
        loading,
        page: data?.listVideos?.meta?.currentPage,
        setPage,
        totalPages: data?.listVideos?.meta?.totalPages,
    };
};

export default useVideosList;
