import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import {
    Box,
    Card as MuiCard,
    CardContent,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { identifiersData, namesData } from "data";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";

const LabelCard = ({
    children,
    deleteAction,
    title,
}) => {
    const { t } = useTranslation();

    return (
        <Box>
            <MuiCard
                sx={{
                    boxShadow: 8,
                    margin: "auto",
                    marginBottom: 5,
                    minHeight: "fit-content",
                    textAlign: "center",
                    width: 290,
                }}
            >
                <CardContent>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        mb={3}
                    >
                        <Typography
                            variant="h6"
                            gutterBottom
                        >
                            {title}
                        </Typography>
                        <Tooltip title={t("actions.delete")}>
                            <IconButton
                                id={`${namesData.buttons["delete"]}_${identifiersData.ids.button}`}
                                onClick={deleteAction}
                            >
                                <DeleteForeverOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box>
                        {children}
                    </Box>
                </CardContent>
            </MuiCard>
        </Box>
    );
};

export default LabelCard;

LabelCard.propTypes = {
    children: PropTypes.node,
    deleteAction: PropTypes.func,
    title: PropTypes.string,
};
