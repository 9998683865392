import { useCallback, useRef } from "react";

const useGetLastElement = (loading, hasMore, action) => {
    const observer = useRef();

    const lastElement = useCallback(
        (node) => {
            if (loading) return;

            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver((entries) => { //eslint-disable-line
                if (entries[0].isIntersecting && hasMore) action();
            });

            if (node) observer.current.observe(node);
        },
        [loading, hasMore], //eslint-disable-line
    );

    return { lastElement };
};

export default useGetLastElement;
