import styled from "@emotion/styled";
import { Box } from "@mui/material";

const StyledMarker = styled(Box)(({
    position,
    theme,
}) => ({
    background: theme?.palette?.pending?.main,
    bottom: "15px",
    cursor: "pointer",
    display: "block",
    height: "15px",
    left: position,
    position: "absolute",
    width: "8px",
}));

export default StyledMarker;
