import fileData from "data/file";
import inputData from "data/input";
import selectionsData from "data/selections";

const { xml: xmlFileName } = fileData.names;

const {
    autoComplete: autoCompleteInputType,
    date: dateInputType,
    file: fileInputType,
    title: titleInputType,
    titleWithTopLine: titleWithTopLineInputType,
} = inputData.types;

const {
    client: clientInputName,
    generalSettings: generalSettingsInputName,
    release: releaseInputName,
    releaseDate: releaseDateInputName,
    territory: territoryInputName,
    xmlFields: xmlFieldsInputName,
} = inputData.names;

const {
    client: clientInputLabel,
    generalSettings: generalSettingsInputLabel,
    release: releaseInputLabel,
    releaseDate: releaseDateInputLabel,
    territory: territoryInputLabel,
    xml: xmlFileInputLabel,
    xmlFields: xmlFieldsInputLabel,
} = inputData.labels;

const dynamicXmlFormData = [
    {
        col: { xs: 12 },
        label: generalSettingsInputLabel,
        name: generalSettingsInputName,
        type: titleInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        isAsyncMenu: true,
        label: releaseInputLabel,
        name: releaseInputName,
        selectionKey: selectionsData.keys.releases,
        type: autoCompleteInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        hasOffset: true,
        isAsyncMenu: true,
        label: clientInputLabel,
        name: clientInputName,
        selectionKey: selectionsData.keys.clients,
        type: autoCompleteInputType,

    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        isAsyncMenu: true,
        label: territoryInputLabel,
        name: territoryInputName,
        selectionKey: selectionsData.keys.territories,
        type: autoCompleteInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: releaseDateInputLabel,
        name: releaseDateInputName,
        type: dateInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: xmlFileInputLabel,
        name: xmlFileName,
        type: fileInputType,
    },
    {
        col: { xs: 12 },
        label: xmlFieldsInputLabel,
        name: xmlFieldsInputName,
        type: titleWithTopLineInputType,
    },
];

export default dynamicXmlFormData;
