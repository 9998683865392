import styled from "@emotion/styled";
import { Grid } from "@mui/material";

const StyledBox = styled(Grid)(({ theme }) => ({
    "&::-webkit-scrollbar": { display: "none" },
    background: theme.palette?.grey[100],
    borderRadius: "20px 20px 0 0",
    boxShadow: `0px 12px 24px -4px ${theme.palette?.grey[902]}`,
    padding: "15px",
    width: "100%",
}));

export default StyledBox;
