import { Box } from "@mui/material";
import { Loader } from "atoms";
import { PageHead, ReleaseVideoForm } from "components";
import { useGetTrack, useVideoTypesSelectionList } from "hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ReleaseVideosTab = () => {
    const [trackId, setTrackId] = useState(null);

    const { t } = useTranslation();

    const {
        data,
        loading: getTrackLoading,
    } = useGetTrack(trackId);

    const { loading } = useVideoTypesSelectionList();

    const formValues = { videos: data?.videos };

    return loading ? <Loader /> : (
        <Box>
            <PageHead title={t("tabs.videos")} />
            <ReleaseVideoForm
                setTrackId={setTrackId}
                trackId={trackId}
                trackLoading={getTrackLoading}
                values={formValues}
            />
        </Box>
    );
};

export default ReleaseVideosTab;
