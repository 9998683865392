import { yupResolver } from "@hookform/resolvers/yup";
import { AutoCompleteMenu } from "atoms";
import { FormContainer } from "components";
import { foldersStructureChildFolderFormData, identifiersData, inputData } from "data";
import { PropTypes } from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFoldersStructureChildFolderSchema } from "schemas";
import { StyledTextField } from "styles";
import { renderFormControllerHandler } from "utils";

const FoldersStructureChildFolderForm = ({
    action,
    onSubmitForm,
    setModalData,
    values,
}) => {
    const { t } = useTranslation();

    const selections = useSelector((state) => state.selectionsReducer);

    const schema = useFoldersStructureChildFolderSchema();

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const {
        control,
        formState: { errors },
    } = formMethods;

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        type,
        __,
        selectionKey,
    ) => {
        if (type === inputData.types.autoComplete) {
            return (
                <AutoCompleteMenu
                    defaultValue={selections?.[selectionKey]?.find((option) => option?.value === values?.[name])}
                    errors={errors}
                    getRenderedOption={(option) => option?.label}
                    label={label}
                    name={name}
                    optionLabel={(option) => option?.label}
                    options={selections?.[selectionKey]}
                    onChange={(_, option) => field.onChange(option?.value)}
                />
            );
        }

        return (
            <StyledTextField
                {...field}
                error={errors[name]}
                helperText={errors[name] && errors[name]?.message}
                id={`${name}_${identifiersData.ids.input}`}
                label={t(`labels.${label}`)}
                variant="outlined"
                fullWidth
            />
        );
    };

    return (
        <FormContainer
            action={action}
            formMethods={formMethods}
            setModalData={setModalData}
            data={renderFormControllerHandler(
                control,
                foldersStructureChildFolderFormData,
                renderFormControllerChildrenHandler,
            )}
            hasCancelButton
            withoutShadow
            onSubmitForm={onSubmitForm}
        />
    );
};

export default FoldersStructureChildFolderForm;

FoldersStructureChildFolderForm.propTypes = {
    action: PropTypes.string,
    onSubmitForm: PropTypes.func,
    setModalData: PropTypes.func,
    values: PropTypes.object,
};
