import {
    CLEAR_FILTERS,
    CLOSE_FILTERS,
    OPEN_FILTERS,
    RESET_CATEGORY_FILTER_DATA,
    SET_CATEGORY_FILTER_DATA,
    SET_FILTERS,
    SET_MODULES_FILTERS,
} from "redux/types";

export const openFilters = (filtersModule) => ({
    payload: { filtersModule },
    type: OPEN_FILTERS,
});

export const closeFilters = () => ({ type: CLOSE_FILTERS });

export const setFilters = (filters) => ({
    payload: { filters },
    type: SET_FILTERS,
});

export const setModulesFilters = (filters) => ({
    payload: { filters },
    type: SET_MODULES_FILTERS,
});

export const setCategoryFilterData = (data) => ({
    payload: { categoryFilterData: data },
    type: SET_CATEGORY_FILTER_DATA,
});

export const resetCategoryFilterData = () => ({ type: RESET_CATEGORY_FILTER_DATA });

export const clearFilters = () => ({ type: CLEAR_FILTERS });
