import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Grid } from "@mui/material";
import { DetailsText } from "atoms";
import { PageHead } from "components";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";

const TrackDetailsRoyalty = ({ data }) => {
    const { t } = useTranslation();

    const {
        master,
        mechanical_performance: mechanicalPerformance,
        neighboring,
        synchronization,
    } = data || {};

    return (
        <Box
            alignItems="end"
            display="flex"
            flexDirection="column"
        >
            <PageHead
                title={t("labels.royalty")}
                titleTag="h2"
            />
            <Grid
                spacing={2}
                container
            >
                <Grid
                    md={6}
                    xs={12}
                    item
                >
                    <DetailsText
                        info={master ? <CheckIcon /> : <ClearIcon color="error" />}
                        tag={t("labels.master")}
                    />
                </Grid>
                <Grid
                    md={6}
                    xs={12}
                    item
                >
                    <DetailsText
                        info={mechanicalPerformance ? <CheckIcon /> : <ClearIcon color="error" />}
                        tag={t("labels.mechanicalPerformance")}
                    />
                </Grid>
                <Grid
                    md={6}
                    xs={12}
                    item
                >
                    <DetailsText
                        info={neighboring ? <CheckIcon /> : <ClearIcon color="error" />}
                        tag={t("labels.neighboring")}
                    />
                </Grid>
                <Grid
                    md={6}
                    xs={12}
                    item
                >
                    <DetailsText
                        info={synchronization ? <CheckIcon /> : <ClearIcon color="error" />}
                        tag={t("labels.synchronization")}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default TrackDetailsRoyalty;

TrackDetailsRoyalty.propTypes = { data: PropTypes.object };
