import styled from "@emotion/styled";
import { Box } from "@mui/material";

const StyledContent = styled(Box)(({
    open,
    theme,
}) => ({
    "& .MuiContainer-root": {
        "@media(max-width: 700px) ": { padding: 20 },
        "@media(min-width: 700px) ": { padding: 30 },
        backgroundColor: theme.palette?.grey[100],
        borderRadius: "5px",
        minHeight: "90vh",

        width: "96%",
    },
    transition: theme?.transitions?.create(
        ["width", "margin"],
        {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
        },
    ),
    ...open && {
        transition: theme?.transitions?.create(
            ["width", "margin"],
            {
                duration: theme.transitions.duration.enteringScreen,
                easing: theme.transitions.easing.sharp,
            },
        ),
        width: "calc(100% - 180px)",
    },
    flexGrow: 1,
    marginBottom: 50,
    marginTop: 100,
    width: "85%",
}));

export default StyledContent;
