import { useMutation } from "@apollo/client";
import {
    constantsData,
    identifiersData,
    namesData,
    statusTypesData,
} from "data";
import { deleteClientFoldersStructureFolderMutation, getClientQuery } from "graph";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useDeleteClientFoldersStructureFolder = (clientId, setSelectedTreeItemData) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [deleteClientFoldersStructureFolderAction, { loading }] = useMutation(
        deleteClientFoldersStructureFolderMutation,
        {
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: getClientQuery,
                    variables: {
                        where: { id: clientId },
                    },
                },
            ],
        },
    );

    const { deleteClientFoldersStructureFolderPending: deleteClientFoldersStructureFolderPendingToastName } = namesData.toasts;

    const { toast: toastId } = identifiersData.ids;

    const deleteClientFoldersStructureFolder = async ({
        name,
        parentPath,
    }) => {
        try {
            await deleteClientFoldersStructureFolderAction({
                onCompleted: () => {
                    dispatch(openAlert(
                        t(
                            "messages.deleteMsgs.success",
                            { entity: t("labels.folder") },
                        ),
                        statusTypesData.success,
                    ));

                    setSelectedTreeItemData({
                        children: [],
                        id: "",
                        isSelection: false,
                        name: "",
                        parentPath: "",
                        type: "",
                    });
                },
                variables: {
                    clientId,
                    name,
                    parentPath,
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        } finally {
            toast.dismiss(`${deleteClientFoldersStructureFolderPendingToastName}_${toastId}`);
        }
    };

    useEffect(
        () => {
            if (loading) {
                toast.info(
                    () => t(
                        "messages.deleteMsgs.pending",
                        { entity: t("labels.folder") },
                    ),
                    { toastId: `${deleteClientFoldersStructureFolderPendingToastName}_${toastId}` },
                );
            }
        },
        [loading], //eslint-disable-line
    );

    return {
        deleteClientFoldersStructureFolder,
        loading,
    };
};

export default useDeleteClientFoldersStructureFolder;
