import styled from "@emotion/styled";
import { AppBar } from "@mui/material";
import { identifiersData } from "data";

const sidebarWidth = 240;

const StyledNavbar = styled(
    AppBar,
    { shouldForwardProp: (prop) => prop !== identifiersData.props.withoutShadow },
)(({
    open,
    theme,
    withoutShadow,
}) => ({
    backgroundColor: theme.palette?.grey[100],
    boxShadow: withoutShadow && "none",
    transition: theme?.transitions?.create(
        ["width", "margin"],
        {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
        },
    ),
    zIndex: theme?.zIndex?.drawer && `${theme.zIndex.drawer + 1} !important`,
    ...open && {
        marginLeft: sidebarWidth,
        transition: theme?.transitions?.create(
            ["width", "margin"],
            {
                duration: theme.transitions.duration.enteringScreen,
                easing: theme.transitions.easing.sharp,
            },
        ),
        width: `calc(100% - ${sidebarWidth}px) !important`,
    },
}));

export default StyledNavbar;
