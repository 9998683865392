export const SET_CURRENT_RELEASE = "SET_CURRENT_RELEASE";

export const SET_RELEASE_TRACKS = "SET_RELEASE_TRACKS";

export const REMOVE_CURRENT_RELEASE = "REMOVE_CURRENT_RELEASE";

export const TOGGLE_RELEASE_CLIENT_LINK_DRAWER = "TOGGLE_RELEASE_CLIENT_LINK_DRAWER";

export const SET_COMPLETED_IMPORTER_RELEASE = "SET_COMPLETED_IMPORTER_RELEASE";

export const SET_INVALID_IMPORTER_RELEASE = "SET_INVALID_IMPORTER_RELEASE";

export const STOP_RELEASE_IMPORTING = "STOP_RELEASE_IMPORTING";

export const CLEAR_IMPORTER_RELEASES = "CLEAR_IMPORTER_RELEASES";

export const REMOVE_INVALID_IMPORTER_RELEASE = "REMOVE_INVALID_IMPORTER_RELEASE";
