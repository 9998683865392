import { useMutation } from "@apollo/client";
import { constantsData, importerConfigData, statusTypesData } from "data";
import { createReleaseDocumentMutation, getReleaseQuery } from "graph";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useCreateReleaseDocument = (
    releaseId,
    withoutRefetch,
    withoutAlert,
) => {
    const [controller, setController] = useState(null);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [createReleaseDocumentAction, { loading }] = useMutation(
        createReleaseDocumentMutation,
        {
            ...!withoutRefetch && {
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: getReleaseQuery,
                        variables: {
                            where: { id: releaseId },
                        },
                    },
                ],
            },
        },
    );

    const createReleaseDocument = async (
        {
            documentFile,
            tracks,
            type,
        },
        id,
        importerData,
    ) => {
        if (window?.__RUNTIME_CONFIG__?.ENABLE_DEBUG_LOGS === "true" && importerData) { // eslint-disable-line
            console.log(
                "releaseIdFromCreateReleaseDocumentHook",
                id,
            );
        }

        try {
            const newController = new AbortController();

            setController(newController);

            if (importerData) importerData?.setCurrentStep(importerConfigData.steps.creatingDocument);

            await createReleaseDocumentAction({
                context: {
                    fetchOptions: { signal: newController.signal },
                },
                onCompleted: ({ createOneDocument }) => {
                    if (importerData) {
                        importerData?.setCreatedDocuments((prev) => [
                            ...prev,
                            {
                                ...importerData?.document,
                                id: createOneDocument?.id,
                            },
                        ]);
                    }

                    if (!withoutAlert) {
                        dispatch(openAlert(
                            t(
                                "messages.createMsgs.success",
                                { entity: `${t("releases.entity")} ${t("labels.document")}` },
                            ),
                            statusTypesData.success,
                        ));
                    }
                },
                variables: {
                    data: {
                        file: {
                            connect: { id: documentFile },
                        },
                        release: {
                            connect: { id: importerData ? id : releaseId },
                        },
                        tracks: { connect: tracks },
                        ...type && {
                            type: {
                                connect: { id: type },
                            },
                        },
                    },
                },

            });
        } catch (err) {
            if (!withoutAlert) {
                displayErrorHandler(
                    err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                    dispatch,
                    openAlert,
                );
            }

            if (importerData) {
                dispatch(importerData?.setInvalidImporterRelease(
                    {
                        document: {
                            data: importerData?.document,
                            message: err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                        },
                        originalData: importerData?.originalData,
                        release: { data: importerData?.release },
                        ...err.message === constantsData.failedToFetch && { isNetworkFailed: true },
                    },
                ));

                importerData?.setStatus((prev) => ({
                    ...prev,
                    documents: {
                        ...prev.documents,
                        loading: false,
                        success: false,
                    },
                }));
            }
        } finally {
            setController(null);

            if (importerData) importerData?.setCurrentStep(null);
        }
    };

    return {
        cancelRequest: () => controller && controller.abort(),
        createReleaseDocument,
        loading,
    };
};

export default useCreateReleaseDocument;
