import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useMemberSchema = () => {
    const { t } = useTranslation();

    return yup.object({
        email: yup.string().email(t("messages.inputMsgs.invalidEmail")).required(t("messages.inputMsgs.required")),
    });
};

export default useMemberSchema;
