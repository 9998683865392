import { AttachFileOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { FormHelperText } from "@mui/material";
import { identifiersData, inputData } from "data";
import { PropTypes } from "prop-types";
import { useRef } from "react";

const UploadInput = ({
    accept,
    color,
    disabled,
    error,
    field,
    hasError,
    id,
    label,
    loading,
    onChange,
}) => {
    const inputRef = useRef();

    const {
        button: buttonId,
        input: inputId,
    } = identifiersData.ids;

    const { file: fileInputName } = inputData.names;

    return (
        <>
            <LoadingButton
                color={color}
                disabled={disabled}
                id={id ? `${id}_${inputId}_${buttonId}` : `${fileInputName}_${inputId}_${buttonId}`}
                loading={loading}
                variant="outlined"
                sx={{
                    height: "54px",
                    textTransform: "capitalize",
                }}
                fullWidth
                onClick={() => inputRef.current.click()}
                {...field}
            >
                <AttachFileOutlined />
                {label}
                <input
                    accept={accept}
                    id={id ? `${id}_${inputId}` : `${fileInputName}_${inputId}`}
                    ref={inputRef}
                    type={inputData.types.file}
                    hidden
                    onChange={(e) => {
                        if (e.target.files[0]) onChange(e);
                    }}
                />
            </LoadingButton>
            {hasError && (
                <FormHelperText
                    sx={{ marginLeft: 2 }}
                    error
                >
                    {error}
                </FormHelperText>
            )}
        </>
    );
};

export default UploadInput;

UploadInput.propTypes = {
    accept: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    color: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    field: PropTypes.object,
    hasError: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
};
