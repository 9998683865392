import { Box } from "@mui/material";
import { PageHead, ReleaseLyricsFileForm } from "components";
import { useGetTrack } from "hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ReleaseLyricsFilesTab = () => {
    const [trackId, setTrackId] = useState(null);

    const { t } = useTranslation();

    const {
        data,
        loading,
    } = useGetTrack(trackId);

    const formValues = { lyricsFiles: data?.lyric_files };

    return (
        <Box>
            <PageHead title={t("tabs.lyricsFiles")} />

            <ReleaseLyricsFileForm
                setTrackId={setTrackId}
                trackId={trackId}
                trackLoading={loading}
                values={formValues}
            />
        </Box>
    );
};

export default ReleaseLyricsFilesTab;
