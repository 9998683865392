import styled from "@emotion/styled";
import { Box } from "@mui/material";

const StyledList = styled(Box)(({
    length,
    loading,
    theme,
}) => ({
    "& svg": {
        color: theme.palette?.primary.main,
        fontSize: 30,
    },
    alignItems: length > 0 && !loading ? "start" : "center",
    borderRadius: "5px",
    display: "flex",
    height: "fit-content",
    justifyContent: length > 0 && !loading ? "start" : "center",
    marginBlock: 10,
    maxHeight: "300px",
    overFlow: "auto",
    padding: "10px",
    width: "100%",
}));

export default StyledList;
