import fileData from "data/file";
import inputData from "data/input";
import selectionsData from "data/selections";

const {
    artists: artistsSelectionKey,
    genres: genresSelectionKey,
    releaseTypes: releaseTypesSelectionKey,
    rightTypes: rightTypesSelectionKey,
} = selectionsData.keys;

const { image: imageFileName } = fileData.names;

const {
    autoComplete: autoCompleteInputType,
    checkBox: checkInputType,
    date: dateInputType,
    file: fileInputType,
    text: textInputType,
} = inputData.types;

const {
    albumCode: albumCodeInputName,
    discNumber: discNumberInputName,
    endDate: endDateInputName,
    genre: genreInputName,
    isCompleted: isCompletedInputName,
    nameAr: nameArInputName,
    nameEn: nameEnInputName,
    primaryArtist: primaryArtistInputName,
    releaseDate: releaseDateInputName,
    releaseType: releaseTypeInputName,
    rightType: rightTypeInputName,
} = inputData.names;

const {
    albumCode: albumCodeInputLabel,
    discNumber: discNumberInputLabel,
    endDate: endDateInputLabel,
    genre: genreInputLabel,
    image: imageFileInputLabel,
    isCompleted: isCompletedInputLabel,
    nameAr: nameArInputLabel,
    nameEn: nameEnInputLabel,
    primaryArtist: primaryArtistInputLabel,
    releaseDate: releaseDateInputLabel,
    releaseType: releaseTypeInputLabel,
    rightType: rightTypeInputLabel,
} = inputData.labels;

const releaseInfoFormData = [
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: nameEnInputLabel,
        name: nameEnInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        hasOffset: true,
        label: nameArInputLabel,
        name: nameArInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: albumCodeInputLabel,
        name: albumCodeInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        hasOffset: true,
        label: releaseTypeInputLabel,
        name: releaseTypeInputName,
        selectionKey: releaseTypesSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: rightTypeInputLabel,
        name: rightTypeInputName,
        selectionKey: rightTypesSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        hasOffset: true,
        isAsyncMenu: true,
        label: primaryArtistInputLabel,
        name: primaryArtistInputName,
        selectionKey: artistsSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: genreInputLabel,
        name: genreInputName,
        selectionKey: genresSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        hasOffset: true,
        isOptional: true,
        label: discNumberInputLabel,
        name: discNumberInputName,
        type: textInputType,

    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: releaseDateInputLabel,
        name: releaseDateInputName,
        type: dateInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        hasOffset: true,
        isOptional: true,
        label: endDateInputLabel,
        name: endDateInputName,
        type: dateInputType,

    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: imageFileInputLabel,
        name: imageFileName,
        type: fileInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: isCompletedInputLabel,
        name: isCompletedInputName,
        type: checkInputType,
    },
];

export default releaseInfoFormData;
