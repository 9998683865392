import { constantsData, regexData } from "data";
import { clearImporterReleases, setInvalidImporterRelease } from "redux/actions";
import { convertDateToLocaleStringFormatHandler, getNextDayDateHandler, reverseDateHandler } from "utils";
import * as XLSX from "xlsx";

import { getDocumentsHandler } from "./documents";
import { getReleaseImageHandler } from "./release";
import { getTrackFileHandler } from "./tracks";
import { getVideosHandler } from "./videos";

export const readXlsxFileHandler = async (states, data, utils) => {
    const {
        files,
        setCurrentRelease,
        setDocuments,
        setReleases,
        setXlsxFiles,
        setXlsxFilesDetails,
    } = states;

    const { file: xlsxFile } = data || {};

    const {
        dispatch,
        translation,
    } = utils;

    dispatch(clearImporterReleases());

    setReleases([]);

    setXlsxFiles([]);

    setDocuments([]);

    setXlsxFilesDetails([]);

    setCurrentRelease(null);

    const extractDateFromExcelSheetHandler = (date) => {
        if (regexData.date.test(`${date}`)) return convertDateToLocaleStringFormatHandler(getNextDayDateHandler(new Date(date)));

        if (new Date(reverseDateHandler(date)).toString() === constantsData.invalidDate) return translation("messages.inputMsgs.invalidDate");

        return convertDateToLocaleStringFormatHandler(new Date(reverseDateHandler(date)));
    };

    const bufferedXlsxFile = await xlsxFile.arrayBuffer();

    const xlsxFileReader = XLSX.read(
        bufferedXlsxFile,
        { cellDates: true },
    );

    const xlsxFileSheet = xlsxFileReader.Sheets[xlsxFileReader.SheetNames[0]];

    const xlsxFileJsonData = XLSX.utils.sheet_to_json(xlsxFileSheet);

    const xlsxFileData = xlsxFileJsonData[0];

    if (xlsxFileData?.["Album Name (En)"] || xlsxFileData?.["Album Code"] || xlsxFileData?.UPC) {
        getDocumentsHandler(
            xlsxFileData?.UPC,
            null,
            files,
            setDocuments,
        );

        const releaseData = {
            albumNameAr: xlsxFileData?.["Album Name (AR)"],
            albumNameEn: xlsxFileData?.["Album Name (En)"],
            artist: {
                artistNameAr: xlsxFileData?.["Artist Name (AR)"],
                artistNameEn: xlsxFileData?.["Artist Name (EN)"],
                code: xlsxFileData?.["Artist Code"],
                isni: xlsxFileData?.["Artist ISNI"],
                nationality: xlsxFileData?.["Artist Nationality"],
            },
            code: xlsxFileData?.["Album Code"],
            discNum: xlsxFileData?.["Disc Num"],
            endDate: extractDateFromExcelSheetHandler(xlsxFileData?.["End Date"]),
            genre: xlsxFileData?.Genre,
            image: getReleaseImageHandler(
                xlsxFileData?.UPC,
                files,
            ),
            note: xlsxFileData?.Note,
            ownership: {
                cLine: xlsxFileData?.["C Line"],
                label: xlsxFileData?.Label,
                pLine: xlsxFileData?.["P Line"],
                territory: xlsxFileData?.Territories,
            },
            releaseDate: extractDateFromExcelSheetHandler(xlsxFileData?.["Release Date"]),
            releaseType: xlsxFileData?.["Release Type"],
            rightType: xlsxFileData?.["Right Type"],
            upc: xlsxFileData?.UPC?.toString(),
        };

        if (!releaseData?.upc) {
            dispatch(setInvalidImporterRelease(
                {
                    originalData: { release: releaseData },
                    release: {
                        data: releaseData,
                        message: translation("messages.importerMsgs.upcIsMissing"),
                    },
                },
            ));
        }

        setReleases((prev) => [...prev, releaseData]);

        xlsxFileJsonData.forEach((file) => {
            getDocumentsHandler(
                releaseData?.upc,
                file?.ISRC,
                files,
                setDocuments,
            );

            const formattedXlsxFileData = {
                ...releaseData,
                composers: file?.["Composer (EN)"],
                customId: file?.["Custom ID"],
                file: getTrackFileHandler(
                    file?.ISRC || undefined,
                    files,
                ),
                isrc: file?.ISRC,
                iswc: file?.ISWC,
                note: file?.Note,
                otherIsrc: file?.["Other ISRC"],
                ...(
                    file?.["Secondary Artist Name (AR)"]
                    || file?.["Secondary Artist Name (EN)"]
                    || file?.["Secondary Artist Code"]
                    || file?.["Secondary Artist ISNI"]
                    || file?.["Secondary Artist Nationality"]
                ) && {
                    secondaryArtist: {
                        artistNameAr: file?.["Secondary Artist Name (AR)"],
                        artistNameEn: file?.["Secondary Artist Name (EN)"],
                        code: file?.["Secondary Artist Code"],
                        isni: file?.["Secondary Artist ISNI"],
                        isrc: file?.ISRC,
                        nationality: file?.["Secondary Artist Nationality"],
                    },
                },
                trackNum: file?.["Track Num"],
                trackTitleAr: file?.["Track Title (AR)"],
                trackTitleEn: file?.["Track Title (EN)"],
                videoType: file?.["Video Type"],
                videos: getVideosHandler(
                    file?.ISRC || undefined,
                    files,
                ) || [],
                writers: file?.["Song Writer (EN)"],
            };

            setXlsxFilesDetails((prev) => [
                ...prev,
                formattedXlsxFileData,
            ]);
        });
    }
};

export const getXlsxFilesHandler = (states, data) => {
    const { setXlsxFiles } = states;

    const { filesList } = data || {};

    const xlsxFilesList = filesList?.filter((file) => file?.name?.includes(".xlsx"));

    setXlsxFiles(xlsxFilesList);
};
