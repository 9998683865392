import inputData from "data/input";
import selectionsData from "data/selections";

const { videos: videosSelectionKey } = selectionsData.keys;

const {
    autoComplete: autoCompleteInputType,
    time: timeInputType,
} = inputData.types;

const {
    from: fromInputName,
    to: toInputName,
    video: videoInputName,
} = inputData.names;

const {
    from: fromInputLabel,
    to: toInputLabel,
    video: videoInputLabel,
} = inputData.labels;

const releaseVideoCutFormData = [
    {
        col: {
            md: 4,
            xs: 12,
        },
        hasOffset: true,
        label: videoInputLabel,
        name: videoInputName,
        selectionKey: videosSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        isOptional: true,
        label: fromInputLabel,
        name: fromInputName,
        type: timeInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        isOptional: true,
        label: toInputLabel,
        name: toInputName,
        type: timeInputType,
    },

];

export default releaseVideoCutFormData;
