import styled from "@emotion/styled";
import { TableCell, tableCellClasses } from "@mui/material";
import { identifiersData } from "data";

const {
    boldCell: boldCellProp,
    rowHeight: rowHeightProp,
} = identifiersData.props;

const StyledTableCell = styled(
    TableCell,
    { shouldForwardProp: (prop) => prop !== boldCellProp && prop !== rowHeightProp },
)(({
    boldCell,
    theme,
}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme?.palette?.primary?.main,
        color: theme?.palette?.grey[100],
        fontSize: 11,
        lineHeight: 1.5,
    },
    [`&.${tableCellClasses.body}`]: {
        "& a": { fontWeight: boldCell ? "bold" : "normal" },
        fontSize: 14,
        fontWeight: boldCell ? "bold" : "normal",
        maxWidth: 200,
        overflow: "hidden",
        textOverflow: "ellipsis",
        textWrap: "wrap",
        whiteSpace: "nowrap",
    },
}));

export default StyledTableCell;
