import { Box } from "@mui/material";
import { PageHead, ReleaseNoteForm } from "components";
import { actionsData } from "data";
import { useCreateReleaseNote, useEditReleaseNote } from "hooks";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ReleaseNoteTab = () => {
    const note = useSelector((state) => state.releasesReducer)?.currentRelease?.notes;

    const { t } = useTranslation();

    const {
        createReleaseNote,
        loading: createReleaseNoteLoading,
    } = useCreateReleaseNote();

    const {
        editReleaseNote,
        loading: editReleaseNoteLoading,
    } = useEditReleaseNote(note?.id);

    const formValues = { note: note?.content };

    return (
        <Box>
            <PageHead title={t("tabs.note")} />
            <ReleaseNoteForm
                action={note?.content ? actionsData.edit : actionsData.create}
                loading={createReleaseNoteLoading || editReleaseNoteLoading}
                values={formValues}
                onSubmitForm={note?.content ? editReleaseNote : createReleaseNote}
            />
        </Box>
    );
};

export default ReleaseNoteTab;
