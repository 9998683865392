import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData, urlsData } from "data";
import { createTerritoryMutation } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useCreateTerritory = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [createTerritoryAction, { loading }] = useMutation(createTerritoryMutation);

    const createTerritory = async ({
        countries,
        name,
    }) => {
        try {
            await createTerritoryAction({
                onCompleted: () => {
                    navigate(urlsData.routes.territories.url);

                    dispatch(openAlert(
                        t(
                            "messages.createMsgs.success",
                            { entity: t("territories.entity") },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: {
                    data: {
                        countries: { connect: countries?.map(({ value }) => ({ id: value })) },
                        name,
                    },
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        createTerritory,
        loading,
    };
};

export default useCreateTerritory;
