import { gql } from "@apollo/client";

export const createDriveLinkMutation = gql`
    mutation createDriveLink(
        $entityId: Int!,
        $entityType: EntityType!,
        $name: String!,
        $url: String!
    ) {
        createOneEntityLink(
            entityId: $entityId,
            entityType: $entityType,
            link_name: $name,
            url: $url
        ) {
            id
        }
    }
`;

export const editDriveLinkMutation = gql`
    mutation editDriveLink(
        $id: Int!,
        $name: String,
        $url: String
    ) {
        updateEntityLink(
            id: $id,
            name: $name,
            url: $url
        ) {
            id
        }
    }
`;

export const deleteDriveLinkMutation = gql`
    mutation deleteDriveLink($id: Int!) {
        deleteEntityLink(id: $id) {
            id
        }
    }
`;
