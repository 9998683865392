import { useAuth0 } from "@auth0/auth0-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Switch, Typography } from "@mui/material";
import { AutoCompleteMenu, SkeletonLoader } from "atoms";
import { FormContainer } from "components";
import {
    inputData,
    permissionsData,
    releaseRoyaltyFormData,
} from "data";
import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useReleaseRoyaltySchema } from "schemas";
import { canDo, renderFormControllerHandler } from "utils";

const ReleaseRoyaltyForm = ({
    action,
    loading,
    onSubmitForm,
    setTrackId,
    trackId,
    trackLoading,
    values,
}) => {
    const [initialRender, setInitialRender] = useState(true);

    const { t } = useTranslation();

    const tracksList = useSelector((state) => state.releasesReducer)?.releaseTracks;

    const { user } = useAuth0();

    const schema = useReleaseRoyaltySchema();

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const {
        control,
        formState: { errors },
        setValue,
        watch,
    } = formMethods;

    const {
        mediaCreate: mediaCreatePermission,
        mediaEdit: mediaEditPermission,
        mediaRead: mediaReadPermission,
    } = permissionsData.permissions.keys;

    const allowedPermissions = values ? [mediaEditPermission, mediaReadPermission] : [mediaCreatePermission, mediaReadPermission];

    const {
        master: masterInputName,
        mechanicalPerformance: mechanicalPerformanceInputName,
        neighboring: neighboringInputName,
        synchronization: synchronizationInputName,
        track: trackInputName,
    } = inputData.names;

    const {
        autoComplete: autoCompleteInputType,
        switch: switchInputType,
    } = inputData.types;

    const tracksOptions = tracksList?.map(({
        id,
        title,
    }) => ({
        label: title,
        value: id,
    }));

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        type,
        isOptional,
    ) => {
        if (type === switchInputType && trackId && watch(trackInputName)) {
            return trackLoading ? name === masterInputName && <SkeletonLoader /> : (
                <Box
                    alignItems="center"
                    display="flex"
                >
                    <Switch
                        {...field}
                        checked={!!watch(name)}
                        value={watch(name)}
                        onChange={(event) => {
                            setValue(
                                name,
                                event.target.checked,
                                { shouldDirty: true },
                            );
                        }}
                    />
                    <Typography
                        color="primary"
                        component="span"
                    >
                        {t(`labels.${label}`)}
                    </Typography>
                </Box>
            );
        }

        if (type === autoCompleteInputType) {
            return (
                <AutoCompleteMenu
                    defaultValue={{}}
                    errors={errors}
                    getRenderedOption={(option) => option?.label}
                    isOptional={isOptional}
                    label={label}
                    name={name}
                    optionLabel={(option) => option?.label || ""}
                    options={tracksOptions}
                    onChange={(_, option) => {
                        if (option) field.onChange(option.value);
                        else field.onChange("");
                    }}
                />
            );
        }
    };

    useEffect(
        () => {
            if (trackId && initialRender) {
                const {
                    master,
                    mechanicalPerformance,
                    neighboring,
                    synchronization,
                } = values || {};

                setValue(
                    masterInputName,
                    master,
                );

                setValue(
                    mechanicalPerformanceInputName,
                    mechanicalPerformance,
                );

                setValue(
                    neighboringInputName,
                    neighboring,
                );

                setValue(
                    synchronizationInputName,
                    synchronization,
                );
            }
        },
        [trackId, initialRender], //eslint-disable-line
    );

    useEffect(
        () => {
            if (values) setInitialRender(true);
            else setInitialRender(false);
        },
        [values], // eslint-disable-line
    );

    useEffect(
        () => {
            setTrackId(watch(trackInputName));
        },
        [watch(trackInputName)], // eslint-disable-line
    );

    return (
        <FormContainer
            action={action}
            formMethods={formMethods}
            loading={loading}
            data={renderFormControllerHandler(
                control,
                releaseRoyaltyFormData,
                renderFormControllerChildrenHandler,
            )}
            disabled={!values && (
                !watch(masterInputName)
                && !watch(neighboringInputName)
                && !watch(mechanicalPerformanceInputName)
                && !watch(synchronizationInputName)
            )}
            hasSubmitButton={canDo(
                user,
                allowedPermissions,
            )}
            onSubmitForm={onSubmitForm}
        />
    );
};

export default ReleaseRoyaltyForm;

ReleaseRoyaltyForm.propTypes = {
    action: PropTypes.string,
    loading: PropTypes.bool,
    onSubmitForm: PropTypes.func,
    setRoyalty: PropTypes.func,
    setTrackId: PropTypes.func,
    trackId: PropTypes.number,
    trackLoading: PropTypes.bool,
    values: PropTypes.object,
};
