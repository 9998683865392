import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import {
    Box,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { identifiersData, namesData } from "data";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { createSearchParams, Link, useSearchParams } from "react-router-dom";
import { theme } from "theme";

const BreadCrumbs = ({
    link,
    title,
}) => {
    const { t } = useTranslation();

    const [params] = useSearchParams();

    const searchValueParam = params.get("q");

    const driveValueParam = params.get("drive");

    return (
        <Box
            alignItems="center"
            display="flex"
            justifyContent="start"
            paddingBlock={1}
            width="100%"
        >
            <Link
                to={{
                    pathname: link,
                    ...driveValueParam && searchValueParam && {
                        search: createSearchParams({
                            drive: driveValueParam,
                            q: searchValueParam,
                        }).toString(),
                    },
                }}
            >
                <Tooltip title={t("actions.back")}>
                    <IconButton id={`${namesData.buttons.back}_${identifiersData.ids.button}`}>
                        <ArrowBackOutlinedIcon
                            sx={{
                                color: theme.palette.grey[700],
                                fontSize: 25,
                            }}
                        />
                    </IconButton>
                </Tooltip>
            </Link>
            <Typography
                component="h1"
                variant="h6"
            >
                {title}
            </Typography>
        </Box>
    );
};
export default BreadCrumbs;

BreadCrumbs.propTypes = {
    link: PropTypes.string,
    title: PropTypes.string,
};
