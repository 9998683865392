import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button,
    Fade,
    Modal as MuiModal,
    Typography,
} from "@mui/material";
import { identifiersData, namesData } from "data";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { StyledModal } from "styles";

const Modal = ({
    modalData,
    setModalData,
}) => {
    const { t } = useTranslation();

    const {
        button: buttonId,
        modal: modalId,
    } = identifiersData.ids;

    const {
        cancel: cancelButtonName,
        submit: submitButtonName,
    } = namesData.buttons;

    return (
        <Box>
            <MuiModal
                open={modalData?.open}
                closeAfterTransition
                onClose={() => setModalData({
                    ...modalData,
                    open: false,
                })}
            >
                <Fade in={!!modalData?.open}>
                    <StyledModal>
                        <Typography
                            component="h2"
                            variant="h6"
                        >
                            {modalData?.title}
                        </Typography>
                        {modalData?.hasComponent ? <Box>{modalData?.body}</Box> : (
                            <Typography
                                mt={2}
                                variant="body2"
                            >
                                {modalData?.body}
                            </Typography>
                        )}
                        {!modalData?.hasComponent && (
                            <Box
                                alignItems="center"
                                display="flex"
                                gap={2}
                                justifyContent="end"
                                mt={1}
                            >
                                <Button
                                    id={`${modalId}_${cancelButtonName}_${buttonId}`}
                                    variant="outlined"
                                    onClick={() => setModalData({
                                        ...modalData,
                                        open: false,
                                    })}
                                >
                                    {t("actions.cancel")}
                                </Button>
                                <LoadingButton
                                    color={modalData?.actionColor ? modalData?.actionColor : "error"}
                                    id={`${modalId}_${submitButtonName}_${buttonId}`}
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    onClick={modalData?.action ? () => {
                                        modalData?.action(modalData?.id);

                                        setModalData({
                                            ...modalData,
                                            open: false,
                                        });
                                    } : () => {}}
                                >
                                    {t(`actions.${modalData?.actionType}`)}
                                </LoadingButton>
                            </Box>
                        )}
                    </StyledModal>
                </Fade>
            </MuiModal>
        </Box>
    );
};

export default Modal;

Modal.propTypes = {
    modalData: PropTypes.object,
    setModalData: PropTypes.func,
};
