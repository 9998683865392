import { useAuth0 } from "@auth0/auth0-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid } from "@mui/material";
import {
    AutoCompleteMenu,
    FileLink,
    SkeletonLoader,
    TimeInput,
} from "atoms";
import { FormContainer } from "components";
import { inputData, permissionsData, releaseVideoCutFormData } from "data";
import { useDeleteVideoCut } from "hooks";
import { PropTypes } from "prop-types";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useVideoCutSchema } from "schemas";
import { canDo, convertTimeToSecondsFormatHandler, renderFormControllerHandler } from "utils";

const ReleaseVideoCutForm = ({
    action,
    loading,
    onSubmitForm,
    selectedVideo,
    setMarkerValues,
    setSelectedVideo,
    values,
    videoCutsLoading,
}) => {
    const videosOptions = useSelector((state) => state.releasesReducer)?.currentRelease?.videos?.map(({
        downloadUri,
        id,
        name,
    }) => ({
        label: name,
        src: downloadUri,
        value: id,
    }));

    const { user } = useAuth0();

    const schema = useVideoCutSchema();

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const { deleteVideoCut } = useDeleteVideoCut(selectedVideo?.value);

    const {
        control,
        formState: { errors },
    } = formMethods;

    const {
        mediaCreate: mediaCreatePermission,
        mediaDelete: mediaDeletePermission,
        mediaRead: mediaReadPermission,
    } = permissionsData.permissions.keys;

    const allowedPermissions = [mediaCreatePermission, mediaReadPermission];

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        type,
        isOptional,
    ) => {
        if (type === inputData.types.time) {
            return (
                <Box>
                    {selectedVideo && (
                        <TimeInput
                            errors={errors}
                            field={field}
                            label={label}
                            name={name}
                            value={field.value}
                            onChange={(_, value) => {
                                setMarkerValues((prev) => ({
                                    ...prev,
                                    [name]: convertTimeToSecondsFormatHandler(value),
                                }));

                                field.onChange(value);
                            }}
                        />
                    )}
                </Box>
            );
        }

        return (
            <AutoCompleteMenu
                errors={errors}
                getRenderedOption={(option) => option?.label}
                isOptional={isOptional}
                label={label}
                name={name}
                optionLabel={(option) => option?.label || ""}
                options={videosOptions}
                onChange={(_, option) => {
                    if (option) {
                        setSelectedVideo(option);
                        field.onChange(option.value);
                    } else {
                        field.onChange("");
                        setSelectedVideo(undefined);
                    }
                }}
            />
        );
    };

    return (
        <FormContainer
            action={action}
            formMethods={formMethods}
            loading={loading}
            data={renderFormControllerHandler(
                control,
                releaseVideoCutFormData,
                renderFormControllerChildrenHandler,
            )}
            hasSubmitButton={canDo(
                user,
                allowedPermissions,
            )}
            otherData={(
                <Grid
                    spacing={2}
                    container
                >
                    <Grid
                        xs={12}
                        item
                    >
                        {videoCutsLoading ? <SkeletonLoader /> : values?.videoCuts?.length > 0 && values?.videoCuts?.map(({
                            downloadUri,
                            file,
                            id,
                        }, index) => (
                            <FileLink
                                deleteAction={() => deleteVideoCut(id)}
                                key={id}
                                link={downloadUri}
                                name={file?.file_uri}
                                rowId={index + 1}
                                hasDelete={canDo(
                                    user,
                                    [mediaDeletePermission],
                                )}
                                hasDownload
                            />
                        ))}
                    </Grid>
                </Grid>
            )}
            onSubmitForm={onSubmitForm}
        />
    );
};

export default ReleaseVideoCutForm;

ReleaseVideoCutForm.propTypes = {
    action: PropTypes.string,
    loading: PropTypes.bool,
    onSubmitForm: PropTypes.func,
    selectedVideo: PropTypes.object,
    setMarkerValues: PropTypes.func,
    setSelectedVideo: PropTypes.func,
    values: PropTypes.object,
    videoCutsLoading: PropTypes.bool,
};
