import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import { FileLink } from "atoms";
import { PageHead } from "components";
import { permissionsData } from "data";
import { useDeleteReleaseLyricsFile } from "hooks";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { canDo } from "utils";

const TrackDetailsLyricsFiles = ({ data }) => {
    const { t } = useTranslation();

    const { user } = useAuth0();

    const { id: trackId } = useParams();

    const { deleteReleaseLyricsFile } = useDeleteReleaseLyricsFile(parseInt(trackId));

    const { mediaDelete: mediaDeletePermission } = permissionsData.permissions.keys;

    return (
        <Box
            display="flex"
            flexDirection="column"
        >
            <PageHead
                title={t("labels.lyricsFiles")}
                titleTag="h2"
            />
            {data?.map(({
                downloadUri,
                id,
                name,
            }, index) => (
                <FileLink
                    deleteAction={() => deleteReleaseLyricsFile(id)}
                    key={id}
                    link={downloadUri}
                    name={name}
                    rowId={index + 1}
                    hasDelete={canDo(
                        user,
                        [mediaDeletePermission],
                    )}
                    hasDownload
                />
            ))}
        </Box>
    );
};

export default TrackDetailsLyricsFiles;

TrackDetailsLyricsFiles.propTypes = { data: PropTypes.array };
