import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData, urlsData } from "data";
import { editArtistMutation } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useEditArtist = (id) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [editArtistAction, { loading }] = useMutation(editArtistMutation);

    const editArtist = async ({
        code,
        imageFile,
        isni,
        nameAr,
        nameEn,
        nationality,
    }) => {
        try {
            await editArtistAction({
                onCompleted: () => {
                    navigate(urlsData.routes.artists.url);

                    dispatch(openAlert(
                        t(
                            "messages.editMsgs.success",
                            { entity: t("artists.entity") },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: {
                    data: {
                        code: { set: parseInt(code) },
                        ...imageFile && {
                            image: {
                                connect: { id: imageFile },
                            },
                        },
                        country: {
                            connect: { id: nationality },
                        },
                        isni: { set: isni },
                        name: { set: nameEn },
                        name_ar: { set: nameAr }, //eslint-disable-line
                    },
                    where: { id },
                },

            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        editArtist,
        loading,
    };
};

export default useEditArtist;
