import FullscreenIcon from "@mui/icons-material/Fullscreen";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import { Box, IconButton, Slider } from "@mui/material";
import { Loader, Marker } from "atoms";
import { identifiersData, namesData, statusTypesData } from "data";
import { PropTypes } from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/actions";
import { StyledVideoPlayer } from "styles";
import { convertHourToDurationFormatHandler } from "utils";

const VideoPlayer = ({
    markers,
    url,
}) => {
    const [isPlaying, setIsPlaying] = useState(false);

    const [showControls, setShowControls] = useState(false);

    const [currentTime, setCurrentTime] = useState(0);

    const [duration, setDuration] = useState(0);

    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    const videoRef = useRef(null);

    const {
        classNames: { controls: controlsClassName },
        ids: {
            button: buttonId,
            slider: sliderId,
            thumb: thumbId,
            track: trackId,
        },
    } = identifiersData;

    const {
        buttons: {
            pauseVideoPlayer: pauseVideoPlayerButtonName,
            playVideoPlayer: playVideoPlayerButtonName,
        },
        sliders: { videoPlayer: videoPlayerSliderName },
    } = namesData;

    const playAndPauseHandler = () => {
        const video = videoRef.current;

        if (isPlaying) video.pause();
        else video.play();

        setIsPlaying((prev) => !prev);
    };

    const changeTimeHandler = (time) => {
        const video = videoRef.current;

        video.currentTime = time;

        setCurrentTime(time);
    };

    const toggleFullScreenHandler = () => {
        const video = videoRef.current;

        if (!document.fullscreenElement) { //eslint-disable-line
            video.requestFullscreen()["catch"]((err) => {
                dispatch(openAlert(
                    err,
                    statusTypesData.error,
                ));
            });
        } else document.exitFullscreen();//eslint-disable-line
    };

    useEffect(
        () => {
            if (url) {
                setLoading(true);

                videoRef.current?.load();

                const loadedMetadataHandler = () => setDuration(videoRef?.current?.duration);

                const updateTimeHandler = () => setCurrentTime(videoRef?.current?.currentTime);

                videoRef?.current?.addEventListener(
                    "loadedmetadata",
                    loadedMetadataHandler,
                );

                videoRef?.current?.addEventListener(
                    "timeupdate",
                    updateTimeHandler,
                );

                return () => {
                    videoRef?.current?.removeEventListener(
                        "loadedmetadata",
                        loadedMetadataHandler,
                    );

                    videoRef?.current?.removeEventListener( //eslint-disable-line
                        "timeupdate",
                        updateTimeHandler,
                    );
                };
            }
        },
        [url],
    );

    return (
        <StyledVideoPlayer showControls={+!!showControls}>
            {url && (
                <video
                    crossOrigin="anonymous"
                    ref={videoRef}
                    width="100%"
                    onCanPlay={() => setLoading(false)}
                    onClick={playAndPauseHandler}
                    onEnded={() => setIsPlaying(false)}
                    onTimeUpdate={() => setCurrentTime(videoRef.current.currentTime)}
                >
                    <source
                        src={url}
                        type="video/mp4"
                    />
                </video>
            )}
            {loading ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    width="100%"
                >
                    <Loader withoutFullHeight />
                </Box>
            ) : (
                <Box
                    className={controlsClassName}
                    onMouseEnter={() => setShowControls(true)}
                    onMouseLeave={() => setShowControls(false)}
                >
                    {isPlaying ? (
                        <IconButton id={`${pauseVideoPlayerButtonName}_${buttonId}`}>
                            <PauseCircleIcon onClick={playAndPauseHandler} />
                        </IconButton>
                    ) : (
                        <IconButton id={`${playVideoPlayerButtonName}_${buttonId}`}>
                            <PlayCircleFilledIcon onClick={playAndPauseHandler} />
                        </IconButton>
                    )}
                    <Box
                        fontSize={{
                            md: 18,
                            xs: 15,
                        }}
                    >
                        <time>
                            {convertHourToDurationFormatHandler(currentTime)}
                            /
                            {convertHourToDurationFormatHandler(duration)}
                        </time>
                    </Box>
                    <Box
                        margin="5px 7px 0"
                        position="relative"
                    >
                        <Slider
                            max={videoRef.current && duration}
                            value={currentTime}
                            slotProps={{
                                thumb: { id: `${videoPlayerSliderName}_${sliderId}_${thumbId}` },
                                track: { id: `${videoPlayerSliderName}_${sliderId}_${trackId}` },

                            }}
                            sx={{
                                width: {
                                    lg: 200,
                                    md: 100,
                                    xl: 300,
                                    xs: 200,
                                },
                            }}
                            onChange={(e) => changeTimeHandler(e.target.value)}
                        />
                        {markers?.length > 0 && markers?.map((marker) => (
                            <Marker
                                duration={duration}
                                key={marker.id}
                                marker={marker}
                                onClick={() => changeTimeHandler(marker.time)}
                            />
                        ))}
                    </Box>
                    <FullscreenIcon onClick={toggleFullScreenHandler} />
                </Box>
            )}
        </StyledVideoPlayer>
    );
};

export default VideoPlayer;

VideoPlayer.propTypes = {
    markers: PropTypes.array,
    url: PropTypes.string,
};
