import {
    Box,
    Checkbox,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import { Loader, Toolbar } from "atoms";
import { NoData } from "components";
import { identifiersData, inputData } from "data";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { StyledTableCell } from "styles";
import { theme } from "theme";

const TableList = ({
    children,
    count,
    downloadContentAction,
    exportAction,
    hasDistribute,
    hasDownloadContent,
    hasExport,
    hasLimitedHeight,
    hasSearchMode,
    hasTab,
    headCells = [],
    isPaginated = true,
    isSelectable,
    limit,
    limitedHeight = 450,
    loading,
    page,
    selectAllAction,
    selected,
    setLimit,
    setPage,
    tabValue,
    tableData,
    type,
    withoutSelectAll,
}) => {
    const searchValues = useSelector((state) => state.searchReducer.searchValues);

    const searchCategory = useSelector((state) => state.searchReducer.searchCategory);

    const { t } = useTranslation();

    const navigate = useNavigate();

    const location = useLocation();

    const params = {
        ...hasSearchMode && { q: searchValues.filter((value) => value !== "").join(",") },
        ...hasSearchMode && { category: searchCategory || 1 },
        ...hasTab && { tab: tabValue },
        limit,
        page: page + 1,
    };

    const navigationOptions = {
        pathname: window.location.pathname, //eslint-disable-line
        search: createSearchParams(params).toString(),
    };

    const {
        input: inputId,
        table: tableId,
    } = identifiersData.ids;

    useEffect(
        () => {
            if (isPaginated) {
                navigate(
                    navigationOptions,
                    {
                        replace: true,
                        state: { from: location.state?.from },
                    },
                );
            }
        },
        [page, limit,location.search], // eslint-disable-line
    );

    const changePageHandler = (_, currentPage) => setPage(currentPage + 1);

    const changeRowsPerPageHandler = (event) => {
        setLimit(parseInt(event.target.value));
    };

    const data = !children || children?.length === 0 ? (
        <TableRow>
            <StyledTableCell
                colSpan={100}
                rowHeight={250}
            >
                <NoData type={type} />
            </StyledTableCell>
        </TableRow>
    ) : children;

    useEffect(
        () => {
            if (page > 0 && children.length === 0) setPage(1);
        },
        [children],//eslint-disable-line
    );

    return (
        <TableContainer
            component={Paper}
            sx={{ overflowX: "initial" }}
        >
            {selected?.length > 0 && (
                <Toolbar
                    downloadContentAction={downloadContentAction}
                    exportAction={exportAction}
                    hasDistribute={hasDistribute}
                    hasDownloadContent={hasDownloadContent}
                    hasExport={hasExport}
                    limit={limit}
                    page={page}
                    selected={selected}
                />
            )}
            <Box
                height={hasLimitedHeight && limitedHeight}
                overflow="auto"
            >
                <Table
                    size="small"
                    sx={{ minWidth: 700 }}
                    stickyHeader
                >
                    <TableHead
                        sx={{
                            "& .MuiTableCell-head": {
                                backgroundColor: `${theme.palette.grey[200]} !important`,
                                color: `${theme.palette.grey[600]} !important`,
                                fontWeight: "700",
                            },
                        }}
                    >
                        <TableRow>
                            {headCells.map(({
                                alignment,
                                id,
                                key,
                            }) => (!isSelectable && id === 0 ? "" : (
                                <StyledTableCell
                                    align={alignment || "left"}
                                    key={id}
                                    padding={isSelectable && id === 0 ? "checkbox" : "normal"}
                                >
                                    {isSelectable && id === 0 && !withoutSelectAll ? (
                                        <Checkbox
                                            checked={children?.length > 0 && selected?.length === children?.length}
                                            color="primary"
                                            id={`${tableId}_${inputData.names.selectAll}_${inputId}`}
                                            indeterminate={selected?.length > 0 && selected.length < children?.length}
                                            onChange={(e) => selectAllAction(
                                                e,
                                                tableData,
                                            )}
                                        />
                                    ) : key && t(`tableHeadCells.${key}`)}
                                </StyledTableCell>
                            )))}
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ cursor: isSelectable && "pointer" }}>{data}</TableBody>
                </Table>
            </Box>
            {isPaginated && (
                <TablePagination
                    component="div"
                    count={count}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[25, 50, 100]}
                    onPageChange={changePageHandler}
                    onRowsPerPageChange={changeRowsPerPageHandler}
                />
            )}
            {loading && (
                <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="center"
                    paddingBlock={2}
                    sx={{
                        "&:hover": { textDecoration: "underline" },
                        cursor: "pointer",
                    }}
                >
                    <Loader
                        size={25}
                        withoutFullHeight
                    />
                </Box>
            )}
        </TableContainer>
    );
};

export default TableList;

TableList.propTypes = {
    children: PropTypes.node,
    count: PropTypes.number,
    downloadContentAction: PropTypes.func,
    exportAction: PropTypes.func,
    hasDistribute: PropTypes.bool,
    hasDownloadContent: PropTypes.bool,
    hasExport: PropTypes.bool,
    hasLimitedHeight: PropTypes.bool,
    hasSearchMode: PropTypes.bool,
    hasTab: PropTypes.bool,
    headCells: PropTypes.array,
    isPaginated: PropTypes.bool,
    isSelectable: PropTypes.bool,
    limit: PropTypes.number,
    limitedHeight: PropTypes.number,
    loading: PropTypes.bool,
    page: PropTypes.number,
    selectAllAction: PropTypes.func,
    selected: PropTypes.array,
    setLimit: PropTypes.func,
    setPage: PropTypes.func,
    tabValue: PropTypes.string,
    tableData: PropTypes.array,
    type: PropTypes.string,
    withoutSelectAll: PropTypes.bool,

};
