import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import {
    Box,
    IconButton,
    LinearProgress,
    Tooltip,
    Typography,
} from "@mui/material";
import { identifiersData, namesData, statusTypesData } from "data";
import { PropTypes } from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { theme } from "theme";
import { getFileSizeHandler, getSubstringHandler } from "utils";

const UploadBox = ({
    file,
    onCancelUpload,
    progress,
    status,
}) => {
    const [cancelUpload, setCancelUpload] = useState(false);

    const { t } = useTranslation();

    const cancelUploadHandler = () => {
        onCancelUpload();

        setCancelUpload(true);
    };

    const statusIcon = {
        error: <CancelIcon color="error" />,
        inProgress: (
            <Box className={identifiersData.classNames.upload}>
                <CloudUploadIcon color={theme.palette.grey[500]} />
            </Box>
        ),
        success: <CheckCircleIcon color="primary" />,
    };

    return (
        <Box>
            <Box
                display="flex"
                justifyContent="space-between"
            >
                <Typography variant="caption">
                    {getSubstringHandler(
                        file.name,
                        10,
                        false,
                        -4,
                    )}
                </Typography>
                <Box
                    alignItems="center"
                    display="flex"
                >
                    {status === statusTypesData.inProgress && !cancelUpload && (
                        <Tooltip title={t("actions.cancel")}>
                            <IconButton
                                id={`${namesData.buttons.cancel}_${identifiersData.ids.button}`}
                                sx={{
                                    bottom: 5,
                                    position: "relative",
                                }}
                                onClick={cancelUploadHandler}
                            >
                                <NotInterestedIcon color="error" />
                            </IconButton>
                        </Tooltip>
                    )}
                    {cancelUpload && (
                        <Typography
                            color="error"
                            variant="caption"
                        >
                            {t("labels.cancelled")}
                        </Typography>
                    )}
                    {!cancelUpload && statusIcon[status]}
                </Box>
            </Box>
            <Box mt={1}>
                <LinearProgress
                    color={cancelUpload || status === statusTypesData.error ? "error" : "primary"}
                    value={progress}
                    variant="determinate"
                    sx={{
                        "&.MuiLinearProgress-root": { height: "7px" },
                    }}
                />
            </Box>
            <Box
                display="flex"
                justifyContent="space-between"
                mt={1}
            >
                <Typography variant="caption">{getFileSizeHandler(file.size)}</Typography>
                <Typography variant="caption">
                    {progress}
                    %
                </Typography>
            </Box>
        </Box>
    );
};

export default UploadBox;

UploadBox.propTypes = {
    file: PropTypes.object,
    onCancelUpload: PropTypes.func,
    progress: PropTypes.number,
    status: PropTypes.string,
};
