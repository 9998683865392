import { Box } from "@mui/material";
import { PageHead, ReleaseInfoForm } from "components";
import { actionsData } from "data";
import { useEditRelease } from "hooks";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ReleaseInfoTab = () => {
    const release = useSelector((state) => state?.releasesReducer).currentRelease;

    const { t } = useTranslation();

    const {
        editRelease,
        loading,
    } = useEditRelease(release?.id);

    const {
        album_code: albumCode,
        album_name: nameEn,
        album_name_ar: nameAr,
        artist,
        disc_number: discNumber,
        end_date: endDate,
        genre,
        image,
        isCompleted,
        release_date: releaseDate,
        releaseType,
        rightType,
    } = release || {};

    const formValues = {
        albumCode,
        discNumber,
        endDate,
        genre: genre?.id,
        imageFile: image?.id,
        isCompleted,
        nameAr,
        nameEn,
        oldImage: image,
        primaryArtist: artist?.id,
        releaseDate,
        releaseType: releaseType?.id,
        rightType: rightType?.id,
    };

    return (
        <Box>
            <PageHead title={t("tabs.info")} />
            <ReleaseInfoForm
                action={actionsData.edit}
                loading={loading}
                values={formValues}
                onSubmitForm={editRelease}
            />
        </Box>
    );
};

export default ReleaseInfoTab;
