import { gql } from "@apollo/client";

export const createGroupMutation = gql`
    mutation createGroup($data: DriveGroupCreateInput!) {
        createOneDriveGroup(data: $data) {
            id
        }
    }
`;

export const editGroupMutation = gql`
    mutation editGroup(
        $data: DriveGroupUpdateInput!,
        $where: DriveGroupWhereUniqueInput!
    ) {
        updateOneDriveGroup(
            data: $data,
            where: $where
        ) {
            id
        }
    }
`;

export const deleteGroupMutation = gql`
    mutation deleteGroup($id: Int!) {
        deleteOneDriveGroup(id: $id) {
            id
        }
    }
`;

export const createMemberMutation = gql`
    mutation createMember($data: DriveMemberCreateInput!) {
        createOneDriveMember(data: $data) {
            id
        }
    }
`;

export const createGroupMemberMutation = gql`
    mutation createGroupMember(
        $groupId: Int!,
        $memberEmail: String!
    ) {
        createOneDriveGroupMember(
            groupId: $groupId,
            memberEmail: $memberEmail
        ) {
            id
        }
    }
`;

export const editMemberMutation = gql`
    mutation editMember(
        $data: DriveMemberUpdateInput!,
        $where: DriveMembersWhereUniqueInput!
    ) {
        updateOneDriveMember(
            data: $data,
            where: $where
        ) {
            id
        }
    }
`;

export const deleteMemberMutation = gql`
    mutation deleteMember($id: Int!) {
        deleteOneDriveMember(id: $id) {
            id
        }
    }
`;

export const removeGroupMemberMutation = gql`
    mutation removeGroupMember($id: Int!) {
        deleteOneDriveGroupMember(id: $id) {
            id
            driveMember {
                memberGroups {
                    driveGroup {
                        group_email
                        group_name
                        id
                        groupMembers {
                            id
                            driveMember {
                                id
                            }
                        }
                    }
                }
            }
        }
    }
`;
