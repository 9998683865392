import { gql } from "@apollo/client";

export const getReleasesListQuery = gql`
    query getReleasesList(
        $page: Int = 0,
        $limit: Int = 100000,
        $orderBy: ReleaseOrderByInput!,
        $where: ReleaseWhereInput!
    ) {
        listReleases(
            page: $page,
            elementsPerPage: $limit,
            orderBy: $orderBy,
            where: $where
        ) {
            posts {
                id
                duration
                album_name
                upc
                release_date
                releaseTracks {
                    track {
                        id
                    }
                }
                releaseType {
                    name
                }
                artist {
                    id
                    name
                }
                image {
                    downloadThumbnail
                }
                isCompleted
            }
            meta {
                resultCount
                nodeCount
                currentPage
                totalPages
            }
        }
    }
`;

export const getReleaseQuery = gql`
    query getRelease($where: ReleaseWhereUniqueInput!) {
        release(where: $where) {
            id
            artist {
                id
                name
            }
            documents {
                id
                name
                downloadUri
            }
            releaseDriveLinks{
                posts {
                    id
                    link_name
                    url
                }
            }
            releaseTracks {
                id
                track {
                    id
                    title
                }
            }
            videos {
                id
                name
                downloadUri
                type {
                    id
                }
                track {
                    id
                    title
                    image {
                        downloadThumbnail
                    }
                    artist {
                        id
                        name
                    }
                }
                release {
                    id
                    image {
                        downloadThumbnail
                    }
                }
                image {
                    downloadThumbnail
                }
            }
            album_code
            album_name
            album_name_ar
            disc_number
            end_date
            genre {
                id
                name
            }
            release_date
            releaseType {
                id
                name
            }
            rightType {
                id
                name
            }
            isCompleted
            ownership {
                id
                c_line
                p_line
                label
                territory {
                    id
                    name
                }
            }
            notes {
                id
                content
            }
            image {
                id
                downloadUri
                downloadThumbnail
            }
        }
    }
`;

export const getReleaseClientLinksListQuery = gql`
    query getReleaseClientLinksList(
        $releaseId: Int!,
        $page: Int = 0,
        $limit: Int = 10
    ) {
        listReleaseLinks(
            releaseId: $releaseId,
            page:$page,
            elementsPerPage: $limit
        ) {
            posts {
                id
                client {
                    name
                }
                releaseTrack {
                    track {
                        id
                        title
                    }
                }
                releaseLink
            }
            meta {
                resultCount
                nodeCount
                currentPage
                totalPages
            }
        }
    }
`;

export const getReleaseClientLinkQuery = gql`
    query getReleaseClientLink($where: ReleaseTrackClientWhereUniqueInput!) {
        releaseTrackClient(where: $where) {
            id
            releaseLink
            client {
                id
            }
            releaseTrack {
                id
            }
        }
    }
`;

export const getReleaseShareLinkQuery = gql`
    query getReleaseShareLink($id: Int!) {
        shareLinkRelease(release_id: $id)
    }
`;
