import fileData from "data/file";
import inputData from "data/input";
import selectionsData from "data/selections";

const {
    documentTypes: documentTypesSelectionKey,
    tracks: tracksSelectionKey,
} = selectionsData.keys;

const { document: documentFileName } = fileData.names;

const {
    autoComplete: autoCompleteInputType,
    file: fileInputType,
    multiAutoComplete: multiAutoCompleteInputType,
} = inputData.types;

const {
    tracks: tracksInputName,
    type: typeInputName,
} = inputData.names;

const {
    document: documentFileInputLabel,
    tracks: tracksInputLabel,
    type: typeInputLabel,
} = inputData.labels;

const releaseDocumentFormData = [
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: tracksInputLabel,
        name: tracksInputName,
        selectionKey: tracksSelectionKey,
        type: multiAutoCompleteInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        hasOffset: true,
        label: typeInputLabel,
        name: typeInputName,
        selectionKey: documentTypesSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: documentFileInputLabel,
        name: documentFileName,
        type: fileInputType,
    },
];

export default releaseDocumentFormData;
