import {
    Box,
    TableRow,
} from "@mui/material";
import { TableList } from "atoms";
import { tableHeadCellsData } from "data";
import { useSelector } from "react-redux";
import { StyledTableCell } from "styles";

const ImporterCompletedReleasesTab = () => {
    const { importer } = useSelector((state) => state.releasesReducer);

    return (
        <Box>
            <TableList
                headCells={tableHeadCellsData.releases.importer.completedReleasesTab}
                isPaginated={false}
                hasLimitedHeight
            >
                {importer?.completedReleases?.map((release) => (
                    <TableRow
                        key={release.id}
                        aria-checked
                        hover
                    >
                        <StyledTableCell>{release.albumNameEn}</StyledTableCell>
                        <StyledTableCell>{release.artist.artistNameEn}</StyledTableCell>
                        <StyledTableCell>{release.releaseType}</StyledTableCell>
                        <StyledTableCell>{release.releaseDate}</StyledTableCell>
                        <StyledTableCell>{release.upc}</StyledTableCell>
                        <StyledTableCell>{release.genre}</StyledTableCell>
                    </TableRow>
                ))}
            </TableList>
        </Box>
    );
};

export default ImporterCompletedReleasesTab;
