import { Box } from "@mui/material";
import { DialogVideoPlayer, ThumbnailVideoPlayer } from "atoms";
import { PageHead } from "components";
import { PropTypes } from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ReleaseDetailsVideos = ({ data }) => {
    const [currentVideo, setCurrentVideo] = useState({
        artistName: "",
        trackName: "",
        url: null,
    });

    const { t } = useTranslation();

    return (
        <Box>
            <DialogVideoPlayer
                currentVideo={currentVideo}
                setCurrentVideo={setCurrentVideo}
            />
            <PageHead
                title={t("videos.name")}
                titleTag="h2"
            />
            {data?.length > 0 && (
                <Box
                    display="flex"
                    flexDirection="column"
                >
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        gap={2}
                    >
                        {data?.map(({
                            downloadUri,
                            id,
                            image,
                            release,
                            track,
                            type,
                        }, index) => (
                            <Box key={id}>
                                <ThumbnailVideoPlayer
                                    id={id}
                                    imgSrc={image?.downloadThumbnail || track?.image?.downloadThumbnail || release?.image?.downloadThumbnail}
                                    rowId={index + 1}
                                    setCurrentVideo={setCurrentVideo}
                                    type={type?.name}
                                    url={downloadUri}
                                    artist={{
                                        id: track?.artist?.id,
                                        name: track?.artist?.name,
                                    }}
                                    track={{
                                        id: track?.id,
                                        name: track?.title,
                                    }}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default ReleaseDetailsVideos;

ReleaseDetailsVideos.propTypes = { data: PropTypes.array };
