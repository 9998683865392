import { useAuth0 } from "@auth0/auth0-react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import {
    AddExistingTrackTab,
    AddNewTrackTab,
    PageHead,
    TracksList,
} from "components";
import {
    identifiersData,
    namesData,
    permissionsData,
    tabsData,
} from "data";
import { PropTypes } from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { canDo } from "utils";

const ReleaseTracksTab = ({ data }) => {
    const [tabValue, setValue] = useState("1");

    const { user } = useAuth0();

    const { id } = useParams();

    const { t } = useTranslation();

    const tabsComponents = {
        existingTrack: <AddExistingTrackTab />,
        newTrack: <AddNewTrackTab />,
    };

    const {
        mediaCreate: mediaCreatePermission,
        mediaRead: mediaReadPermission,
    } = permissionsData.permissions.keys;

    const allowedPermissions = [mediaCreatePermission, mediaReadPermission];

    return (
        <Box>
            <TracksList
                data={data}
                releaseId={id}
                tabValue="2"
                hasTab
            />
            {canDo(
                user,
                allowedPermissions,
            ) && (
                <Box mt={3}>
                    <PageHead
                        title={t("tracks.createTrack")}
                        titleTag="h2"
                    />
                    <TabContext value={tabValue}>
                        <Box
                            borderBottom={1}
                            borderColor="divider"
                        >
                            <TabList
                                variant="scrollable"
                                onChange={(_, newValue) => setValue(newValue)}
                            >
                                {tabsData.data.releaseTracks.map(({
                                    label,
                                    value,
                                }) => (
                                    <Tab
                                        itemID={`${namesData.tabs.releaseTracks}_${identifiersData.ids.tab}_${value}`}
                                        key={value}
                                        label={t(`tabs.${label}`)}
                                        sx={{ textTransform: "none" }}
                                        value={value}
                                    />
                                ))}
                            </TabList>
                        </Box>
                        {tabsData.data.releaseTracks.map(({
                            label,
                            value,
                        }) => tabValue && (
                            <TabPanel
                                key={value}
                                sx={{ paddingInline: 0 }}
                                value={value}
                            >
                                {tabsComponents[label]}
                            </TabPanel>
                        ))}
                    </TabContext>
                </Box>
            )}
        </Box>
    );
};

export default ReleaseTracksTab;

ReleaseTracksTab.propTypes = { data: PropTypes.object };
