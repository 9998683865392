import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { constantsData } from "data";
import { getSearchListQuery } from "graph";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useSearchList = () => {
    const [params] = useSearchParams();

    const pageParam = parseInt(params.get("page")) || 1;

    const limitParam = parseInt(params.get("limit")) || 25;

    const [page, setPage] = useState(pageParam);

    const [limit, setLimit] = useState(limitParam);

    const dispatch = useDispatch();

    const { isAuthenticated } = useAuth0();

    const { t } = useTranslation();

    const searchData = useSelector((state) => state.searchReducer.searchData);

    const {
        data,
        error,
        loading,
    } = useQuery(
        getSearchListQuery,
        {
            skip: !isAuthenticated || Object.keys(searchData).length === 0 || !searchData?.category,
            variables: {
                entityId: parseInt(searchData?.category),
                limit,
                page: parseInt(page) - 1,
                searchValues: searchData?.values,
            },
        },
    );

    useEffect(
        () => {
            if (error?.message === constantsData.failedToFetch && !loading) {
                displayErrorHandler(
                    t("messages.networkFailure"),
                    dispatch,
                    openAlert,
                );
            }
        },
        [error, data], // eslint-disable-line
    );

    return {
        count: data?.searchByModel?.meta?.nodeCount,
        data: data?.searchByModel?.posts || [],
        limit,
        loading,
        page: data?.searchByModel?.meta?.currentPage,
        setLimit,
        setPage,
    };
};

export default useSearchList;
