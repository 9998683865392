import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useReleaseOwnershipSchema = () => {
    const { t } = useTranslation();

    return yup.object({
        cLine: yup.string().required(t("messages.inputMsgs.required")),
        label: yup.string().required(t("messages.inputMsgs.required")),
        pLine: yup.string().required(t("messages.inputMsgs.required")),
        territory: yup.string().required(t("messages.inputMsgs.required")),

    });
};

export default useReleaseOwnershipSchema;
