import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useReleaseClientLinkSchema = () => {
    const { t } = useTranslation();

    return yup.object({
        client: yup.string().required(t("messages.inputMsgs.required")),
        link: yup.string().required(t("messages.inputMsgs.required")),
        track: yup.string().required(t("messages.inputMsgs.required")),
    });
};

export default useReleaseClientLinkSchema;
