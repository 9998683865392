import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import {
    AutoCompleteMenu,
    FileLink,
    Loader,
    UploadInput,
} from "atoms";
import { FormContainer } from "components";
import {
    artistFormData,
    fileData,
    identifiersData,
    inputData,
} from "data";
import { useCountriesSelectionList, useUploadFile } from "hooks";
import { PropTypes } from "prop-types";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useArtistSchema } from "schemas";
import { StyledTextField } from "styles";
import { deleteFileHandler, renderFormControllerHandler, setUploadInputPreviousValueHandler } from "utils";

const ArtistForm = ({
    action,
    loading,
    onSubmitForm,
    values,
}) => {
    const { t } = useTranslation();

    const selections = useSelector((state) => state.selectionsReducer);

    const schema = useArtistSchema();

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const {
        filesData,
        filesLoading,
        setFilesData,
        uploadFile,
    } = useUploadFile();

    const {
        control,
        formState: { errors },
        setError,
        setValue,
        watch,
    } = formMethods;

    const {
        file: fileInputType,
        text: textInputType,
    } = inputData.types;

    const { loading: countriesSelectionListLoading } = useCountriesSelectionList();

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        type,
        isOptional,
        selectionKey,
    ) => {
        switch (type) {
        case textInputType:
            return (
                <StyledTextField
                    {...field}
                    error={errors[name]}
                    helperText={errors[name] && errors[name]?.message}
                    id={`${name}_${identifiersData.ids.input}`}
                    label={isOptional ? `${t(`labels.${label}`)} (${t("labels.optional")})` : t(`labels.${label}`)}
                    variant="outlined"
                    fullWidth
                />
            );
        case fileInputType:
            return (
                <Box mb={1}>
                    <UploadInput
                        accept={fileData.acceptedExtensions.image}
                        color={errors[name]?.message ? "error" : "primary"}
                        error={errors[name]?.message}
                        field={field}
                        hasError={!!errors[name]?.message}
                        label={t(`labels.${label}`)}
                        loading={filesLoading?.[name]}
                        onChange={(e) => {
                            uploadFile(
                                e.target.files[0],
                                formMethods,
                                name,
                            );
                        }}
                    />
                    {filesData?.[name] && (
                        <FileLink
                            name={filesData?.[name]?.name}
                            deleteAction={() => deleteFileHandler(
                                setFilesData,
                                formMethods,
                                name,
                            )}
                            hasDelete
                        />
                    )}
                    {(values?.oldImage && !filesData?.[name]) && (
                        <FileLink
                            link={values?.oldImage?.downloadThumbnail}
                            name={values?.oldImage?.downloadThumbnail}
                            hasDownload
                            hasImage
                            hasJustifyStart
                        />
                    )}
                </Box>
            );
        default:
            return (
                <AutoCompleteMenu
                    defaultValue={selections?.[selectionKey]?.find((option) => option?.value === values?.[name])}
                    errors={errors}
                    getRenderedOption={(option) => option?.label}
                    label={label}
                    name={name}
                    optionLabel={(option) => option?.label}
                    options={selections?.[selectionKey]}
                    isCountriesMenu
                    onChange={(_, option) => field.onChange(option?.value)}
                />
            );
        }
    };

    useEffect(
        () => {
            setUploadInputPreviousValueHandler(
                watch,
                setError,
                setValue,
                filesData,
                fileData.names.image,
            );
        },
        [watch(fileData.names.image)], // eslint-disable-line
    );

    if (countriesSelectionListLoading) return <Loader />;

    return (
        <FormContainer
            action={action}
            disabled={filesLoading?.[fileData.names.image]}
            formMethods={formMethods}
            loading={loading}
            data={renderFormControllerHandler(
                control,
                artistFormData,
                renderFormControllerChildrenHandler,
            )}
            onSubmitForm={onSubmitForm}
        />
    );
};

export default ArtistForm;

ArtistForm.propTypes = {
    action: PropTypes.string,
    loading: PropTypes.bool,
    onSubmitForm: PropTypes.func,
    values: PropTypes.object,

};
