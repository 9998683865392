import styled from "@emotion/styled";
import { Stepper } from "@mui/material";

const StyledStepper = styled(Stepper)(() => ({
    "@media(max-width: 700px) ": {
        "& .MuiStepConnector-horizontal": { display: "none" },
    },
    display: "flex",
    flexWrap: "wrap",
    gap: 3,
    spacing: 3,
}));

export default StyledStepper;
