import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { constantsData } from "data";
import { getGroupMembersListQuery, getMembersListQuery } from "graph";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useMembersList = (
    groupId,
    searchValue,
    skip,
) => {
    const [params] = useSearchParams();

    const pageParam = parseInt(params.get("page")) || 1;

    const limitParam = parseInt(params.get("limit")) || 25;

    const [page, setPage] = useState(pageParam);

    const [limit, setLimit] = useState(limitParam);

    const searchData = useSelector((state) => state.searchReducer.searchData);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const { isAuthenticated } = useAuth0();

    const {
        data,
        error,
        loading,
    } = useQuery(
        groupId ? getGroupMembersListQuery : getMembersListQuery,
        {
            skip: !isAuthenticated || skip,
            variables: {
                limit,
                page: page - 1,
                where: {
                    ...groupId && { driveGroupId: groupId },
                    ...(searchData?.values?.[0] || searchValue) && {
                        email: { contains: searchValue || searchData?.values[0] },
                    },
                },
            },
        },
    );

    useEffect(
        () => {
            if (error?.message === constantsData.failedToFetch && !loading) {
                displayErrorHandler(
                    t("messages.networkFailure"),
                    dispatch,
                    openAlert,
                );
            }
        },
        [error, data], // eslint-disable-line
    );

    const responseKey = groupId ? "listDriveGroupMembers" : "listDriveMembers";

    return {
        count: data?.[responseKey]?.meta?.nodeCount,
        data,
        error,
        limit,
        loading,
        page: data?.[responseKey]?.meta?.currentPage,
        setLimit,
        setPage,
    };
};

export default useMembersList;
