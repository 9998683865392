import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { constantsData, permissionsData, urlsData } from "data";
import { getClientQuery } from "graph";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openAlert, setCurrentClient } from "redux/actions";
import { canDo, displayErrorHandler } from "utils";

const useGetClient = (id) => {
    const { isAuthenticated } = useAuth0();

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { user } = useAuth0();

    const navigate = useNavigate();

    const { mediaRead: mediaReadPermission } = permissionsData.permissions.keys;

    const {
        data,
        error,
        loading,
    } = useQuery(
        getClientQuery,
        {
            onCompleted: ({ clientModel }) => {
                if (!clientModel) navigate(urlsData.routes.notFound);

                dispatch(setCurrentClient(clientModel));
            },
            skip: !isAuthenticated || !canDo(
                user,
                [mediaReadPermission],
            ),
            variables: {
                where: { id },
            },
        },
    );

    useEffect(
        () => {
            if (error?.message === constantsData.failedToFetch && !loading) {
                displayErrorHandler(
                    t("messages.networkFailure"),
                    dispatch,
                    openAlert,
                );
            }
        },
        [error, data], // eslint-disable-line
    );

    return {
        data: data?.clientModel,
        loading,
    };
};

export default useGetClient;
