import { useMutation } from "@apollo/client";
import {
    constantsData,
    identifiersData,
    namesData,
    statusTypesData,
} from "data";
import { getGroupMembersListQuery, getMembersListQuery, removeGroupMemberMutation } from "graph";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useRemoveGroupMember = (
    page,
    limit,
    groupId,
    searchData,
    fromEditMember,
    setGroups,
) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [removeGroupMemberAction, { loading }] = useMutation(
        removeGroupMemberMutation,
        {
            ...!fromEditMember && {
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: groupId ? getGroupMembersListQuery : getMembersListQuery,
                        variables: {
                            limit,
                            page,
                            where: {
                                ...groupId && { driveGroupId: groupId },
                                ...searchData?.[0] && {
                                    email: { contains: searchData?.[0] },
                                },
                            },
                        },
                    },
                ],
            },
        },
    );

    const { removeGroupMemberPending: removeGroupMemberPendingToastName } = namesData.toasts;

    const { toast: toastId } = identifiersData.ids;

    const removeGroupMember = async (id) => {
        try {
            await removeGroupMemberAction({
                onCompleted: ({ deleteOneDriveGroupMember }) => {
                    if (setGroups) setGroups(deleteOneDriveGroupMember.driveMember.memberGroups);

                    dispatch(openAlert(
                        t(
                            "messages.removeMsgs.success",
                            { entity: `${t("groups.entity")} ${t("groups.members.entity")}` },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: { id },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        } finally {
            toast.dismiss(`${removeGroupMemberPendingToastName}_${toastId}`);
        }
    };

    useEffect(
        () => {
            if (loading) {
                toast.info(
                    () => t(
                        "messages.removeMsgs.pending",
                        { entity: `${t("groups.entity")} ${t("groups.members.entity")}` },
                    ),
                    { toastId: `${removeGroupMemberPendingToastName}_${toastId}` },
                );
            }
        },
        [loading], //eslint-disable-line
    );

    return { removeGroupMember };
};

export default useRemoveGroupMember;
