const statusTypesData = {
    active: "active",
    delayed: "delayed",
    error: "error",
    fail: "fail",
    inProgress: "inProgress",
    info: "info",
    success: "success",
    waiting: "waiting",
};

export default statusTypesData;
