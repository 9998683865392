import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useReleaseNoteSchema = () => {
    const { t } = useTranslation();

    return yup.object({
        note: yup.string().required(t("messages.inputMsgs.required")),
    });
};

export default useReleaseNoteSchema;
