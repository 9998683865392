import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { identifiersData } from "data";
import { theme } from "theme";

const StyledAudioPlayer = styled(Box)(() => ({
    [`& .${identifiersData.classNames.controls}`]: {
        "& .MuiSlider-root": {
            "& .MuiSlider-thumb": {
                height: 15,
                width: 15,
            },
        },
        "& > .MuiBox-root:first-of-type": {
            background: theme.palette?.grey["300"],
            borderRadius: "10px 10px 0 0",
            position: "absolute",
            right: 25,
            top: -40,
        },
        "@media(max-width: 800px)": { paddingInline: 0 },
        "@media(min-width: 1200px)": { paddingInline: 10 },
        alignItems: "center",
        background: theme.palette?.grey["300"],
        bottom: 0,
        display: "flex",
        justifyContent: "space-around",
        minHeight: "60px",
        paddingInline: 15,
        position: "fixed",
        width: "100%",
    },
}));

export default StyledAudioPlayer;
