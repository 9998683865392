import inputData from "data/input";
import selectionsData from "data/selections";

const { roles: rolesSelectionKey } = selectionsData.keys;

const {
    autoComplete: autoCompleteInputType,
    text: textInputType,
} = inputData.types;

const {
    email: emailInputName,
    familyName: familyNameInputName,
    givenName: givenNameInputName,
    role: roleInputName,
} = inputData.names;

const {
    email: emailInputLabel,
    familyName: familyNameInputLabel,
    firstName: firstNameInputLabel,
    role: roleInputLabel,
} = inputData.labels;

const userFormData = [
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: firstNameInputLabel,
        name: givenNameInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        hasOffset: true,
        label: familyNameInputLabel,
        name: familyNameInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: emailInputLabel,
        name: emailInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: roleInputLabel,
        name: roleInputName,
        selectionKey: rolesSelectionKey,
        type: autoCompleteInputType,
    },
];

export default userFormData;
