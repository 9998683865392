import { useAuth0 } from "@auth0/auth0-react";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
    Box,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { Menu, Modal } from "atoms";
import { ImportRevenueReportTemplateForm, RevenueReportsTemplatesList, RevenueReportTemplate } from "components";
import { identifiersData, namesData, permissionsData } from "data";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setRevenueReportTemplateData } from "redux/actions";
import { theme } from "theme";
import { canDo } from "utils";

const ClientRevenueReportsTab = ({
    data,
    loading,
    refetch,
}) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { revenueReportTemplate } = useSelector((state) => state.clientsReducer);

    const { user } = useAuth0();

    const [modalData, setModalData] = useState({
        body: null,
        hasComponent: true,
        id: null,
        open: false,
        title: "",
    });

    const { reportCreate: reportCreatePermission } = permissionsData.permissions.keys;

    const {
        buttons: { back: backButtonName },
        menus: { import: importMenuName },
    } = namesData;

    useEffect(
        () => () => dispatch(setRevenueReportTemplateData({})),
        [], //eslint-disable-line
    );

    return (
        <Box>
            <Modal
                modalData={modalData}
                setModalData={setModalData}
            />
            <Box
                alignItems="center"
                display="flex"
                flexWrap="wrap"
                gap={2}
                justifyContent="space-between"
                marginBottom={5}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                >
                    <Box
                        alignItems="center"
                        display="flex"
                    >
                        {Object?.keys(revenueReportTemplate)?.length > 0 && (
                            <Tooltip title={t("actions.back")}>
                                <IconButton
                                    id={`${backButtonName}_${identifiersData.ids.button}`}
                                    onClick={() => dispatch(setRevenueReportTemplateData({}))}
                                >
                                    <ArrowBackOutlinedIcon
                                        sx={{
                                            color: theme.palette.grey[700],
                                            fontSize: 25,
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Typography variant="h6">{Object?.keys(revenueReportTemplate)?.length === 0 ? t("labels.templates") : t("labels.templateDetails")}</Typography>
                    </Box>
                    {Object?.keys(revenueReportTemplate)?.length === 0 && (
                        <Typography
                            mt={2}
                            sx={{ wordBreak: "break-word" }}
                            variant="body1"
                        >
                            {t("labels.templatesDescription")}
                        </Typography>
                    )}
                </Box>
                {Object?.keys(revenueReportTemplate)?.length === 0 && canDo(
                    user,
                    [reportCreatePermission],
                ) && (
                    <Menu
                        label={t("actions.import")}
                        name={importMenuName}
                        options={[
                            {
                                action: () => {
                                    setModalData({
                                        body: (
                                            <ImportRevenueReportTemplateForm
                                                refetch={refetch}
                                                setModalData={setModalData}
                                            />
                                        ),
                                        hasComponent: true,
                                        id: null,
                                        open: true,
                                        title: t("labels.importTemplate"),
                                    });
                                },
                                icon: <AttachFileIcon sx={{ fontSize: 20 }} />,
                                label: t("actions.importTemplate"),
                            },
                        ]}
                    />
                )}
            </Box>
            {Object?.keys(revenueReportTemplate)?.length > 0 ? <RevenueReportTemplate /> : (
                <RevenueReportsTemplatesList
                    data={data}
                    loading={loading}
                />
            )}
        </Box>
    );
};

export default ClientRevenueReportsTab;

ClientRevenueReportsTab.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool,
    refetch: PropTypes.func,
};
