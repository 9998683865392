import { useMutation } from "@apollo/client";
import { constantsData, importerConfigData, statusTypesData } from "data";
import { createVideoMutation, getReleaseQuery, getTrackQuery } from "graph";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useCreateVideo = (
    trackId,
    releaseId,
    withoutRefetch,
    withoutAlert,
) => {
    const [controller, setController] = useState(null);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [createVideoAction] = useMutation(
        createVideoMutation,
        {
            ...!withoutRefetch && {
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: getTrackQuery,
                        variables: {
                            where: { id: trackId },
                        },
                    },
                    {
                        query: getReleaseQuery,
                        variables: {
                            where: { id: releaseId },
                        },
                    },
                ],
            },
        },
    );

    const createVideo = async (
        fileId,
        typeId,
        importerData,
    ) => {
        if (window?.__RUNTIME_CONFIG__?.ENABLE_DEBUG_LOGS === "true" && importerData) { // eslint-disable-line
            console.log(
                "releaseIdFromCreateVideoHook",
                importerData?.release?.id,
            );
        }

        try {
            const newController = new AbortController();

            setController(newController);

            importerData?.setCurrentStep(importerConfigData.steps.creatingVideo);

            await createVideoAction({
                context: {
                    fetchOptions: { signal: newController.signal },
                },
                onCompleted: ({ createOneVideo }) => {
                    if (importerData?.uploadingVideoFile) {
                        importerData?.setCreatedVideos((prev) => [
                            ...prev,
                            {
                                ...importerData?.video,
                                id: createOneVideo?.id,
                            },
                        ]);
                    }

                    if (!withoutAlert) {
                        dispatch(openAlert(
                            t(
                                "messages.uploadMsgs.success",
                                { entity: t("labels.video") },
                            ),
                            statusTypesData.success,
                        ));
                    }
                },
                variables: {
                    data: {
                        file: {
                            connect: { id: fileId },
                        },
                        release: {
                            connect: { id: importerData ? importerData?.release?.id : releaseId },
                        },
                        track: {
                            connect: { id: importerData ? importerData?.trackId : trackId },
                        },
                        type: {
                            connect: { id: typeId },
                        },
                    },
                },
            });
        } catch (err) {
            if (!withoutAlert) {
                displayErrorHandler(
                    err.message === constantsData.failedToFetch ? t("messages.networkFailure") : `${t(
                        "messages.createMsgs.fail",
                        { entity: t("labels.videos") },
                    )}, ${err.message}`,
                    dispatch,
                    openAlert,
                );
            }

            if (importerData) {
                if (importerData?.uploadingVideoFile) {
                    dispatch(importerData?.setInvalidImporterRelease(
                        {
                            release: { data: importerData?.release },
                            video: {
                                data: importerData?.video,
                                message: err.message === constantsData.failedToFetch ? t("messages.networkFailure") : `${t("messages.importerMsgs.createVideoFailed")}, ${err.message}`,
                            },
                            ...err.message === constantsData.failedToFetch && { isNetworkFailed: true },
                            originalData: importerData?.originalData,
                        },
                    ));

                    importerData?.setStatus((prev) => ({
                        ...prev,
                        videos: {
                            ...prev.videos,
                            loading: false,
                            success: false,
                        },
                    }));
                }
            }
        } finally {
            setController(null);

            if (importerData) importerData?.setCurrentStep(null);
        }
    };

    return {
        cancelRequest: () => controller && controller.abort(),
        createVideo,
    };
};

export default useCreateVideo;
