const permissionsData = {
    key: "http://roles/permissions",
    permissions: {
        actions: {
            create: "create",
            delete: "delete",
            edit: "edit",
            read: "read",
        },
        keys: {
            accountCreate: "account:create",
            accountDelete: "account:delete",
            accountEdit: "account:edit",
            accountRead: "account:read",
            documentCreate: "document:create",
            documentDelete: "document:delete",
            documentEdit: "document:edit",
            documentRead: "document:read",
            driveRead: "drive:read",
            mediaCreate: "media:create",
            mediaDelete: "media:delete",
            mediaEdit: "media:edit",
            mediaRead: "media:read",
            reportCreate: "report:create",
            reportDelete: "report:delete",
            reportEdit: "report:edit",
            reportRead: "report:read",
        },
        types: {
            account: "account",
            document: "document",
            drive: "drive",
            media: "media",
            report: "report",
        },
        typesWithActions: {
            account: ["create", "delete", "edit", "read"],
            document: ["create", "delete", "edit", "read"],
            drive: ["read"],
            media: ["create", "delete", "edit", "read"],
            report: ["create", "delete", "edit", "read"],
        },
    },
    rolesKey: "http://roles/roles",
};

export default permissionsData;
