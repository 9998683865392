import { SET_REFETCH_REVENUE_REPORTS_LIST, TOGGLE_REVENUE_REPORT_DRAWER } from "redux/types";

export const setRefetchRevenueReportsList = (refetch) => ({
    payload: { refetch },
    type: SET_REFETCH_REVENUE_REPORTS_LIST,
});

export const toggleRevenueReportDrawer = (open) => ({
    payload: { open },
    type: TOGGLE_REVENUE_REPORT_DRAWER,
});
