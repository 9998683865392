import fileData from "data/file";
import inputData from "data/input";

const { releaseClientLinks: releaseClientLinksFileName } = fileData.names;

const { file: fileInputType } = inputData.types;

const { releaseClientLinks: releaseClientLinksFileInputLabel } = inputData.labels;

const importReleaseClientLinksFormData = [
    {
        col: { xs: 12 },
        label: releaseClientLinksFileInputLabel,
        name: releaseClientLinksFileName,
        type: fileInputType,
    },
];

export default importReleaseClientLinksFormData;
