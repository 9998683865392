import { regexData } from "data";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useArtistSchema = () => {
    const { t } = useTranslation();

    const { number: numberRegex } = regexData;

    return yup.object({
        code: yup.string().required(t("messages.inputMsgs.required")).matches(
            numberRegex,
            t("messages.inputMsgs.invalidNumber"),
        ).max(
            6,
            t("messages.inputMsgs.max"),
        ),
        imageFile: yup.mixed(),
        isni: yup.string().nullable(),
        nameAr: yup.string().required(t("messages.inputMsgs.required")),
        nameEn: yup.string().required(t("messages.inputMsgs.required")),
        nationality: yup.string().required(t("messages.inputMsgs.required")),
    });
};

export default useArtistSchema;
