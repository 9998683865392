import { HIDE_SENDING_LOADER, SHOW_SENDING_LOADER } from "redux/types";
import { updateStateHandler } from "utils";

const initialState = { show: false };

const showSending = (state) => updateStateHandler(
    state,
    { show: true },
);

const hideSending = (state) => updateStateHandler(
    state,
    { show: false },
);

const sendingReducer = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
    case SHOW_SENDING_LOADER: return showSending(
        state,
        action,
    );
    case HIDE_SENDING_LOADER: return hideSending(
        state,
        action,
    );
    default: return state;
    }
};

export default sendingReducer;
