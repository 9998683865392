import { Drawer, Loader } from "atoms";
import { TrackForm } from "components";
import { actionsData } from "data";
import { useEditTrack, useGetTrack } from "hooks";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toggleTrackDrawer } from "redux/actions";

const TrackDrawer = ({
    id,
    setId,
}) => {
    const { t } = useTranslation();

    const open = useSelector((state) => state.tracksReducer?.openDrawer);

    const dispatch = useDispatch();

    const {
        data,
        loading,
    } = useGetTrack(id);

    const {
        editTrack,
        loading: editTrackLoading,
    } = useEditTrack(
        data?.trackFileId,
        id,
        setId,
    );

    const {
        artist,
        composers,
        custom_id: customId,
        downloadUri,
        image,
        isrc,
        isrc_other: otherIsrc,
        iswc,
        secondary_artists: secondaryArtists,
        title,
        title_ar: nameAr,
        track_num: trackNum,
        trackFile,
        writers,
    } = data || {};

    const formValues = {
        composers,
        customId,
        imageFile: image?.id,
        isrc,
        iswc,
        nameAr,
        nameEn: title,
        number: trackNum,
        oldImage: image,
        oldTrack: downloadUri,
        otherIsrc,
        primaryArtist: artist?.id,
        primaryArtistObj: {
            label: artist?.name,
            value: artist?.id,
        },
        secondaryArtists: secondaryArtists?.map(({ id: artistId }) => ({ id: artistId })),
        secondaryArtistsArray: secondaryArtists?.map(({
            id: artistId,
            name,
        }) => (
            {
                label: name,
                value: artistId,
            }
        )),
        track: id,
        trackFile: trackFile?.id,
        writers,
    };

    return (
        <Drawer
            closeAction={() => dispatch(toggleTrackDrawer(false))}
            open={!!(open && id)}
            title={t("tracks.editTrack")}
        >
            {loading ? <Loader /> : (
                <TrackForm
                    action={actionsData.edit}
                    loading={editTrackLoading}
                    values={formValues}
                    hasDrawer
                    onSubmitForm={editTrack}
                />
            )}
        </Drawer>
    );
};

export default TrackDrawer;

TrackDrawer.propTypes = {
    id: PropTypes.number,
    setId: PropTypes.func,
};
