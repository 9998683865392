import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import { Loader } from "atoms";
import { PageHead, ReleaseDocumentForm } from "components";
import { actionsData, permissionsData } from "data";
import { useCreateReleaseDocument, useDocumentTypesSelectionList } from "hooks";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { canDo } from "utils";
import { ForbiddenView } from "views";

const ReleaseDocumentsTab = () => {
    const { id } = useParams();

    const { user } = useAuth0();

    const { t } = useTranslation();

    const {
        createReleaseDocument,
        loading,
    } = useCreateReleaseDocument(parseInt(id));

    const { loading: documentTypesSelectionListLoading } = useDocumentTypesSelectionList();

    const { documentRead: documentReadPermission } = permissionsData.permissions.keys;

    const allowedPermissions = [documentReadPermission];

    if (documentTypesSelectionListLoading) return <Loader />;

    return (
        <Box>
            <PageHead title={t("tabs.documents")} />
            {canDo(user, allowedPermissions) ? ( // eslint-disable-line
                <ReleaseDocumentForm
                    action={actionsData.create}
                    loading={loading}
                    onSubmitForm={createReleaseDocument}
                />
            ) : <ForbiddenView />}
        </Box>
    );
};

export default ReleaseDocumentsTab;
