import {
    CLEAR_IMPORTER_RELEASES,
    REMOVE_CURRENT_RELEASE,
    REMOVE_INVALID_IMPORTER_RELEASE,
    SET_COMPLETED_IMPORTER_RELEASE,
    SET_CURRENT_RELEASE,
    SET_INVALID_IMPORTER_RELEASE,
    SET_RELEASE_TRACKS,
    STOP_RELEASE_IMPORTING,
    TOGGLE_RELEASE_CLIENT_LINK_DRAWER,
} from "redux/types";
import { updateStateHandler } from "utils";

const initialState = {
    created: false,
    currentRelease: {},
    importer: {
        completedReleases: [],
        invalidReleases: [],
        stopImporting: {
            finish: false,
            start: false,
        },
    },
    openDrawer: false,
    releaseTracks: [],
};

const setCurrentRelease = (state, release) => updateStateHandler(
    state,
    {
        created: true,
        currentRelease: release,
    },
);

const removeCurrentRelease = (state) => updateStateHandler(
    state,
    {
        created: false,
        currentRelease: null,
    },
);

const setReleaseTracks = (state, tracks) => updateStateHandler(
    state,
    { releaseTracks: tracks },
);

const toggleReleaseClientLinkDrawer = (state, open) => updateStateHandler(
    state,
    { openDrawer: open },
);

const setCompletedImporterRelease = (state, release) => updateStateHandler(
    state,
    {
        importer: {
            ...state.importer,
            completedReleases: [
                ...state.importer.completedReleases,
                { ...release },
            ],
        },
    },
);

const setInvalidImporterRelease = (
    state,
    data,
) => {
    const {
        artist,
        document,
        isNetworkFailed,
        isStopped,
        originalData,
        release,
        track,
        video,
    } = data || {};

    const clonedImporterInvalidReleases = [...state.importer.invalidReleases];

    const invalidExistingReleaseIndex = state.importer.invalidReleases.findIndex(({ release: invalidRelease }) => invalidRelease?.data?.upc === release?.data?.upc);

    if (invalidExistingReleaseIndex > -1) {
        const clonedArtists = clonedImporterInvalidReleases[invalidExistingReleaseIndex]?.artists || [];

        const clonedTracks = clonedImporterInvalidReleases[invalidExistingReleaseIndex]?.tracks || [];

        const clonedVideos = clonedImporterInvalidReleases[invalidExistingReleaseIndex]?.videos || [];

        const clonedDocuments = clonedImporterInvalidReleases[invalidExistingReleaseIndex]?.documents || [];

        if (release) {
            clonedImporterInvalidReleases[invalidExistingReleaseIndex].release = {
                data: release?.data,
                ...release?.message && { message: release?.message },
            };
        }

        if (artist) {
            clonedImporterInvalidReleases[invalidExistingReleaseIndex].artists = [
                ...clonedArtists,
                {
                    data: artist?.data,
                    message: artist?.message,
                },
            ];
        }

        if (track) {
            clonedImporterInvalidReleases[invalidExistingReleaseIndex].tracks = [
                ...clonedTracks,
                {
                    data: track?.data,
                    message: track?.message,
                },
            ];
        }

        if (video) {
            clonedImporterInvalidReleases[invalidExistingReleaseIndex].videos = [
                ...clonedVideos,
                {
                    data: video?.data,
                    message: video?.message,
                },
            ];
        }

        if (document) {
            clonedImporterInvalidReleases[invalidExistingReleaseIndex].documents = [
                ...clonedDocuments,
                {
                    data: document?.data,
                    message: document?.message,
                },
            ];
        }

        clonedImporterInvalidReleases[invalidExistingReleaseIndex].originalData = originalData;

        if (isNetworkFailed) clonedImporterInvalidReleases[invalidExistingReleaseIndex].isNetworkFailed = isNetworkFailed;

        if (isStopped) clonedImporterInvalidReleases[invalidExistingReleaseIndex].isStopped = isStopped;

        return updateStateHandler(
            state,
            {
                importer: {
                    ...state.importer,
                    invalidReleases: clonedImporterInvalidReleases,
                },
            },
        );
    }

    return updateStateHandler(
        state,
        {
            importer: {
                ...state.importer,
                invalidReleases: [
                    ...state.importer.invalidReleases,
                    {
                        ...release && {
                            release: {
                                data: release?.data,
                                ...release?.message && { message: release.message },
                            },
                        },
                        ...artist && {
                            artists: [{
                                data: artist?.data,
                                message: artist.message,
                            }],
                        },
                        ...track && {
                            tracks: [{
                                data: track?.data,
                                message: track.message,
                            }],
                        },
                        ...video && {
                            videos: [{
                                data: video?.data,
                                message: video.message,
                            }],
                        },
                        ...document && {
                            documents: [{
                                data: document?.data,
                                message: document.message,
                            }],
                        },
                        originalData,
                        ...isNetworkFailed && { isNetworkFailed },
                        ...isStopped && { isStopped },
                    },
                ],
            },
        },
    );
};

const removeInvalidImporterRelease = (state, upc) => updateStateHandler(
    state,
    {
        importer: {
            ...state.importer,
            invalidReleases: state.importer.invalidReleases?.filter(({ release }) => release.data.upc !== upc),
        },
    },
);

const stopReleaseImporting = (state, data) => updateStateHandler(
    state,
    {
        importer: {
            ...state.importer,
            stopImporting: {
                ...state.importer.stopImporting,
                ...data,
            },
        },
    },
);

const clearImporterReleases = (state) => updateStateHandler(
    state,
    {
        importer: {
            completedReleases: [],
            invalidReleases: [],
            stopImporting: {
                finish: false,
                start: false,
            },
        },
    },
);

const releasesReducer = (state = initialState, action) => {
    const {
        payload,
        type,
    } = action;

    const {
        data,
        open,
        release,
        tracks,
        upc,
    } = payload || {};

    switch (type) {
    case SET_CURRENT_RELEASE: return setCurrentRelease(
        state,
        release,
    );
    case REMOVE_CURRENT_RELEASE: return removeCurrentRelease(state);
    case TOGGLE_RELEASE_CLIENT_LINK_DRAWER: return toggleReleaseClientLinkDrawer(
        state,
        open,
    );
    case SET_COMPLETED_IMPORTER_RELEASE: return setCompletedImporterRelease(
        state,
        release,
    );
    case SET_INVALID_IMPORTER_RELEASE: return setInvalidImporterRelease(
        state,
        data,
    );
    case REMOVE_INVALID_IMPORTER_RELEASE: return removeInvalidImporterRelease(
        state,
        upc,
    );
    case STOP_RELEASE_IMPORTING: return stopReleaseImporting(
        state,
        data,
    );

    case CLEAR_IMPORTER_RELEASES: return clearImporterReleases(state);
    case SET_RELEASE_TRACKS: return setReleaseTracks(
        state,
        tracks,
    );
    default:
        return state;
    }
};

export default releasesReducer;
