import inputData from "data/input";
import selectionsData from "data/selections";

const { tracks: tracksSelectionKey } = selectionsData.keys;

const {
    autoComplete: autoCompleteInputType,
    range: rangeInputType,
} = inputData.types;

const {
    duration: durationInputName,
    track: trackInputName,
} = inputData.names;

const {
    duration: durationInputLabel,
    track: trackInputLabel,
} = inputData.labels;

const releaseSoundRecordingFormData = [
    {
        col: {
            md: 4,
            xs: 12,
        },
        hasOffset: true,
        label: trackInputLabel,
        name: trackInputName,
        selectionKey: tracksSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: durationInputLabel,
        name: durationInputName,
        type: rangeInputType,
    },
];

export default releaseSoundRecordingFormData;
