import { Blank } from "components";
import { namesData, urlsData } from "data";
import { BlankLayout } from "layouts";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ForbiddenView = ({ withoutBack }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    return (
        <BlankLayout>
            <Blank
                buttonName={namesData.buttons.backToHome}
                buttonText={t("labels.backToHome")}
                description={t("labels.forbiddenDescription")}
                title={t("labels.forbiddenTitle")}
                withoutButton={withoutBack}
                onClickButton={() => navigate(urlsData.routes.home)}
            />
        </BlankLayout>
    );
};

export default ForbiddenView;

ForbiddenView.propTypes = { withoutBack: PropTypes.bool };
