import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData } from "data";
import { createReleaseRoyaltyMutation, getTrackQuery } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useCreateReleaseRoyalty = (id) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [createReleaseRoyaltyAction, { loading }] = useMutation(
        createReleaseRoyaltyMutation,
        {
            refetchQueries: [
                {
                    query: getTrackQuery,
                    variables: {
                        where: { id },
                    },

                },
            ],
        },
    );

    const createReleaseRoyalty = async ({
        master,
        mechanicalPerformance,
        neighboring,
        synchronization,
        track,
    }) => {
        try {
            await createReleaseRoyaltyAction({
                onCompleted: () => {
                    dispatch(openAlert(
                        t(
                            "messages.createMsgs.success",
                            { entity: `${t("releases.entity")} ${t("labels.royalty")}` },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: {
                    data: {
                        master: !!master,
                        mechanical_performance: !!mechanicalPerformance, //eslint-disable-line
                        neighboring: !!neighboring,
                        synchronization: !!synchronization,
                        track: {
                            connect: { id: track },
                        },
                    },
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        createReleaseRoyalty,
        loading,
    };
};

export default useCreateReleaseRoyalty;
