export const createSecondaryArtistHandler = async (importerData) => {
    const {
        artist,
        createArtist,
    } = importerData;

    const {
        artistNameAr,
        artistNameEn,
        code,
        isni,
        nationality,
    } = artist;

    await createArtist(
        {
            code,
            isni: isni?.toString(),
            nameAr: artistNameAr,
            nameEn: artistNameEn,
            nationality: nationality?.value,
        },
        {
            ...importerData,
            creatingSecondaryArtist: true,
        },
    );
};

export const checkSecondaryArtistExistence = async (importerData, lists) => {
    const {
        artist,
        setExistingSecondaryArtists,
    } = importerData;

    const { artists } = lists;

    const { code } = artist;

    const secondaryArtist = artists?.find(({ code: artistCode }) => artistCode === code);

    if (secondaryArtist && secondaryArtist?.code) {
        setExistingSecondaryArtists((prev) => [
            ...prev,
            { ...secondaryArtist },
        ]);
    } else await createSecondaryArtistHandler(importerData);
};

export const createArtistHandler = async (importerData) => {
    const {
        artist,
        createArtist,
    } = importerData;

    const {
        artistNameAr,
        artistNameEn,
        code,
        isni,
        nationality,
    } = artist;

    await createArtist(
        {
            code,
            isni: isni?.toString(),
            nameAr: artistNameAr,
            nameEn: artistNameEn,
            nationality: nationality?.value,
        },
        {
            ...importerData,
            creatingArtist: true,
        },
    );
};

export const checkArtistExistenceHandler = async (importerData, lists) => {
    const {
        artist,
        setArtistId,
        setStatus,
        uploadReleaseImageHandler,
    } = importerData;

    const { artists } = lists;

    const { code } = artist;

    const releaseArtist = artists?.find(({ code: artistCode }) => artistCode === code);

    if (releaseArtist && releaseArtist?.code) {
        setStatus((prev) => ({
            ...prev,
            artist: {
                ...prev.artist,
                loading: false,
                searching: false,
                success: true,
            },
            releaseImage: {
                ...prev.releaseImage,
                loading: true,
            },
        }));

        setArtistId(releaseArtist?.id);

        await uploadReleaseImageHandler(importerData);
    } else await createArtistHandler(importerData);
};

export const startLoadingArtistHandler = (importerData) => {
    importerData?.setStatus((prev) => ({
        ...prev,
        artist: {
            ...prev.artist,
            loading: true,
            searching: true,
            success: null,
        },
    }));
};

export const searchArtistsHandler = (importerData) => {
    const {
        artist,
        secondaryArtists,
        setArtistsCodes,
        setArtistsNames,
    } = importerData;

    const artistsNamesList = [];

    const artistsCodesList = [];

    if (secondaryArtists?.length > 0) {
        secondaryArtists.forEach(({
            artistNameEn,
            code,
        }) => {
            if (artistNameEn) artistsNamesList.push(artistNameEn);

            if (code) artistsCodesList.push(code);
        });
    }
    if (artist?.artistNameEn) artistsNamesList.push(artist?.artistNameEn);

    if (artist?.code) artistsCodesList.push(artist?.code);

    if (artistsNamesList.length > 0) setArtistsNames(artistsNamesList);

    if (artistsCodesList.length > 0) setArtistsCodes(artistsCodesList);
};
