import loadable from "@loadable/component";
import { constantsData, permissionsData, urlsData } from "data";
import { ProtectedRoute } from "routes";

const {
    artists: {
        create: createArtistUrl,
        edit: editArtistUrl,
        url: artistsRouteUrl,
    },
    clients: {
        create: createClientRouteUrl,
        edit: editClientRouteUrl,
        url: clientsRouteUrl,
        xmlFile: {
            edit: editXmlFileRouteUrl,
            preview: previewXmlFileRouteUrl,
        },
    },
    distributions: distributionsRouteUrl,
    driveManagement: {
        drives: driveManagementDrivesRouteUrl,
        folders: driveManagementFoldersRouteUrl,
        search: driveManagementSearchRouteUrl,
        url: driveManagementRouteUrl,
    },
    dynamicXml: dynamicXmlFormRouteUrl,
    groups: {
        create: createGroupRouteUrl,
        edit: editGroupRouteUrl,
        members: {
            create: createMemberRouteUrl,
            edit: editMemberRouteUrl,
            url: membersRouteUrl,
        },
        url: groupsRouteUrl,
    },
    home: homeRouteUrl,
    login: loginRouteUrl,
    notFound: notFoundRouteUrl,
    permissions: permissionsRouteUrl,
    releases: {
        create: createReleaseUrl,
        edit: editReleaseUrl,
        importer: releasesImporterRouteUrl,
        url: releasesRouteUrl,
    },
    revenueReports: { url: revenueReportsRouteUrl },
    search: searchRouteUrl,
    territories: {
        create: createTerritoryUrl,
        edit: editTerritoryUrl,
        url: territoriesRouteUrl,
    },
    tracks: { url: tracksRouteUrl },
    users: {
        create: createUserRouteUrl,
        edit: editUserRouteUrl,
        url: usersRouteUrl,
    },
    videos: videosRouteUrl,
} = urlsData.routes;

const {
    accountCreate: accountCreatePermission,
    accountEdit: accountEditPermission,
    accountRead: accountReadPermission,
    driveRead: driveReadPermission,
    mediaCreate: mediaCreatePermission,
    mediaEdit: mediaEditPermission,
    mediaRead: mediaReadPermission,
    reportRead: reportReadPermission,
} = permissionsData.permissions.keys;

const {
    roleKeys: roleKeysConstant,
    superAdmins: superAdminsConstant,
} = constantsData;

const { [superAdminsConstant]: superAdminKey } = JSON.parse(localStorage.getItem(roleKeysConstant)) || superAdminsConstant; // eslint-disable-line

/* ****Layouts***** */
const BlankLayout = loadable(() => import("layouts/blank"));
const MainLayout = loadable(() => import("layouts/main"));
const DriveManagementLayout = loadable(() => import("layouts/drive-management"));
const GroupsLayout = loadable(() => import("layouts/groups"));

/* ****Views***** */
const HomeView = loadable(() => import("views/home"));

const ArtistsListView = loadable(() => import("views/artists"));
const ArtistDetailsView = loadable(() => import("views/artists/details"));
const CreateArtistView = loadable(() => import("views/artists/create"));
const EditArtistView = loadable(() => import("views/artists/edit"));

const ReleasesListView = loadable(() => import("views/releases"));
const ReleaseDetailsView = loadable(() => import("views/releases/details"));
const ReleasesImporterView = loadable(() => import("views/releases/importer"));
const CreateReleaseView = loadable(() => import("views/releases/create"));
const EditReleaseView = loadable(() => import("views/releases/edit"));

const DistributionsListView = loadable(() => import("views/distributions"));

const TracksListView = loadable(() => import("views/tracks"));
const TrackDetailsView = loadable(() => import("views/tracks/details"));

const VideosListView = loadable(() => import("views/videos"));

const UsersListView = loadable(() => import("views/users"));
const CreateUserView = loadable(() => import("views/users/create"));
const EditUserView = loadable(() => import("views/users/edit"));

const ClientsListView = loadable(() => import("views/clients"));
const PreviewClientXmlFileView = loadable(() => import("views/clients/xml-file/preview"));
const CreateClientView = loadable(() => import("views/clients/create"));
const EditClientView = loadable(() => import("views/clients/edit"));
const EditClientXmlFileView = loadable(() => import("views/clients/xml-file/edit"));

const RevenueReportsListView = loadable(() => import("views/revenue-reports"));

const TerritoriesListView = loadable(() => import("views/territories"));
const CreateTerritoryView = loadable(() => import("views/territories/create"));
const EditTerritoryView = loadable(() => import("views/territories/edit"));

const PermissionsView = loadable(() => import("views/permissions"));

const DriveManagementListView = loadable(() => import("views/drive-management"));
const DriveManagementFilesListView = loadable(() => import("views/drive-management/files-list"));
const DriveManagementSearchListView = loadable(() => import("views/drive-management/search"));

const GroupsListView = loadable(() => import("views/groups"));
const CreateGroupView = loadable(() => import("views/groups/create"));
const EditGroupView = loadable(() => import("views/groups/edit"));

const MembersListView = loadable(() => import("views/groups/members"));
const CreateMemberView = loadable(() => import("views/groups/members/create"));
const EditMemberView = loadable(() => import("views/groups/members/edit"));

const SearchListsView = loadable(() => import("views/search"));

const LoginView = loadable(() => import("views/login"));

const NotFoundView = loadable(() => import("views/not-found"));

const DynamicXml = loadable(() => import("views/dynamic-xml"));

/* ****Routes***** */
const Routes = [
    {
        children: [{
            element: (
                <ProtectedRoute
                    component={HomeView}
                    permissions={[mediaReadPermission]}
                />
            ),
            path: homeRouteUrl,
        }],
        element: <MainLayout />,
        path: homeRouteUrl,
    },
    {
        children: [
            {
                element: (
                    <ProtectedRoute
                        component={ArtistsListView}
                        permissions={[mediaReadPermission]}
                    />
                ),
                path: artistsRouteUrl,
            },
            {
                element: (
                    <ProtectedRoute
                        component={ArtistDetailsView}
                        permissions={[mediaReadPermission]}
                    />
                ),
                path: `${artistsRouteUrl}/:id`,
            },
            {
                element: (
                    <ProtectedRoute
                        component={CreateArtistView}
                        permissions={[mediaReadPermission, mediaCreatePermission]}
                    />
                ),
                path: createArtistUrl,
            },
            {
                element: (
                    <ProtectedRoute
                        component={EditArtistView}
                        permissions={[mediaReadPermission, mediaEditPermission]}
                    />
                ),
                path: `${editArtistUrl}/:id`,
            },
        ],
        element: <MainLayout />,
        path: artistsRouteUrl,
    },
    {
        children: [
            {
                element: (
                    <ProtectedRoute
                        component={ReleasesListView}
                        permissions={[mediaReadPermission]}
                    />
                ),
                path: releasesRouteUrl,
            },
            {
                element: (
                    <ProtectedRoute
                        component={ReleaseDetailsView}
                        permissions={[mediaReadPermission]}
                    />
                ),
                path: `${releasesRouteUrl}/:id`,
            },
            {
                element: (
                    <ProtectedRoute
                        component={ReleasesImporterView}
                        permissions={[mediaReadPermission, mediaCreatePermission]}
                    />
                ),
                path: releasesImporterRouteUrl,
            },
            {
                element: (
                    <ProtectedRoute
                        component={CreateReleaseView}
                        permissions={[mediaReadPermission, mediaCreatePermission]}
                    />
                ),
                path: createReleaseUrl,
            },
            {
                element: (
                    <ProtectedRoute
                        component={EditReleaseView}
                        permissions={[mediaReadPermission]}
                    />
                ),
                path: `${editReleaseUrl}/:id`,
            },
        ],
        element: <MainLayout />,
        path: releasesRouteUrl,
    },
    {
        children: [
            {
                element: (
                    <ProtectedRoute
                        component={DistributionsListView}
                        permissions={[mediaReadPermission]}
                    />
                ),
                path: distributionsRouteUrl,
            },
        ],
        element: <MainLayout />,
        path: distributionsRouteUrl,
    },
    {
        children: [
            {
                element: (
                    <ProtectedRoute
                        component={TracksListView}
                        permissions={[mediaReadPermission]}
                    />
                ),
                path: tracksRouteUrl,
            },
            {
                element: (
                    <ProtectedRoute
                        component={TrackDetailsView}
                        permissions={[mediaReadPermission]}
                    />
                ),
                path: `${tracksRouteUrl}/:id`,
            },
        ],
        element: <MainLayout />,
        path: tracksRouteUrl,
    },
    {
        children: [
            {
                element: (
                    <ProtectedRoute
                        component={VideosListView}
                        permissions={[mediaReadPermission]}
                    />
                ),
                path: videosRouteUrl,
            },
        ],
        element: <MainLayout />,
        path: videosRouteUrl,
    },
    {
        children: [
            {
                element: (
                    <ProtectedRoute
                        component={UsersListView}
                        permissions={[accountReadPermission]}
                    />
                ),
                path: usersRouteUrl,
            },
            {
                element: (
                    <ProtectedRoute
                        component={CreateUserView}
                        permissions={[accountReadPermission, accountCreatePermission]}
                    />
                ),
                path: createUserRouteUrl,
            },
            {
                element: (
                    <ProtectedRoute
                        component={EditUserView}
                        permissions={[accountReadPermission, accountEditPermission]}
                    />
                ),
                path: `${editUserRouteUrl}/:id`,
            },
        ],
        element: <MainLayout />,
        path: usersRouteUrl,
    },
    {
        children: [
            {
                element: (
                    <ProtectedRoute
                        component={ClientsListView}
                        permissions={[mediaReadPermission, reportReadPermission]}
                        oneOfPermissions
                    />
                ),
                path: clientsRouteUrl,
            },
            {
                element: (
                    <ProtectedRoute
                        component={PreviewClientXmlFileView}
                        permissions={[mediaReadPermission]}
                    />
                ),
                path: `${clientsRouteUrl}/:id${previewXmlFileRouteUrl}`,
            },
            {
                element: (
                    <ProtectedRoute
                        component={CreateClientView}
                        permissions={[mediaReadPermission, mediaCreatePermission]}
                    />
                ),
                path: createClientRouteUrl,
            },
            {
                element: (
                    <ProtectedRoute
                        component={EditClientView}
                        permissions={[mediaReadPermission, reportReadPermission]}
                        oneOfPermissions
                    />
                ),
                path: `${editClientRouteUrl}/:id`,
            },
            {
                element: (
                    <ProtectedRoute
                        component={EditClientXmlFileView}
                        permissions={[mediaReadPermission]}
                    />
                ),
                path: `${clientsRouteUrl}/:id${editXmlFileRouteUrl}`,
            },
        ],
        element: <MainLayout />,
        path: clientsRouteUrl,
    },
    {
        children: [
            {
                element: (
                    <ProtectedRoute
                        component={RevenueReportsListView}
                        permissions={[reportReadPermission]}
                    />
                ),
                path: revenueReportsRouteUrl,
            },
        ],
        element: <MainLayout />,
        path: revenueReportsRouteUrl,
    },
    {
        children: [
            {
                element: (
                    <ProtectedRoute
                        component={TerritoriesListView}
                        permissions={[mediaReadPermission]}
                    />
                ),
                path: territoriesRouteUrl,
            },
            {
                element: (
                    <ProtectedRoute
                        component={CreateTerritoryView}
                        permissions={[mediaReadPermission, mediaCreatePermission]}
                    />
                ),
                path: createTerritoryUrl,
            },
            {
                element: (
                    <ProtectedRoute
                        component={EditTerritoryView}
                        permissions={[mediaReadPermission, mediaEditPermission]}
                    />
                ),
                path: `${editTerritoryUrl}/:id`,
            },
        ],
        element: <MainLayout />,
        path: territoriesRouteUrl,
    },
    {
        children: [
            {
                element: (
                    <ProtectedRoute
                        component={PermissionsView}
                        roles={[superAdminKey]}
                    />
                ),
                path: permissionsRouteUrl,
            },
        ],
        element: <MainLayout />,
        path: permissionsRouteUrl,
    },
    {
        children: [
            {
                element: (
                    <ProtectedRoute
                        component={DriveManagementListView}
                        permissions={[driveReadPermission]}
                    />
                ),
                path: driveManagementRouteUrl,
            },
            {
                element: (
                    <ProtectedRoute
                        component={DriveManagementFilesListView}
                        permissions={[driveReadPermission]}
                    />
                ),
                path: `${driveManagementRouteUrl}${driveManagementDrivesRouteUrl}/:driveId`,
            },
            {
                element: (
                    <ProtectedRoute
                        component={DriveManagementFilesListView}
                        permissions={[driveReadPermission]}
                    />
                ),
                path: `${driveManagementRouteUrl}${driveManagementDrivesRouteUrl}/:driveId${driveManagementFoldersRouteUrl}/:folderId`,
            },
            {
                element: (
                    <ProtectedRoute
                        component={DriveManagementSearchListView}
                        permissions={[driveReadPermission]}
                    />
                ),
                path: `${driveManagementRouteUrl}/${driveManagementSearchRouteUrl}`,
            },
        ],
        element: <DriveManagementLayout />,
        path: driveManagementRouteUrl,
    },
    {
        children: [
            {
                element: (
                    <ProtectedRoute
                        component={GroupsListView}
                        permissions={[driveReadPermission]}
                    />
                ),
                path: `${groupsRouteUrl}`,
            },
            {
                element: (
                    <ProtectedRoute
                        component={CreateGroupView}
                        permissions={[driveReadPermission]}
                    />
                ),
                path: `${createGroupRouteUrl}`,
            },
            {
                element: (
                    <ProtectedRoute
                        component={EditGroupView}
                        permissions={[driveReadPermission]}
                    />
                ),
                path: `${editGroupRouteUrl}/:id`,
            },
            {
                element: (
                    <ProtectedRoute
                        component={MembersListView}
                        permissions={[driveReadPermission]}
                    />
                ),
                path: `${groupsRouteUrl}${membersRouteUrl}`,
            },
            {
                element: (
                    <ProtectedRoute
                        component={MembersListView}
                        permissions={[driveReadPermission]}
                    />
                ),
                path: `${groupsRouteUrl}/:id${membersRouteUrl}`,
            },
            {
                element: (
                    <ProtectedRoute
                        component={CreateMemberView}
                        permissions={[driveReadPermission]}
                    />
                ),
                path: `${groupsRouteUrl}${createMemberRouteUrl}`,
            },
            {
                element: (
                    <ProtectedRoute
                        component={CreateMemberView}
                        permissions={[driveReadPermission]}
                    />
                ),
                path: `${groupsRouteUrl}/:id${createMemberRouteUrl}`,
            },
            {
                element: (
                    <ProtectedRoute
                        component={EditMemberView}
                        permissions={[driveReadPermission]}
                    />
                ),
                path: `${groupsRouteUrl}${editMemberRouteUrl}/:id`,
            },
        ],
        element: <GroupsLayout />,
        path: groupsRouteUrl,
    },
    {
        children: [{
            element: (
                <ProtectedRoute
                    component={SearchListsView}
                    permissions={[mediaReadPermission]}
                />
            ),
            path: searchRouteUrl,
        }],
        element: <MainLayout />,
        path: searchRouteUrl,
    },
    {
        element: <LoginView />,
        path: loginRouteUrl,
    },
    {
        children: [
            {
                element: <ProtectedRoute
                    component={DynamicXml}
                    permissions={[mediaReadPermission]}
                />,
                path: dynamicXmlFormRouteUrl,
            },
        ],
        element: <MainLayout />,
        path: dynamicXmlFormRouteUrl,
    },
    {
        children: [
            {
                element: <NotFoundView />,
                path: notFoundRouteUrl,
            },
        ],
        element: <BlankLayout />,
        path: notFoundRouteUrl,
    },
];

export default Routes;
