import { useAuth0 } from "@auth0/auth0-react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import TreeView from "@mui/lab/TreeView";
import { Box, Button, Typography } from "@mui/material";
import { Modal } from "atoms";
import { FoldersStructureChildFolderForm } from "components";
import {
    actionsData,
    constantsData,
    identifiersData,
    namesData,
    permissionsData,
    statusTypesData,
} from "data";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { openAlert } from "redux/actions";
import { theme } from "theme";
import { canDo } from "utils";

import FoldersStructureTabTreeItem from "./item";

const FoldersStructureTabTree = ({
    actions,
    selectedTreeItemData,
    setSelectedTreeItemData,
}) => {
    const [modalData, setModalData] = useState({
        body: null,
        hasComponent: true,
        id: null,
        open: false,
        title: "",
    });

    const { t } = useTranslation();

    const currentClient = useSelector((state) => state.clientsReducer)?.currentClient;

    const dispatch = useDispatch();

    const foldersStructureTreeData = currentClient?.XMLFolderStructure?.children;

    const { user } = useAuth0();

    const {
        mediaCreate: mediaCreatePermission,
        mediaRead: mediaReadPermission,
    } = permissionsData.permissions.keys;

    const {
        file: fileConstant,
        folder: folderConstant,
    } = constantsData;

    const treeItemsIds = [];

    const renderTreeItemHandler = (data, parentIndex) => data?.map(({
        children,
        id,
        name,
        type,
    }, index) => {
        if (type === folderConstant) {
            return (
                <FoldersStructureTabTreeItem
                    childrenNumber={children?.filter(({ type: itemType }) => itemType === fileConstant)?.length}
                    key={id}
                    name={name}
                    nodeId={id}
                    rowId={parentIndex || parentIndex === 0 ? `${parentIndex + 1}_${index + 1}` : index + 1}
                >
                    {children?.length > 0 && renderTreeItemHandler(
                        children,
                        parentIndex || parentIndex === 0 ? `${parentIndex}_${index}` : index,
                    )}
                </FoldersStructureTabTreeItem>
            );
        }
    });

    const getSelectedTreeItemDataHandler = (id, data) => {
        data?.map(({
            children,
            id: folderId,
            isSelection,
            name,
            parentPath,
            type,
        }) => {
            if (type === folderConstant) {
                if (id === folderId) {
                    setSelectedTreeItemData({
                        children,
                        id: folderId,
                        isSelection,
                        name,
                        parentPath,
                        type,
                    });
                }

                if (children?.length > 0) {
                    getSelectedTreeItemDataHandler(
                        id,
                        children,
                    );
                }
            }
        });
    };

    const getTreeItemsIds = (data) => {
        data?.map(({
            children,
            id,
            type,
        }) => {
            if (type === folderConstant) {
                treeItemsIds.push(id);

                if (children?.length > 0) getTreeItemsIds(children);
            }
        });

        return treeItemsIds;
    };

    const addFolderHandler = ({
        selectableFolderName,
        textFolderName,
    }) => {
        if (!selectableFolderName && !textFolderName) {
            dispatch(openAlert(
                t("messages.requiredNewFolderName"),
                statusTypesData.error,
            ));
        } else {
            actions.createFolder({
                isSelection: !!selectableFolderName,
                name: selectableFolderName || textFolderName,
                parentPath: selectedTreeItemData?.parentPath === "" ? selectedTreeItemData?.name : `${selectedTreeItemData?.parentPath}/${selectedTreeItemData?.name}`,
            });

            setModalData({
                ...modalData,
                open: false,
            });
        }
    };

    const clickAddNewFolderButtonHandler = () => {
        setModalData({
            body: (
                <FoldersStructureChildFolderForm
                    action={actionsData.add}
                    setModalData={setModalData}
                    values={{}}
                    onSubmitForm={addFolderHandler}
                />
            ),
            hasComponent: true,
            open: true,
            title: t("actions.addNewFolder"),
        });
    };

    return (
        <Box>
            <Modal
                modalData={modalData}
                setModalData={setModalData}
            />

            <Box mb={3}>
                <Button
                    color="primary"
                    id={`${namesData.buttons.addNewFolder}_${identifiersData.ids.button}`}
                    variant="outlined"
                    disabled={!selectedTreeItemData?.name || !canDo(
                        user,
                        [mediaCreatePermission, mediaReadPermission],
                    )}
                    onClick={clickAddNewFolderButtonHandler}
                >
                    <CreateNewFolderOutlinedIcon sx={{ marginRight: 1 }} />
                    {t("actions.addNewFolder")}
                </Button>
                <Typography
                    caption="body1"
                    color={theme.palette.grey[600]}
                    mt={1}
                >
                    {t("labels.foldersStructureFolderTitle")}
                </Typography>
            </Box>

            <Box minHeight={400}>
                <TreeView
                    defaultCollapseIcon={<ArrowDropDownIcon />}
                    defaultEndIcon={<Box width={24} />}
                    defaultExpandIcon={<ArrowRightIcon />}
                    defaultExpanded={getTreeItemsIds(foldersStructureTreeData)}
                    defaultSelected={selectedTreeItemData?.id}
                    sx={{
                        flexGrow: 1,
                        height: 264,
                        maxWidth: 400,
                        overflowY: "auto",
                    }}
                    onNodeSelect={(_, value) => getSelectedTreeItemDataHandler(
                        value,
                        foldersStructureTreeData,
                    )}
                >
                    {renderTreeItemHandler(foldersStructureTreeData)}
                </TreeView>
            </Box>
        </Box>

    );
};

export default FoldersStructureTabTree;

FoldersStructureTabTree.propTypes = {
    actions: PropTypes.object,
    selectedTreeItemData: PropTypes.object,
    setSelectedTreeItemData: PropTypes.func,
};
