import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData, urlsData } from "data";
import { editTerritoryMutation } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useEditTerritory = (id) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [editTerritoryAction, { loading }] = useMutation(editTerritoryMutation);

    const editTerritory = async ({
        countries,
        name,
    }) => {
        try {
            await editTerritoryAction({
                onCompleted: () => {
                    navigate(urlsData.routes.territories.url);

                    dispatch(openAlert(
                        t(
                            "messages.editMsgs.success",
                            { entity: t("territories.entity") },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: {
                    data: {
                        countries: {
                            set: countries?.map(({
                                id: countryId,
                                value,
                            }) => ({ id: countryId || value })),
                        },
                        name: { set: name },
                    },
                    where: { id },
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        editTerritory,
        loading,
    };
};

export default useEditTerritory;
