import { useAuth0 } from "@auth0/auth0-react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
    Badge,
    Box,
    Button,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { DriveManagementMenu, Menu } from "atoms";
import {
    identifiersData,
    namesData,
    permissionsData,
    urlsData,
} from "data";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { closeFilters, openFilters } from "redux/actions";
import { StyledPageHead, StyledPageHeadTitle } from "styles";
import { theme } from "theme";
import { canDo } from "utils";

const PageHead = ({
    actionComponent,
    addAction,
    addRouteUrl,
    backAction,
    backRouteUrl,
    createNewFolderAction,
    enableUploadFiles,
    exportAction,
    filtersCount,
    filtersModule,
    hasAction,
    hasAdd,
    hasBack,
    hasDriveManagementMenu,
    hasExport,
    hasFilters,
    hasSelect,
    isDrivePageHead,
    selectAction,
    selected = [],
    title,
    titleTag,
    uploadFilesAction,
}) => {
    const { t } = useTranslation();

    const filtersOpened = useSelector((state) => state.filtersReducer.open);

    const dispatch = useDispatch();

    const location = useLocation();

    const { user } = useAuth0();

    const {
        accountCreate: accountCreatePermission,
        mediaCreate: mediaCreatePermission,
    } = permissionsData.permissions.keys;

    const allowedPermissions = location.pathname === urlsData.routes.users.url ? [accountCreatePermission] : [mediaCreatePermission];

    const { button: buttonId } = identifiersData.ids;

    const {
        back: backButtonName,
        closeFilters: closeFiltersButtonName,
        create: createButtonName,
        openFilters: openFiltersButtonName,
        select: selectButtonName,
    } = namesData.buttons;

    return (
        <StyledPageHead>
            <StyledPageHeadTitle>
                {hasBack && (
                    <Link
                        to={backRouteUrl}
                        onClick={backAction}
                    >
                        <Tooltip title={t("actions.back")}>
                            <IconButton id={`${backButtonName}_${buttonId}`}>
                                <ArrowBackOutlinedIcon
                                    sx={{
                                        color: theme.palette.grey[700],
                                        fontSize: 25,
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Link>
                )}
                {hasDriveManagementMenu ? (
                    <DriveManagementMenu
                        createNewFolderAction={createNewFolderAction}
                        enableUploadFiles={enableUploadFiles}
                        uploadFilesAction={uploadFilesAction}
                    />
                ) : (
                    <Typography
                        component={titleTag || "h1"}
                        variant="h6"
                    >
                        {title}
                    </Typography>
                )}
            </StyledPageHeadTitle>
            <Box
                display="flex"
                gap={(hasAdd && hasFilters) && 2}
            >
                {hasExport && selected.length > 0 && (
                    <Box
                        mt={1}
                        mx={1}
                    >
                        <Menu
                            label={t("actions.exportData")}
                            name={namesData.menus.exportData}
                            options={[
                                {
                                    action: () => exportAction(selected),
                                    label: t("labels.csv"),
                                },
                            ]}
                            withTransparentButton
                        />
                    </Box>
                )}
                {hasSelect && (
                    <Box
                        mt={1}
                        mx={1}
                    >
                        <Button
                            id={`${selectButtonName}_${buttonId}`}
                            onClick={() => selectAction(true)}
                        >
                            {t("actions.select")}
                        </Button>
                    </Box>
                )}
                {hasAdd && (
                    canDo(
                        user,
                        allowedPermissions,
                    ) || isDrivePageHead
                ) && (
                    <Box>
                        {addRouteUrl ? (
                            <Link to={addRouteUrl}>
                                <Tooltip title={t("actions.create")}>
                                    <IconButton id={`${createButtonName}_${buttonId}`}>
                                        <AddCircleIcon
                                            sx={{
                                                color: theme.palette.primary.main,
                                                fontSize: 30,
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Link>
                        ) : (
                            <Tooltip title={t("actions.create")}>
                                <IconButton
                                    id={`${createButtonName}_${buttonId}`}
                                    onClick={addAction}
                                >
                                    <AddCircleIcon
                                        sx={{
                                            color: theme.palette.primary.main,
                                            fontSize: 30,
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                )}
                {hasAction && (
                    <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="center"
                    >
                        {actionComponent}
                    </Box>
                )}
                {hasFilters && (
                    <Tooltip title={filtersOpened ? t("actions.closeFilters") : t("actions.openFilters")}>
                        <IconButton
                            id={`${filtersOpened ? closeFiltersButtonName : openFiltersButtonName}_${buttonId}`}
                            onClick={() => (filtersOpened ? dispatch(closeFilters()) : dispatch(openFilters(filtersModule)))}
                        >
                            <Badge
                                badgeContent={filtersCount}
                                color="error"
                                variant="solid"
                            >
                                <FilterListIcon
                                    sx={{
                                        background: filtersOpened ? theme.palette.primary.main : theme.palette.grey[100],
                                        borderRadius: "50%",
                                        color: filtersOpened ? theme.palette.grey[100] : theme.palette.primary.main,
                                        fontSize: 25,
                                        padding: filtersOpened && 0.5,
                                    }}
                                />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        </StyledPageHead>
    );
};

export default PageHead;

PageHead.propTypes = {
    actionComponent: PropTypes.node,
    addAction: PropTypes.func,
    addRouteUrl: PropTypes.string,
    backAction: PropTypes.func,
    backRouteUrl: PropTypes.string,
    createNewFolderAction: PropTypes.func,
    enableUploadFiles: PropTypes.bool,
    exportAction: PropTypes.func,
    filtersCount: PropTypes.number,
    filtersModule: PropTypes.string,
    hasAction: PropTypes.bool,
    hasAdd: PropTypes.bool,
    hasBack: PropTypes.bool,
    hasDriveManagementMenu: PropTypes.bool,
    hasExport: PropTypes.bool,
    hasFilters: PropTypes.bool,
    hasSelect: PropTypes.bool,
    isDrivePageHead: PropTypes.bool,
    selectAction: PropTypes.func,
    selected: PropTypes.array,
    title: PropTypes.string,
    titleTag: PropTypes.string,
    uploadFilesAction: PropTypes.func,
};
