import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useFoldersStructureTrackFileSchema = () => {
    const { t } = useTranslation();

    return yup.object({
        fileExtension: yup.string().required(t("messages.inputMsgs.required")),
        fileName: yup.string().required(t("messages.inputMsgs.required")),
        fileType: yup.string().required(t("messages.inputMsgs.required")),
    });
};

export default useFoldersStructureTrackFileSchema;
