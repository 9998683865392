import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData, urlsData } from "data";
import { createClientMutation } from "graph";
import { useEnableClient } from "hooks";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useCreateClient = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { enableClient } = useEnableClient();

    const [createClientAction, { loading }] = useMutation(createClientMutation);

    const createClient = async ({
        enabled,
        ftpBaseFolder,
        ftpHost,
        ftpPassword,
        ftpPort,
        ftpUsername,
        imageHeight,
        imageType,
        imageWidth,
        name,
        pdap,
        pdapName,
        secured,
        sshKeyFile,
        trackType,
    }) => {
        try {
            createClientAction({
                onCompleted: async ({ createClient: createClientData }) => {
                    await enableClient(
                        enabled,
                        createClientData.id,
                    );

                    navigate(urlsData.routes.clients.url);

                    dispatch(openAlert(
                        t(
                            "messages.createMsgs.success",
                            { entity: t("clients.entity") },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: {
                    isSecured: secured,
                    ...sshKeyFile && { sshKeyFile: parseInt(sshKeyFile) },
                    ftpBaseFolder,
                    ftpHost,
                    ftpPassword,
                    ftpPort: parseInt(ftpPort),
                    ftpUsername,
                    imageHeight: parseInt(imageHeight),
                    imageTypeId: imageType,
                    imageWidth: parseInt(imageWidth),
                    name,
                    pdap,
                    pdapName,
                    trackTypeId: trackType,
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        createClient,
        loading,
    };
};

export default useCreateClient;
