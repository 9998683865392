import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { Avatar, Box, Typography } from "@mui/material";
import { Loader, Menu } from "atoms";
import { constantsData, driveManagementData, namesData } from "data";
import { useDeleteDriveManagementUserPermission, useEditDriveManagementUserPermission } from "hooks";
import { PropTypes } from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { theme } from "theme";
import { getDriveManagementUserPermissionsListHandler } from "utils";

const EditDriveManagementUserPermissionView = ({
    data,
    fileId,
    isOrganizer,
    isViewerMode,
    loading,
    mimeType,
}) => {
    const [currentPermissions, setCurrentPermissions] = useState([]);

    const { editDriveManagementUserPermission } = useEditDriveManagementUserPermission(setCurrentPermissions);

    const { deleteDriveManagementUserPermission } = useDeleteDriveManagementUserPermission();

    const { t } = useTranslation();

    const disabledMenuItemsHandler = (
        value,
        index,
        permission,
        inheritedPermission,
    ) => {
        if (inheritedPermission) {
            if (permission === value || index > getDriveManagementUserPermissionsListHandler(
                mimeType,
                isOrganizer,
            ).indexOf(getDriveManagementUserPermissionsListHandler(
                mimeType,
                isOrganizer,
            ).find((item) => item?.value === inheritedPermission))) return true;
        }

        if (permission === value) return true;
    };

    const {
        file: fileConstant,
        folder: folderConstant,
        organizer: organizerConstant,
        owner: ownerConstant,
    } = constantsData;

    return (
        <Box>
            <Typography
                marginBottom={1}
                textAlign="start"
                variant="body1"
            >
                {t("labels.peopleWithAccess")}
            </Typography>
            <Box
                alignItems={loading && "center"}
                background={theme.palette.grey[200]}
                borderRadius="15px"
                display={loading && "flex"}
                height={300}
                justifyContent={loading && "center"}
                overflow="auto"
                padding={1}
                width={{
                    md: 400,
                    xs: 300,
                }}
            >
                {loading ? <Loader withoutFullHeight /> : (
                    <Box>
                        {data?.filter((user) => !user?.deleted)?.map(({
                            displayName,
                            emailAddress,
                            id,
                            permissionDetails,
                            photoLink,
                            role,
                        }) => {
                            const currentPermission = currentPermissions[id] || role;

                            const inheritedPermission = permissionDetails.find((item) => item?.inherited === true)?.role;

                            const ownerOrganizer = driveManagementData.userPermissions.find((item) => item?.value === currentPermission)?.value === ownerConstant || driveManagementData.userPermissions.find((item) => item?.value === currentPermission)?.value === organizerConstant;

                            return (
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="space-between"
                                    key={id}
                                    marginBottom="20px"
                                    width="100%"
                                >
                                    <Box
                                        alignItems="center"
                                        display="flex"
                                        gap={1}
                                    >
                                        <Avatar
                                            alt={t("imageAlternatives.user")}
                                            imgProps={{ loading: "lazy" }}
                                            src={photoLink}
                                            sx={{
                                                height: "40px",
                                                width: "40px",
                                            }}
                                        />
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                        >
                                            <Typography variant="body2">{displayName}</Typography>
                                            <Typography variant="caption">{emailAddress}</Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        alignItems="center"
                                        display="flex"
                                    >
                                        <Menu
                                            label={driveManagementData.userPermissions.find((item) => item?.value === currentPermission)?.label?.[mimeType.includes(folderConstant) ? folderConstant : fileConstant]}
                                            name={namesData.menus.userPermissions}
                                            options={getDriveManagementUserPermissionsListHandler(
                                                mimeType,
                                                isOrganizer,
                                            ).map(({
                                                label,
                                                value,
                                            }, index) => ({
                                                action: () => {
                                                    editDriveManagementUserPermission(
                                                        fileId,
                                                        id,
                                                        value,
                                                    );
                                                },
                                                disabled: !!disabledMenuItemsHandler(
                                                    value,
                                                    index,
                                                    currentPermission,
                                                    inheritedPermission,
                                                ),
                                                label: mimeType.includes(folderConstant) ? label?.folder : label?.file,
                                            })).concat([{
                                                action: () => deleteDriveManagementUserPermission(
                                                    fileId,
                                                    id,
                                                ),
                                                disabled: !!inheritedPermission,
                                                icon: <DeleteForeverOutlinedIcon />,
                                                isDelete: true,
                                                label: t("actions.removeAccess"),
                                            }])}
                                            style={{
                                                cursor: ownerOrganizer || isViewerMode ? "initial" : "pointer",
                                                opacity: (ownerOrganizer || isViewerMode) && 0.5,
                                                userSelect: "none",
                                            }}
                                            withTransparentButton
                                        />
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default EditDriveManagementUserPermissionView;

EditDriveManagementUserPermissionView.propTypes = {
    data: PropTypes.array,
    fileId: PropTypes.string,
    isOrganizer: PropTypes.bool,
    isViewerMode: PropTypes.bool,
    loading: PropTypes.bool,
    mimeType: PropTypes.string,
};
