import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useReleaseLyricsFileSchema = () => {
    const { t } = useTranslation();

    return yup.object({
        lyricsFile: yup.mixed().required(t("messages.inputMsgs.required")),
        track: yup.string().required(t("messages.inputMsgs.required")),
    });
};

export default useReleaseLyricsFileSchema;
