import { constantsData, fileData } from "data";

export const uploadVideoFileHandler = async (importerData) => {
    const {
        formMethods,
        uploadFile,
        video,
    } = importerData;

    await uploadFile(
        video?.file,
        formMethods,
        fileData.names.video,
        null,
        {
            importerData: {
                ...importerData,
                uploadingVideoFile: true,
            },
            videoType: video?.type?.value,
        },
    );
};

export const getVideoTypeHandler = (videoType) => {
    const {
        videoClip: videoClipConstant,
        videoWithoutLogo: videoWithoutLogoConstant,
        withLogo: withLogoConstant,
        withoutLogo: withoutLogoConstant,
    } = constantsData;

    switch (videoType) {
    case withoutLogoConstant:
        return videoWithoutLogoConstant;
    case withLogoConstant:
        return videoClipConstant;
    default:
        return videoType;
    }
};

export const getVideosHandler = (isrc, files) => {
    const videos = [];

    if (isrc) {
        const videosObjs = files?.filter((file) => file?.name?.includes(isrc) && fileData.extensions.video.some((item) => file.name.toLowerCase().includes(item)));

        videosObjs.forEach((video) => {
            const videoType = video?.name?.split("_")[1]?.split(".")?.[0]?.toUpperCase();

            videos.push({
                file: video,
                isrc,
                type: getVideoTypeHandler(videoType),
            });
        });
    }

    return videos;
};
