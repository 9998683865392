import { Blank } from "components";
import { constantsData } from "data";
import { BlankLayout } from "layouts";
import { useTranslation } from "react-i18next";

const OfflineView = () => {
    const { t } = useTranslation();

    return (
        <BlankLayout>
            <Blank
                description={t(
                    "labels.offlineDescription",
                    { defaultValue: constantsData.offlineDescription },
                )}
                title={t(
                    "labels.offlineTitle",
                    { defaultValue: constantsData.offlineTitle },
                )}
                withoutButton
            />
        </BlankLayout>
    );
};

export default OfflineView;
