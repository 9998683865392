import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { constantsData, selectionsData } from "data";
import { getTerritoriesListQuery } from "graph";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { openAlert, setSelection } from "redux/actions";
import { displayErrorHandler } from "utils";

const useTerritoriesList = (
    paginated = true,
    hasAutoCompleteMenu = false,
    searchValue,
) => {
    const [fetched, setFetched] = useState(false);

    const [params] = useSearchParams();

    const pageParam = parseInt(params.get("page")) || 1;

    const limitParam = parseInt(params.get("limit")) || 25;

    const [page, setPage] = useState(pageParam);

    const [limit, setLimit] = useState(limitParam);

    const dispatch = useDispatch();

    const selections = useSelector((state) => state.selectionsReducer);

    const { isAuthenticated } = useAuth0();

    const { t } = useTranslation();

    const { territories: territoriesKey } = selectionsData.keys;

    const {
        data,
        error,
        loading,
        refetch,
    } = useQuery(
        getTerritoriesListQuery,
        {
            onCompleted: ({ listTerritories }) => {
                if (!paginated || hasAutoCompleteMenu) {
                    const fetchedList = listTerritories?.posts?.map(({
                        id,
                        name,
                    }) => ({
                        label: name,
                        value: id,
                    }));

                    dispatch(setSelection({
                        [territoriesKey]: selections?.[territoriesKey]?.length > 0 && hasAutoCompleteMenu ? selections?.[territoriesKey].concat(fetchedList) : fetchedList,
                    }));

                    setFetched(true);
                }
            },
            ...hasAutoCompleteMenu && { notifyOnNetworkStatusChange: true },
            skip: !isAuthenticated,
            variables: {
                orderBy: { name: "asc" },
                ...paginated && {
                    limit,
                    page: page - 1,
                },
                ...searchValue && {
                    where: {
                        name: { contains: searchValue },
                    },
                },
            },
        },

    );

    useEffect(
        () => {
            if (error?.message === constantsData.failedToFetch && !loading) {
                displayErrorHandler(
                    t("messages.networkFailure"),
                    dispatch,
                    openAlert,
                );
            }
        },
        [error, data], // eslint-disable-line
    );

    useEffect(
        () => {
            dispatch(setSelection({ [territoriesKey]: [] }));
        },
        [], // eslint-disable-line
    );

    return {
        count: data?.listTerritories?.meta?.nodeCount,
        data: data?.listTerritories?.posts,
        fetched,
        limit,
        loading,
        page: data?.listTerritories?.meta?.currentPage,
        refetch,
        searchKey: "name",
        setFetched,
        setLimit,
        setPage,
    };
};

export default useTerritoriesList;
