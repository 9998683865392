import { yupResolver } from "@hookform/resolvers/yup";
import { AutoCompleteMenu, Loader } from "atoms";
import { FormContainer } from "components";
import {
    actionsData,
    identifiersData,
    inputData,
    revenueReportFormData,
    selectionsData,
} from "data";
import { useArtistsList, useReleasesList } from "hooks";
import { PropTypes } from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useRevenueReportSchema } from "schemas";
import { StyledTextField } from "styles";
import { renderFormControllerHandler } from "utils";

const RevenueReportForm = ({
    loading,
    onSubmitForm,
    values,
}) => {
    const { t } = useTranslation();

    const selections = useSelector((state) => state.selectionsReducer);

    const schema = useRevenueReportSchema();

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const {
        count: artistsCount,
        fetched: artistsFetched,
        loading: artistsLoading,
        page: artistsPage,
        refetch: artistsRefetch,
        searchKey: artistsSearchKey,
    } = useArtistsList(
        true,
        true,
        {},
        null,
    );

    const {
        count: releasesCount,
        fetched: releasesFetched,
        loading: releasesLoading,
        page: releasesPage,
        refetch: releasesRefetch,
        searchKey: releasesSearchKey,
    } = useReleasesList(
        true,
        true,
        true,
        {},
        null,
        null,
    );

    const {
        control,
        formState: { errors },
    } = formMethods;

    const asyncMenus = {
        artists: {
            count: artistsCount,
            loading: artistsLoading,
            page: artistsPage,
            refetch: artistsRefetch,
            searchKey: artistsSearchKey,
            selectionKey: selectionsData.keys.artists,
        },
        releases: {
            count: releasesCount,
            loading: releasesLoading,
            page: releasesPage,
            refetch: releasesRefetch,
            searchKey: releasesSearchKey,
            selectionKey: selectionsData.keys.releases,
        },
    };

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        type,
        isOptional,
        selectionKey,
        isAsyncMenu,
    ) => {
        if (type === inputData.types.text) {
            return (
                <StyledTextField
                    {...field}
                    error={errors[name]}
                    helperText={errors[name] && errors[name]?.message}
                    id={`${name}_${identifiersData.ids.input}`}
                    label={isOptional ? `${t(`labels.${label}`)} (${t("labels.optional")})` : t(`labels.${label}`)}
                    variant="outlined"
                    fullWidth
                />
            );
        }

        return (
            <AutoCompleteMenu
                asyncMenuData={asyncMenus?.[selectionKey]}
                defaultValue={selections?.[selectionKey]?.find((option) => option.label === values?.[name])}
                errors={errors}
                getRenderedOption={(option) => option.label}
                isAsyncMenu={isAsyncMenu}
                isOptional={isOptional}
                label={label}
                name={name}
                optionLabel={(option) => option.label}
                options={selections?.[selectionKey]}
                onChange={(_, option) => {
                    if (option) field.onChange(option);
                    else field.onChange("");
                }}
            />
        );
    };

    if ((artistsLoading && !artistsFetched) || (releasesLoading && !releasesFetched)) return <Loader withoutFullHeight />;

    return (
        <FormContainer
            action={actionsData.edit}
            formMethods={formMethods}
            loading={loading}
            data={renderFormControllerHandler(
                control,
                revenueReportFormData,
                renderFormControllerChildrenHandler,
            )}
            withoutShadow
            onSubmitForm={onSubmitForm}
        />
    );
};

export default RevenueReportForm;

RevenueReportForm.propTypes = {
    loading: PropTypes.bool,
    onSubmitForm: PropTypes.func,
    values: PropTypes.object,
};
