import { Typography } from "@mui/material";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import XMLViewer from "react-xml-viewer";
import { StyledBox } from "styles";
import { theme } from "theme";

const PreviewClientXmlFile = ({ xmlFile }) => {
    const { t } = useTranslation();

    const xmlTheme = {
        attributeKeyColor: theme.palette.grey[700],
        attributeValueColor: theme.palette.primary.main,
        overflowBreak: true,
    };

    return xmlFile ? (
        <StyledBox>
            <XMLViewer
                theme={xmlTheme}
                xml={xmlFile}
            />
        </StyledBox>
    ) : (
        <Typography
            color="primary"
            varient="h4"
        >
            {t("labels.noXmlFileFound")}
        </Typography>
    );
};

export default PreviewClientXmlFile;

PreviewClientXmlFile.propTypes = { xmlFile: PropTypes.string };
