import inputData from "data/input";

const { text: textInputType } = inputData.types;

const { folderName: folderNameInputName } = inputData.names;

const { folderName: folderNameInputLabel } = inputData.labels;

const driveManagementFolderFormData = [
    {
        col: { xs: 12 },
        label: folderNameInputLabel,
        name: folderNameInputName,
        type: textInputType,
    },
];

export default driveManagementFolderFormData;
