import { yupResolver } from "@hookform/resolvers/yup";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Box, IconButton, Tooltip } from "@mui/material";
import { List } from "atoms";
import { FormContainer } from "components";
import {
    actionsData,
    identifiersData,
    inputData,
    memberFormData,
    namesData,
    urlsData,
} from "data";
import { useMembersList, useRemoveGroupMember } from "hooks";
import { PropTypes } from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useMemberSchema } from "schemas";
import { StyledTextField } from "styles";
import { debounce, renderFormControllerHandler } from "utils";

const MemberForm = ({
    action,
    groupMembers,
    isGroupMember,
    loading,
    onSubmitForm,
    values,
}) => {
    const [emailFieldValue, setEmailFieldValue] = useState("");

    const [searchValue, setSearchValue] = useState("");

    const [addedMember, setAddedMember] = useState({});

    const [filteredMembersData, setFilteredMembersData] = useState([]);

    const [groups, setGroups] = useState(values?.groups || []);

    const { t } = useTranslation();

    const { id: memberId } = useParams();

    const schema = useMemberSchema();

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const {
        data,
        loading: membersListLoading,
    } = useMembersList(
        null,
        searchValue,
        !isGroupMember && action !== actionsData.create,
    );

    const { removeGroupMember } = useRemoveGroupMember(
        null,
        null,
        null,
        null,
        true,
        setGroups,
    );

    const {
        clearErrors,
        control,
        formState: { errors },
        setValue,
    } = formMethods;

    const {
        members: { url: membersRouteUrl },
        url: groupsRouteUrl,
    } = urlsData.routes.groups;

    const {
        button: buttonId,
        input: inputId,
    } = identifiersData.ids;

    const { email: emailInputName } = inputData.names;

    const {
        addToGroup: addToGroupButtonName,
        clearSelectedMember: clearSelectedMemberButtonName,
        removeFromGroup: removeFromGroupButtonName,
    } = namesData.buttons;

    const fetchSearchDataHandler = useCallback(
        debounce(
            (value) => {
                if (value.length > 0) setSearchValue(value);
            },
            1200,
        ),
        [],
    ); /* eslint react-hooks/exhaustive-deps: "off" */

    const changeEmailFieldValueHandler = (e, field) => {
        const { value } = e.target;

        field.onChange(value);

        setEmailFieldValue(value);

        if (isGroupMember && action === actionsData.create) fetchSearchDataHandler(value);
    };

    const addMemberToGroupHandler = (id, email) => {
        setAddedMember({
            email,
            id,
        });

        clearErrors(emailInputName);

        setValue(
            emailInputName,
            email,
        );
    };

    const clearSelectedMemberHandler = () => {
        setAddedMember({});

        clearErrors(emailInputName);

        setValue(
            emailInputName,
            "",
        );
    };

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
    ) => (
        <Box
            alignItems="center"
            display="flex"
        >
            <StyledTextField
                {...field}
                error={errors[name]}
                helperText={errors[name] && errors[name]?.message}
                id={`${name}_${inputId}`}
                label={t(`labels.${label}`)}
                variant="outlined"
                fullWidth
                onChange={(e) => {
                    changeEmailFieldValueHandler(
                        e,
                        field,
                    );
                }}
            />
            <Box minWidth="40px">
                {Object.entries(addedMember).length > 0 && (
                    <Tooltip title={t("actions.clearSelectedMember")}>
                        <IconButton
                            id={`${clearSelectedMemberButtonName}_${buttonId}`}
                            onClick={clearSelectedMemberHandler}
                        >
                            <HighlightOffIcon color="error" />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        </Box>
    );

    useEffect(
        () => {
            if (!emailFieldValue) {
                setAddedMember({});

                setFilteredMembersData([]);
            }
        },
        [emailFieldValue],
    );

    useEffect(
        () => {
            setFilteredMembersData(
                emailFieldValue ? data?.listDriveMembers?.posts?.map(({
                    email,
                    id,
                }) => ({
                    id,
                    name: email,
                })).filter(({ id }) => !groupMembers.some(({ driveMember }) => driveMember?.id === id)) : [],
            );
        },
        [data],
    );

    return (
        <FormContainer
            action={action}
            formMethods={formMethods}
            loading={loading}
            data={renderFormControllerHandler(
                control,
                memberFormData,
                renderFormControllerChildrenHandler,
            )}
            otherData={(
                <>
                    {emailFieldValue
                    && filteredMembersData?.length > 0
                    && isGroupMember
                    && action === actionsData.create
                    && (
                        <Box>
                            <List
                                comparingData={[addedMember]}
                                data={filteredMembersData}
                                loading={membersListLoading}
                                icons={[
                                    {
                                        Icon: <RemoveCircleOutlineIcon />,
                                        action: () => setAddedMember({}),
                                        name: removeFromGroupButtonName,
                                        tooltip: t("actions.removeFromGroup"),
                                    },
                                    {
                                        Icon: <AddCircleIcon />,
                                        action: (id, email) => {
                                            addMemberToGroupHandler(
                                                id,
                                                email,
                                            );
                                        },
                                        name: addToGroupButtonName,
                                        tooltip: t("actions.addToGroup"),
                                    },
                                ]}
                            />
                        </Box>
                    )}
                    {action === actionsData.edit && groups.length > 0 && (
                        <Box maxWidth={400}>
                            <List
                                routeUrl={`${groupsRouteUrl}`}
                                subRouteUrl={membersRouteUrl}
                                title={t("labels.relatedGroups")}
                                data={groups?.map(({ driveGroup }) => ({
                                    entityId: driveGroup.groupMembers.find(({ driveMember }) => driveMember.id === parseInt(memberId))?.id,
                                    id: driveGroup.id,
                                    name: driveGroup.group_name,
                                }))}
                                icons={[
                                    {
                                        Icon: <DeleteForeverOutlinedIcon />,
                                        action: (id) => removeGroupMember(id),
                                        name: removeFromGroupButtonName,
                                        tooltip: t("actions.removeFromGroup"),
                                    },
                                ]}
                                withoutComparingData
                            />
                        </Box>
                    )}
                </>
            )}
            onSubmitForm={onSubmitForm}
        />
    );
};

export default MemberForm;

MemberForm.propTypes = {
    action: PropTypes.string,
    groupMembers: PropTypes.array,
    isGroupMember: PropTypes.bool,
    loading: PropTypes.bool,
    onSubmitForm: PropTypes.func,
    values: PropTypes.object,
};
