import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import {
    Box,
    Button,
    Menu,
    Zoom,
} from "@mui/material";
import { MenuItem, Modal } from "atoms";
import { DriveManagementFolderForm } from "components";
import {
    actionsData,
    identifiersData,
    inputData,
    namesData,
} from "data";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const DriveManagementMenu = ({
    createNewFolderAction,
    enableUploadFiles,
    uploadFilesAction,
}) => {
    const [modalData, setModalData] = useState({
        body: null,
        hasComponent: true,
        id: null,
        open: false,
        title: "",
    });

    const [anchorEl, setAnchorEl] = useState(null);

    const { t } = useTranslation();

    const {
        driveId,
        folderId,
    } = useParams();

    const open = Boolean(anchorEl);

    const {
        button: buttonId,
        input: inputId,
        menu: menuId,
        option: optionId,
    } = identifiersData.ids;

    const {
        names: { file: fileInputName },
        types: { file: fileInputType },
    } = inputData;

    const {
        menus: { driveManagement: driveManagementMenuName },
        options: {
            addNewFile: addNewFileOptionName,
            addNewFolder: addNewFolderOptionName,
        },
    } = namesData;

    const openMenuHandler = (event) => setAnchorEl(event.currentTarget);

    const closeMenuHandler = () => setAnchorEl(null);

    const addFolderHandler = ({ folderName }) => {
        createNewFolderAction(
            folderName,
            folderId || driveId,
        );

        setModalData({
            ...modalData,
            open: false,
        });
    };

    const clickAddNewFileHandler = (e) => {
        uploadFilesAction(e.target.files);

        closeMenuHandler();
    };

    const clickAddNewFolderButtonHandler = () => {
        setModalData({
            body: (
                <DriveManagementFolderForm
                    action={actionsData.add}
                    setModalData={setModalData}
                    values={{}}
                    onSubmitForm={addFolderHandler}
                />
            ),
            hasComponent: true,
            open: true,
            title: t("actions.addNewFolder"),
        });

        closeMenuHandler();
    };

    return (
        <Box>
            <Modal
                modalData={modalData}
                setModalData={setModalData}
            />
            <Button
                id={`${driveManagementMenuName}_${menuId}_${buttonId}`}
                size="large"
                variant="outlined"
                endIcon={(
                    <KeyboardArrowDownIcon
                        sx={{
                            transform: open && "rotate(180deg)",
                            transition: "ease-in-out .2s",
                        }}
                    />
                )}
                onClick={openMenuHandler}
            >
                {t("labels.sharedDrive")}
            </Button>
            <Menu
                TransitionComponent={Zoom}
                anchorEl={anchorEl}
                open={open}
                onClose={closeMenuHandler}
            >
                <Box id={`${driveManagementMenuName}_${menuId}`}>
                    <MenuItem
                        icon={<CreateNewFolderOutlinedIcon />}
                        id={`${driveManagementMenuName}_${menuId}_${addNewFolderOptionName}_${optionId}`}
                        label={t("actions.addNewFolder")}
                        style={{ gap: 1 }}
                        onClick={clickAddNewFolderButtonHandler}
                    />
                    <label htmlFor={`${fileInputName}_${inputId}`}>
                        <MenuItem
                            disabled={!enableUploadFiles}
                            icon={<UploadFileOutlinedIcon />}
                            id={`${driveManagementMenuName}_${menuId}_${addNewFileOptionName}_${optionId}`}
                            label={t("actions.addNewFile")}
                            style={{
                                alignItems: "center",
                                cursor: "pointer",
                                display: "flex",
                                gap: 1,
                            }}
                        />
                        <input
                            id={`${fileInputName}_${inputId}`}
                            style={{ display: "none" }}
                            type={fileInputType}
                            multiple
                            onChange={(e) => clickAddNewFileHandler(e)}
                        />
                    </label>
                </Box>
            </Menu>
        </Box>
    );
};

export default DriveManagementMenu;

DriveManagementMenu.propTypes = {
    createNewFolderAction: PropTypes.func,
    enableUploadFiles: PropTypes.bool,
    uploadFilesAction: PropTypes.func,
};
