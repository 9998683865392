import { useMutation } from "@apollo/client";
import { constantsData, importerConfigData } from "data";
import { createImageMutation } from "graph";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useCreateImage = (withoutAlert) => {
    const [controller, setController] = useState(null);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [createImageAction] = useMutation(createImageMutation);

    const createImage = async (
        fileId,
        formMethods,
        fieldName,
        importerData,
        setFilesData,
    ) => {
        try {
            const newController = new AbortController();

            setController(newController);

            importerData?.setCurrentStep(importerConfigData.steps.creatingReleaseImage);

            await createImageAction({
                context: {
                    fetchOptions: { signal: newController.signal },
                },
                variables: {
                    data: {
                        file: {
                            connect: { id: fileId },
                        },
                    },
                },
            }).then(({ data }) => {
                formMethods.setValue(
                    fieldName,
                    data.createOneImage.id,
                );

                setFilesData((prev) => ({
                    ...prev,
                    [fieldName]: {
                        ...prev[fieldName],
                        imageId: data.createOneImage.id,
                    },
                }));

                if (importerData) {
                    if (importerData?.uploadingReleaseImage) {
                        importerData?.setStatus((prev) => ({
                            ...prev,
                            release: {
                                ...prev.release,
                                loading: true,
                            },
                            releaseImage: {
                                ...prev.releaseImage,
                                loading: false,
                                success: true,
                            },
                        }));
                    }
                }
            });
        } catch (err) {
            if (!withoutAlert) {
                displayErrorHandler(
                    err.message === constantsData.failedToFetch ? t("messages.networkFailure") : `${t(
                        "messages.createMsgs.fail",
                        { entity: t("labels.image") },
                    )}, ${err.message}`,
                    dispatch,
                    openAlert,
                );
            }

            if (importerData) {
                if (importerData?.uploadingReleaseImage) {
                    dispatch(importerData?.setInvalidImporterRelease(
                        {
                            release: {
                                data: importerData?.release,
                                message: err.message === constantsData.failedToFetch ? t("messages.networkFailure") : `${t("messages.importerMsgs.createReleaseImageFailed")}, ${err.message}`,
                            },
                            ...err.message === constantsData.failedToFetch && { isNetworkFailed: true },
                            originalData: importerData?.originalData,
                        },
                    ));

                    importerData?.setStatus((prev) => ({
                        ...prev,
                        releaseImage: {
                            ...prev.releaseImage,
                            loading: false,
                            success: false,
                        },
                    }));
                }
            }
        } finally {
            setController(null);

            if (importerData) importerData?.setCurrentStep(null);
        }
    };

    return {
        cancelRequest: () => controller && controller.abort(),
        createImage,
    };
};

export default useCreateImage;
