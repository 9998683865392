import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Drawer as MuiDrawer,
    IconButton,
    Tooltip,
} from "@mui/material";
import { PageHead } from "components";
import { identifiersData, namesData } from "data";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";

const Drawer = ({
    children,
    closeAction,
    isMini,
    open,
    title,
}) => {
    const { t } = useTranslation();

    return (
        <MuiDrawer
            anchor="right"
            open={open}
            PaperProps={{
                sx: {
                    top: {
                        sm: 0,
                        xs: "-8px",
                    },
                },
            }}
            onClose={closeAction}
        >
            <Box
                position="absolute"
                right={5}
                top={5}
            >
                <Tooltip title={t("actions.close")}>
                    <IconButton
                        id={`${namesData.buttons.close}_${identifiersData.ids.button}`}
                        onClick={closeAction}
                    >
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Box
                sx={{
                    paddingBottom: 3,
                    width: {
                        sm: isMini ? 330 : 600,
                        xs: 300,
                    },
                }}
            >
                <Box
                    mt={2}
                    mx={2}
                >
                    <PageHead
                        title={title}
                        titleTag="h5"
                    />
                </Box>
                {children}
            </Box>
        </MuiDrawer>
    );
};

export default Drawer;

Drawer.propTypes = {
    children: PropTypes.node,
    closeAction: PropTypes.func,
    isMini: PropTypes.bool,
    open: PropTypes.bool,
    title: PropTypes.string,
};
