import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { constantsData, permissionsData, selectionsData } from "data";
import {
    getBatchNamesSelectionListQuery,
    getClientXmlFileTreeSelectionListQuery,
    getCountriesSelectionListQuery,
    getDistributionFileTypesSelectionListQuery,
    getDocumentTypesSelectionListQuery,
    getGenresSelectionListQuery,
    getImageTypesSelectionListQuery,
    getReleaseTypesSelectionListQuery,
    getRevenueReportsSystemFieldsSelectionListQuery,
    getRightTypesSelectionListQuery,
    getRolesSelectionListQuery,
    getTrackTypesSelectionListQuery,
    getVideoTypesSelectionListQuery,
    getXmlFileTypesSelectionListQuery,
} from "graph";
import { useDispatch, useSelector } from "react-redux";
import { setSelection } from "redux/actions";
import { canDo } from "utils";

const {
    batchNames: batchNamesKey,
    countries: countriesKey,
    distributionFileTypes: distributionFileTypesKey,
    documentTypes: documentTypesKey,
    genres: genresKey,
    imageTypes: imageTypesKey,
    releaseTypes: releaseTypesKey,
    revenueReportsSystemFields: revenueReportsSystemFieldsKey,
    rightTypes: rightTypesKey,
    roles: rolesKey,
    trackTypes: trackTypesKey,
    videoTypes: videoTypesKey,
    xmlFileTree: xmlFileTreeKey,
    xmlFileTypes: xmlFileTypesKey,
} = selectionsData.keys;

export const useCountriesSelectionList = () => {
    const selections = useSelector((state) => state.selectionsReducer);

    const dispatch = useDispatch();

    const { isAuthenticated } = useAuth0();

    const { loading } = useQuery(
        getCountriesSelectionListQuery,
        {
            onCompleted: ({ countries }) => {
                dispatch(setSelection({
                    [countriesKey]: countries.map(({
                        en_name: name,
                        id,
                        territoryCode: code,
                    }) => ({
                        code,
                        label: name,
                        value: id,
                    })),
                }));
            },
            skip: !isAuthenticated || !!selections[countriesKey],
        },
    );

    return { loading };
};

export const useGenresSelectionList = () => {
    const selections = useSelector((state) => state.selectionsReducer);

    const dispatch = useDispatch();

    const { isAuthenticated } = useAuth0();

    const { loading } = useQuery(
        getGenresSelectionListQuery,
        {
            onCompleted: ({ genres }) => {
                dispatch(setSelection({
                    [genresKey]: genres.map(({
                        id,
                        name,
                    }) => ({
                        label: name,
                        value: id,
                    })),
                }));
            },
            skip: !isAuthenticated || !!selections[genresKey],
        },
    );

    return { loading };
};

export const useRolesSelectionList = () => {
    const selections = useSelector((state) => state.selectionsReducer);

    const dispatch = useDispatch();

    const {
        isAuthenticated,
        user,
    } = useAuth0();

    const { accountRead: accountReadPermission } = permissionsData.permissions.keys;

    const { loading } = useQuery(
        getRolesSelectionListQuery,
        {
            onCompleted: ({ listRoles }) => {
                const roleKeys = listRoles.reduce(
                    (acc, value) => ({
                        ...acc,
                        [value.name]: value.name,
                    }),
                    {},
                );

                localStorage.setItem( // eslint-disable-line
                    constantsData.roleKeys,
                    JSON.stringify(roleKeys),
                );

                const roleIds = listRoles.reduce(
                    (acc, value) => ({
                        ...acc,
                        [value.id]: value.name,
                    }),
                    {},
                );

                dispatch(setSelection({
                    [rolesKey]: {
                        ids: roleIds,
                        keys: roleKeys,
                        list: listRoles.map(({
                            id,
                            name,
                        }) => ({
                            label: name,
                            value: id,
                        })),
                    },
                }));
            },
            skip: !isAuthenticated || !!selections[rolesKey] || !canDo(
                user,
                [accountReadPermission],
            ),
        },
    );

    return { loading };
};

export const useTrackTypesSelectionList = () => {
    const selections = useSelector((state) => state.selectionsReducer);

    const dispatch = useDispatch();

    const { isAuthenticated } = useAuth0();

    const { loading } = useQuery(
        getTrackTypesSelectionListQuery,
        {
            onCompleted: ({ trackTypes }) => {
                dispatch(setSelection({
                    [trackTypesKey]: trackTypes.map(({
                        id,
                        name,
                    }) => ({
                        label: name,
                        value: id,
                    })),
                }));
            },
            skip: !isAuthenticated || !!selections[trackTypesKey],
        },
    );

    return { loading };
};

export const useDistributionFileTypesSelectionList = () => {
    const selections = useSelector((state) => state.selectionsReducer);

    const dispatch = useDispatch();

    const { isAuthenticated } = useAuth0();

    const { loading } = useQuery(
        getDistributionFileTypesSelectionListQuery,
        {
            onCompleted: ({ distributeTypes }) => {
                dispatch(setSelection({
                    [distributionFileTypesKey]: distributeTypes.map(({
                        id,
                        name,
                    }) => ({
                        label: name,
                        value: id,
                    })),
                }));
            },
            skip: !isAuthenticated || !!selections[distributionFileTypesKey],
        },
    );

    return { loading };
};

export const useDocumentTypesSelectionList = () => {
    const selections = useSelector((state) => state.selectionsReducer);

    const dispatch = useDispatch();

    const { isAuthenticated } = useAuth0();

    const { loading } = useQuery(
        getDocumentTypesSelectionListQuery,
        {
            onCompleted: ({ documentTypes }) => {
                dispatch(setSelection({
                    [documentTypesKey]: documentTypes.map(({
                        id,
                        name,
                    }) => ({
                        label: name,
                        value: id,
                    })),
                }));
            },
            skip: !isAuthenticated || !!selections[documentTypesKey],
        },
    );

    return { loading };
};

export const useImageTypesSelectionList = () => {
    const selections = useSelector((state) => state.selectionsReducer);

    const dispatch = useDispatch();

    const { isAuthenticated } = useAuth0();

    const { loading } = useQuery(
        getImageTypesSelectionListQuery,
        {
            onCompleted: ({ imageTypes }) => {
                dispatch(setSelection({
                    [imageTypesKey]: imageTypes.map(({
                        id,
                        name,
                    }) => ({
                        label: name,
                        value: id,
                    })),
                }));
            },
            skip: !isAuthenticated || !!selections[imageTypesKey],
        },
    );

    return { loading };
};

export const useReleaseTypesSelectionList = () => {
    const selections = useSelector((state) => state.selectionsReducer);

    const dispatch = useDispatch();

    const { isAuthenticated } = useAuth0();

    const { loading } = useQuery(
        getReleaseTypesSelectionListQuery,
        {
            onCompleted: ({ releaseTypes }) => {
                dispatch(setSelection({
                    [releaseTypesKey]: releaseTypes.map(({
                        id,
                        name,
                    }) => ({
                        label: name,
                        value: id,
                    })),
                }));
            },
            skip: !isAuthenticated || !!selections[releaseTypesKey],
        },
    );

    return { loading };
};

export const useRightTypesSelectionList = () => {
    const selections = useSelector((state) => state.selectionsReducer);

    const dispatch = useDispatch();

    const { isAuthenticated } = useAuth0();

    const { loading } = useQuery(
        getRightTypesSelectionListQuery,
        {
            onCompleted: ({ rightTypes }) => {
                dispatch(setSelection({
                    [rightTypesKey]: rightTypes.map(({
                        id,
                        name,
                    }) => ({
                        label: name,
                        value: id,
                    })),
                }));
            },
            skip: !isAuthenticated || !!selections[rightTypesKey],
        },
    );

    return { loading };
};

export const useVideoTypesSelectionList = () => {
    const selections = useSelector((state) => state.selectionsReducer);

    const dispatch = useDispatch();

    const { isAuthenticated } = useAuth0();

    const { loading } = useQuery(
        getVideoTypesSelectionListQuery,
        {
            onCompleted: ({ videoTypes }) => {
                dispatch(setSelection({
                    [videoTypesKey]: videoTypes.map(({
                        id,
                        name,
                    }) => ({
                        label: name,
                        value: id,
                    })),
                }));
            },
            skip: !isAuthenticated || !!selections[videoTypesKey],
        },
    );

    return { loading };
};

export const useXmlFileTypesSelectionList = () => {
    const selections = useSelector((state) => state.selectionsReducer);

    const dispatch = useDispatch();

    const { isAuthenticated } = useAuth0();

    const { loading } = useQuery(
        getXmlFileTypesSelectionListQuery,
        {
            onCompleted: ({ downloadXmlTypes }) => {
                dispatch(setSelection({
                    [xmlFileTypesKey]: downloadXmlTypes.map(({
                        id,
                        name,
                    }) => ({
                        label: name,
                        value: id,
                    })),
                }));
            },
            skip: !isAuthenticated || !!selections[xmlFileTypesKey],
        },
    );

    return { loading };
};

export const useBatchNamesSelectionList = () => {
    const selections = useSelector((state) => state.selectionsReducer);

    const dispatch = useDispatch();

    const { isAuthenticated } = useAuth0();

    const { loading } = useQuery(
        getBatchNamesSelectionListQuery,
        {
            onCompleted: ({ distributeBatchNames }) => {
                dispatch(setSelection({
                    [batchNamesKey]: distributeBatchNames.map(({
                        id,
                        name,
                    }) => ({
                        label: name,
                        value: id,
                    })),
                }));
            },
            skip: !isAuthenticated || !!selections[batchNamesKey],
        },
    );

    return { loading };
};

export const useClientXmlFileTreeSelectionList = () => {
    const selections = useSelector((state) => state.selectionsReducer);

    const dispatch = useDispatch();

    const { isAuthenticated } = useAuth0();

    const { loading } = useQuery(
        getClientXmlFileTreeSelectionListQuery,
        {
            onCompleted: ({ getSelectionsMenuOptions }) => {
                dispatch(setSelection({
                    [xmlFileTreeKey]: getSelectionsMenuOptions.selections,
                }));
            },
            skip: !isAuthenticated || !!selections[xmlFileTreeKey],
        },
    );

    return { loading };
};

export const useRevenueReportsSystemFieldsSelectionList = () => {
    const selections = useSelector((state) => state.selectionsReducer);

    const dispatch = useDispatch();

    const { isAuthenticated } = useAuth0();

    const { loading } = useQuery(
        getRevenueReportsSystemFieldsSelectionListQuery,
        {
            onCompleted: ({ getRevenueMappingTemplate }) => dispatch(setSelection({
                [revenueReportsSystemFieldsKey]: getRevenueMappingTemplate.posts.map(({
                    value,
                }) => ({
                    label: value,
                    value,
                })),
            })),
            skip: !isAuthenticated || !!selections[revenueReportsSystemFieldsKey],
        },
    );

    return { loading };
};
