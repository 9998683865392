import fileData from "data/file";
import inputData from "data/input";
import selectionsData from "data/selections";

const { countries: countriesSelectionKey } = selectionsData.keys;

const { image: imageFileName } = fileData.names;

const {
    autoComplete: autoCompleteInputType,
    file: fileInputType,
    text: textInputType,
} = inputData.types;

const {
    code: codeInputName,
    isni: isniInputName,
    nameAr: nameArInputName,
    nameEn: nameEnInputName,
    nationality: nationalityInputName,
} = inputData.names;

const {
    code: codeInputLabel,
    image: imageFileInputLabel,
    isni: isniInputLabel,
    nameAr: nameArInputLabel,
    nameEn: nameEnInputLabel,
    nationality: nationalityInputLabel,
} = inputData.labels;

const artistFormData = [
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: nameEnInputLabel,
        name: nameEnInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        hasOffset: true,
        label: nameArInputLabel,
        name: nameArInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        isOptional: true,
        label: isniInputLabel,
        name: isniInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        hasOffset: true,
        label: codeInputLabel,
        name: codeInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: nationalityInputLabel,
        name: nationalityInputName,
        selectionKey: countriesSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: imageFileInputLabel,
        name: imageFileName,
        type: fileInputType,
    },
];

export default artistFormData;
