import {
    CREATE_TRACK,
    DELETE_TRACK,
    SET_CURRENT_PLAYED_TRACK,
    TOGGLE_TRACK_DRAWER,
} from "redux/types";

export const createTrack = (track) => ({
    payload: { track },
    type: CREATE_TRACK,
});

export const deleteTracks = () => ({ type: DELETE_TRACK });

export const setCurrentPlayedTrack = (track) => ({
    payload: { track },
    type: SET_CURRENT_PLAYED_TRACK,
});

export const toggleTrackDrawer = (open) => ({
    payload: { open },
    type: TOGGLE_TRACK_DRAWER,
});
