import { useMutation } from "@apollo/client";
import { DownloadBox } from "atoms";
import {
    constantsData,
    identifiersData,
    namesData,
    statusTypesData,
} from "data";
import { downloadReleaseClientXmlFileMutation } from "graph";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { openAlert } from "redux/actions";
import { displayErrorHandler, getUniqueRandomId } from "utils";

const useDownloadReleaseClientXmlFile = (releaseId) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [downloadReleaseClientXmlFileAction, { loading }] = useMutation(downloadReleaseClientXmlFileMutation);

    const {
        downloadReleaseClientXmlFilePending: downloadReleaseClientXmlFilePendingToastName,
        downloadReleaseClientXmlFileSuccess: downloadReleaseClientXmlFileSuccessToastName,
    } = namesData.toasts;

    const { toast: toastId } = identifiersData.ids;

    const downloadReleaseClientXmlFile = async ({
        client,
        fileType,
        territory,
        xmlType,
    }) => {
        try {
            await downloadReleaseClientXmlFileAction(
                {
                    onCompleted: ({ downloadXML }) => {
                        const randomId = getUniqueRandomId();

                        toast.success(
                            () => (
                                <DownloadBox
                                    id={`${downloadReleaseClientXmlFileSuccessToastName}_${toastId}_${randomId}`}
                                    link={downloadXML}
                                    message={t(
                                        "messages.downloadMsgs.download",
                                        { entity: `${t("releases.entity")} ${t("labels.xmlFile")}` },
                                    )}
                                />
                            ),
                            {
                                closeButton: true,
                                toastId: `${downloadReleaseClientXmlFileSuccessToastName}_${toastId}_${randomId}`,
                            },
                        );
                    },
                    variables: {
                        clientId: client,
                        distributionFileTypeId: fileType,
                        releaseId,
                        territoryId: territory,
                        xmlTypeId: xmlType,
                    },
                },
            );

            dispatch(openAlert(
                t(
                    "messages.downloadMsgs.success",
                    { entity: `${t("releases.entity")} ${t("labels.xmlFile")}` },
                ),
                statusTypesData.success,
            ));
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        } finally {
            toast.dismiss(`${downloadReleaseClientXmlFilePendingToastName}_${toastId}`);
        }
    };

    useEffect(
        () => {
            if (loading) {
                toast.info(
                    () => t(
                        "messages.downloadMsgs.pending",
                        { entity: `${t("releases.entity")} ${t("labels.xmlFile")}` },
                    ),
                    { toastId: `${downloadReleaseClientXmlFilePendingToastName}_${toastId}` },
                );
            }
        },
        [loading], //eslint-disable-line
    );

    return {
        downloadReleaseClientXmlFile,
        loading,
    };
};

export default useDownloadReleaseClientXmlFile;
