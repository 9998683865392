const namesData = {
    buttons: {
        addAll: "addAll",
        addField: "addField",
        addLabel: "addLabel",
        addNewFile: "addNewFile",
        addNewFolder: "addNewFolder",
        addToGroup: "addToGroup",
        addToRelease: "addToRelease",
        allDrafts: "allDrafts",
        apply: "apply",
        back: "back",
        backToHome: "backToHome",
        cancel: "cancel",
        clear: "clear",
        clearSelectedMember: "clearSelectedMember",
        close: "close",
        closeAll: "closeAll",
        closeAudioPlayer: "closeAudioPlayer",
        closeFilters: "closeFilters",
        closeSidebar: "closeSidebar",
        closeVideoPlayer: "closeVideoPlayer",
        completed: "completed",
        copy: "copy",
        create: "create",
        dashboard: "dashboard",
        delete: "delete",
        deleteReleaseOwnership: "deleteReleaseOwnership",
        distribute: "distribute",
        download: "download",
        downloadTrack: "downloadTrack",
        editXmlFile: "editXmlFile",
        hidePassword: "hidePassword",
        openFilters: "openFilters",
        openSidebar: "openSidebar",
        pauseVideoPlayer: "pauseVideoPlayer",
        playVideoPlayer: "playVideoPlayer",
        previewXmlFile: "previewXmlFile",
        remove: "remove",
        removeAll: "removeAll",
        removeFromGroup: "removeFromGroup",
        resetAll: "resetAll",
        retry: "retry",
        retryAll: "retryAll",
        save: "save",
        search: "search",
        select: "select",
        selectAll: "selectAll",
        showPassword: "showPassword",
        startImport: "startImport",
        stop: "stop",
        stopAll: "stopAll",
        submit: "submit",
        unSelectAll: "unSelectAll",
        uploadReleases: "uploadReleases",
        viewAllGroups: "viewAllGroups",
        viewAllMembers: "viewAllMembers",
    },
    links: {
        artist: "artist",
        primaryArtist: "primaryArtist",
        secondaryArtist: "secondaryArtist",
        track: "track",
        viewAllArtists: "viewAllArtists",
        viewAllReleases: "viewAllReleases",
    },
    menus: {
        categoryFilter: "categoryFilter",
        dashboard: "dashboard",
        dashboardSearch: "dashboardSearch",
        details: "details",
        driveManagement: "driveManagement",
        driveManagementSearch: "driveManagementSearch",
        exportAllData: "exportAllData",
        exportData: "exportData",
        exportFilteredData: "exportFilteredData",
        groupsSearch: "groupsSearch",
        import: "import",
        table: "table",
        user: "user",
        userPermissions: "userPermissions",
        xmlFile: "xmlFile",
    },
    options: {
        addLabel: "addLabel",
        addNewFile: "addNewFile",
        addNewFolder: "addNewFolder",
        delete: "delete",
        download: "download",
        downloadSoundRecording: "downloadSoundRecording",
        downloadTrack: "downloadTrack",
        downloadXmlFile: "downloadXmlFile",
        driveManagement: "driveManagement",
        edit: "edit",
        groups: "groups",
        playSoundRecording: "playSoundRecording",
        playTrack: "playTrack",
        releases: "releases",
        removeFromGroup: "removeFromGroup",
        removeFromRelease: "removeFromRelease",
        retryDistribution: "retryDistribution",
        revenueReports: "revenueReports",
        share: "share",
        shareLink: "shareLink",
        trash: "trash",
        viewInDrive: "viewInDrive",
    },
    sliders: {
        audioPlayerDuration: "audioPlayerDuration",
        audioPlayerVolume: "audioPlayerVolume",
        audioRange: "audioRange",
        filterRange: "filterRange",
        videoPlayer: "videoPlayer",
    },
    tabs: {
        clientForm: "clientForm",
        releaseDetails: "releaseDetails",
        releaseForm: "releaseForm",
        releaseTracks: "releaseTracks",
        releasesImporter: "releasesImporter",
        trackDetails: "trackDetails",
    },
    toasts: {
        addReleaseTrackPending: "addReleaseTrackPending",
        createClientFoldersStructureFolderPending: "createClientFoldersStructureFolderPending",
        createDriveManagementFolderPending: "createDriveManagementFolderPending",
        deleteArtistPending: "deleteArtistPending",
        deleteClientFoldersStructureFolderPending: "deleteClientFoldersStructureFolderPending",
        deleteClientPending: "deleteClientPending",
        deleteClientRevenueReportTemplatePending: "deleteClientRevenueReportTemplatePending",
        deleteDistributionPending: "deleteDistributionPending",
        deleteDriveLinkPending: "deleteDriveLinkPending",
        deleteDriveManagementFilePending: "deleteDriveManagementFilePending",
        deleteDriveManagementFolderPending: "deleteDriveManagementFolderPending",
        deleteDriveManagementLabelPending: "deleteDriveManagementLabelPending",
        deleteDriveManagementUserPermissionPending: "deleteDriveManagementUserPermissionPending",
        deleteFilePending: "deleteFilePending",
        deleteGroupPending: "deleteGroupPending",
        deleteMemberPending: "deleteMemberPending",
        deleteReleaseClientLinkPending: "deleteReleaseClientLinkPending",
        deleteReleaseDocumentPending: "deleteReleaseDocumentPending",
        deleteReleaseLyricsFilePending: "deleteReleaseLyricsFilePending",
        deleteReleaseOwnershipPending: "deleteReleaseOwnershipPending",
        deleteReleasePending: "deleteReleasePending",
        deleteReleaseSoundRecordingPending: "deleteReleaseSoundRecordingPending",
        deleteRevenueReportPending: "deleteRevenueReportPending",
        deleteTerritoryPending: "deleteTerritoryPending",
        deleteTrackPending: "deleteTrackPending",
        deleteUserPending: "deleteUserPending",
        deleteVideoCutPending: "deleteVideoCutPending",
        deleteVideoPending: "deleteVideoPending",
        downloadDriveManagementFilePending: "downloadDriveManagementFilePending",
        downloadReleaseClientXmlFilePending: "downloadReleaseClientXmlFilePending",
        downloadReleaseClientXmlFileSuccess: "downloadReleaseClientXmlFileSuccess",
        downloadReleasesContentPending: "downloadReleasesContentPending",
        downloadTracksContentPending: "downloadTracksContentPending",
        downloadTracksContentSuccess: "downloadTracksContentSuccess",
        editDriveManagementLabelPending: "editDriveManagementLabelPending",
        editDriveManagementUserPermissionPending: "editDriveManagementUserPermissionPending",
        exportAllTracksPending: "exportAllTracksPending",
        exportAllTracksSuccess: "exportAllTracksSuccess",
        exportArtistsPending: "exportArtistsPending",
        exportArtistsSuccess: "exportArtistsSuccess",
        exportFilteredRevenueReportsPending: "exportFilteredRevenueReportsPending",
        exportFilteredRevenueReportsSuccess: "exportFilteredRevenueReportsSuccess",
        exportReleasesPending: "exportReleasesPending",
        exportReleasesSuccess: "exportReleasesSuccess",
        exportRevenueReportsPending: "exportRevenueReportsPending",
        exportRevenueReportsSuccess: "exportRevenueReportsSuccess",
        exportTracksPending: "exportTracksPending",
        exportTracksSuccess: "exportTracksSuccess",
        exportVideosPending: "exportVideosPending",
        exportVideosSuccess: "exportVideosSuccess",
        getReleaseShareLinkPending: "getReleaseShareLinkPending",
        getTrackShareLinkPending: "getTrackShareLinkPending",
        grantDriveManagementUserPermissionPending: "grantDriveManagementUserPermissionPending",
        removeGroupMemberPending: "removeGroupMemberPending",
        removeReleaseTrackPending: "removeReleaseTrackPending",
        retryFailedDistribution: "retryFailedDistribution",
        retryFailedDistributionPending: "retryFailedDistributionPending",
        trashDriveManagementFilePending: "trashDriveManagementFilePending",
        trashDriveManagementFolderPending: "trashDriveManagementFolderPending",
        uploadFilePending: "uploadFilePending",
    },
};

export default namesData;
