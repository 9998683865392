import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";

import alertReducer from "./alert";
import clientsReducer from "./clients";
import distributionsReducer from "./distributions";
import driveLinksReducer from "./drive-links";
import driveManagementReducer from "./drive-management";
import filtersReducer from "./filters";
import loadingReducer from "./loading";
import networkReducer from "./network";
import releasesReducer from "./releases";
import revenueReportsReducer from "./revenue-reports";
import searchReducer from "./search";
import selectionsReducer from "./selections";
import sendingReducer from "./sending";
import sidebarActiveTabReducer from "./sidebar-active-tab";
import tracksReducer from "./tracks";

export const initialState = {
    key: "root",
    storage,
    whitelist: [],
};

export const rootReducer = combineReducers({
    alertReducer,
    clientsReducer,
    distributionsReducer,
    driveLinksReducer,
    driveManagementReducer,
    filtersReducer,
    loadingReducer,
    networkReducer,
    releasesReducer,
    revenueReportsReducer,
    searchReducer,
    selectionsReducer,
    sendingReducer,
    sidebarActiveTabReducer,
    tracksReducer,
});
