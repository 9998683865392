import { useAuth0 } from "@auth0/auth0-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid } from "@mui/material";
import {
    AutoCompleteMenu,
    FileLink,
    SkeletonLoader,
    UploadInput,
} from "atoms";
import { FormContainer } from "components";
import {
    fileData,
    identifiersData,
    inputData,
    permissionsData,
    releaseLyricsFileFormData,
} from "data";
import { useDeleteReleaseLyricsFile, useUploadFile } from "hooks";
import { PropTypes } from "prop-types";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useReleaseLyricsFileSchema } from "schemas";
import { canDo, renderFormControllerHandler, setUploadInputPreviousValueHandler } from "utils";

const ReleaseLyricsFileForm = ({
    setTrackId,
    trackId,
    trackLoading,
    values,
}) => {
    const { t } = useTranslation();

    const tracksList = useSelector((state) => state.releasesReducer)?.releaseTracks;

    const { user } = useAuth0();

    const schema = useReleaseLyricsFileSchema();

    const {
        filesData,
        filesLoading,
        uploadFile,
    } = useUploadFile(trackId);

    const { deleteReleaseLyricsFile } = useDeleteReleaseLyricsFile(trackId);

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const {
        control,
        formState: { errors },
        setError,
        setValue,
        watch,
    } = formMethods;

    const {
        mediaCreate: mediaCreatePermission,
        mediaDelete: mediaDeletePermission,
        mediaRead: mediaReadPermission,
    } = permissionsData.permissions.keys;

    const allowedPermissions = [mediaCreatePermission, mediaReadPermission];

    const tracksOptions = tracksList?.map(({
        id,
        title,
    }) => ({
        label: title,
        value: id,
    }));

    const {
        file: fileInputName,
        track: trackInputName,
    } = inputData.names;

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        type,
        isOptional,
    ) => {
        if (type === inputData.types.file) {
            return (
                <Box mb={1}>
                    <UploadInput
                        accept={fileData.acceptedExtensions.document}
                        color={errors[name]?.message ? "error" : "primary"}
                        error={errors[name]?.message}
                        field={field}
                        hasError={!!errors[name]?.message}
                        label={t(`labels.${label}`)}
                        loading={filesLoading?.[name]}
                        disabled={!canDo(
                            user,
                            allowedPermissions,
                        ) || !trackId}
                        onChange={(e) => {
                            uploadFile(
                                e.target.files[0],
                                formMethods,
                                name,
                            );
                        }}
                    />
                </Box>
            );
        }

        return (
            <AutoCompleteMenu
                defaultValue={{}}
                errors={errors}
                getRenderedOption={(option) => option?.label}
                isOptional={isOptional}
                label={label}
                name={name}
                optionLabel={(option) => option?.label || ""}
                options={tracksOptions}
                onChange={(_, option) => {
                    if (option) field.onChange(option.value);
                    else field.onChange("");
                }}
            />
        );
    };

    useEffect(
        () => {
            setTrackId(tracksList?.find(({ id }) => id === watch(trackInputName))?.id);
        },
        [watch(trackInputName)], // eslint-disable-line
    );

    useEffect(
        () => {
            setUploadInputPreviousValueHandler(
                watch,
                setError,
                setValue,
                filesData,
                fileData.names.lyrics,
            );
        },
        [watch(fileData.names.lyrics)], // eslint-disable-line
    );

    return (
        <FormContainer
            formMethods={formMethods}
            hasSubmitButton={false}
            data={renderFormControllerHandler(
                control,
                releaseLyricsFileFormData,
                renderFormControllerChildrenHandler,
            )}
            otherData={(
                <Grid
                    spacing={2}
                    container
                >
                    <Grid
                        md={4}
                        xs={12}
                        item
                    >
                        {trackLoading ? <SkeletonLoader /> : values?.lyricsFiles?.length > 0 && values?.lyricsFiles?.map(({
                            downloadUri,
                            id,
                            name: fileName,
                        }, index) => (
                            <FileLink
                                key={id}
                                link={downloadUri}
                                name={fileName}
                                rowId={index + 1}
                                deleteAction={() => {
                                    document.getElementById(`${fileInputName}_${identifiersData.ids.input}`).value = ""; //eslint-disable-line

                                    deleteReleaseLyricsFile(id);
                                }}
                                hasDelete={canDo(
                                    user,
                                    [mediaDeletePermission],
                                )}
                                hasDownload
                            />
                        ))}
                    </Grid>
                </Grid>
            )}
        />
    );
};

export default ReleaseLyricsFileForm;

ReleaseLyricsFileForm.propTypes = {
    setTrackId: PropTypes.func,
    trackId: PropTypes.number,
    trackLoading: PropTypes.bool,
    values: PropTypes.object,
};
