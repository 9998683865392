import { Container } from "@mui/material";
import PropTypes from "prop-types";
import { StyledContent } from "styles";

const Content = ({
    children,
    open,
}) => (
    <StyledContent
        component="main"
        open={open}
    >
        <Container maxWidth={false}>
            {children}
        </Container>
    </StyledContent>
);

export default Content;

Content.propTypes = {
    children: PropTypes.node,
    open: PropTypes.bool,
};
