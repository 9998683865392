import { Box } from "@mui/material";
import { PageHead, ReleaseSoundRecordingForm } from "components";
import { actionsData } from "data";
import { useCreateReleaseSoundRecording, useGetTrack } from "hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ReleaseSoundRecordingsTab = () => {
    const [trackId, setTrackId] = useState(null);

    const { t } = useTranslation();

    const {
        createReleaseSoundRecording,
        loading,
    } = useCreateReleaseSoundRecording();

    const {
        data,
        loading: getTrackLoading,
    } = useGetTrack(trackId);

    const formValues = {
        downloadUrl: data?.downloadUri,
        soundRecordings: data?.recordings,
    };

    return (
        <Box>
            <PageHead title={t("tabs.soundRecordings")} />
            <ReleaseSoundRecordingForm
                action={actionsData.create}
                loading={loading}
                setTrackId={setTrackId}
                trackId={trackId}
                trackLoading={getTrackLoading}
                values={formValues}
                onSubmitForm={createReleaseSoundRecording}
            />
        </Box>
    );
};

export default ReleaseSoundRecordingsTab;
