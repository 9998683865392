import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData } from "data";
import { editReleaseMutation } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useEditRelease = (id) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [editReleaseAction, { loading }] = useMutation(editReleaseMutation);

    const editRelease = async ({
        albumCode,
        discNumber,
        endDate,
        genre,
        imageFile,
        isCompleted,
        nameAr,
        nameEn,
        primaryArtist,
        releaseDate,
        releaseType,
        rightType,
    }) => {
        try {
            await editReleaseAction({
                onCompleted: () => {
                    dispatch(openAlert(
                        t(
                            "messages.editMsgs.success",
                            { entity: t("releases.entity") },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: {
                    data: {
                        album_code: { set: parseInt(albumCode) }, //eslint-disable-line
                        album_name: { set: nameEn }, //eslint-disable-line
                        album_name_ar: { set: nameAr }, //eslint-disable-line
                        artist: {
                            connect: { id: primaryArtist },
                        },
                        disc_number: discNumber ? { set: parseInt(discNumber) } : null, //eslint-disable-line
                        end_date: endDate ? { set: endDate } : null, //eslint-disable-line
                        genre: {
                            connect: { id: genre },
                        },
                        image: {
                            connect: { id: imageFile },
                        },
                        isCompleted: { set: isCompleted },
                        releaseType: {
                            connect: { id: releaseType },
                        },
                        release_date: { set: releaseDate }, //eslint-disable-line
                        rightType: {
                            connect: { id: rightType },
                        },
                        zipFileName: { set: null },
                    },
                    where: { id },

                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        editRelease,
        loading,
    };
};

export default useEditRelease;
