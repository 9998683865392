import { useAuth0 } from "@auth0/auth0-react";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import { Box, Button, Grid } from "@mui/material";
import { Loader, Modal } from "atoms";
import { FoldersStructureRootFolderForm, PageHead } from "components";
import {
    actionsData,
    identifiersData,
    namesData,
    permissionsData,
} from "data";
import {
    useCreateClientFoldersStructureFiles,
    useCreateClientFoldersStructureFolder,
    useDeleteClientFoldersStructureFolder,
    useEditClientFoldersStructureFolder,
    useImageTypesSelectionList,
    useTrackTypesSelectionList,
} from "hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { StyledBox } from "styles";
import { theme } from "theme";
import { canDo } from "utils";
import { ForbiddenView } from "views";

import FoldersStructureTabFilesView from "./files-view";
import FoldersStructureTabTree from "./tree";

const ClientFoldersStructureTab = () => {
    const [selectedTreeItemData, setSelectedTreeItemData] = useState({
        children: [],
        id: "",
        isSelection: false,
        name: "",
        parentPath: "",
        type: "",
    });

    const [modalData, setModalData] = useState({
        body: null,
        hasComponent: true,
        id: null,
        open: false,
        title: "",
    });

    const { t } = useTranslation();

    const currentClient = useSelector((state) => state.clientsReducer)?.currentClient;

    const { id } = useParams();

    const { user } = useAuth0();

    const {
        mediaCreate: mediaCreatePermission,
        mediaRead: mediaReadPermission,
    } = permissionsData.permissions.keys;

    const { loading: trackTypesSelectionListLoading } = useTrackTypesSelectionList();

    const { loading: imageTypesSelectionListLoading } = useImageTypesSelectionList();

    const {
        createClientFoldersStructureFolder,
        loading: createFolderLoading,
    } = useCreateClientFoldersStructureFolder(parseInt(id));

    const {
        editClientFoldersStructureFolder,
        loading: editFolderLoading,
    } = useEditClientFoldersStructureFolder(parseInt(id));

    const {
        createClientFoldersStructureFiles,
        loading: createFilesLoading,
    } = useCreateClientFoldersStructureFiles(parseInt(id));

    const {
        deleteClientFoldersStructureFolder,
        loading: deleteFolderLoading,
    } = useDeleteClientFoldersStructureFolder(
        parseInt(id),
        setSelectedTreeItemData,
    );

    const addFolderHandler = ({ folderName }) => {
        createClientFoldersStructureFolder({
            isSelection: true,
            name: folderName,
            parentPath: "",
        });

        setModalData({
            ...modalData,
            open: false,
        });
    };

    const clickAddNewFolderButtonHandler = () => {
        setModalData({
            body: (
                <FoldersStructureRootFolderForm
                    action={actionsData.add}
                    setModalData={setModalData}
                    values={{}}
                    onSubmitForm={addFolderHandler}
                />
            ),
            hasComponent: true,
            open: true,
            title: t("actions.addNewFolder"),
        });
    };

    useEffect(
        () => {
            if (currentClient?.XMLFolderStructure?.children?.length > 0 && !selectedTreeItemData?.id) setSelectedTreeItemData(currentClient?.XMLFolderStructure?.children[0]);
        },
        [currentClient], // eslint-disable-line
    );

    if (
        trackTypesSelectionListLoading
        || imageTypesSelectionListLoading
        || createFolderLoading
        || editFolderLoading
        || createFilesLoading
        || deleteFolderLoading
    ) return <Loader />;

    return (
        <Box>
            <PageHead title={t("clients.foldersStructure")} />
            <StyledBox
                gap={2}
                justifyContent="space-between"
                minHeight={400}
                overflow="auto"
                flexWrap={{
                    md: "nowrap",
                    xs: "wrap",
                }}
                container
            >
                {currentClient?.XMLFolderStructure ? (
                    <>
                        <Grid
                            md={4}
                            xs={12}
                            borderBottom={{
                                md: "none",
                                xs: `1px dotted ${theme.palette.grey[900]}`,
                            }}
                            borderRight={{
                                md: `1px dotted ${theme.palette.grey[900]}`,
                                xs: "none",
                            }}
                            item
                        >
                            <FoldersStructureTabTree
                                actions={{ createFolder: createClientFoldersStructureFolder }}
                                selectedTreeItemData={selectedTreeItemData}
                                setSelectedTreeItemData={setSelectedTreeItemData}
                            />
                        </Grid>
                        <Grid
                            md={8}
                            xs={12}
                            item
                        >
                            <FoldersStructureTabFilesView
                                selectedTreeItemData={selectedTreeItemData}
                                setSelectedTreeItemData={setSelectedTreeItemData}
                                actions={{
                                    createFiles: createClientFoldersStructureFiles,
                                    deleteFolder: deleteClientFoldersStructureFolder,
                                    editFolder: editClientFoldersStructureFolder,
                                }}
                            />
                        </Grid>
                    </>
                ) : (
                    <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        margin="auto"
                    >
                        {canDo(
                            user,
                            [mediaReadPermission, mediaCreatePermission],
                        )
                            ? (
                                <Box>
                                    <Modal
                                        modalData={modalData}
                                        setModalData={setModalData}
                                    />
                                    <Button
                                        color="primary"
                                        id={`${namesData.buttons.addNewFolder}_${identifiersData.ids.button}`}
                                        variant="outlined"
                                        onClick={clickAddNewFolderButtonHandler}
                                    >
                                        <CreateNewFolderOutlinedIcon sx={{ marginRight: 1 }} />
                                        {t("actions.addNewFolder")}
                                    </Button>
                                </Box>
                            ) : <ForbiddenView withoutBack />}
                    </Box>
                )}
            </StyledBox>
        </Box>
    );
};

export default ClientFoldersStructureTab;
