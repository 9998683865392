import inputData from "data/input";
import selectionsData from "data/selections";

const { tracks: tracksSelectionKey } = selectionsData.keys;

const {
    autoComplete: autoCompleteInputType,
    switch: switchInputType,
} = inputData.types;

const {
    master: masterInputName,
    mechanicalPerformance: mechanicalPerformanceInputName,
    neighboring: neighboringInputName,
    synchronization: synchronizationInputName,
    track: trackInputName,
} = inputData.names;

const {
    master: masterInputLabel,
    mechanicalPerformance: mechanicalPerformanceInputLabel,
    neighboring: neighboringInputLabel,
    synchronization: synchronizationInputLabel,
    track: trackInputLabel,
} = inputData.labels;

const releaseRoyaltyFormData = [
    {
        col: {
            md: 4,
            xs: 12,
        },
        hasOffset: true,
        label: trackInputLabel,
        name: trackInputName,
        selectionKey: tracksSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        isOptional: true,
        label: masterInputLabel,
        name: masterInputName,
        type: switchInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        hasOffset: true,
        isOptional: true,
        label: mechanicalPerformanceInputLabel,
        name: mechanicalPerformanceInputName,
        type: switchInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        isOptional: true,
        label: neighboringInputLabel,
        name: neighboringInputName,
        type: switchInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        isOptional: true,
        label: synchronizationInputLabel,
        name: synchronizationInputName,
        type: switchInputType,
    },
];

export default releaseRoyaltyFormData;
