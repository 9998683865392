import { Box } from "@mui/material";
import { Loader } from "atoms";
import { PageHead, ReleaseOwnershipForm } from "components";
import { actionsData, selectionsData } from "data";
import { useCreateReleaseOwnership, useEditReleaseOwnership, useTerritoriesList } from "hooks";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const ReleaseOwnershipTab = () => {
    const releaseData = useSelector((state) => state.releasesReducer).currentRelease;

    const { id } = useParams();

    const { t } = useTranslation();

    const {
        createReleaseOwnership,
        loading: createReleaseOwnershipLoading,
    } = useCreateReleaseOwnership();

    const {
        editReleaseOwnership,
        loading: editReleaseOwnershipLoading,
    } = useEditReleaseOwnership(
        releaseData?.ownership?.id,
        parseInt(id),
    );

    const {
        count: territoriesCount,
        fetched: territoriesFetched,
        loading: territoriesLoading,
        page: territoriesPage,
        refetch: territoriesRefetch,
        searchKey: territoriesSearchKey,
    } = useTerritoriesList(
        true,
        true,
    );

    const ownership = releaseData?.ownership;

    const {
        c_line: cLine,
        id: ownershipId,
        label,
        p_line: pLine,
        territory,
    } = ownership || {};

    const formValues = ownership && {
        cLine,
        id: ownershipId,
        label,
        pLine,
        territory: territory?.id,
    };

    const asyncMenus = {
        territories: {
            count: territoriesCount,
            loading: territoriesLoading,
            page: territoriesPage,
            refetch: territoriesRefetch,
            searchKey: territoriesSearchKey,
            selectionKey: selectionsData.keys.territories,
        },
    };

    if (territoriesLoading && !territoriesFetched) return <Loader />;

    return (
        <Box>
            <PageHead title={t("tabs.ownership")} />
            <ReleaseOwnershipForm
                action={ownership ? actionsData.edit : actionsData.create}
                asyncMenus={asyncMenus}
                loading={createReleaseOwnershipLoading || editReleaseOwnershipLoading}
                values={formValues}
                onSubmitForm={ownership ? editReleaseOwnership : createReleaseOwnership}
            />
        </Box>
    );
};

export default ReleaseOwnershipTab;
