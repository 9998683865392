import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { constantsData, selectionsData } from "data";
import { getReleasesListQuery } from "graph";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { openAlert, setSelection } from "redux/actions";
import { displayErrorHandler } from "utils";

const useReleasesList = (
    isPaginated = true,
    hasAutoCompleteMenu = false,
    isCompleted = false,
    filters = {},
    importerData,
    artistId,
    searchValue,
) => {
    const [fetched, setFetched] = useState(false);

    const [params] = useSearchParams();

    const pageParam = parseInt(params.get("page")) || 1;

    const limitParam = parseInt(params.get("limit")) || 25;

    const [page, setPage] = useState(pageParam);

    const [limit, setLimit] = useState(limitParam);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const selections = useSelector((state) => state.selectionsReducer);

    const { isAuthenticated } = useAuth0();

    const { releases: releasesKey } = selectionsData.keys;

    const {
        data,
        error,
        loading,
        refetch,
    } = useQuery(
        getReleasesListQuery,
        {
            onCompleted: ({ listReleases }) => {
                if (!isPaginated || hasAutoCompleteMenu) {
                    const fetchedList = listReleases?.posts?.map(({
                        album_name, //eslint-disable-line
                        album_name_ar, //eslint-disable-line
                        id,
                        upc,
                    }) => ({
                        albumNameAr: album_name_ar, //eslint-disable-line
                        label: album_name, //eslint-disable-line
                        upc,
                        value: id,

                    }));

                    dispatch(setSelection({
                        [releasesKey]: selections?.[releasesKey]?.length > 0 && hasAutoCompleteMenu ? selections?.[releasesKey].concat(fetchedList) : fetchedList,
                    }));

                    setFetched(true);
                }
            },
            ...hasAutoCompleteMenu && { notifyOnNetworkStatusChange: true },
            skip: !isAuthenticated || (importerData && !importerData?.importing) || (importerData && (importerData.codesList?.length === 0 && importerData.upcsList?.length === 0)),
            variables: {
                ...isPaginated && {
                    limit,
                    page: page - 1,
                },
                orderBy: { release_date: "desc" }, //eslint-disable-line
                where: {
                    ...Object.keys(filters)?.length > 0 && { ...filters },
                    isCompleted: { equals: isCompleted },
                    ...(importerData || artistId) && {
                        OR: [
                            {
                                ...artistId && {
                                    artist_id: { equals: artistId }, //eslint-disable-line
                                },
                            },
                            {
                                ...importerData && {
                                    album_code: { in: importerData.codesList }, //eslint-disable-line
                                },
                            },
                            {
                                ...importerData && {
                                    upc: { in: importerData.upcsList },
                                },
                            },
                        ],
                    },
                    ...searchValue && {
                        album_name: { contains: searchValue }, // eslint-disable-line
                    },
                },
            },
        },
    );

    useEffect(
        () => {
            if (error?.message === constantsData.failedToFetch && !loading) {
                if (importerData) {
                    dispatch(importerData.setInvalidImporterRelease({
                        isNetworkFailed: true,
                        originalData: { release: importerData?.currentRelease },
                        release: {
                            data: importerData?.currentRelease,
                            message: t("messages.networkFailure"),
                        },
                    }));
                } else {
                    displayErrorHandler(
                        t("messages.networkFailure"),
                        dispatch,
                        openAlert,
                    );
                }
            }
        },
        [error, data], // eslint-disable-line
    );

    useEffect(
        () => {
            dispatch(setSelection({ [releasesKey]: [] }));
        },
        [], // eslint-disable-line
    );

    return {
        count: data?.listReleases?.meta?.nodeCount,
        data: data?.listReleases?.posts,
        error,
        fetched,
        limit,
        loading,
        page: data?.listReleases?.meta?.currentPage,
        refetch,
        searchKey: "album_name",
        setLimit,
        setPage,
    };
};

export default useReleasesList;
