import styled from "@emotion/styled";
import { Chip } from "@mui/material";

const StyledChip = styled(Chip)(({
    label,
    status,
    theme,
}) => ({
    background: theme.palette?.chip?.background[status],
    borderRadius: 6,
    color: theme.palette?.chip?.color[status],
    display: label === "0" && "none",
    fontWeight: "bold",
    height: "22px",
    padding: "1px 8px",
    textTransform: "capitalize",
}));

export default StyledChip;
