import { useAuth0 } from "@auth0/auth0-react";
import {
    constantsData,
    identifiersData,
    namesData,
    statusTypesData,
    urlsData,
} from "data";
import { useApisClient } from "hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useDeleteDriveManagementLabel = () => {
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { logout } = useAuth0();

    const { post } = useApisClient();

    const {
        files: { url: driveManagementFilesApiUrl },
        labels: driveManagementLabelsApiUrl,
    } = urlsData.apis.driveManagement;

    const { deleteDriveManagementLabelPending: deleteDriveManagementLabelPendingToastName } = namesData.toasts;

    const { toast: toastId } = identifiersData.ids;

    const deleteDriveManagementLabel = ({
        fileLabels,
        id,
        labelId,
        setFileLabels,
    }) => {
        setLoading(true);

        post(
            `${driveManagementFilesApiUrl}/${driveManagementLabelsApiUrl}`,
            id,
            {
                labelModifications: [
                    {
                        labelId,
                        removeLabel: true,
                    },
                ],
            },
        ).then(() => {
            dispatch(openAlert(
                t(
                    "messages.deleteMsgs.success",
                    { entity: t("labels.label") },
                ),
                statusTypesData.success,
            ));

            const filteredFileLabels = fileLabels.filter((label) => label.id !== labelId);

            setFileLabels(filteredFileLabels);
        })["catch"]((err) => {
            displayErrorHandler(
                err.response.data.message === constantsData.networkError ? t("messages.networkFailure") : err.response.data.message,
                dispatch,
                openAlert,
                err.response.status,
                logout,
            );
        })["finally"](() => {
            toast.dismiss(`${deleteDriveManagementLabelPendingToastName}_${toastId}`);

            setLoading(false);
        });
    };

    useEffect(
        () => {
            if (loading) {
                toast.info(
                    () => t(
                        "messages.deleteMsgs.pending",
                        { entity: t("labels.label") },
                    ),
                    { toastId: `${deleteDriveManagementLabelPendingToastName}_${toastId}` },
                );
            }
        },
        [loading], //eslint-disable-line
    );

    return {
        deleteDriveManagementLabel,
        loading,
    };
};

export default useDeleteDriveManagementLabel;
