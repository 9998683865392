import styled from "@emotion/styled";
import { LinearProgress, linearProgressClasses } from "@mui/material";

const StyledProgressBar = styled(LinearProgress)(({
    status,
    theme,
}) => ({
    borderRadius: 5,
    height: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.progressBar.background[status],
    },
    [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: theme.palette.progressBar.color[status],
        borderRadius: 5,
    },
    minWidth: 70,
}));

export default StyledProgressBar;
