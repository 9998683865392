import inputData from "data/input";
import selectionsData from "data/selections";

const {
    artworkFileNames: artworkFileNamesSelectionKey,
    fileTypes: fileTypesSelectionKey,
    imageTypes: imageTypesSelectionKey,
} = selectionsData.keys;

const {
    autoComplete: autoCompleteInputType,
    text: textInputType,
} = inputData.types;

const {
    fileExtension: fileExtensionInputName,
    fileName: fileNameInputName,
    fileType: fileTypeInputName,
    height: heightInputName,
    width: widthInputName,
} = inputData.names;

const {
    fileExtension: fileExtensionInputLabel,
    fileName: fileNameInputLabel,
    fileType: fileTypeInputLabel,
    height: heightInputLabel,
    width: widthInputLabel,
} = inputData.labels;

const foldersStructureFileFormData = [
    {
        col: { xs: 12 },
        label: fileTypeInputLabel,
        name: fileTypeInputName,
        selectionKey: fileTypesSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: { xs: 12 },
        label: fileExtensionInputLabel,
        name: fileExtensionInputName,
        selectionKey: imageTypesSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: { xs: 12 },
        label: fileNameInputLabel,
        name: fileNameInputName,
        selectionKey: artworkFileNamesSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: { xs: 12 },
        label: widthInputLabel,
        name: widthInputName,
        type: textInputType,
    },
    {
        col: { xs: 12 },
        label: heightInputLabel,
        name: heightInputName,
        type: textInputType,
    },
];

export default foldersStructureFileFormData;
