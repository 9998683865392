import CheckIcon from "@mui/icons-material/Check";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import { Loader } from "atoms";
import { identifiersData, namesData } from "data";
import { useGrantDriveManagementUserPermission, useSearchDriveManagementUsersList } from "hooks";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { theme } from "theme";

const GrantDriveManagementUserPermissionView = ({
    currentPermission,
    fileId,
    fileName,
    searchValue,
    selectedUser,
    setModalData,
    setSelectedUser,
}) => {
    const { button: buttonId } = identifiersData.ids;

    const {
        cancel: cancelButtonName,
        create: createButtonName,
    } = namesData.buttons;

    const {
        data,
        loading,
    } = useSearchDriveManagementUsersList(searchValue);

    const {
        grantDriveManagementUserPermission,
        loading: grantPermissionLoading,
    } = useGrantDriveManagementUserPermission();

    const { t } = useTranslation();

    return (
        <Box>
            <Typography
                marginBottom={1}
                textAlign="start"
                variant="body1"
            >
                {`${t("actions.share")} "${fileName}"`}
            </Typography>
            <Box
                alignItems={loading && "center"}
                background={theme.palette.grey[200]}
                borderRadius="15px"
                display={loading && "flex"}
                height={300}
                justifyContent={loading && "center"}
                overflow="auto"
                padding={1}
                width={{
                    md: 400,
                    xs: 300,
                }}
            >
                {loading ? <Loader withoutFullHeight /> : (
                    <>
                        {data.map(({
                            email,
                            id,
                            kind,
                            name,
                            primaryEmail,
                        }) => (
                            <Box
                                alignItems="center"
                                border={selectedUser?.id === id && `1px solid ${theme.palette.primary.main}`}
                                borderRadius="5px"
                                display="flex"
                                justifyContent="space-between"
                                key={id}
                                mb="10px"
                                padding={1}
                                sx={{
                                    cursor: "pointer",
                                    userSelect: "none",
                                }}
                                onClick={() => {
                                    setSelectedUser({
                                        email: primaryEmail || email,
                                        id,
                                        type: kind,
                                    });
                                }}
                            >
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    gap={1}
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                    >
                                        <Typography variant="caption">{name?.fullName || name}</Typography>
                                        <Typography variant="caption">{primaryEmail || email}</Typography>
                                    </Box>
                                </Box>
                                {selectedUser?.id === id && <CheckIcon sx={{ color: theme.palette.primary.main }} />}
                            </Box>
                        ))}
                    </>
                )}
            </Box>
            <Box
                alignItems="center"
                display="flex"
                gap={2}
                justifyContent="end"
                mt={1}
            >
                <Button
                    id={`${cancelButtonName}_${buttonId}`}
                    variant="outlined"
                    onClick={() => setModalData((prev) => ({
                        ...prev,
                        open: false,
                    }))}
                >
                    {t("actions.cancel")}
                </Button>
                <LoadingButton
                    disabled={!selectedUser}
                    id={`${createButtonName}_${buttonId}`}
                    loading={grantPermissionLoading}
                    size="medium"
                    type="submit"
                    variant="contained"
                    onClick={() => {
                        grantDriveManagementUserPermission(
                            fileId,
                            selectedUser,
                            currentPermission,
                        );
                    }}
                >
                    {t("actions.create")}
                </LoadingButton>
            </Box>
        </Box>
    );
};

export default GrantDriveManagementUserPermissionView;

GrantDriveManagementUserPermissionView.propTypes = {
    currentPermission: PropTypes.string,
    fileId: PropTypes.string,
    fileName: PropTypes.string,
    searchValue: PropTypes.string,
    selectedUser: PropTypes.object,
    setModalData: PropTypes.func,
    setSelectedUser: PropTypes.func,
};
