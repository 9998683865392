import { gql } from "@apollo/client";

export const getTerritoriesListQuery = gql`
    query getTerritoriesList(
        $page: Int = 0,
        $limit: Int = 100000,
        $orderBy: TerritoryOrderByInput!,
        $where: TerritoryWhereInput
    ) {
        listTerritories(
            page: $page,
            elementsPerPage: $limit,
            orderBy: $orderBy,
            where: $where
        ) {
            posts {
                id
                name
                countries {
                    id
                }
                ownerships {
                    release {
                        id
                    }
                }
            }
            meta {
                currentPage
                nodeCount
                resultCount
                totalPages
            }
        }
    }
`;

export const getTerritoryQuery = gql`
    query getTerritory($where: TerritoryWhereUniqueInput!) {
        territory(where: $where) {
            name
            countries {
                id
            }
            ownerships {
                release {
                    id
                    album_name
                    upc
                    ownership {
                        id
                    }
                }
            }
        }
    }
`;
