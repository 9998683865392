import { gql } from "@apollo/client";

export const createReleaseMutation = gql`
    mutation createRelease($data: ReleaseCreateInput!) {
        createOneRelease(data: $data) {
            id
        }
    }
`;

export const editReleaseMutation = gql`
    mutation editRelease(
        $data: ReleaseUpdateInput!,
        $where: ReleaseWhereUniqueInput!
    ) {
        updateOneRelease(
            data: $data,
            where: $where
        ) {
            album_code
            album_name
            album_name_ar
            disc_number
            end_date
            genre {
                id
            }
            id
            image_id
            ownershipId
            release_date
            releaseType {
                id
                name
            }
            rightType {
                id
                name
            }
            upc
            isCompleted
            image {
                downloadUri
                id
            }
        }
    }
`;

export const deleteReleaseMutation = gql`
    mutation deleteRelease($where: ReleaseWhereUniqueInput!) {
        deleteOneRelease(where: $where) {
            id
        }
    }
`;

export const exportReleasesMutation = gql`
    mutation exportReleases(
        $releasesIds: [Int],
        $toCsv: Boolean
    ) {
        exportReleases(
            releasesId: $releasesIds,
            toCsv: $toCsv
        )
    }
`;

export const downloadReleasesContentMutation = gql`
    mutation downloadReleasesContent($releasesIds: [Int]!) {
        bulkDownloadReleases(releasesId: $releasesIds)
    }
`;

export const downloadReleaseClientXmlFileMutation = gql`
    mutation downloadReleaseClientXmlFile(
        $releaseId: Int!,
        $clientId: Int!,
        $territoryId: Int!,
        $distributionFileTypeId: Int!,
        $xmlTypeId: Int!
    ) {
        downloadXML(
            releaseId: $releaseId,
            clientId: $clientId,
            territoryId: $territoryId,
            distributeTypeId: $distributionFileTypeId,
            downloadXMLTypeId: $xmlTypeId
        )
    }
`;

export const addReleaseTrackMutation = gql`
    mutation addReleaseTrack(
        $releaseId: Int!,
        $trackId: Int!
    ) {
        createReleaseTrack(
            releaseId: $releaseId,
            trackId: $trackId
        )
    }
`;

export const removeReleaseTrackMutation = gql`
    mutation removeReleaseTrack($where: ReleaseTrackWhereUniqueInput!) {
        deleteOneReleaseTrack(where: $where) {
            id
        }
    }
`;

export const createReleaseLyricsFileMutation = gql`
    mutation createReleaseLyricsFile($data: LyricFileCreateInput!) {
        createOneLyricFile(data: $data) {
            id
        }
    }
`;

export const deleteReleaseLyricsFileMutation = gql`
    mutation deleteReleaseLyricsFile($where: LyricFileWhereUniqueInput!) {
        deleteOneLyricFile(where: $where) {
            id
        }
    }
`;

export const createReleaseOwnershipMutation = gql`
    mutation createReleaseOwnership($data: OwnershipCreateInput!) {
        createOneOwnership(data: $data) {
           id
        }
    }
`;

export const editReleaseOwnershipMutation = gql`
    mutation editReleaseOwnership(
        $data: OwnershipUpdateInput!,
        $where: OwnershipWhereUniqueInput!
    ) {
        updateOneOwnership(
            data: $data,
            where: $where
        ) {
            id
        }
    }
`;

export const deleteReleaseOwnershipMutation = gql`
    mutation deleteReleaseOwnership($where: OwnershipWhereUniqueInput!) {
        deleteOneOwnership(where: $where) {
            id
        }
    }
`;

export const createReleaseSoundRecordingMutation = gql`
    mutation createReleaseSoundRecording(
        $trackId: Int!,
        $start: Int!,
        $duration: Int!
    ) {
        createRecording(
            track_id: $trackId,
            start: $start,
            duration: $duration
        ) {
            id
        }
    }
`;

export const deleteReleaseSoundRecordingMutation = gql`
    mutation deleteReleaseSoundRecording($where: RecordingWhereUniqueInput!) {
        deleteOneRecording(where: $where) {
            id
        }
    }
`;

export const createReleaseRoyaltyMutation = gql`
    mutation createReleaseRoyalty($data: RoyaltyCreateInput!) {
        createOneRoyalty(data: $data) {
            id
        }
    }
`;

export const editReleaseRoyaltyMutation = gql`
    mutation editReleaseRoyalty(
        $data: RoyaltyUpdateInput!,
        $where: RoyaltyWhereUniqueInput!
    ) {
        updateOneRoyalty(
            data: $data,
            where: $where
        ) {
            id
        }
    }
`;

export const createReleaseDocumentMutation = gql`
    mutation createReleaseDocument($data: DocumentCreateInput!) {
        createOneDocument(data: $data) {
            id
        }
    }
`;

export const deleteReleaseDocumentMutation = gql`
    mutation deleteReleaseDocument($where: DocumentWhereUniqueInput!) {
        deleteOneDocument(where: $where) {
            id
        }
    }
`;

export const createReleaseNoteMutation = gql`
    mutation createReleaseNote($data: NoteCreateInput!) {
        createOneNote(data: $data) {
            id
        }
    }
`;

export const editReleaseNoteMutation = gql`
    mutation editReleaseNote(
        $data: NoteUpdateInput!,
        $where: NoteWhereUniqueInput!
    ) {
        updateOneNote(
            data: $data,
            where: $where
        ) {
            id
            content
        }
    }
`;

export const createReleaseClientLinkMutation = gql`
    mutation createReleaseClientLink($data: ReleaseTrackClientCreateInput!) {
        createOneReleaseTrackClient(data: $data) {
            id
        }
    }
`;

export const editReleaseClientLinkMutation = gql`
    mutation editReleaseClientLink(
        $data: ReleaseTrackClientUpdateInput!,
        $where: ReleaseTrackClientWhereUniqueInput!
    ) {
        updateOneReleaseTrackClient(
            data: $data,
            where: $where
        ) {
            id
        }
    }
`;

export const deleteReleaseClientLinkMutation = gql`
    mutation deleteReleaseClientLink($where: ReleaseTrackClientWhereUniqueInput!) {
        deleteOneReleaseTrackClient(where: $where) {
            id
        }
    }
`;
