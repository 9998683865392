import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import {
    Avatar,
    Box,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { identifiersData, inputData, namesData } from "data";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { StyledFileLink } from "styles";
import { getSubstringHandler } from "utils";

const FileLink = ({
    deleteAction,
    hasDelete,
    hasDownload,
    hasImage,
    hasJustifyStart,
    id,
    link,
    name,
    otherData,
    rowId,
}) => {
    const { t } = useTranslation();

    const {
        button: buttonId,
        input: inputId,
        link: linkId,
    } = identifiersData.ids;

    const {
        delete: deleteButtonName,
        download: downloadButtonName,
    } = namesData.buttons;

    const { file: fileInputName } = inputData.names;

    return (
        <Box
            alignItems="center"
            display="flex"
            gap={1}
            justifyContent="start"
            mt={1}
        >
            {hasImage && (
                <Avatar
                    alt={t("imageAlternatives.file")}
                    imgProps={{ loading: "lazy" }}
                    src={`${window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_DOMAIN}${link}`} // eslint-disable-line
                    sx={{ borderRadius: 1 }}
                />
            )}
            <StyledFileLink hasJustifyStart={hasJustifyStart ? +true : +false}>
                {hasDownload ? (
                    <a
                        href={`${window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_DOMAIN}${link}`} // eslint-disable-line
                        id={id ? `${id}_${inputId}_${linkId}${rowId ? `_${rowId}` : ""}` : `${fileInputName}_${inputId}_${linkId}${rowId ? `_${rowId}` : ""}`}
                        rel="noreferrer"
                        target="_blank"
                        download
                    >
                        {getSubstringHandler(
                            name,
                            40,
                        )}
                    </a>
                ) : (
                    <Typography variant="caption">
                        {getSubstringHandler(
                            name,
                            30,
                        )}
                    </Typography>
                )}
                {otherData && (
                    <Box
                        display={{
                            md: "inline-block",
                            xs: "none",
                        }}
                    >
                        {otherData}
                    </Box>
                )}
                <Box display="flex">
                    {hasDownload && (
                        <Tooltip title={t("actions.download")}>
                            <IconButton id={id ? `${id}_${inputId}_${downloadButtonName}_${buttonId}${rowId ? `_${rowId}` : ""}` : `${fileInputName}_${inputId}_${downloadButtonName}_${buttonId}${rowId ? `_${rowId}` : ""}`}>
                                <a
                                    href={`${window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_DOMAIN}${link}`} // eslint-disable-line
                                    rel="noreferrer"
                                    target="_blank"
                                    download
                                >
                                    {}
                                    <DownloadIcon
                                        sx={{
                                            position: "relative",
                                            top: 5,
                                        }}
                                    />
                                </a>
                            </IconButton>
                        </Tooltip>
                    )}
                    {hasDelete && (
                        <Tooltip title={t("actions.delete")}>
                            <IconButton
                                id={id ? `${id}_${inputId}_${deleteButtonName}_${buttonId}${rowId ? `_${rowId}` : ""}` : `${fileInputName}_${inputId}_${deleteButtonName}_${buttonId}${rowId ? `_${rowId}` : ""}`}
                                onClick={deleteAction}
                            >
                                <DeleteForeverOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
            </StyledFileLink>
        </Box>
    );
};

export default FileLink;

FileLink.propTypes = {
    deleteAction: PropTypes.func,
    hasDelete: PropTypes.bool,
    hasDownload: PropTypes.bool,
    hasImage: PropTypes.bool,
    hasJustifyStart: PropTypes.bool,
    id: PropTypes.string,
    link: PropTypes.string,
    name: PropTypes.string,
    otherData: PropTypes.node,
    rowId: PropTypes.number,
};
