import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import {
    Box,
    IconButton,
    InputLabel,
    Tooltip,
} from "@mui/material";
import { DebouncedSearchInput, SelectMenu } from "atoms";
import { EditDriveManagementUserPermissionView, GrantDriveManagementUserPermissionView } from "components";
import {
    constantsData,
    identifiersData,
    inputData,
    namesData,
} from "data";
import { useDriveManagementUsersPermissionsList } from "hooks";
import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { theme } from "theme";
import { getDriveManagementUserPermissionsListHandler } from "utils";

const DriveManagementUsersPermissions = ({
    canShare,
    fileId,
    fileName,
    mimeType,
    setModalData,
}) => {
    const [editMode, setEditMode] = useState(true);

    const [selectedUser, setSelectedUser] = useState(null);

    const [currentPermission, setCurrentPermission] = useState("reader");

    const [searchValue, setSearchValue] = useState("");

    const { t } = useTranslation();

    const {
        data,
        isOrganizer,
        loading,
    } = useDriveManagementUsersPermissionsList(fileId);

    const {
        button: buttonId,
        label: labelId,
        modal: modalId,
        selectMenu: selectMenuId,
    } = identifiersData.ids;

    const {
        buttons: { back: backButtonName },
        menus: { userPermissions: userPermissionsMenuName },
    } = namesData;

    const {
        userPermissions: userPermissionsInputName,
        usersSearch: usersSearchInputName,
    } = inputData.names;

    useEffect(
        () => {
            setSelectedUser(null);

            if (searchValue.length > 0) setEditMode(false);

            else setEditMode(true);
        },
        [searchValue], //eslint-disable-line
    );

    return (
        <Box mt={3}>
            <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                width={{
                    md: 450,
                    xs: 300,
                }}
            >
                {(isOrganizer || canShare) && (
                    <Box
                        alignItems="center"
                        display="flex"
                        gap={1}
                        justifyContent="space-between"
                        marginBottom={2}
                        width="100%"
                    >
                        {!editMode && (
                            <Tooltip title={t("actions.back")}>
                                <IconButton
                                    id={`${modalId}_${backButtonName}_${buttonId}`}
                                    onClick={() => {
                                        setEditMode(true);

                                        setSelectedUser(null);
                                    }}
                                >
                                    <ArrowBackOutlinedIcon
                                        sx={{
                                            color: theme.palette.grey[700],
                                            fontSize: 25,
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        )}
                        <DebouncedSearchInput
                            label={t("labels.addPeopleAndGroups")}
                            name={usersSearchInputName}
                            setSearchValue={setSearchValue}
                        />
                        <SelectMenu
                            disabled={!searchValue || !selectedUser}
                            inputLabel={<InputLabel id={`${userPermissionsInputName}_${selectMenuId}_${labelId}`}>{t("labels.permission")}</InputLabel>}
                            label={t("labels.permission")}
                            labelId={`${userPermissionsInputName}_${selectMenuId}_${labelId}`}
                            name={userPermissionsMenuName}
                            value={currentPermission}
                            options={getDriveManagementUserPermissionsListHandler(
                                mimeType,
                                isOrganizer,
                            )?.map(({
                                label,
                                value,
                            }) => ({
                                label: mimeType.includes(constantsData.folder) ? label?.folder : label?.file,
                                onClick: () => setCurrentPermission(value),
                                value,
                            }))}
                            style={{
                                "&.MuiInputBase-root": {
                                    borderRadius: "50px",
                                    height: "40px",
                                },
                            }}
                            hasFullWidth
                            hasInputLabel
                        />
                    </Box>
                )}
                {editMode ? (
                    <EditDriveManagementUserPermissionView
                        data={data}
                        fileId={fileId}
                        isOrganizer={isOrganizer}
                        isViewerMode={!canShare && !isOrganizer}
                        loading={loading}
                        mimeType={mimeType}
                    />
                ) : (
                    <GrantDriveManagementUserPermissionView
                        currentPermission={currentPermission}
                        fileId={fileId}
                        fileName={fileName}
                        searchValue={searchValue}
                        selectedUser={selectedUser}
                        setModalData={setModalData}
                        setSelectedUser={setSelectedUser}
                    />
                )}
            </Box>
        </Box>
    );
};

export default DriveManagementUsersPermissions;

DriveManagementUsersPermissions.propTypes = {
    canShare: PropTypes.bool,
    fileId: PropTypes.string,
    fileName: PropTypes.string,
    mimeType: PropTypes.string,
    setModalData: PropTypes.func,
};
