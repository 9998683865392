import {
    SET_DRIVE_MANAGEMENT_DRIVES_LIST,
    SET_DRIVE_MANAGEMENT_LABELS_LIST,
    SET_REFETCH_DRIVE_MANAGEMENT_LIST,
    TOGGLE_DRIVE_MANAGEMENT_LABEL_DRAWER,
} from "redux/types";
import { updateStateHandler } from "utils";

const initialState = {
    drivesList: null,
    labels: [],
    openDrawer: false,
    refetch: false,
};

const setRefetchDriveManagementList = (state, refetch) => updateStateHandler(
    state,
    { refetch },
);

const setDriveManagementDrivesList = (state, drives) => updateStateHandler(
    state,
    { drivesList: drives },
);

const toggleDriveManagementLabelDrawer = (state, open) => updateStateHandler(
    state,
    { openDrawer: open },
);

const setDriveManagementLabelsList = (state, labels) => updateStateHandler(
    state,
    { labels },
);

const driveManagementReducer = (state = initialState, action) => {
    const {
        payload,
        type,
    } = action;

    const {
        drives,
        labels,
        open,
        refetch,
    } = payload || {};

    switch (type) {
    case SET_REFETCH_DRIVE_MANAGEMENT_LIST: return setRefetchDriveManagementList(
        state,
        refetch,
    );
    case SET_DRIVE_MANAGEMENT_DRIVES_LIST: return setDriveManagementDrivesList(
        state,
        drives,
    );
    case TOGGLE_DRIVE_MANAGEMENT_LABEL_DRAWER: return toggleDriveManagementLabelDrawer(
        state,
        open,
    );
    case SET_DRIVE_MANAGEMENT_LABELS_LIST: return setDriveManagementLabelsList(
        state,
        labels,
    );
    default: return state;
    }
};

export default driveManagementReducer;
