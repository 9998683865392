import { useAuth0 } from "@auth0/auth0-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
    Box,
    IconButton,
    InputAdornment,
    Switch,
    Tooltip,
    Typography,
} from "@mui/material";
import {
    AutoCompleteMenu,
    FileLink,
    Loader,
    UploadInput,
} from "atoms";
import { FormContainer } from "components";
import {
    clientInfoFormData,
    fileData,
    identifiersData,
    inputData,
    namesData,
    permissionsData,
    statusTypesData,
} from "data";
import {
    useDeleteFile,
    useImageTypesSelectionList,
    useTrackTypesSelectionList,
    useUploadFile,
} from "hooks";
import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { openAlert } from "redux/actions";
import { useClientInfoSchema } from "schemas";
import { StyledTextField } from "styles";
import {
    canDo,
    deleteFileHandler,
    renderFormControllerHandler,
    setUploadInputPreviousValueHandler,
} from "utils";

const ClientInfoForm = ({
    action,
    loading,
    onSubmitForm,
    values,
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const { t } = useTranslation();

    const selections = useSelector((state) => state.selectionsReducer);

    const dispatch = useDispatch();

    const { id } = useParams();

    const schema = useClientInfoSchema();

    const { user } = useAuth0();

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const { deleteFile } = useDeleteFile();

    const {
        filesData,
        filesLoading,
        setFilesData,
        uploadFile,
    } = useUploadFile();

    const { loading: trackTypesSelectionListLoading } = useTrackTypesSelectionList();

    const { loading: imageTypesSelectionListLoading } = useImageTypesSelectionList();

    const {
        control,
        formState: { errors },
        setError,
        setValue,
        watch,
    } = formMethods;

    const {
        mediaCreate: mediaCreatePermission,
        mediaEdit: mediaEditPermission,
        mediaRead: mediaReadPermission,
    } = permissionsData.permissions.keys;

    const allowedPermissions = values ? [mediaReadPermission, mediaEditPermission] : [mediaCreatePermission, mediaReadPermission];

    const {
        button: buttonId,
        input: inputId,
    } = identifiersData.ids;

    const {
        hidePassword: hidePasswordButtonName,
        showPassword: showPasswordButtonName,
    } = namesData.buttons;

    const {
        names: {
            ftpPassword: ftpPasswordInputName,
            xmlDefaultSettings: xmlDefaultSettingsInputName,
        },
        types: {
            autoComplete: autoCompleteInputType,
            file: fileInputType,
            password: passwordInputType,
            switch: switchInputType,
            text: textInputType,
            title: titleInputType,
            titleWithTopLine: titleWithTopLineInputType,
        },
    } = inputData;

    const deleteSshKeyFileHandler = (name) => {
        deleteFile(filesData?.[fileData.names.sshKey]?.id).then(() => {
            deleteFileHandler(
                setFilesData,
                formMethods,
                name,
            );
        });
    };

    const submitFormHandler = (data) => {
        if (!data?.[fileData.names.sshKey] && !data.ftpPassword && !data.currentPassword) {
            dispatch(openAlert(
                t("messages.requiredPasswordOrSshKeyFile"),
                statusTypesData.error,
            ));
        } else onSubmitForm(data);
    };

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        type,
        isOptional,
        selectionKey,
    ) => {
        switch (type) {
        case fileInputType:
            return (
                <Box mb={1}>
                    <UploadInput
                        accept={fileData.acceptedExtensions.sshKey}
                        color={errors[name]?.message ? "error" : "primary"}
                        error={errors[name]?.message}
                        field={field}
                        hasError={!!errors[name]?.message}
                        label={t(`labels.${label}`)}
                        loading={filesLoading?.[name]}
                        disabled={!canDo(
                            user,
                            allowedPermissions,
                        )}
                        onChange={(e) => {
                            uploadFile(
                                e.target.files[0],
                                formMethods,
                                name,
                                parseInt(id),
                            );
                        }}
                    />
                    {filesData?.[name] && (
                        <FileLink
                            deleteAction={() => deleteSshKeyFileHandler(name)}
                            hasDelete={name === fileData.names.sshKey}
                            name={filesData?.[name]?.name}
                        />
                    )}
                </Box>
            );
        case switchInputType:
            return (
                <Box
                    alignItems="center"
                    display="flex"
                >
                    <Switch
                        {...field}
                        defaultChecked={!!values?.[label]}
                        id={`${name}_${inputId}`}
                    />
                    <Typography
                        color="primary"
                        component="span"
                    >
                        {t(`labels.${label}`)}
                    </Typography>
                </Box>
            );
        case titleInputType:
        case titleWithTopLineInputType:
            return (
                <Box>
                    {type === titleWithTopLineInputType && <hr style={{ marginBlock: 30 }} />}
                    <Typography
                        color="primary"
                        component="h4"
                        variant="h6"
                    >
                        {t(`labels.${label}`)}
                    </Typography>
                    {name === xmlDefaultSettingsInputName && (
                        <Typography
                            color="inherit"
                            variant="caption"
                        >
                            {t("labels.xmlDefaultSettingsNote")}
                        </Typography>
                    )}
                </Box>
            );
        case autoCompleteInputType:
            return (
                <AutoCompleteMenu
                    defaultValue={selections?.[selectionKey]?.find((option) => option?.value === values?.[name])}
                    errors={errors}
                    getRenderedOption={(option) => option?.label}
                    label={label}
                    name={name}
                    optionLabel={(option) => option?.label}
                    options={selections?.[selectionKey]}
                    onChange={(_, option) => field.onChange(option?.value)}
                />
            );
        default:
            return (
                <StyledTextField
                    {...field}
                    error={errors[name]}
                    helperText={(errors[name] && errors[name]?.message) || (values?.hasFtpPassword && name === ftpPasswordInputName && t("messages.inputMsgs.passwordIsSet"))}
                    id={`${name}_${inputId}`}
                    label={t(`labels.${label}`) + (isOptional ? ` (${t("labels.optional")})` : "")}
                    type={type === passwordInputType && showPassword ? textInputType : type}
                    variant="outlined"
                    InputProps={{
                        endAdornment: type === passwordInputType && (
                            <InputAdornment position="end">
                                <Tooltip title={showPassword ? t("actions.hidePassword") : t("actions.showPassword")}>
                                    <IconButton
                                        id={`${showPassword ? hidePasswordButtonName : showPasswordButtonName}_${buttonId}`}
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                />
            );
        }
    };

    useEffect(
        () => {
            if (values?.sshKeyFile) {
                setFilesData({
                    ...values?.sshKeyFile && {
                        sshKeyFile: {
                            id: values?.sshKeyFile,
                            name: t("labels.sshKeyFileUploaded"),
                        },
                    },
                });
            } else setFilesData({});
        },
        [values?.sshKeyFile], // eslint-disable-line
    );

    useEffect(
        () => {
            setUploadInputPreviousValueHandler(
                watch,
                setError,
                setValue,
                filesData,
                fileData.names.sshKey,
            );
        },
        [watch(fileData.names.sshKey)], // eslint-disable-line
    );

    if (trackTypesSelectionListLoading || imageTypesSelectionListLoading) return <Loader />;

    return (
        <FormContainer
            action={action}
            disabled={filesLoading?.sshKeyFile}
            formMethods={formMethods}
            loading={loading}
            data={renderFormControllerHandler(
                control,
                clientInfoFormData,
                renderFormControllerChildrenHandler,
            )}
            hasSubmitButton={canDo(
                user,
                [mediaEditPermission, mediaReadPermission],
            )}
            onSubmitForm={submitFormHandler}
        />
    );
};

export default ClientInfoForm;

ClientInfoForm.propTypes = {
    action: PropTypes.string,
    loading: PropTypes.bool,
    onSubmitForm: PropTypes.func,
    values: PropTypes.object,

};
