import { gql } from "@apollo/client";

export const createUserMutation = gql`
    mutation createUser($data: UserCreateInput!) {
        createUserWithPassword(UserCreateInput: $data) {
            user_id
        }
    }
`;

export const editUserMutation = gql`
    mutation editUser(
        $id: String!,
        $data: UserUpdateInput!
    ) {
        updateUser(
            id: $id,
            userUpdate: $data
        ) {
            user_id
        }
    }
`;

export const deleteUserMutation = gql`
    mutation deleteUser($where: UserDeleteInput!) {
        deleteUser(UserDeleteInput: $where)
    }
`;
