import { regexData } from "data";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useClientInfoSchema = () => {
    const { t } = useTranslation();

    const { number: numberRegex } = regexData;

    return yup.object({
        ftpBaseFolder: yup.string().required(t("messages.inputMsgs.required")),
        ftpHost: yup.string().required(t("messages.inputMsgs.required")),
        ftpPassword: yup.string(),
        ftpPort: yup.string().required(t("messages.inputMsgs.required")).matches(
            numberRegex,
            t("messages.inputMsgs.invalidNumber"),
        ),
        ftpUsername: yup.string().required(t("messages.inputMsgs.required")),
        imageHeight: yup.string().matches(
            numberRegex,
            t("messages.inputMsgs.invalidNumber"),
        ).required(t("messages.inputMsgs.required")),
        imageType: yup.string().required(t("messages.inputMsgs.required")),
        imageWidth: yup.string().matches(
            numberRegex,
            t("messages.inputMsgs.invalidNumber"),
        ).required(t("messages.inputMsgs.required")),
        name: yup.string().required(t("messages.inputMsgs.required")),
        pdap: yup.string().required(t("messages.inputMsgs.required")),
        pdapName: yup.string().required(t("messages.inputMsgs.required")),
        secured: yup["boolean"]().required(t("messages.inputMsgs.required")),
        sshKeyFile: yup.mixed(),
        trackType: yup.string().required(t("messages.inputMsgs.required")),
    });
};

export default useClientInfoSchema;
