import {
    REMOVE_CURRENT_CLIENT,
    SET_CURRENT_CLIENT,
    SET_REVENUE_REPORT_TEMPLATE_DATA,
    SET_XML_DOWNLOAD_LINK,
} from "redux/types";

export const setCurrentClient = (client) => ({
    payload: { client },
    type: SET_CURRENT_CLIENT,
});

export const setRevenueReportTemplateData = (data) => ({
    payload: { data },
    type: SET_REVENUE_REPORT_TEMPLATE_DATA,
});

export const setXmlDownloadLink = (link) => ({
    payload: { link },
    type: SET_XML_DOWNLOAD_LINK,
});

export const removeCurrentClient = () => ({ type: REMOVE_CURRENT_CLIENT });
