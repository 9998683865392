import fileData from "data/file";
import inputData from "data/input";
import selectionsData from "data/selections";

const {
    tracks: tracksSelectionKey,
    videoTypes: videoTypesSelectionKey,
} = selectionsData.keys;

const { video: videoFileName } = fileData.names;

const {
    autoComplete: autoCompleteInputType,
    file: fileInputType,
} = inputData.types;

const {
    track: trackInputName,
    type: typeInputName,
} = inputData.names;

const {
    track: trackInputLabel,
    type: typeInputLabel,
    video: videoFileInputLabel,
} = inputData.labels;

const releaseVideoFormData = [
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: trackInputLabel,
        name: trackInputName,
        selectionKey: tracksSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        hasOffset: true,
        label: typeInputLabel,
        name: typeInputName,
        selectionKey: videoTypesSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: videoFileInputLabel,
        name: videoFileName,
        type: fileInputType,
    },
];

export default releaseVideoFormData;
