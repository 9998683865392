import styled from "@emotion/styled";
import { Avatar, Box } from "@mui/material";

export const StyledImage = styled(Avatar)({
    aspectRatio: "3/2",
    borderRadius: 5,
    height: 400,
    maxHeight: 400,
    objectFit: "contain",
    position: "relative",
    width: "100%",
});

export const StyledImageBackdrop = styled(Avatar)({
    "@media(min-width: 700px) ": { minWidth: "40%" },
    borderRadius: 5,
    minHeight: "90%",
    minWidth: "80%",
});

export const StyledImageShadow = styled(Box)(({ theme }) => ({
    alignItems: "center",
    background: theme.palette?.grey[901],
    borderRadius: 5,
    cursor: "pointer",
    display: "flex",
    flexWrap: "wrap",
    fontSize: 20,
    gap: 10,
    height: "100%",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    top: 0,
    width: "100%",
}));
