import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Typography } from "@mui/material";
import { AutoCompleteMenu, Calendar } from "atoms";
import {
    driveManagementData,
    identifiersData,
    inputData,
    namesData,
} from "data";
import { PropTypes } from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledTextField } from "styles";
import { theme } from "theme";
import { convertDateToIsoFormatHandler } from "utils";

const DriveManagementLabelsFilters = ({
    filteredLabels,
    labels,
    setFilteredLabels,
    setValue,
}) => {
    const [currentLabel, setCurrentLabel] = useState({
        condition: {
            id: "",
            name: "",
        },
        field: "",
        id: "",
        name: "",
        query: "",
        type: {
            id: "",
            name: "",
        },
        value: "",
    });

    const [labelFields, setLabelFields] = useState([]);

    const [labelTypeConditions, setLabelTypeConditions] = useState([]);

    const [labelValueField, setLabelValueField] = useState({
        data: {},
        label: "",
        type: "",
        value: "",
    });

    const [dateValue, setDateValue] = useState(null);

    const { t } = useTranslation();

    const {
        classNames: {
            smallField: smallFieldClassName,
            withoutPosition: withoutPositionClassName,
        },
        ids: {
            button: buttonId,
            filters: filtersId,
            input: inputId,
        },
    } = identifiersData;

    const {
        labels: {
            condition: conditionInputLabel,
            field: fieldInputLabel,
            label: labelInputLabel,
            value: valueInputLabel,
        },
        names: {
            condition: conditionInputName,
            field: fieldInputName,
            filteredLabels: filteredLabelsInputName,
            label: labelInputName,
            value: valueInputName,
        },
        types: {
            number: numberInputType,
            text: textInputType,
        },
    } = inputData;

    const labelsOptions = labels ? labels.map(({
        fields,
        id,
        properties,
    }) => ({
        fields,
        label: properties?.title,
        value: id,
    })) : [];

    const changeLabelNameHandler = (option) => {
        setCurrentLabel(() => ({
            condition: {
                id: "",
                name: "",
            },
            field: "",
            id: option?.value,
            name: option?.label,
            type: {
                id: "",
                name: "",
            },
            value: "",
        }));

        if (option?.fields) {
            const fieldsOptions = option?.fields?.map(({
                id,
                options,
                properties,
            }) => ({
                label: properties.displayName,
                options,
                value: id,
            }));

            setLabelFields([...fieldsOptions]);
        }

        setLabelTypeConditions([]);

        setLabelValueField({
            data: {},
            label: "",
            type: "",
            value: "",
        });
    };

    const changeLabelFieldHandler = ({
        label,
        options,
        value,
    }) => {
        setCurrentLabel((prev) => ({
            ...prev,
            condition: {
                id: "",
                name: "",
            },
            field: label,
            type: {
                id: value,
                type: options.type,
            },
            value: "",
        }));

        setLabelTypeConditions([...driveManagementData.labels.conditions[options.type]]);

        setLabelValueField({
            data: options.data,
            type: options.type,
        });
    };

    const changeLabelTypeConditionHandler = ({
        label,
        value,
    }) => {
        setCurrentLabel((prev) => ({
            ...prev,
            condition: {
                id: value,
                name: label,
            },
        }));
    };

    const addFilteredLabelHandler = () => {
        setFilteredLabels((prev) => [
            ...prev,
            {
                ...currentLabel,
                query: `labels/${currentLabel.id}.${currentLabel.type.id},${currentLabel.condition.id},${currentLabel?.value?.id ? currentLabel?.value?.id : currentLabel.value}`,
            },
        ]);

        setCurrentLabel(() => ({
            condition: {
                id: "",
                name: "",
            },
            field: "",
            id: "",
            name: "",
            type: {
                id: "",
                name: "",
            },
            value: "",
        }));

        setLabelFields([]);

        setLabelTypeConditions([]);

        setLabelValueField({
            data: {},
            label: "",
            type: "",
            value: "",
        });

        setValue(
            filteredLabelsInputName,
            [
                ...filteredLabels,
                {
                    ...currentLabel,
                    query: `labels/${currentLabel.id}.${currentLabel.type.id},${currentLabel.condition.id},${currentLabel?.value?.id ? currentLabel?.value?.id : currentLabel.value}`,
                },
            ],
            { shouldDirty: true },
        );
    };

    const renderLabelValueFieldHandler = () => {
        const {
            date,
            integer,
            selection,
            text,
        } = driveManagementData.labels.types;

        const selectionsOptions = labelValueField?.data?.choices?.map(({
            id,
            properties,
        }) => ({
            label: properties?.displayName,
            value: id,
        })) || [];

        switch (labelValueField.type) {
        case text:
        case integer:
            return (
                <StyledTextField
                    className={`${smallFieldClassName} ${withoutPositionClassName}`}
                    id={`${valueInputName}_${inputId}`}
                    label={t("labels.value")}
                    type={labelValueField.type === text ? textInputType : numberInputType}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => setCurrentLabel((prev) => ({
                        ...prev,
                        value: e.target.value,
                    }))}
                />
            );
        case date:
            return (
                <Calendar
                    label={valueInputLabel}
                    name={valueInputName}
                    value={dateValue}
                    field={{
                        onChange: (value) => {
                            setCurrentLabel((prev) => ({
                                ...prev,
                                value: convertDateToIsoFormatHandler(new Date(value)),
                            }));

                            setDateValue(new Date(value));
                        },
                    }}
                    hasSmallField
                    isFilterInput
                />
            );
        case selection:
            return (
                <AutoCompleteMenu
                    getRenderedOption={(option) => option?.label}
                    label={valueInputLabel}
                    name={valueInputName}
                    optionLabel={(option) => option?.label}
                    options={selectionsOptions}
                    hasSmallField
                    onChange={(_, option) => setCurrentLabel((prev) => ({
                        ...prev,
                        value: {
                            id: option?.value,
                            name: option?.label,
                        },
                    }))}
                />
            );
        }
    };

    return (
        <Box
            border={`1px solid ${theme.palette.grey[400]}`}
            borderRadius={3}
            maxWidth={900}
            px={1}
            py={2}
        >
            <Box
                alignItems="center"
                display="flex"
                flexWrap="wrap"
                gap={2}
                justifyContent="space-between"
            >
                <Box
                    display="flex"
                    flexWrap="wrap"
                    gap={2}
                >
                    <Box>
                        <AutoCompleteMenu
                            getRenderedOption={(option) => option?.label}
                            label={labelInputLabel}
                            name={labelInputName}
                            optionLabel={(option) => option?.label}
                            options={labelsOptions}
                            hasSmallField
                            onChange={(_, option) => changeLabelNameHandler(option)}
                        />
                    </Box>
                    {labelFields.length > 0 && (
                        <Box>
                            <AutoCompleteMenu
                                getRenderedOption={(option) => option?.label}
                                label={fieldInputLabel}
                                name={fieldInputName}
                                optionLabel={(option) => option?.label}
                                options={labelFields}
                                hasSmallField
                                onChange={(_, option) => changeLabelFieldHandler(option)}
                            />
                        </Box>
                    )}
                    {labelTypeConditions.length > 0 && (
                        <Box>
                            <AutoCompleteMenu
                                getRenderedOption={(option) => option?.label}
                                label={conditionInputLabel}
                                name={conditionInputName}
                                optionLabel={(option) => option?.label}
                                options={labelTypeConditions}
                                hasSmallField
                                onChange={(_, option) => changeLabelTypeConditionHandler(option)}
                            />
                        </Box>
                    )}
                    {labelValueField.type && <Box>{renderLabelValueFieldHandler()}</Box>}
                </Box>
                <Box>
                    <Button
                        color="primary"
                        disabled={!currentLabel.value || !currentLabel.field || !currentLabel.condition || !currentLabel.name}
                        id={`${filtersId}_${namesData.buttons.addLabel}_${buttonId}`}
                        variant="text"
                        onClick={addFilteredLabelHandler}
                    >
                        <Box
                            alignItems="center"
                            display="flex"
                            gap={1}
                        >
                            <AddIcon />
                            <Typography
                                fontWeight="bold"
                                variant="caption"
                            >
                                {t("actions.addLabel")}
                            </Typography>
                        </Box>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default DriveManagementLabelsFilters;

DriveManagementLabelsFilters.propTypes = {
    filteredLabels: PropTypes.array,
    labels: PropTypes.array,
    setFilteredLabels: PropTypes.func,
    setValue: PropTypes.func,
};
