import { useAuth0 } from "@auth0/auth0-react";
import { constantsData, urlsData } from "data";
import { useApisClient } from "hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useDriveManagementUserPermissionsList = (fileId) => {
    const [data, setData] = useState([]);

    const [isOrganizer, setIsOrganizer] = useState(false);

    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const { logout } = useAuth0();

    const dispatch = useDispatch();

    const { get } = useApisClient();

    const {
        files: { url: driveManagementFilesApiUrl },
        users: { permissions: driveManagementUsersPermissionsApiUrl },
    } = urlsData.apis.driveManagement;

    useEffect(() => {
        setLoading(true);

        get(
            `${driveManagementFilesApiUrl}/${fileId}/${driveManagementUsersPermissionsApiUrl}`,
            null,
            {
                fields: "*",
            },
        ).
            then(({ data: responseData }) => {
                setData(responseData?.permissions);

                setIsOrganizer(responseData.isOrganizer);
            })["catch"]((err) => {
                displayErrorHandler(
                    err.response.data.message === constantsData.networkError ? t("messages.networkFailure") : err.response.data.message,
                    dispatch,
                    openAlert,
                    err.response.status,
                    logout,
                );
            })["finally"](() => {
                setLoading(false);
            });
    },[]); //eslint-disable-line

    return {
        data,
        isOrganizer,
        loading,
    };
};

export default useDriveManagementUserPermissionsList;
