import {
    Avatar,
    Box,
    Checkbox,
    TableRow,
} from "@mui/material";
import { Modal, TableList, TableMenu } from "atoms";
import {
    actionsData,
    identifiersData,
    inputData,
    tableHeadCellsData,
    urlsData,
} from "data";
import { useDeleteArtist, useExportArtists, useSelectTableRows } from "hooks";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { StyledLink, StyledTableCell } from "styles";

const ArtistsSearchList = ({
    count,
    data,
    limit,
    page,
    setLimit,
    setPage,
}) => {
    const { t } = useTranslation();

    const location = useLocation();

    const searchValues = useSelector((state) => state.searchReducer.searchValues);

    const searchCategory = useSelector((state) => state.searchReducer.searchCategory);

    const {
        isSelected,
        selectAllHandler,
        selectRowHandler,
        selected,
        setSelected,
    } = useSelectTableRows();

    const { deleteArtist } = useDeleteArtist(
        page,
        limit,
        true,
        {
            category: searchCategory || 1,
            values: searchValues,
        },
    );

    const { exportArtists } = useExportArtists();

    const [modalData, setModalData] = useState({
        action: deleteArtist,
        actionType: actionsData["delete"],
        body: t(
            "messages.deleteMsgs.alert",
            { entity: t("artists.entity") },
        ),
        id: null,
        open: false,
        title: t("artists.deleteArtist"),
    });

    const {
        edit: editArtistRouteUrl,
        url: artistsRouteUrl,
    } = urlsData.routes.artists;

    const {
        input: inputId,
        link: linkId,
        table: tableId,
    } = identifiersData.ids;

    return (
        <Box
            alignItems="end"
            display="flex"
            flexDirection="column"
            mt={5}
        >
            <Modal
                modalData={modalData}
                setModalData={setModalData}
            />
            <TableList
                count={count}
                exportAction={exportArtists}
                headCells={tableHeadCellsData.artists}
                limit={limit}
                page={page}
                selectAllAction={selectAllHandler}
                selected={selected}
                setLimit={setLimit}
                setPage={setPage}
                setSelected={setSelected}
                tableData={data}
                hasExport
                hasSearchMode
                isSelectable
            >
                {data?.map(({
                    artistImage: image,
                    code,
                    country,
                    id,
                    name,
                    name_ar: nameAr,
                    releases,
                    tracks,
                }, index) => (
                    <TableRow
                        key={id}
                        role="checkbox"
                        selected={isSelected(id)}
                        tabIndex={-1}
                        aria-checked
                        hover
                        onClick={() => selectRowHandler(id)}
                    >
                        <StyledTableCell padding="checkbox">
                            <Checkbox
                                checked={isSelected(id)}
                                color="primary"
                                id={`${tableId}_${inputData.names.select}_${inputId}_${index + 1}`}
                            />
                        </StyledTableCell>
                        <StyledTableCell>
                            <Avatar
                                alt={t("imageAlternatives.artist")}
                                imgProps={{ loading: "lazy" }}
                                src={`${image?.downloadThumbnail ? window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_DOMAIN + image?.downloadThumbnail : ""}`} // eslint-disable-line
                            />
                        </StyledTableCell>
                        <StyledTableCell boldCell={+true}>
                            <StyledLink
                                id={`${tableId}_${linkId}_${index + 1}`}
                                state={{ from: location.pathname + location.search }}
                                to={`${artistsRouteUrl}/${id}`}
                            >
                                {name}
                            </StyledLink>
                        </StyledTableCell>
                        <StyledTableCell>{nameAr}</StyledTableCell>
                        <StyledTableCell>{code}</StyledTableCell>
                        <StyledTableCell>{country?.en_name}</StyledTableCell>
                        <StyledTableCell>{releases?.length}</StyledTableCell>
                        <StyledTableCell>{tracks?.length}</StyledTableCell>
                        <StyledTableCell onClick={(e) => e.stopPropagation()}>
                            <TableMenu
                                editRouteUrl={`${editArtistRouteUrl}/${id}`}
                                rowId={index + 1}
                                setOpenModal={() => {
                                    setModalData({
                                        ...modalData,
                                        id,
                                        open: true,
                                    });
                                }}
                                hasDelete
                                hasEdit
                            />
                        </StyledTableCell>
                    </TableRow>
                ))}
            </TableList>
        </Box>
    );
};

export default ArtistsSearchList;

ArtistsSearchList.propTypes = {
    count: PropTypes.number,
    data: PropTypes.array,
    limit: PropTypes.number,
    page: PropTypes.number,
    setLimit: PropTypes.func,
    setPage: PropTypes.func,
};
