import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { identifiersData } from "data";

const {
    classNames: { controls: controlsClassName },
    props: { showControls: showControlsProp },
} = identifiersData;

const StyledVideoPlayer = styled(
    Box,
    { shouldForwardProp: (prop) => prop !== showControlsProp },
)(({
    showControls,
    theme,
}) => ({
    [`& .${controlsClassName}`]: {
        "& .MuiSlider-root": {
            "& .MuiSlider-thumb": {
                height: 15,
                width: 15,
            },
        },
        "& svg": {
            color: theme?.palette?.primary?.main,
            cursor: "pointer",
        },
        alignItems: "center",
        background: theme?.palette?.grey[200],
        bottom: 0,
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-around",
        opacity: showControls ? 1 : 0.8,
        position: "absolute",
        transition: "ease-in-out .2s",
        width: "100%",
    },
    position: "relative",
}));

export default StyledVideoPlayer;
