import fileData from "data/file";
import inputData from "data/input";

const { revenueReportTemplate: revenueReportTemplateFileName } = fileData.names;

const {
    file: fileInputType,
    text: textInputType,
} = inputData.types;

const { templateName: templateNameInputName } = inputData.names;

const {
    revenueReportTemplate: revenueReportTemplateInputLabel,
    templateName: templateNameInputLabel,
} = inputData.labels;

const importRevenueReportTemplateFormData = [
    {
        col: { xs: 12 },
        label: templateNameInputLabel,
        name: templateNameInputName,
        type: textInputType,
    },
    {
        col: { xs: 12 },
        label: revenueReportTemplateInputLabel,
        name: revenueReportTemplateFileName,
        type: fileInputType,
    },
];

export default importRevenueReportTemplateFormData;
