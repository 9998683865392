import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData } from "data";
import { editClientFoldersStructureFolderMutation, getClientQuery } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useEditClientFoldersStructureFolder = (clientId) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [editClientFoldersStructureFolderAction, { loading }] = useMutation(
        editClientFoldersStructureFolderMutation,
        {
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: getClientQuery,
                    variables: {
                        where: { id: clientId },
                    },
                },
            ],
        },
    );

    const editClientFoldersStructureFolder = async ({
        id,
        isSelection,
        name,
        parentPath,
    }) => {
        try {
            await editClientFoldersStructureFolderAction({
                onCompleted: () => {
                    dispatch(openAlert(
                        t(
                            "messages.editMsgs.success",
                            { entity: t("labels.folder") },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: {
                    clientId,
                    id,
                    isSelection,
                    name,
                    parentPath,
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        editClientFoldersStructureFolder,
        loading,
    };
};

export default useEditClientFoldersStructureFolder;
