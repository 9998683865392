const importerConfigData = {
    msgs: {
        albumCodeIsMissing: "Album Code is missing",
        albumNameArIsMissing: "Album Name Ar is missing",
        albumNameEnIsMissing: "Album Name EN is missing",
        artistCodeExceedLimit: "Artist Code exceeds limit",
        artistCodeIsMissing: "Artist Code is missing",
        artistNameArIsMissing: "Artist Name Ar is missing",
        artistNameEnIsMissing: "Artist Name EN is missing",
        artistNationalityIsMissing: "Artist Nationality is missing",
        artistNationalityNotExist: "Artist Nationality does not exist in database",
        cLineIsMissing: "C Line is missing",
        customIdIsMissing: "Custom Id is missing",
        docuemntTypeNotExist: "Document Type does not exist in database",
        documentFileIsMissing: "Document File is missing",
        genreIsMissing: "Genre is missing",
        genreNotExist: "Genre does not exist in database",
        invalidAlbumCode: "Invalid Album Code",
        invalidArtistCode: "Invalid Artist Code",
        invalidCustomId: "Invalid Custom Id",
        invalidDiscNumber: "Invalid Disc Number",
        invalidEndDate: "Invalid End Date",
        invalidIsrcLength: "Invalid Isrc Length",
        invalidReleaseDate: "Invalid Release Date",
        invalidTrackNumber: "Invalid Track NumBER",
        invalidUpcLength: "Invalid Upc Length",
        isrcIsMissing: "Isrc is missing",
        labelIsMissing: "Label is missing",
        pLineIsMissing: "P Line is missing",
        releaseDateIsMissing: "Release Date is missing",
        releaseImageIsMissing: "Release Image is missing",
        releaseTypeIsMissing: "Release Type is missing",
        releaseTypeNotExist: "Release Type does not exist in database",
        rightTypeIsMissing: "Right Type is missing",
        rightTypeNotExist: "Right Type does not exist in database",
        territoryIsMissing: "Territory is missing",
        territoryNotExist: "Territory does not exist in database",
        trackFileIsMissing: "Track File is missing",
        trackNumberIsMissing: "Track Number is missing",
        trackTitleArIsMissing: "Track Title Ar is missing",
        trackTitleEnIsMissing: "Track Title EN is missing",
        upcIsMissing: "upc is missing",
        videoFileIsMissing: "Video File is missing",
        videoTypeIsMissing: "Video Type is missing",
        videoTypeNotExist: "Video Type does not exist in database",
    },
    steps: {
        addingTrack: "addingTrack",
        creatingArtist: "creatingArtist",
        creatingDocument: "creatingDocument",
        creatingNote: "creatingNote",
        creatingOwnership: "creatingOwnership",
        creatingRelease: "creatingRelease",
        creatingReleaseImage: "creatingReleaseImage",
        creatingSecondaryArtist: "creatingSecondaryArtist",
        creatingTrack: "creatingTrack",
        creatingVideo: "creatingVideo",
        searchingArtists: "searchingArtists",
        searchingTracks: "searchingTracks",
        uploadingDocumentFile: "uploadingDocumentFile",
        uploadingReleaseImage: "uploadingReleaseImage",
        uploadingTrackFile: "uploadingTrackFile",
        uploadingVideoFile: "uploadingVideoFile",
    },
};

export default importerConfigData;
