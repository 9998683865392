import { useAuth0 } from "@auth0/auth0-react";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import {
    Box,
    Button,
    Divider,
    Menu,
    Zoom,
} from "@mui/material";
import { MenuItem, Modal } from "atoms";
import { DownloadReleaseClientXmlFileForm } from "components";
import {
    identifiersData,
    namesData,
    permissionsData,
    urlsData,
} from "data";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { canDo } from "utils";

const DetailsMenu = ({
    children,
    copyLinkAction,
    editAction,
    editRouteUrl,
    hasDownloadXmlFile,
    hasShareLink,
    isReleaseHeader,
    setOpenModal,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const [modalData, setModalData] = useState({
        body: null,
        id: null,
        open: false,
        title: "",
    });
    const { t } = useTranslation();

    const navigate = useNavigate();

    const location = useLocation();

    const { user } = useAuth0();

    const open = Boolean(anchorEl);

    const { url: usersRouteUrl } = urlsData.routes.users;

    const {
        accountDelete: accountDeletePermission,
        accountEdit: accountEditPermission,
        mediaDelete: mediaDeletePermission,
        mediaEdit: mediaEditPermission,
        mediaRead: mediaReadPermission,
    } = permissionsData.permissions.keys;

    const {
        button: buttonId,
        menu: menuId,
        option: optionId,
    } = identifiersData.ids;

    const {
        menus: { details: detailsMenuName },
        options: {
            delete: deleteOptionName,
            downloadXmlFile: downloadXmlFileOptionName,
            edit: editOptionName,
            shareLink: shareLinkOptionName,
        },
    } = namesData;

    let allowedEditPermissions = [];

    let allowedDeletePermissions = [];

    if (isReleaseHeader) allowedEditPermissions = [mediaReadPermission];
    else allowedEditPermissions = location.pathname === usersRouteUrl ? [accountEditPermission] : [mediaEditPermission];

    if (location.pathname === usersRouteUrl) allowedDeletePermissions = [accountDeletePermission];
    else allowedDeletePermissions = [mediaDeletePermission];

    const openMenuHandler = (event) => setAnchorEl(event.currentTarget);

    const closeMenuHandler = () => setAnchorEl(null);

    return (
        <Box
            display="flex"
            width="100%"
            justifyContent={{
                sm: "end",
                xs: "start",
            }}
        >
            <Modal
                modalData={modalData}
                setModalData={setModalData}
            />
            <Button
                id={`${detailsMenuName}_${menuId}_${buttonId}`}
                variant="contained"
                endIcon={(
                    <KeyboardArrowDownIcon
                        sx={{
                            transform: open && "rotate(180deg)",
                            transition: "ease-in-out .2s",
                        }}
                    />
                )}
                onClick={openMenuHandler}
            >
                {t("labels.actions")}
            </Button>
            <Menu
                TransitionComponent={Zoom}
                anchorEl={anchorEl}
                open={open}
                onClose={closeMenuHandler}
            >
                <Box
                    id={`${detailsMenuName}_${menuId}`}
                    onClick={closeMenuHandler}
                >
                    {hasShareLink && (
                        <MenuItem
                            icon={<ShareOutlinedIcon />}
                            id={`${detailsMenuName}_${menuId}_${shareLinkOptionName}_${optionId}`}
                            label={t("actions.shareLink")}
                            onClick={() => copyLinkAction()}
                        />
                    )}
                    {hasDownloadXmlFile && (
                        <MenuItem
                            icon={<SimCardDownloadOutlinedIcon />}
                            id={`${detailsMenuName}_${menuId}_${downloadXmlFileOptionName}_${optionId}`}
                            label={t("actions.downloadXmlFile")}
                            onClick={() => {
                                setModalData({
                                    body: <DownloadReleaseClientXmlFileForm setModalData={setModalData} />,
                                    hasComponent: true,
                                    id: null,
                                    open: true,
                                    title: t("labels.downloadXmlFile"),
                                });
                            }}
                        />
                    )}
                    {children}
                    <MenuItem
                        icon={<EditOutlinedIcon />}
                        id={`${detailsMenuName}_${menuId}_${editOptionName}_${optionId}`} // eslint-disable-line
                        label={t("actions.edit")}
                        disabled={!canDo(
                            user,
                            allowedEditPermissions,
                        )}
                        onClick={() => (editRouteUrl ? navigate(
                            editRouteUrl,
                            {
                                state:
                                { from: location.pathname + location.search },
                            },
                        ) : editAction())}
                    />
                    <Divider />
                    <MenuItem
                        icon={<DeleteForeverOutlinedIcon />}
                        id={`${detailsMenuName}_${menuId}_${deleteOptionName}_${optionId}`} // eslint-disable-line
                        label={t("actions.delete")}
                        disabled={!canDo(
                            user,
                            allowedDeletePermissions,
                        )}
                        isDelete
                        onClick={setOpenModal}
                    />
                </Box>
            </Menu>
        </Box>
    );
};

export default DetailsMenu;

DetailsMenu.propTypes = {
    children: PropTypes.node,
    copyLinkAction: PropTypes.func,
    editAction: PropTypes.func,
    editRouteUrl: PropTypes.string,
    hasDownloadXmlFile: PropTypes.bool,
    hasShareLink: PropTypes.bool,
    isReleaseHeader: PropTypes.bool,
    setOpenModal: PropTypes.func,
};
