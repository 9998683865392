import { useAuth0 } from "@auth0/auth0-react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PageviewIcon from "@mui/icons-material/Pageview";
import {
    Avatar,
    Box,
    Button,
    CssBaseline,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Zoom,
} from "@mui/material";
import Logo from "assets/images/logo.png";
import { SearchInput, SkeletonLoader } from "atoms";
import {
    identifiersData,
    namesData,
    sidebarData,
    urlsData,
} from "data";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { StyledSidebar, StyledSidebarHeader } from "styles";
import { canDo, hasRole } from "utils";

const Sidebar = ({
    open,
    setOpen,
}) => {
    const { t } = useTranslation();

    const sidebarActiveTab = useSelector((state) => state.sidebarActiveTabReducer.activeTab);

    const track = useSelector((state) => state.tracksReducer).playedTrack;

    const {
        isLoading,
        user,
    } = useAuth0();

    const filteredSidebarData = sidebarData.tabs.filter(({
        hasRoles,
        oneOfPermissions,
        permissions,
        roles,
    }) => (hasRoles ? hasRole(
        user,
        roles,
    ) : canDo(
        user,
        permissions,
        oneOfPermissions,
    )));

    const {
        classNames: { active: activeClassName },
        ids: {
            button: buttonId,
            sidebar: sidebarId,
        },
    } = identifiersData;

    const {
        closeSidebar: closeSidebarButtonName,
        search: searchButtonName,
    } = namesData.buttons;

    return (
        <Box display="flex">
            <CssBaseline />
            <StyledSidebar
                open={open}
                variant="permanent"
                PaperProps={{
                    sx: { height: track?.downloadUri && "calc(100% - 60px) !important" },
                }}
            >
                <StyledSidebarHeader>
                    <Zoom in={open}>
                        <Link to={urlsData.routes.home}>
                            <Avatar
                                alt={t("imageAlternatives.logo")}
                                imgProps={{ loading: "lazy" }}
                                src={Logo}
                                sx={{
                                    borderRadius: 1,
                                    height: 35,
                                    width: "auto",
                                }}
                            />
                        </Link>
                    </Zoom>
                    <Tooltip title={t("actions.closeSidebar")}>
                        <IconButton
                            id={`${closeSidebarButtonName}_${buttonId}`}
                            onClick={() => setOpen(false)}
                        >
                            <ChevronLeftIcon />
                        </IconButton>
                    </Tooltip>
                </StyledSidebarHeader>
                <Divider />
                <List>
                    {open ? <Box px={1}><SearchInput /></Box> : (
                        <Tooltip
                            placement="right"
                            title={t("search.name")}
                        >
                            <ListItem
                                sx={{
                                    display: {
                                        sm: "none",
                                        xs: "block",
                                    },
                                }}
                                disablePadding
                            >
                                <ListItemButton
                                    component={Button}
                                    id={`${sidebarId}_${searchButtonName}_${buttonId}`}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                    onClick={() => setOpen(true)}
                                >
                                    <ListItemIcon
                                        sx={{
                                            justifyContent: "center",
                                            minWidth: 0,
                                        }}
                                    >
                                        <PageviewIcon />
                                    </ListItemIcon>
                                </ListItemButton>
                            </ListItem>
                        </Tooltip>
                    )}
                    {isLoading ? sidebarData.tabs.map(({ key }) => ( // eslint-disable-line
                        <Box
                            alignItems="center"
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            key={key}
                        >
                            <SkeletonLoader
                                count={1}
                                height={50}
                                width={open ? 200 : 50}
                            />
                        </Box>
                    )) : filteredSidebarData.length > 0 ? filteredSidebarData.map(({
                        Icon,
                        id,
                        key,
                        routeUrl,
                    }) => (
                        <Tooltip
                            key={id}
                            placement="right"
                            title={t(`${key}.name`)}
                        >
                            <ListItem
                                key={id}
                                sx={{ display: "block" }}
                                disablePadding
                            >
                                <ListItemButton
                                    className={sidebarActiveTab.id === id ? activeClassName : ""}
                                    component={Link}
                                    id={`${sidebarId}_${key}_${buttonId}`}
                                    to={routeUrl}
                                    sx={{
                                        justifyContent: open ? "initial" : "center",
                                        minHeight: 40,
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            justifyContent: "center",
                                            minWidth: 0,
                                            mr: open ? 3 : "auto",
                                        }}
                                    >
                                        <Icon color={sidebarActiveTab.id === id ? "primary" : ""} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={t(`${key}.name`)}
                                        sx={{ opacity: open ? 1 : 0 }}
                                    />
                                </ListItemButton>
                            </ListItem>
                        </Tooltip>
                    )) : null}
                </List>
            </StyledSidebar>
        </Box>
    );
};

export default Sidebar;

Sidebar.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
};
