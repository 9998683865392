import inputData from "data/input";

const { text: textInputType } = inputData.types;

const {
    email: emailInputName,
    name: nameInputName,
} = inputData.names;

const {
    email: emailInputLabel,
    name: nameInputLabel,
} = inputData.labels;

const groupFormData = [
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: nameInputLabel,
        name: nameInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: emailInputLabel,
        name: emailInputName,
        type: textInputType,
    },
];

export default groupFormData;
