import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import {
    Avatar,
    Box,
    Checkbox,
    TableRow,
    Typography,
} from "@mui/material";
import {
    MenuItem,
    Modal,
    TableList,
    TableMenu,
} from "atoms";
import { TrackDrawer } from "components";
import {
    actionsData,
    identifiersData,
    inputData,
    namesData,
    tableHeadCellsData,
    urlsData,
} from "data";
import {
    useDeleteTrack,
    useDownloadTracksContent,
    useExportTracks,
    useSelectTableRows,
} from "hooks";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setCurrentPlayedTrack, toggleTrackDrawer } from "redux/actions";
import { StyledLink, StyledTableCell } from "styles";
import { convertHourToDurationFormatHandler } from "utils";

const TracksSearchList = ({
    count,
    data,
    limit,
    page,
    setLimit,
    setPage,
}) => {
    const [trackId, setTrackId] = useState(null);

    const { t } = useTranslation();

    const { downloadTracksContent } = useDownloadTracksContent();

    const dispatch = useDispatch();

    const location = useLocation();

    const searchValues = useSelector((state) => state.searchReducer.searchValues);

    const searchCategory = useSelector((state) => state.searchReducer.searchCategory);

    const {
        isSelected,
        selectAllHandler,
        selectRowHandler,
        selected,
        setSelected,
    } = useSelectTableRows();

    const { deleteTrack } = useDeleteTrack(
        null,
        limit,
        page,
        true,
        {
            category: searchCategory || 1,
            values: searchValues,
        },
    );

    const { exportTracks } = useExportTracks();

    const [modalData, setModalData] = useState({
        action: deleteTrack,
        actionType: actionsData["delete"],
        body: t(
            "messages.deleteMsgs.alert",
            { entity: t("tracks.entity") },
        ),
        id: null,
        open: false,
        title: t("tracks.deleteTrack"),
    });

    const {
        artists: { url: artistsRouteUrl },
        tracks: { url: tracksRouteUrl },
    } = urlsData.routes;

    const {
        input: inputId,
        link: linkId,
        menu: menuId,
        option: optionId,
        table: tableId,
    } = identifiersData.ids;

    const {
        links: {
            primaryArtist: primaryArtistLinkName,
            secondaryArtist: secondaryArtistLinkName,
        },
        menus: { table: tableMenuName },
        options: {
            downloadTrack: downloadTrackOptionName,
            playTrack: playTrackOptionName,
        },
    } = namesData;

    return (
        <Box
            alignItems="end"
            display="flex"
            flexDirection="column"
            mt={5}
        >
            <TrackDrawer
                id={trackId}
                setId={setTrackId}
            />
            <Modal
                modalData={modalData}
                setModalData={setModalData}
            />
            <TableList
                count={count}
                downloadContentAction={downloadTracksContent}
                exportAction={exportTracks}
                headCells={tableHeadCellsData.tracks.list}
                limit={limit}
                page={page}
                selectAllAction={selectAllHandler}
                selected={selected}
                setLimit={setLimit}
                setPage={setPage}
                setSelected={setSelected}
                tableData={data}
                hasDownloadContent
                hasExport
                hasSearchMode
                isSelectable
            >
                {data?.map(({
                    artist,
                    downloadUri,
                    id,
                    image,
                    isrc,
                    secondary_artists: secondaryArtists,
                    title,
                    title_ar: titleAr,
                    track_num: trackNumber,
                    trackFile,
                }, index) => (
                    <TableRow
                        key={id}
                        role="checkbox"
                        selected={isSelected(id)}
                        tabIndex={-1}
                        aria-checked
                        hover
                        onClick={() => selectRowHandler(id)}
                    >
                        <StyledTableCell padding="checkbox">
                            <Checkbox
                                checked={isSelected(id)}
                                color="primary"
                                id={`${tableId}_${inputData.names.select}_${inputId}_${index + 1}`}
                            />
                        </StyledTableCell>
                        <StyledTableCell>
                            <Avatar
                                alt={t("imageAlternatives.track")}
                                imgProps={{ loading: "lazy" }}
                                src={`${image?.downloadThumbnail ? window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_DOMAIN + image?.downloadThumbnail : ""}`} // eslint-disable-line
                            >
                                <LibraryMusicIcon />
                            </Avatar>
                        </StyledTableCell>
                        <StyledTableCell boldCell={+true}>
                            <StyledLink
                                id={`${tableId}_${linkId}_${index + 1}`}
                                state={{ from: location.pathname + location.search }}
                                to={`${tracksRouteUrl}/${id}`}
                            >
                                {title}
                            </StyledLink>
                        </StyledTableCell>
                        <StyledTableCell>{titleAr}</StyledTableCell>
                        <StyledTableCell>{trackNumber}</StyledTableCell>
                        <StyledTableCell>
                            <time>{convertHourToDurationFormatHandler(trackFile?.duration)}</time>
                        </StyledTableCell>
                        <StyledTableCell>
                            <StyledLink
                                id={`${tableId}_${primaryArtistLinkName}_${linkId}_${index + 1}`}
                                state={{ from: location.pathname + location.search }}
                                to={`${artistsRouteUrl}/${artist?.id}`}
                            >
                                {artist?.name}
                            </StyledLink>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Box
                                alignItems="start"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                            >
                                {secondaryArtists?.length === 0 ? <Typography variant="body1">{t("labels.N/A")}</Typography> : secondaryArtists?.map(({
                                    id: secondaryArtistId,
                                    name,
                                }, secondaryArtistIndex) => (
                                    <StyledLink
                                        id={`${tableId}_${secondaryArtistLinkName}_${linkId}_${index + 1}_${secondaryArtistIndex + 1}`}
                                        key={secondaryArtistId}
                                        state={{ from: location.pathname + location.search }}
                                        to={`${artistsRouteUrl}/${secondaryArtistId}`}
                                    >
                                        {name}
                                    </StyledLink>
                                ))}
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell>{isrc}</StyledTableCell>
                        <StyledTableCell onClick={(e) => e.stopPropagation()}>
                            <TableMenu
                                rowId={index + 1}
                                editAction={() => {
                                    setTrackId(id);

                                    dispatch(toggleTrackDrawer(true));
                                }}
                                setOpenModal={() => {
                                    setModalData({
                                        ...modalData,
                                        id,
                                        open: true,
                                    });
                                }}
                                hasDelete
                                hasEdit
                            >
                                <MenuItem
                                    icon={<PlayCircleFilledWhiteOutlinedIcon />}
                                    id={`${tableMenuName}_${menuId}_${index + 1}_${playTrackOptionName}_${optionId}`}
                                    label={t("tracks.playTrack")}
                                    onClick={() => dispatch(setCurrentPlayedTrack({
                                        artist,
                                        downloadUri,
                                        image,
                                        title,
                                    }))}
                                />
                                <a
                                    href={`${window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_DOMAIN}${downloadUri}`} // eslint-disable-line
                                    style={{
                                        display: "flex",
                                        gap: "8px",
                                        textDecoration: "none",
                                    }}
                                >
                                    <MenuItem
                                        icon={<DownloadOutlinedIcon />}
                                        id={`${tableMenuName}_${menuId}_${index + 1}_${downloadTrackOptionName}_${optionId}`}
                                        label={t("tracks.downloadTrack")}
                                    />
                                </a>
                            </TableMenu>
                        </StyledTableCell>
                    </TableRow>
                ))}
            </TableList>
        </Box>
    );
};

export default TracksSearchList;

TracksSearchList.propTypes = {
    count: PropTypes.number,
    data: PropTypes.array,
    limit: PropTypes.number,
    page: PropTypes.number,
    setLimit: PropTypes.func,
    setPage: PropTypes.func,
};
