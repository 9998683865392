import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { constantsData } from "data";
import { getPermissionsListQuery } from "graph";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const usePermissionsList = (id) => {
    const [initialPermissions, setInitialPermissions] = useState({
        id: null,
        permissions: {},
    });

    const [permissions, setPermissions] = useState({
        id: null,
        permissions: {},
    });

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const { isAuthenticated } = useAuth0();

    const {
        data,
        error,
        loading,
        refetch,
    } = useQuery(
        getPermissionsListQuery,
        {
            onCompleted: ({ listPermissionsByRole }) => {
                setInitialPermissions(listPermissionsByRole.data);

                setPermissions(listPermissionsByRole.data);
            },
            skip: !isAuthenticated,
            variables: {
                roleId: { id },
            },
        },
    );

    useEffect(
        () => {
            if (error?.message === constantsData.failedToFetch && !loading) {
                displayErrorHandler(
                    t("messages.networkFailure"),
                    dispatch,
                    openAlert,
                );
            }
        },
        [error,data], // eslint-disable-line
    );

    return {
        initialPermissions,
        loading,
        permissions,
        refetch,
        setPermissions,
    };
};

export default usePermissionsList;
