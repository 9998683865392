import { useMutation } from "@apollo/client";
import {
    constantsData,
    identifiersData,
    namesData,
    statusTypesData,
    urlsData,
} from "data";
import { deleteReleaseMutation, getReleasesListQuery, getSearchListQuery } from "graph";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useDeleteRelease = (
    completed,
    page,
    limit,
    artistId,
    hasSearchMode,
    searchData,
    redirect,
    withoutRefetch,
    withoutAlert,
) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [deleteReleaseAction, {
        data,
        loading,
    }] = useMutation(
        deleteReleaseMutation,
        {
            awaitRefetchQueries: true,
            ...!withoutRefetch && {
                refetchQueries: [
                    {
                        query: hasSearchMode ? getSearchListQuery : getReleasesListQuery,
                        variables: hasSearchMode ? {
                            entityId: parseInt(searchData?.category),
                            limit,
                            page,
                            searchValues: searchData?.values,
                        } : {
                            limit,
                        orderBy: { release_date: "desc" }, //eslint-disable-line
                            page,
                            where: {
                                isCompleted: { equals: completed },
                                ...artistId && {
                                    OR: [
                                        {
                                        artist_id: { equals: artistId }, //eslint-disable-line
                                        },
                                    ],
                                },
                            },
                        },
                    },
                ],
            },
        },
    );

    const { deleteReleasePending: deleteReleasePendingToastName } = namesData.toasts;

    const { toast: toastId } = identifiersData.ids;

    const deleteRelease = async (id) => {
        try {
            await deleteReleaseAction({

                onCompleted: () => {
                    if (redirect) navigate(urlsData.routes.releases.url);

                    if (!withoutAlert) {
                        dispatch(openAlert(
                            t(
                                "messages.deleteMsgs.success",
                                { entity: t("releases.entity") },
                            ),
                            statusTypesData.success,
                        ));
                    }
                },
                variables: {
                    where: { id },
                },

            });
        } catch (err) {
            if (!withoutAlert) {
                displayErrorHandler(
                    err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                    dispatch,
                    openAlert,
                );
            }
        } finally {
            toast.dismiss(`${deleteReleasePendingToastName}_${toastId}`);
        }
    };

    useEffect(
        () => {
            if (loading && !withoutAlert) {
                toast.info(
                    () => t(
                        "messages.deleteMsgs.pending",
                        { entity: t("releases.entity") },
                    ),
                    { toastId: `${deleteReleasePendingToastName}_${toastId}` },
                );
            }
        },
        [loading], //eslint-disable-line
    );

    return {
        data,
        deleteRelease,
        loading,
    };
};

export default useDeleteRelease;
