import { yupResolver } from "@hookform/resolvers/yup";
import { AutoCompleteMenu } from "atoms";
import { FormContainer } from "components";
import { foldersStructureRootFolderFormData } from "data";
import { PropTypes } from "prop-types";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useFoldersStructureRootFolderSchema } from "schemas";
import { renderFormControllerHandler } from "utils";

const FoldersStructureRootFolderForm = ({
    action,
    onSubmitForm,
    setModalData,
    values,
}) => {
    const selections = useSelector((state) => state.selectionsReducer);

    const schema = useFoldersStructureRootFolderSchema();

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const {
        control,
        formState: { errors },
    } = formMethods;

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        ___,
        __,
        selectionKey,
    ) => (
        <AutoCompleteMenu
            defaultValue={selections?.[selectionKey]?.find((option) => option?.value === values?.[name])}
            errors={errors}
            getRenderedOption={(option) => option?.label}
            label={label}
            name={name}
            optionLabel={(option) => option?.label}
            options={selections?.[selectionKey]}
            onChange={(_, option) => field.onChange(option?.value)}
        />
    );

    return (
        <FormContainer
            action={action}
            formMethods={formMethods}
            setModalData={setModalData}
            data={renderFormControllerHandler(
                control,
                foldersStructureRootFolderFormData,
                renderFormControllerChildrenHandler,
            )}
            hasCancelButton
            withoutShadow
            onSubmitForm={onSubmitForm}
        />
    );
};

export default FoldersStructureRootFolderForm;

FoldersStructureRootFolderForm.propTypes = {
    action: PropTypes.string,
    onSubmitForm: PropTypes.func,
    setModalData: PropTypes.func,
    values: PropTypes.object,
};
