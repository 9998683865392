import { yupResolver } from "@hookform/resolvers/yup";
import { FormContainer } from "components";
import { groupFormData, identifiersData } from "data";
import { PropTypes } from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useGroupSchema } from "schemas";
import { StyledTextField } from "styles";
import { renderFormControllerHandler } from "utils";

const GroupForm = ({
    action,
    loading,
    onSubmitForm,
    values,
}) => {
    const { t } = useTranslation();

    const schema = useGroupSchema();

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const {
        control,
        formState: { errors },
    } = formMethods;

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
    ) => (
        <StyledTextField
            {...field}
            error={errors[name]}
            helperText={errors[name] && errors[name]?.message}
            id={`${name}_${identifiersData.ids.input}`}
            label={t(`labels.${label}`)}
            variant="outlined"
            fullWidth
        />
    );

    return (
        <FormContainer
            action={action}
            formMethods={formMethods}
            loading={loading}
            data={renderFormControllerHandler(
                control,
                groupFormData,
                renderFormControllerChildrenHandler,
            )}
            onSubmitForm={onSubmitForm}
        />
    );
};

export default GroupForm;

GroupForm.propTypes = {
    action: PropTypes.string,
    loading: PropTypes.bool,
    onSubmitForm: PropTypes.func,
    values: PropTypes.object,

};
