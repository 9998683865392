import styled from "@emotion/styled";
import { TextField } from "@mui/material";

const StyledDebouncedSearchInput = styled(TextField)(() => ({
    "& .MuiInputBase-root": {
        borderRadius: "50px !important",
        height: 40,
    },
    "@media(min-width: 700px) ": {
        minWidth: "300px",
        width: " 50%",
    },
    transition: "ease-in-out .2s !important",
    width: " 100%",
}));

export default StyledDebouncedSearchInput;
