import { useAuth0 } from "@auth0/auth0-react";
import ReplayIcon from "@mui/icons-material/Replay";
import {
    Box,
    TableRow,
    Typography,
} from "@mui/material";
import {
    Loader,
    MenuItem,
    Modal,
    TableList,
    TableMenu,
} from "atoms";
import { DistributionDrawer, PageHead } from "components";
import {
    actionsData,
    identifiersData,
    namesData,
    permissionsData,
    statusTypesData,
    tableHeadCellsData,
} from "data";
import { useDeleteDistribution, useDistributionsList, useRetryFailedDistribution } from "hooks";
import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toggleDistributionDrawer } from "redux/actions";
import { StyledProgressBar, StyledTableCell } from "styles";
import StyledChip from "styles/chip";
import { canDo, convertDateToLocaleStringFormatHandler, convertHourToDurationFormatHandler } from "utils";

const DistributionsList = ({ releaseId }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const {
        count,
        data,
        limit,
        loading,
        page,
        setLimit,
        setPage,
        startPolling,
        stopPolling,
    } = useDistributionsList(parseInt(releaseId));

    const { retryFailedDistribution } = useRetryFailedDistribution(
        parseInt(releaseId),
        limit,
        page,
    );

    const { deleteDistribution } = useDeleteDistribution(
        parseInt(releaseId),
        limit,
        page,
    );

    const { user } = useAuth0();

    const {
        active,
        delayed,
        waiting,
    } = statusTypesData;

    const [modalData, setModalData] = useState({
        action: deleteDistribution,
        actionType: actionsData["delete"],
        body: t(
            "messages.deleteMsgs.alert",
            { entity: `${t("releases.entity")} ${t("labels.distribution")}` },
        ),
        id: null,
        open: false,
        title: t("releases.deleteDistribution"),
    });

    const { mediaCreate: mediaCreatePermission } = permissionsData.permissions.keys;

    const {
        menu: menuId,
        option: optionId,
    } = identifiersData.ids;

    const {
        menus: { table: tableMenuName },
        options: { retryDistribution: retryDistributionOptionName },
    } = namesData;

    useEffect(
        () => {
            if (data) {
                if (data?.filter((distribution) => distribution.status === active || distribution.status === waiting || distribution.status === delayed)?.length === 0) stopPolling();
                else startPolling(7000);
            }
        },
        [data], // eslint-disable-line
    );

    if (loading) return <Loader withoutFullHeight={!!releaseId} />;

    return (
        <Box>
            <Modal
                modalData={modalData}
                setModalData={setModalData}
            />
            <DistributionDrawer
                limit={limit}
                page={page}
                hasRefetchDistributions
            />
            <PageHead
                addAction={() => dispatch(toggleDistributionDrawer(true))}
                hasAdd={!!releaseId}
                title={t("distributions.name")}
                titleTag={releaseId && "h2"}
            />
            <TableList
                count={count}
                headCells={tableHeadCellsData.releases.distributions}
                limit={limit}
                page={page}
                setLimit={setLimit}
                setPage={setPage}
            >
                {data?.map(({
                    client,
                    finishedOn,
                    id: distributionId,
                    processedOn,
                    progress,
                    releaseName,
                    status,
                }, index) => (
                    <TableRow key={distributionId}>
                        <StyledTableCell boldCell={+true}>{releaseName}</StyledTableCell>
                        <StyledTableCell boldCell={+true}>{client.name}</StyledTableCell>
                        <StyledTableCell>
                            <time>{convertDateToLocaleStringFormatHandler(processedOn)}</time>
                        </StyledTableCell>
                        <StyledTableCell>
                            {status === "active" ? t("labels.N/A") : <time>{convertHourToDurationFormatHandler((new Date(finishedOn).getTime() - new Date(processedOn).getTime()) / 1000)}</time>}
                        </StyledTableCell>
                        <StyledTableCell>
                            <StyledChip
                                label={status}
                                status={status}
                            />
                        </StyledTableCell>
                        <StyledTableCell>
                            <Box
                                alignItems="center"
                                display="flex"
                                gap={2}
                            >
                                <StyledProgressBar
                                    status={status}
                                    value={status !== "waiting" ? parseInt(progress) : 0}
                                    variant={status === "active" ? "indeterminate" : "determinate"}
                                />
                                <Typography variant="body2">
                                    {status !== "waiting" ? parseInt(progress) : 0}
                                    %
                                </Typography>
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                            <TableMenu
                                disabled={status === "active"}
                                rowId={index + 1}
                                setOpenModal={() => {
                                    setModalData({
                                        ...modalData,
                                        id: distributionId,
                                        open: true,
                                    });
                                }}
                                hasDelete
                            >
                                <MenuItem
                                    icon={<ReplayIcon />}
                                    id={`${tableMenuName}_${menuId}_${index + 1}_${retryDistributionOptionName}_${optionId}`}
                                    label={t("releases.retryDistribution")}
                                    disabled={progress === 100 || status === "waiting" || !canDo(
                                        user,
                                        [mediaCreatePermission],
                                    )}
                                    onClick={() => retryFailedDistribution(parseInt(distributionId))}
                                />
                            </TableMenu>
                        </StyledTableCell>
                    </TableRow>
                ))}
            </TableList>
        </Box>
    );
};

export default DistributionsList;

DistributionsList.propTypes = { releaseId: PropTypes.string };
