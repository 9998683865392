import { useAuth0 } from "@auth0/auth0-react";
import { constantsData, regexData, urlsData } from "data";
import { useApisClient } from "hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useSearchDriveManagementUsersList = (searchValue) => {
    const [users, setUsers] = useState([]);

    const [groups, setGroups] = useState([]);

    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { logout } = useAuth0();

    const { get } = useApisClient();

    const { search: searchUsersApiUrl } = urlsData.apis.driveManagement.users;

    const { email } = regexData;

    const searchDriveManagementUsersList = () => {
        setLoading(true);

        get(
            searchUsersApiUrl,
            null,
            {
                fields: "*",
                s: searchValue,
            },
        ).then(({ data }) => {
            if ((data?.users?.length > 0 && data?.groups?.length > 0) || !searchValue.match(email)) {
                setUsers(data?.users);

                setGroups(data?.groups);
            } else {
                setUsers([{
                    email: searchValue,
                    id: searchValue,
                    kind: "user",
                    name: searchValue,
                }]);

                setGroups([]);
            }
        })["catch"]((err) => {
            displayErrorHandler(
                err.response.data.message === constantsData.networkError ? t("messages.networkFailure") : err.response.data.message,
                dispatch,
                openAlert,
                err.response.status,
                logout,
            );
        })["finally"](() => {
            setLoading(false);
        });
    }; //eslint-disable-line

    useEffect(
        () => {
            if (searchValue) searchDriveManagementUsersList();
        },
        [searchValue], // eslint-disable-line
    );

    return {
        data: [...users, ...groups],
        loading,
    };
};

export default useSearchDriveManagementUsersList;
