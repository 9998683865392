import { DetailsText } from "atoms";
import { DetailsHeader } from "components";
import {
    actionsData,
    identifiersData,
    namesData,
    urlsData,
} from "data";
import { useDeleteRelease, useGetReleaseShareLink } from "hooks";
import { PropTypes } from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { StyledLink } from "styles";
import { convertDateToLocaleStringFormatHandler } from "utils";

const ReleaseDetailsHeader = ({ data }) => {
    const [shareLinkButtonClicked, setShareLinkButtonClicked] = useState(false);

    const { t } = useTranslation();

    const location = useLocation();

    const {
        copyReleaseShareLink,
        data: shareLinkData,
    } = useGetReleaseShareLink(
        data?.id,
        shareLinkButtonClicked,
    );

    const { deleteRelease } = useDeleteRelease(
        true,
        1,
        10,
        null,
        false,
        false,
        true,
    );

    const [modalData, setModalData] = useState({
        action: deleteRelease,
        actionType: actionsData["delete"],
        body: t(
            "messages.deleteMsgs.alert",
            { entity: t("releases.entity") },
        ),
        id: null,
        open: false,
        title: t("releases.deleteRelease"),
    });

    const {
        album_code: albumCode,
        album_name: albumName,
        album_name_ar: albumNameAr,
        artist,
        disc_number: discNumber,
        end_date: endDate,
        genre,
        id,
        image,
        release_date: releaseDate,
        releaseType,
        rightType,
    } = data || {};

    const {
        artists: { url: artistsRouteUrl },
        releases: { edit: editReleaseRouteUrl },
    } = urlsData.routes;

    return (
        <DetailsHeader
            copyLinkAction={shareLinkData ? copyReleaseShareLink : () => setShareLinkButtonClicked(true)}
            editRouteUrl={`${editReleaseRouteUrl}/${id}`}
            entity={t("releases.name").toLowerCase()}
            id={id}
            image={image}
            modalData={modalData}
            setModalData={setModalData}
            title={`${albumName} | ${albumNameAr}`}
            columnOneData={(
                <>
                    <DetailsText
                        info={releaseType?.name}
                        tag={t("labels.releaseType")}
                    />
                    <DetailsText
                        info={<time>{convertDateToLocaleStringFormatHandler(releaseDate)}</time>}
                        tag={t("labels.releaseDate")}
                    />
                    {endDate && (
                        <DetailsText
                            info={<time>{convertDateToLocaleStringFormatHandler(endDate)}</time>}
                            tag={t("labels.endDate")}
                        />
                    )}
                    <DetailsText
                        info={rightType?.name}
                        tag={t("labels.rightType")}
                    />
                    <DetailsText
                        tag={t("labels.primaryArtist")}
                        info={(
                            <StyledLink
                                id={`${namesData.links.primaryArtist}_${identifiersData.ids.link}`}
                                state={{ from: location.pathname + location.search }}
                                to={`${artistsRouteUrl}/${artist?.id}`}
                            >
                                {artist?.name}
                            </StyledLink>
                        )}
                    />
                </>
            )}
            columnTwoData={(
                <>
                    {!discNumber && discNumber !== 0 ? null : (
                        <DetailsText
                            info={discNumber}
                            tag={t("labels.discNumber")}
                        />
                    )}
                    <DetailsText
                        info={genre?.name}
                        tag={t("labels.genre")}
                    />
                    <DetailsText
                        info={albumCode}
                        tag={t("labels.code")}
                    />
                </>
            )}
            hasDownloadXmlFile
            hasShareLinkAction
            isReleaseHeader
            withoutStyle
        />
    );
};

export default ReleaseDetailsHeader;

ReleaseDetailsHeader.propTypes = { data: PropTypes.object };
