/* eslint-disable max-lines */

import constantsData from "./constants";

const unitTestingData = {
    artist: {
        code: 10006,
        country: { id: 214 },
        id: 5,
        image: null,
        isni: "1010 1010 1010 0006",
        name: "Assala",
        releases: [
            { id: 8 },
            { id: 25 },
            { id: 87 },
            { id: 92 },
            { id: 93 },
            { id: 94 },
            { id: 246 },
            { id: 335 },
            { id: 375 },
        ],
        tracks: [
            {
                artist: {
                    id: 5,
                    name: "Assala",
                },
                id: 178,
                title: "60ـDe2i2a ",
                trackFile: { duration: 355 },
            },
            {
                artist: {
                    id: 5,
                    name: "Assala",
                },
                id: 274,
                title: "Sultan Almahaba",
                trackFile: { duration: 232 },
            },
            {
                artist: {
                    id: 5,
                    name: "Assala",
                },
                id: 275,
                title: "Ya Shayfeen Anfoskom",
                trackFile: { duration: 297 },
            },
            {
                artist: {
                    id: 5,
                    name: "Assala",
                },
                id: 276,
                title: "La Tashkou Le Elnas",
                trackFile: { duration: 374 },
            },
            {
                artist: {
                    id: 5,
                    name: "Assala",
                },
                id: 277,
                title: "Ana Dakhel Allah",
                trackFile: { duration: 292 },
            },
        ],
    },
    files: {
        imageFile: {
            name: "file.png",
            type: "image/png",
        },
        lyricsFile: {
            name: "file.pdf",
            type: "application",
        },
        sshKeyFile: {
            name: "file.ppk",
            type: ".ppk",
        },
        trackFile: {
            name: "file.mp3",
            type: "audio/mp3",
        },
        xmlFile: {
            name: "file.xml",
            type: "text/xml",
        },
    },
    filteredTabs: [
        {
            label: "tracks",
            value: "1",
        },
        {
            label: "videos",
            value: "2",
        },
        {
            label: "ownership",
            value: "3",
        },
        {
            label: "clientLinks",
            value: "5",
        },
        {
            label: "driveLinks",
            value: "6",
        },
        {
            label: "distributions",
            value: "7",
        },
    ],
    importer: {
        data: {
            originalData: {},
            setEntries: () => Function,
            setInvalidImporterRelease: () => Function,
        },
        dummyObjects: {
            artists: {
                artist1: {
                    artistNameEn: "Mohamed Ahmed",
                    code: 10022,
                    nationality: "Kuwait",
                },
                artist2: {
                    artistNameAr: "محمد احمد",
                    code: 10022,
                    nationality: "Kuwait",
                },
                artist3: {
                    artistNameAr: "محمد احمد",
                    artistNameEn: "Mohamed Ahmed",
                    nationality: "Kuwait",
                },
                artist4: {
                    artistNameAr: "محمد احمد",
                    artistNameEn: "Mohamed Ahmed",
                    code: 10022,
                },
                artist5: {
                    artistNameAr: "محمد احمد",
                    artistNameEn: "Mohamed Ahmed",
                    code: "LA12324S23",
                    nationality: "Kuwait",
                },
                artist6: {
                    artistNameAr: "محمد احمد",
                    artistNameEn: "Mohamed Ahmed",
                    code: 1002212323,
                    nationality: "Kuwait",
                },
                artist7: {
                    artistNameAr: "محمد احمد",
                    artistNameEn: "Mohamed Ahmed",
                    code: 10022,
                    nationality: "Italy",
                },
                artist8: {
                    artistNameAr: "محمد احمد",
                    artistNameEn: "Mohamed Ahmed",
                    code: 10022,
                    nationality: "Kuwait",
                },
            },
            documents: {
                document1: { type: "COMPOSER_PUBLISHING_CONTRACT" },
                document2: {
                    file: {},
                    type: "Contract",
                },
                document3: { file: {} },
                document4: {
                    file: {},
                    type: "COMPOSER_PUBLISHING_CONTRACT",
                },
            },
            releases: {
                release1: {
                    albumNameEn: "New Release",
                    code: 22080,
                    discNum: 1,
                    endDate: "2022-12-31",
                    genre: "KHALEJE",
                    image: {},
                    ownership: {
                        cLine: "cLine",
                        label: "label",
                        pLine: "pLine",
                        territory: "WW",
                    },
                    releaseDate: "2022-07-08",
                    releaseType: "SINGLE",
                    rightType: "DISTRIBUTE",
                    upc: "6281130222362",
                },
                release2: {
                    albumNameAr: "اصدار جديد",
                    code: 22080,
                    discNum: 1,
                    endDate: "2022-12-31",
                    genre: "KHALEJE",
                    image: {},
                    ownership: {
                        cLine: "cLine",
                        label: "label",
                        pLine: "pLine",
                        territory: "WW",
                    },
                    releaseDate: "2022-07-08",
                    releaseType: "SINGLE",
                    rightType: "DISTRIBUTE",
                    upc: "6281130222362",
                },
                release3: {
                    albumNameAr: "اصدار جديد",
                    albumNameEn: "New Release",
                    code: 22080,
                    discNum: 1,
                    endDate: "2022-12-31",
                    genre: "KHALEJE",
                    image: {},
                    ownership: {
                        cLine: "cLine",
                        label: "label",
                        pLine: "pLine",
                        territory: "WW",
                    },
                    releaseDate: "2022-07-08",
                    releaseType: "SINGLE",
                    rightType: "DISTRIBUTE",
                },
                release4: {
                    albumNameAr: "اصدار جديد",
                    albumNameEn: "New Release",
                    discNum: 1,
                    endDate: "2022-12-31",
                    genre: "KHALEJE",
                    image: {},
                    ownership: {
                        cLine: "cLine",
                        label: "label",
                        pLine: "pLine",
                        territory: "WW",
                    },
                    releaseDate: "2022-07-08",
                    releaseType: "SINGLE",
                    rightType: "DISTRIBUTE",
                    upc: "6281130222362",
                },
                release5: {
                    albumNameAr: "اصدار جديد",
                    albumNameEn: "New Release",
                    code: 22080,
                    discNum: 1,
                    endDate: "2022-12-31",
                    image: {},
                    ownership: {
                        cLine: "cLine",
                        label: "label",
                        pLine: "pLine",
                        territory: "WW",
                    },
                    releaseDate: "2022-07-08",
                    releaseType: "SINGLE",
                    rightType: "DISTRIBUTE",
                    upc: "6281130222362",
                },
                release6: {
                    albumNameAr: "اصدار جديد",
                    albumNameEn: "New Release",
                    code: 22080,
                    discNum: 1,
                    endDate: "2022-12-31",
                    genre: "KHALEJE",
                    ownership: {
                        cLine: "cLine",
                        label: "label",
                        pLine: "pLine",
                        territory: "WW",
                    },
                    releaseDate: "2022-07-08",
                    releaseType: "SINGLE",
                    rightType: "DISTRIBUTE",
                    upc: "6281130222362",
                },
                release7: {
                    albumNameAr: "اصدار جديد",
                    albumNameEn: "New Release",
                    code: 22080,
                    discNum: 1,
                    endDate: "2022-12-31",
                    genre: "KHALEJE",
                    image: {},
                    ownership: {
                        cLine: "cLine",
                        label: "label",
                        pLine: "pLine",
                        territory: "WW",
                    },
                    releaseType: "SINGLE",
                    rightType: "DISTRIBUTE",
                    upc: "6281130222362",
                },
                release8: {
                    albumNameAr: "اصدار جديد",
                    albumNameEn: "New Release",
                    code: 22080,
                    discNum: 1,
                    endDate: "2022-12-31",
                    genre: "KHALEJE",
                    image: {},
                    ownership: {
                        cLine: "cLine",
                        label: "label",
                        pLine: "pLine",
                        territory: "WW",
                    },
                    releaseDate: "2022-07-08",
                    rightType: "DISTRIBUTE",
                    upc: "6281130222362",
                },
                release9: {
                    albumNameAr: "اصدار جديد",
                    albumNameEn: "New Release",
                    code: 22080,
                    discNum: 1,
                    endDate: "2022-12-31",
                    genre: "KHALEJE",
                    image: {},
                    ownership: {
                        cLine: "cLine",
                        label: "label",
                        pLine: "pLine",
                        territory: "WW",
                    },
                    releaseDate: "2022-07-08",
                    releaseType: "SINGLE",
                    upc: "6281130222362",
                },
                release10: { // eslint-disable-line
                    albumNameAr: "اصدار جديد",
                    albumNameEn: "New Release",
                    code: 22080,
                    discNum: 1,
                    endDate: "2022-12-31",
                    genre: "KHALEJE",
                    image: {},
                    ownership: {
                        label: "label",
                        pLine: "pLine",
                        territory: "WW",
                    },
                    releaseDate: "2022-07-08",
                    releaseType: "SINGLE",
                    rightType: "DISTRIBUTE",
                    upc: "6281130222362",
                },
                release11: {
                    albumNameAr: "اصدار جديد",
                    albumNameEn: "New Release",
                    code: 22080,
                    discNum: 1,
                    endDate: "2022-12-31",
                    genre: "KHALEJE",
                    image: {},
                    ownership: {
                        cLine: "cLine",
                        label: "label",
                        territory: "WW",
                    },
                    releaseDate: "2022-07-08",
                    releaseType: "SINGLE",
                    rightType: "DISTRIBUTE",
                    upc: "6281130222362",
                },
                release12: {
                    albumNameAr: "اصدار جديد",
                    albumNameEn: "New Release",
                    code: 22080,
                    discNum: 1,
                    endDate: "2022-12-31",
                    genre: "KHALEJE",
                    image: {},
                    ownership: {
                        cLine: "cLine",
                        pLine: "pLine",
                        territory: "WW",
                    },
                    releaseDate: "2022-07-08",
                    releaseType: "SINGLE",
                    rightType: "DISTRIBUTE",
                    upc: "6281130222362",
                },
                release13: {
                    albumNameAr: "اصدار جديد",
                    albumNameEn: "New Release",
                    code: 22080,
                    discNum: 1,
                    endDate: "2022-12-31",
                    genre: "KHALEJE",
                    image: {},
                    ownership: {
                        cLine: "cLine",
                        label: "label",
                        pLine: "pLine",
                    },
                    releaseDate: "2022-07-08",
                    releaseType: "SINGLE",
                    rightType: "DISTRIBUTE",
                    upc: "6281130222362",
                },
                release14: {
                    albumNameAr: "اصدار جديد",
                    albumNameEn: "New Release",
                    code: 22080,
                    discNum: 1,
                    endDate: "2022-12-31",
                    genre: "KHALEJE",
                    image: {},
                    ownership: {
                        cLine: "cLine",
                        label: "label",
                        pLine: "pLine",
                        territory: "MiddleEast",
                    },
                    releaseDate: "2022-07-08",
                    releaseType: "SINGLE",
                    rightType: "DISTRIBUTE",
                    upc: "6281130222362",
                },
                release15: {
                    albumNameAr: "اصدار جديد",
                    albumNameEn: "New Release",
                    code: 22080,
                    discNum: 1,
                    endDate: "2022-12-31",
                    genre: "KHALEJE",
                    image: {},
                    ownership: {
                        cLine: "cLine",
                        label: "label",
                        pLine: "pLine",
                        territory: "WW",
                    },
                    releaseDate: constantsData.invalidDate,
                    releaseType: "SINGLE",
                    rightType: "DISTRIBUTE",
                    upc: "6281130222362",
                },
                release16: {
                    albumNameAr: "اصدار جديد",
                    albumNameEn: "New Release",
                    code: 22080,
                    discNum: 1,
                    endDate: constantsData.invalidDate,
                    genre: "KHALEJE",
                    image: {},
                    ownership: {
                        cLine: "cLine",
                        label: "label",
                        pLine: "pLine",
                        territory: "WW",
                    },
                    releaseDate: "2022-07-08",
                    releaseType: "SINGLE",
                    rightType: "DISTRIBUTE",
                    upc: "6281130222362",
                },
                release17: {
                    albumNameAr: "اصدار جديد",
                    albumNameEn: "New Release",
                    code: "Code22080",
                    discNum: 1,
                    endDate: "2022-12-31",
                    genre: "KHALEJE",
                    image: {},
                    ownership: {
                        cLine: "cLine",
                        label: "label",
                        pLine: "pLine",
                        territory: "WW",
                    },
                    releaseDate: "2022-07-08",
                    releaseType: "SINGLE",
                    rightType: "DISTRIBUTE",
                    upc: "6281130222362",
                },
                release18: {
                    albumNameAr: "اصدار جديد",
                    albumNameEn: "New Release",
                    code: 22080,
                    discNum: "DiscNum1",
                    endDate: "2022-12-31",
                    genre: "KHALEJE",
                    image: {},
                    ownership: {
                        cLine: "cLine",
                        label: "label",
                        pLine: "pLine",
                        territory: "WW",
                    },
                    releaseDate: "2022-07-08",
                    releaseType: "SINGLE",
                    rightType: "DISTRIBUTE",
                    upc: "6281130222362",
                },
                release19: {
                    albumNameAr: "اصدار جديد",
                    albumNameEn: "New Release",
                    code: 22080,
                    discNum: 1,
                    endDate: "2022-12-31",
                    genre: "KHALEJE",
                    image: {},
                    ownership: {
                        cLine: "cLine",
                        label: "label",
                        pLine: "pLine",
                        territory: "WW",
                    },
                    releaseDate: "2022-07-08",
                    releaseType: "SINGLE",
                    rightType: "DISTRIBUTE",
                    upc: "62811302223",
                },
                release20: {
                    albumNameAr: "اصدار جديد",
                    albumNameEn: "New Release",
                    code: 22080,
                    discNum: 1,
                    endDate: "2022-12-31",
                    genre: "AFRICA",
                    image: {},
                    ownership: {
                        cLine: "cLine",
                        label: "label",
                        pLine: "pLine",
                        territory: "WW",
                    },
                    releaseDate: "2022-07-08",
                    releaseType: "SINGLE",
                    rightType: "DISTRIBUTE",
                    upc: "6281130222362",
                },
                release21: {
                    albumNameAr: "اصدار جديد",
                    albumNameEn: "New Release",
                    code: 22080,
                    discNum: 1,
                    endDate: "2022-12-31",
                    genre: "KHALEJE",
                    image: {},
                    ownership: {
                        cLine: "cLine",
                        label: "label",
                        pLine: "pLine",
                        territory: "WW",
                    },
                    releaseDate: "2022-07-08",
                    releaseType: "SINGLE",
                    rightType: "PRODUCE",
                    upc: "6281130222362",
                },
                release22: {
                    albumNameAr: "اصدار جديد",
                    albumNameEn: "New Release",
                    code: 22080,
                    discNum: 1,
                    endDate: "2022-12-31",
                    genre: "KHALEJE",
                    image: {},
                    ownership: {
                        cLine: "cLine",
                        label: "label",
                        pLine: "pLine",
                        territory: "WW",
                    },
                    releaseDate: "2022-07-08",
                    releaseType: "DOUBLE",
                    rightType: "DISTRIBUTE",
                    upc: "6281130222362",
                },
                release23: {
                    albumNameAr: "اصدار جديد",
                    albumNameEn: "New Release",
                    code: 22080,
                    discNum: 1,
                    endDate: "2022-12-31",
                    genre: "KHALEJE",
                    image: {},
                    ownership: {
                        cLine: "cLine",
                        label: "label",
                        pLine: "pLine",
                        territory: "WW",
                    },
                    releaseDate: "2022-07-08",
                    releaseType: "SINGLE",
                    rightType: "DISTRIBUTE",
                    upc: "6281130222362",
                },
            },
            tracks: {
                track1: {
                    customId: 1212,
                    file: {},
                    isrc: "LBA082200178",
                    trackNum: 122,
                    trackTitleEn: "Album Track",
                },
                track2: {
                    customId: 1212,
                    file: {},
                    isrc: "LBA082200178",
                    trackNum: 122,
                    trackTitleAr: "البوم تراك",
                },
                track3: {
                    customId: 1212,
                    file: {},
                    trackNum: 122,
                    trackTitleAr: "البوم تراك",
                    trackTitleEn: "Album Track",
                },
                track4: {
                    customId: 1212,
                    file: {},
                    isrc: "LBA082200178",
                    trackTitleAr: "البوم تراك",
                    trackTitleEn: "Album Track",
                },
                track5: {
                    customId: 1212,
                    isrc: "LBA082200178",
                    trackNum: 122,
                    trackTitleAr: "البوم تراك",
                    trackTitleEn: "Album Track",
                },
                track6: {
                    file: {},
                    isrc: "LBA082200178",
                    trackNum: 122,
                    trackTitleAr: "البوم تراك",
                    trackTitleEn: "Album Track",
                },
                track7: {
                    customId: "Id12",
                    file: {},
                    isrc: "LBA082200178",
                    trackNum: 122,
                    trackTitleAr: "البوم تراك",
                    trackTitleEn: "Album Track",
                },
                track8: {
                    customId: 1212,
                    file: {},
                    isrc: "LBA082200178",
                    trackNum: "trackNum122",
                    trackTitleAr: "البوم تراك",
                    trackTitleEn: "Album Track",
                },
                track9: {
                    customId: 1212,
                    file: {},
                    isrc: "LBA082",
                    trackNum: 122,
                    trackTitleAr: "البوم تراك",
                    trackTitleEn: "Album Track",
                },
                track10: { // eslint-disable-line
                    customId: 1212,
                    file: {},
                    isrc: "LBA082200178",
                    trackNum: 122,
                    trackTitleAr: "البوم تراك",
                    trackTitleEn: "Album Track",
                },
            },
            videos: {
                video1: { file: {} },
                video2: { type: "VIDEOCLIP" },
                video3: {
                    file: {},
                    type: "Clip",
                },
                video4: {
                    file: {},
                    type: "VIDEOCLIP",
                },
            },
        },
        lists: {
            artists: [
                {
                    code: 1212,
                    name: "Abdullah Al Ruwaished",
                },
                {
                    code: 10022,
                    name: "Mohamed Ahmed",
                },
                {
                    code: 10001,
                    name: "Nasser Naief",
                },
            ],
            countries: [
                { label: "Algeria" },
                { label: "Egypt" },
                { label: "Kuwait" },
            ],
            documentTypes: [
                { label: "COMPOSER_PUBLISHING_CONTRACT" },
                { label: "COMPOSER_COMPOSITIONS" },
                { label: "WRITER_PUBLISHING_CONTRACT" },
            ],
            genres: [
                { label: "CLASSIC" },
                { label: "KHALEJE" },
                { label: "EGYPTIAN" },
            ],
            releaseTypes: [
                { label: "SINGLE" },
                { label: "ALBUM" },
            ],
            rightTypes: [
                { label: "DISTRIBUTE" },
                { label: "PRODUCE_AND_DISTRIBUTE" },
            ],
            territories: [
                { label: "MENA" },
                { label: "WW" },
            ],
            tracks: [
                { name: "LBA082200056.mp3" },
                { name: "LBA082200057.mp3" },
                { name: "LBA082200058.mp3" },
                { name: "LBA082200059.mp3" },
            ],
            videoTypes: [
                { label: "VIDEOCLIP" },
                { label: "LYRICS" },
                { label: "JALASAT" },
                { label: "CONCERT" },
                { label: "VIDEO WITHOUT LOGO" },
            ],
            videos: [
                { name: "LBA082200056_without Logo.mp4" },
                { name: "LBA082200057_without Logo.mp4" },
                { name: "LBA082200058_without Logo.mp4" },
                { name: "LBA082200059_without Logo.mp4" },
            ],
        },
        utils: {
            dispatch: () => Function,
            translation: () => Function,
        },
    },
    release: {
        artist: {
            id: 5,
            name: "Assala",
        },
        clientLinks: [
            {
                client: { name: "Spotify ME32" },
                id: 79,
                releaseLink: "releaseLink",
                releaseTrack: {
                    track: {
                        id: 291,
                        title: "Hadrat Janabak",
                    },
                },
            },
        ],
        documents: [],
        genre: {
            id: 3,
            name: "EGYPTIAN",
        },
        id: 335,
        image: {
            downloadThumbnail: "media/images/6281130222652_thumbnail.webp/6281130222652_thumbnail.webp",
            downloadUri: "media/images/6281130222652.jpeg/6281130222652.jpeg",
            id: 738,
        },
        isCompleted: true,
        notes: null,
        ownership: {
            c_line: "℗ 2022 Rotana LLC",//eslint-disable-line
            id: 216,
            label: "Single",
            p_line: "Rotana",//eslint-disable-line
            territory: {
                id: 2,
                name: "WW",
            },
        },
        releaseDriveLinks: {
            posts: [
                {
                    id: 12,
                    linkName: "resource",
                    url: "http://resource.com",
                },
            ],
        },
        releaseTracks: [
            {
                id: 392,
                track: {
                    id: 178,
                    isrc: "LBA082200121",
                    title: "60ـDe2i2a ",
                },
            },
        ],
        releaseType: {
            id: 1,
            name: "SINGLE",
        },
        rightType: {
            id: 1,
            name: "DISTRIBUTE",
        },
        upc: "6281130222652",
        videos: [
            {
                downloadUri: "media/videos/LBA082200121_2.mp4/LBA082200121_2.mp4",
                id: 83,
                name: "VIDEOCLIP_LBA082200121.mp4",
                type: {
                    id: 1,
                    name: "VIDEOCLIP",
                },
            },
        ],
    },
};

export default unitTestingData;
