import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { constantsData, urlsData } from "data";
import { getReleaseQuery } from "graph";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openAlert, setCurrentRelease, setReleaseTracks } from "redux/actions";
import { displayErrorHandler } from "utils";

const useGetRelease = (id) => {
    const { isAuthenticated } = useAuth0();

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const {
        data,
        error,
        loading,
    } = useQuery(
        getReleaseQuery,
        {
            onCompleted: ({ release }) => {
                if (!release) navigate(urlsData.routes.notFound);

                dispatch(setCurrentRelease(release));

                const tracks = release?.releaseTracks?.map(({
                    id: releaseTrackId,
                    track,
                }) => ({
                    ...track,
                    releaseTrackId,
                }));

                dispatch(setReleaseTracks(tracks));
            },
            skip: !isAuthenticated,
            variables: {
                where: { id },
            },
        },

    );

    useEffect(
        () => {
            if (error?.message === constantsData.failedToFetch && !loading) {
                displayErrorHandler(
                    t("messages.networkFailure"),
                    dispatch,
                    openAlert,
                );
            }
        },
        [error, data], // eslint-disable-line
    );

    return {
        data: data?.release,
        loading,
    };
};

export default useGetRelease;
