import { useMutation } from "@apollo/client";
import {
    constantsData,
    identifiersData,
    namesData,
    statusTypesData,
} from "data";
import { deleteClientMutation, getClientsListQuery } from "graph";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useDeleteClient = (page, limit) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [deleteClientAction, { loading }] = useMutation(
        deleteClientMutation,
        {
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: getClientsListQuery,
                    variables: {
                        limit,
                        page,
                    },
                },
            ],
        },
    );

    const { deleteClientPending: deleteClientPendingToastName } = namesData.toasts;

    const { toast: toastId } = identifiersData.ids;

    const deleteClient = async (id) => {
        try {
            await deleteClientAction({
                onCompleted: () => {
                    dispatch(openAlert(
                        t(
                            "messages.deleteMsgs.success",
                            { entity: t("clients.entity") },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: {
                    where: { id },
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        } finally {
            toast.dismiss(`${deleteClientPendingToastName}_${toastId}`);
        }
    };

    useEffect(
        () => {
            if (loading) {
                toast.info(
                    () => t(
                        "messages.deleteMsgs.pending",
                        { entity: t("clients.entity") },
                    ),
                    { toastId: `${deleteClientPendingToastName}_${toastId}` },
                );
            }
        },
        [loading], //eslint-disable-line
    );

    return { deleteClient };
};

export default useDeleteClient;
