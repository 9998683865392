import { Box } from "@mui/material";
import { PageHead, ReleaseRoyaltyForm } from "components";
import { actionsData } from "data";
import { useCreateReleaseRoyalty, useEditReleaseRoyalty, useGetTrack } from "hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ReleaseRoyaltiesTab = () => {
    const [trackId, setTrackId] = useState(null);

    const { t } = useTranslation();

    const {
        data,
        loading: getTrackLoading,
    } = useGetTrack(trackId);

    const {
        createReleaseRoyalty,
        loading: createReleaseRoyaltyLoading,
    } = useCreateReleaseRoyalty(trackId);

    const {
        editReleaseRoyalty,
        loading: editReleaseRoyaltyLoading,
    } = useEditReleaseRoyalty(
        data?.royalty?.id,
        trackId,
    );

    const royalty = data?.royalty;

    const {
        master,
        mechanical_performance: mechanicalPerformance,
        neighboring,
        synchronization,
    } = royalty || {};

    const formValues = royalty && {
        master,
        mechanicalPerformance,
        neighboring,
        synchronization,
    };

    return (
        <Box>
            <PageHead title={t("tabs.royalties")} />

            <ReleaseRoyaltyForm
                action={data?.royalty ? actionsData.edit : actionsData.create}
                loading={createReleaseRoyaltyLoading || editReleaseRoyaltyLoading}
                setTrackId={setTrackId}
                trackId={trackId}
                trackLoading={getTrackLoading}
                values={formValues}
                onSubmitForm={data?.royalty ? editReleaseRoyalty : createReleaseRoyalty}
            />
        </Box>
    );
};

export default ReleaseRoyaltiesTab;
