import SearchIcon from "@mui/icons-material/Search";
import { IconButton, Tooltip } from "@mui/material";
import { Loader, SelectMenu } from "atoms";
import {
    identifiersData,
    inputData,
    namesData,
    urlsData,
} from "data";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { StyledSelectableDebouncedSearchInput } from "styles";
import { debounce } from "utils";

const DriveManagementSelectableDebouncedSearchInput = () => {
    const [loading, setLoading] = useState(false);

    const [params] = useSearchParams();

    const searchValueParam = params.get("q");

    const driveValueParam = params.get("drive");

    const [searchValue, setSearchValue] = useState(searchValueParam);

    const [selectedDrive, setSelectedDrive] = useState(driveValueParam || 1);

    const { drivesList } = useSelector((state) => state.driveManagementReducer);

    const navigate = useNavigate();

    const { t } = useTranslation();

    const {
        drives: driveManagementDrivesRouteUrl,
        search: driveManagementSearchRouteUrl,
        url: driveManagementRouteUrl,
    } = urlsData.routes.driveManagement;

    const {
        classNames: {
            searchBox: searchBoxClassName,
            searchButton: searchButtonClassName,
            searchSelectMenu: searchSelectMenuClassName,
            searchSelectMenuFormControl: searchSelectMenuFormControlClassName,
        },
        ids: {
            button: buttonId,
            input: inputId,
            label: labelId,
            selectMenu: selectMenuId,
        },
    } = identifiersData;

    const {
        driveManagementSearch: driveManagementSearchInputName,
        search: searchInputName,
    } = inputData.names;

    const fetchSearchDataHandler = (value, drive) => {
        if (value?.length > 0) {
            navigate({
                pathname: `${driveManagementRouteUrl}${driveManagementSearchRouteUrl}`,
                search: createSearchParams({
                    drive,
                    q: value,
                }).toString(),
            });
        }
    };

    const callSearchDataHandler = useCallback(
        debounce(
            (value) => {
                fetchSearchDataHandler(
                    value,
                    selectedDrive,
                );

                setLoading(false);
            },
            1200,
        ),
        [selectedDrive],
    ); /* eslint react-hooks/exhaustive-deps: "off" */

    const changeSearchInputValueHandler = (e) => {
        const { value } = e.target;

        setSearchValue(value);

        setLoading(true);

        callSearchDataHandler(value);

        if (value.length === 0) {
            navigate({
                pathname: `${driveManagementRouteUrl}${driveManagementDrivesRouteUrl}/${driveValueParam}`,
                search: createSearchParams({ }).toString(),
            });

            setSearchValue(null);
        }
    };

    const selectDriveHandler = (e) => {
        const { value } = e.target;

        setSelectedDrive(value);

        if (value !== 1) {
            fetchSearchDataHandler(
                searchValueParam || "",
                value,
            );
        } else {
            navigate({
                pathname: driveManagementRouteUrl,
                search: createSearchParams({}).toString(),
            });
        }
    };

    const keyDownSearchInputHandler = (event) => {
        if (event.key === "Enter" && selectedDrive) {
            fetchSearchDataHandler(
                searchValue,
                selectedDrive,
            );
        }
    };

    const clickSearchButtonHandler = () => {
        if (selectedDrive) {
            fetchSearchDataHandler(
                searchValue,
                selectedDrive,
            );
        }
    };

    return (
        <StyledSelectableDebouncedSearchInput
            className={searchBoxClassName}
            defaultValue={searchValue || searchValueParam}
            disabled={selectedDrive === 1}
            hasLargeSelectMenu={+true}
            id={`${driveManagementSearchInputName}_${inputId}`}
            options={[]}
            placeholder={t("labels.search")}
            variant="outlined"
            InputProps={{
                endAdornment: (
                    <Tooltip title={t("actions.search")}>
                        <IconButton
                            className={searchButtonClassName}
                            id={`${driveManagementSearchInputName}_${inputId}_${buttonId}`}
                            sx={{
                                display: {
                                    sm: "block!important",
                                    xs: "none!important",
                                },
                            }}
                            onClick={clickSearchButtonHandler}
                        >
                            {loading ? (
                                <Loader
                                    size={22}
                                    withoutFullHeight
                                />
                            ) : <SearchIcon />}
                        </IconButton>
                    </Tooltip>
                ),
                startAdornment: (
                    <SelectMenu
                        className={searchSelectMenuClassName}
                        formControlClassName={searchSelectMenuFormControlClassName}
                        label={t("labels.category")}
                        labelId={`${searchInputName}_${selectMenuId}_${labelId}`}
                        name={namesData.menus.driveManagementSearch}
                        value={selectedDrive === 1 ? driveValueParam || selectedDrive : selectedDrive}
                        options={[{
                            label: t("driveManagement.chooseDrive"),
                            value: 1,
                        }].concat(drivesList?.map(({
                            id,
                            name,
                        }) => ({
                            label: name,
                            value: id,
                        })))}
                        onChange={selectDriveHandler}
                    />
                ),
            }}
            onChange={changeSearchInputValueHandler}
            onKeyDown={keyDownSearchInputHandler}
        />
    );
};
export default DriveManagementSelectableDebouncedSearchInput;
