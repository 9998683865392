import { useAuth0 } from "@auth0/auth0-react";
import {
    constantsData,
    identifiersData,
    namesData,
    statusTypesData,
    urlsData,
} from "data";
import { useApisClient } from "hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { openAlert, setRefetchDriveManagementList } from "redux/actions";
import { displayErrorHandler } from "utils";

const useDeleteDriveManagementFile = () => {
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { logout } = useAuth0();

    const { delete: deleteApiClient } = useApisClient();

    const { deleteDriveManagementFilePending: deleteDriveManagementFilePendingToastName } = namesData.toasts;

    const { toast: toastId } = identifiersData.ids;

    const deleteDriveManagementFile = (fileId) => {
        setLoading(true);

        deleteApiClient(
            urlsData.apis.driveManagement.files.url,
            fileId,
        ).then(() => {
            dispatch(openAlert(
                t(
                    "messages.deleteMsgs.success",
                    { entity: t("labels.file") },
                ),
                statusTypesData.success,
            ));
        })["catch"]((err) => {
            displayErrorHandler(
                err.response.data.message === constantsData.networkError ? t("messages.networkFailure") : err.response.data.message,
                dispatch,
                openAlert,
                err.response.status,
                logout,
            );
        })["finally"](() => {
            toast.dismiss(`${deleteDriveManagementFilePendingToastName}_${toastId}`);

            setLoading(false);

            dispatch(setRefetchDriveManagementList(true));
        });
    };

    useEffect(
        () => {
            if (loading) {
                toast.info(
                    () => t(
                        "messages.deleteMsgs.pending",
                        { entity: t("labels.file") },
                    ),
                    { toastId: `${deleteDriveManagementFilePendingToastName}_${toastId}` },
                );
            }
        },
        [loading], //eslint-disable-line
    );

    return {
        deleteDriveManagementFile,
        loading,
    };
};

export default useDeleteDriveManagementFile;
