import { setContext } from "@apollo/client/link/context";
import { constantsData, permissionsData } from "data";
import Cookies from "js-cookie";

export const authHeaders = (getAccessTokenSilently) => setContext(async (_, previousContext) => {
    const token = await getAccessTokenSilently();

    Cookies.set(
        constantsData.token,
        token,
    );

    return {
        headers: {
            ...previousContext.headers,
            Authorization: `Bearer ${token}`,
        },
    };
});

export const canDo = (user, permissions, oneOfPermissions) => {
    if (user) {
        if (oneOfPermissions) return permissions?.some((permission) => user[permissionsData.key]?.includes(permission));
        return permissions?.every((permission) => user[permissionsData.key]?.includes(permission));
    }
};

export const hasRole = (user, roles) => {
    let filteredArray = [];

    if (user) {
        filteredArray = user[permissionsData.rolesKey].filter((role) => roles.indexOf(role) !== -1);

        return filteredArray.length !== 0;
    }
};
