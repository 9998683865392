import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Box, TableRow, Typography } from "@mui/material";
import { TableList } from "atoms";
import { PageHead } from "components";
import { tableHeadCellsData, urlsData } from "data";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { StyledTableCell } from "styles";

const ReleaseClientLinksResultsList = ({
    data,
    setShowResults,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <PageHead
                backAction={() => setShowResults(false)}
                backRouteUrl={urlsData.routes.releases.url}
                title={t("labels.results")}
                hasBack
            />
            <Box mb={2}>
                <Box
                    alignItems="center"
                    display="flex"
                    gap={1}
                    mb={2}
                >
                    <CheckCircleOutlineIcon
                        color="primary"
                        fontSize="small"
                    />
                    <Typography
                        color="primary"
                        variant="caption"
                    >
                        {data?.successRowsNumber}
                        {" "}
                        {t("messages.releaseClientLinksResults.success")}
                    </Typography>
                </Box>
                <Box
                    alignItems="center"
                    display="flex"
                    gap={1}
                >
                    <ReportProblemIcon
                        color="error"
                        fontSize="small"
                    />
                    <Typography
                        color="error"
                        variant="caption"
                    >
                        {data?.uninsertedRows?.length}
                        {" "}
                        {t("messages.releaseClientLinksResults.fail")}
                    </Typography>
                </Box>
            </Box>
            <TableList
                headCells={tableHeadCellsData.releases.clientLinks.results}
                isPaginated={false}
                tableData={data?.uninsertedRows}
            >
                {data?.uninsertedRows?.map(({
                    Link,
                    UPC,
                    clientName,
                    message,
                }) => (
                    <TableRow
                        key={UPC}
                        tabIndex={-1}
                        hover
                    >
                        <StyledTableCell boldCell={+true}>{clientName}</StyledTableCell>
                        <StyledTableCell>{Link}</StyledTableCell>
                        <StyledTableCell>{UPC}</StyledTableCell>
                        <StyledTableCell>
                            <Box
                                alignItems="center"
                                display="flex"
                                gap={1}
                            >
                                <ReportProblemIcon
                                    color="error"
                                    fontSize="small"
                                />
                                <Typography
                                    color="error"
                                    variant="caption"
                                >
                                    {message}
                                </Typography>
                            </Box>
                        </StyledTableCell>
                    </TableRow>
                ))}
            </TableList>
        </>
    );
};

export default ReleaseClientLinksResultsList;

ReleaseClientLinksResultsList.propTypes = {
    data: PropTypes.object,
    setShowResults: PropTypes.func,
};
