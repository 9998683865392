const constantsData = {
    admins: "Admins",
    canceled: "canceled",
    chunkLoadError: "ChunkLoadError",
    closeToastButtonClicked: "closeToastButtonClicked",
    dataOptionIndex: "data-option-index",
    failedToFetch: "Failed to fetch",
    file: "file",
    folder: "folder",
    group: "group",
    invalidDate: "Invalid Date",
    mena: "mena",
    networkError: "Network Error",
    offline: "offline",
    offlineDescription: "Your Internet connection is unstable, please try again later or try another network",
    offlineTitle: "You are Offline",
    online: "online",
    organizer: "organizer",
    owner: "owner",
    releaseDetails: "releaseDetails",
    roleKeys: "roleKeys",
    superAdmins: "Super Admins",
    token: "token",
    trackDetails: "trackDetails",
    user: "user",
    videoClip: "VIDEOCLIP",
    videoWithoutLogo: "VIDEO WITHOUT LOGO",
    withLogo: "WITH LOGO",
    withoutLogo: "WITHOUT LOGO",
    ww: "ww",
};

export default constantsData;
