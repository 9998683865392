import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData } from "data";
import { createReleaseLyricsFileMutation, getTrackQuery } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useCreateReleaseLyricsFile = (trackId) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [createReleaseLyricsFileAction] = useMutation(
        createReleaseLyricsFileMutation,
        {
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: getTrackQuery,
                    variables: {
                        where: { id: trackId },
                    },
                },
            ],
        },
    );

    const createReleaseLyricsFile = async (fileId) => {
        try {
            await createReleaseLyricsFileAction({
                onCompleted: () => {
                    dispatch(openAlert(
                        t(
                            "messages.uploadMsgs.success",
                            { entity: `${t("releases.entity")} ${t("labels.lyricsFile")}` },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: {
                    data: {
                        file: {
                            connect: { id: fileId },
                        },
                        track: {
                            connect: { id: trackId },
                        },
                    },
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return { createReleaseLyricsFile };
};

export default useCreateReleaseLyricsFile;
