import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useImportReleaseClientLinksSchema = () => {
    const { t } = useTranslation();

    return yup.object({ releaseClientLinksFile: yup.string().required(t("messages.inputMsgs.required")) });
};

export default useImportReleaseClientLinksSchema;
