import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData, urlsData } from "data";
import { createGroupMutation } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useCreateGroup = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [createGroupAction, { loading }] = useMutation(createGroupMutation);

    const createGroup = async (data) => {
        try {
            await createGroupAction({
                onCompleted: () => {
                    navigate(urlsData.routes.groups.url);

                    dispatch(openAlert(
                        t(
                            "messages.createMsgs.success",
                            { entity: t("groups.entity") },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: {
                    data: {
                        group_email: data.email, // eslint-disable-line
                        group_name: data.name, // eslint-disable-line
                    },
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        createGroup,
        loading,
    };
};

export default useCreateGroup;
