import styled from "@emotion/styled";
import { Dialog } from "@mui/material";
import { identifiersData } from "data";

const StyledDialogVideoPlayer = styled(Dialog)(({
    loading,
    theme,
}) => ({
    [`& .MuiBox-root.${identifiersData.classNames.videoTopBox}`]: {
        "& .MuiBox-root": {
            "& svg": {
                color: theme.palette?.grey[100],
                cursor: "pointer",
            },
            position: "absolute",
            right: "20px",
            top: "10px",
            zIndex: 999,
        },
        "& h6": { textAlign: "left" },
        background: theme.palette?.grey[903],
        color: theme.palette?.grey[100],
        display: loading ? "none" : "block",
        padding: "5px 10px",
        position: "absolute",
        width: "100%",
    },
    "& .MuiPaper-root": {
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "hidden",
    },
    "& video": { display: loading ? "none" : "block" },
    backgroundColor: "transparent",
}));

export default StyledDialogVideoPlayer;
