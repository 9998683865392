import { gql } from "@apollo/client";

export const createTerritoryMutation = gql`
    mutation createTerritory($data: TerritoryCreateInput!) {
        createOneTerritory(data: $data) {
           id
        }
    }
`;

export const editTerritoryMutation = gql`
    mutation editTerritory(
        $data: TerritoryUpdateInput!,
        $where: TerritoryWhereUniqueInput!
    ) {
        updateOneTerritory(
            data: $data,
            where: $where
        ) {
            id
        }
    }
`;

export const deleteTerritoryMutation = gql`
    mutation deleteTerritory($where: TerritoryWhereUniqueInput!) {
        deleteOneTerritory(where: $where) {
            id
        }
    }
`;
