import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { constantsData, urlsData } from "data";
import { getArtistQuery } from "graph";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useGetArtist = (id) => {
    const { isAuthenticated } = useAuth0();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const {
        data,
        error,
        loading,
    } = useQuery(
        getArtistQuery,
        {
            onCompleted: ({ artist }) => {
                if (!artist) navigate(urlsData.routes.notFound);
            },
            skip: !isAuthenticated,
            variables: {
                where: { id },
            },
        },
    );

    useEffect(
        () => {
            if (error?.message === constantsData.failedToFetch && !loading) {
                displayErrorHandler(
                    t("messages.networkFailure"),
                    dispatch,
                    openAlert,
                );
            }
        },
        [error, data], // eslint-disable-line
    );

    return {
        data: data?.artist,
        loading,
    };
};

export default useGetArtist;
