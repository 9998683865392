import { gql } from "@apollo/client/core";

export const createVideoMutation = gql`
    mutation createVideo($data: VideoCreateInput!) {
        createOneVideo(data: $data) {
            fileId
            id
        }
    }
`;

export const deleteVideoMutation = gql`
    mutation deleteVideo($where: VideoWhereUniqueInput!) {
        deleteOneVideo(where: $where) {
            id
        }
    }
`;

export const exportVideosMutation = gql`
    mutation exportVideos(
        $videosIds: [Int]!,
        $toCsv: Boolean
    ) {
        exportVideos(
            videosIds: $videosIds,
            toCsv: $toCsv
        )
    }
`;

export const cutVideoMutation = gql`
    mutation cutVideo(
        $id: Int!,
        $startTime: String!,
        $endTime: String!
    ) {
        CropVideo(
            id: $id,
            startTime: $startTime,
            endTime: $endTime
        )
    }
`;

export const deleteVideoCutMutation = gql`
    mutation deleteVideoCut($where: VideoClipWhereUniqueInput!) {
        deleteOneVideoClip(where: $where) {
            id
        }
    }
`;
