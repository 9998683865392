import { useAuth0 } from "@auth0/auth0-react";
import { constantsData, statusTypesData, urlsData } from "data";
import { useApisClient } from "hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { openAlert, setRefetchDriveManagementList } from "redux/actions";
import { logoutHandler } from "utils";

const useNotifyDriveManagementSuccessUploadedFiles = () => {
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const location = useLocation();

    const { post } = useApisClient();

    const { logout } = useAuth0();

    const notifyDriveManagementSuccessUploadedFiles = async (
        files,
        id,
    ) => {
        setLoading(true);

        post(
            urlsData.apis.driveManagement.files.notifySuccessUploaded,
            null,
            {
                files,
                locationId: id,
            },
        ).then(() => {
            dispatch(openAlert(
                t(
                    "messages.uploadMsgs.success",
                    { entity: t("labels.files") },
                ),
                statusTypesData.success,
            ));
        })["catch"]((err) => {
            if (err.response.status === 401) {
                logoutHandler(
                    logout,
                    location.pathname,
                );
            }

            dispatch(openAlert(
                t(
                    "messages.uploadFilesNotification",
                    { error: err.response.data.message === constantsData.networkError ? t("messages.networkFailure") : err.response.data.message },
                ),
                statusTypesData.info,
            ));
        })["finally"](() => {
            setLoading(false);

            dispatch(setRefetchDriveManagementList(true));
        });
    };

    return {
        loading,
        notifyDriveManagementSuccessUploadedFiles,
    };
};

export default useNotifyDriveManagementSuccessUploadedFiles;
