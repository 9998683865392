import "rc-tree/assets/index.css";

import { useAuth0 } from "@auth0/auth0-react";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button,
    Chip,
    Grid,
    Switch,
    Typography,
} from "@mui/material";
import {
    identifiersData,
    inputData,
    namesData,
    permissionsData,
} from "data";
import { PropTypes } from "prop-types";
import Tree from "rc-tree";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { StyledBox, StyledTextField } from "styles";
import { theme } from "theme";
import { canDo } from "utils";

const EditClientXmlFile = ({
    editClientXmlFile,
    loading,
    selectMenuOptions,
    treeData,
}) => {
    const [textFieldValue, setTextFieldValue] = useState("");

    const [menuOpened, setMenuOpened] = useState(false);

    const { t } = useTranslation();

    const defaultOption = {
        id: 0,
        label: t("labels.chooseValue"),
        value: 0,
    };

    const [initialRender, setInitialRender] = useState(true);

    const [currentKey, setCurrentKey] = useState("");

    const [disableSelectMenu, setDisableSelectMenu] = useState(true);

    const [selectedNodes, setSelectedNodes] = useState({});

    const [selectMenuValue, setSelectMenuValue] = useState(defaultOption);

    const [showClearBtn, setShowClearBtn] = useState(false);

    const [dateType, setDateType] = useState("UTC");

    const [isDateNode, setIsDateNode] = useState(false);

    const [withTimeNode, setWithTimeNode] = useState(false);

    const [isAttributeNode, setIsAttributeNode] = useState(false);

    const [uploadedFileValue, setUploadedFileValue] = useState(null);

    const treeRef = useRef();

    const { user } = useAuth0();

    const { mediaEdit: mediaEditPermission } = permissionsData.permissions.keys;

    const {
        button: buttonId,
        input: inputId,
        menu: menuId,
    } = identifiersData.ids;

    const {
        buttons: {
            clear: clearButtonName,
            save: saveButtonName,
        },
        menus: { xmlFile: xmlFileMenu },
    } = namesData;

    const {
        attribute: attributeInputName,
        date: dateInputName,
        textValue: textValueInputName,
        withTime: withTimeInputName,
    } = inputData.names;

    const formatTreeObjHandler = (data) => {
        data.map((node) => {
            const selectedNode = selectedNodes[node.key];

            if (node?.children?.length > 0) formatTreeObjHandler(node?.children);
            else {
                if (selectedNode) {
                    node.selectedOption = selectedNode; // eslint-disable-line

                    node.isAttribute = selectedNode?.isAttribute; // eslint-disable-line

                    node.isDate = selectedNode?.isDate; // eslint-disable-line

                    node.withTime = selectedNode?.withTime; // eslint-disable-line

                    node.dateType = selectedNode?.dateType; // eslint-disable-line
                }

                if (selectedNode && node?.selectedOption)
                    if (!selectedNode?.label && !selectedNode?.isDate && !selectedNode?.isAttribute) delete node.selectedOption; // eslint-disable-line

            }
        });
    };

    const resetTreeNodesHandler = () => {
        setSelectMenuValue(defaultOption);

        setShowClearBtn(false);

        setTextFieldValue("");

        setIsAttributeNode(false);

        setIsDateNode(false);

        setWithTimeNode(false);

        setDateType("UTC");
    };

    const selectTreeNodeHandler = (key, info) => {
        setInitialRender(false);

        if (info.selectedNodes[0]?.children?.length > 0 || key.length === 0) {
            setDisableSelectMenu(true);

            resetTreeNodesHandler();
        } else {
            setDisableSelectMenu(false);

            setCurrentKey(key[0]);

            const selectedNode = selectedNodes[key[0]] || info.selectedNodes[0]?.selectedOption;

            const {
                dateType: selectedNodeDateType,
                isAttribute,
                isDate,
                isSelection,
                label,
                textFieldValue: selectedNodeTextFieldValue,
                value,
                withTime,
            } = selectedNode || {};

            if (info?.selectedNodes[0]?.uploadedFileValue) setUploadedFileValue(info?.selectedNodes[0]?.uploadedFileValue);
            else setUploadedFileValue(null);

            if (!selectedNodes[key[0]] && !info.selectedNodes[0]?.selectedOption) resetTreeNodesHandler();

            if (!selectedNodes[key[0]] && info.selectedNodes[0]?.selectedOption) {
                setSelectedNodes({
                    ...selectedNodes,
                    [key[0]]: {
                        isSelection: !!isSelection,
                        label: label || null,
                        value: value || null,
                        ...selectedNodeDateType && { dateType: selectedNodeDateType },
                        isAttribute: !!isAttribute,
                        isDate: !!isDate,
                        withTime: !!withTime,
                    },
                });
            }

            if (selectedNode) {
                if (selectedNodes[key[0]]?.textFieldValue) setTextFieldValue(selectedNodeTextFieldValue);

                if (isAttribute) setIsAttributeNode(true);
                else setIsAttributeNode(false);

                if (isDate) {
                    setIsDateNode(true);

                    setDateType(selectedNodeDateType);
                } else {
                    setIsDateNode(false);

                    setDateType("UTC");
                }

                if (withTime) setWithTimeNode(true);
                else setWithTimeNode(false);

                if (isSelection) {
                    setSelectMenuValue({
                        label,
                        value,
                    });

                    setShowClearBtn(true);

                    if (!selectedNodes[key[0]]?.textFieldValue) setTextFieldValue("");
                } else {
                    setSelectMenuValue(defaultOption);

                    setShowClearBtn(false);

                    setTextFieldValue(label);
                }
            }
        }
    };

    const changeTextFieldValueHandler = (value) => {
        setTextFieldValue(value);

        if (!showClearBtn) {
            setSelectedNodes({
                ...selectedNodes,
                [currentKey]: {
                    ...selectedNodes?.[currentKey],
                    isSelection: false,
                    label: value,
                    value: null,
                },
            });
        } else {
            setSelectedNodes({
                ...selectedNodes,
                [currentKey]: {
                    ...selectedNodes?.[currentKey],
                    textFieldValue: value,
                },
            });
        }
    };

    const selectOptionHandler = (value) => {
        setSelectedNodes({
            ...selectedNodes,
            [currentKey]: {
                ...selectedNodes?.[currentKey],
                ...value,
                isSelection: true,
            },
        });

        setSelectMenuValue(value);

        setShowClearBtn(true);
    };

    const toggleDateSwitchHandler = (value) => {
        setIsDateNode(value);

        setSelectedNodes({
            ...selectedNodes,
            [currentKey]: {
                ...selectedNodes?.[currentKey],
                dateType: value ? dateType : "UTC",
                isDate: value,
            },
        });
    };

    const toggleWithTimeSwitchHandler = (value) => {
        setWithTimeNode(value);

        setSelectedNodes({
            ...selectedNodes,
            [currentKey]: {
                ...selectedNodes?.[currentKey],
                withTime: value,
            },
        });
    };

    const toggleAttributeSwitchHandler = (value) => {
        setIsAttributeNode(value);

        setSelectedNodes({
            ...selectedNodes,
            [currentKey]: {
                ...selectedNodes?.[currentKey],
                isAttribute: value,
            },
        });
    };

    const clearOptionHandler = () => {
        setSelectedNodes({
            ...selectedNodes,
            [currentKey]: {
                ...selectedNodes?.[currentKey],
                isSelection: false,
                label: textFieldValue || null,
                value: null,
            },
        });

        setSelectMenuValue(defaultOption);

        setShowClearBtn(false);
    };

    const editClientXmlFileHandler = () => {
        const clonedTreeObj = global.structuredClone(treeData);

        formatTreeObjHandler(clonedTreeObj);

        editClientXmlFile(clonedTreeObj);
    };

    const renderGroupLabelHandler = ({
        label,
        options,
    }) => (
        <Box
            display="flex"
            justifyContent="space-between"
        >
            <Box>{label}</Box>
            <Box>{options.length}</Box>
        </Box>
    );

    return treeData ? (
        <Grid
            gap={2}
            justifyContent="space-between"
            container
        >
            <StyledBox
                md={7}
                overflow="auto"
                item
            >
                <Typography
                    mb={3}
                    variant="h6"
                >
                    {t("labels.fileInformation")}
                </Typography>
                <Tree
                    ref={treeRef}
                    treeData={treeData}
                    defaultExpandAll
                    onSelect={(key, info) => selectTreeNodeHandler(
                        key,
                        info,
                    )}
                />
            </StyledBox>
            {canDo(
                user,
                [mediaEditPermission],
            ) && (
                <StyledBox
                    height="fit-content"
                    md={4}
                    overflow="auto"
                    position="sticky"
                    top={100}
                    item
                >
                    <LoadingButton
                        color="primary"
                        id={`${saveButtonName}_${buttonId}`}
                        loading={loading}
                        size="large"
                        variant="contained"
                        onClick={editClientXmlFileHandler}
                    >
                        {t("actions.save")}
                    </LoadingButton>
                    {uploadedFileValue && (
                        <Box
                            alignItems="center"
                            display="flex"
                            flexWrap="wrap"
                            gap={1}
                            mt={2}
                        >
                            <Typography
                                color="primary"
                                variant="span"
                            >
                                {t("labels.uploadedFileValue")}
                                :
                                {" "}
                            </Typography>
                            <Chip label={uploadedFileValue} />
                        </Box>
                    )}
                    <>
                        <Box mt={3}>
                            <Select
                                defaultValue={selectMenuValue}
                                formatGroupLabel={renderGroupLabelHandler}
                                id={`${xmlFileMenu}_${menuId}`}
                                isDisabled={disableSelectMenu}
                                options={selectMenuOptions}
                                value={selectMenuValue}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? `${theme.palette.primary.main}!important` : theme.palette.grey[700],
                                        boxShadow: state.isFocused ? `0 0 0 1px ${theme.palette.primary.main}` : theme.palette.grey[700],
                                        color: theme.palette.primary.main,
                                    }),
                                }}
                                onChange={(value) => selectOptionHandler(value)}
                                onMenuClose={() => setMenuOpened(false)}
                                onMenuOpen={() => setMenuOpened(true)}
                            />
                            {disableSelectMenu && !initialRender && (
                                <Typography
                                    color="error"
                                    variant="caption"
                                >
                                    {t("messages.noChildNodeChosen")}
                                </Typography>
                            )}
                            {isDateNode && selectMenuValue.value === 0 && (
                                <Typography
                                    color="error"
                                    variant="caption"
                                >
                                    {t("messages.noDropdownValueChosen")}
                                </Typography>
                            )}
                            {showClearBtn && (
                                <Box mt={2}>
                                    <Button
                                        color="error"
                                        id={`${clearButtonName}_${buttonId}`}
                                        size="small"
                                        variant="contained"
                                        onClick={clearOptionHandler}
                                    >
                                        {t("actions.clear")}
                                    </Button>
                                </Box>
                            )}
                            <Box mt={2}>
                                <StyledTextField
                                    disabled={disableSelectMenu}
                                    helperText={t("labels.textAndDropdownNote")}
                                    id={`${textValueInputName}_${inputId}`}
                                    label={t("labels.addTextValue")}
                                    style={{ zIndex: menuOpened ? -5 : 1 }}
                                    value={textFieldValue}
                                    variant="outlined"
                                    fullWidth
                                    onChange={(e) => changeTextFieldValueHandler(e.target.value)}
                                />
                            </Box>
                        </Box>
                        {!isDateNode && <hr />}
                    </>
                    <Box>
                        <Typography
                            my={2}
                            variant="h6"
                        >
                            {t("labels.options")}
                        </Typography>
                        <Box
                            alignItems="center"
                            display="flex"
                        >
                            <Switch
                                checked={isAttributeNode}
                                disabled={disableSelectMenu && !initialRender}
                                id={`${attributeInputName}_${inputId}`}
                                onChange={(e) => toggleAttributeSwitchHandler(e.target.checked)}
                            />
                            <Typography
                                color="primary"
                                component="span"
                                fontWeight="bold"
                            >
                                {t("labels.attribute")}
                            </Typography>
                        </Box>
                        <Box
                            alignItems="center"
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Box>
                                <Switch
                                    checked={isDateNode}
                                    disabled={disableSelectMenu && !initialRender}
                                    id={`${dateInputName}_${inputId}`}
                                    onChange={(e) => toggleDateSwitchHandler(e.target.checked)}
                                />
                                <Typography
                                    color="primary"
                                    component="span"
                                    fontWeight="bold"
                                >
                                    {t("labels.date")}
                                </Typography>
                            </Box>
                            {isDateNode && (
                                <Box
                                    alignItems="center"
                                    display="flex"
                                >
                                    <Switch
                                        checked={withTimeNode}
                                        disabled={disableSelectMenu && !initialRender}
                                        id={`${withTimeInputName}_${inputId}`}
                                        onChange={(e) => toggleWithTimeSwitchHandler(e.target.checked)}
                                    />
                                    <Typography
                                        color="primary"
                                        component="span"
                                        fontWeight="bold"
                                    >
                                        {t("labels.withTime")}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </StyledBox>
            )}
        </Grid>
    ) : (
        <Typography
            color="primary"
            variant="h4"
        >
            {t("labels.noXmlFileFound")}
        </Typography>
    );
};

export default EditClientXmlFile;

EditClientXmlFile.propTypes = {
    editClientXmlFile: PropTypes.func,
    loading: PropTypes.bool,
    selectMenuOptions: PropTypes.array,
    treeData: PropTypes.array,
};
