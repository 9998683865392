const fileData = {
    acceptedExtensions: {
        application: "application",
        audio: "audio/*",
        csv: "text/csv",
        document: "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,  application/vnd.ms-word, text/plain, application/pdf,.xlsx,.csv",
        image: "image/*",
        sshKey: ".ppk,.pem",
        text: "text",
        video: "video/*,.mxf",
        xml: "text/xml",
    },
    extensions: {
        audio: ["mp3", "wav", "flac", "aac", "midi", "mpeg", "webm"],
        csv: ["csv"],
        document: ["pdf", "docx", "doc", "csv", "txt", "application", "xls"],
        image: ["png", "jpg", "jpeg", "webp"],
        sshKey: ["ppk", "pem"],
        video: ["mp4", "webm", "flv", "mov", "avi", "mkv", "wmv", "mxf", "mpeg", "mpg", "ogg"],
        xml: ["xml"],
    },
    names: {
        document: "documentFile",
        image: "imageFile",
        lyrics: "lyricsFile",
        releaseClientLinks: "releaseClientLinksFile",
        revenueReport: "revenueReportFile",
        revenueReportTemplate: "revenueReportTemplateFile",
        sshKey: "sshKeyFile",
        track: "trackFile",
        video: "videoFile",
        xml: "xmlFile",
    },
    types: {
        artwork: "ArtWork",
        track: "Track",
        xml: "XML",
    },
    uploadedTypes: {
        documentFile: "DOCUMENT",
        imageFile: "IMAGE",
        lyricsFile: "LYRIC_FILE",
        releaseClientLinksFile: "DOCUMENT",
        revenueReportFile: "DOCUMENT",
        revenueReportTemplateFile: "DOCUMENT",
        sshKeyFile: "KEYFILE",
        trackFile: "TRACK",
        videoFile: "VIDEO",
        xmlFile: "DOCUMENT",
    },
};

export default fileData;
