import { regexData } from "data";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useReleaseInfoSchema = () => {
    const { t } = useTranslation();

    const { number: numberRegex } = regexData;

    return yup.object({
        albumCode: yup.string().required(t("messages.inputMsgs.required")).matches(
            numberRegex,
            t("messages.inputMsgs.invalidNumber"),
        ),
        discNumber: yup.string().matches(
            numberRegex,
            t("messages.inputMsgs.invalidNumber"),
        ).nullable(),
        endDate: yup.date(),
        genre: yup.string().required(t("messages.inputMsgs.required")),
        imageFile: yup.mixed().required(t("messages.inputMsgs.required")),
        isCompleted: yup["boolean"](),
        nameAr: yup.string().required(t("messages.inputMsgs.required")),
        nameEn: yup.string().required(t("messages.inputMsgs.required")),
        primaryArtist: yup.string().required(t("messages.inputMsgs.required")),
        releaseDate: yup.date().required(t("messages.inputMsgs.required")),
        releaseType: yup.string().required(t("messages.inputMsgs.required")),
        rightType: yup.string().required(t("messages.inputMsgs.required")),
    });
};

export default useReleaseInfoSchema;
