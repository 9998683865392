import { yupResolver } from "@hookform/resolvers/yup";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { Box } from "@mui/material";
import { AutoCompleteMenu, List, Loader } from "atoms";
import { FormContainer } from "components";
import {
    actionsData,
    identifiersData,
    inputData,
    namesData,
    selectionsData,
    territoryFormData,
    urlsData,
} from "data";
import { useCountriesSelectionList, useDeleteReleaseOwnership } from "hooks";
import { PropTypes } from "prop-types";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useTerritorySchema } from "schemas";
import { StyledTextField } from "styles";
import { renderFormControllerHandler } from "utils";

const TerritoryForm = ({
    action,
    loading,
    onSubmitForm,
    values,
}) => {
    const [ownerships, setOwnerships] = useState(values?.ownerships || []);

    const { t } = useTranslation();

    const selections = useSelector((state) => state.selectionsReducer);

    const schema = useTerritorySchema();

    const formMethods = useForm({
        defaultValues: {
            countries: values?.countries?.map(({ id }) => ({ id })),
            name: values?.name,
        },
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const { loading: countriesSelectionListLoading } = useCountriesSelectionList();

    const { deleteReleaseOwnership } = useDeleteReleaseOwnership(
        null,
        setOwnerships,
    );

    const { countries } = selectionsData.keys;

    const {
        control,
        formState: { errors },
    } = formMethods;

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        type,
        isOptional,
        selectionKey,
    ) => {
        if (type === inputData.types.text) {
            return (
                <StyledTextField
                    {...field}
                    disabled={!!values?.email && name === inputData.names.email}
                    error={errors[name]}
                    helperText={errors[name] && errors[name]?.message}
                    id={`${name}_${identifiersData.ids.input}`}
                    label={t(`labels.${label}`)}
                    variant="outlined"
                    fullWidth
                />
            );
        }

        return (
            <AutoCompleteMenu
                defaultValue={selections?.[selectionKey]?.filter((option) => values?.[name]?.find((country) => country?.id === option?.value))}
                errors={errors}
                getRenderedOption={(option) => option?.label}
                isOptional={isOptional}
                label={label}
                name={name}
                optionLabel={(option) => option.label}
                options={selections[countries]}
                isCountriesMenu
                isMultiple
                onChange={(_, options) => {
                    if (options.length > 0) field.onChange(options);
                    else field.onChange([]);
                }}
            />
        );
    };

    if (countriesSelectionListLoading) return <Loader />;

    return (
        <FormContainer
            action={action}
            formMethods={formMethods}
            loading={loading}
            data={renderFormControllerHandler(
                control,
                territoryFormData,
                renderFormControllerChildrenHandler,
            )}
            otherData={action === actionsData.edit && ownerships?.length > 0 && (
                <Box maxWidth={400}>
                    <List
                        routeUrl={`${urlsData.routes.releases.edit}`}
                        subRouteUrl="?tab=4"
                        title={t("labels.relatedOwnerships")}
                        data={ownerships?.filter(({ release }) => release !== null)?.map(({ release }) => ({
                            entityId: release?.ownership?.id,
                            id: release.id,
                            name: release.album_name,
                        }))}
                        icons={[
                            {
                                Icon: <DeleteForeverOutlinedIcon />,
                                action: (id) => deleteReleaseOwnership(id),
                                name: namesData.buttons.deleteReleaseOwnership,
                                tooltip: t("actions.deleteReleaseOwnership"),
                            },
                        ]}
                        withoutComparingData
                    />
                </Box>
            )}
            onSubmitForm={onSubmitForm}
        />
    );
};

export default TerritoryForm;

TerritoryForm.propTypes = {
    action: PropTypes.string,
    loading: PropTypes.bool,
    onSubmitForm: PropTypes.func,
    values: PropTypes.object,
};
