import { Drawer } from "atoms";
import { DriveLinkForm } from "components";
import { actionsData } from "data";
import { useCreateDriveLink, useEditDriveLink } from "hooks";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toggleDriveLinkDrawer } from "redux/actions";

const DriveLinkDrawer = ({
    driveLink,
    entityId,
    entityType,
}) => {
    const { t } = useTranslation();

    const open = useSelector((state) => state.driveLinksReducer?.openDrawer);

    const {
        createDriveLink,
        loading: createDriveLinkLoading,
    } = useCreateDriveLink(
        parseInt(entityId),
        entityType,
    );

    const {
        editDriveLink,
        loading: editDriveLinkLoading,
    } = useEditDriveLink(
        driveLink?.id,
        parseInt(entityId),
        entityType,
    );

    const dispatch = useDispatch();

    const {
        id,
        name,
        url,
    } = driveLink || {};

    const values = {
        driveLink: url,
        id,
        resourceName: name,
    };

    return (
        <Drawer
            closeAction={() => dispatch(toggleDriveLinkDrawer(false))}
            open={open}
            title={`${t(`actions.${id ? "edit" : "add"}`)} ${entityType} ${t("labels.driveLink")}`}
        >
            <DriveLinkForm
                action={id ? actionsData.edit : actionsData.create}
                loading={id ? editDriveLinkLoading : createDriveLinkLoading}
                values={values}
                onSubmitForm={id ? editDriveLink : createDriveLink}
            />
        </Drawer>
    );
};

export default DriveLinkDrawer;

DriveLinkDrawer.propTypes = {
    driveLink: PropTypes.object,
    entityId: PropTypes.number,
    entityType: PropTypes.string,
};
