import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { identifiersData } from "data";

const StyledFileLink = styled(
    Box,
    { shouldForwardProp: (prop) => prop !== identifiersData.props.hasJustifyStart },
)(({
    hasJustifyStart,
    theme,
}) => ({
    alignItems: "center",
    color: theme.palette?.grey[700],
    display: "flex",
    fontSize: 12,
    fontWeight: "bold",
    gap: 8,
    justifyContent: hasJustifyStart ? "start" : "space-between",
    marginBottom: 8,
    marginTop: 8,
    width: "100%",
    wordBreak: "break-word",
}));

export default StyledFileLink;
