import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData } from "data";
import { editClientXmlFileMutation, getClientTreeObjQuery } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useEditClientXmlFile = (id) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [editClientXmlFileAction, { loading }] = useMutation(
        editClientXmlFileMutation,
        {
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: getClientTreeObjQuery,
                    variables: {
                        where: { id },
                    },
                },
            ],
        },
    );

    const editClientXmlFile = async (data) => {
        try {
            await editClientXmlFileAction({
                onCompleted: () => {
                    dispatch(openAlert(
                        t(
                            "messages.editMsgs.success",
                            { entity: `${t("clients.entity")} ${t("labels.xmlFile")}` },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: {
                    data: { treeObj: data },
                    where: { id },
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        editClientXmlFile,
        loading,
    };
};

export default useEditClientXmlFile;
