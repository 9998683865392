import { TOGGLE_DISTRIBUTION_DRAWER } from "redux/types";
import { updateStateHandler } from "utils";

const initialState = { openDrawer: false };

const toggleDistributionDrawer = (state, open) => updateStateHandler(
    state,
    { openDrawer: open },
);

const distributionsReducer = (state = initialState, action) => {
    const {
        payload,
        type,
    } = action;

    const { open } = payload || {};

    if (type === TOGGLE_DISTRIBUTION_DRAWER) {
        return toggleDistributionDrawer(
            state,
            open,
        );
    }

    return state;
};

export default distributionsReducer;
