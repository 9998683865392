import { useAuth0 } from "@auth0/auth0-react";
import { constantsData, urlsData } from "data";
import { useApisClient } from "hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { openAlert, setDriveManagementLabelsList } from "redux/actions";
import { displayErrorHandler } from "utils";

const useDriveManagementLabelsList = () => {
    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(true);

    const [fetched, setFetched] = useState(false);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const labels = useSelector((state) => state.driveManagementReducer?.labels);

    const { logout } = useAuth0();

    const { get } = useApisClient();

    useEffect(() => {
        if (!fetched) {
            setLoading(true);

            get(
                urlsData.apis.driveManagement.labels,
                null,
                {
                    pageSize: 200,
                    publishedOnly: true,
                },
            ).
                then(({ data: responseData }) => {
                    setData(responseData.labels);

                    dispatch(setDriveManagementLabelsList(responseData.labels));
                })["catch"]((err) => {
                    displayErrorHandler(
                        err.response.data.message === constantsData.networkError ? t("messages.networkFailure") : err.response.data.message,
                        dispatch,
                        openAlert,
                        err.response.status,
                        logout,
                    );
                })["finally"](() => {
                    setLoading(false);

                    setFetched(true);
                });
        } else {
            setData(labels);

            setLoading(false);
        }
    }, [fetched]); //eslint-disable-line

    return {
        data: data.length > 0 ? data : labels,
        loading,
    };
};

export default useDriveManagementLabelsList;
