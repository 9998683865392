import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { constantsData, selectionsData } from "data";
import { getTracksListQuery } from "graph";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { openAlert, setReleaseTracks, setSelection } from "redux/actions";
import { displayErrorHandler } from "utils";

const useTracksList = (
    isPaginated = true,
    hasAutoCompleteMenu = false,
    filters = {},
    importerData,
    releaseId,
    searchValue,
) => {
    const [params] = useSearchParams();

    const pageParam = parseInt(params.get("page")) || 1;

    const limitParam = parseInt(params.get("limit")) || 25;

    const [page, setPage] = useState(pageParam);

    const [limit, setLimit] = useState(limitParam);

    const [cancelRequest, setCancelRequest] = useState(false);

    const [fetched, setFetched] = useState(false);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const selections = useSelector((state) => state.selectionsReducer);

    const { isAuthenticated } = useAuth0();

    const { tracks: tracksKey } = selectionsData.keys;

    const {
        data,
        error,
        loading,
        refetch,
    } = useQuery(
        getTracksListQuery,
        {
            onCompleted: ({ listTracks }) => {
                if (!isPaginated || hasAutoCompleteMenu) {
                    const fetchedList = listTracks?.posts?.map(({
                        id,
                        title,
                    }) => ({
                        label: title,
                        value: id,
                    }));

                    dispatch(setSelection({
                        [tracksKey]: selections?.[tracksKey]?.length > 0 && hasAutoCompleteMenu ? selections?.[tracksKey].concat(fetchedList) : fetchedList,
                    }));
                }

                setFetched(true);
            },
            ...hasAutoCompleteMenu && { notifyOnNetworkStatusChange: true },
            skip: !isAuthenticated || (importerData && importerData.isrcsList?.length === 0) || cancelRequest,
            variables: {
                ...isPaginated && {
                    limit,
                    page: page - 1,
                },
                orderBy: { track_num: "asc" }, //eslint-disable-line
                where: {
                    ...!importerData && {
                        AND: [{
                            ...((filters?.releases && Object.keys(filters?.releases).length > 0) || releaseId) && {
                                releaseTracks: {
                                    ...filters?.releases && Object.keys(filters?.releases).length > 0 && {
                                        every: {
                                            release: {
                                                ...filters?.releases,
                                                artist: {},
                                            },
                                        },
                                    },
                                    ...releaseId && {
                                        some: {
                                            releaseId: { equals: releaseId },
                                        },
                                    },
                                },
                            },
                            ...filters?.tracks && Object.keys(filters?.tracks).length > 0 && {
                                ...filters?.tracks?.duration && {
                                    trackFile: { duration: filters?.tracks?.duration },
                                },
                                ...filters?.tracks?.isrc && { isrc: filters?.tracks?.isrc },
                            },
                            ...filters?.videos && Object.keys(filters?.videos).length > 0 && {
                                NOT: {
                                    videos: { none: {} },
                                },
                            },
                            ...filters?.releases?.artist?.id && { artist: filters?.releases?.artist },
                        }],
                    },
                    ...importerData && {
                        OR: [
                            {
                                ...importerData && {
                                    isrc: { in: importerData.isrcsList },
                                },
                            },
                        ],
                    },
                    ...searchValue && {
                        title: { contains: searchValue },
                    },
                },
            },
        },
    );

    useEffect(
        () => {
            if (importerData?.stopping) setCancelRequest(false);
        },
        [importerData?.stopping],
    );

    useEffect(
        () => {
            if (releaseId) {
                const tracks = data?.listTracks?.posts.map((track) => ({
                    ...track,
                    releaseTrackId: track.releaseTracks.find((releaseTrack) => releaseTrack?.releaseId === releaseId)?.id,
                }));

                dispatch(setReleaseTracks(tracks));
            }
        },
        [data], // eslint-disable-line
    );

    useEffect(
        () => {
            if (error?.message === constantsData.failedToFetch && !loading) {
                if (importerData) {
                    dispatch(importerData?.setInvalidImporterRelease(
                        {
                            isNetworkFailed: true,
                            originalData: importerData?.originalData,
                            release: {
                                data: importerData?.release,
                                message: t("messages.networkFailure"),
                            },
                        },
                    ));
                } else {
                    displayErrorHandler(
                        t("messages.networkFailure"),
                        dispatch,
                        openAlert,
                    );
                }
            }
        },
        [error, data], // eslint-disable-line
    );

    useEffect(
        () => {
            dispatch(setSelection({ [tracksKey]: [] }));
        },
        [], // eslint-disable-line
    );

    return {
        cancelRequest: () => setCancelRequest(true),
        count: data?.listTracks?.meta?.nodeCount,
        data: data?.listTracks.posts,
        fetched,
        limit,
        loading,
        page: data?.listTracks?.meta?.currentPage,
        refetch,
        searchKey: "title",
        setFetched,
        setLimit,
        setPage,
    };
};

export default useTracksList;
