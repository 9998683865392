import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ClearIcon from "@mui/icons-material/Clear";
import FlagIcon from "@mui/icons-material/Flag";
import {
    Autocomplete,
    Avatar,
    Box,
    Checkbox,
    Chip,
    debounce,
} from "@mui/material";
import Loader from "atoms/loader";
import { constantsData, identifiersData } from "data";
import { useGetLastElement } from "hooks";
import { PropTypes } from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setSelection } from "redux/actions";
import { StyledTextField } from "styles";

const AutoCompleteMenu = ({
    asyncMenuData,
    defaultValue,
    disabledOption,
    errors,
    getRenderedOption,
    hasSmallField,
    isAsyncMenu,
    isCountriesMenu,
    isFilterInput,
    isMultiple,
    isOptional,
    label,
    loading,
    name,
    onChange,
    optionLabel,
    options,
    size,
    withoutLabel,
    withoutLabelTranslation,
}) => {
    const [selectedOption, setSelectedOption] = useState(null);

    const [textFieldValue, setTextFieldValue] = useState("");

    const [fetching, setFetching] = useState(false);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const {
        count: asyncMenuCount,
        loading: asyncMenuLoading,
        page: asyncMenuPage,
        refetch: asyncMenuRefetch,
        searchKey: asyncMenuSearchKey,
        selectionKey: asyncMenuSelectionKey,
    } = asyncMenuData || {};

    const { lastElement } = useGetLastElement(
        asyncMenuLoading,
        options?.length < asyncMenuCount,
        () => {
            asyncMenuRefetch({
                limit: 25,
                page: asyncMenuPage + 1,
                where: {
                    ...textFieldValue && {
                        [asyncMenuSearchKey]: { contains: textFieldValue },
                    },
                },
            });
        },
    );

    let inputLabel = "";

    if (withoutLabelTranslation) inputLabel = label;
    else inputLabel = t(`labels.${label}`);

    const getInputPlaceHolderHandler = () => {
        if (defaultValue?.length > 1) return `+ ${defaultValue?.length - 1}`; //eslint-disable-line

        return "";
    };

    const fetchSearchDataHandler = (value) => {
        if (value !== undefined) {
            dispatch(setSelection({ [asyncMenuSelectionKey]: [] }));

            if (value) {
                asyncMenuRefetch({
                    limit: 25,
                    page: 0,
                    where: {
                        [asyncMenuSearchKey]: { contains: value },
                    },
                });
            }

            if (!value) {
                asyncMenuRefetch({
                    limit: 25,
                    page: 0,
                    where: {},
                });
            }
        }
    };

    const callSearchDataHandler = useCallback(
        debounce(
            (value) => {
                fetchSearchDataHandler(value);
            },
            700,
        ),
        [],
    ); /* eslint react-hooks/exhaustive-deps: "off" */

    const changeTextFieldValueHandler = (e) => {
        setFetching(true);

        if (e) {
            const { value } = e.target;

            setTextFieldValue(value);

            callSearchDataHandler(value);
        }
    };

    const changeMenuValueHandler = (e, option) => {
        onChange(
            e,
            option,
        );

        if (isAsyncMenu) {
            if (e.target.classList.contains(identifiersData.classNames.clearIndicator)) {
                setSelectedOption(null);

                fetchSearchDataHandler("");
            } else if (Array.isArray(option)) {
                if (option.length > 0) setSelectedOption(option);
                else {
                    setSelectedOption(null);

                    fetchSearchDataHandler("");
                }
            } else setSelectedOption(option);
        }
    };

    useEffect(
        () => {
            if (!asyncMenuLoading && fetching) setFetching(false);
        },
        [options, asyncMenuLoading],
    );

    return (
        <Autocomplete
            autoComplete={false}
            disableCloseOnSelect={isMultiple}
            disabled={loading}
            getOptionDisabled={(option) => option.value === disabledOption}
            getOptionLabel={optionLabel || ""}
            id={`${name}_${identifiersData.ids.input}`}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            multiple={!!isMultiple}
            options={options || []}
            renderTags={(isFilterInput && isMultiple) && (() => <Chip label={defaultValue?.[0]?.label} />)}
            size={size}
            sx={{ "&.MuiAutocomplete-root": { zIndex: "99999 !important" } }}
            clearIcon={(
                <ClearIcon
                    fontSize="small"
                    onClick={() => {
                        if (isAsyncMenu) {
                            setSelectedOption(null);

                            fetchSearchDataHandler("");
                        }
                    }}
                />
            )}
            defaultValue={defaultValue || {
                label: "",
                value: "",
            }}
            noOptionsText={isAsyncMenu && (asyncMenuLoading || fetching) ? (
                <Loader
                    size={20}
                    withoutFullHeight
                />
            ) : t("labels.noOptions")}
            renderInput={(params) => (
                <StyledTextField
                    {...params}
                    autoComplete="off"
                    className={hasSmallField && identifiersData.classNames.smallField}
                    error={errors?.[name]}
                    helperText={errors?.[name] && errors?.[name]?.message}
                    label={!withoutLabel && (inputLabel + (isOptional ? ` (${t("labels.optional")})` : ""))}
                    name={name}
                    placeholder={!withoutLabel && (isFilterInput ? getInputPlaceHolderHandler() : inputLabel + (isOptional ? ` (${t("labels.optional")})` : ""))}
                    valuesLength={defaultValue?.length}
                    variant="outlined"
                    onBlur={() => {
                        if ((!selectedOption || (selectedOption && isMultiple)) && textFieldValue && isAsyncMenu) {
                            setTextFieldValue("");

                            fetchSearchDataHandler("");
                        }
                    }}
                    onChange={(e) => {
                        if (isAsyncMenu) changeTextFieldValueHandler(e);
                    }}
                />
            )}
            renderOption={(props, option, { selected }) => (
                <>
                    <Box
                        display="block"
                        gap={1}
                        key={option?.value}
                        {...props}
                        {...isAsyncMenu ? { ref: options?.length === props?.[constantsData.dataOptionIndex] + 1 ? lastElement : null } : null} // eslint-disable-line
                    >
                        {isCountriesMenu ? (
                            <>
                                <Checkbox
                                    checked={selected}
                                    checkedIcon={<CheckBoxIcon color="primary" />}
                                    icon={<CheckBoxOutlineBlankIcon />}
                                />
                                <Avatar
                                    alt={t("imageAlternatives.country")}
                                    imgProps={{ loading: "lazy" }}
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    sx={{
                                        height: 20,
                                        width: 20,
                                    }}
                                >
                                    <FlagIcon />
                                </Avatar>
                            </>
                        ) : (
                            <Checkbox
                                checked={selected}
                                checkedIcon={<CheckBoxIcon color="primary" />}
                                icon={<CheckBoxOutlineBlankIcon />}
                            />
                        )}
                        {getRenderedOption(option)}
                    </Box>
                    {asyncMenuLoading && props?.[constantsData.dataOptionIndex] + 1 === options.length && ( // eslint-disable-line
                        <Loader
                            size={20}
                            withoutFullHeight
                        />
                    )}
                </>
            )}
            onChange={(e, option) => {
                changeMenuValueHandler(
                    e,
                    option,
                );
            }}
        />
    );
};

export default AutoCompleteMenu;

AutoCompleteMenu.propTypes = {
    asyncMenuData: PropTypes.object,
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.array,
    ]),
    disabledOption: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    errors: PropTypes.object,
    getRenderedOption: PropTypes.func,
    hasSmallField: PropTypes.bool,
    isAsyncMenu: PropTypes.bool,
    isCountriesMenu: PropTypes.bool,
    isFilterInput: PropTypes.bool,
    isMultiple: PropTypes.bool,
    isOptional: PropTypes.bool,
    label: PropTypes.string,
    loading: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    optionLabel: PropTypes.func,
    options: PropTypes.array,
    size: PropTypes.string,
    withoutLabel: PropTypes.bool,
    withoutLabelTranslation: PropTypes.bool,
};
