import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useImportRevenueReportSchema = () => {
    const { t } = useTranslation();

    return yup.object({
        client: yup.string().required(t("messages.inputMsgs.required")),
        revenueReportFile: yup.string().required(t("messages.inputMsgs.required")),
        templateName: yup.string().required(t("messages.inputMsgs.required")),
    });
};

export default useImportRevenueReportSchema;
