import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Accordion as MuiAccordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from "@mui/material";
import { identifiersData } from "data";
import { PropTypes } from "prop-types";

const Accordion = ({
    details,
    rowId,
    summary,
}) => (
    <MuiAccordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id={`${identifiersData.ids.accordion}_${rowId}`}
        >
            <Typography
                color="primary"
                fontWeight="bold"
            >
                {summary}
            </Typography>
        </AccordionSummary>
        <AccordionDetails>{details}</AccordionDetails>
    </MuiAccordion>
);

export default Accordion;

Accordion.propTypes = {
    details: PropTypes.node,
    rowId: PropTypes.number,
    summary: PropTypes.string,
};
