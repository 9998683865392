import styled from "@emotion/styled";
import { Box } from "@mui/material";
import VideoPlaceholder from "assets/images/video-placeholder.jpg";
import { identifiersData } from "data";

const {
    classNames: { thumbnail: thumbnailClassName },
    props: {
        backgroundImg: backgroundImgProp,
        hasSelectMode: hasSelectModeProp,
    },
} = identifiersData;

const StyledThumbnailVideoPlayer = styled(
    Box,
    { shouldForwardProp: (prop) => prop !== backgroundImgProp && prop !== hasSelectModeProp },
)(({
    backgroundImg,
    hasSelectMode,
    id,
    theme,
    videos,
}) => ({
    [`& .${thumbnailClassName}`]: {
        "& div.MuiBox-root": {
            left: "50%",
            position: "absolute",
            top: "25%",
            transform: "translate(-50%)",
        },
        "& div.MuiChip-root": {
            background: theme.palette?.primary.main,
            color: theme.palette?.grey[100],
            padding: 0,
            position: "absolute",
            right: "5%",
            top: "5%",
        },
        "& img": { borderRadius: "50%" },
        background: backgroundImg ? `url(${window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_DOMAIN + backgroundImg})` : `url(${VideoPlaceholder})`, // eslint-disable-line
        backgroundSize: "cover",
        borderRadius: 15,
        cursor: "pointer",
        height: 160,
        position: "relative",
        width: 230,
    },
    border: hasSelectMode && videos.find((videoId) => videoId === id) ? `4px solid ${theme.palette.primary.main}` : "none",
    borderRadius: 15,
    cursor: hasSelectMode && "pointer",
    padding: 10,
    width: "fit-content",
}));

export default StyledThumbnailVideoPlayer;
