import inputData from "data/input";
import selectionsData from "data/selections";

const {
    fileTypes: fileTypesSelectionKey,
    xmlFileNames: xmlFileNamesSelectionKey,
} = selectionsData.keys;

const {
    autoComplete: autoCompleteInputType,
    text: textInputType,
} = inputData.types;

const {
    fileType: fileTypeInputName,
    selectableFileName: selectableFileNameInputName,
    textFileName: textFileNameInputName,
} = inputData.names;

const {
    fileType: fileTypeInputLabel,
    selectableFileName: selectableFileNameInputLabel,
    textFileName: textFileNameInputLabel,
} = inputData.labels;

const foldersStructureXmlFileFormData = [
    {
        col: { xs: 12 },
        label: fileTypeInputLabel,
        name: fileTypeInputName,
        selectionKey: fileTypesSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: { xs: 12 },
        label: textFileNameInputLabel,
        name: textFileNameInputName,
        type: textInputType,
    },
    {
        col: { xs: 12 },
        label: selectableFileNameInputLabel,
        name: selectableFileNameInputName,
        selectionKey: xmlFileNamesSelectionKey,
        type: autoCompleteInputType,
    },
];

export default foldersStructureXmlFileFormData;
