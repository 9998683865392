import { useAuth0 } from "@auth0/auth0-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormContainer } from "components";
import {
    driveLinkFormData,
    identifiersData,
    inputData,
    permissionsData,
} from "data";
import { PropTypes } from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDriveLinkSchema } from "schemas";
import { StyledTextField } from "styles";
import { canDo, renderFormControllerHandler } from "utils";

const DriveLinkForm = ({
    action,
    loading,
    onSubmitForm,
    values,
}) => {
    const { t } = useTranslation();

    const { user } = useAuth0();

    const schema = useDriveLinkSchema();

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const {
        control,
        formState: { errors },
    } = formMethods;

    const {
        mediaCreate: mediaCreatePermission,
        mediaRead: mediaReadPermission,
    } = permissionsData.permissions.keys;

    const allowedPermissions = [mediaCreatePermission, mediaReadPermission];

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        type,
    ) => {
        if (type === inputData.types.text) {
            return (
                <StyledTextField
                    {...field}
                    error={errors[name]}
                    helperText={errors?.[name]?.message}
                    id={`${name}_${identifiersData.ids.input}`}
                    label={t(`labels.${label}`)}
                    variant="outlined"
                    fullWidth
                />
            );
        }
    };

    return (
        <FormContainer
            action={action}
            formMethods={formMethods}
            loading={loading}
            data={renderFormControllerHandler(
                control,
                driveLinkFormData,
                renderFormControllerChildrenHandler,
            )}
            hasSubmitButton={canDo(
                user,
                allowedPermissions,
            )}
            withoutShadow
            onSubmitForm={onSubmitForm}
        />
    );
};

export default DriveLinkForm;

DriveLinkForm.propTypes = {
    action: PropTypes.string,
    loading: PropTypes.bool,
    onSubmitForm: PropTypes.func,
    values: PropTypes.object,
};
