const urlsData = {
    apis: {
        driveManagement: {
            drives: "drives",
            files: {
                notifySuccessUploaded: "notify-users-data-uploaded",
                session: "files/upload/resumable-upload",
                url: "files",
            },
            labels: "labels",
            logout: "logout",
            users: {
                permissions: "permissions",
                search: "groups/users/search",
                url: "users",
            },
        },
    },
    routes: {
        artists: {
            create: "/artists/create",
            edit: "/artists/edit",
            url: "/artists",
        },
        clients: {
            create: "/clients/create",
            edit: "/clients/edit",
            url: "/clients",
            xmlFile: {
                edit: "/xml-file/edit",
                preview: "/xml-file/preview",
            },
        },
        distributions: "/distributions",
        driveManagement: {
            drives: "/drives",
            folders: "/folders",
            search: "/search",
            url: "/drive-management",
        },
        dynamicXml: "/dynamic-xml",
        edit: "/edit",
        groups: {
            create: "/groups/create",
            edit: "/groups/edit",
            members: {
                create: "/members/create",
                edit: "/members/edit",
                url: "/members",
            },
            url: "/groups",
        },
        home: "/",
        login: "/login",
        notFound: "*",
        permissions: "/permissions",
        releases: {
            create: "/releases/create",
            edit: "/releases/edit",
            importer: "/releases/importer",
            url: "/releases",
        },
        revenueReports: { url: "/revenue-reports" },
        search: "/search",
        territories: {
            create: "/territories/create",
            edit: "/territories/edit",
            url: "/territories",
        },
        tracks: {
            create: "/tracks/create",
            edit: "/tracks/edit",
            url: "/tracks",
        },
        users: {
            create: "/users/create",
            edit: "/users/edit",
            url: "/users",
        },
        videos: "/videos",
    },
};

export default urlsData;
