import { Box, Skeleton } from "@mui/material";
import PropTypes from "prop-types";

const SkeletonLoader = ({
    count = 3,
    height = 20,
    style,
    width = 200,
}) => {
    const renderSkeletonsHandler = () => {
        const skeletonArray = [];

        for (let index = 0; index < count;) {
            skeletonArray.push(index);

            index += 1;
        }

        return skeletonArray;
    };

    return (
        <Box>
            {renderSkeletonsHandler().map((_, index) => (
                <Skeleton
                    height={height}
                    key={index} // eslint-disable-line
                    sx={style || {}}
                    width={width}
                />
            ))}
        </Box>
    );
};

export default SkeletonLoader;

SkeletonLoader.propTypes = {
    count: PropTypes.number,
    height: PropTypes.number,
    style: PropTypes.object,
    width: PropTypes.number,
};
