import fileData from "data/file";
import inputData from "data/input";
import selectionsData from "data/selections";

const { clients: clientsSelectionKey } = selectionsData.keys;

const { revenueReport: revenueReportFileName } = fileData.names;

const {
    autoComplete: autoCompleteInputType,
    file: fileInputType,
} = inputData.types;

const {
    client: clientInputName,
    templateName: templateNameInputName,
} = inputData.names;

const {
    client: clientInputLabel,
    revenueReport: revenueReportFileInputLabel,
    templateName: templateNameInputLabel,
} = inputData.labels;

const importRevenueReportFormData = [
    {
        col: { xs: 12 },
        isAsyncMenu: true,
        label: clientInputLabel,
        name: clientInputName,
        selectionKey: clientsSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: { xs: 12 },
        label: templateNameInputLabel,
        name: templateNameInputName,
        type: autoCompleteInputType,
    },
    {
        col: { xs: 12 },
        label: revenueReportFileInputLabel,
        name: revenueReportFileName,
        type: fileInputType,
    },
];

export default importRevenueReportFormData;
