import { gql } from "@apollo/client";

export const getVideosListQuery = gql`
    query getVideosList(
        $page: Int!,
        $limit: Int = 100000,
        $where: VideoWhereInput!
    ) {
        listVideos(
            page: $page,
            elementsPerPage: $limit,
            videoFilter: $where
        ) {
            posts {
                id
                downloadUri
                type {
                    name
                }
                image {
                    downloadThumbnail
                }
                release {
                    image {
                        downloadThumbnail
                    }
                }
                track {
                    id
                    title
                    image {
                        downloadThumbnail
                    }
                    artist {
                        id
                        name
                    }
                }
            }
            meta {
                resultCount
                nodeCount
                currentPage
                totalPages
            }
        }
    }
`;

export const getVideoCutsListQuery = gql`
    query getVideoCutsList($id: Int!) {
        getClipsByVideoId(videoId: $id) {
            id
            file {
                id
                file_uri
            }
            downloadUri
        }
    }
`;
