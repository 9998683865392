import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useUserSchema = () => {
    const { t } = useTranslation();

    return yup.object({
        email: yup.string().email(t("messages.inputMsgs.invalidEmail")).required(t("messages.inputMsgs.required")),
        familyName: yup.string().required(t("messages.inputMsgs.required")),
        givenName: yup.string().required(t("messages.inputMsgs.required")),
        role: yup.string().required(t("messages.inputMsgs.required")),
    });
};

export default useUserSchema;
