import { Box } from "@mui/material";
import { AutoCompleteMenu, Calendar } from "atoms";
import { driveManagementData, identifiersData, inputData } from "data";
import { useEditDriveManagementLabel } from "hooks";
import { PropTypes } from "prop-types";
import { useCallback } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { StyledTextField } from "styles";
import { convertDateToIsoFormatHandler, debounce } from "utils";

const DriveManagementLabelForm = ({
    data,
    id,
    labelId,
    selectedValues,
    title,
}) => {
    const { editDriveManagementLabel } = useEditDriveManagementLabel();

    const {
        date,
        integer,
        selection,
        text,
    } = driveManagementData.labels.types;

    const formMethods = useForm({
        defaultValues: {
            ...selectedValues?.Selection && {
                Selection: {
                    label: data.find((field) => field.options.type === selection).options.data.choices.find(({ id: selectionId }) => selectionId === selectedValues.Selection).properties.displayName,
                    value: selectedValues.Selection,
                },
            },
            ...selectedValues?.Date && { Date: new Date(selectedValues.Date) },
            ...selectedValues?.Text && { Text: selectedValues.Text },
            ...selectedValues?.Integer && { Integer: selectedValues.Integer },
        },
        mode: "onSubmit",
    });

    const { control } = formMethods;

    const {
        number: numberInputType,
        text: textInputType,
    } = inputData.types;

    const changeTextFieldValueHandler = useCallback(
        debounce(
            (e, {
                fieldId,
                type,
            }) => {
                editDriveManagementLabel(
                    id,
                    labelId,
                    [{
                        fieldId,
                        ...type === text && { setTextValues: e.target.value },
                        ...type === integer && { setIntegerValues: e.target.value },
                    }],
                );
            },
            1200,
        ),
        [],
    ); /* eslint react-hooks/exhaustive-deps: "off" */

    const changeSelectionFieldValueHandler = (option, fieldId) => {
        editDriveManagementLabel(
            id,
            labelId,
            [{
                fieldId,
                setSelectionValues: [option.value],
            }],
        );
    };

    const changeDateFieldValueHandler = (value, fieldId) => {
        editDriveManagementLabel(
            id,
            labelId,
            [{
                fieldId,
                setDateValues: [convertDateToIsoFormatHandler(new Date(value))],
            }],
        );
    };

    const renderLabelCardFieldsHandler = (
        field,
        options,
        properties,
        fieldId,
    ) => {
        const selectionsOptions = options?.data?.choices?.map(({
            id: selectionId,
            properties: selectionProperties,
        }) => ({
            label: selectionProperties?.displayName,
            value: selectionId,
        })) || [];

        switch (options.type) {
        case text:
        case integer:
            return (
                <StyledTextField
                    {...field}
                    label={title}
                    type={options.type === text ? textInputType : numberInputType}
                    value={field?.value}
                    variant="outlined"
                    id={`${title.replace(
                        /\s+/g,
                        "_",
                    )}_${options.type}_${identifiersData.ids.input}`}
                    name={`${title.replace(
                        /\s+/g,
                        "_",
                    )}_${options.type}`}
                    fullWidth
                    onChange={(e) => {
                        field.onChange(e.target.value);

                        changeTextFieldValueHandler(
                            e,
                            {
                                fieldId,
                                type: options.type,
                            },
                        );
                    }}
                />
            );
        case date:
            return (
                <Calendar
                    field={field}
                    label={title}
                    value={field.value || ""}
                    name={`${title.replace(
                        /\s+/g,
                        "_",
                    )}_${date}`}
                    withoutLabelTranslation
                    onChange={(value) => {
                        changeDateFieldValueHandler(
                            value,
                            fieldId,
                        );

                        field.onChange(new Date(value));
                    }}
                />
            );
        case selection:
            return (
                <AutoCompleteMenu
                    defaultValue={field?.value}
                    getRenderedOption={(option) => option?.label}
                    label={title}
                    optionLabel={(option) => option?.label}
                    options={selectionsOptions}
                    name={`${title.replace(
                        /\s+/g,
                        "_",
                    )}_${selection}`}
                    withoutLabelTranslation
                    onChange={(_, option) => {
                        changeSelectionFieldValueHandler(
                            option,
                            fieldId,
                        );

                        field.onChange(option);
                    }}
                />
            );
        }
    };

    return (
        <FormProvider {...formMethods}>
            <form>
                <Box
                    alignItems="center"
                    display="flex"
                    flexWrap="wrap"
                    gap={2}
                >
                    {data?.map(({
                        id: fieldId,
                        options,
                        properties,
                    }) => (
                        <Box
                            key={fieldId}
                            width="100%"
                            item
                        >
                            <Controller
                                control={control}
                                key={fieldId}
                                name={options.type}
                                render={({ field }) => renderLabelCardFieldsHandler(
                                    field,
                                    options,
                                    properties,
                                    fieldId,
                                )}
                            />
                        </Box>
                    ))}
                </Box>
            </form>
        </FormProvider>
    );
};

export default DriveManagementLabelForm;

DriveManagementLabelForm.propTypes = {
    data: PropTypes.array,
    id: PropTypes.string,
    labelId: PropTypes.string,
    selectedValues: PropTypes.object,
    title: PropTypes.string,
};
