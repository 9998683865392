const regexData = {
    allDigitsSameNumber: /^(?!(.)\1{3})/,
    date: /^([A-Za-z]{3}) (\w{3}) (\d{2}) (\d{4}) (\d{2}):(\d{2}):(\d{2}) GMT([+-]\d{4}) \(([\w\s]+)\)$/,
    domain: /^(((?!\-))(xn\-\-)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn\-\-)?([a-z0-9\-]{1,61}|[a-z0-9\-]{1,30})\.[a-z]{2,}$/,
    email: "[A-Z0-9a-z._%+-]*@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}",
    groupMembers: /\/groups\/(\d+)\/members/,
    incrementingNumbers: /(?!1234567890|012345678)/,
    name: /^[A-Za-z][A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*/,
    number: /^[0-9]*$/,
    numberWithSpaces: "(\s*[0-9]+)+",
    phoneNumber: /^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-\/\s.]|\d)+$/,
    repeatingPatternTypeOne: /^(?!(..)\1{3})/,
    repeatingPatternTypeTwo: /^(?!((.)..)\1{2}\2)/,
    url: /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
};

export default regexData;
