import { Drawer } from "atoms";
import { ReleaseDistributionForm } from "components";
import { actionsData } from "data";
import { useCreateBulkDistributions, useCreateDistribution } from "hooks";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toggleDistributionDrawer } from "redux/actions";

const DistributionDrawer = ({
    hasBulkDistributions,
    hasRefetchDistributions,
    ids,
    limit,
    page,
}) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const params = useParams();

    const open = useSelector((state) => state.distributionsReducer?.openDrawer);

    const {
        createDistribution,
        loading: createDistributionLoading,
    } = useCreateDistribution(
        params?.id && parseInt(params?.id),
        hasRefetchDistributions,
        limit,
        page,
    );

    const {
        createBulkDistributions,
        loading: createBulkDistributionsLoading,
    } = useCreateBulkDistributions(ids);

    return (
        <Drawer
            closeAction={() => dispatch(toggleDistributionDrawer(false))}
            open={open}
            title={t(`releases.${hasBulkDistributions ? "distributeReleases" : "distributeRelease"}`)}
        >
            <ReleaseDistributionForm
                action={actionsData.distribute}
                loading={createDistributionLoading || createBulkDistributionsLoading}
                onSubmitForm={hasBulkDistributions ? createBulkDistributions : createDistribution}
            />
        </Drawer>
    );
};

export default DistributionDrawer;

DistributionDrawer.propTypes = {
    hasBulkDistributions: PropTypes.bool,
    hasRefetchDistributions: PropTypes.bool,
    ids: PropTypes.array,
    limit: PropTypes.number,
    page: PropTypes.number,
};
