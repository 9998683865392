import {
    CLEAR_SEARCH_VALUES,
    DELETE_SEARCH_VALUE,
    SET_SEARCH_CATEGORY,
    SET_SEARCH_DATA,
    SET_SEARCH_VALUE,
    SET_SEARCH_VALUES,
} from "redux/types";

export const setSearchValue = (value) => ({
    payload: { value },
    type: SET_SEARCH_VALUE,
});

export const setSearchValues = (values) => ({
    payload: { values },
    type: SET_SEARCH_VALUES,
});

export const deleteSearchValue = (index) => ({
    payload: { index },
    type: DELETE_SEARCH_VALUE,
});

export const clearSearchValues = () => ({ type: CLEAR_SEARCH_VALUES });

export const setSearchCategory = (category) => ({
    payload: { category },
    type: SET_SEARCH_CATEGORY,
});

export const setSearchData = (data) => ({
    payload: { data },
    type: SET_SEARCH_DATA,
});
