import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ReplayIcon from "@mui/icons-material/Replay";
import {
    Box,
    Button,
    Collapse,
    IconButton,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import { TableList } from "atoms";
import { PageHead } from "components";
import { identifiersData, namesData, tableHeadCellsData } from "data";
import { PropTypes } from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StyledTableCell } from "styles";
import { theme } from "theme";

const ImporterInvalidReleasesTab = ({
    importing,
    retryAllInvalidReleaseCallHandler,
    retryInvalidReleaseCallHandler,
}) => {
    const [opened, setOpened] = useState(null);

    const { t } = useTranslation();

    const { importer } = useSelector((state) => state.releasesReducer);

    const { button: buttonId } = identifiersData.ids;

    const {
        retry: retryButtonName,
        retryAll: retryAllButtonName,
    } = namesData.buttons;

    return (
        <Box>
            {importer?.invalidReleases?.filter((release) => release.isNetworkFailed || release.isStopped)?.length > 1 && window?.__RUNTIME_CONFIG__?.ENABLE_RELEASES_IMPORTER_STOP_AND_RETRY !== "false" && ( //eslint-disable-line
                <PageHead
                    actionComponent={(
                        <Button
                            disabled={importing}
                            id={`${retryAllButtonName}_${buttonId}`}
                            variant="outlined"
                            onClick={retryAllInvalidReleaseCallHandler}
                        >
                            <ReplayIcon />
                            <Typography
                                fontWeight="bold"
                                variant="caption"
                            >
                                {t("actions.retryAll")}
                            </Typography>
                        </Button>
                    )}
                    hasAction
                />
            )}
            <TableList
                headCells={tableHeadCellsData.releases.importer.invalidReleasesTab}
                isPaginated={false}
                hasLimitedHeight
            >
                {importer?.invalidReleases?.map(({
                    artists,
                    documents,
                    isNetworkFailed,
                    isStopped,
                    originalData,
                    release,
                    tracks,
                    videos,
                }, index) => (
                    <>
                        <TableRow
                            key={release.data.upc}
                            sx={{ userSelect: "none" }}
                            aria-checked
                            hover
                            onClick={() => {
                                if (opened === release.data.upc) setOpened(null);
                                else setOpened(release.data.upc);
                            }}
                        >
                            <StyledTableCell>
                                <KeyboardArrowDownIcon
                                    sx={{
                                        transform: opened === release.data.upc && "rotate(180deg)",
                                        transition: "ease-in-out.2s",
                                    }}
                                />
                            </StyledTableCell>
                            <StyledTableCell>{release.data.albumNameEn}</StyledTableCell>
                            <StyledTableCell>{release.data.artist.artistNameEn}</StyledTableCell>
                            <StyledTableCell>{release.data.releaseType?.label || release.data.releaseType}</StyledTableCell>
                            <StyledTableCell>{release.data.releaseDate}</StyledTableCell>
                            <StyledTableCell>{release.data.upc}</StyledTableCell>
                            <StyledTableCell>{release.data.genre.label || release.data.genre}</StyledTableCell>
                            <StyledTableCell onClick={(e) => e.stopPropagation()}>
                                {(isNetworkFailed || isStopped) && window?.__RUNTIME_CONFIG__?.ENABLE_RELEASES_IMPORTER_STOP_AND_RETRY !== "false" && ( //eslint-disable-line
                                    <Tooltip title={t("actions.retry")}>
                                        <IconButton
                                            disabled={importing}
                                            id={`${retryButtonName}_${buttonId}_${index + 1}`}
                                            onClick={() => retryInvalidReleaseCallHandler(originalData)}
                                        >
                                            <ReplayIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                colSpan={8}
                                style={{
                                    paddingBottom: 0,
                                    paddingTop: 0,
                                }}
                            >
                                <Collapse
                                    in={opened === release.data.upc}
                                    timeout="auto"
                                    style={{
                                        paddingBottom: 0,
                                        paddingTop: 0,
                                    }}
                                    unmountOnExit
                                >
                                    {release.message
                                    && (
                                        <Box
                                            sx={{
                                                alignItems: "center",
                                                color: theme.palette.error.dark,
                                                display: "flex",
                                                gap: 1,
                                                margin: 1,
                                            }}
                                        >
                                            <ErrorOutlineIcon />
                                            {release?.message}
                                        </Box>
                                    )}
                                    {tracks && (
                                        <Box sx={{ marginBlock: 2 }}>
                                            <TableList
                                                headCells={tableHeadCellsData.releases.importer.invalidReleasesTabModules.tracks}
                                                isPaginated={false}
                                                limitedHeight={200}
                                                hasLimitedHeight
                                            >
                                                {tracks?.map(({
                                                    data,
                                                    message,
                                                }) => (
                                                    <TableRow key={data?.isrc}>
                                                        <TableCell>{data?.trackTitleEn}</TableCell>
                                                        <TableCell>{data?.isrc}</TableCell>
                                                        <TableCell>
                                                            <Box
                                                                sx={{
                                                                    alignItems: "center",
                                                                    color: theme.palette.error.dark,
                                                                    display: "flex",
                                                                    gap: 1,
                                                                    margin: 1,
                                                                }}
                                                            >
                                                                <ErrorOutlineIcon />
                                                                {message}
                                                            </Box>

                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableList>
                                        </Box>
                                    )}
                                    {artists && (
                                        <Box sx={{ marginBlock: 2 }}>
                                            <TableList
                                                headCells={tableHeadCellsData.releases.importer.invalidReleasesTabModules.artists}
                                                isPaginated={false}
                                                limitedHeight={200}
                                                hasLimitedHeight
                                            >
                                                {artists?.map(({
                                                    data,
                                                    message,
                                                }) => (
                                                    <TableRow key={data?.code}>
                                                        <TableCell>{data?.artistNameEn || "-"}</TableCell>
                                                        <TableCell>{data?.code || "-"}</TableCell>
                                                        <TableCell>
                                                            <Box
                                                                sx={{
                                                                    alignItems: "center",
                                                                    color: theme.palette.error.dark,
                                                                    display: "flex",
                                                                    gap: 1,
                                                                    margin: 1,
                                                                }}
                                                            >
                                                                <ErrorOutlineIcon />
                                                                {message}
                                                            </Box>

                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableList>
                                        </Box>
                                    )}
                                    {documents && (
                                        <Box sx={{ marginBlock: 2 }}>
                                            <TableList
                                                headCells={tableHeadCellsData.releases.importer.invalidReleasesTabModules.documents}
                                                isPaginated={false}
                                                limitedHeight={200}
                                                hasLimitedHeight
                                            >
                                                {documents?.map(({
                                                    data,
                                                    message,
                                                }) => (
                                                    <TableRow key={data?.isrc || data?.upc}>
                                                        <TableCell>{data?.isrc || data?.upc}</TableCell>
                                                        <TableCell>{data?.type || "-"}</TableCell>
                                                        <TableCell>
                                                            <Box
                                                                sx={{
                                                                    alignItems: "center",
                                                                    color: theme.palette.error.dark,
                                                                    display: "flex",
                                                                    gap: 1,
                                                                    margin: 1,
                                                                }}
                                                            >
                                                                <ErrorOutlineIcon />
                                                                {message}
                                                            </Box>

                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableList>
                                        </Box>
                                    )}
                                    {videos && (
                                        <Box sx={{ marginBlock: 2 }}>
                                            <TableList
                                                headCells={tableHeadCellsData.releases.importer.invalidReleasesTabModules.videos}
                                                isPaginated={false}
                                                limitedHeight={200}
                                                hasLimitedHeight
                                            >
                                                {videos?.map(({
                                                    data,
                                                    message,
                                                }) => (
                                                    <TableRow key={data?.file?.name}>
                                                        <TableCell>{data?.file?.name}</TableCell>
                                                        <TableCell>
                                                            <Box
                                                                sx={{
                                                                    alignItems: "center",
                                                                    color: theme.palette.error.dark,
                                                                    display: "flex",
                                                                    gap: 1,
                                                                    margin: 1,
                                                                }}
                                                            >
                                                                <ErrorOutlineIcon />
                                                                {message}
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableList>
                                        </Box>
                                    )}
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    </>
                ))}
            </TableList>
        </Box>
    );
};

export default ImporterInvalidReleasesTab;

ImporterInvalidReleasesTab.propTypes = {
    importing: PropTypes.bool,
    retryAllInvalidReleaseCallHandler: PropTypes.func,
    retryInvalidReleaseCallHandler: PropTypes.func,
};
