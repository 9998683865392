import { gql } from "@apollo/client";

export const getDistributionsListQuery = gql`
    query getDistributionsList(
        $releaseId: Int,
        $limit: Int = 100000,
        $page: Int!
    ) {
        listDistributions(
            releaseId: $releaseId,
            page: $page,
            elementsPerPage: $limit
        ) {
            posts {
                releaseName
                client {
                    name
                }
                status
                progress
                timestamp
                id
                processedOn
                finishedOn
            }
            meta {
                resultCount
                nodeCount
                currentPage
                totalPages
            }
        }
    }
`;
