import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button,
    Divider,
    Grid,
    Typography,
} from "@mui/material";
import { AutoCompleteMenu, Loader } from "atoms";
import { identifiersData, namesData, selectionsData } from "data";
import { useEditClientRevenueReportTemplate, useRevenueReportsSystemFieldsSelectionList } from "hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setRevenueReportTemplateData } from "redux/actions";
import { StyledTextField } from "styles";

const RevenueReportTemplate = () => {
    const [template, setTemplate] = useState({});

    const [errors, setErrors] = useState({});

    const [disabled, setDisabled] = useState(true);

    const { revenueReportTemplate } = useSelector((state) => state.clientsReducer);

    const selections = useSelector((state) => state.selectionsReducer);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const { loading: revenueReportsSystemFieldsLoading } = useRevenueReportsSystemFieldsSelectionList();

    const {
        editClientReportRevenueTemplate,
        loading,
    } = useEditClientRevenueReportTemplate(setDisabled);

    const { revenueReportsSystemFields } = selectionsData.keys;

    const { button: buttonId } = identifiersData.ids;

    const {
        cancel: cancelButtonName,
        save: saveButtonName,
    } = namesData.buttons;

    const {
        data = {},
        id,
        name,
    } = revenueReportTemplate || {};

    const getFinalOptionsHandler = () => {
        const finalOptions = selections?.[revenueReportsSystemFields]?.filter((item) => item?.label);

        finalOptions?.push({
            label: t("labels.don'tMapThisField"),
            value: t("labels.don'tMapThisField"),
        });

        return finalOptions;
    };

    const editTemplateHandler = () => {
        if (Object.values(template)?.filter((item) => item === undefined)?.length === 0) {
            setErrors({});

            editClientReportRevenueTemplate(
                template,
                id,
            );
        } else {
            Object.keys(template)?.filter((item) => template[item] === undefined).forEach((element) => {
                setErrors((prev) => ({
                    ...prev,
                    [element]: { message: t("messages.inputMsgs.required") },
                }));
            });
        }
    };

    const changeFieldHandler = (value, key) => {
        setTemplate((prev) => ({
            ...prev,
            [key]: value,
        }));

        setDisabled(false);
    };

    useEffect(
        () => {
            if (Object.keys(data)?.length > 0 && selections?.[revenueReportsSystemFields]) {
                Object.keys(data).forEach((key) => {
                    setTemplate((prev) => ({
                        ...prev,
                        [key]: selections?.[revenueReportsSystemFields]?.find((item) => item?.label.toLowerCase() === data?.[key].toLowerCase())?.value || t("labels.don'tMapThisField"),
                    }));
                });
            }
        },
        [revenueReportTemplate, revenueReportsSystemFields, selections], //eslint-disable-line
    );

    if (revenueReportsSystemFieldsLoading) return <Loader />;

    return (
        <Grid
            spacing={2}
            container
        >
            <Grid
                xs={12}
                item
            >
                <StyledTextField
                    label={t("labels.templateName")}
                    size="small"
                    value={name}
                    variant="outlined"
                    width="70%"
                    disabled
                />
            </Grid>
            <Grid
                xs={6}
                item
            >
                <Typography
                    color="primary"
                    component="span"
                    variant="h6"
                >
                    {t("labels.templateFields")}
                </Typography>
            </Grid>
            <Grid
                xs={6}
                item
            >
                <Typography
                    color="primary"
                    component="span"
                    variant="h6"
                >
                    {t("labels.systemFields")}
                </Typography>
            </Grid>
            <Grid
                marginBottom={4}
                xs={12}
                item
            >
                <Divider />
            </Grid>
            {Object?.keys(data)?.map((key) => (
                <Grid
                    key={key}
                    marginBottom={4}
                    marginLeft={2}
                    container
                >
                    <Grid
                        xs={5}
                        item
                    >
                        <StyledTextField
                            size="small"
                            value={key}
                            variant="outlined"
                            disabled
                            fullWidth
                        />
                    </Grid>
                    <Grid
                        alignItems="center"
                        display="flex"
                        justifyContent="center"
                        xs={1}
                        item
                    >
                        <Box
                            alignItems="center"
                            display="flex"
                            justifyContent="center"
                        >
                            <ArrowForwardIcon />
                        </Box>
                    </Grid>
                    <Grid
                        xs={5}
                        item
                    >
                        <AutoCompleteMenu
                            defaultValue={getFinalOptionsHandler().find((option) => option?.label.toLowerCase() === data?.[key].toLowerCase())}
                            errors={errors}
                            getRenderedOption={(option) => option?.label}
                            loading={loading}
                            name={key}
                            optionLabel={(option) => option?.label}
                            options={getFinalOptionsHandler()}
                            size="small"
                            withoutLabel
                            onChange={(_, option) => {
                                changeFieldHandler(
                                    option?.label,
                                    key,
                                );
                            }}
                        />
                    </Grid>
                </Grid>
            ))}
            <Grid
                alignItems="center"
                display="flex"
                gap={2}
                justifyContent="end"
                xs={12}
                item
            >
                <Button
                    color="error"
                    id={`${cancelButtonName}_${buttonId}`}
                    variant="outlined"
                    onClick={() => dispatch(setRevenueReportTemplateData({}))}
                >
                    {t("actions.cancel")}
                </Button>
                <LoadingButton
                    disabled={disabled}
                    id={`${saveButtonName}_${buttonId}`}
                    loading={loading}
                    variant="contained"
                    onClick={editTemplateHandler}
                >
                    {t("actions.save")}
                </LoadingButton>
            </Grid>
        </Grid>
    );
};

export default RevenueReportTemplate;
