import { SET_NETWORK_MODE } from "redux/types";
import { updateStateHandler } from "utils";

const initialState = { mode: null };

const setNetworkMode = (state, mode) => updateStateHandler(
    state,
    { mode },
);

const networkReducer = (state = initialState, action) => {
    const {
        payload,
        type,
    } = action;

    const { mode } = payload || {};

    switch (type) {
    case SET_NETWORK_MODE: return setNetworkMode(
        state,
        mode,
    );
    default: return state;
    }
};

export default networkReducer;
