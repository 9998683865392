import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useFiltersSchema = () => {
    const { t } = useTranslation();

    return yup.object().shape({
        dateRangeFrom: yup.
            date().
            nullable().
            transform((curr, date) => (date === "" ? null : curr)).
            max(
                yup.ref("dateRangeTo"),
                t("messages.inputMsgs.dateEarlier"),
            ),
        dateRangeTo: yup.
            date().
            nullable().
            transform((curr, date) => (date === "" ? null : curr)).
            min(
                yup.ref("dateRangeFrom"),
                t("messages.inputMsgs.dateLater"),
            ),
        endDate: yup.
            date().
            nullable().
            transform((curr, date) => (date === "" ? null : curr)).
            min(
                yup.ref("startDate"),
                t("messages.inputMsgs.dateLater"),
            ),
        startDate: yup.
            date().
            nullable().
            transform((curr, date) => (date === "" ? null : curr)).
            max(
                yup.ref("endDate"),
                t("messages.inputMsgs.dateEarlier"),
            ),
    });
};

export default useFiltersSchema;
