const driveManagementData = {
    labels: {
        conditions: {
            Date: [
                {
                    label: "Is",
                    value: "=",
                },
                {
                    label: "Is Not",
                    value: "!=",
                },
                {
                    label: "Less Than",
                    value: "<",
                },
                {
                    label: "Greater Than",
                    value: ">",
                },
                {
                    label: "Less Than or Equal",
                    value: "<=",
                },
                {
                    label: "Greater Than or Equal",
                    value: ">=",
                },
            ],
            Integer: [
                {
                    label: "Is",
                    value: "=",
                },
                {
                    label: "Is Not",
                    value: "!=",
                },
                {
                    label: "Less Than",
                    value: "<",
                },
                {
                    label: "Greater Than",
                    value: ">",
                },
                {
                    label: "Less Than or Equal",
                    value: "<=",
                },
                {
                    label: "Greater Than or Equal",
                    value: ">=",
                },
            ],
            Selection: [
                {
                    label: "Is",
                    value: "=",
                },
                {
                    label: "Is Not",
                    value: "!=",
                },
            ],
            Text: [
                {
                    label: "Is",
                    value: "=",
                },
                {
                    label: "Is Not",
                    value: "!=",
                },
                {
                    label: "Contains",
                    value: "contains",
                },
                {
                    label: "Starts With",
                    value: "starts with",
                },
            ],
        },
        types: {
            date: "Date",
            integer: "Integer",
            selection: "Selection",
            text: "Text",
        },
    },
    userPermissions: [
        {
            label: {
                file: "Owner",
                folder: "Owner",
            },
            value: "owner",
        },
        {
            label: {
                file: "Editor",
                folder: "Manager",
            },
            value: "organizer",
        },
        {
            label: {
                file: "Editor",
                folder: "Content Manager",
            },
            value: "fileOrganizer",
        },
        {
            label: {
                file: "Editor",
                folder: "Contributor",
            },
            value: "writer",
        },
        {
            label: {
                file: "Commenter",
                folder: "Commenter",
            },
            value: "commenter",
        },
        {
            label: {
                file: "Viewer",
                folder: "Viewer",
            },
            value: "reader",
        },
    ],
};

export default driveManagementData;
