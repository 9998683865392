import styled from "@emotion/styled";
import { TextField } from "@mui/material";
import { identifiersData } from "data";

const {
    classNames: {
        searchButton: searchButtonClassName,
        searchSelectMenu: searchSelectMenuClassName,
    },
    props: { hasLargeSelectMenu: hasLargeSelectMenuProp },
} = identifiersData;

const StyledSelectableDebouncedSearchInput = styled(
    TextField,
    { shouldForwardProp: (prop) => prop !== hasLargeSelectMenuProp },
)(({
    hasLargeSelectMenu,
    theme,
}) => ({
    "& .MuiInputBase-root": {
        borderRadius: "50px !important",
        height: 40,
        padding: 0,
    },
    [`& .MuiInputBase-root:not(.${searchSelectMenuClassName})`]: {
        [`& .${searchButtonClassName}`]: {
            alignItems: "center",
            background: theme.palette?.primary.main,
            borderRadius: "0 45px 45px 0",
            color: theme.palette?.grey[100],
            display: "flex",
            height: 38,
        },
        borderRadius: "45px !important",
        minHeight: 40,
        minWidth: 200,
    },
    "& .MuiSelect-select": {
        fontSize: 12,
        paddingRight: "180px !important",
    },
    [`& .${searchSelectMenuClassName}`]: {
        "&.MuiOutlinedInput-root": { paddingRight: 8 },
        "@media(max-width: 500px)": {
            borderRadius: "10px 0 10px 0",
            width: 80,
        },
        borderRadius: "10px 0 0 10px",
        height: 40,
        width: 200,
    },
    [`& .${identifiersData.classNames.searchSelectMenuFormControl}`]: {
        "& .MuiInputBase-root": { width: "100px!important" },
        "& .MuiSelect-select": { paddingRight: "0!important" },
        "& fieldset": {
            border: 0,
            borderRadius: 1,
            borderRight: `1px solid ${theme.palette?.grey[400]}!important`,
        },
        "@media(min-width: 900px)": {
            ...hasLargeSelectMenu && {
                "& .MuiInputBase-root": { width: "200px!important" },
                "& .MuiSelect-select": { paddingRight: "180px" },
                minWidth: "210px",
            },
        },
        minWidth: 105,
    },
    "@media(min-width: 900px) ": { minWidth: 600 },
    transition: "ease-in-out .2s !important",
    width: " 100%",
}));

export default StyledSelectableDebouncedSearchInput;
