import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Chip,
    Grid,
    Switch,
    Typography,
} from "@mui/material";
import {
    AutoCompleteMenu,
    Calendar,
    CategoryFilterInput,
    FilterRangeInput,
    Loader,
} from "atoms";
import { DriveManagementLabelsFilters } from "components";
import {
    filtersFormData,
    filtersModulesData,
    identifiersData,
    inputData,
    namesData,
} from "data";
import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    clearFilters,
    resetCategoryFilterData,
    setFilters,
    setModulesFilters,
    setRefetchDriveManagementList,
    setRefetchRevenueReportsList,
} from "redux/actions";
import { useFiltersSchema } from "schemas";
import { StyledTextField } from "styles";

const FiltersForm = ({
    asyncMenus,
    data,
    loadings,
    module,
}) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const selections = useSelector((state) => state.selectionsReducer);

    const filters = useSelector((state) => state.filtersReducer.filters);

    const filtersModule = useSelector((state) => state.filtersReducer.filtersModule);

    const filtersModules = useSelector((state) => state.filtersReducer?.filtersModules);

    const categoryFilter = useSelector((state) => state.filtersReducer.categoryFilter);

    const [filteredLabels, setFilteredLabels] = useState(filters?.filteredLabels || []);

    const [disableResetAllButton, setDisableResetAllButton] = useState(false);

    const schema = useFiltersSchema();

    const formMethods = useForm({
        defaultValues: { ...filters },
        mode: "onSubmit",
        resolver: yupResolver(schema),
    });

    const {
        control,
        formState: {
            dirtyFields,
            errors,
        },
        getValues,
        handleSubmit,
        reset,
        setValue,
    } = formMethods;

    const loadingValue = Object.values(loadings).some((value) => value === true);

    const { labels: labelsData } = data || {};

    const {
        classNames: {
            clearIndicator: clearIndicatorClassName,
            filterRangeInput: filterRangeInputClassName,
            sliderRoot: sliderRootClassName,
            sliderThumb: sliderThumbClassName,
            sliderTrack: sliderTrackClassName,
            smallField: smallFieldClassName,
            switchBase: switchBaseClassName,
            withoutPosition: withoutPositionClassName,
        },
        ids: {
            button: buttonId,
            filters: filtersId,
            input: inputId,
        },
    } = identifiersData;

    const {
        categoryFilter: categoryFilterInputName,
        createdTime: createdTimeInputName,
        duration: durationInputName,
        endDate: endDateInputName,
        filteredLabels: filteredLabelsInputName,
        isrc: isrcInputName,
        modifiedTime: modifiedTimeInputName,
        nationality: nationalityInputName,
        startDate: startDateInputName,
        upc: upcInputName,
        "upc/isrc": upcIsrcInputName,
    } = inputData.names;

    const {
        autoComplete: autoCompleteInputType,
        category: categoryInputType,
        date: dateInputType,
        range: rangeInputType,
        switch: switchInputType,
        text: textInputType,
    } = inputData.types;

    const {
        apply: applyButtonName,
        resetAll: resetAllButtonName,
    } = namesData.buttons;

    const renderFiltersHandler = (
        field,
        label,
        name,
        type,
        selectionKey,
        categoriesData,
        isAsyncMenu,
    ) => {
        switch (type) {
        case dateInputType:
            return (
                <Calendar
                    errors={errors}
                    field={field}
                    label={label}
                    name={name}
                    value={field.value || ""}
                    hasSmallField
                    isFilterInput
                />
            );
        case textInputType:
            return (
                <StyledTextField
                    {...field}
                    className={`${smallFieldClassName} ${withoutPositionClassName}`}
                    error={errors[name]}
                    helperText={errors[name] && errors[name]?.message}
                    id={`${name}_${inputId}`}
                    label={`${t(`labels.${label}`)}`}
                    value={field?.value}
                    variant="outlined"
                    fullWidth
                />
            );
        case switchInputType:
            return (
                <Box
                    alignItems="center"
                    display="flex"
                >
                    <Switch
                        {...field}
                        checked={formMethods.watch(name)}
                        id={`${name}_${inputId}`}
                    />
                    <Typography
                        color="primary"
                        component="span"
                    >
                        {t(`labels.${label}`)}
                    </Typography>
                </Box>
            );
        case rangeInputType:
            return (
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    px={2}
                    width="100%"
                >
                    <Typography
                        color="primary"
                        component="span"
                        variant="h6"
                    >
                        {t(`labels.${label}`)}
                    </Typography>
                    <FilterRangeInput
                        defaultValue={[0, 1800]}
                        fieldName={name}
                        formMethods={formMethods}
                    />
                </Box>
            );
        case categoryInputType:
            return (
                <CategoryFilterInput
                    categoriesData={categoriesData}
                    label={label}
                    name={name}
                    setValue={setValue}
                />
            );
        case autoCompleteInputType:
            return (
                <AutoCompleteMenu
                    asyncMenuData={asyncMenus?.[selectionKey]}
                    defaultValue={field?.value || []}
                    errors={errors}
                    getRenderedOption={(option) => option?.label}
                    isAsyncMenu={isAsyncMenu}
                    isCountriesMenu={name === nationalityInputName}
                    label={label}
                    name={name}
                    optionLabel={(option) => option?.label}
                    withSelect={type === ""}
                    options={name === nationalityInputName ? selections?.[selectionKey].map((country) => ({
                        ...country,
                        label: country?.code,
                    })) : selections?.[selectionKey]}
                    hasSmallField
                    isFilterInput
                    isMultiple
                    onChange={(_, option) => field.onChange(option)}
                />
            );
        }
    };

    const submitFormHandler = (values) => { // eslint-disable-line
        const {
            artistName,
            createdTime,
            dateRangeFrom,
            dateRangeTo,
            duration,
            endDate,
            genre,
            hasVideos,
            isrc,
            label: labelName,
            mimeType,
            modifiedTime,
            nationality,
            platformName,
            releaseName,
            releaseType,
            rightType,
            startDate,
            upc,
            videoType,
        } = values;

        const videosFilter = {
            ...videoType?.length > 0 && {
                typeId: { in: videoType.map(({ value }) => value) },
            },
            ...hasVideos && { hasVideos: true },
        };

        let selectedCategory = "";

        let tagsArray = [];

        if (module === filtersModulesData.revenueReports) {
            selectedCategory = categoryFilter?.[upcIsrcInputName].selectedCategory;

            tagsArray = categoryFilter?.[upcIsrcInputName].tagsArray;
        }

        const revenueReportsFilter = {
            ...(dateRangeFrom && dateRangeTo) && {
                date: {
                    gte: new Date(dateRangeFrom).toISOString(),
                    lte: new Date(dateRangeTo).toISOString(),
                },
            },
            ...platformName?.length > 0 && {
                platformName: { in: platformName.map(({ label }) => label) },
            },
            ...labelName?.length > 0 && {
                labelName: { in: labelName.map(({ label }) => label) },
            },
            ...artistName?.length > 0 && {
                artist_name_en: { in: artistName?.map(({ label }) => label) }, //eslint-disable-line
            },
            ...releaseName?.length > 0 && {
                album_name_en: { in: releaseName.map(({ label }) => label) }, //eslint-disable-line
            },
            ...nationality?.length > 0 && {
                OR: [
                    {
                        countryId: { in: nationality.map(({ value }) => value) },
                    },
                    {
                        countryName: { in: nationality.map(({ label }) => label) },
                    },
                    {
                        countryName: { in: nationality.map(({ code }) => code) },
                    },
                ],
            },
            ...selectedCategory === upcInputName && tagsArray.length > 0 && getValues(categoryFilterInputName)?.[upcIsrcInputName]?.tagsArray.length !== 0 && {
                upc: { in: tagsArray },
            },
            ...selectedCategory === isrcInputName && tagsArray.length > 0 && getValues(categoryFilterInputName)?.[upcIsrcInputName]?.tagsArray.length !== 0 && {
                isrc: { in: tagsArray },
            },
        };

        const artistsFilter = {
            ...nationality?.length > 0 && {
                country_id: { in: nationality.map(({ value }) => value) }, // eslint-disable-line
            },
        };

        const releasesFilter = {
            ...releaseType?.length > 0 && {
                releaseTypeId: { in: releaseType.map(({ value }) => value) },
            },
            ...upc && {
                upc: { equals: upc },
            },
            ...rightType?.length > 0 && {
                rightTypeId: { in: rightType.map(({ value }) => value) },
            },
            ...genre?.length > 0 && {
                genreId: { in: genre.map(({ value }) => value) },
            },
            ...artistName?.length > 0 && {
                artist: {
                    id: { in: artistName?.map(({ value }) => value) },
                },
            },
            ...releaseName && {
                id: { in: releaseName.map(({ value }) => value) },
            },
            ...(startDate && endDate) && {
                    release_date: { // eslint-disable-line
                    gte: new Date(startDate).toISOString(),
                    lte: new Date(endDate).toISOString(),
                },
            },
        };

        const tracksFilter = {
            ...isrc && {
                isrc: { equals: isrc },
            },
            ...(duration && (duration.gte !== 0 || duration.lte !== 1800)) && { duration },
        };

        const driveManagementFilter = {
            ...mimeType?.length > 0 && { mimeType: mimeType.map(({ types }) => types.map((type) => `'${type}'`)) },
            ...createdTime && { createdTime: new Date(createdTime) },
            ...modifiedTime && { modifiedTime: new Date(modifiedTime) },
            ...filteredLabels.length > 0 && Object.keys(values)?.length > 0 && { filteredLabels },
        };

        dispatch(setModulesFilters({
            artists: artistsFilter,
            driveManagement: driveManagementFilter,
            releases: releasesFilter,
            revenueReports: revenueReportsFilter,
            tracks: tracksFilter,
            videos: videosFilter,
        }));

        dispatch(setFilters(values));

        if (module === filtersModulesData.driveManagement) dispatch(setRefetchDriveManagementList(true));

        if (module === filtersModulesData.revenueReports) dispatch(setRefetchRevenueReportsList(true));
    };

    const resetFiltersHandler = () => {
        dispatch(clearFilters());

        document.querySelectorAll(`.${clearIndicatorClassName}`).forEach(button => button.click()); // eslint-disable-line

        document.querySelectorAll(`.${switchBaseClassName} input`).forEach((switchButton) => {  // eslint-disable-line
            if (switchButton.checked) switchButton.click();
        });

        const filterRangeInputTrack = document.querySelectorAll(`.${sliderRootClassName}.${filterRangeInputClassName} .${sliderTrackClassName}`)[0]; // eslint-disable-line

        const filterRangeInputThumbs = document.querySelectorAll(`.${sliderRootClassName}.${filterRangeInputClassName} .${sliderThumbClassName}`); // eslint-disable-line

        if (filterRangeInputTrack) {
            filterRangeInputTrack.style.width = "100%";

            filterRangeInputTrack.style.left = "0%";

            filterRangeInputThumbs[0].style.left = "0%";

            filterRangeInputThumbs[1].style.left = "100%";
        }

        reset();

        setValue(
            isrcInputName,
            "",
        );

        setValue(
            upcInputName,
            "",
        );

        setValue(
            startDateInputName,
            null,
        );

        setValue(
            endDateInputName,
            null,
        );

        setValue(
            createdTimeInputName,
            null,
        );

        setValue(
            modifiedTimeInputName,
            null,
        );

        setValue(
            filteredLabelsInputName,
            [],
        );

        setValue(
            durationInputName,
            {
                gte: undefined,
                lte: undefined,
            },
        );

        setValue(
            categoryFilterInputName,
            {
                [upcIsrcInputName]: {
                    category: upcInputName,
                    tagsArray: [],
                },
            },
        );

        dispatch(resetCategoryFilterData());

        setFilteredLabels([]);

        submitFormHandler({});
    };

    const deleteFilteredLabelHandler = (id) => {
        const filteredLabelsNewArray = filteredLabels.filter((_, index) => index !== id);

        setFilteredLabels(filteredLabelsNewArray);

        setValue(
            filteredLabelsInputName,
            filteredLabelsNewArray,
            { shouldDirty: true },
        );
    };

    useEffect(
        () => {
            if (
                Object.values(getValues()).some((value) => !value !== null
                && value !== false
                && value !== undefined
                && !(Array.isArray(value) && value.length === 0)
                && !(typeof value === "object" && (Object.keys(value).length === 0 || (value?.lte === 1800 && value?.gte === 0))))
            ) setDisableResetAllButton(false);
            else setDisableResetAllButton(true);

            if (Object.values(dirtyFields).length > 0) setDisableResetAllButton(false);
        },
        [filtersModules, dirtyFields], // eslint-disable-line
    );

    if (loadingValue) return <Loader withoutFullHeight />;

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(() => submitFormHandler(getValues()))}>
                <Box
                    alignItems="center"
                    display="flex"
                    flexWrap="wrap"
                    gap={2}
                >
                    {filtersFormData[filtersModule]?.map(({
                        categoriesData,
                        isAsyncMenu,
                        label,
                        name,
                        selectionKey,
                        type,
                    }) => (
                        <Box key={name}>
                            <Controller
                                control={control}
                                key={name}
                                name={name}
                                render={({ field }) => renderFiltersHandler(
                                    field,
                                    label,
                                    name,
                                    type,
                                    selectionKey,
                                    categoriesData,
                                    isAsyncMenu,
                                )}
                            />
                        </Box>
                    ))}
                    {module === filtersModulesData.driveManagement && (
                        <DriveManagementLabelsFilters
                            filteredLabels={filteredLabels}
                            labels={labelsData}
                            setFilteredLabels={setFilteredLabels}
                            setValue={setValue}
                        />
                    )}
                </Box>
                {filteredLabels.length > 0 && (
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        gap={2}
                        mt={2}
                    >
                        {filteredLabels.map(({
                            condition,
                            name,
                            value,
                        }, index) => (
                            <Chip
                                key={name}
                                label={`${name}:${condition?.name}:${value?.name ? value?.name : value}`}
                                variant="outlined"
                                onDelete={() => deleteFilteredLabelHandler(index)}
                            />
                        ))}
                    </Box>
                )}
                <Grid
                    gap={2}
                    mb={2}
                    mt={2}
                    container
                >
                    <Grid item>
                        <LoadingButton
                            color="primary"
                            disabled={Object.keys(dirtyFields).length === 0}
                            id={`${filtersId}_${applyButtonName}_${buttonId}`}
                            size="small"
                            type="submit"
                            variant="outlined"
                        >
                            {t("actions.apply")}
                        </LoadingButton>
                    </Grid>
                    <Grid item>
                        <LoadingButton
                            color="primary"
                            disabled={(Object.keys(filters)?.length === 0 && Object.keys(dirtyFields).length === 0) || disableResetAllButton}
                            id={`${filtersId}_${resetAllButtonName}_${buttonId}`}
                            size="small"
                            variant="text"
                            onClick={resetFiltersHandler}
                        >
                            {t("actions.resetAll")}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};

export default FiltersForm;

FiltersForm.propTypes = {
    asyncMenus: PropTypes.object,
    data: PropTypes.object,
    loadings: PropTypes.object,
    module: PropTypes.string,
};
