import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { constantsData } from "data";
import { getReleaseClientLinksListQuery } from "graph";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useReleaseClientLinksList = (releaseId) => {
    const [params] = useSearchParams();

    const pageParam = parseInt(params.get("page")) || 1;

    const limitParam = parseInt(params.get("limit")) || 25;

    const [page, setPage] = useState(pageParam);

    const [limit, setLimit] = useState(limitParam);

    const dispatch = useDispatch();

    const { isAuthenticated } = useAuth0();

    const { t } = useTranslation();

    const {
        data,
        error,
        loading,
    } = useQuery(
        getReleaseClientLinksListQuery,
        {
            skip: !isAuthenticated,
            variables: {
                limit,
                orderBy: { release_date: "desc" }, //eslint-disable-line
                page: page - 1,
                releaseId,
            },
        },
    );

    useEffect(
        () => {
            if (error?.message === constantsData.failedToFetch && !loading) {
                displayErrorHandler(
                    t("messages.networkFailure"),
                    dispatch,
                    openAlert,
                );
            }
        },
        [error, data], // eslint-disable-line
    );

    return {
        count: data?.listReleaseLinks?.meta?.nodeCount,
        data: data?.listReleaseLinks?.posts,
        limit,
        loading,
        page: data?.listReleaseLinks?.meta?.currentPage,
        setLimit,
        setPage,
    };
};

export default useReleaseClientLinksList;
