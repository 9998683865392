import { Box, LinearProgress, Typography } from "@mui/material";
import { PropTypes } from "prop-types";
import { theme } from "theme";

const Progressbar = ({
    progress,
    text,
}) => (
    <Box width="100%">
        <Box>
            {text && (
                <Typography
                    color={theme.palette.grey[600]}
                    textAlign="center"
                    variant="body1"
                >
                    {text}
                </Typography>
            )}
        </Box>
        <Box
            alignItems="center"
            display="flex"
        >
            <Box
                mr={1}
                width="100%"
            >
                <LinearProgress
                    sx={{ backgroundColor: theme.palette.grey[400] }}
                    value={progress}
                    variant="determinate"
                />
            </Box>
            <Box minWidth={35}>
                <Typography
                    color={theme.palette.grey[600]}
                    variant="caption"
                >
                    {`${Math.round(progress)}%`}
                </Typography>
            </Box>
        </Box>
    </Box>
);

export default Progressbar;

Progressbar.propTypes = {
    progress: PropTypes.number,
    text: PropTypes.string,
};
