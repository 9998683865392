import styled from "@emotion/styled";

const StyledPoint = styled("strong")(() => ({
    backgroundColor: "grey",
    borderRadius: "50%",
    color: "white",
    marginInline: "5px",
    padding: "2px 6px",
}));

export default StyledPoint;
