import { gql } from "@apollo/client";

export const createDistributionMutation = gql`
    mutation createDistribution(
        $releaseId: Int!,
        $clientId: Int!,
        $territoryId: Int!,
        $distributionFileTypeId: Int!,
        $releaseDate: DateTime!
    ) {
        distribute(
            releaseId: $releaseId,
            clientId: $clientId,
            territoryId: $territoryId,
            distributeTypeId: $distributionFileTypeId,
            releaseDate: $releaseDate
        )
    }
`;

export const createBulkDistributionsMutation = gql`
    mutation createBulkDistributions(
        $releasesIds: [Int]!,
        $clientId: Int!,
        $territoryId: Int!,
        $distributionFileTypeId: Int!,
        $releaseDate: DateTime!
    ) {
        bulkDistribute(
            releaseIds: $releasesIds,
            clientId: $clientId,
            territoryId: $territoryId,
            distributeTypeId: $distributionFileTypeId,
            releaseDate: $releaseDate
        )
    }
`;

export const deleteDistributionMutation = gql`
    mutation deleteDistribution($id: Int!) {
        deleteDistributionJob(jobId: $id)
    }
`;

export const retryFailedDistributionMutation = gql`
    mutation retryFailedDistribution($id: Int!) {
        retryDistributionJob(jobId: $id)
    }
`;
