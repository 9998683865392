import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button,
    CardContent,
    Grid,
} from "@mui/material";
import { actionsData, identifiersData, namesData } from "data";
import PropTypes from "prop-types";
import { useState } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { StyledFormContainer } from "styles";
import { clearFormStateAfterSubmissionHandler } from "utils";

const FormContainer = ({
    action,
    clearAction,
    data,
    disabled,
    filesData,
    formMethods,
    hasCancelButton,
    hasClearAfterSubmission,
    hasSecondaryActionButton,
    hasSubmitButton = true,
    loading,
    onSubmitForm,
    otherData,
    secondaryActionButtonData,
    setFilesData,
    setModalData,
    withoutShadow,
}) => {
    const [formReset, setFormReset] = useState(false);

    const { t } = useTranslation();

    const {
        clearErrors,
        formState: { dirtyFields },
        getValues,
        handleSubmit,
        reset,
        setValue,
    } = formMethods;

    const {
        cancel: cancelButtonName,
        submit: submitButtonName,
    } = namesData.buttons;

    const { button: buttonId } = identifiersData.ids;

    const submitFormHandler = () => {
        onSubmitForm(getValues())?.then((response) => {
            if (hasClearAfterSubmission) {
                clearFormStateAfterSubmissionHandler(
                    response,
                    filesData,
                    setFilesData,
                    setFormReset,
                    getValues,
                    setValue,
                    clearErrors,
                );

                if (clearAction) clearAction();
            }

            if (!hasClearAfterSubmission) {
                reset(getValues());

                setFormReset(true);
            }
        });
    };

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(() => submitFormHandler())}>
                <Grid container>
                    <Grid
                        xs={12}
                        item
                    >
                        <StyledFormContainer withoutShadow={+!!withoutShadow}>
                            <CardContent>
                                <Grid
                                    spacing={2}
                                    container
                                >
                                    {data}
                                </Grid>
                                {otherData}
                            </CardContent>
                            <Grid
                                marginY={2}
                                item
                            >
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    gap={2}
                                >
                                    {hasSubmitButton && (
                                        <LoadingButton
                                            color="primary"
                                            disabled={(Object.keys(dirtyFields).length === 0 && action === actionsData.edit) || disabled || (formReset && Object.keys(dirtyFields).length === 0)}
                                            id={`${submitButtonName}_${buttonId}`}
                                            loading={loading}
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                        >
                                            {action === actionsData.edit ? t("actions.save") : t(`actions.${action}`)}
                                        </LoadingButton>
                                    )}
                                    {hasSecondaryActionButton && (
                                        <LoadingButton
                                            color={secondaryActionButtonData?.color}
                                            id={`${secondaryActionButtonData.name}_${buttonId}`}
                                            loading={secondaryActionButtonData?.loading}
                                            size="large"
                                            type="button"
                                            variant={secondaryActionButtonData?.variant}
                                            onClick={secondaryActionButtonData?.action}
                                        >
                                            {secondaryActionButtonData?.text}
                                        </LoadingButton>
                                    )}
                                    {hasCancelButton && (
                                        <Button
                                            color="error"
                                            id={`${cancelButtonName}_${buttonId}`}
                                            size="large"
                                            type="button"
                                            variant="outlined"
                                            onClick={() => setModalData((prev) => ({
                                                ...prev,
                                                open: false,
                                            }))}
                                        >
                                            {t("actions.cancel")}
                                        </Button>
                                    )}
                                </Box>
                            </Grid>
                        </StyledFormContainer>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};

export default FormContainer;

FormContainer.propTypes = {
    action: PropTypes.string,
    clearAction: PropTypes.func,
    data: PropTypes.node,
    disabled: PropTypes.bool,
    filesData: PropTypes.array,
    formMethods: PropTypes.object,
    hasCancelButton: PropTypes.bool,
    hasClearAfterSubmission: PropTypes.bool,
    hasSecondaryActionButton: PropTypes.bool,
    hasSubmitButton: PropTypes.bool,
    loading: PropTypes.bool,
    onSubmitForm: PropTypes.func,
    otherData: PropTypes.node,
    secondaryActionButtonData: PropTypes.object,
    setFilesData: PropTypes.func,
    setModalData: PropTypes.func,
    withoutShadow: PropTypes.bool,
};
