import { regexData } from "data";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useDriveLinkSchema = () => {
    const { t } = useTranslation();

    const { url: urlRegex } = regexData;

    return yup.object({
        driveLink: yup.string().required(t("messages.inputMsgs.required")).matches(
            urlRegex,
            t("messages.inputMsgs.invalidLink"),
        ),
        resourceName: yup.string().required(t("messages.inputMsgs.required")),
    });
};

export default useDriveLinkSchema;
