import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { theme } from "theme";

const StyledLink = styled(Link)(({
    bold,
    gray,
}) => ({
    "&:hover": { textDecoration: "underline" },
    color: gray ? theme.palette.grey[500] : theme.palette.primary.main,
    cursor: "pointer",
    fontWeight: bold ? "bold" : "normal",
    textDecoration: "none",
    transition: "ease-in-out .2s",
    width: "fit-content",
}));

export default StyledLink;
