import { gql } from "@apollo/client/core";

export const createArtistMutation = gql`
    mutation createArtist($data: ArtistCreateInput!) {
        createOneArtist(data: $data) {
            id
        }
    }
`;

export const editArtistMutation = gql`
    mutation editArtist(
        $data: ArtistUpdateInput!,
        $where: ArtistWhereUniqueInput!
    ) {
        updateOneArtist(
            data: $data,
            where: $where
        ) {
            id
        }
    }
`;

export const deleteArtistMutation = gql`
    mutation deleteArtist($where: ArtistWhereUniqueInput!) {
        deleteOneArtist(where: $where) {
            id
        }
    }
`;

export const exportArtistsMutation = gql`
    mutation exportArtists(
        $artistsIds: [Int]!,
        $toCsv: Boolean
    ) {
        exportArtists(
            artistsIds: $artistsIds,
            toCsv: $toCsv
        )
    }
`;
