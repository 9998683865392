import { Box } from "@mui/material";
import { DialogVideoPlayer, ThumbnailVideoPlayer } from "atoms";
import { PageHead } from "components";
import { PropTypes } from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getSubstringHandler } from "utils";

const TrackDetailsVideoCuts = ({ data }) => {
    const [currentVideo, setCurrentVideo] = useState({
        artistName: "",
        trackName: "",
        url: null,
    });

    const { t } = useTranslation();

    return (
        <Box>
            <DialogVideoPlayer
                currentVideo={currentVideo}
                setCurrentVideo={setCurrentVideo}
            />
            <PageHead
                title={t("labels.videoCuts")}
                titleTag="h2"
            />
            {data?.length > 0 && (
                <Box
                    display="flex"
                    flexWrap="wrap"
                    gap={2}
                >
                    {data?.map(({
                        downloadUri,
                        id,
                        video,
                    }, index) => (
                        <Box key={id}>
                            <ThumbnailVideoPlayer
                                id={id}
                                imgSrc={video?.image?.downloadThumbnail || video?.track?.image?.downloadThumbnail || video?.release?.image?.downloadThumbnail}
                                rowId={index + 1}
                                setCurrentVideo={setCurrentVideo}
                                url={downloadUri}
                                artist={{
                                    id: video.track?.artist?.id,
                                    name: video.track?.artist?.name,
                                }}
                                videoCut={{
                                    id: video.track?.id,
                                    name: getSubstringHandler(
                                        downloadUri,
                                        6,
                                        false,
                                        -21,
                                    ),
                                    videoName: video.name,
                                }}
                                isTrackPage
                            />
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default TrackDetailsVideoCuts;

TrackDetailsVideoCuts.propTypes = { data: PropTypes.array };
