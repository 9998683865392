import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { Loader } from "atoms";
import { identifiersData, namesData } from "data";
import { PropTypes } from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledDialogVideoPlayer } from "styles";

const DialogVideoPlayer = ({
    currentVideo,
    setCurrentVideo,
}) => {
    const [loading, setLoading] = useState(true);

    const { t } = useTranslation();

    const {
        classNames: { videoTopBox: videoTopBoxClassName },
        ids: { button: buttonId },
    } = identifiersData;

    const closeDialogVideoHandler = () => {
        setCurrentVideo({
            artistName: "",
            trackName: "",
            url: null,
        });

        setLoading(true);
    };

    return (
        <StyledDialogVideoPlayer
            loading={+!!loading}
            open={!!currentVideo?.url}
            onClose={closeDialogVideoHandler}
        >
            <Box className={videoTopBoxClassName}>
                <Typography
                    textAlign="center"
                    variant="h6"
                >
                    {currentVideo?.trackName}
                </Typography>
                <Typography
                    textAlign="center"
                    variant="caption"
                >
                    {currentVideo?.artistName}
                </Typography>
                <Box>
                    <Tooltip title={t("actions.close")}>
                        <IconButton
                            id={`${namesData.buttons.closeVideoPlayer}_${buttonId}`}
                            onClick={closeDialogVideoHandler}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <video
                crossOrigin="anonymous"
                height="100%"
                width="100%"
                autoPlay
                controls
                onCanPlay={() => setLoading(false)}
            >
                <source
                    src={`${window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_DOMAIN}${currentVideo?.url}`} // eslint-disable-line
                    type="video/mp4"
                />
            </video>
            {loading && <Loader />}
        </StyledDialogVideoPlayer>
    );
};

export default DialogVideoPlayer;

DialogVideoPlayer.propTypes = {
    currentVideo: PropTypes.object,
    setCurrentVideo: PropTypes.func,
};
