import fileData from "data/file";
import inputData from "data/input";
import selectionsData from "data/selections";

const { tracks: tracksSelectionKey } = selectionsData.keys;

const { lyrics: lyricsFileName } = fileData.names;

const {
    autoComplete: autoCompleteInputType,
    file: fileInputType,
} = inputData.types;

const { track: trackInputName } = inputData.names;

const {
    lyrics: lyricsFileInputLabel,
    track: trackInputLabel,
} = inputData.labels;

const releaseLyricsFileFormData = [
    {
        col: {
            md: 4,
            xs: 12,
        },
        hasOffset: true,
        label: trackInputLabel,
        name: trackInputName,
        selectionKey: tracksSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        isOptional: true,
        label: lyricsFileInputLabel,
        name: lyricsFileName,
        type: fileInputType,
    },
];

export default releaseLyricsFileFormData;
