import { yupResolver } from "@hookform/resolvers/yup";
import { AutoCompleteMenu } from "atoms";
import { FormContainer } from "components";
import {
    fileData,
    foldersStructureArtworkFileFormData,
    identifiersData,
    inputData,
} from "data";
import { PropTypes } from "prop-types";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFoldersStructureArtworkFileSchema } from "schemas";
import { StyledTextField } from "styles";
import { renderFormControllerHandler } from "utils";

import FoldersStructureTrackFileForm from "./track";
import FoldersStructureXmlFileForm from "./xml";

const FoldersStructureArtworkFileForm = ({
    action,
    modalData,
    onSubmitForm,
    setModalData,
    values,
}) => {
    const [fileType, setFileType] = useState(fileData.types.artwork);

    const { t } = useTranslation();

    const selections = useSelector((state) => state.selectionsReducer);

    const schema = useFoldersStructureArtworkFileSchema();

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const {
        control,
        formState: { errors },
        getValues,
    } = formMethods;

    const {
        fileExtension: fileExtensionInputName,
        fileName: fileNameInputName,
        fileType: fileTypeInputName,
    } = inputData.names;

    const changeAutoCompleteMenuValueHandler = (option, name, field) => {
        if (name === fileTypeInputName) {
            setFileType(option?.label);

            field.onChange(option?.value);
        } else field.onChange(option?.value);
    };

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        fieldType,
        __,
        selectionKey,
    ) => {
        if (fieldType === inputData.types.text) {
            return (
                <StyledTextField
                    {...field}
                    error={errors[name]}
                    helperText={errors[name] && errors[name]?.message}
                    id={`${name}_${identifiersData.ids.input}`}
                    label={t(`labels.${label}`)}
                    variant="outlined"
                    fullWidth
                />
            );
        }

        return (
            <AutoCompleteMenu
                defaultValue={selections?.[selectionKey]?.find((option) => option?.value === values?.[name])}
                errors={errors}
                getRenderedOption={(option) => option?.label}
                label={label}
                name={name}
                optionLabel={(option) => option?.label}
                options={(name === fileExtensionInputName || name === fileNameInputName) && !getValues(fileTypeInputName) ? [] : selections?.[selectionKey]}
                onChange={(_, option) => changeAutoCompleteMenuValueHandler(
                    option,
                    name,
                    field,
                )}

            />
        );
    };

    if (fileType === fileData.types.track || !fileType) {
        return (
            <FoldersStructureTrackFileForm
                action={action}
                modalData={modalData}
                setModalData={setModalData}
                values={{
                    fileType: !fileType ? "" : fileData.types.track,
                    id: values.id,
                }}
                onSubmitForm={onSubmitForm}
            />
        );
    }

    if (fileType === fileData.types.xml) {
        return (
            <FoldersStructureXmlFileForm
                action={action}
                modalData={modalData}
                setModalData={setModalData}
                values={{
                    fileType: fileData.types.xml,
                    id: values.id,
                }}
                onSubmitForm={onSubmitForm}
            />
        );
    }

    return (
        <FormContainer
            action={action}
            formMethods={formMethods}
            setModalData={setModalData}
            data={renderFormControllerHandler(
                control,
                foldersStructureArtworkFileFormData,
                renderFormControllerChildrenHandler,
            )}
            hasCancelButton
            withoutShadow
            onSubmitForm={onSubmitForm}
        />
    );
};

export default FoldersStructureArtworkFileForm;

FoldersStructureArtworkFileForm.propTypes = {
    action: PropTypes.string,
    modalData: PropTypes.object,
    onSubmitForm: PropTypes.func,
    setModalData: PropTypes.func,
    values: PropTypes.object,
};
