const getAuthConfig = () => {
    const audience = window?.__RUNTIME_CONFIG__?.REACT_APP_AUTH0_AUDIENCE && window?.__RUNTIME_CONFIG__?.REACT_APP_AUTH0_AUDIENCE !== "YOUR_API_IDENTIFIER" ? window?.__RUNTIME_CONFIG__?.REACT_APP_AUTH0_AUDIENCE : null; // eslint-disable-line

    return {
        clientId: window?.__RUNTIME_CONFIG__?.REACT_APP_AUTH0_CLIENT_ID, // eslint-disable-line
        domain: window?.__RUNTIME_CONFIG__?.REACT_APP_AUTH0_DOMAIN, // eslint-disable-line
        ...audience ? { audience } : null,
        connectionScope: window?.__RUNTIME_CONFIG__?.REACT_APP_AUTH0_CONNECTION_SCOPE, // eslint-disable-line
    };
};

export default getAuthConfig;
