import inputData from "data/input";

const { textArea: textAreaInputType } = inputData.types;

const { note: noteInputName } = inputData.names;

const { note: noteInputLabel } = inputData.labels;

const releaseNoteFormData = [
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: noteInputLabel,
        name: noteInputName,
        type: textAreaInputType,
    },

];

export default releaseNoteFormData;
