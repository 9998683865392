const tabsData = {
    data: {
        clientForm: [
            {
                label: "info",
                value: "1",
            },
            {
                label: "xmlFile",
                value: "2",
            },
            {
                label: "revenueReports",
                value: "3",
            },
            {
                label: "foldersStructure",
                value: "4",
            },
        ],
        releaseDetails: [
            {
                label: "tracks",
                value: "1",
            },
            {
                label: "videos",
                value: "2",
            },
            {
                label: "ownership",
                value: "3",
            },
            {
                label: "documents",
                value: "4",
            },
            {
                label: "clientLinks",
                value: "5",
            },
            {
                label: "driveLinks",
                value: "6",
            },
            {
                label: "distributions",
                value: "7",
            },
        ],
        releaseForm: [
            {
                label: "info",
                value: "1",
            },
            {
                label: "tracks",
                value: "2",
            },
            {
                label: "lyricsFiles",
                value: "3",
            },
            {
                label: "ownership",
                value: "4",
            },
            {
                label: "videos",
                value: "5",
            },
            {
                label: "videoCuts",
                value: "6",
            },
            {
                label: "soundRecordings",
                value: "7",
            },
            {
                label: "royalties",
                value: "8",
            },
            {
                label: "documents",
                value: "9",
            },
            {
                label: "note",
                value: "10",
            },
            {
                label: "clientLinks",
                value: "11",
            },
        ],
        releaseTracks: [
            {
                label: "newTrack",
                value: "1",
            },
            {
                label: "existingTrack",
                value: "2",
            },

        ],
        releasesImporter: [
            {
                label: "releases",
                value: "1",
            },
            {
                label: "invalidReleases",
                value: "2",
            },
            {
                label: "completeReleases",
                value: "3",
            },
        ],
        searchLists: [
            {
                label: "releases",
                value: "1",
            },
            {
                label: "artists",
                value: "2",
            },
            {
                label: "tracks",
                value: "3",
            },
        ],
        trackDetails: [
            {
                label: "royalty",
                value: "1",
            },
            {
                label: "soundRecordings",
                value: "2",
            },
            {
                label: "videos",
                value: "3",
            },
            {
                label: "videoCuts",
                value: "4",
            },
            {
                label: "lyricsFiles",
                value: "5",
            },
        ],
    },
    entities: {
        clientForm: [
            {
                key: "info",
                label: "info",
            },
            {
                key: "xmlFile",
                label: "xmlFile",
            },
            {
                key: "foldersStructure",
                label: "foldersStructure",
            },
        ],
        releaseDetails: [
            {
                isObject: true,
                key: "ownership",
                label: "ownership",
            },
            {
                key: "videos",
                label: "videos",
            },
            {
                key: "clientLinks",
                label: "clientLinks",
            },
            {
                key: "documents",
                label: "documents",
            },
        ],
        searchLists: [
            {
                key: "artists",
                label: "artists",
            },
            {
                key: "releases",
                label: "releases",
            },
            {
                key: "tracks",
                label: "tracks",
            },
        ],
        trackDetails: [
            {
                isObject: true,
                key: "royalty",
                label: "royalty",
            },
            {
                key: "soundRecordings",
                label: "soundRecordings",
            },
            {
                key: "videos",
                label: "videos",
            },
            {
                key: "videoCuts",
                label: "videoCuts",
            },
            {
                key: "lyricsFiles",
                label: "lyricsFiles",
            },
        ],
    },
};

export default tabsData;
