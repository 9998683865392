import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { constantsData } from "data";
import { getRevenueReportsListQuery } from "graph";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { openAlert, setRefetchRevenueReportsList } from "redux/actions";
import { displayErrorHandler } from "utils";

const useRevenueReportsList = (filters = {}) => {
    const [params] = useSearchParams();

    const pageParam = parseInt(params.get("page")) || 1;

    const limitParam = parseInt(params.get("limit")) || 25;

    const [data, setData] = useState([]);

    const [page, setPage] = useState(pageParam);

    const [limit, setLimit] = useState(limitParam);

    const dispatch = useDispatch();

    const refetchRevenueReports = useSelector((state) => state.revenueReportsReducer?.refetch);

    const filtersObject = useSelector((state) => state.filtersReducer?.filters);

    const { isAuthenticated } = useAuth0();

    const { t } = useTranslation();

    const {
        data: revenueReportsData,
        error,
        loading,
        refetch,
    } = useQuery(
        getRevenueReportsListQuery,
        {
            onCompleted: ({ listRevenueReport }) => {
                if (refetchRevenueReports) {
                    setData(listRevenueReport?.posts);

                    setTimeout(
                        () => {
                            dispatch(setRefetchRevenueReportsList(false));
                        },
                        [500],
                    );
                } else {
                    setData((prev) => [
                        ...prev,
                        ...listRevenueReport?.posts, //eslint-disable-line
                    ]);
                }
            },
            skip: !isAuthenticated,
            variables: {
                limit,
                page: page - 1,
                where: { ...filters?.revenueReports && Object.keys(filters?.revenueReports).length > 0 && filters?.revenueReports },
            },
        },
    );

    useEffect(
        () => {
            if (refetchRevenueReports) {
                refetch();

                setData([]);

                setPage(1);
            }
        },
        [refetchRevenueReports], //eslint-disable-line
    );

    useEffect(
        () => {
            if (Object.keys(filtersObject)?.length > 0) dispatch(setRefetchRevenueReportsList(true));
        },
        [filters], //eslint-disable-line
    );

    useEffect(
        () => {
            dispatch(setRefetchRevenueReportsList(true));
        },
        [], // eslint-disable-line
    );

    useEffect(
        () => {
            if (error?.message === constantsData.failedToFetch && !loading) {
                displayErrorHandler(
                    t("messages.networkFailure"),
                    dispatch,
                    openAlert,
                );
            }
        },
        [error, data], // eslint-disable-line
    );

    return {
        count: revenueReportsData?.listRevenueReport?.meta?.nodeCount,
        data,
        error,
        limit,
        loading,
        page: revenueReportsData?.listRevenueReport?.meta?.currentPage,
        setLimit,
        setPage,
        totalRevenue: revenueReportsData?.listRevenueReport?.meta?.totalReveunue,
    };
};

export default useRevenueReportsList;
