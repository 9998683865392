import { useMutation } from "@apollo/client";
import { enableClientMutation } from "graph";

const useEnableClient = () => {
    const [enableClientAction] = useMutation(enableClientMutation);

    const enableClient = async (enabled, id) => {
        await enableClientAction({
            variables: {
                data: {
                    isEnabled: { set: enabled },
                },
                where: { id },
            },
        });
    };

    return { enableClient };
};

export default useEnableClient;
