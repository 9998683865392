import { useMutation } from "@apollo/client";
import {
    constantsData,
    identifiersData,
    namesData,
    statusTypesData,
} from "data";
import { downloadReleasesContentMutation } from "graph";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useDownloadReleasesContent = () => {
    const [exporting, setExporting] = useState(false);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [downloadReleasesContentAction, { loading }] = useMutation(downloadReleasesContentMutation);

    const { downloadReleasesContentPending: downloadReleasesContentPendingToastName } = namesData.toasts;

    const { toast: toastId } = identifiersData.ids;

    const downloadReleasesContent = async (ids) => {
        setExporting(true);

        try {
            await downloadReleasesContentAction({
                onCompleted: () => {
                    setExporting(false);

                    dispatch(openAlert(
                        t(
                            "messages.downloadMsgs.successAndLinkPending",
                            { entity: t("releases.name") },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: { releasesIds: ids },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        } finally {
            toast.dismiss(`${downloadReleasesContentPendingToastName}_${toastId}`);
        }
    };

    useEffect(
        () => {
            if (loading && exporting) {
                toast.info(
                    () => t(
                        "messages.downloadMsgs.pending",
                        { entity: t("releases.name") },
                    ),
                    { toastId: `${downloadReleasesContentPendingToastName}_${toastId}` },
                );
            }
        },
        [loading, exporting], //eslint-disable-line
    );

    return {
        downloadReleasesContent,
        loading,
    };
};

export default useDownloadReleasesContent;
