import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
} from "@mui/material";
import { Loader } from "atoms";
import {
    constantsData,
    identifiersData,
    namesData,
    permissionsData,
} from "data";
import { useAddPermission, usePermissionsList, useRemovePermission } from "hooks";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledPoint } from "styles";
import { getSplicedArrayHandler } from "utils";

const RolePermissions = ({
    roleId,
    roleName,
}) => {
    const [addedPermissions, setAddedPermissions] = useState([]);

    const [removedPermissions, setRemovedPermissions] = useState([]);

    const [changingPermissions, setChangingPermissions] = useState(false);

    const { t } = useTranslation();

    const {
        initialPermissions,
        loading: permissionsListLoading,
        permissions,
        refetch: refetchPermissionsList,
        setPermissions,
    } = usePermissionsList(roleId);

    const {
        addPermission,
        loading: addPermissionLoading,
    } = useAddPermission(
        setAddedPermissions,
        setRemovedPermissions,
        refetchPermissionsList,
    );

    const {
        loading: removePermissionLoading,
        removePermission,
    } = useRemovePermission(
        setAddedPermissions,
        setRemovedPermissions,
        refetchPermissionsList,
    );

    const {
        button: buttonId,
        input: inputId,
    } = identifiersData.ids;

    const {
        addAll: addAllButtonName,
        cancel: cancelButtonName,
        removeAll: removeAllButtonName,
        save: saveButtonName,
    } = namesData.buttons;

    const permissionsList = Object.keys(permissionsData.permissions.keys).map((permission) => permissionsData.permissions.keys[permission]);

    let permissionsTypesList = Object.keys(permissionsData.permissions.types);

    if (roleName !== constantsData.admins) permissionsTypesList = permissionsTypesList.filter((type) => type !== permissionsData.permissions.types.account);

    const clickPermissionHandler = (e) => {
        const { checked } = e.target;

        const { parentNode } = e.target;

        const defaultChecked = parentNode.getAttribute("data-checked");

        const value = e.target.defaultValue;

        if (checked && defaultChecked === "false") {
            setAddedPermissions([...addedPermissions, value]);

            getSplicedArrayHandler(
                removedPermissions,
                setRemovedPermissions,
                value,
            );
        }

        if (!checked && defaultChecked === "true") {
            setRemovedPermissions([...removedPermissions, value]);

            getSplicedArrayHandler(
                addedPermissions,
                setAddedPermissions,
                value,
            );
        }

        if (checked && defaultChecked === "true") {
            getSplicedArrayHandler(
                removedPermissions,
                setRemovedPermissions,
                value,
            );
        }

        if (!checked && defaultChecked === "false") {
            getSplicedArrayHandler(
                addedPermissions,
                setAddedPermissions,
                value,
            );
        }
    };

    const clickAddAllBtnHandler = () => {
        setAddedPermissions(permissionsList);

        setRemovedPermissions([]);

        setChangingPermissions(true);

        setPermissions({
            id: roleId,
            permissions: permissionsData.permissions.typesWithActions,
        });

        setTimeout(
            () => {
                setChangingPermissions(false);
            },
            [10],
        );
    };

    const clickDeleteAllBtnHandler = () => {
        setAddedPermissions([]);

        setRemovedPermissions(permissionsList);

        setChangingPermissions(true);

        setPermissions({
            id: roleId,
            permissions: {},
        });

        setTimeout(
            () => {
                setChangingPermissions(false);
            },
            [10],
        );
    };

    const clickCancelBtnHandler = () => {
        setAddedPermissions([]);

        setRemovedPermissions([]);

        setChangingPermissions(true);

        setPermissions(initialPermissions);

        setTimeout(
            () => {
                setChangingPermissions(false);
            },
            [10],
        );
    };

    const clickSaveBtnHandler = () => {
        if (addedPermissions.length > 0) {
            addPermission({
                permissions: addedPermissions,
                roleId,
            });
        }

        if (removedPermissions.length > 0) {
            removePermission({
                permissions: removedPermissions,
                roleId,
            });
        }
    };

    if (permissionsListLoading || addPermissionLoading || removePermissionLoading) return <Loader />;

    if (changingPermissions) return null;

    return (
        <Box>
            <Box
                display="flex "
                gap={3}
                justifyContent="flex-end"
                mb={2}
            >
                <Button
                    id={`${addAllButtonName}_${buttonId}`}
                    variant="outlined"
                    onClick={clickAddAllBtnHandler}
                >
                    {t("actions.addAll")}
                </Button>
                <Button
                    color="error"
                    id={`${removeAllButtonName}_${buttonId}`}
                    variant="outlined"
                    onClick={clickDeleteAllBtnHandler}
                >
                    {t("actions.removeAll")}
                </Button>
            </Box>
            {permissionsTypesList.map((type, index) => (
                <Box
                    key={type}
                    mb={3}
                >
                    <Box mb={3}>
                        <Typography variant="caption">
                            <StyledPoint>{index + 1}</StyledPoint>
                            {t(`permissions.types.${type}`)}
                        </Typography>
                    </Box>
                    <Box>
                        <FormGroup
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            {Object.keys(permissionsData.permissions.actions)?.map((action) => (type === permissionsData.permissions.types.drive && action !== permissionsData.permissions.actions.read ? null : (
                                <Fragment key={action}>
                                    <FormControlLabel
                                        label={t(`permissions.actions.${action}`)}
                                        control={(
                                            <Checkbox
                                                data-checked={initialPermissions?.permissions[type]?.includes(action) ? "true" : "false"}
                                                defaultChecked={permissions?.permissions[type]?.includes(action)}
                                                id={`${roleName}_${type}_${action}_${inputId}`}
                                                value={`${type}:${action}`}
                                                onChange={clickPermissionHandler}
                                            />
                                        )}
                                    />
                                </Fragment>
                            )))}
                        </FormGroup>
                    </Box>
                </Box>
            ))}
            <Box
                display="flex"
                gap={3}
            >
                <Button
                    disabled={addedPermissions.length === 0 && removedPermissions.length === 0}
                    id={`${saveButtonName}_${buttonId}`}
                    variant="contained"
                    onClick={clickSaveBtnHandler}
                >
                    {t("actions.save")}
                </Button>
                <Button
                    color="error"
                    disabled={addedPermissions.length === 0 && removedPermissions.length === 0}
                    id={`${cancelButtonName}_${buttonId}`}
                    variant="outlined"
                    onClick={clickCancelBtnHandler}
                >
                    {t("actions.cancel")}
                </Button>
            </Box>
        </Box>
    );
};

export default RolePermissions;

RolePermissions.propTypes = {
    roleId: PropTypes.string,
    roleName: PropTypes.string,
};
