import { useAuth0 } from "@auth0/auth0-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid } from "@mui/material";
import { AutoCompleteMenu, FileLink, UploadInput } from "atoms";
import { FormContainer } from "components";
import {
    fileData,
    identifiersData,
    inputData,
    permissionsData,
    releaseDocumentFormData,
} from "data";
import { useDeleteReleaseDocument, useUploadFile } from "hooks";
import { PropTypes } from "prop-types";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useReleaseDocumentSchema } from "schemas";
import {
    canDo,
    deleteFileHandler,
    renderFormControllerHandler,
    setUploadInputPreviousValueHandler,
} from "utils";

const ReleaseDocumentForm = ({
    action,
    loading,
    onSubmitForm,
}) => {
    const { t } = useTranslation();

    const tracksList = useSelector((state) => state.releasesReducer)?.releaseTracks;

    const currentRelease = useSelector((state) => state.releasesReducer)?.currentRelease;

    const selections = useSelector((state) => state.selectionsReducer);

    const { id: releaseId } = useParams();

    const { user } = useAuth0();

    const schema = useReleaseDocumentSchema();

    const {
        filesData,
        filesLoading,
        setFilesData,
        uploadFile,
    } = useUploadFile();

    const { deleteReleaseDocument } = useDeleteReleaseDocument(parseInt(releaseId));

    const formMethods = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),

    });

    const {
        control,
        formState: { errors },
        setError,
        setValue,
        watch,
    } = formMethods;

    const tracksOptions = tracksList?.map(({
        id,
        title,
    }) => ({
        label: title,
        value: id,
    }));

    const {
        documentCreate: documentCreatePermission,
        documentDelete: documentDeletePermission,
    } = permissionsData.permissions.keys;

    const {
        file: fileInputName,
        tracks: tracksInputName,
    } = inputData.names;

    const {
        file: fileInputType,
        multiAutoComplete: multiAutoCompleteInputType,
    } = inputData.types;

    const setTracksHandler = (tracks = []) => {
        const formattedTracks = tracks?.map(({ value }) => ({ id: value })); //eslint-disable-line

        if (formattedTracks?.length > 0) {
            setValue(
                tracksInputName,
                formattedTracks,
            );
        } else {
            setValue(
                tracksInputName,
                undefined,
            );
        }
    };

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        type,
        isOptional,
        selectionKey,
    ) => {
        switch (type) {
        case fileInputType:
            return (
                <Box mb={1}>
                    <UploadInput
                        accept={fileData.acceptedExtensions.document}
                        color={errors[name]?.message ? "error" : "primary"}
                        error={errors[name]?.message}
                        field={field}
                        hasError={!!errors[name]?.message}
                        label={t(`labels.${label}`)}
                        loading={filesLoading?.[name]}
                        onChange={(e) => {
                            uploadFile(
                                e.target.files[0],
                                formMethods,
                                name,
                            );
                        }}
                    />
                    {filesData?.[name] && !filesLoading?.[name] && (
                        <FileLink
                            hasDelete={!!filesData?.[name]}
                            name={filesData?.[name]?.name}
                            deleteAction={() => deleteFileHandler(
                                setFilesData,
                                formMethods,
                                name,
                            )}
                        />
                    )}
                </Box>
            );
        case multiAutoCompleteInputType:
            return (
                <AutoCompleteMenu
                    defaultValue={[]}
                    errors={errors}
                    getRenderedOption={(option) => option?.label}
                    isOptional={isOptional}
                    label={label}
                    name={name}
                    optionLabel={(option) => option.label}
                    options={tracksOptions}
                    isMultiple
                    onChange={(_, value) => setTracksHandler(value)}
                />
            );
        default:
            return (
                <AutoCompleteMenu
                    errors={errors}
                    getRenderedOption={(option) => option.label}
                    label={label}
                    name={name}
                    optionLabel={(option) => option.label}
                    options={selections?.[selectionKey]}
                    onChange={(_, option) => {
                        if (option) field.onChange(option.value);
                        else field.onChange("");
                    }}
                />
            );
        }
    };

    useEffect(
        () => {
            setUploadInputPreviousValueHandler(
                watch,
                setError,
                setValue,
                filesData,
                fileData.names.document,
            );
        },
        [watch(fileData.names.document)], // eslint-disable-line
    );

    return (
        <Box>
            <FormContainer
                action={action}
                disabled={filesLoading?.length > 0}
                formMethods={formMethods}
                loading={loading}
                data={renderFormControllerHandler(
                    control,
                    releaseDocumentFormData,
                    renderFormControllerChildrenHandler,
                )}
                hasSubmitButton={canDo(
                    user,
                    [documentCreatePermission],
                )}
                otherData={(
                    <Grid
                        spacing={2}
                        container
                    >
                        <Grid
                            md={8}
                            xs={12}
                            item
                        >
                            {currentRelease?.documents?.map(({
                                downloadUri,
                                id,
                                name: fileName,
                            }, index) => (
                                <FileLink
                                    key={id}
                                    link={downloadUri}
                                    name={fileName}
                                    rowId={index + 1}
                                    deleteAction={() => {
                                        document.getElementById(`${fileInputName}_${identifiersData.ids.input}`).value = ""; //eslint-disable-line

                                        deleteReleaseDocument(id);
                                    }}
                                    hasDelete={canDo(
                                        user,
                                        [documentDeletePermission],
                                    )}
                                    hasDownload
                                />
                            ))}
                        </Grid>
                    </Grid>
                )}
                onSubmitForm={(data) => {
                    onSubmitForm(data);

                    setFilesData({});

                    setValue(
                        fileData.names.document,
                        undefined,
                    );
                }}
            />
        </Box>
    );
};

export default ReleaseDocumentForm;

ReleaseDocumentForm.propTypes = {
    action: PropTypes.string,
    loading: PropTypes.bool,
    onSubmitForm: PropTypes.func,
};
