import styled from "@emotion/styled";
import { Paper } from "@mui/material";
import { identifiersData } from "data";

const StyledHeader = styled(
    Paper,
    { shouldForwardProp: (prop) => prop !== identifiersData.props.withoutStyle },
)(({
    theme,
    withoutStyle,
}) => ({
    background: withoutStyle ? "transparent" : theme?.palette?.grey[100],
    borderRadius: withoutStyle ? 0 : 5,
    boxShadow: withoutStyle && "none",
    marginBottom: 10,
    padding: 16,
}));

export default StyledHeader;
