import { TracksList } from "components";
import { PropTypes } from "prop-types";

const ReleaseDetailsTracks = ({
    data,
    releaseId,
}) => (
    <TracksList
        data={data}
        releaseId={releaseId}
    />
);

export default ReleaseDetailsTracks;

ReleaseDetailsTracks.propTypes = {
    data: PropTypes.object,
    releaseId: PropTypes.string,
};
