import { gql } from "@apollo/client";

export const getGroupsListQuery = gql`
    query getGroupsList(
        $page: Int!,
        $limit: Int,
        $where: DriveGroupWhereUniqueInput
    ) {
        listDriveGroups(
            elementsPerPage: $limit,
            page: $page,
            where: $where
        ) {
            posts {
                group_email
                group_name
                id
                groupMembers {
                    id
                }
            }
            meta {
                resultCount
                nodeCount
                currentPage
                totalPages
            }
        }
    }
`;

export const getGroupQuery = gql`
    query getGroup($where: DriveGroupWhereUniqueInput) {
        driveGroup(where: $where) {
            group_email
            group_name
            groupMembers {
                driveMember {
                    email
                    id
                }
            }
        }
    }
`;

export const getMembersListQuery = gql`
    query getMembersList(
        $limit: Int,
        $page: Int,
        $where: DriveMembersWhereUniqueInput
    ) {
        listDriveMembers(
            elementsPerPage: $limit,
            page: $page,
            where: $where
        ) {
            posts {
                email
                id
            }
            meta {
                currentPage
                nodeCount
                resultCount
                totalPages
            }
        }
    }
`;

export const getGroupMembersListQuery = gql`
    query getGroupMembersList(
        $limit: Int,
        $page: Int,
        $where: DriveGroupMembersWhereUniqueInput
    ) {
        listDriveGroupMembers(
            elementsPerPage: $limit,
            page: $page,
            where: $where
        ) {
            posts {
                driveMember {
                    email
                    id
                }
                id
            }
            meta {
                currentPage
                nodeCount
                resultCount
                totalPages
            }
        }
    }
`;

export const getMemberQuery = gql`
    query getMember($where: DriveMembersWhereUniqueInput) {
        driveMember(where: $where) {
            email
            memberGroups {
                driveGroup {
                    group_name
                    id
                    groupMembers {
                        id
                        driveMember {
                            id
                        }
                    }
                }
            }
        }
    }
`;
