import axiosBase from "axios";

const axios = (isGraphQLServerRequest, params, isFormData) => axiosBase.create({
    baseURL: isGraphQLServerRequest ? window?.__RUNTIME_CONFIG__?.REACT_APP_GRAPHQL_SERVER : window?.__RUNTIME_CONFIG__?.REACT_DRIVE_MANAGEMENT_SERVER,    //eslint-disable-line
    headers: { "Content-Type": isFormData ? "multipart/form-data" : "application/json" },
    timeout: 3000 * 3000,
    ...params && {
        params: { ...params },
    },
});

export default axios;
