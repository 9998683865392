import { gql } from "@apollo/client";

export const createTrackMutation = gql`
    mutation createTrack($data: TrackCreateInput!) {
        createOneTrack(data: $data) {
            id
        }
    }
`;

export const editTrackMutation = gql`
    mutation editTrack(
        $data: TrackUpdateInput!,
        $where: TrackWhereUniqueInput!
    ) {
        updateOneTrack(
            data: $data,
            where: $where
        ) {
            id
        }
    }
`;

export const deleteTrackMutation = gql`
    mutation deleteTrack($where: TrackWhereUniqueInput!) {
        deleteOneTrack(where: $where) {
            id
        }
    }
`;

export const exportTracksMutation = gql`
    mutation exportTracks(
        $tracksIds: [Int]!,
        $toCsv: Boolean
    ) {
        exportTracks(
            tracksId: $tracksIds,
            toCsv: $toCsv
        )
    }
`;

export const exportAllTracksMutation = gql`
    mutation exportAllTracks($toCsv: Boolean) {
        exportAllTracks(toCsv: $toCsv)
    }
`;

export const downloadTracksContentMutation = gql`
    mutation downloadTracksContent($tracksIds: [Int]!) {
        bulkDownloadTracks(tracksId: $tracksIds)
    }
`;
