import { useAuth0 } from "@auth0/auth0-react";
import { constantsData, urlsData } from "data";
import { useApisClient } from "hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { openAlert, setDriveManagementDrivesList } from "redux/actions";
import { displayErrorHandler } from "utils";

const useDriveManagementDrivesList = () => {
    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(false);

    const [hasNext, setHasNext] = useState(true);

    const [nextPageToken, setNextPageToken] = useState(null);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const drives = useSelector((state) => state.driveManagementReducer).drivesList;

    const { logout } = useAuth0();

    const location = useLocation();

    const { get } = useApisClient();

    useEffect(() => {
        if (hasNext && !drives && location.pathname.includes(urlsData.routes.driveManagement.url)) {
            setLoading(true);

            get(
                urlsData.apis.driveManagement.drives,
                null,
                {
                    fields: "*",
                    pageSize: 100,
                    ...hasNext && { pageToken: nextPageToken },
                },
            ).
                then(({ data: responseData }) => {
                    setData((prev) => [...prev, ...responseData?.drives]); //eslint-disable-line

                    setHasNext(false);

                    dispatch(setDriveManagementDrivesList(responseData?.drives));

                    if (responseData?.nextPageToken) setNextPageToken(responseData?.nextPageToken);
                    else setNextPageToken(null);
                })["catch"]((err) => {
                    displayErrorHandler(
                        err.response.data.message === constantsData.networkError ? t("messages.networkFailure") : err.response.data.message,
                        dispatch,
                        openAlert,
                        err.response.status,
                        logout,
                    );
                })["finally"](() => {
                    setLoading(false);
                });
        }
    },[hasNext]); //eslint-disable-line

    return {
        data,
        loading,
        nextPageToken,
        setHasNext,
    };
};

export default useDriveManagementDrivesList;
