const actionsData = {
    add: "add",
    bulkUpload: "bulkUpload",
    create: "create",
    delete: "delete",
    distribute: "distribute",
    download: "download",
    edit: "edit",
    import: "import",
    read: "read",
    reset: "reset",
    save: "save",
    shareLink: "shareLink",
    trash: "trash",
    upload: "upload",
};

export default actionsData;
