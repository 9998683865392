import styled from "@emotion/styled";
import { Autocomplete } from "@mui/material";
import { identifiersData } from "data";

const {
    categoryFilterSelectMenu: categoryFilterSelectMenuClassName,
    categoryFilterSelectMenuFormControl: categoryFilterSelectMenuFormControlClassName,
    categoryFilterTagsBox: categoryFilterTagsBoxClassName,
} = identifiersData.classNames;

const StyledCategoryFilterInput = styled(Autocomplete)(({ theme }) => ({
    "& .MuiInputBase-root": {
        fontSize: 12,
        height: 30,
        padding: 0,
    },
    "& .MuiSelect-select": {
        "@media(max-width: 400px)": { paddingRight: "32px !important" },
        fontSize: 12,
        paddingRight: "18px !important",
    },
    [`& .${categoryFilterSelectMenuClassName}`]: {
        "@media(max-width: 400px)": { width: "100%" },
        borderRadius: "10px 0 0 10px",
        height: 30,
        width: 80,
    },
    [`& .${categoryFilterSelectMenuFormControlClassName}`]: {
        "& .MuiOutlinedInput-root": { paddingRight: 0 },
        "& fieldset": {
            border: 0,
            borderRight: `1px solid ${theme.palette?.grey[400]}!important`,
        },
    },
    [`& .${categoryFilterTagsBoxClassName}`]: {
        "& .MuiChip-root": {
            height: 20,
            marginBlock: 0,
        },
        "&::-webkit-scrollbar": { display: "none" },
        "@media(max-width: 400px)": { maxWidth: 100 },
        height: 20,
        maxWidth: 220,
        scrollbarWidth: "none",
    },
    "@media(max-width: 400px)": { width: 250 },
    transition: "ease-in-out .2s !important",
    width: 400,
}));

export default StyledCategoryFilterInput;
