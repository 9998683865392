import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import {
    Box,
    TableRow,
} from "@mui/material";
import { MenuItem, TableList, TableMenu } from "atoms";
import { PageHead } from "components";
import { identifiersData, namesData, tableHeadCellsData } from "data";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setCurrentPlayedTrack } from "redux/actions";
import { StyledTableCell } from "styles";
import { convertHourToDurationFormatHandler } from "utils";

const TrackDetailsSoundRecordings = ({
    artist,
    data,
}) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const {
        menu: menuId,
        option: optionId,
    } = identifiersData.ids;

    const {
        menus: { table: tableMenuName },
        options: {
            downloadSoundRecording: downloadSoundRecordingOptionName,
            playSoundRecording: playSoundRecordingOptionName,
        },
    } = namesData;

    return (
        <Box
            alignItems="end"
            display="flex"
            flexDirection="column"
        >
            <PageHead
                title={t("labels.soundRecordings")}
                titleTag="h2"
            />
            <TableList
                headCells={tableHeadCellsData.tracks.soundRecordings}
                isPaginated={false}
            >
                {data?.map(({
                    downloadUri,
                    id,
                    name,
                    trackFile,
                }, index) => (
                    <TableRow key={id}>
                        <StyledTableCell boldCell={+true}>{name}</StyledTableCell>
                        <StyledTableCell>
                            <time>{convertHourToDurationFormatHandler(trackFile?.duration)}</time>
                        </StyledTableCell>
                        <StyledTableCell onClick={(e) => e.stopPropagation()}>
                            <TableMenu rowId={index + 1}>
                                <MenuItem
                                    icon={<PlayCircleFilledWhiteOutlinedIcon />}
                                    id={`${tableMenuName}_${menuId}_${index + 1}_${playSoundRecordingOptionName}_${optionId}`}
                                    label={t("tracks.playSoundRecording")}
                                    onClick={() => {
                                        dispatch(setCurrentPlayedTrack({
                                            artist,
                                            downloadUri,
                                            title: name,
                                        }));
                                    }}
                                />
                                <a
                                    href={`${window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_DOMAIN}${downloadUri}`} // eslint-disable-line
                                    style={{
                                        display: "flex",
                                        gap: "8px",
                                        textDecoration: "none",
                                    }}
                                >
                                    <MenuItem
                                        icon={<DownloadOutlinedIcon />}
                                        id={`${tableMenuName}_${menuId}_${index + 1}_${downloadSoundRecordingOptionName}_${optionId}`}
                                        label={t("tracks.downloadSoundRecording")}
                                    />
                                </a>
                            </TableMenu>
                        </StyledTableCell>
                    </TableRow>
                ))}
            </TableList>
        </Box>
    );
};

export default TrackDetailsSoundRecordings;

TrackDetailsSoundRecordings.propTypes = {
    artist: PropTypes.object,
    data: PropTypes.array,
};
