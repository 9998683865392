import "./styles/index.css";

import { Auth0Provider } from "@auth0/auth0-react";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { createBrowserHistory } from "history";
import { GraphQLProvider } from "providers";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { persistor, store } from "redux/store";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
import getAuthConfig from "./auth-config";

export const history = createBrowserHistory();

const root = ReactDOM.createRoot(document.getElementById("root")); // eslint-disable-line

const {
    audience,
    clientId,
    connectionScope,
    domain,
} = getAuthConfig();

const providerConfig = {
    authorizationParams: {
        ...audience ? { audience } : null,
        access_type: "offline", // eslint-disable-line
        approval_prompt: "force",  // eslint-disable-line
        connection_scope: connectionScope, // eslint-disable-line
        scope: "offline_access profile roles",
        redirect_uri: window.location.origin, // eslint-disable-line
    },
    cacheLocation: "localstorage",
    clientId,
    domain,
};

root.render(
    <Auth0Provider {...providerConfig}>
        <BrowserRouter history={history}>
            <GraphQLProvider>
                <StyledEngineProvider injectFirst>
                    <Provider store={store}>
                        <PersistGate
                            loading={null}
                            persistor={persistor}
                        >
                            <App />
                        </PersistGate>
                    </Provider>
                </StyledEngineProvider>
            </GraphQLProvider>
        </BrowserRouter>
    </Auth0Provider>,
);
