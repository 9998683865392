export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";

export const SET_SEARCH_VALUES = "SET_SEARCH_VALUES";

export const DELETE_SEARCH_VALUE = "DELETE_SEARCH_VALUE";

export const CLEAR_SEARCH_VALUES = "CLEAR_SEARCH_VALUES";

export const SET_SEARCH_CATEGORY = "SET_SEARCH_CATEGORY";

export const SET_SEARCH_DATA = "SET_SEARCH_DATA";
