import { useAuth0 } from "@auth0/auth0-react";
import MenuIcon from "@mui/icons-material/Menu";
import {
    Avatar,
    Box,
    Dialog,
    IconButton,
    Paper,
    Toolbar,
    Tooltip,
} from "@mui/material";
import MinimizedLogo from "assets/images/logo-mini.png";
import {
    DashboardMenu,
    DriveManagementSelectableDebouncedSearchInput,
    GroupsSelectableDebouncedSearchInput,
    SearchInput,
    SkeletonLoader,
    UserMenu,
} from "atoms";
import {
    identifiersData, namesData, permissionsData, urlsData,
} from "data";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { StyledNavbar } from "styles";

const Navbar = ({
    isDriveManagementNavbar,
    isGroupsNavbar,
    onOpenSidebar,
    open,
}) => {
    const [searchOpened, setSearchOpened] = useState(false);

    const { t } = useTranslation();

    const openTrackDrawer = useSelector((state) => state.tracksReducer?.openDrawer);

    const openRevenueReportDrawer = useSelector((state) => state.revenueReportsReducer?.openDrawer);

    const openDistributionDrawer = useSelector((state) => state.distributionsReducer?.openDrawer);

    const openDriveLinkDrawer = useSelector((state) => state.driveLinksReducer?.openDrawer);

    const openReleaseClientLinkDrawer = useSelector((state) => state.releasesReducer?.openDrawer);

    const openDriveManagementLabelDrawer = useSelector((state) => state.driveManagementReducer?.openDrawer);

    const drives = useSelector((state) => state.driveManagementReducer).drivesList;

    const {
        isLoading,
        user,
    } = useAuth0();

    const mediaReadPermission = user?.[permissionsData.key]?.find((permission) => permission === permissionsData.permissions.keys.mediaRead);

    const driveReadPermission = user?.[permissionsData.key]?.find((permission) => permission === permissionsData.permissions.keys.driveRead);

    const reportReadPermission = user?.[permissionsData.key]?.find((permission) => permission === permissionsData.permissions.keys.reportRead);

    return (
        <>
            {(isDriveManagementNavbar || isGroupsNavbar) && (
                <Dialog
                    open={searchOpened}
                    sx={{
                        display: {
                            md: "none",
                            xs: "block",
                        },
                    }}
                    onClose={() => setSearchOpened(false)}
                >
                    <Paper
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            overflow: "hidden",
                            padding: 2,
                            width: {
                                md: 500,
                                xs: 300,
                            },
                        }}
                    >
                        {isGroupsNavbar ? <GroupsSelectableDebouncedSearchInput /> : <DriveManagementSelectableDebouncedSearchInput />}
                    </Paper>
                </Dialog>
            )}
            <StyledNavbar
                open={open}
                position="fixed"
                withoutShadow={+!!(
                    !openTrackDrawer
                    && !openRevenueReportDrawer
                    && !openDistributionDrawer
                    && !openDriveLinkDrawer
                    && !openReleaseClientLinkDrawer
                    && !openDriveManagementLabelDrawer
                )}
            >
                <Toolbar
                    sx={{
                        gap: 2,
                        justifyContent: "space-between",
                    }}
                >
                    <Box
                        alignItems="center"
                        display="flex"
                    >
                        <Box
                            alignItems="center"
                            display={open ? "none" : "flex"}
                            justifyContent="start"
                        >
                            <Link to={urlsData.routes.home}>
                                <Avatar
                                    alt={t("imageAlternatives.logo")}
                                    imgProps={{ loading: "lazy" }}
                                    src={MinimizedLogo}
                                    sx={{
                                        display: open && "none",
                                        height: 20,
                                        marginRight: 1,
                                        width: 20,
                                    }}
                                />
                            </Link>
                            {!isDriveManagementNavbar && !isGroupsNavbar && (
                                <Tooltip title={t("actions.openSidebar")}>
                                    <IconButton
                                        id={`${namesData.buttons.openSidebar}_${identifiersData.ids.button}`}
                                        onClick={onOpenSidebar}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                        {mediaReadPermission ? isDriveManagementNavbar || isGroupsNavbar ? ( // eslint-disable-line
                            <Box>
                                {isGroupsNavbar && <GroupsSelectableDebouncedSearchInput />}
                                {!drives && isDriveManagementNavbar && (
                                    <SkeletonLoader
                                        count={1}
                                        height={50}
                                        width={450}
                                    />
                                )}
                                {drives && isDriveManagementNavbar && <DriveManagementSelectableDebouncedSearchInput />}
                            </Box>
                        ) : <SearchInput fromNavbar /> : null}
                    </Box>
                    <Box
                        alignItems="center"
                        display="flex"
                        gap={1}
                        justifyContent="center"
                    >
                        {window?.__RUNTIME_CONFIG__?.ENABLE_DRIVE_MANAGEMENT === "true" ? isLoading ? ( /* eslint-disable-line */
                            <SkeletonLoader
                                count={1}
                                height={50}
                                width={25}
                            />
                        ) : (mediaReadPermission && driveReadPermission) || (reportReadPermission && driveReadPermission) ? <DashboardMenu /> : null : null}
                        <Box>
                            {isLoading ? (
                                <SkeletonLoader
                                    count={1}
                                    height={50}
                                    width={200}
                                />
                            ) : <UserMenu withoutUsername={isDriveManagementNavbar || isGroupsNavbar} />}
                        </Box>
                    </Box>
                </Toolbar>
            </StyledNavbar>
        </>

    );
};

export default Navbar;

Navbar.propTypes = {
    isDriveManagementNavbar: PropTypes.bool,
    isGroupsNavbar: PropTypes.bool,
    onOpenSidebar: PropTypes.func,
    open: PropTypes.bool,
};
