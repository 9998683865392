import SearchIcon from "@mui/icons-material/Search";
import { IconButton, Tooltip } from "@mui/material";
import { Loader, SelectMenu } from "atoms";
import {
    identifiersData, inputData, namesData, urlsData,
} from "data";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { setSearchCategory, setSearchData, setSearchValues } from "redux/actions";
import { StyledSelectableDebouncedSearchInput } from "styles";
import { debounce } from "utils";

const GroupsSelectableDebouncedSearchInput = () => {
    const [loading, setLoading] = useState(false);

    const [searchInputValue, setSearchInputValue] = useState("");

    const dispatch = useDispatch();

    const searchCategory = useSelector((state) => state.searchReducer.searchCategory) || 1;

    const searchData = useSelector((state) => state.searchReducer.searchData);

    const searchValues = useSelector((state) => state.searchReducer.searchValues);

    const { t } = useTranslation();

    const navigate = useNavigate();

    const {
        members: { url: membersRouteUrl },
        url: groupsRouteUrl,
    } = urlsData.routes.groups;

    const {
        classNames: {
            searchBox: searchBoxClassName,
            searchButton: searchButtonClassName,
            searchSelectMenu: searchSelectMenuClassName,
            searchSelectMenuFormControl: searchSelectMenuFormControlClassName,
        },
        ids: {
            button: buttonId,
            input: inputId,
            label: labelId,
            selectMenu: selectMenuId,
        },
    } = identifiersData;

    const {
        groupsSearch: groupsSearchInputName,
        search: searchInputName,
    } = inputData.names;

    const fetchSearchDataHandler = (value, category) => {
        if (value.length > 0) {
            navigate({
                pathname: category === 1 ? groupsRouteUrl : groupsRouteUrl + membersRouteUrl,
                search: createSearchParams({
                    category,
                    limit: 25,
                    page: 1,
                    q: value,
                }).toString(),
            });

            dispatch(
                setSearchData({
                    category,
                    values: [value.trim()],
                }),
            );
        }
    };

    const callSearchDataHandler = useCallback(
        debounce(
            (value) => {
                fetchSearchDataHandler(
                    value,
                    searchCategory,
                );

                setLoading(false);
            },
            1200,
        ),
        [searchCategory],
    ); /* eslint react-hooks/exhaustive-deps: "off" */

    const changeSearchInputValueHandler = (e) => {
        const { value } = e.target;

        setSearchInputValue(value);

        setLoading(true);

        callSearchDataHandler(value);

        dispatch(setSearchValues([value.trim()]));

        if (value.length === 0) {
            navigate({
                pathname: searchCategory === 1 ? groupsRouteUrl : groupsRouteUrl + membersRouteUrl,
                search: createSearchParams({
                    limit: 25,
                    page: 1,
                }).toString(),
            });

            dispatch(setSearchValues([]));

            dispatch(
                setSearchData({
                    category: searchCategory,
                    values: [],
                }),
            );
        }
    };

    const selectCategoryHandler = (e) => {
        const { value } = e.target;

        dispatch(setSearchCategory(value));

        fetchSearchDataHandler(
            searchData?.values?.[0],
            value,
        );
    };

    const keyDownSearchInputHandler = (event) => {
        if (event.key === "Enter") {
            dispatch(setSearchValues([searchInputValue.trim()]));

            fetchSearchDataHandler(
                searchInputValue,
                searchCategory,
            );
        }
    };

    const clickSearchButtonHandler = () => {
        dispatch(setSearchValues([searchInputValue.trim()]));

        fetchSearchDataHandler(
            searchInputValue,
            searchCategory,
        );
    };

    return (
        <StyledSelectableDebouncedSearchInput
            className={searchBoxClassName}
            defaultValue={searchValues?.[0]}
            id={`${groupsSearchInputName}_${inputId}`}
            options={[]}
            placeholder={t("labels.search")}
            value={searchValues?.[0] || searchInputValue}
            variant="outlined"
            InputProps={{
                endAdornment: (
                    <Tooltip title={t("actions.search")}>
                        <IconButton
                            className={searchButtonClassName}
                            id={`${groupsSearchInputName}_${inputId}_${buttonId}`}
                            sx={{
                                display: {
                                    sm: "block!important",
                                    xs: "none!important",
                                },
                            }}
                            onClick={clickSearchButtonHandler}
                        >
                            {loading ? (
                                <Loader
                                    size={22}
                                    withoutFullHeight
                                />
                            ) : <SearchIcon />}
                        </IconButton>
                    </Tooltip>
                ),
                startAdornment: (
                    <SelectMenu
                        className={searchSelectMenuClassName}
                        formControlClassName={searchSelectMenuFormControlClassName}
                        label={t("labels.category")}
                        labelId={`${searchInputName}_${selectMenuId}_${labelId}`}
                        name={namesData.menus.groupsSearch}
                        selected={!searchCategory}
                        value={searchCategory}
                        options={[
                            {
                                label: t("groups.name"),
                                value: 1,
                            },
                            {
                                label: t("groups.members.name"),
                                value: 2,
                            },
                        ]}
                        onChange={(e) => selectCategoryHandler(e)}
                    />
                ),
            }}
            onChange={changeSearchInputValueHandler}
            onKeyDown={keyDownSearchInputHandler}
        />
    );
};
export default GroupsSelectableDebouncedSearchInput;
