import AddToDriveIcon from "@mui/icons-material/AddToDrive";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import RecyclingIcon from "@mui/icons-material/Recycling";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import {
    Avatar,
    Box,
    Button,
    TableRow,
} from "@mui/material";
import {
    Loader,
    MenuItem,
    Modal,
    TableList,
    TableMenu,
} from "atoms";
import { DriveManagementLabelsDrawer, DriveManagementUsersPermissions } from "components";
import {
    actionsData,
    constantsData,
    identifiersData,
    namesData,
    tableHeadCellsData,
    urlsData,
} from "data";
import {
    useDeleteDriveManagementFile,
    useDeleteDriveManagementFolder,
    useDownloadDriveManagementFile,
    useDriveManagementDrivesList,
    useDriveManagementFilesList,
    useGetLastElement,
    useTrashDriveManagementFile,
    useTrashDriveManagementFolder,
} from "hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    createSearchParams,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { toggleDriveManagementLabelDrawer } from "redux/actions";
import { StyledTableCell } from "styles";
import { convertDateToLocaleStringFormatHandler, getFileSizeHandler, openLinkInNewTabHandler } from "utils";

const DriveManagementFilesList = () => {
    const [fileId, setFileId] = useState(null);

    const [modalData, setModalData] = useState({
        actionType: "",
        body: "",
        hasComponent: true,
        id: null,
        open: false,
        title: "",
    });

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const {
        driveId,
        folderId,
    } = useParams();

    const navigate = useNavigate();

    const [params] = useSearchParams();

    const searchValueParam = params.get("q");

    const driveValueParam = params.get("drive");

    const { refetch } = useSelector((state) => state.driveManagementReducer);

    const filtersModules = useSelector((state) => state.filtersReducer?.filtersModules);

    const { deleteDriveManagementFile } = useDeleteDriveManagementFile();

    const { deleteDriveManagementFolder } = useDeleteDriveManagementFolder();

    const { trashDriveManagementFile } = useTrashDriveManagementFile();

    const { trashDriveManagementFolder } = useTrashDriveManagementFolder();

    const {
        data,
        loading,
        nextPageToken,
        setHasNext,
    } = useDriveManagementFilesList(
        driveId,
        folderId,
        searchValueParam,
        driveValueParam,
        filtersModules?.driveManagement,
    );

    useDriveManagementDrivesList();

    const { downloadDriveManagementFile } = useDownloadDriveManagementFile();

    const { lastElement } = useGetLastElement(
        loading,
        !!nextPageToken,
        () => setHasNext(true),
    );

    const {
        drives: driveManagementDrivesRouteUrl,
        folders: driveManagementFoldersRouteUrl,
        url: driveManagementRouteUrl,
    } = urlsData.routes.driveManagement;

    const { folder: folderConstant } = constantsData;

    const {
        link: linkId,
        menu: menuId,
        option: optionId,
        table: tableId,
    } = identifiersData.ids;

    const {
        menus: { table: tableMenuName },
        options: {
            addLabel: addLabelOptionName,
            download: downloadOptionName,
            share: shareOptionName,
            trash: trashOptionName,
            viewInDrive: viewInDriveOptionName,
        },
    } = namesData;

    const openPermissionsModalHandler = (id, name, mimeType, canShare) => {
        setModalData({
            ...modalData,
            action: null,
            body: <DriveManagementUsersPermissions
                canShare={canShare}
                fileId={id}
                fileName={name}
                mimeType={mimeType}
                setModalData={setModalData}
            />,
            hasComponent: true,
            id,
            open: true,
            title: t("labels.handlePermissions"),
        });
    };

    const clickFileHandler = (mimeType, webViewLink, id) => {
        if (mimeType?.includes(folderConstant)) {
            navigate({
                pathname: `${driveManagementRouteUrl}${driveManagementDrivesRouteUrl}/${driveId || driveValueParam}${driveManagementFoldersRouteUrl}/${id}`,
                ...driveValueParam && searchValueParam && {
                    search: createSearchParams({
                        drive: driveValueParam,
                        q: searchValueParam,
                    }).toString(),
                },
            });
        } else openLinkInNewTabHandler(webViewLink);
    };

    const openDeleteModalHandler = (id, mimeType, isTrash) => {
        setModalData({
            ...modalData,
            action: () => {
                if (isTrash) {
                    if (mimeType?.includes(folderConstant)) trashDriveManagementFolder(id);
                    else trashDriveManagementFile(id);
                } else if (mimeType?.includes(folderConstant)) deleteDriveManagementFolder(id);
                else deleteDriveManagementFile(id);
            },
            actionType: isTrash ? actionsData.trash : actionsData["delete"],
            body: t(
                `messages.${isTrash ? "trashMsgs" : "deleteMsgs"}.alert`,
                { entity: mimeType?.includes(folderConstant) ? t("labels.folder") : t("labels.file") },
            ),
            hasComponent: false,
            id,
            open: true,
            title: `${t(`actions.${isTrash ? "trash" : "delete"}`)} ${mimeType?.includes(folderConstant) ? t("labels.folder") : t("labels.file")}`,
        });
    };

    if ((loading && data?.length === 0) || refetch) return <Loader />;

    return (
        <>
            <DriveManagementLabelsDrawer id={fileId} />
            <Modal
                modalData={modalData}
                setModalData={setModalData}
            />
            <TableList
                headCells={tableHeadCellsData.driveManagement.files}
                isPaginated={false}
                loading={loading}
                type="files"
            >
                {data?.map(({
                    capabilities,
                    fileExtension,
                    iconLink,
                    id,
                    mimeType,
                    modifiedTime,
                    name,
                    size,
                    webViewLink,
                }, index) => (
                    <TableRow
                        key={id}
                        ref={data?.length === index + 1 ? lastElement : null}
                    >
                        <StyledTableCell boldCell={+true}>
                            <Button
                                id={`${tableId}_${linkId}_${index + 1}`}
                                onClick={() => clickFileHandler(
                                    mimeType,
                                    webViewLink,
                                    id,
                                )}
                            >
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    gap={2}
                                >
                                    <Avatar
                                        alt={t("imageAlternatives.file")}
                                        imgProps={{ loading: "lazy" }}
                                        src={iconLink}
                                        sx={{
                                            borderRadius: 1,
                                            height: 15,
                                            width: 15,
                                        }}
                                    />
                                    {name}
                                </Box>
                            </Button>
                        </StyledTableCell>
                        <StyledTableCell align="center">{getFileSizeHandler(size)}</StyledTableCell>
                        <StyledTableCell>
                            <time>{convertDateToLocaleStringFormatHandler(modifiedTime)}</time>
                        </StyledTableCell>
                        <StyledTableCell onClick={(e) => e.stopPropagation()}>
                            <TableMenu
                                hasDelete={capabilities?.canDelete}
                                rowId={index + 1}
                                setOpenModal={() => {
                                    openDeleteModalHandler(
                                        id,
                                        mimeType,
                                    );
                                }}
                            >
                                {window?.__RUNTIME_CONFIG__?.ENABLE_DRIVE_MANAGEMENT_COLLABORATION === "true" && ( // eslint-disable-line
                                    <MenuItem
                                        icon={<ShareOutlinedIcon />}
                                        id={`${tableMenuName}_${menuId}_${index + 1}_${shareOptionName}_${optionId}`}
                                        label={t("actions.share")}
                                        onClick={() => openPermissionsModalHandler(
                                            id,
                                            name,
                                            mimeType,
                                            capabilities?.canShare,
                                        )}
                                    />
                                )}
                                <a
                                    href={webViewLink}
                                    rel="noreferrer"
                                    target="_blank"
                                    style={{
                                        display: "flex",
                                        textDecoration: "none",
                                    }}
                                >
                                    <MenuItem
                                        icon={<AddToDriveIcon />}
                                        id={`${tableMenuName}_${menuId}_${index + 1}_${viewInDriveOptionName}_${optionId}`}
                                        label={t("actions.viewInDrive")}
                                    />
                                </a>
                                {fileExtension && (
                                    <MenuItem
                                        icon={<DownloadOutlinedIcon />}
                                        id={`${tableMenuName}_${menuId}_${index + 1}_${downloadOptionName}_${optionId}`}
                                        label={t("actions.download")}
                                        onClick={() => downloadDriveManagementFile(
                                            id,
                                            name,
                                        )}
                                    />
                                )}
                                {window?.__RUNTIME_CONFIG__?.ENABLE_DRIVE_MANAGEMENT_LABELS === "true" && ( // eslint-disable-line
                                    <MenuItem
                                        icon={<LabelOutlinedIcon />}
                                        id={`${tableMenuName}_${menuId}_${index + 1}_${addLabelOptionName}_${optionId}`}
                                        label={t("actions.addLabel")}
                                        onClick={() => {
                                            setFileId(id);

                                            dispatch(toggleDriveManagementLabelDrawer(true));
                                        }}
                                    />
                                )}
                                {capabilities?.canTrash && ( // eslint-disable-line
                                    <MenuItem
                                        icon={<RecyclingIcon />}
                                        id={`${tableMenuName}_${menuId}_${index + 1}_${trashOptionName}_${optionId}`}
                                        label={t("actions.trash")}
                                        onClick={() => {
                                            openDeleteModalHandler(
                                                id,
                                                mimeType,
                                                true,
                                            );
                                        }}
                                    />
                                )}
                            </TableMenu>
                        </StyledTableCell>
                    </TableRow>
                ))}
            </TableList>
        </>
    );
};

export default DriveManagementFilesList;
