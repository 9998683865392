import { gql } from "@apollo/client";

export const addPermissionMutation = gql`
    mutation addPermission($addedPermission: PermissionRemoveAddInput!) {
        addPermissionsByRole(PermissionRemoveAddInput: $addedPermission)
    }
`;

export const removePermissionMutation = gql`
    mutation removePermission($removedPermission: PermissionRemoveAddInput!) {
        removePermissionsByRole(PermissionRemoveAddInput: $removedPermission)
    }
`;
