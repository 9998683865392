import { yupResolver } from "@hookform/resolvers/yup";
import { AutoCompleteMenu } from "atoms";
import { FormContainer } from "components";
import { fileData, foldersStructureTrackFileFormData, inputData } from "data";
import { PropTypes } from "prop-types";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useFoldersStructureTrackFileSchema } from "schemas";
import { renderFormControllerHandler } from "utils";

import FoldersStructureArtworkFileForm from "./artwork";
import FoldersStructureXmlFileForm from "./xml";

const FoldersStructureTrackFileForm = ({
    action,
    modalData,
    onSubmitForm,
    setModalData,
    values,
}) => {
    const [fileType, setFileType] = useState(fileData.types.track);

    const selections = useSelector((state) => state.selectionsReducer);

    const schema = useFoldersStructureTrackFileSchema();

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const {
        control,
        formState: { errors },
    } = formMethods;

    const {
        fileExtension: fileExtensionInputName,
        fileName: fileNameInputName,
        fileType: fileTypeInputName,
    } = inputData.names;

    const changeAutoCompleteMenuValueHandler = (option, name, field) => {
        if (name === fileTypeInputName) {
            setFileType(option?.label);

            field.onChange(option?.value);
        } else field.onChange(option?.value);
    };

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        ___,
        __,
        selectionKey,
    ) => (
        <AutoCompleteMenu
            defaultValue={selections?.[selectionKey]?.find((option) => option?.value === values?.[name])}
            errors={errors}
            getRenderedOption={(option) => option?.label}
            label={label}
            name={name}
            optionLabel={(option) => option?.label}
            options={((name === fileExtensionInputName || name === fileNameInputName) && !fileType ? [] : selections?.[selectionKey]) || []}
            onChange={(_, option) => changeAutoCompleteMenuValueHandler(
                option,
                name,
                field,
            )}
        />
    );

    if (fileType === fileData.types.artwork) {
        return (
            <FoldersStructureArtworkFileForm
                action={action}
                modalData={modalData}
                setModalData={setModalData}
                values={{
                    fileType: fileData.types.artwork,
                    id: values.id,
                }}
                onSubmitForm={onSubmitForm}
            />
        );
    }

    if (fileType === fileData.types.xml) {
        return (
            <FoldersStructureXmlFileForm
                action={action}
                modalData={modalData}
                setModalData={setModalData}
                values={{
                    fileType: fileData.types.xml,
                    id: values.id,
                }}
                onSubmitForm={onSubmitForm}
            />
        );
    }

    return (
        <FormContainer
            action={action}
            formMethods={formMethods}
            setModalData={setModalData}
            data={renderFormControllerHandler(
                control,
                foldersStructureTrackFileFormData,
                renderFormControllerChildrenHandler,
            )}
            hasCancelButton
            withoutShadow
            onSubmitForm={onSubmitForm}
        />
    );
};

export default FoldersStructureTrackFileForm;

FoldersStructureTrackFileForm.propTypes = {
    action: PropTypes.string,
    modalData: PropTypes.object,
    onSubmitForm: PropTypes.func,
    setModalData: PropTypes.func,
    values: PropTypes.object,
};
