import { regexData } from "data";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useTrackSchema = () => {
    const { t } = useTranslation();

    const { number: numberRegex } = regexData;

    return yup.object({
        composers: yup.string().nullable(),
        customId: yup.string().matches(
            numberRegex,
            t("messages.inputMsgs.invalidNumber"),
        ).required(t("messages.inputMsgs.required")),
        imageFile: yup.mixed().nullable(),
        isrc: yup.string().required(t("messages.inputMsgs.required")).length(
            12,
            t(
                "messages.inputMsgs.length",
                { length: 12 },
            ),
        ),
        iswc: yup.string().nullable(),
        nameAr: yup.string().required(t("messages.inputMsgs.required")),
        nameEn: yup.string().required(t("messages.inputMsgs.required")),
        number: yup.string().matches(
            numberRegex,
            t("messages.inputMsgs.invalidNumber"),
        ).required(t("messages.inputMsgs.required")),
        otherIsrc: yup.string().nullable(),
        primaryArtist: yup.string().required(t("messages.inputMsgs.required")),
        secondaryArtists: yup.mixed().nullable(),
        trackFile: yup.mixed().required(t("messages.inputMsgs.required")),
        writers: yup.string().nullable(),
    });
};

export default useTrackSchema;
