import { regexData } from "data";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useFoldersStructureArtworkFileSchema = () => {
    const { t } = useTranslation();

    const { number: numberRegex } = regexData;

    return yup.object({
        fileExtension: yup.string().required(t("messages.inputMsgs.required")),
        fileName: yup.string().required(t("messages.inputMsgs.required")),
        fileType: yup.string().required(t("messages.inputMsgs.required")),
        height: yup.string().required(t("messages.inputMsgs.required")).matches(
            numberRegex,
            t("messages.inputMsgs.invalidNumber"),
        ),
        width: yup.string().required(t("messages.inputMsgs.required")).matches(
            numberRegex,
            t("messages.inputMsgs.invalidNumber"),
        ),
    });
};

export default useFoldersStructureArtworkFileSchema;
