import styled from "@emotion/styled";
import { Drawer } from "@mui/material";
import { identifiersData } from "data";

const sidebarWidth = 240;

const openedMixin = (theme) => ({
    marginTop: 0,
    overflowX: "hidden",
    transition: theme?.transitions?.create(
        "width",
        {
            duration: theme?.transitions?.duration?.enteringScreen,
            easing: theme?.transitions?.easing?.sharp,
        },
    ),
    width: `${sidebarWidth}px!important`,
});

const closedMixin = (theme) => ({
    marginTop: 0,
    overflowX: "hidden",
    transition: theme?.transitions?.create(
        "width",
        {
            duration: theme?.transitions?.duration?.leavingScreen,
            easing: theme?.transitions?.easing?.sharp,
        },
    ),
    [theme?.breakpoints?.up("sm")]: { width: theme?.spacing ? `calc(${theme?.spacing(8)} + 1px)` : "initial" },
    width: theme?.spacing ? `calc(${theme?.spacing(7)} + 1px)` : "initial",
});

export const StyledSidebarHeader = styled("div")(({ theme }) => ({
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: theme?.spacing ? theme?.spacing(
        0,
        1,
    ) : "initial",
    ...theme?.mixins?.toolbar,
}));

export const StyledSidebar = styled(
    Drawer,
    { shouldForwardProp: (prop) => prop !== identifiersData.props.open },
)(({
    open,
    theme,
}) => ({
    boxSizing: "border-box",
    flexShrink: 0,
    whiteSpace: "nowrap",
    width: sidebarWidth,
    ...open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    },
    ...!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    },
    [`& .MuiButtonBase-root.${identifiersData.classNames.active}`]: { color: theme?.palette?.primary?.main },
}));
