import styled from "@emotion/styled";
import { alpha, Toolbar } from "@mui/material";

const StyledToolbar = styled(Toolbar)(({
    selected,
    theme,
}) => ({
    "&::-webkit-scrollbar": { display: "none" },
    overflow: "auto",
    pl: { sm: 2 },
    pr: {
        sm: 1,
        xs: 1,
    },
    ...selected?.length > 0 && {
        backgroundColor: alpha(
            theme.palette.primary.main,
            0.2,
        ),
    },
}));

export default StyledToolbar;
