import { gql } from "@apollo/client";

const getSearchListQuery = gql`
    query getSearchList(
        $searchValues: [String]!,
        $entityId: Int!,
        $page: Int!,
        $limit: Int
    ) {
        searchByModel(
            searchParam: $searchValues,
            modelId: $entityId,
            page: $page,
            elementsPerPage: $limit
        ) {
            ... on Artists {
                posts {
                    id
                    name
                    name_ar
                    code
                    country {
                        en_name
                    }
                    releases {
                        id
                    }
                    tracks {
                        id
                    }
                    secondary_tracks {
                        id
                    }
                    artistImage: image {
                        downloadThumbnail
                    }
                }
                meta {
                    resultCount
                    nodeCount
                    currentPage
                    totalPages
                }
            }
            ... on Releases {
                posts {
                    id
                    duration
                    album_name
                    upc
                    release_date
                    releaseTracks {
                        track {
                            id
                        }
                    }
                    releaseType {
                        name
                    }
                    artist {
                        id
                        name
                    }
                    releaseImage: image {
                        downloadThumbnail
                    }
                }
                meta {
                    resultCount
                    nodeCount
                    currentPage
                    totalPages
                }
            }
            ... on Tracks {
                posts {
                    id
                    title
                    title_ar
                    track_num
                    downloadUri
                    isrc
                    trackFile {
                        duration
                    }
                    secondary_artists {
                        id
                        name
                    }
                    artist {
                        id
                        name
                    }
                    image {
                        downloadThumbnail
                    }
                }
                meta {
                    resultCount
                    nodeCount
                    currentPage
                    totalPages
                }
            }
        }
    }
`;

export default getSearchListQuery;
