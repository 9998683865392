import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData, urlsData } from "data";
import { editGroupMutation } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useEditGroup = (id) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [editGroupAction, { loading }] = useMutation(editGroupMutation);

    const editGroup = async ({
        email,
        name,
    }) => {
        try {
            await editGroupAction({
                onCompleted: () => {
                    navigate(urlsData.routes.groups.url);

                    dispatch(openAlert(
                        t(
                            "messages.editMsgs.success",
                            { entity: t("groups.entity") },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: {
                    data: {
                        group_email: { set: email }, // eslint-disable-line
                        group_name: { set: name }, // eslint-disable-line
                    },
                    where: { id },
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        editGroup,
        loading,
    };
};

export default useEditGroup;
