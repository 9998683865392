import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import { FileLink } from "atoms";
import { PageHead } from "components";
import { permissionsData } from "data";
import { useDeleteReleaseDocument } from "hooks";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { canDo } from "utils";
import { ForbiddenView } from "views";

const ReleaseDetailsDocuments = ({ data }) => {
    const { t } = useTranslation();

    const { id: releaseId } = useParams();

    const { user } = useAuth0();

    const { deleteReleaseDocument } = useDeleteReleaseDocument(parseInt(releaseId));

    const {
        documentDelete: documentDeletePermission,
        documentRead: documentReadPermission,
    } = permissionsData.permissions.keys;

    const allowedPermissions = [documentReadPermission];

    return (
        <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
        >
            {canDo(user, allowedPermissions) ? ( // eslint-disable-line
                <Box width="100%">
                    <PageHead
                        title={t("labels.documents")}
                        titleTag="h2"
                    />
                    {data?.map(({
                        downloadUri,
                        id,
                        name,
                    }, index) => (
                        <FileLink
                            deleteAction={() => deleteReleaseDocument(id)}
                            key={id}
                            link={downloadUri}
                            name={name}
                            rowId={index + 1}
                            hasDelete={canDo(
                                user,
                                [documentDeletePermission],
                            )}
                            hasDownload
                        />
                    ))}
                </Box>
            ) : <ForbiddenView withoutBack />}
        </Box>
    );
};

export default ReleaseDetailsDocuments;

ReleaseDetailsDocuments.propTypes = { data: PropTypes.array };
