import { useAuth0 } from "@auth0/auth0-react";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
    Box,
    Divider,
    IconButton,
    Menu,
    Tooltip,
    Zoom,
} from "@mui/material";
import { MenuItem } from "atoms";
import {
    identifiersData,
    namesData,
    permissionsData,
    urlsData,
} from "data";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { canDo } from "utils";

const TableMenu = ({
    children,
    deleteAction,
    deleteText,
    disabled,
    editAction,
    editRouteUrl,
    editText,
    hasDelete,
    hasEdit,
    isRevenueReportsTemplatesMenu,
    rowId,
    setOpenModal,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const { t } = useTranslation();

    const navigate = useNavigate();

    const location = useLocation();

    const { user } = useAuth0();

    const open = Boolean(anchorEl);

    const {
        clients: { url: clientsRouteUrl },
        driveManagement: { url: driveManagementRouteUrl },
        groups: { url: groupsRouteUrl },
        releases: { url: releasesRouteUrl },
        revenueReports: { url: revenueReportsRouteUrl },
        users: { url: usersRouteUrl },
    } = urlsData.routes;

    const {
        accountDelete: accountDeletePermission,
        accountEdit: accountEditPermission,
        driveRead: driveReadPermission,
        mediaDelete: mediaDeletePermission,
        mediaEdit: mediaEditPermission,
        mediaRead: mediaReadPermission,
        reportDelete: reportDeletePermission,
        reportEdit: reportEditPermission,
        reportRead: reportReadPermission,
    } = permissionsData.permissions.keys;

    const {
        button: buttonId,
        menu: menuId,
        option: optionId,
    } = identifiersData.ids;

    const {
        menus: { table: tableMenuName },
        options: {
            delete: deleteOptionName,
            edit: editOptionName,
        },
    } = namesData;

    let allowedEditPermissions = [];

    let allowedDeletePermissions = [];

    switch (true) {
    case location.pathname === releasesRouteUrl: allowedEditPermissions = [mediaReadPermission];
        break;
    case location.pathname === revenueReportsRouteUrl:
    case isRevenueReportsTemplatesMenu: allowedEditPermissions = [reportEditPermission];
        break;
    case location.pathname === usersRouteUrl: allowedEditPermissions = [accountEditPermission];
        break;
    case location.pathname === driveManagementRouteUrl || location.pathname === groupsRouteUrl: allowedEditPermissions = [driveReadPermission];
        break;
    case location.pathname === clientsRouteUrl: allowedEditPermissions = [mediaReadPermission, reportReadPermission];
        break;
    default: allowedEditPermissions = [mediaEditPermission];
    }

    switch (true) {
    case location.pathname === revenueReportsRouteUrl:
    case isRevenueReportsTemplatesMenu: allowedDeletePermissions = [reportDeletePermission];
        break;
    case location.pathname === usersRouteUrl: allowedDeletePermissions = [accountDeletePermission];
        break;
    case location.pathname === driveManagementRouteUrl || location.pathname === groupsRouteUrl: allowedDeletePermissions = [driveReadPermission];
        break;
    default: allowedDeletePermissions = [mediaDeletePermission];
    }

    const openMenuHandler = (event) => setAnchorEl(event.currentTarget);

    const closeMenuHandler = () => setAnchorEl(null);

    return (
        <Box>
            <Tooltip title={t("labels.actions")}>
                <IconButton
                    disabled={disabled}
                    id={`${tableMenuName}_${menuId}_${buttonId}${rowId ? `_${rowId}` : ""}`}
                    onClick={openMenuHandler}
                >
                    <MoreVertIcon cursor="pointer" />
                </IconButton>
            </Tooltip>
            <Menu
                TransitionComponent={Zoom}
                anchorEl={anchorEl}
                open={open}
                onClose={closeMenuHandler}
            >
                <Box
                    id={`${tableMenuName}_${menuId}${rowId ? `_${rowId}` : ""}`}
                    onClick={closeMenuHandler}
                >
                    {children}
                    {hasEdit && (
                        <MenuItem
                            icon={<EditOutlinedIcon />}
                            id={`${tableMenuName}_${menuId}${rowId ? `_${rowId}` : ""}_${editOptionName}_${optionId}`}
                            label={editText || t("actions.edit")}
                            disabled={!canDo(
                                user,
                                allowedEditPermissions,
                                location.pathname === clientsRouteUrl,
                            )}
                            onClick={() => (editRouteUrl ? navigate(
                                editRouteUrl,
                                {
                                    state: { from: location.pathname + location.search },
                                },
                            ) : editAction())}
                        />
                    )}
                    {hasDelete && (
                        <>
                            <Divider />
                            <MenuItem
                                icon={<DeleteForeverOutlinedIcon />}
                                id={`${tableMenuName}_${menuId}${rowId ? `_${rowId}` : ""}_${deleteOptionName}_${optionId}`}
                                label={deleteText || t("actions.delete")}
                                disabled={!canDo(
                                    user,
                                    allowedDeletePermissions,
                                )}
                                isDelete
                                onClick={deleteAction || setOpenModal}
                            />
                        </>
                    )}
                </Box>
            </Menu>
        </Box>
    );
};

export default TableMenu;

TableMenu.propTypes = {
    children: PropTypes.node,
    deleteAction: PropTypes.func,
    deleteText: PropTypes.string,
    disabled: PropTypes.bool,
    editAction: PropTypes.func,
    editRouteUrl: PropTypes.string,
    editText: PropTypes.string,
    hasDelete: PropTypes.bool,
    hasEdit: PropTypes.bool,
    isRevenueReportsTemplatesMenu: PropTypes.bool,
    rowId: PropTypes.number,
    setOpenModal: PropTypes.func,
};
