import { useMutation } from "@apollo/client";
import { DownloadBox } from "atoms";
import {
    constantsData,
    identifiersData,
    namesData,
    statusTypesData,
} from "data";
import { exportFilteredRevenueReportsMutation } from "graph";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { openAlert } from "redux/actions";
import { displayErrorHandler, getUniqueRandomId } from "utils";

const useExportFilteredRevenueReports = (filters) => {
    const [exporting, setExporting] = useState(false);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [exportFilteredRevenueReportsAction, { loading }] = useMutation(exportFilteredRevenueReportsMutation);

    const {
        exportFilteredRevenueReportsPending: exportFilteredRevenueReportsPendingToastName,
        exportFilteredRevenueReportsSuccess: exportFilteredRevenueReportsSuccessToastName,
    } = namesData.toasts;

    const { toast: toastId } = identifiersData.ids;

    const exportFilteredRevenueReports = async () => {
        setExporting(true);

        try {
            await exportFilteredRevenueReportsAction({
                onCompleted: ({ exportFilteredReportData }) => {
                    setExporting(false);

                    const randomId = getUniqueRandomId();

                    toast.success(
                        () => (
                            <DownloadBox
                                id={`${exportFilteredRevenueReportsSuccessToastName}_${toastId}_${randomId}`}
                                link={exportFilteredReportData}
                                message={t(
                                    "messages.downloadMsgs.download",
                                    { entity: t("revenueReports.name") },
                                )}
                            />
                        ),
                        {
                            closeButton: true,
                            toastId: `${exportFilteredRevenueReportsSuccessToastName}_${toastId}_${randomId}`,
                        },
                    );

                    dispatch(openAlert(
                        t(
                            "messages.exportMsgs.success",
                            { entity: t("revenueReports.name") },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: {
                    where: { ...filters?.revenueReports && Object.keys(filters?.revenueReports).length > 0 && filters?.revenueReports },
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        } finally {
            toast.dismiss(`${exportFilteredRevenueReportsPendingToastName}_${toastId}`);
        }
    };

    useEffect(
        () => {
            if (loading && exporting) {
                toast.info(
                    () => t(
                        "messages.exportMsgs.pending",
                        { entity: t("revenueReports.name") },
                    ),
                    { toastId: `${exportFilteredRevenueReportsPendingToastName}_${toastId}` },
                );
            }
        },
        [loading, exporting], //eslint-disable-line
    );

    return { exportFilteredRevenueReports };
};

export default useExportFilteredRevenueReports;
