import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import { Box, Typography } from "@mui/material";
import { identifiersData } from "data";
import PropTypes from "prop-types";
import { theme } from "theme";

const FoldersStructureTabTreeItem = ({
    childrenNumber,
    name,
    nodeId,
    rowId,
    ...other
}) => {
    const {
        foldersStructure: foldersStructureId,
        treeItem: treeItemId,
    } = identifiersData.ids;

    return (
        <TreeItem
            id={`${foldersStructureId}_${treeItemId}_${rowId}`}
            nodeId={nodeId}
            label={(
                <Box
                    alignItems="center"
                    display="flex"
                    ml={1}
                    p={0.5}
                    pr={0}
                >
                    <Box
                        color="inherit"
                        component={FolderOpenIcon}
                        mr={1}
                    />
                    <Box
                        alignItems="center"
                        display="flex"
                        flexWrap="nowrap"
                        gap={2}
                        overflow="auto"
                        sx={{ "&::-webkit-scrollbar": { display: "none" } }}
                    >
                        <Typography variant="caption">{name}</Typography>
                        {childrenNumber ? (
                            <Typography
                                bgcolor={theme.palette.primary.main}
                                borderRadius="50%"
                                color={theme.palette.grey[100]}
                                fontWeight="bold"
                                px={1}
                                variant="caption"
                            >
                                {childrenNumber}
                            </Typography>
                        ) : null}
                    </Box>
                </Box>
            )}
            sx={{
                "& .MuiTreeItem-content.Mui-selected": { backgroundColor: theme.palette.grey[300] },
                [`& .${treeItemClasses.content}`]: {
                    borderBottomRightRadius: theme.spacing(2),
                    borderTopRightRadius: theme.spacing(2),
                },
                color: theme.palette.grey[600],
            }}
            {...other}
        />
    );
};

export default FoldersStructureTabTreeItem;

FoldersStructureTabTreeItem.propTypes = {
    childrenNumber: PropTypes.number,
    name: PropTypes.string,
    nodeId: PropTypes.string,
    rowId: PropTypes.number,
};
