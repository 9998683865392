import { Box } from "@mui/material";
import { PageHead, ReleaseClientLinkForm, ReleaseClientLinksList } from "components";
import { actionsData } from "data";
import { useCreateReleaseClientLink } from "hooks";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const ReleaseClientLinksTab = ({ data }) => {
    const { id } = useParams();

    const { t } = useTranslation();

    const {
        createReleaseClientLink,
        loading,
    } = useCreateReleaseClientLink(parseInt(id));

    return (
        <Box>
            <PageHead title={t("tabs.clientLinks")} />
            <ReleaseClientLinkForm
                action={actionsData.create}
                loading={loading}
                onSubmitForm={createReleaseClientLink}
            />
            <ReleaseClientLinksList
                data={data}
                tabValue="11"
                hasTab
            />
        </Box>
    );
};

export default ReleaseClientLinksTab;

ReleaseClientLinksTab.propTypes = { data: PropTypes.object };
