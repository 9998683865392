import { Drawer, Loader } from "atoms";
import { RevenueReportForm } from "components";
import { useEditRevenueReport, useGetRevenueReport } from "hooks";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toggleRevenueReportDrawer } from "redux/actions";

const RevenueReportDrawer = ({
    id,
    setId,
}) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const open = useSelector((state) => state.revenueReportsReducer?.openDrawer);

    const {
        data,
        loading: revenueReportsLoading,
    } = useGetRevenueReport(id);

    const {
        editRevenueReport,
        loading,
    } = useEditRevenueReport(
        id,
        setId,
    );

    const values = {
        artist: data?.artist_name_en,
        label: data?.labelName,
        release: data?.album_name_en,
        revenue: data?.revenue,
    };

    return (
        <Drawer
            closeAction={() => dispatch(toggleRevenueReportDrawer(false))}
            open={!!(open && id)}
            title={t("revenueReports.editRevenueReport")}
        >
            {revenueReportsLoading ? <Loader /> : (
                <RevenueReportForm
                    loading={loading}
                    values={values}
                    onSubmitForm={editRevenueReport}
                />
            )}
        </Drawer>
    );
};

export default RevenueReportDrawer;

RevenueReportDrawer.propTypes = {
    id: PropTypes.number,
    setId: PropTypes.func,
};
