import { useAuth0 } from "@auth0/auth0-react";
import {
    constantsData,
    identifiersData,
    namesData,
    statusTypesData,
    urlsData,
} from "data";
import { useApisClient } from "hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { openAlert, setRefetchDriveManagementList } from "redux/actions";
import { displayErrorHandler } from "utils";

const useGrantDriveManagementUserPermission = () => {
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const { logout } = useAuth0();

    const dispatch = useDispatch();

    const { post } = useApisClient();

    const {
        files: { url: driveManagementFilesApiUrl },
        users: { permissions: driveManagementUsersPermissionsApiUrl },
    } = urlsData.apis.driveManagement;

    const {
        group: groupConstant,
        user: userConstant,
    } = constantsData;

    const { grantDriveManagementUserPermissionPending: grantDriveManagementUserPermissionPendingToastName } = namesData.toasts;

    const { toast: toastId } = identifiersData.ids;

    const getEmailTypeHandler = (type) => {
        if (type?.includes(userConstant)) return userConstant;
        if (type?.includes(groupConstant)) return groupConstant;
        return type;
    };

    const grantDriveManagementUserPermission = (fileId, selected, permission) => {
        setLoading(true);

        post(
            `${driveManagementFilesApiUrl}/${fileId}/${driveManagementUsersPermissionsApiUrl}`,
            null,
            {
                emailAddress: selected?.email,
                role: permission,
                type: getEmailTypeHandler(selected?.type),
            },
        ).
            then(() => {
                dispatch(openAlert(
                    t(
                        "messages.createMsgs.success",
                        { entity: t("labels.permission") },
                    ),
                    statusTypesData.success,
                ));

                dispatch(setRefetchDriveManagementList(true));
            })["catch"]((err) => {
                displayErrorHandler(
                    err.response.data.message === constantsData.networkError ? t("messages.networkFailure") : err.response.data.message,
                    dispatch,
                    openAlert,
                    err.response.status,
                    logout,
                );
            })["finally"](() => {
                toast.dismiss(`${grantDriveManagementUserPermissionPendingToastName}_${toastId}`);

                setLoading(false);
            });
    };

    useEffect(
        () => {
            if (loading) {
                toast.info(
                    () => t(
                        "messages.createMsgs.pending",
                        { entity: t("labels.permission") },
                    ),
                    { toastId: `${grantDriveManagementUserPermissionPendingToastName}_${toastId}` },
                );
            }
        },
        [loading], //eslint-disable-line
    );

    return {
        grantDriveManagementUserPermission,
        loading,
    };
};

export default useGrantDriveManagementUserPermission;
