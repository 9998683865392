import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData } from "data";
import { editReleaseClientLinkMutation, getReleaseClientLinkQuery } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert, toggleReleaseClientLinkDrawer } from "redux/actions";
import { displayErrorHandler } from "utils";

const useEditReleaseClientLink = (id, setId) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [editReleaseClientLinkAction, { loading }] = useMutation(
        editReleaseClientLinkMutation,
        {
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: getReleaseClientLinkQuery,
                    variables: {
                        where: { id },
                    },
                },
            ],
        },
    );

    const editReleaseClientLink = async ({
        client,
        link,
        track,
    }) => {
        try {
            await editReleaseClientLinkAction({
                onCompleted: () => {
                    dispatch(toggleReleaseClientLinkDrawer(false));

                    setId(null);

                    dispatch(openAlert(
                        t(
                            "messages.editMsgs.success",
                            { entity: `${t("releases.entity")} ${t("labels.clientLink")}` },
                        ),
                        statusTypesData.success,
                    ));
                },

                variables: {
                    data: {
                        ...client && {
                            client: {
                                connect: { id: client },
                            },
                        },
                        releaseLink: { set: link },
                        ...track && {
                            releaseTrack: {
                                connect: { id: track },
                            },
                        },
                    },
                    where: { id },
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        editReleaseClientLink,
        loading,
    };
};

export default useEditReleaseClientLink;
