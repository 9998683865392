import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {
    Box,
    Button,
    Chip,
    FormGroup,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { SelectMenu } from "atoms";
import {
    identifiersData,
    inputData,
    namesData,
    statusTypesData,
    urlsData,
} from "data";
import { PropTypes } from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
    clearSearchValues,
    deleteSearchValue,
    openAlert,
    setSearchCategory,
    setSearchData,
    setSearchValue,
    setSearchValues,
} from "redux/actions";
import { StyledSearchInput, StyledTextField } from "styles";

const SearchInput = ({ fromNavbar }) => {
    const [searchInputValue, setSearchInputValue] = useState("");

    const dispatch = useDispatch();

    const searchValues = useSelector((state) => state.searchReducer.searchValues);

    const searchCategory = useSelector((state) => state.searchReducer.searchCategory) || 1;

    const { t } = useTranslation();

    const navigate = useNavigate();

    const { search: searchRouteUrl } = urlsData.routes;

    const {
        classNames: {
            searchBox: searchBoxClassName,
            searchButton: searchButtonClassName,
            searchSelectMenu: searchSelectMenuClassName,
            searchSelectMenuFormControl: searchSelectMenuFormControlClassName,
            searchTagsBox: searchTagsBoxClassName,
            searchTextField: searchTextFieldClassName,
        },
        ids: {
            button: buttonId,
            input: inputId,
            label: labelId,
            selectMenu: selectMenuId,
        },
    } = identifiersData;

    const {
        dashboardSearch: dashboardSearchInputName,
        search: searchInputName,
    } = inputData.names;

    const clickSearchHandler = () => {
        if (searchValues?.length > 0) {
            if (!searchCategory) dispatch(setSearchCategory(1));

            const trimSearchValues = searchValues.map((value) => value.trim());

            navigate({
                pathname: searchRouteUrl,
                search: createSearchParams({
                    category: searchCategory || 1,
                    limit: 25,
                    page: 1,
                    q: trimSearchValues.filter((value) => value !== "").join(","),
                }).toString(),
            });

            dispatch(setSearchData({
                category: searchCategory || 1,
                values: trimSearchValues,
            }));
        } else {
            dispatch(openAlert(
                t("labels.searchNote"),
                statusTypesData.error,
            ));
        }
    };

    const keyDownSearchInputHandler = (event) => {
        const searchValue = event.target.value;

        switch (event.key) {
        case ",":
        case "Tab": {
            event.preventDefault();

            event.stopPropagation();

            if (event.target.value.length > 0) {
                setSearchInputValue("");

                if (searchValue.includes(",")) {
                    const searchTagsArray = searchValue.split(",");

                    dispatch(setSearchValues(searchTagsArray));
                } else dispatch(setSearchValue(event.target.value));

                const searchTagsBox = document.querySelector(`.${searchTagsBoxClassName}`); // eslint-disable-line

                if (searchTagsBox) searchTagsBox.scrollLeft = searchTagsBox.scrollWidth;
            }

            break;
        }
        case "Enter": {
            event.preventDefault();

            event.stopPropagation();

            clickSearchHandler();

            break;
        }
        default:
        }
    };

    return (
        <>
            <StyledSearchInput
                className={searchBoxClassName}
                fromNavbar={+!!fromNavbar}
                id={`${dashboardSearchInputName}_${inputId}`}
                inputValue={searchInputValue}
                options={[]}
                value={searchValues}
                clearIcon={(
                    <Box>
                        <Box
                            ml={1}
                            onClick={() => dispatch(clearSearchValues())}
                        >
                            <ClearIcon fontSize="small" />
                        </Box>
                    </Box>
                )}
                renderInput={(inputParams) => {
                    inputParams.InputProps.onKeyDown = keyDownSearchInputHandler; // eslint-disable-line

                    return (
                        <FormGroup>
                            <StyledTextField
                                {...inputParams}
                                className={searchTextFieldClassName}
                                placeholder={t("labels.search")}
                                variant="outlined"
                                InputProps={{
                                    ...inputParams.InputProps,
                                    endAdornment: (
                                        <>
                                            <Tooltip
                                                title={t("actions.search")}
                                                sx={{
                                                    display: {
                                                        sm: "block",
                                                        xs: "none",
                                                    },
                                                }}
                                            >
                                                <IconButton
                                                    className={searchButtonClassName}
                                                    edge="end"
                                                    id={`${dashboardSearchInputName}_${inputId}_${buttonId}`}
                                                    onClick={clickSearchHandler}
                                                >
                                                    <SearchIcon />
                                                </IconButton>
                                            </Tooltip>
                                            {inputParams.InputProps.endAdornment}
                                        </>
                                    ),
                                    startAdornment: (
                                        <>
                                            <SelectMenu
                                                className={searchSelectMenuClassName}
                                                formControlClassName={searchSelectMenuFormControlClassName}
                                                formControlStyle={{ minWidth: "80px" }}
                                                label={t("labels.category")}
                                                labelId={`${searchInputName}_${selectMenuId}_${labelId}`}
                                                name={namesData.menus.dashboardSearch}
                                                selected={!searchCategory}
                                                value={searchCategory}
                                                options={[
                                                    {
                                                        label: t("artists.name"),
                                                        value: 1,
                                                    },
                                                    {
                                                        label: t("releases.name"),
                                                        value: 2,
                                                    },
                                                    {
                                                        label: t("tracks.name"),
                                                        value: 3,
                                                    },
                                                ]}
                                                onChange={(e) => dispatch(setSearchCategory(e.target.value))}
                                            />
                                            {inputParams.InputProps.startAdornment}
                                        </>
                                    ),
                                }}
                            />
                        </FormGroup>
                    );
                }}
                renderTags={(_, getTagProps) => (
                    <Box
                        className={searchTagsBoxClassName}
                        display="flex"
                        flexWrap="nowrap"
                        maxHeight={40}
                        overflow="auto"
                    >
                        {searchValues.filter((tag) => tag !== undefined).filter((tag) => tag !== "").map((tag, index) => (
                            <Chip
                                key={tag}
                                label={tag}
                                variant="outlined"
                                {...getTagProps({ index })}
                                onDelete={() => dispatch(deleteSearchValue(index))}
                            />
                        ))}
                    </Box>
                )}
                freeSolo
                multiple
                onChange={(_, value) => dispatch(setSearchValues(value))}
                onInputChange={(_, value) => setSearchInputValue(value)}
            />
            <Box
                my={3}
                sx={{
                    "@media(max-width: 700px)": {
                        display: fromNavbar ? "none" : "block",
                    },
                    "@media(min-width: 700px)": {
                        display: "none",
                    },
                }}
            >
                <Button
                    id={`${dashboardSearchInputName}_${inputId}_${buttonId}`}
                    variant="contained"
                    onClick={clickSearchHandler}
                >
                    <Box
                        display="flex"
                        gap={1}
                    >
                        <SearchIcon sx={{ fontSize: 20 }} />
                        <Typography
                            fontWeight="bold"
                            variant="caption"
                        >
                            {t("actions.search")}
                        </Typography>
                    </Box>
                </Button>
            </Box>
        </>
    );
};

export default SearchInput;

SearchInput.propTypes = { fromNavbar: PropTypes.bool };
