import InboxIcon from "@mui/icons-material/Inbox";
import { Avatar, Typography } from "@mui/material";
import NoFiles from "assets/images/no-files.svg";
import NoLabels from "assets/images/no-labels.png";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { StyledNoData } from "styles";

const NoData = ({
    body,
    title,
    type,
}) => {
    const { t } = useTranslation();

    const icon = () => {
        switch (type) {
        case "files":
            return (
                <Avatar
                    alt={t("imageAlternatives.noFiles")}
                    imgProps={{ loading: "lazy" }}
                    src={NoFiles}
                    sx={{
                        height: 250,
                        width: 250,
                    }}
                />
            );

        case "labels":
            return (
                <Avatar
                    alt={t("imageAlternatives.noLabels")}
                    imgProps={{ loading: "lazy" }}
                    src={NoLabels}
                    sx={{
                        borderRadius: 0,
                        height: 76,
                        width: 185,
                    }}
                />
            );

        default:
            return (
                <InboxIcon
                    sx={{
                        height: 150,
                        width: 150,
                    }}
                />
            );
        }
    };

    return (
        <StyledNoData>
            {icon()}
            <Typography
                mb={2}
                mt={2}
                variant="h6"
            >
                {title || t("labels.noData")}
            </Typography>
            {body && (
                <Typography
                    textAlign="center"
                    variant="body1"
                >
                    {body}
                </Typography>
            )}
        </StyledNoData>
    );
};

export default NoData;

NoData.propTypes = {
    body: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
};
