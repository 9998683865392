import { useMutation } from "@apollo/client";
import {
    constantsData,
    identifiersData,
    importerConfigData,
    namesData,
    statusTypesData,
} from "data";
import { addReleaseTrackMutation, getTracksListQuery } from "graph";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useAddReleaseTrack = (releaseId, withoutRefetch, withoutAlert) => {
    const [controller, setController] = useState(null);

    const [exporting, setExporting] = useState(false);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [addReleaseTrackAction, { loading }] = useMutation(
        addReleaseTrackMutation,
        {
            ...!withoutRefetch && {
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: getTracksListQuery,
                        variables: {
                            limit: 25,
                        orderBy: { track_num: "asc" }, //eslint-disable-line
                            page: 0,
                            where: {
                                AND: {},
                                releaseTracks: {
                                    some: {
                                        releaseId: { equals: releaseId },
                                    },
                                },
                            },
                        },
                    },
                ],
            },
        },
    );

    const { addReleaseTrackPending: addReleaseTrackPendingToastName } = namesData.toasts;

    const { toast: toastId } = identifiersData.ids;

    const addReleaseTrack = async (
        trackId,
        id,
        importerData,
    ) => {
        if (window?.__RUNTIME_CONFIG__?.ENABLE_DEBUG_LOGS === "true" && importerData) { // eslint-disable-line
            console.log(
                "releaseIdFromAddTrackToReleaseHook",
                id,
            );
        }

        setExporting(true);

        try {
            const newController = new AbortController();

            setController(newController);

            importerData?.setCurrentStep(importerConfigData.steps.addingTrack);

            await addReleaseTrackAction({
                context: {
                    fetchOptions: { signal: newController.signal },
                },
                onCompleted: () => {
                    setExporting(false);

                    if (!withoutAlert) {
                        dispatch(openAlert(
                            t(
                                "messages.addMsgs.success",
                                { entity: `${t("tracks.entity")}` },
                            ),
                            statusTypesData.success,
                        ));
                    }

                    if (importerData) {
                        importerData?.setAddedExistingTracks((prev) => [
                            ...prev,
                            { ...importerData?.track },
                        ]);
                    }
                },
                variables: {
                    releaseId: importerData ? id : releaseId,
                    trackId,
                },
            });
        } catch (err) {
            if (!withoutAlert) {
                displayErrorHandler(
                    err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                    dispatch,
                    openAlert,
                );
            }

            if (importerData) {
                dispatch(importerData?.setInvalidImporterRelease(
                    {
                        originalData: importerData?.originalData,
                        release: { data: importerData?.release },
                        track: {
                            data: importerData?.track,
                            message: err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                        },
                        ...err.message === constantsData.failedToFetch && { isNetworkFailed: true },
                    },
                ));

                importerData?.setStatus((prev) => ({
                    ...prev,
                    tracks: {
                        ...prev.tracks,
                        loading: false,
                        success: false,
                    },
                }));
            }
        } finally {
            toast.dismiss(`${addReleaseTrackPendingToastName}_${toastId}`);

            setController(null);

            if (importerData) importerData?.setCurrentStep(null);
        }
    };

    useEffect(
        () => {
            if (loading && exporting && !withoutAlert) {
                toast.info(
                    () => t(
                        "messages.addMsgs.pending",
                        { entity: t("tracks.entity") },
                    ),
                    { toastId: `${addReleaseTrackPendingToastName}_${toastId}` },
                );
            }
        },
        [loading, exporting], //eslint-disable-line
    );

    return {
        addReleaseTrack,
        cancelRequest: () => controller && controller.abort(),
        loading,
    };
};

export default useAddReleaseTrack;
