import {
    REMOVE_CURRENT_CLIENT,
    SET_CURRENT_CLIENT,
    SET_REVENUE_REPORT_TEMPLATE_DATA,
    SET_XML_DOWNLOAD_LINK,
} from "redux/types";
import { updateStateHandler } from "utils";

const initialState = {
    created: false,
    currentClient: {},
    revenueReportTemplate: {},
    xmlDownloadLink: null,
};

const setCurrentClient = (state, client) => updateStateHandler(
    state,
    {
        created: true,
        currentClient: client,
    },
);

const setRevenueReportTemplateData = (state, data) => updateStateHandler(
    state,
    { revenueReportTemplate: data },
);

const removeCurrentClient = (state) => updateStateHandler(
    state,
    {
        created: false,
        currentClient: null,
    },
);

const setXmlDownloadLink = (state, link) => updateStateHandler(
    state,
    { xmlDownloadLink: link },
);

const clientsReducer = (state = initialState, action) => {
    const {
        payload,
        type,
    } = action;

    const {
        client,
        data,
        link,
    } = payload || {};

    switch (type) {
    case SET_CURRENT_CLIENT: return setCurrentClient(
        state,
        client,
    );
    case SET_REVENUE_REPORT_TEMPLATE_DATA: return setRevenueReportTemplateData(
        state,
        data,
    );
    case SET_XML_DOWNLOAD_LINK: return setXmlDownloadLink(
        state,
        link,
    );
    case REMOVE_CURRENT_CLIENT: return removeCurrentClient(state);
    default:
        return state;
    }
};

export default clientsReducer;
