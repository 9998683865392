import { useMutation } from "@apollo/client";
import { constantsData, importerConfigData, statusTypesData } from "data";
import { createTrackMutation, getTracksListQuery } from "graph";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useCreateTrack = (withoutRefetch, withoutAlert) => {
    const [controller, setController] = useState(null);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const releaseData = useSelector((state) => state.releasesReducer);

    const [createTrackAction, { loading }] = useMutation(
        createTrackMutation,
        {
            ...!withoutRefetch && {
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: getTracksListQuery,
                        variables: {
                            limit: 25,
                            orderBy: { track_num: "asc" }, // eslint-disable-line
                            page: 0,
                            where: {
                                AND: {},
                                releaseTracks: {
                                    some: {
                                        releaseId: { equals: releaseData?.currentRelease?.id },
                                    },
                                },
                            },
                        },
                    },
                ],
            },
        },
    );

    const createTrack = async (
        {
            composers,
            customId,
            imageFile,
            isrc,
            iswc,
            nameAr,
            nameEn,
            number,
            otherIsrc,
            primaryArtist,
            secondaryArtists,
            trackFile,
            writers,
        },
        id,
        importerData,
    ) => {
        if (window?.__RUNTIME_CONFIG__?.ENABLE_DEBUG_LOGS === "true" && importerData) { // eslint-disable-line
            console.log(
                "releaseIdFromCreateTrackHook",
                id,
            );
        }

        try {
            const newController = new AbortController();

            setController(newController);

            importerData?.setCurrentStep(importerConfigData.steps.creatingTrack);

            await createTrackAction({
                context: {
                    fetchOptions: { signal: newController.signal },
                },
                onCompleted: ({ createOneTrack }) => {
                    if (importerData) {
                        importerData?.setCreatedNonExistingTracks((prev) => [
                            ...prev,
                            {
                                ...importerData?.track,
                                id: createOneTrack?.id,
                            },
                        ]);
                    }

                    if (!withoutAlert) {
                        dispatch(openAlert(
                            t(
                                "messages.createMsgs.success",
                                { entity: t("tracks.entity") },
                            ),
                            statusTypesData.success,
                        ));
                    }
                },
                variables: {
                    data: {
                        artist: {
                            connect: { id: primaryArtist },
                        },
                        composers,
                        ...imageFile && {
                            image: {
                                connect: { id: imageFile },
                            },
                        },
                        isrc,
                        releaseTracks: {
                            create: {
                                release: {
                                    connect: { id: importerData ? id : releaseData?.currentRelease?.id },
                                },
                            },
                        },
                        custom_id: customId, // eslint-disable-line
                        isrc_other: otherIsrc, // eslint-disable-line
                        iswc,
                        secondary_artists: { connect: secondaryArtists?.map(({ value }) => ({ id: value })) }, // eslint-disable-line
                        title: nameEn,
                        title_ar:  nameAr, // eslint-disable-line
                        trackFile: {
                            connect: { id: trackFile },
                        },
                        track_num: parseInt(number), // eslint-disable-line
                        writers,
                    },
                    where: { id: importerData ? id : releaseData?.currentRelease?.id },
                },
            });
        } catch (err) {
            if (!withoutAlert) {
                displayErrorHandler(
                    err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                    dispatch,
                    openAlert,
                );
            }

            if (importerData) {
                dispatch(importerData?.setInvalidImporterRelease(
                    {
                        originalData: importerData?.originalData,
                        release: { data: importerData?.release },
                        track: {
                            data: importerData?.track,
                            message: err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                        },
                        ...err.message === constantsData.failedToFetch && { isNetworkFailed: true },
                    },
                ));

                importerData?.setStatus((prev) => ({
                    ...prev,
                    tracks: {
                        ...prev.tracks,
                        loading: false,
                        success: false,
                    },
                }));
            }

            return { error: err };
        } finally {
            setController(null);

            if (importerData) importerData?.setCurrentStep(null);
        }
    };

    return {
        cancelRequest: () => controller && controller.abort(),
        createTrack,
        loading,
    };
};

export default useCreateTrack;
