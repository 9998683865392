import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData } from "data";
import { createDriveLinkMutation, getReleaseQuery } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert, toggleDriveLinkDrawer } from "redux/actions";
import { displayErrorHandler } from "utils";

const useCreateDriveLink = (entityId, entityType) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [createDriveLinkAction, { loading }] = useMutation(
        createDriveLinkMutation,
        {
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: getReleaseQuery,
                    variables: {
                        where: { id: entityId },
                    },
                },
            ],
        },
    );

    const createDriveLink = async (data) => {
        try {
            await createDriveLinkAction({
                onCompleted: () => {
                    dispatch(toggleDriveLinkDrawer(false));

                    dispatch(openAlert(
                        t(
                            "messages.createMsgs.success",
                            { entity: `${entityType} ${t("labels.driveLink")}` },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: {
                    entityId,
                    entityType: entityType.toUpperCase(),
                    name: data?.resourceName,
                    url: data?.driveLink,
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        createDriveLink,
        loading,
    };
};

export default useCreateDriveLink;
