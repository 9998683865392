import { useAuth0 } from "@auth0/auth0-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormContainer } from "components";
import { identifiersData, permissionsData, releaseNoteFormData } from "data";
import { PropTypes } from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useReleaseNoteSchema } from "schemas";
import { StyledTextField } from "styles";
import { canDo, renderFormControllerHandler } from "utils";

const ReleaseNoteForm = ({
    action,
    loading,
    onSubmitForm,
    values,
}) => {
    const { t } = useTranslation();

    const schema = useReleaseNoteSchema();

    const { user } = useAuth0();

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(schema),

    });

    const {
        control,
        formState: { errors },
    } = formMethods;

    const {
        mediaCreate: mediaCreatePermission,
        mediaEdit: mediaEditPermission,
        mediaRead: mediaReadPermission,
    } = permissionsData.permissions.keys;

    const allowedPermissions = values ? [mediaEditPermission, mediaReadPermission] : [mediaCreatePermission, mediaReadPermission];

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        _,
        isOptional,
    ) => (
        <StyledTextField
            {...field}
            defaultValue={values?.note || field.value}
            error={errors[name]}
            helperText={errors?.[name]?.message}
            id={`${name}_${identifiersData.ids.input}`}
            label={isOptional ? `${t(`labels.${label}`)} (${t("labels.optional")})` : t(`labels.${label}`)}
            minRows={4}
            variant="outlined"
            fullWidth
            multiline
        />
    );

    return (
        <FormContainer
            action={action}
            formMethods={formMethods}
            loading={loading}
            data={renderFormControllerHandler(
                control,
                releaseNoteFormData,
                renderFormControllerChildrenHandler,
            )}
            hasSubmitButton={canDo(
                user,
                allowedPermissions,
            )}
            onSubmitForm={onSubmitForm}
        />
    );
};

export default ReleaseNoteForm;

ReleaseNoteForm.propTypes = {
    action: PropTypes.string,
    loading: PropTypes.bool,
    onSubmitForm: PropTypes.func,
    values: PropTypes.object,
};
