import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData, urlsData } from "data";
import { createGroupMemberMutation, createMemberMutation } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useCreateMember = (groupId) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [createMemberAction, { loading }] = useMutation(groupId ? createGroupMemberMutation : createMemberMutation);

    const {
        members: { url: membersRouteUrl },
        url: groupsRouteUrl,
    } = urlsData.routes.groups;

    const createMember = async ({ email }) => {
        try {
            await createMemberAction({
                onCompleted: () => {
                    navigate(groupId ? `${groupsRouteUrl}/${groupId}${membersRouteUrl}` : groupsRouteUrl + membersRouteUrl);

                    dispatch(openAlert(
                        t(
                            "messages.createMsgs.success",
                            { entity: groupId ? t("groups.members.groupMember") : t("groups.members.entity") },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: {
                    ...groupId && {
                        groupId,
                        memberEmail: email,
                    },
                    ...!groupId && {
                        data: { email },
                    },
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        createMember,
        loading,
    };
};

export default useCreateMember;
