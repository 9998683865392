import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData } from "data";
import { createReleaseClientLinkMutation, getReleaseClientLinksListQuery } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useCreateReleaseClientLink = (releaseId) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [createReleaseClientLinkAction, { loading }] = useMutation(
        createReleaseClientLinkMutation,
        {
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: getReleaseClientLinksListQuery,
                    variables: {
                        limit: 25,
                        page: 0,
                        releaseId,
                    },
                },
            ],
        },
    );

    const createReleaseClientLink = async ({
        client,
        link,
        track,
    }) => {
        try {
            await createReleaseClientLinkAction({
                onCompleted: () => {
                    dispatch(openAlert(
                        t(
                            "messages.createMsgs.success",
                            { entity: `${t("releases.entity")} ${t("labels.clientLink")}` },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: {
                    data: {
                        client: {
                            connect: { id: client },
                        },
                        releaseLink: link,
                        releaseTrack: {
                            connect: { id: track },
                        },
                    },
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );

            return { error: err };
        }
    };

    return {
        createReleaseClientLink,
        loading,
    };
};

export default useCreateReleaseClientLink;
