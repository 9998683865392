import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData } from "data";
import { removePermissionMutation } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useRemovePermission = (setAddedPermissions, setRemovedPermissions, refetch) => {
    const [removePermissionAction, { loading }] = useMutation(removePermissionMutation);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const removePermission = async ({
        permissions,
        roleId,
    }) => {
        try {
            await removePermissionAction({
                onCompleted: () => {
                    setAddedPermissions([]);

                    setRemovedPermissions([]);

                    refetch();

                    dispatch(openAlert(
                        t(
                            "messages.editMsgs.success",
                            { entity: t("permissions.name") },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: {
                    removedPermission: {
                        id: roleId,
                        permissions: permissions.map((permission) => ({ permission_name: permission })), // eslint-disable-line
                    },
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        loading,
        removePermission,
    };
};

export default useRemovePermission;
