import { Box, Step, StepLabel } from "@mui/material";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { StyledStepper } from "styles";

const Stepper = ({
    activeStep,
    steps,
}) => {
    const { t } = useTranslation();

    return (
        <Box sx={{ width: "100%" }}>
            <StyledStepper activeStep={activeStep}>
                {steps.map((step) => (
                    <Step key={step}>
                        <StepLabel>
                            {t(`labels.${step}`)}
                        </StepLabel>
                    </Step>
                ))}
            </StyledStepper>
        </Box>
    );
};

export default Stepper;

Stepper.propTypes = {
    activeStep: PropTypes.number,
    steps: PropTypes.array,
};
